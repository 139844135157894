import React from 'react';
import NoData from './Images/NoData.png'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';

const NoRowsToDisplay = () => {
  const {t} = useTranslation();
  const noDataMessageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
    textAlign: 'center'
  };
  return (
    <div style={noDataMessageStyle}>
      <img src={NoData} style={{ maxWidth: "100%", height: "auto", maxHeight: "60vh" }}
        alt="No Data" />
      <div style={{ marginTop: '-4rem' }} className='font-weight-light font-italic'>
        <span className='font-semibold text-sm text-gray-600'>{t('No records found')}</span>
        {/* <span style={{ fontSize: '16px' }}>No records found for <strong>{contextVariable.date}</strong> </span> */}
      </div>
    </div>
  )
}

export default NoRowsToDisplay;