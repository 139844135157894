import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout';
import { API } from 'aws-amplify';
import { getByCustomerInvoiceDetail } from '../../../Layout/MutaionsAndQueries';
import { showToast } from '../../ToastUtils';
import CardTopbar from '../CardTopbar';
import getSymbolFromCurrency from 'currency-symbol-map';
import SkeletonLoader from '../../SkeletonLoader';
import DataNotFound from '../dataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';

const InvoiceDetailsCard = () => {
  const{t}=useTranslation();
  const contextVariable = useContext(AppContext);
  // console.log("contextVariable", contextVariable);
  const [generalDetails, setGeneralDetails] = useState({});
  const [itemArray, setItemArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  // const [GrossAmountIndicator, setGrossAmountIndicator] = useState("true");
  // const [netAndGrossAmount, setnetAndGrossAmount] = useState({
  //   netAmount: '',
  //   grossAmount: '',
  // });

  const fetchInvoiceDetails = async () => {
    setLoading(true);
    try {
      const response = await API.graphql({
        query: getByCustomerInvoiceDetail,
        variables: {
          InvoiceID: contextVariable.rootObject.InvoiceID
        }
      });
      setGeneralDetails(response.data.GetByCustomerInvoiceDetail.items[0]);
      if (response.data.GetByCustomerInvoiceDetail.items[0].GrossAmountIndicator === 'false') {
        // setGrossAmountIndicator("false");
      }
      else {
        // setGrossAmountIndicator("true");
      }
      setItemArray(response.data.GetByCustomerInvoiceDetail.items[0].CustomerInvoiceItem);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      setIsError(error);
      // showToast(`Cannot get orders list : ${error.errors[0].message}`, "error");
      showToast(t(`Something went wrong. Please contact the admin.`), "error",4000);
      console.error("fetchInvoiceDetails Error", error);
    }
  }

  useEffect(() => {
    fetchInvoiceDetails();
    // eslint-disable-next-line 
  }, [])

  // useEffect(() => {
  //   if (contextVariable.rootObject) {
  //     const newNetAndGrossAmount = {
  //       netAmount: contextVariable.rootObject.NetAmount,
  //       grossAmount: contextVariable.rootObject.GrossAmount,
  //     };
  //     // setnetAndGrossAmount(newNetAndGrossAmount);
  //   }
  //   //eslint-disable-next-line
  // }, [contextVariable.rootObject]);

  return (
    <div className='h-screen' style={{ background: "#ededed", fontFamily: "helvetica" }}>
      {/* Topbar */}
      <CardTopbar
        header={`${contextVariable.rootObject.InvoiceID}`}
        link="invoice"
        buttonNeeded={true}
      />

      <div className='m-2 overflow-auto'>
        {
          (Object.keys(generalDetails).length !== 0) && !loading && isError === null &&
          <>
            <div className='card bg-white p-3' style={{ color: "var(--sidebar-color)" }}>
              {/* Reference and Date section */}
              <div className='flex justify-content-between align-items-baseline text-xs'>
                <div className='flex gap-2 pb-2'>
                  <span>#</span>
                  <span className='value-color'>{contextVariable.rootObject.ExternalReference}</span>
                </div>
                <div className='flex gap-2'>
                  <span>Date :</span>
                  <span className='value-color'>{contextVariable.rootObject.InvoiceDate}</span>
                </div>
              </div>
              {/* Payment terms and Inco terms */}
              <div className='text-xs'>
                <div className='flex gap-2 pb-2'>
                  <span>Payment Terms:</span>
                  <span className='value-color'>{generalDetails.PaymentTermsText}</span>
                </div>
                <div className='flex gap-2 pb-2'>
                  <span>Inco Terms:</span>
                  <span className='value-color'>{generalDetails.IncotermsText}</span>
                </div>
              </div>

              <hr className='pb-2' />

              {/* Amount section */}
              <div className='flex justify-content-between align-itemms-center pb-2'>
                <div className='flex flex-column'>
                  <span className='text-xs'>Total Amount</span>
                  <span className='text-sm text-left'>
                    {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(contextVariable.rootObject.TotalAmount)).toFixed(2)}
                  </span>
                </div>
                <div className='flex flex-column'>
                  <span className='text-xs'>Open Amount</span>
                  <span className='text-sm text-left'>
                    {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(contextVariable.rootObject.OpenAmount)).toFixed(2)}
                  </span>
                </div>
              </div>

              <hr className='pb-2' />

              {/* Note */}
              <div className='text-xs'>
                <span>Notes:</span>&nbsp;
                <span className='value-color'>{generalDetails.Notes}</span>
              </div>

            </div>
          </>
        }

        {/* Product details */}
        {
          (itemArray.length !== 0 && !loading && isError === null) &&
          <>
            <span className='text-base font-semibold'>Items</span>
            {
              itemArray
                .map((product, index) => (
                  <div key={index} className='card bg-white p-3 mb-2' style={{ color: "var(--sidebar-color)" }}>
                    <div className='flex justify-content-between align-items-center pb-2'>
                      <span className='text-sm'>{product.ProductDescription}</span>
                      <span className='text-xs'>
                        {(parseFloat(product.Quantity)).toFixed(2)}{" "}{product.QuantityUOMText}
                      </span>
                    </div>

                    <div className='grid text-xs'>

                      <div className='col-6'>
                        {/* <div className='pb-2'>
                          <span className='value-color'>#{" "}{product.POReference}</span>
                        </div> */}
                        <div className='flex flex-column align-items-baseline gap-2'>
                          <div className='flex gap-2'>
                            <span className='value-color'>#{" "}</span>
                            <span className='value-color'>{product.POReference}</span>
                          </div>
                          <div className='flex gap-2'>
                            <span>Discount:</span>
                            <span className='value-color'>{(-product.Discount).toFixed(2) + '%'}</span>
                          </div>
                        </div>

                      </div>

                      <div className='col-6'>
                        <div className='flex justify-content-end align-items-baseline gap-2'>
                          <div className='text-right'>
                            <div className=''>
                              <div className='pb-2'>
                                <span>Tax :</span>
                              </div>
                              {
                                contextVariable.rootObject.GrossAmountIndicator === "true" ?
                                  <span>Gross Price :</span>
                                  :
                                  <span>Net Price :</span>
                              }
                            </div>
                          </div>
                          <div className='text-right'>
                            <div>
                              <span className=''>{getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(product.TaxRate)).toFixed(2)}</span>
                            </div>
                            {
                              contextVariable.rootObject.GrossAmountIndicator === "true" ?
                                <div className='pt-2'>
                                  <span>{getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(product.GrossValue)).toFixed(2)}</span>
                                </div>
                                :
                                <div className='pt-2'>
                                  <span>{getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(product.NetValue)).toFixed(2)}</span>
                                </div>
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ))
            }
          </>
        }

        {/* Error */}
        {
          isError !== null &&
          <>
           <DataNotFound />
          </>
        }

        {/* Loading */}
        {
          loading === true &&
          <>
            <div className="flex flex-column">
              {
                [...Array(10)].map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              }
            </div>
          </>
        }

      </div>

    </div>
  )
}

export default InvoiceDetailsCard