import React, { useContext } from "react";
import { Box, IconButton, Tooltip, Zoom } from "@mui/material";
import { useState, useEffect } from "react";
import "./Invoice.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import InvoiceView from "./InvoiceView";
import PayComponent from "./PayComponent";
import { CachedOutlined, PictureAsPdfOutlined } from "@mui/icons-material";
import { showToast } from "../../components/ToastUtils";
import { AppContext } from "../../Layout/Layout";
import GetOrderPDF from "../order/GetOrderPDF";
import { API } from "aws-amplify";
import { GetInvoiceClearingStatus, ListByCustomerInvoiceDetails } from "../../graphql/queries";
import CurrencyFormat from "../../components/CurrencyFormat";
import { useFade } from "../../components/FadeContext/FadeContext";
import CardTopbar from "../../components/cards/CardTopbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import InvoiceCard from "../../components/cards/invoices/InvoiceCard";
import InvoiceDetailsCard from "../../components/cards/invoices/InvoiceDetailsCard";
import { Button } from "primereact/button";
import SkeletonLoader from "../../components/SkeletonLoader";
import NoResultsIcon from "../order/NoResultsIcon";
import InvoicePaymentCard from "../../components/cards/invoices/InvoicePaymentCard";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../components/cards/dataNotFound/DataNotFound";
import { useTranslation } from "react-i18next";
import DialogOfClearingInvoice from "./DialogOfClearingInvoice";
// import StripeCheckoutButton from '../../components/StripeCheckoutButton';

const Invoice = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [InvoiceViews, setInvoiceView] = useState("OverAllInvoiceView");
  const [invoiceDetails, setInvoiceDetails] = useState(1);
  const [rowLoadingStates, setRowLoadingStates] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const invoiceDet̥ailsCard = [...contextVariable.selectedInvoices];
  sessionStorage.setItem("title", "Invoice & Payments");
  const { fadeIn } = useFade();
  const isMobile = contextVariable.isMobile;
  const [disableDate, setDisableDate] = useState(false);
  const location = useLocation();

  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const dates = [
    { label: String(currentYear), year: currentYear },
    { label: String(currentYear - 1), year: currentYear - 1 },
    { label: String(currentYear - 2), year: currentYear - 2 },
    { label: String(currentYear - 3), year: currentYear - 3 },
    { label: String(currentYear - 4), year: currentYear - 4 },
    { label: String(currentYear - 5), year: currentYear - 5 },
    { label: String(currentYear - 6), year: currentYear - 6 },
    { label: String(currentYear - 7), year: currentYear - 7 },
    { label: String(currentYear - 8), year: currentYear - 8 },
    { label: String(currentYear - 9), year: currentYear - 9 },
    { label: String(currentYear - 10), year: currentYear - 10 },
    { label: String(currentYear - 11), year: currentYear - 11 },
    { label: String(currentYear - 12), year: currentYear - 12 },
    { label: String(currentYear - 13), year: currentYear - 13 },
    { label: String(currentYear - 14), year: currentYear - 14 },
    { label: String(currentYear - 15), year: currentYear - 15 }
  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [isSelectAllVisible, setIsSelectAllVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredInvoiceDetails, setFilteredInvoiceDetails] = useState([]);

  const setRowLoadingState = (rowKey, loading) => {
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [rowKey]: loading,
    }));
  };

  const columnDefs = [
    {
      filter: false,
      checkboxSelection: (params) => params.data.PaymentStatus !== "Cleared",
      headerCheckboxSelection: true, headerClass: 'custom-ag-header', flex: 0.1
    },
    { field: "ExternalReference", headerName: t('Reference Number'), filter: true, headerClass: 'custom-ag-header', flex: 0.3, tooltipField: 'ExternalReference' },
    { field: "InvoiceID", headerName: t('Invoice ID'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "InvoiceDate", headerName: t('Invoice Date'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    {
      field: "TotalAmount", headerName: `${t('Total Amount')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    {
      field: "PaymentStatus", headerName: t('Payment Status'), filter: true, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        return (
          <>
            {
              params.value === 'Open' &&
              <span className='px-2 py-1 text-blue-600 bg-blue-100 border-round-md font-semibold'>
                {params.value}
              </span>
            }
            {
              params.value === 'Partially Cleared' &&
              <span className='px-2 py-1 text-yellow-600 bg-yellow-100 border-round-md font-semibold'>
                {params.value}
              </span>
            }
            {
              params.value === 'Cleared' &&
              <span className='px-2 py-1 text-green-600 bg-green-100 border-round-md font-semibold'>
                {params.value}
              </span>
            }
          </>
        )
      }
    },
    {
      field: "OpenAmount", headerName: `${t('Open Amount')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    { field: "PaymentDueDate", headerName: t('Payment Due Date'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    {
      field: 'preview',
      headerName: t('PDF Preview'),
      flex: 0.3,
      filter: false,
      sort: false,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const handlePreviewClick = () => {
          setRowLoadingState(params.data.InvoiceID, true);
          GetOrderPDF("invoice", params.data.InvoiceID, params.data.InvoiceID, t).then(() => {
            setRowLoadingState(params.data.InvoiceID, false);
          });
        };
        return (
          rowLoadingStates[params.data.InvoiceID] ? (
            <Tooltip title="loading" placement="bottom" TransitionComponent={Zoom}>
              <CachedOutlined
                className="rotating-icon-anticlockwise"
                style={{ marginTop: '-0.3rem' }}
              />
            </Tooltip>
          ) :
            (params.data.InvoiceID).trim() !== '' ?
              <>
                <IconButton
                  style={{ marginTop: '-0.3rem' }}
                  color="var(--sidebar-color)"
                  onClick={handlePreviewClick}
                >
                  <PictureAsPdfOutlined style={{ color: 'red' }} />
                </IconButton>
              </> :
              <IconButton
                style={{ marginTop: '-0.3rem' }}
                color="var(--sidebar-color)"
                disabled={true}
              >
                <PictureAsPdfOutlined />
              </IconButton>
        );
      },
    }
  ];


  const fetchInvoiceDetails = async () => {
    setDisableDate(true);
    setInvoiceDetails(1);
    setLoading(true);

    let InvoiceDetails = [];
    let Skip = 0;
    try {
      do {
        const responseData = await API.graphql({
          query: ListByCustomerInvoiceDetails,
          variables: {
            year: contextVariable.date,
            limit: contextVariable.FetchLimit.invoice,
            skip: Skip,
            sortField: "CTRANSDAT",
            sortOrder: "desc",
          }
        });
        const sessionYear = parseInt(sessionStorage.getItem("date"))
        if (responseData.data.ListByCustomerInvoiceDetails.year === sessionYear) {
          if (responseData.data.ListByCustomerInvoiceDetails.count < contextVariable.FetchLimit.invoice) {
            // if (responseData.data.ListByCustomerInvoiceDetails.count === 0) {
            Skip = 0;
            contextVariable.setIsFiltered(false);
          }
          else if (responseData.data.ListByCustomerInvoiceDetails.count >= contextVariable.FetchLimit.invoice) {
            // else if (responseData.data.ListByCustomerInvoiceDetails.count > 0) {
            Skip = Skip + responseData.data.ListByCustomerInvoiceDetails.count;
            contextVariable.setIsFiltered(false);
          }
          InvoiceDetails = InvoiceDetails.concat(responseData.data.ListByCustomerInvoiceDetails.items)
          if (InvoiceDetails.length > 0) {
            setInvoiceDetails(InvoiceDetails);
            // console.log("InvoiceDetails",InvoiceDetails);
          }
          else if (responseData.data.ListByCustomerInvoiceDetails.items.length === 0) {
            setInvoiceDetails(0);
          }
        }
        else {
          Skip = 0;
        }
      } while (Skip)
      setDisableDate(false);
      setLoading(false);
    }
    catch (error) {
      setDisableDate(false);
      setInvoiceDetails(0);
      setLoading(false);
      setError(error.errors[0].message);
      console.error("fetchInvoiceDetails Error", error);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchInvoiceDetails Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
      // showToast(`Cannot get sales order list : ${error.errors[0].message}`, "error");
    }
  }

  useEffect(() => {
    fetchInvoiceDetails();

    return () => {
      setInvoiceDetails(1)
    }
    //eslint-disable-next-line
  }, [contextVariable.date]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setSelectedInvoices(new Set());
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (!contextVariable.showView.showInvoiceDetails) {
      window.scrollTo(0, contextVariable.scrollPosition);
    }
  }, [contextVariable.showView.showInvoiceDetails, contextVariable.scrollPosition]);

  const openInvoiceItemView = () => {
    setInvoiceView("InvoiceView");
  }

  const closeInvoiceItemView = () => {
    setInvoiceView("OverAllInvoiceView");
  }

  const closepaymentView = () => {
    setInvoiceView("OverAllInvoiceView");
  }

  const [UnClearInvoice, setUnClearInvoice] = useState({ DialogShow: false, Data: [] });
  const handlepay = async () => {

    // Extract selected Invoice ids 
    const invoiceIds = selectedInvoices.map((el) => ({ InvoiceID: el.InvoiceID }));

    //Api for Clearing invoice status
    try {
      const ClearingResponse = await API.graphql({
        query: GetInvoiceClearingStatus,
        variables:
          { input: invoiceIds }
      })
      // console.log("ClearingResponse", ClearingResponse)
      if (ClearingResponse.data.GetInvoiceClearingStatus.count == 0) {
        setInvoiceView("PaymentView");
      }
      else if (ClearingResponse.data.GetInvoiceClearingStatus.count > 0) {
        setUnClearInvoice({ DialogShow: true, Data: ClearingResponse.data.GetInvoiceClearingStatus.items })
      }

      // console.log("ClearingResponse", ClearingResponse)
    } catch (error) {
      console.error("Clearing Invoice API Error", error)
    }



  }

  const sendData = (params) => {
  }

  const selectedRowsHandler = (rows) => {
    const filteredRows = rows.filter((el) => el.PaymentStatus !== 'Cleared')
    setSelectedInvoices(filteredRows);
    // if (rows.length !== 0) {
    //   setRows(rows)
    // }
  }

  // Handler for updating the search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery) {
      setFilteredInvoiceDetails(
        invoiceDetails.filter((el) =>
          Object.values(el).some(
            (value) =>
              value &&
              typeof value === 'string' &&
              value.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      );
    } else {
      setFilteredInvoiceDetails(invoiceDetails);
    }
  }, [searchQuery, invoiceDetails]);

  const handleDateChange = (e) => {
    sessionStorage.setItem("date", e.value.year);
    setSelectedDate(e.value.year);
    // contextVariable.setGlobalData(null);
    contextVariable.setGlobalSearch(contextVariable.globalSearch)
    // contextVariable.setFilteredData([]);
    contextVariable.setIsFiltered(false);
    contextVariable.setDate(e.value.year);
  };

  const [checkboxes, setCheckboxes] = useState([]);

  // Function to handle checkbox state changes
  const handleCheckboxChange = (index) => {
    // console.log(index)
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  // Function to handle "Select All" button click
  const handleSelectAll = () => {
    // console.log("filteredInvoiceDetails", filteredInvoiceDetails);
    const partiallyClearedInvoices = filteredInvoiceDetails.filter((el) =>
      (el.PaymentStatus === 'Partially Cleared' || el.PaymentStatus === 'Open')
    );
    // console.log("partiallyClearedInvoices", partiallyClearedInvoices)
    contextVariable.setSelectedInvoices(partiallyClearedInvoices);
    setIsSelectAllVisible(false);
  };

  const handleDeselectAll = () => {
    contextVariable.setSelectedInvoices([]);
    setIsSelectAllVisible(true);
  }

  const [MobilePaymentLoading, setMobilePaymentLoading] = useState(false)
  // Function to handle "Select All" button click
  const handlePayment = async () => {
    // contextVariable.setRootObject(params);
    setMobilePaymentLoading(true)
    // Extract selected Invoice ids 
    const invoiceIds = Array.from(contextVariable.selectedInvoices).map((el) => ({ InvoiceID: el.InvoiceID }));
    //Api for Clearing invoice status
    try {
      const ClearingResponse = await API.graphql({
        query: GetInvoiceClearingStatus,
        variables:
          { input: invoiceIds }
      })
      // console.log("ClearingResponse", ClearingResponse)
      if (ClearingResponse.data.GetInvoiceClearingStatus.count == 0) {
        contextVariable.setShowView((prev) => ({ ...prev, showInvoiceDetails: false, showInvoicPayment: true }));
      }
      else if (ClearingResponse.data.GetInvoiceClearingStatus.count > 0) {
        setUnClearInvoice({ DialogShow: true, Data: ClearingResponse.data.GetInvoiceClearingStatus.items })
      }

      // console.log("ClearingResponse", ClearingResponse)
    } catch (error) {
      console.error("Clearing Invoice API Error", error)
    }
    finally{
      setMobilePaymentLoading(false);
    }

  }

  useEffect(() => {
    sessionStorage.setItem("date", contextVariable.date);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {UnClearInvoice.DialogShow && <DialogOfClearingInvoice
        UnClearInvoice={UnClearInvoice}
        setUnClearInvoice={setUnClearInvoice}
        setSelectedInvoices={setSelectedInvoices}
        isMobile={isMobile}
      />}


      {InvoiceViews === "InvoiceView" && <InvoiceView backToInvoice={closeInvoiceItemView}></InvoiceView>}
      {InvoiceViews === "PaymentView" && <PayComponent selectedInvoices={selectedInvoices} backToInvoiceView={closepaymentView}></PayComponent>}

      {
        (!isMobile &&
          contextVariable.showView.showInvoiceDetails === false &&
          contextVariable.showView.showInvoicPayment === false) &&
        <>
          {InvoiceViews === "OverAllInvoiceView" &&
            <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
              {/* HEADER */}

              {/* DataGrid Card  */}
              <div
                className="card card-shadow"
              >
                <div className="card-body">
                  <GridData
                    rowData={invoiceDetails}
                    columnDefs={contextVariable.taxDetails.StripeDefaultIndicator === "false" ? columnDefs.slice(1) : columnDefs}
                    InvoiceView={openInvoiceItemView}
                    component={'Invoice'}
                    tableHeight={'78vh'}
                    needFilters={true}
                    doPayment={sendData}
                    showPagination={true}
                    selectedRows={selectedRowsHandler}
                    filter={'Invoice'}
                    handlepay={handlepay}
                  />
                </div>
              </div>
            </Box>}
        </>
      }

      {
        (isMobile &&
          contextVariable.showView.showInvoiceDetails === false &&
          contextVariable.showView.showInvoicPayment === false) &&
        <>
          <div className="bg-gray-50">
            <div className="sticky top-0 z-1">
              {/* Topbar */}
              <CardTopbar
                header={`Invoices`}
                buttonNeeded={false}
                refresh={fetchInvoiceDetails}
                loading={loading}
              />
              {/* Dropdown and Search */}
              <div className="bg-gray-50 border-bottom-2 pt-2 pr-2 pl-2 shadow-2">
                <div className="grid">
                  <div className="col-4">
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 w-full mobile-dropdown"
                    />
                  </div>
                  <div className="col-8">
                    <span className="p-input-icon-left w-full">
                      <i className="pt-1 pi pi-search" />
                      <InputText
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by id, status etc..."
                        className="p-inputtext-sm p-2 pl-5 w-full"
                      />
                    </span>
                  </div>
                </div>
                {/* "Select All" and "Payment" buttons */}
                {
                  (invoiceDetails !== 0 && invoiceDetails !== 1) &&
                  <div className='pb-2 sticky top-0 z-1 flex justify-content-end gap-2'>
                    <Button
                      outlined={invoiceDet̥ailsCard.length !== 0 ? false : true}
                      disabled={invoiceDet̥ailsCard.length !== 0 ? false : true}
                      className=""
                      label="Payment"
                      severity="secondary"
                      loading={MobilePaymentLoading}
                      // icon="pi pi-angle-right"
                      // iconPos="right"
                      onClick={handlePayment} />
                    {isSelectAllVisible ? (
                      <Button
                        className=""
                        label="Select All"
                        // icon="pi pi-check-square"
                        // iconPos="right"
                        onClick={handleSelectAll}
                      />
                    ) : (
                      <Button
                        className="secondary-button"
                        label="Deselect All"
                        onClick={handleDeselectAll}
                      />
                    )}
                  </div>
                }
              </div>
            </div>

            <div className="overflow-auto">
              {
                (invoiceDetails !== 0 && invoiceDetails !== 1 && filteredInvoiceDetails.length > 0) &&

                filteredInvoiceDetails
                  .map((filteredEl, index) => (
                    <InvoiceCard
                      key={index}
                      index={index}
                      selectedInvoices={invoiceDet̥ailsCard}
                      invoiceDetails={filteredEl}
                      contextVariable={contextVariable}
                      isChecked={checkboxes[index]}
                      onCheckboxChange={() => handleCheckboxChange(index)}
                    />
                  ))
              }

              {/* Loading skeleton */}
              {
                invoiceDetails === 1 &&
                <div className="flex flex-column">
                  {
                    [...Array(7)].map((_, index) => (
                      (invoiceDetails === 0 || invoiceDetails === 1) &&
                      <SkeletonLoader key={index} />
                    ))
                  }
                </div>
              }

              {/* No data foundd for selected year */}
              {
                (invoiceDetails === 0 && !error) &&
                <>
                  <div className="mt-3 h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                </>
              }

              {/* No data found on search */}
              {
                (invoiceDetails !== 0 && invoiceDetails !== 1) &&
                invoiceDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .length === 0 && (
                  <div className="h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                )
              }
            </div>
          </div>
        </>
      }

      {/* Api error */}
      {
        (error) &&
        <DataNotFound />
      }

      {
        (isMobile &&
          contextVariable.showView.showInvoiceDetails === true &&
          contextVariable.showView.showInvoicPayment === false) &&
        <>
          <div>
            <InvoiceDetailsCard />
          </div>
        </>
      }

      {
        (isMobile &&
          contextVariable.showView.showInvoiceDetails === false &&
          contextVariable.showView.showInvoicPayment === true) &&
        <>
          <div>
            <InvoicePaymentCard />
          </div>
        </>
      }
    </>
  );
};

export default Invoice;

