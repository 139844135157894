import { Badge, Box, IconButton, Menu, Tooltip, Zoom, useMediaQuery } from "@mui/material";
import './Topbar.css';
import {
  AccountBalanceOutlined, CloseOutlined, ContentCopyOutlined,
  Info,
  InventoryOutlined, LaunchOutlined, LocalShippingOutlined, LogoutOutlined,
  MenuOpenOutlined, NotificationsActive, NotificationsOutlined, PersonOutlineOutlined,
  PriceCheckOutlined, ReceiptLongOutlined, RequestQuoteOutlined, SettingsSuggestOutlined,
  ShoppingCart, ShoppingCartOutlined, WarningAmber
} from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../Layout/Layout";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import { CreateNotify } from "../../Layout/MutaionsAndQueries";
import { Divider } from "@aws-amplify/ui-react";
import { BatchDeleteNotifications, deleteNotification } from "../../graphql/mutations";
import { showToast } from "../../components/ToastUtils";
import NotificationImage from "./NotificationEmpty.png";
import EmptyCart from "../../components/EmptyCart";
import { Avatar } from "primereact/avatar";
import { handleShowNotification } from "../../components/Notification/NotificationComponent";
import copyToClipboard from "../../components/copyToClipboard";
import ProCustomer from "../../logo/ProCustomerPNG.png";
import { OverlayPanel } from "primereact/overlaypanel";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { getNotificationDescription } from "../../components/Notification/NotificationDescription";

const Topbar = ({ cartCount }) => {
  const contextVariable = useContext(AppContext);
  const { t } = useTranslation();
  const [title, setTitle] = useState(sessionStorage.getItem("title"));
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [anchorElCart, setAnchorElCart] = useState(null);
  const [UserName, setUserName] = useState("");
  const [vibrate, setVibrate] = useState(false);
  const [logoImage, setLogoImage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTab = useMediaQuery("(max-width: 768px)");
  const responsiveText = 'text-xs md:text-sm lg:text-base';
  // const [isRTL, setIsRTL] = useState(false);

  // const handleToggleRtl = () => {
  //   contextVariable.setRtl(!contextVariable.rtl);
  //   setIsRTL(!isRTL);
  // };

  useEffect(() => {
    const getLogo = async () => {

      const user = await Auth.currentAuthenticatedUser();
      try {
        if (user && user.signInUserSession && user.signInUserSession.idToken) {
          // console.log("user name pur", user)
          setUserName(user.signInUserSession.idToken.payload.name)
        }

        if (contextVariable.taxDetails && contextVariable.taxDetails.Logo) {
          const imageKey = contextVariable.taxDetails.Logo.split("public/")[1];
          const authorizedUrl = await Storage.get(imageKey);
          setLogoImage(authorizedUrl);
          sessionStorage.setItem("ogol", authorizedUrl)

          const img = new Image();
          img.src = authorizedUrl;
          // img.onload = () => {
          //   const aspectRatio = img.width / img.height;
          //   const width = aspectRatio === 1 ? '35px' : '100px';
          // };
        }
      } catch (error) {
        window.location.reload()
      }
    };
    if (contextVariable.taxDetails) {
      getLogo();
    }

  }, [contextVariable.taxDetails]);

  // Webview data transfer
  const sendMessageToNative = async (message) => {

    const NotifyObject = {
      Message: "NotificationMsg",
      data: message
    }

    // console.log("message", NotifyObject);
    if (window.ReactNativeWebView) {
      await window.ReactNativeWebView.postMessage(JSON.stringify(NotifyObject));
    } else {
      console.log("window.ReactNativeWebView is not available. Cannot send message to native app.");
    }
  }

  useEffect(() => {
    // getLogo();
    const filter = {
      filter: {
        CustomerId: { eq: contextVariable.userDetails["custom:contactperson_UUID"] },
        NotificationStatus: { eq: 'Unread' }
      }
    }

    const subscription = API.graphql(
      graphqlOperation(CreateNotify, filter)
    ).subscribe({
      next: ({ value }) => {

        const CreatedNotification = value.data.onCreateNotification;
        // console.log("CreatedNotification", CreatedNotification)
        const SplitnotificationArray = JSON.parse(CreatedNotification.NotificationData);
        const updatedData = [CreatedNotification, ...contextVariable.NotificationMsg];
        // Desktop notification
        handleShowNotification(CreatedNotification);

        // Webview data transfer call
        sendMessageToNative(JSON.stringify(CreatedNotification));


        if (CreatedNotification.NotificationReasonCode === "SO_C") {
          if (SplitnotificationArray && SplitnotificationArray[0] && SplitnotificationArray[0].PlaceOrderStatus === "Ordered") {
            showToast(`${t(`Your order request submitted with Order ID:`)} ${SplitnotificationArray[0].SalesOrderId}`, "success")
            contextVariable.setNotificationMsg(updatedData);

            //Same user only clear Usercarddetails otherwise no one  clear
            // if ( SplitnotificationArray[0].UserCartTableID === contextVariable.UserCartIdDetails[0].id) {
            contextVariable.setUserCartIdDetails([]);
            contextVariable.setUserCartItems([]);
            contextVariable.setCartCount(0);
            contextVariable.setCartItemsIds([]);
            contextVariable.setCartStatusView("OrderRequestPlaced");
            contextVariable.setSalesorderIdwithPlaced(SplitnotificationArray[0].SalesOrderId)
            // }
          }
        }
        else if (CreatedNotification.NotificationReasonCode === "PS_S") {
          contextVariable.setShow("PaymentSuccess");
          contextVariable.setNotificationMsg(updatedData);
        }
        else if (CreatedNotification.NotificationReasonCode === "BPA_C") {
          contextVariable.setShow("PaymentAdvice Updated");
          contextVariable.setNotificationMsg(updatedData);
        }
        else if (CreatedNotification.NotificationReasonCode === "BPA_C_E") {
          contextVariable.setShow("PaymentAdviceCreationError");
          contextVariable.setNotificationMsg(updatedData);
        }
        else if (CreatedNotification.NotificationReasonCode === "BPA_F") {
          contextVariable.setNotificationMsg(updatedData);
        }
        else if (CreatedNotification.NotificationReasonCode === "SO_F") {
          showToast(`${t(CreatedNotification.NotificationReasonCode)}`, "error")

          contextVariable.setUserCartIdDetails((prev) => prev.map((P) => {
            return { ...P, CartStatus: "Open", PlaceOrderStatus: "Open" }
          }))
          contextVariable.setCartStatusView("ProductsInTheCart");
          contextVariable.setCartCount(contextVariable.UserCartItems.length);
          contextVariable.setNotificationMsg(updatedData);
        }
        else {
          contextVariable.setNotificationMsg(updatedData);
        }
      },
      error: (error) => {
        console.error('Subscription Error:', error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };

  });

  useEffect(() => {
    if (cartCount !== -1) {
      setVibrate(true);

      const timeoutId = setTimeout(() => {
        setVibrate(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [cartCount]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey && (event.key === 'n' || event.key === 'N')) {
        handleMenuNotification(event);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleMenuNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleMenuCart = (event) => {
    setAnchorElCart(event.currentTarget);
  };


  const handleCloseNotification = () => {
    setAnchorElNotification(null);
    setAnchorElCart(null);
  }

  const handleMarkAllAsRead = async () => {
    setButtonLoading(true);
    const UpdateAllNotifications = contextVariable.NotificationMsg.map((p) => ({
      id: p.id,
    }));

    try {
      contextVariable.setNotificationMsg([]);
      const response = await API.graphql(
        {
          query: BatchDeleteNotifications,
          variables:
          {
            input: UpdateAllNotifications
          }
        }
      )
      if (response.data.BatchDeleteNotifications && response) {
        setAnchorElNotification(null);
        setButtonLoading(false);
      }

    }
    catch (error) {
      contextVariable.setNotificationMsg([]);
      setButtonLoading(false);
      // console.error("Notification Mark All as Read error", error)
    }

  };

  const handleMenuProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  useEffect(() => {

    const interval = setInterval(() => {
      const storedTitle = sessionStorage.getItem("title");
      if (storedTitle !== title) {
        setTitle(storedTitle);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [title]);

  const handleToDeleteReadFile = async (Notify) => {

    try {
      const DeleteData = {
        id: Notify.id,
      }
      await API.graphql({
        query: deleteNotification,
        variables:
        {
          input: DeleteData
        }
      })
      const updateNotificationdata = contextVariable.NotificationMsg.filter((p) => p.id !== Notify.id);
      contextVariable.setNotificationMsg(updateNotificationdata);
      // showToast("Notification deleted");
    }
    catch (error) {
      console.error("Notification Double Click error", error)
    }
  }




  const calculateTimeDifference = (current, initial) => {
    const timeDifference = (current - initial) / 1000; // Convert to seconds

    if (timeDifference >= 3600) {
      const hours = Math.floor(timeDifference / 3600);
      const remainingSeconds = timeDifference % 3600;
      if (remainingSeconds >= 60) {
        const minutes = Math.floor(remainingSeconds / 60);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      }
    } else if (timeDifference >= 60) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      const seconds = Math.floor(timeDifference);
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
  };

  const renderDropDown = () => {

    return (
      <>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElProfile}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElProfile)}
          onClose={handleCloseProfile}
        >
          <div className="p-3">

            <div className="text-center text-lg text-slate-300">
              {/* <MenuItem disabled> */}
              {t('Profile')}
              {/* </MenuItem> */}
            </div>

            <Divider />
            <div className="text-center p-3 w-15rem">
              <div className="mt-3">
                <Avatar
                  style={{ borderRadius: '50%', background: "var(--sidebar-color)", color: "white" }}
                  className="font-semibold"
                  label={UserName ? UserName.charAt(0).toUpperCase() : ""}
                  // icon="pi pi-user"
                  size="xlarge"
                  shape="circle"
                />
              </div>
              <div className="mt-3">
                <span className="text-base font-semibold">
                  {t('Hi,')} {UserName ? UserName.charAt(0).toUpperCase() + UserName.slice(1).toLowerCase() : ""}  👋
                </span>
              </div>
              <div>
                <span className="text-sm text-gray-400 font-semibold">
                  {contextVariable.userDetails["custom:customer_id"]}
                </span>
                <IconButton
                  onClick={() => handleCopyToClipboard(contextVariable.userDetails["custom:customer_id"])}
                >
                  <ContentCopyOutlined fontSize="small" />
                </IconButton>
              </div>
            </div>
            <div
              onClick={() => {
                contextVariable.setShow("Logout");
                handleCloseProfile();
              }}
              style={{ background: "var(--sidebar-color)" }}
              className="mt-3 border-round-md cursor-pointer p-2 flex justify-content-center gap-2">
              <LogoutOutlined className="text-white" />
              <span className="text-white font-semibold">{t('Logout')}</span>
            </div>
          </div>
        </Menu>

        {contextVariable.CartStatusView !== "OrderRequestPlaced" &&
          <Menu
            id="cart-appbar"
            anchorEl={anchorElCart}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElCart)}
            onClose={handleCloseNotification}
            className="notification"
            PaperProps={{ style: { width: '300px' } }}
          >

            <div className="text-center text-lg text-slate-300 font-semibold">
              {/* <MenuItem disabled> */}
              {t('Cart')}
              {/* </MenuItem> */}
            </div>
            <EmptyCart />
          </Menu>}

        <Menu
          id="menu-appbar"
          anchorEl={anchorElNotification}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNotification)}
          onClose={handleCloseNotification}
          className="notification"
          PaperProps={{ style: { width: '400px' } }}
        >

          <div className="text-center text-lg text-slate-300 font-semibold z-5" style={{ position: 'sticky', top: 0, background: "white" }}>
            {t('Notification')}
          </div>

          {
            (contextVariable.NotificationMsg.length > 0) &&

            <div>
              {buttonLoading === false &&
                <div
                  className="text-center px-4 py-2 hover:cursor-pointer m-2 border-round-md"
                  style={{ background: 'var(--primary-color)', cursor: 'pointer' }}
                  onClick={handleMarkAllAsRead}
                >
                  <span className="font-semibold text-white border-rounded">
                    Mark all as read
                  </span>
                </div>}

              {
                buttonLoading === true &&
                <div className="flex justify-content-center mt-2">
                  <Loader />
                </div>
              }
            </div>
          }


          {(contextVariable.NotificationMsg.length === 0) &&
            <div>
              <div className="flex justify-content-center">
                <img style={{ height: '30vh' }} src={NotificationImage} alt="notifyImage" />

              </div>
              <span className="flex justify-content-center font-semibold text-sm">There are no notifications to display at the moment.</span>
            </div>}

          {
            contextVariable.NotificationMsg.length > 0 && contextVariable.NotificationMsg.map((Notify, index) => {
              const initialDate = new Date(Notify.updatedAt);
              const currentDate = new Date();
              const timeDifference = (currentDate - initialDate) / 1000;
              const timeDifferenceText =
                timeDifference < 60
                  ? "Just now"
                  : timeDifference >= 86400
                    ? `${initialDate.toLocaleString()}`
                    : calculateTimeDifference(currentDate, initialDate);
              let iconComponent;
              let iconColor;
              switch (Notify.NotificationReasonCode) {
                case "SO_C":
                  iconComponent = <InventoryOutlined />;
                  break;
                case "SO_S":
                  iconComponent = <LocalShippingOutlined />;
                  break;
                case "SO_P":
                  iconComponent = <SettingsSuggestOutlined />;
                  break;
                  case "SO_P_All":
                  iconComponent = <SettingsSuggestOutlined />;
                  break;
                  case "SO_P_Few":
                  iconComponent = <SettingsSuggestOutlined />;
                  break;
                case "SO_R":
                  iconComponent = <LaunchOutlined />;
                  break;
                case "PS_S":
                  iconComponent = <PriceCheckOutlined />;
                  break;
                case "BPA_C":
                  iconComponent = <AccountBalanceOutlined />;
                  break;
                case "SO_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                case "I_C":
                  iconComponent = <RequestQuoteOutlined />
                  break;
                case "C_C":
                  iconComponent = <ReceiptLongOutlined />;
                  break;
                case "PS_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                case "BPA_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                default:
                  iconComponent = <Info />;
              }

              if (Notify.NotificationReasonCode === "SO_F" ||
                Notify.NotificationReasonCode === "PS_F") {
                iconColor = "red-600"
              }
              else if (Notify.NotificationReasonCode === "PS_S") {
                iconColor = "primary-500";
              }
              else {
                iconColor = "cyan-500"
              }
              return (
                <div
                  // onDoubleClick={() => handleNavigate(Notify)}
                  style={{
                    backgroundColor: Notify.NotificationStatus === "Unread" ? 'white' : "rgba(229, 229, 229, 0.792)",
                  }}
                  className="mt-1 mb-1"
                  key={index}
                >
                  <div className="text-left p-2 hover:bg-pink-50 hover:cursor-pointer">
                    <div className="flex justify-content-center align-items-center gap-2">

                      {iconComponent &&
                        <>
                          {/* <div className={`text-${iconColor} border-${iconColor} border-3  p-2 border-circle`}> */}
                          <div className={`bg-${iconColor} text-white p-2 border-circle`}>
                            {iconComponent}
                          </div>
                        </>
                      }
                      <div className="text-xs font-medium flex-1"> {Notify.NotificationReasonCode && showNotificationDescription(Notify)}</div>
                    </div>
                    <p className="text-xs" style={{ display: "flex", justifyContent: "space-between", color: "gray", alignItems: "center" }}>
                      {timeDifferenceText}
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'var(--sidebar-color)',
                              fontSize: '12px'
                            },
                          },
                        }}
                        title="Delete"
                        placement="bottom"
                        TransitionComponent={Zoom}
                        enterDelay={500}
                      >
                        {/* <button className="border-2 p-1 border-circle border-cyan-500 text-cyan-500" onClick={() => handleToDeleteReadFile(Notify)}> */}
                        <button className="p-1 text-cyan-500" onClick={() => handleToDeleteReadFile(Notify)}>
                          <CloseOutlined fontSize="small" />
                        </button>
                      </Tooltip>
                    </p>
                  </div>
                  <Divider />
                </div>
              );
            })
          }

        </Menu>

        {/* icon buttons for open Menu items */}
        <img
          className="d-none d-md-block"
          src={ProCustomer}
          alt="logo"
          style={{ height: '40px', opacity: "60%" }}
        />

        {contextVariable.CartStatusView === "EmptyCart" &&
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'var(--sidebar-color)',
                  fontSize: '12px'
                },
              },
            }}
            title={t('Cart')}
            placement="bottom"
            TransitionComponent={Zoom}
            enterDelay={500}
          >
            <IconButton onClick={handleMenuCart}>
              <Badge className={vibrate ? 'vibration' : ''}
                // badgeContent={cartCount}
                color="primary">
                <ShoppingCartOutlined
                  className="text-base md:text-xl"
                />
              </Badge>
            </IconButton>
          </Tooltip>
        }

        {contextVariable.CartStatusView !== "EmptyCart" &&
          <Link to={'/productCatalog/placeOrder'}>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'var(--sidebar-color)',
                    fontSize: '12px'
                  },
                },
              }}
              title={t('Cart')}
              placement="bottom"
              TransitionComponent={Zoom}
              enterDelay={500}
            >
              <IconButton >
                <Badge
                  className={vibrate ? 'vibration' : ''}
                  badgeContent={cartCount}
                  color="primary">
                  {
                    cartCount !== 0 ?
                      <ShoppingCart
                        style={{ color: "var(--sidebar-color)" }}
                        className="text-base md:text-2xl"
                      />
                      :
                      <ShoppingCartOutlined
                        style={{ color: "var(--sidebar-color)" }}
                        className="text-base md:text-xl"
                      />
                  }
                </Badge>
              </IconButton>
            </Tooltip>
          </Link>}

        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'var(--sidebar-color)',
                fontSize: '12px'
              },
            },
          }}
          title={t('Notification')}
          placement="bottom"
          TransitionComponent={Zoom}
          enterDelay={500}
        >
          <IconButton
            onClick={handleMenuNotification}
          >
            <Badge color="primary"
              badgeContent={contextVariable.NotificationMsg.length}
            >
              {
                contextVariable.NotificationMsg.length !== 0 ?
                  <NotificationsActive
                    style={{ color: "var(--sidebar-color)" }}
                    className="swing-animation text-base md:text-2xl"
                  />
                  :
                  <NotificationsOutlined
                    style={{ color: "var(--sidebar-color)" }}
                    className='text-base md:text-xl'
                  />
              }
            </Badge>
          </IconButton>
        </Tooltip>

        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'var(--sidebar-color)',
                fontSize: '12px'
              },
            },
          }}
          title={t('Profile')}
          placement="bottom"
          TransitionComponent={Zoom}
          enterDelay={500}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuProfile}
          >
            <PersonOutlineOutlined
              style={{ color: "var(--sidebar-color)" }}
              className="text-base md:text-xl" />
          </IconButton>
        </Tooltip>

        {/* Toggle RTL */}
        {/* {
          contextVariable.rtl ?
            <>
              <IconButton
                onClick={handleToggleRtl}
              >
                <ToggleOn
                  style={{ color: "var(--sidebar-color)" }}
                  className='text-base md:text-xl'
                />
              </IconButton>
            </>
            :
            <>
              <IconButton
                onClick={handleToggleRtl}
              >
                <ToggleOffOutlined
                  style={{ color: "var(--sidebar-color)" }}
                  className='text-base md:text-xl'
                />
              </IconButton>
            </>
        } */}
      </>
    );
  };

  const handleCopyToClipboard = (data) => {
    copyToClipboard(data);
    showToast(t(`Copied to clipboard`), "info");
  }
  const op = useRef(null);

  const showNotificationDescription = (notifyData) => {
    let notifyDetails;
    const notifyCode = notifyData.NotificationReasonCode;
    const parsedNotificationData = JSON.parse(notifyData.NotificationData);

    // console.log("parsedNotificationData", notifyCode, parsedNotificationData);

    switch (notifyCode) {
      // Saleorders Notifications
      case 'SO_C':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, referenceID: parsedNotificationData[0].POReferanceID });
        return notifyDetails.description;

      case 'SO_P':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
        return notifyDetails.description;

      case 'SO_P_All':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
        return notifyDetails.description;

      case 'SO_P_Few':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
        return notifyDetails.description;

      case 'SO_R':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
        return notifyDetails.description;

      case 'SO_S':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });
        return notifyDetails.description;

      case 'SO_S_I':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });
        return notifyDetails.description;

      // Invoice Notifications
      case 'I_I':
        notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].OrderID });
        return notifyDetails.description;

      case 'I_C':
        notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceId, orderID: parsedNotificationData[0].OrderID });
        return notifyDetails.description;

      // Creditmemo Notifications
      case 'C_C':
        notifyDetails = getNotificationDescription(notifyCode, { creditMemoID: parsedNotificationData[0].CreditmemoID });
        return notifyDetails.description;

      case 'C_I':
        notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceID });
        return notifyDetails.description;

      // Payment Advice Notifications
      case 'PS_S':
        notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });
        return notifyDetails.description;

      case 'PS_F':
        notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });
        return notifyDetails.description;

      case 'BPA_C':
        notifyDetails = getNotificationDescription(notifyCode, { bankPaymentID: parsedNotificationData[0].BankPaymentId });
        return notifyDetails.description;

      default:
        notifyDetails = getNotificationDescription(notifyCode);
        return notifyDetails.description;

    }
  }




  // const showt = () => {
  //   const notifyDetails = getNotificationDescription("SO_R");
  //   let ex = t(notifyDetails.description, { orderID: 7899 });
  //   console.log("12(notifyDetails.description)", ex)
  // }

  return (
    <>
      <Box
        className="fixed md:fixed lg:static topbar p-1">

        {/* <div className={isRTL ? RTLClassname : LTRClassname}> */}
        <div className='flex justify-content-between align-items-center'>
          {/* Open menu icon */}
          <div className="flex">
            <OverlayPanel ref={op} className='w-12rem border-round-xl'>
              <div className="text-center">
                <img src={logoImage} alt="logo" />
                <span className={`${responsiveText} font-semibold`}>
                  {contextVariable.taxDetails?.CompanyName}
                </span>
              </div>
            </OverlayPanel>

            {
              (isMobile || isTab) &&
              <>
                <div>
                  <IconButton type="button"
                    onClick={() => contextVariable.setIsSidebarOpen(!contextVariable.isSidebarOpen)}
                  >
                    <MenuOpenOutlined className="rotate-180" />
                  </IconButton>
                </div>
              </>
            }
            {
              logoImage &&
              <div className="flex gap-2">
                <img
                  className="cursor-pointer "
                  onClick={(e) => op.current.toggle(e)}
                  // src={ProCustomer}
                  src={logoImage}
                  alt="logo"
                  style={{ height: '40px', marginLeft: "0.3rem" }}
                />
                <span className="pt-3 font-semibold opacity-75 d-none d-md-block" style={{ fontSize: "10px", fontStyle: "oblique 2deg" }}>
                  {contextVariable.taxDetails?.CompanyName}</span>
              </div>
            }
          </div>

          <div className="Page_header flex">
            <span className="text-sm md:text-lg font-semibold">
              {title}
            </span>
          </div>

          {/* <button onClick={showt}>Test</button> */}

          {/* <div style={isMobile ? { marginRight: '3rem' } : { marginRight: '2rem' }}> */}
          <div className="topbar-items flex justify-content-center align-items-center">
            {renderDropDown()}
          </div>
        </div>

      </Box>
    </>
  );
};

export default Topbar;
