import React from 'react'
import { showToast } from '../../ToastUtils';
import GetOrderPDF from '../../../Pages/order/GetOrderPDF';
import { SimCardDownload } from '@mui/icons-material';
import './CreditNoteCard.css';
import getSymbolFromCurrency from 'currency-symbol-map';
import CurrencyFormat from '../../CurrencyFormat';
import { useTranslation } from 'react-i18next';

const CreditNoteCard = ({ index, creditNoteDetails, contextVariable }) => {
    // console.log("CreditNoteCard", creditNoteDetails);
    const { t } = useTranslation();
    let data = creditNoteDetails;

    const handlePreviewClick = (uuid, orderID) => {
        GetOrderPDF("creditNote", uuid, orderID);
        showToast(t(`Started downloading...`), "info", 1000);
    }

    const handleCardClick = (params) => {
        contextVariable.setScrollPosition(window.scrollY);
        contextVariable.setRootObject(params);
        contextVariable.setShowView((prev) => ({ ...prev, showCreditNoteDetails: true }));
        // navigate('/creditNoteDetails');
    }

    return (
        <div
            key={index}
            style={{ fontFamily: "helvetica", marginTop: "0" }}
            className='card m-2 bg-white'>
            <div className='credit-container'>

                {/* PDF container */}
                {
                    (data.PDFUUID).trim() !== '' ?
                        <div
                            onClick={() => handlePreviewClick(data.PDFUUID, data.CreditNoteID)}
                            style={{ background: "#f8f8f8" }}
                            className='p-3 flex flex-column justify-content-center align-items-center'>
                            <div>
                                <SimCardDownload
                                    style={{ fontSize: "2rem", color: "#ff6c6c" }} />
                            </div>
                            <div>
                                <span className='font-10'>PDF</span>
                            </div>
                        </div>
                        :
                        <div
                            style={{ background: "#f8f8f8" }}
                            className='p-3 flex flex-column justify-content-center align-items-center'>
                            <div>
                                <span className='text-sm' style={{ color: "#c5c5c5" }}>NO <br />PDF</span>
                            </div>
                        </div>
                }

                {/* Reference, status and credit note id */}
                <div
                    onClick={() => handleCardClick(data)}
                    className='bg-white pl-2 flex flex-column justify-content-center align-items-left'
                    style={{ gap: "6px" }}
                >
                    <div>
                        <span className='text-base'>#{" "}{data.ExternalReferenceID}</span>
                    </div>
                    {/* Status and credit note id */}
                    <div className='flex gap-5'>
                        <div className='text-green-600 text-xs'>
                            {data.ReleaseStatusCodeText}
                        </div>
                        <div className='text-xs value-color'>
                            {data.CreditNoteID}
                        </div>
                    </div>
                </div>

                {/* Date and total amount */}
                <div
                    onClick={() => handleCardClick(data)}
                    className='bg-white flex flex-column justify-content-center' style={{ alignItems: 'flex-end', gap: "6px" }}>
                    <div>
                        <span className='text-xs value-color'>{data.CreditNoteDate}</span>
                    </div>
                    <div>
                        <span className='text-base'>
                            {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(data.GrossAmount)}
                            {/* {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(data.GrossAmount)).toFixed(2)} */}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditNoteCard