export const listProductsLists = /* GraphQL */ `
  query ListProductsLists(
    $filter: ModelProductsListFilterInput
    $discountFilter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductImage
        ProductStatus
        ProductDetailDescription
        ProductActiveStatus
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        
        ProductsDiscounts(filter: $discountFilter) {
          items {
            Customer
            ProductDiscount
            productID
            id
          }
        }
        UserFavouriteProducts {
          items {
            FavouriteStatus
            id
            owner
            productID
          }
        }
      
      }
      nextToken
      __typename
    }
  }
`;
export const customerSpecificPricesByCustomerUUID = /* GraphQL */ `
  query CustomerSpecificPricesByCustomerUUID(
    $CustomerUUID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSpecificPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSpecificPricesByCustomerUUID(
      CustomerUUID: $CustomerUUID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        Customer
        CustomerUUID
        productID
        tenant
        
      }
      nextToken
    }
  }
`;
export const productsListsByTenant = /* GraphQL */ `
  query ProductsListsByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
    $customerUUID: String
  ) {
    productsListsByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductImage
        ProductStatus
        ProductActiveStatus
        ProductDetailDescription
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        ProductsDiscounts(filter: {
        CustomerUUID: { eq: $customerUUID } 
      }) {
          items {
            Customer
            CustomerUUID
            ProductDiscount
            productID
            id
          }
        }
        UserFavouriteProducts {
          items {
            FavouriteStatus
            id
            owner
            productID
          }
        }
      
      }
      nextToken
      __typename
    }
  }
`;
export const AdminlistProductsLists = /* GraphQL */ `
 query ListProductsLists(
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductDetailDescription
        ProductCategoryName
        ProductImage
        ProductStatus
        tenant
        ProductActiveStatus
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        ProductsDiscounts {
        items {
          ProductID
        ProductDiscount
        Customer
        CustomerUUID
        productID
        tenant
          id
        }}
        
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserProductsCarts = /* GraphQL */ `
  query ListUserProductsCarts(
    $filter: ModelUserProductsCartFilterInput
    $discountFilter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProductsCarts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ProductDiscount
        ProductPrice
        ProductQuantity
        carttableID
        id
        owner
        userProductsCartProductdetailsId
        Productdetails {
          ProductCategoryId
          ProductCategoryName
          ProductId
          ProductImage
          ProductName
          ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
          ProductStatus
          ProductUOM
          ProductUOMText
          ProductsDiscounts(filter: $discountFilter) {
            items {
              Customer
              CustomerUUID
              ProductDiscount
              id
              productID
            }
          }
          UserFavouriteProducts {
            items {
              FavouriteStatus
              id
              owner
              productID
            }
          }
          id
        }
      }
      nextToken
      __typename
    }
  }
`;
export const ListByCustomerSalesBlockDetails = /* GraphQL */ `
  query ListByCustomerSalesBlockDetails(
    $sortField: String
    $sortOrder: SortOrder
  ) {
    ListByCustomerSalesBlockDetails(
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      items {
        SaleOrderBlockedIndicator
        DeliveryBlockedIndicator
        InvoiceBlockedIndicator
        __typename
      }
      count
      __typename
    }
  }
`;

export const getByCustomerSalesOrderDetail = /* GraphQL */ `
  query GetByCustomerSalesOrderDetail($OrderID: ID!) {
    GetByCustomerSalesOrderDetail(OrderID: $OrderID) {
      items {
        CustomerID
        DeliveryStatus
        DeliveryStatusCode
        RequestedDate
        CreatedDate
        RequestedDate
        ExternalReference
        GrossAmount
        IncotermsLocation
        IncotermsCode
        Incoterms
        GrossAmountCurrencyCode
        GrossAmountIndicator
        InvoiceStatus
        InvoiceStatusCode
        NetAmount
        PostingDate
        TaxAmount
        TaxAmountCurrencyCode
        UUID
        OrderStatusCode
        OrderStatus
        ObjectID
        OrderID
        NetAmountCurrencyCode
        CustomerAddress
        CustomerName
        PaymentTermText
        PaymentTerm
        Note
        ContactPersonID
        ContactPersonName
        SalesOrderItem {
        DeliveryDate
        Discount
        LineItem
        ObjectID
        ProductDescription
        ProductID
        Quantity
        QuantityUOM
        QuantityUOMText
        TaxRate
        UnitPrice
        UnitPriceCode
        UnitPriceCodeText
        NetValue
        NetValueUnitCode
        NetValueUnitCodeText
        NetPrice
        NetPriceCode
        NetPriceCodeText
      }
      }
      count
    }
  }
`;
export const listByCustomerSalesOrderDetails = /* GraphQL */ `
query ListByCustomerSalesOrderDetails(
  $year: String!
  $limit: Int!
  $skip: Int!
  $sortOrder: String!
  $sortField: String!
) {
  ListByCustomerSalesOrderDetails(
    year: $year
    limit: $limit
    skip: $skip
    sortOrder: $sortOrder
    sortField: $sortField
  ) {
      items {
        CreatedDate
        CustomerID
        DeliveryStatus
        DeliveryStatusCode
        NetAmountCurrencyCode
        ObjectID
        NetAmount
        InvoiceStatusCode
        InvoiceStatus
        IncotermsLocation
        IncotermsCode
        Incoterms
        TotalAmount
        GrossAmountCurrencyCode
        GrossAmount
        ExternalReference
        OrderID
        OrderStatus
        OrderStatusCode
        PDFUUID
        PostingDate
        UUID
        TaxAmount
        TaxAmountCurrencyCode
        SalesOrderItem {
          LineItem
          ObjectID
          ProductID
          ProductDescription
        }
      }
      count
      year
    }
  }
`;
export const ListByCustomerAddressList = /* GraphQL */ `
  query ListByCustomerAddressList($sortField: String, $sortOrder: SortOrder) {
    ListByCustomerAddressList(sortField: $sortField, sortOrder: $sortOrder) {
      items {
        RootUUID
        RootObjectID
        LifeCycleStatusCodeText
        LifeCycleStatusCode
        AccountName
        AccountID
        CustomerAddressInformation {
          AdditionalStreetPrefixName
          AdditionalStreetSuffixName
          AddressSnapshot_ObjectID
          CityName
          ConventionalPhone
          CountryCode
          CountryCodeText
          PostalAddress_ObjectID
          MobilePhone
          HouseID
          FormattedAddress_ObjectID
          DistrictName
          DefaultAddress
          CustomerAddressInformation_UUID
          CustomerAddressInformation_ObjectID
          Email
          FormattedAddress
          RegionCode
          RegionCodeText
          StreetSuffixName
          StreetPrefixName
          StreetPostalCode
          StreetName
        }
      }
      count
    }
  }
`;
export const listByCustomerOutboundDeliveryDetails = /* GraphQL */ `
query ListByCustomerOutboundDeliveryDetails(
  $year: String!
  $limit: Int!
  $skip: Int!
  $sortOrder: String!
  $sortField: String!
) {
  ListByCustomerOutboundDeliveryDetails(
    year: $year
    limit: $limit
    skip: $skip
    sortOrder: $sortOrder
    sortField: $sortField
  ) {
      items {
        DeliveryDate
        DeliveryID
        FreightForwarder
        FreightForwarderID
        GrossWeight
        GrossWeightUnitCode
        GrossWeightUnitCodeText
        ObjectID
        PDFUUID
        ReleaseStatus
        ReleaseStatusText
        ShipmentDate
        TrackingID
        TrackingURI
        UUID
        OutboundDeliveryItem {
          LineItem
          ObjectID
          ProductID
          SalesOrderID
        }
      }
      count
      year
    }
  }
`;
export const getByCustomerOutboundDeliveryDetail = /* GraphQL */ `
  query GetByCustomerOutboundDeliveryDetail($DeliveryID: ID!) {
    GetByCustomerOutboundDeliveryDetail(DeliveryID: $DeliveryID) {
      items {
        CustomerID
        CustomerName
        FreightForwarder
        FreightForwarderID
        GrossWeight
        Incoterms
        IncotermsText
        GrossWeightUnitCode
        GrossWeightUnitCodeText
        ObjectID
        PDFUUID
        ShippingAddress
        TrackingID
        TrackingURI
        UUID
        ReleaseStatus
        ReleaseStatusText
        OutboundDeliveryItem {
          BatchID
          DeliveryDate
          ExternalReferenceNO
          ItemObjectID
          LineItem
          OrderNO
          ProductDescription
          ProductID
          Quantity
          QuantityUOM
          QuantityUOMText
          SalesOrderID
          SalesOrderItemID
        }
      }
      count
    }
  }
`;
export const getByCustomerInvoiceDetail = /* GraphQL */ `
  query GetByCustomerInvoiceDetail($InvoiceID: ID!) {
    GetByCustomerInvoiceDetail(InvoiceID: $InvoiceID) {
      count
      items {
        CustomerName
        ExternalReference
        Incoterms
        IncotermsText
        InvoiceID
        Notes
        ObjectID
        PDFUUID
        GrossAmountIndicator
        PaymentTerms
        PaymentTermsText
        CustomerID
        CustomerAddress
        CustomerInvoiceItem {
        Discount
        LineItem
        ObjectID
        ProductID
        Quantity
        QuantityUOM
        QuantityUOMText
        TaxRate
        UnitPrice
        UnitPriceUnitCode
        UnitPriceUnitCodeText
        NetValue
        NetValueUnitCode
        NetValueUnitCodeText
        POReference
        ProductDescription
        SalesOrderReference
        NetPrice
        NetPriceCode
        NetPriceCodeText
      }
      }
    }
  }
`;
export const getByCustomerCreditNotes = /* GraphQL */ `
  query GetByCustomerCreditNotes($InvoiceID: ID!) {
    GetByCustomerCreditNotes(InvoiceID: $InvoiceID) {
      count
      items {
        ObjectID
        ExternalReference
        CreditID
        CustomerID
        CustomerName
        CustomerAddress
        PaymentTerms
        PaymentTermsText
        Incoterms
        IncotermsText
        GrossAmountIndicator
        ReleaseStatusCode
        ReleaseStatusCodeText
        Notes
        NetAmount
        GrossAmount
        GrossAmountCurrencyCode
        GrossAmountCurrencyCodeText
        PDFUUID
        CustomerCreditNotesItem {
        Discount
        LineItem
        UnitPrice
  UnitPriceUnitCode
  UnitPriceUnitCodeText
        ObjectID
        ProductDescription
        ProductID
        Quantity
        QuantityUOM
        QuantityUOMText
        TaxRate
        NetValue
        NetValueUnitCode
        NetValueUnitCodeText
        NetPrice
        NetPriceCode
        NetPriceCodeText
      }
      }
    }
  }
`;
export const userProductsCartsByCarttableID = /* GraphQL */ `
  query UserProductsCartsByCarttableID(
    $carttableID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProductsCartFilterInput
    $discountFilter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProductsCartsByCarttableID(
      carttableID: $carttableID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Productdetails {
          ProductsDiscounts(filter: $discountFilter) {
            items {
              Customer
              CustomerUUID
              ProductDiscount
              id
              productID
            }
          }
          ProductCategoryId
          ProductCategoryName
          ProductId
          ProductImage
          ProductName
          ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
          ProductStatus
          ProductUOM
          ProductUOMText
          id
        }
        ProductDiscount
        ProductPrice
        ProductQuantity
        ProductQuantityText
        carttableID
        id
        owner
        userProductsCartProductdetailsId
      }
      nextToken
      __typename
    }
  }
`;
export const userCartSubscription = /* GraphQL */ `
  subscription OnUpdateUserCart($filter: ModelSubscriptionUserCartFilterInput) {
    onUpdateUserCart(filter: $filter) {
      CartId
      CartStatus
      id
      SalesOrderId
    }
  }
`;
export const CreateNotify = /* GraphQL */ `
subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput 
) {
  onCreateNotification(filter: $filter) {
    id
    CustomerId
    MessageText
    NotificationStatus
    createdAt
    NotificationReason
    NotificationReasonCode
    NotificationData
    updatedAt
    __typename
  }
}
`;
export const paymentHistoriesByCustomerIdAndDateOfTransaction = /* GraphQL */ `
  query PaymentHistoriesByCustomerIdAndDateOfTransaction(
    $CustomerId: String!
    $DateOfTransaction: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentHistoriesByCustomerIdAndDateOfTransaction(
      CustomerId: $CustomerId
      DateOfTransaction: $DateOfTransaction
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TransactionReferenceNo
        CustomerId
        ContactId
        DateOfTransaction
        createdAt
        PaymentAmount
        SAPBankPaymentadviceId
        SAPBankPaymentadviceUUID
        SAPBankPaymentadviceStatus
        PaymentStatus
        PaymentStatusCode
        PaymentHistoryDetails
        tenant
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContactInformations = /* GraphQL */ `
  query ListContactInformations(
    $filter: ModelContactInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CustomerId
        CustomerName
      }
      nextToken
    }
  }
`;
export const productPricesByTenant = /* GraphQL */ `
  query ProductPricesByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productPricesByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        productID
        tenant
      }
      nextToken
      __typename
    }
  }
`;
export const productDiscountsByCustomerUUID = /* GraphQL */ `
  query ProductDiscountsByCustomerUUID(
    $CustomerUUID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productDiscountsByCustomerUUID(
      CustomerUUID: $CustomerUUID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductDiscount
        Customer
        CustomerUUID
        productID
        tenant
      }
      nextToken
      __typename
    }
  }
`;
export const userFavouriteProductsByOwner = /* GraphQL */ `
  query UserFavouriteProductsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFavouriteProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFavouriteProductsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FavouriteStatus
        owner
        CustomerUUID
        productID
        tenant
        userFavouriteProductsProductDetailId
        productDetail {
        ProductCategoryId
        ProductCategoryName
        ProductQuantityDetails
        ProductDetailDescription
        ProductId
        ProductImage
        ProductName
        ProductStatus
        ProductUOM
        ProductUOMText
        id
        tenant
      }
       
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const createUserFavouriteProducts = /* GraphQL */ `
  mutation CreateUserFavouriteProducts(
    $input: CreateUserFavouriteProductsInput!
    $condition: ModelUserFavouriteProductsConditionInput
  ) {
    createUserFavouriteProducts(input: $input, condition: $condition) {
      id
      FavouriteStatus
      owner
      CustomerUUID
      productID
      productDetail {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        tenant
      }
      tenant
      userFavouriteProductsProductDetailId
      
    }
  }
`;
// export const createUserProductsCart = /* GraphQL */ `
//   mutation CreateUserProductsCart(
//     $input: CreateUserProductsCartInput!
//     $condition: ModelUserProductsCartConditionInput
//     $customerUUID: String
//   ) {
//     createUserProductsCart(input: $input, condition: $condition) {
//       id
//       ProductQuantity
//       ProductQuantityText
//       owner
//       ProductPrice
//       ProductDiscount
//       carttableID
//       Productdetails {
//         id
//         ProductId
//         ProductName
//         ProductUOM
//         ProductUOMText
//         ProductCategoryId
//         ProductCategoryName
//         ProductDetailDescription
//         ProductImage
//         ProductStatus
//         ProductsDiscounts(filter: {
//         CustomerUUID: { eq: $customerUUID } 
//       }) {
//           items {
//             Customer
//             CustomerUUID
//             ProductDiscount
//             productID
//             id
//           }
//         }
//         ProductsPrices {
//         items {
//           ProductPrice
//           ProductPriceCurrencyCode
//           ProductPricecurrencyCodeText
//           id
//           productID
//           tenant
//         }
//       }
//       UserFavouriteProducts {
//         items {
//           CustomerUUID
//           FavouriteStatus
//           id
//           owner
//           productID
//           tenant
//           userFavouriteProductsProductDetailId
//         }
//       }
//         tenant
//       }
//       tenant
//       createdAt
//       updatedAt
//       userProductsCartProductdetailsId
//     }
//   }
// `;

export const createUserProductsCart = /* GraphQL */ `
  mutation CreateUserProductsCart(
    $input: CreateUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    createUserProductsCart(input: $input, condition: $condition) {
      id
      ProductQuantity
      ProductQuantityText
      ProductPriceUOMCode
      CalculatedUOMQuantity
      owner
      ProductPrice
      ProductDiscount
      carttableID
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
    }
  }
`;
export const BatchDeleteNotifications = /* GraphQL */ `
  mutation BatchDeleteNotifications($input: [BatchDeleteNotificationInput]!) {
    BatchDeleteNotifications(input: $input) {

      MessageText
  
    }
  }
`;
export const SearchByProductList = /* GraphQL */ `
  query SearchByProductList(
    $q: String!
    $tenant: String!
    $from: String
    $size: String!
  ) {
    SearchByProductList(q: $q, tenant: $tenant, from: $from, size: $size) {
      SearchResult {
        ProductName
        ProductImage
        ProductUOMText
        ProductCategoryName
        ProductId
        ProductUOM
        ProductUUID
        ProductDetailDescription
        ProductsDiscounts
        ProductsPrices
        ProductCategoryId
        id
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        __typename
      }
      TotalValue
      __typename
    }
  }
`;

export const updateUserProductsCart = /* GraphQL */ `
  mutation UpdateUserProductsCart(
    $input: UpdateUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    updateUserProductsCart(input: $input, condition: $condition) {
      id
      CalculatedUOMQuantity
      ProductPriceUOMCode
      ProductQuantity
      ProductQuantityText
      owner
      ProductPrice
      ProductDiscount
      carttableID
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;
export const deleteUserProductsCart = /* GraphQL */ `
  mutation DeleteUserProductsCart(
    $input: DeleteUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    deleteUserProductsCart(input: $input, condition: $condition) {
      id
      ProductQuantity
      ProductQuantityText
      owner
      ProductPrice
      ProductDiscount
      carttableID
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;

export const tenantUsersByEmail = /* GraphQL */ `
  query TenantUsersByEmail(
    $Email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByEmail(
      Email: $Email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Email
        TenantMasters {
          items {
            CompanyId
            CompanyName
            tenant
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tenantUsersByPhoneNumber = /* GraphQL */ `
  query TenantUsersByPhoneNumber(
    $PhoneNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByPhoneNumber(
      PhoneNumber: $PhoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        PhoneNumber
        TenantMasters {
          items {
            CompanyId
            CompanyName
            tenant
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;