import React from 'react'
import { SimCardDownload } from '@mui/icons-material';
import './DeliveryCard.css';
import GetOrderPDF from '../../../Pages/order/GetOrderPDF';
import { showToast } from '../../ToastUtils';
import { useTranslation } from 'react-i18next';

const DeliveryCard = ({ index, deliveryDetails, contextVariable }) => {
    // console.log("delivery", deliveryDetails);
    let delivery = deliveryDetails;
    const { t } = useTranslation();

    const handlePreviewClick = (uuid, deliveryID) => {
        GetOrderPDF("delivery", uuid, deliveryID);
        showToast(t(`Started downloading...`), "info", 1000);
    }

    const handleCardClick = (params) => {
        contextVariable.setScrollPosition(window.scrollY);
        contextVariable.setRootObject(params);
        contextVariable.setShowView((prev) => ({ ...prev, showDeliveryDetails: true }));
        // console.log(contextVariable.showView);
        // navigate('/deliveryDetails');
    }

    return (
        <div key={index} className="card m-2" style={{ fontFamily: "helvetica", marginTop: "0" }}>
            <div
                className={`delivery-container ${delivery.ReleaseStatus === '1' ? 'deliverycard-border-open' :
                    delivery.ReleaseStatus === '2' ? 'deliverycard-border-notreleased' :
                        delivery.ReleaseStatus === '3' ? 'deliverycard-border-released' : ''
                    }`}
            >
                {/* PDF section */}
                {
                    (delivery.PDFUUID).trim() !== '' ?
                        <div
                            onClick={() => handlePreviewClick(delivery.PDFUUID, delivery.DeliveryID)}
                            style={{ background: "#f8f8f8" }}
                            className='p-3 flex flex-column justify-content-center align-items-center'>
                            <div>
                                <SimCardDownload
                                    style={{ fontSize: "2rem", color: "#ff6c6c" }} />
                            </div>
                            <div>
                                <span className='font-10'>PDF</span>
                            </div>
                        </div>
                        :
                        <div
                            style={{ background: "#f8f8f8" }}
                            className='p-3 flex flex-column justify-content-center align-items-center'>
                            <div>
                                <span className='text-sm' style={{ color: "#c5c5c5" }}>NO <br />PDF</span>
                            </div>
                        </div>
                }

                {/* 2nd section */}
                <div
                    onClick={() => handleCardClick(deliveryDetails)}
                    className='pt-3'>
                    {/* Deliveery id and date */}
                    <div className='flex justify-content-between'>
                        <div className='flex gap-1'>
                            <span className='text-base'>ID :</span>
                            <span className='text-base'>{delivery.DeliveryID || "-"}</span>
                        </div>
                        <div className='text-right flex gap-1'>
                            <span className='text-xs'>Delivery :</span>
                            <span className='text-xs value-color'>{delivery.DeliveryDate}</span>
                        </div>
                    </div>
                    {/* Track no and shipper */}
                    <div className='flex gap-1'>
                        {
                            (delivery.ReleaseStatus === "1") &&
                            <span className='text-xs' style={{ color: "var(--sidebar-color)" }}>{delivery.ReleaseStatusText}</span> //In process
                        }
                        {
                            (delivery.ReleaseStatus === "2") &&
                            <span className='text-xs' style={{ color: "var(--info-color)" }}>{delivery.ReleaseStatusText}</span> //In process
                        }
                        {
                            (delivery.ReleaseStatus === "3") &&
                            <span className='text-xs' style={{ color: "#00b321" }}>{delivery.ReleaseStatusText}</span> //Completed
                        }
                        {/* <span className='text-xs'>Track ID :</span>
                        <span className='text-xs value-color'>{delivery.TrackingID}</span> */}
                    </div>
                    <div className='flex gap-1'>
                        <span className='text-xs'>Freight Forwarder :</span>
                        <span className='text-xs value-color'>{delivery.FreightForwarder}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryCard