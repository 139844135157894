import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrimeReactProvider } from 'primereact/api';
// import PrimeReact from "primereact/api";
import Dashboard from "./Pages/dashboard/Dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ProductCatalog from "./Pages/ProductCatalog/ProductCatalog";
import Delivery from "./Pages/delivery/Delivery";
import Invoice from "./Pages/invoice/Invoice";
// import PaymentStatus from "./Pages/invoice/Stripe/PaymentStatus";
import CreditNote from "./Pages/creditNote/CreditNote";
import Order from "./Pages/order/Order";
import PaymentHistory from "./Pages/paymentHistory/PaymentHistory";
import StatementOfAccount from "./Pages/statementOfAccount/StatementOfAccount";
import PlaceOrder from "./Pages/ProductCatalog/PlaceOrder/PlaceOrder";
import ErrorComponent from "./components/ErrorComponent";
import { createContext, useEffect, useState } from "react";
import { Flip, ToastContainer } from 'react-toastify';
import AdminDashboard from './Pages/Admin/dashboard/AdminDashboard';
import CustomerPortalSetupLayout from './Pages/Admin/customerPortalSetup/CustomerPortalSetupLayout';
import OrganizationDetailsForm from './Pages/Admin/customerPortalSetup/organizationDetailsForm/OrganizationDetailsForm';
import ProductImageUpload from './Pages/Admin/customerPortalSetup/productImageUpload/ProductImageUpload';
import ProductImageView from './Pages/Admin/customerPortalSetup/productImageView/ProductImageView';
import ContactDetails from './Pages/Admin/customerPortalSetup/contactDetails/ContactDetails';
import ErrorLogDetails from './Pages/Admin/errorLogs/ErrorLogs';
import SAPandStripeDetails from "./Pages/Admin/customerPortalSetup/organizationDetailsForm/SAPandStripeDetails";

import 'react-toastify/dist/ReactToastify.css';
import "@aws-amplify/ui-react/styles.css";
// import {
//   withAuthenticator,
// } from "@aws-amplify/ui-react";
// import { listOrganizationDetails } from "./graphql/queries";
// import { showToast } from "./components/ToastUtils";
import Login from "./Pages/Login/Login";
import Loader from "./components/Loader";
// import { FadeProvider } from "./components/FadeContext/FadeContext";
import Register from "./Pages/Register/Register";
import OrderDetailsCard from "./components/cards/orders/OrderDetailsCard";
import DeliveryDetailsCard from "./components/cards/deliveries/DeliveryDetailsCard";
import PaymentHistoryDetailsCard from "./components/cards/paymentHistory/PaymentHistoryDetailsCard";
import CreditNoteDetailsCard from "./components/cards/creditNote/CreditNoteDetailsCard";
import Layout from "./Layout/Layout";
import NotificationCard from "./components/cards/Notification/NotificationCard";
import Notifications from "./Pages/Admin/customerPortalSetup/Notifications/Notifications";
import SessionExpired from "./components/SessionExpired";
Amplify.configure(awsExports);
export const LoginContext = createContext();

function App({ signOut }) {
  const [theme, colorMode] = useMode();
  // const [isRTL, setIsRTL] = useState(false);
  const [session, setSession] = useState(null);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [userGroups, setUserGroups] = useState(null);
  const [Loading, setLoading] = useState(false);

  const value = {
    ripple: true,
  };

  const getOrganizationDetails = (params) => {
    setOrganizationDetails(params);
  }

  const verifyAuth = () => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setLoading(false);
        setSession(null);
        // Get user groups
        const groups = userData.signInUserSession.idToken.payload["cognito:groups"];
        setUserGroups(groups[0]);
      })
      .catch((err) => {
        setLoading(false);
        localStorage.clear();
        // console.error(err);
      });
  };

  useEffect(() => {
    verifyAuth();
  }, []);



  const loginContextValue = {
    session, setSession,
    userGroups, setUserGroups
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout
        role={userGroups}
        getOrganizationDetails={getOrganizationDetails}
      />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "/order",
          element: <Order />,
        },
        {
          path: "/orderDetails",
          element: <OrderDetailsCard />
        },
        {
          path: "/productCatalog",
          children: [
            {
              index: true,
              element: (
                <ProductCatalog />
              ),
            },
            {
              path: "placeOrder",
              element: <PlaceOrder />,
            },
          ],
        },
        {
          path: "/delivery",
          element: <Delivery />,
        },
        {
          path: "/deliveryDetails",
          element: <DeliveryDetailsCard />,
        },
        {
          path: "/invoicemobile",
          element: <Invoice />,
        },
        {
          path: "/invoice",
          children: [
            {
              index: true,
              element: (
                <Invoice />
              ),
            },
            // {
            //   path: "paymentStatus",
            //   element: <PaymentStatus />,
            // },
          ],
        },
        {
          path: "/creditNote",
          element: <CreditNote />,
        },
        {
          path: "/creditNoteDetails",
          element: <CreditNoteDetailsCard />
        },
        {
          path: "/paymentHistory",
          element: organizationDetails && organizationDetails.StripeDefaultIndicator === "true" ? <PaymentHistory /> : <div style={{ height: "60vh" }} className='flex justify-content-center align-items-center'>
            <Loader></Loader></div>,
        },
        {
          path: "/historyDetails",
          element: <PaymentHistoryDetailsCard />
        },
        {
          path: "/statementOfAccount",
          element: <StatementOfAccount />,
        },
        {
          path: "/notifications",
          element: <NotificationCard />,
        },
      ],
      errorElement: <ErrorComponent />
      // errorElement: <CommonErrorComponent />
    },
  ]);

  const adminRouter = createBrowserRouter([
    {
      path: "/",
      element: <Layout
        role={userGroups}
        organizationDetails={organizationDetails}
      />,
      children: [
        {
          index: true,
          element: <AdminDashboard />,
        },
        {
          path: "customerportalsetup",
          element: <CustomerPortalSetupLayout />,
          children: [
            {
              index: true,

              element: <OrganizationDetailsForm />,
            },
            {
              path: "SAPandStripeDetails",
              element: <SAPandStripeDetails />,
            },
            {
              path: "productImageUpload",
              element: <ProductImageUpload />,
            },
            {
              path: "productImageView",
              element: <ProductImageView />,
            },
            {
              path: "contactDetails",
              element: <ContactDetails />,
            },
            {
              path: "notificationsView",
              element: <Notifications />,
            },
          ],
        },
        {
          path: "/errorlogs",
          element: <ErrorLogDetails />,
        },
      ],
      errorElement: <ErrorComponent />,
      // errorElement: <CommonErrorComponent />
    },
  ]);

  const LoginRouter = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <SessionExpired />
    },
    {
      path: "/register",
      element: <Register />,
    },
  ])

  return (
    <PrimeReactProvider value={value}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LoginContext.Provider value={loginContextValue}>
            <CssBaseline />
            <div className="app">
              {
                (userGroups === 'Admin' && !Loading) &&
                <RouterProvider router={adminRouter} />
              }
              {
                (userGroups === 'Customers' && !Loading) &&
                <RouterProvider router={router} />
              }
              {
                !userGroups && !localStorage.getItem("CognitoIdentityServiceProvider") &&
                <RouterProvider router={LoginRouter} />

              }
            </div>
            {/* Toastr Container Section */}
            <ToastContainer
              position="top-center"
              limit={2}
              autoClose={8000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              toastClassName="custom-toastr"
              transition={Flip}
            />
          </LoginContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </PrimeReactProvider>
  );
}

export default App;
// export default withAuthenticator(App, { hideSignUp: true });
