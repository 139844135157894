import { ArrowBackIos } from '@mui/icons-material';
import React, { useContext } from 'react'
import {  useNavigate } from 'react-router-dom';
import { AppContext } from '../../Layout/Layout';
// import ProCustomer from "../../logo/ProCustomerPNG.png";

const CardTopbar = (props) => {
    const contextVariable = useContext(AppContext);
    const navigate = useNavigate();

    const handleBack = () => {
        switch (props.link) {
            case "order":
                contextVariable.setShowView((prev) => ({ ...prev, showOrderDetails: false }));
                break;
            case "delivery":
                contextVariable.setShowView((prev) => ({ ...prev, showDeliveryDetails: false }));
                break;
            case "invoice":
                contextVariable.setShowView((prev) => ({ ...prev, showInvoiceDetails: false }));
                break;
            case "invoiceDetails":
                contextVariable.setShowView((prev) => ({ ...prev, showInvoiceDetails: false, showInvoicPayment: false }));
                break;
            case "paymentHistory":
                contextVariable.setShowView((prev) => ({ ...prev, showPaymentHistoryDetails: false }));
                break;
            case "creditNote":
                contextVariable.setShowView((prev) => ({ ...prev, showCreditNoteDetails: false }));
                break;
            case "productCatalog":
                navigate("/productCatalog");
                sessionStorage.setItem('selectedIconIndex', 2)
                break;
            default:
                navigate("/");
                contextVariable.setShowView((prev) => ({ ...prev, showCreditNoteDetails: false }));
                sessionStorage.setItem('selectedIconIndex', 1)
                break;
        }
    }

    return (
        <>
            {/* header */}
            <div className="sticky top-0 z-1">
                <div className="h-4rem text-white w-full p-2" style={{ background: "#344955" }}>
                    <div className="mt-4">
                        <div>
                            {/* For Dashboard */}
                            {
                                props.header === 'dashboard' ?
                                    <>
                                        <div className='flex justify-content-between align-items-baseline'>
                                            <div>
                                                <span className='text-base'>{`Welcome, ${props.username}`}</span>
                                            </div>
                                            <div>
                                                <span className='text-sm'>
                                                    {contextVariable.userDetails["custom:customer_id"]}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='m-1 pb-2 flex justify-content-between align-items-center'>
                                            {
                                                props.buttonNeeded &&
                                                <div>
                                                    <span onClick={handleBack}><ArrowBackIos fontSize='large' /></span>
                                                </div>
                                            }
                                            <div className='flex justify-content-between align-items-top w-full'>
                                                <div>
                                                    <span className='text-base'>{props.header}</span>
                                                </div>
                                                <div className='-mt-2'>
                                                    {/* {
                                                    (props.header === "Orders" ||
                                                            props.header === "Payment History" ||
                                                            props.header === "Invoices") &&
                                                }*/}
                                                    {
                                                        (!props.buttonNeeded && props.header !== "Notifications" && props.header !== "Cart") &&
                                                        <>
                                                            <i
                                                                className={`border-circle border-2 border-white p-2 pi ${props.loading ? 'pi-spin' : ''} pi-refresh`}
                                                                style={{ fontSize: '0.8rem', color: props.loading ? 'lightgray' : 'white' }}
                                                                onClick={!props.loading ? props.refresh : null}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardTopbar