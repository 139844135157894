import React from 'react'
import './PaymentHistoryCard.css';
import getSymbolFromCurrency from 'currency-symbol-map';
import CurrencyFormat from '../../CurrencyFormat';

const PaymentHistoryCard = ({ index, rowData, contextVariable }) => {
    let data = rowData;

    const handleCardClick = (params) => {
        contextVariable.setScrollPosition(window.scrollY);
        contextVariable.setRootObject(params);
        contextVariable.setShowView((prev) => ({ ...prev, showPaymentHistoryDetails: true }));
        // navigate('/historyDetails');
    }

    return (
        <div key={index}
            className="card m-2"
            style={{ fontFamily: "helvetica", marginTop: "0" }}>
            <div
                onClick={() => handleCardClick(data)}
                className={`p-2 ${data.PaymentStatus === "In Process" ? 'card-border-in-process' :
                    data.PaymentStatus === "Success" ? 'card-border-completed' : ''
                    }`}
            >
                {/* Reference no */}
                <div className='flex gap-2 align-items-baseline'>
                    <span className='text-xs value-color'>Ref No :</span>
                    <span className='text-xs'>{data.TransactionReferenceNo}</span>
                </div>
                {/* Status, date, amount*/}
                <div className='grid pt-2'>
                    <div className='col-3 text-xs'>
                        {
                            data.PaymentStatus === "In Process" &&
                            <span className='var(--sidebar-color)'>{data.PaymentStatus}</span>
                        }
                        {
                            data.PaymentStatus === "Success" &&
                            <span style={{ color: "#00b321" }}>{data.PaymentStatus}</span>
                        }
                        {
                            (data.PaymentStatus !== "Success" && data.PaymentStatus !== "In Process") &&
                            <span className='text-gray-600'>{data.PaymentStatus}</span>
                        }
                    </div>
                    <div className='col-5 text-xs'>
                        <span>{data.DateOfTransaction}</span>
                    </div>
                    <div className='col-4 text-sm text-right'>
                        <span>{getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(data.PaymentAmount)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentHistoryCard