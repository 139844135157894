import React, { useContext, useState } from 'react'
import Loader from '../../components/Loader';
import DataViewGridItem from './DataViewGridItem';
import { DataView } from 'primereact/dataview';
import { AppContext } from '../../Layout/Layout';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

function CatalogDataView(props) {
  const {t}=useTranslation();
  // const filteredValue = props.filteredValue;
  const ProductList = props.ProductList;
  const dataViewRef = props.dataViewRef;
  const contextVariable = useContext(AppContext);



  //Item Template for using Gird view of Product List details (grid view portion)
  const itemTemplate = (ProductListData, layout) => {

    if (!ProductListData) {
      return (
        <div style={{}}>
          <Loader />
        </div>
      );
    } else {
      // If ProductListData is available, render the DataViewGridItem
      return (
        <DataViewGridItem ProductListData={ProductListData}></DataViewGridItem>
      );
    }
  };

  const [loadmoreLoading, setLoadMoreLoading] = useState(false);
  const handleLoad = async (e) => {
    setLoadMoreLoading(true);
    await contextVariable.OpenSearchProductsDetails((contextVariable.OpenSearchInputText || "ListByProduct"), contextVariable.ProductListDetails.length)
    setLoadMoreLoading(false);
  }
  const footer = contextVariable.productListNextToken &&
    <div className='flex justify-content-center align-items-center'>
      <Button
        type="text"
        style={{ color: "var(--sidebar-color)" }}
        rounded outlined
        className='px-4 py-2 text-sm'
        loading={loadmoreLoading}
        label={t('Load More')}
        onClick={(e) => handleLoad(e)} />
    </div>;

  return (
    <div>
      <DataView
        // value={filteredValue || ProductList}
        value={ProductList}
        layout="grid"
        first={props.calculateFirstRow()}
        // rows={60}
        // paginator
        itemTemplate={itemTemplate}
        ref={dataViewRef}
        footer={footer}
      >
      </DataView></div>
  )
}

export default CatalogDataView;