import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material"; import { useState, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import CreditNoteView from "./CreditNoteView";
import { CachedOutlined, PictureAsPdfOutlined } from "@mui/icons-material";
import { showToast } from "../../components/ToastUtils";
import { AppContext } from "../../Layout/Layout";
import { API } from "aws-amplify";
import { ListByCustomerCreditNotes } from "../../graphql/queries";
import GetOrderPDF from "../order/GetOrderPDF";
import CurrencyFormat from "../../components/CurrencyFormat";
import { useFade } from "../../components/FadeContext/FadeContext";
import CardTopbar from "../../components/cards/CardTopbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditNoteCard from "../../components/cards/creditNote/CreditNoteCard";
import NoResultsIcon from "../order/NoResultsIcon";
import SkeletonLoader from "../../components/SkeletonLoader";
import CreditNoteDetailsCard from "../../components/cards/creditNote/CreditNoteDetailsCard";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Payment } from "@mui/icons-material";

const CreditNote = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [creditNoteDetails, setCreditNoteDetails] = useState(1);
  const [CreditNoteViews, setCreditNoteView] = useState(false);
  const [rowLoadingStates, setRowLoadingStates] = useState({});
  const [loading, setLoading] = useState(false);

  const setRowLoadingState = (rowKey, loading) => {
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [rowKey]: loading,
    }));
  };

  const isMobile = contextVariable.isMobile;
  const [disableDate, setDisableDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const dates = [
    { label: String(currentYear), year: currentYear },
    { label: String(currentYear - 1), year: currentYear - 1 },
    { label: String(currentYear - 2), year: currentYear - 2 },
    { label: String(currentYear - 3), year: currentYear - 3 },
    { label: String(currentYear - 4), year: currentYear - 4 },
    { label: String(currentYear - 5), year: currentYear - 5 },
    { label: String(currentYear - 6), year: currentYear - 6 },
    { label: String(currentYear - 7), year: currentYear - 7 },
    { label: String(currentYear - 8), year: currentYear - 8 },
    { label: String(currentYear - 9), year: currentYear - 9 },
    { label: String(currentYear - 10), year: currentYear - 10 },
    { label: String(currentYear - 11), year: currentYear - 11 },
    { label: String(currentYear - 12), year: currentYear - 12 },
    { label: String(currentYear - 13), year: currentYear - 13 },
    { label: String(currentYear - 14), year: currentYear - 14 },
    { label: String(currentYear - 15), year: currentYear - 15 }
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  // Each Column Definition results in one Column.
  const columnDefs = [
    { field: "ExternalReferenceID", headerName: t('Reference Number'), filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    { field: "CreditNoteID", headerName: t('Credit Note ID'), filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    { field: "CreditNoteDate", headerName: t('Credit Note Date'), filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    {
      field: "NetAmount",
      headerName: `${t('Total Amount')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      flex: 0.5,
      headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    {
      field: "GrossAmount",
      headerName: `${t('Gross Amount')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      flex: 0.5,
      headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    {
      field: "PaymentStatus",
      headerName: t('Status'),
      filter: true,
      flex: 0.5,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        return (
          <>
            {
              params.value === 'Released' &&
              <span className='px-2 py-1 text-green-600 bg-green-100 border-round-md font-semibold'>
                {params.value}
              </span>
            }
            {
              params.value !== 'Released' &&
              <span className='px-2 py-1 text-yellow-600 bg-yellow-100 border-round-md font-semibold'>
                {params.value}
              </span>
            }
          </>
        );
      }
    },
    {
      field: 'preview',
      headerName: t('PDF Preview'),
      flex: 0.3,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const handlePreviewClick = () => {
          setRowLoadingState(params.data.PDFUUID, true);
          GetOrderPDF("creditNote", params.data.PDFUUID, params.data.CreditNoteID).then(() => {
            setRowLoadingState(params.data.PDFUUID, false);
          });
        };
        return (
          rowLoadingStates[params.data.PDFUUID] ? (
            <CachedOutlined
              className="rotating-icon-anticlockwise"
              style={{ marginTop: '-0.3rem' }}
            />
          ) :
            (params.data.PDFUUID).trim() !== '' ?
              <>
                <IconButton
                  style={{ marginTop: '-0.3rem' }}
                  color="var(--sidebar-color)"
                  onClick={handlePreviewClick}
                >
                  <PictureAsPdfOutlined style={{ color: 'red' }} />
                </IconButton>
              </> :
              <IconButton
                style={{ marginTop: '-0.3rem' }}
                color="var(--sidebar-color)"
                onClick={handlePreviewClick}
                disabled={true}
              >
                <PictureAsPdfOutlined />
              </IconButton>
        );
      },
    },
  ];


  sessionStorage.setItem("title", "Credit Note");

  const fetchCreditNote = async () => {
    setCreditNoteDetails(1);
    setDisableDate(true);
    setLoading(true);
    let creditNoteDetails = [];
    let Skip = 0;
    try {
      do {
        const responseData = await API.graphql({
          query: ListByCustomerCreditNotes,
          variables: {
            year: contextVariable.date,
            limit: contextVariable.FetchLimit.crditNote,
            skip: Skip,
            sortField: "InvoiceID",
            sortOrder: "desc"
          },
          // authToken: 'custom-authorized'
        });
        const sessionYear = parseInt(sessionStorage.getItem("date"))
        if (responseData.data.ListByCustomerCreditNotes.year === sessionYear) {
          if (responseData.data.ListByCustomerCreditNotes.count < contextVariable.FetchLimit.crditNote) {
            Skip = 0;
            contextVariable.setIsFiltered(false);
          }
          else if (responseData.data.ListByCustomerCreditNotes.count >= contextVariable.FetchLimit.crditNote) {
            Skip = Skip + responseData.data.ListByCustomerCreditNotes.count;
            contextVariable.setIsFiltered(false);
          }
          creditNoteDetails = creditNoteDetails.concat(responseData.data.ListByCustomerCreditNotes.items);

          if (creditNoteDetails.length > 0) {
            setCreditNoteDetails(creditNoteDetails);
          }

          if (responseData.data.ListByCustomerCreditNotes.items.length === 0) {
            setCreditNoteDetails(0);
          }
        }
        else {
          Skip = 0;
        }

      } while (Skip);
      setDisableDate(false);
      setLoading(false);
    } catch (error) {
      setCreditNoteDetails(0);
      setDisableDate(false);
      setLoading(false);
      console.error("fetchCreditNote Error", error);
      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchCreditNote Error", error);
        // showToast(`Cannot get Credit Note list : ${error.errors[0].message}`, "error");
        showToast(t(`Something went wrong. Please contact the admin`),'error',4000);
      }
    }
  }

  useEffect(() => {
    sessionStorage.setItem("date", contextVariable.date);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCreditNote();
    //eslint-disable-next-line
  }, [contextVariable.date]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (!contextVariable.showView.showCreditNoteDetails) {
      window.scrollTo(0, contextVariable.scrollPosition);
    }
  }, [contextVariable.showView.showCreditNoteDetails, contextVariable.scrollPosition]);


  useEffect(() => {
    sessionStorage.setItem("date", contextVariable.date)
  }, [contextVariable.date]);

  const handleCardClick = (data) => {
    contextVariable.setRootObject(data);
  }

  const openCreditNoteItemView = () => {
    setCreditNoteView(true);
  }

  const closeCreditNoteItemView = () => {
    setCreditNoteView(false);
  }

  const selectedRowsHandler = (rows) => {
  }

  const { fadeIn } = useFade();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateChange = (e) => {
    sessionStorage.setItem("date", e.value.year);
    setSelectedDate(e.value.year);
    // contextVariable.setGlobalData(null);
    contextVariable.setGlobalSearch(contextVariable.globalSearch)
    // contextVariable.setFilteredData([]);
    contextVariable.setIsFiltered(false);
    contextVariable.setDate(e.value.year);
  };

  return (
    <>

      {CreditNoteViews &&
        <CreditNoteView
          backToCreditNote={closeCreditNoteItemView}
          creditNoteDetails={creditNoteDetails}
        >
        </CreditNoteView>}

      {
        (!isMobile && contextVariable.showView.showCreditNoteDetails === false) &&
        <>
          {!CreditNoteViews && <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>

            {/* DataGrid Card  */}
            <div
              className="card card-shadow">
              <div className="card-body">
                <GridData
                  rowData={creditNoteDetails}
                  columnDefs={columnDefs}
                  CreditNoteView={openCreditNoteItemView}
                  component={'creditNote'}
                  tableHeight={'78vh'}
                  needFilters={true}
                  showPagination={true}
                  selectedRows={selectedRowsHandler}
                  filter={'CreditNote'}
                />
              </div>
            </div>
          </Box>}
        </>
      }

      {
        (isMobile && contextVariable.showView.showCreditNoteDetails === false) &&
        <>
          <div className="bg-gray-50">
            <div className="sticky top-0 z-1">
              {/* Topbar */}
              <CardTopbar
                header={`Credit Notes`}
                buttonNeeded={false}
                refresh={fetchCreditNote}
                loading={loading}
              />
              {/* Dropdown and Search */}
              <div className="bg-gray-50 border-bottom-2 pt-2 pr-2 pl-2 shadow-2">
                <div className="grid">
                  <div className="col-4">
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 w-full mobile-dropdown"
                    />
                  </div>
                  <div className="col-8">
                    <span className="p-input-icon-left w-full">
                      <i className="pt-1 pi pi-search" />
                      <InputText
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by id, reference etc..."
                        className="p-inputtext-sm p-2 pl-5 w-full"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto">
              {
                (creditNoteDetails !== 0 && creditNoteDetails !== 1) &&
                creditNoteDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .map((filteredEl, index) => (
                    <CreditNoteCard
                      key={index}
                      index={index}
                      creditNoteDetails={filteredEl}
                      handleCardClick={handleCardClick}
                      contextVariable={contextVariable}
                    />
                  ))
              }

              {/* Loading skeleton */}
              {
                creditNoteDetails === 1 &&
                <div className="flex flex-column">
                  {
                    [...Array(7)].map((_, index) => (
                      (creditNoteDetails === 0 || creditNoteDetails === 1) &&
                      <SkeletonLoader key={index} />
                    ))
                  }
                </div>
              }

              {/* No data foundd for selected year */}
              {
                creditNoteDetails === 0 &&
                <>
                  <div className="mt-3 h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                </>
              }

              {/* No data found on search */}
              {
                (creditNoteDetails !== 0 && creditNoteDetails !== 1) &&
                creditNoteDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .length === 0 && (
                  <div className="h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                )
              }

            </div>

          </div>
        </>
      }

      {
        (isMobile && contextVariable.showView.showCreditNoteDetails === true) &&
        <>
          <CreditNoteDetailsCard />
        </>
      }

    </>
  );
};

export default CreditNote;
