import { showToast } from "../ToastUtils";
import { getNotificationDescription } from "./NotificationDescription";
import i18n from "../../i18/i18nConfig";

export const handleShowNotification = async (CreatedNotification) => {

    const showNotificationDescription = (notifyData) => {
        let notifyDetails;
        const notifyCode = notifyData.NotificationReasonCode;
        const parsedNotificationData = JSON.parse(notifyData.NotificationData);

        switch (notifyCode) {
            case 'SO_C':
                notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, referenceID: parsedNotificationData[0].POReferanceID })
                return notifyDetails;


            case 'SO_P':
                return notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });


            case 'SO_R':
                return notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });


            case 'SO_S':
                return notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });

            case 'SO_S_I':
                return notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });

            case 'I_I':
                return notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].OrderID });

            case 'I_C':
                return notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceId, orderID: parsedNotificationData[0].OrderID });

            case 'C_C':
                return notifyDetails = getNotificationDescription(notifyCode, { creditMemoID: parsedNotificationData[0].CreditmemoID });

            case 'C_I':
                return notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceID });

            case 'PS_S':
                return notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });


            case 'PS_F':
                return notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });


            case 'BPA_C':
                return notifyDetails = getNotificationDescription(notifyCode, { bankPaymentID: parsedNotificationData[0].BankPaymentId });


            default:
                return notifyDetails = getNotificationDescription(notifyCode);
        }

    }

    async function requestNotificationPermission() {
        // const permission = await Notification.requestPermission();
    }

    requestNotificationPermission();

    if ('Notification' in window) {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            const notifyDetails = showNotificationDescription(CreatedNotification);

            if (CreatedNotification.NotificationReasonCode === 'CSP_D_F' || CreatedNotification.NotificationReasonCode === 'CSD_D_F') {
                showToast(i18n.t(notifyDetails.description), "error", 3000);
            }
            // console.log("notifyDetails", notifyDetails)
            const notification = new Notification(notifyDetails.header, {
                body: i18n.t(notifyDetails.description),
            });

            notification.onclick = () => {
            };

        } else {
        }
    }
};

