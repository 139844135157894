import { CardTravelOutlined, InfoRounded, ShoppingCartCheckoutOutlined } from '@mui/icons-material'
import {
    Box, Divider, Tooltip, Zoom,
    Autocomplete, TextField, Skeleton
} from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataView } from 'primereact/dataview'
import PlaceOrderListView from './PlaceOrderListView';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Header from '../../../components/Header.jsx';
import { Button } from 'primereact/button';
import './PlaceOrder.css';
import { API } from 'aws-amplify';
import { showToast } from '../../../components/ToastUtils';
import { AppContext } from '../../../Layout/Layout';
import ProcessGif from "./process.png";
import orderedImage from "./ordered.png";
// import Loader from '../../../components/Loader';
import { CreatePlaceOrder } from '../../../graphql/mutations';
import Loader from '../../../components/Loader';
import ConfettiEffect from '../../../components/ConfettiEffect';
import CheckOut from './CheckOut.png';
import { useFade } from '../../../components/FadeContext/FadeContext.jsx';
import InfoBar from '../../../components/InfoBar.jsx';
import CurrencyFormat from '../../../components/CurrencyFormat.js';
import { OverlayPanel } from 'primereact/overlaypanel';
import getSymbolFromCurrency from 'currency-symbol-map';
import CardTopbar from '../../../components/cards/CardTopbar.jsx';
import CartCard from '../../../components/cards/CartCard.jsx';
import { Sidebar } from 'primereact/sidebar';
import { BasePriceAndCSDCartDetails, BasePriceAndCSPCartDetails, BasePriceCartDetails } from '../../../Layout/PriceAndDiscountQueries.js';
import PriceUpdateDialog from './PriceUpdateDialog.jsx';
import { useTranslation } from 'react-i18next';
// import TruncateText from '../../../components/TruncateText';

const PlaceOrder = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    // const [expanded, setExpanded] = React.useState('panel2');
    const [CartProducts, setCartProducts] = useState([]);
    const [userAddress, setuserAddress] = useState([]);
    const [accountDetails, setaccountDetails] = useState(contextVariable.accountDetails);
    const [customerAddress, setcustomerAddress] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [loading, setLoading] = useState(contextVariable.addRemoveCartLoading);
    const [PlaceOrdersListLoading, setPlaceOrdersListLoading] = useState(false);
    const [FormValue, setFormValue] = useState({
        PORef: "",
        Notes: ""
    });
    const [openDialog, setOpenDialog] = useState(false);

    const op = useRef(null);

    sessionStorage.setItem("title", "Cart");

    const customHeader = (
        <div className="flex align-items-center gap-2">
            <span className="text-base font-semibold">Order confirmation</span>
        </div>
    );



    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };



    const fetchCartProductCount = async () => {
        if (contextVariable.userDetails !== "" && contextVariable.UserCartIdDetails &&
            contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus === "Open") {

            let ProductWithPriceTable;
            ProductWithPriceTable = contextVariable.taxDetails.AdditionalPricingOptions === "None" ? BasePriceCartDetails : contextVariable.taxDetails.AdditionalPricingOptions === "CSD" ? BasePriceAndCSDCartDetails : BasePriceAndCSPCartDetails;
            // const discountFilter = {
            //     CustomerUUID: { eq: contextVariable.userDetails["custom:customer_UUID"] },
            // };
            const discountFilter = {
                CustomerUUID: contextVariable.taxDetails.AdditionalPricingOptions === "CSD" ? { eq: contextVariable.userDetails["custom:customer_UUID"] } : "",
            };
            let nextToken;
            let CartDetailsResponseItems = [];
            // console.log("ProductWithPriceTable", ProductWithPriceTable)
            // console.log(" contextVariable.UserCartIdDetails[0].id", contextVariable.UserCartIdDetails[0].id)
            do {
                try {
                    const CartDetailsResponse = await API.graphql({
                        query: ProductWithPriceTable,
                        variables: {
                            carttableID: contextVariable.UserCartIdDetails[0].id,
                            discountFilter,
                            customerUUID: contextVariable.taxDetails.AdditionalPricingOptions === "CSP" ? contextVariable.userDetails["custom:customer_UUID"] : "",
                        },
                    });

                    // console.log("CartDetailsResponse", CartDetailsResponse)
                    CartDetailsResponseItems = CartDetailsResponse.data.userProductsCartsByCarttableID.items.concat(CartDetailsResponseItems)
                    nextToken = CartDetailsResponse.data.userProductsCartsByCarttableID.nextToken;
                } catch (error) {
                    console.error("Fetching Error", error);
                }
            } while (nextToken);

            const CartITems = CartDetailsResponseItems.map((p) => {
                const newProduct = { ...p };
                newProduct.ProductUOM = JSON.parse(p.Productdetails.ProductQuantityDetails);
                if (contextVariable.taxDetails.AdditionalPricingOptions === "None" || contextVariable.taxDetails.AdditionalPricingOptions === "CSD") {
                    if (contextVariable.taxDetails.AdditionalPricingOptions === "None") {
                        newProduct.Productdetails.ProductsDiscounts = { items: [] };
                        newProduct.Productdetails.ProductDiscount = 0;
                    }

                    if (p.Productdetails && p.Productdetails.ProductsPrices && p.Productdetails.ProductsPrices.items[0]) {
                        newProduct.Productdetails.ProductPrice = p.Productdetails.ProductsPrices.items[0].ProductPrice > 0 ? p.Productdetails.ProductsPrices.items[0].ProductPrice : 0.00;
                        newProduct.Productdetails.ProductPriceCurrencyCode = p.Productdetails.ProductsPrices.items[0].ProductPriceCurrencyCode;
                        newProduct.Productdetails.ProductPriceQuantity = p.Productdetails.ProductsPrices.items[0].ProductPriceQuantity;
                        newProduct.Productdetails.ProductPriceQuantityCode = p.Productdetails.ProductsPrices.items[0].ProductPriceQuantityCode;
                    }
                    else {
                        if (p.Productdetails === null) {
                            newProduct.Productdetails = {
                                ProductPrice: 0.00,
                                ProductPriceCurrencyCode: "",
                                ProductPriceQuantity: "",
                                ProductPriceQuantityCode: ""
                            };
                        }
                        else {
                            newProduct.Productdetails.ProductPrice = 0.00;
                            newProduct.Productdetails.ProductPriceCurrencyCode = "";
                            newProduct.ProductPriceQuantity = "";
                            newProduct.Productdetails.ProductPriceQuantityCode = "";
                        }

                    }

                    if (p.Productdetails && p.Productdetails.ProductsDiscounts &&
                        p.Productdetails.ProductsDiscounts.items && p.Productdetails.ProductsDiscounts.items[0]) {
                        newProduct.Productdetails.ProductDiscount = (Math.abs(parseFloat(p.Productdetails.ProductsDiscounts.items[0].ProductDiscount).toFixed(2)));
                    }
                    else {
                        newProduct.Productdetails.ProductDiscount = 0;
                    }

                }
                else if (contextVariable.taxDetails.AdditionalPricingOptions === "CSP") {
                    // console.log("p", p)
                    if (p.Productdetails && p.Productdetails.CustomerSpecificPrices && p.Productdetails.CustomerSpecificPrices.items && p.Productdetails.CustomerSpecificPrices.items[0] && p.Productdetails.CustomerSpecificPrices.items[0].ProductPrice > 0) {
                        newProduct.Productdetails.ProductsDiscounts = { items: [] };
                        newProduct.Productdetails.ProductDiscount = 0;
                        newProduct.Productdetails.ProductPriceCurrencyCode = p.Productdetails.CustomerSpecificPrices.items[0].ProductPriceCurrencyCode;
                        newProduct.Productdetails.ProductsPrices = p.Productdetails.CustomerSpecificPrices
                        newProduct.Productdetails.ProductPrice = p.Productdetails.CustomerSpecificPrices.items[0].ProductPrice;
                        newProduct.Productdetails.ProductPriceQuantity = p.Productdetails.CustomerSpecificPrices.items[0].ProductPriceQuantity;
                        newProduct.Productdetails.ProductPriceQuantityCode = p.Productdetails.CustomerSpecificPrices.items[0].ProductPriceQuantityCode;
                    }
                    else if (p.Productdetails && p.Productdetails.ProductsPrices && p.Productdetails.ProductsPrices.items[0] && p.Productdetails.ProductsPrices.items[0].ProductPrice > 0) {

                        newProduct.Productdetails.ProductsDiscounts = { items: [] };
                        newProduct.Productdetails.ProductDiscount = 0;
                        newProduct.Productdetails.ProductPriceCurrencyCode = p.Productdetails.ProductsPrices.items[0].ProductPriceCurrencyCode;
                        newProduct.Productdetails.ProductPrice = p.Productdetails.ProductsPrices.items[0].ProductPrice;
                        newProduct.Productdetails.ProductPriceQuantity = p.Productdetails.ProductsPrices.items[0].ProductPriceQuantity;
                        newProduct.Productdetails.ProductPriceQuantityCode = p.Productdetails.ProductsPrices.items[0].ProductPriceQuantityCode;
                    }
                    else {
                        newProduct.Productdetails.ProductsDiscounts = { items: [] };
                        newProduct.Productdetails.ProductPrice = 0.00;
                        newProduct.Productdetails.ProductPriceCurrencyCode = "";
                        newProduct.Productdetails.ProductPriceQuantity = "";
                        newProduct.Productdetails.ProductPriceQuantityCode = "";

                    }
                }

                return newProduct;

            })

            contextVariable.setCartCount(CartITems.length);
            contextVariable.setUserCartItems(CartITems);
            setCartProducts(CartITems);

        }

    }

    useEffect(() => {
        setaccountDetails(contextVariable.accountDetails);
        setuserAddress(contextVariable.accountDetails.CustomerAddressInformation);

        if (contextVariable.accountDetails.CustomerAddressInformation) {
            const defaultAddressIndex = contextVariable.accountDetails.CustomerAddressInformation.filter(element => element.DefaultAddress !== '');
            // console.log("defaultAddressIndex[0].FormattedAddress", defaultAddressIndex[0].FormattedAddress)
            setSelectedDeliveryAddress(defaultAddressIndex[0].FormattedAddress)
            // console.log("contextVariable.accountDetails.CustomerAddressInformation[0]", defaultAddressIndex[0])
            setcustomerAddress(defaultAddressIndex[0]);
        }


    }, [contextVariable.accountDetails, userAddress]);

    //UseEffect for Getting ProductCartItems details
    useEffect(() => {
        if (contextVariable.userDetails !== "") {
            fetchCartProductCount();
        }
        //eslint-disable-next-line
    }, [contextVariable.UserCartIdDetails]);

    // Handling back navigation for mobile
    const location = useLocation();
    useEffect(() => {
        if (contextVariable) {
            contextVariable.setShowView((prev) => ({
                showOrderDetails: false,
                showDeliveryDetails: false,
                showPaymentHistoryDetails: false,
                showCreditNoteDetails: false,
                showInvoiceDetails: false,
                showInvoicPayment: false
            }));
        }
        //eslint-disable-next-line
    }, [location.pathname])

    // console.log("contextvariable", contextVariable)
    const handlePlaceOrder = async () => {
        let OutofStockArray = [];
        if (contextVariable.taxDetails.InventoryStatus) {
            OutofStockArray = CartProducts.filter(p => p.ProductStock === 0)
        }
        if (OutofStockArray.length === 0) {
            setLoading(true);
            setPlaceOrdersListLoading(true);
            contextVariable.setAddRemoveCartLoading(true);
            // const ProductDetails = CartProducts.map((element) => ({
            //     ProductId: element.Productdetails.id,
            //     ProductQuantity: element.ProductQuantity,
            //     ProductQuantityName: element.Productdetails.ProductUOM
            // }));

            const CustomerAddress = {
                AccountID: accountDetails.AccountID,
                AccountName: accountDetails.AccountName,
                CityName: customerAddress.CityName,
                CountryCode: customerAddress.CountryCode,
                CountryName: customerAddress.CountryCodeText,
                DistrictName: "1",
                Email: customerAddress.Email,
                HouseID: customerAddress.HouseID,
                PhoneNumber: customerAddress.ConventionalPhone,
                RegionCode: customerAddress.RegionCode,
                StreetName: customerAddress.StreetName,
                StreetPostalCode: customerAddress.StreetPostalCode
            }

            const UserCartTableID = contextVariable.UserCartIdDetails[0].id;
            const BuyerID = FormValue.PORef;
            const Notes = FormValue.Notes

            const payload = {
                // ProductDetails: ProductDetails,
                CustomerAddress: CustomerAddress,
                UserCartTableID: UserCartTableID,
                BuyerID: BuyerID,
                Notes: Notes
            };
            // console.log("payload", payload)
            try {
                // if (payload.ProductDetails && payload.CustomerAddress && payload.BuyerID) {
                if (payload.CustomerAddress) {
                    const response = await API.graphql({
                        query: CreatePlaceOrder,
                        variables: {
                            input: payload
                        }
                    });


                    // console.log("placeorder response", response)

                    const PlaceOrderResponseChange = [{
                        ...response.data.CreatePlaceOrder,
                        id: response.data.CreatePlaceOrder.UserCartTableID,
                        CartStatus: response.data.CreatePlaceOrder.PlaceOrderStatus,
                        SalesOrderId: ""
                    }]

                    // console.log("PlaceOrderResponseChange", PlaceOrderResponseChange)

                    if (response.data.CreatePlaceOrder.PlaceOrderStatus === "InProgress") {
                        setPlaceOrdersListLoading(false);
                        setLoading(false);
                        contextVariable.setCartStatusView("OrderInProgress");
                        contextVariable.setAddRemoveCartLoading(false);
                        contextVariable.setCartCount(0);
                        contextVariable.setUserCartIdDetails(PlaceOrderResponseChange);
                        setFormValue({
                            PORef: "",
                            Notes: ""
                        })


                    }


                }
                else {
                    showToast(t(`Invalid data provided for placing the order.`), "error");
                    setLoading(false);
                    setPlaceOrdersListLoading(false);
                    contextVariable.setAddRemoveCartLoading(false);
                }
                setOpenDialog(false);
            }
            catch (error) {
                showToast(t(`Something went wrong. Please try placing your order again or contact admin for assistance.`), "error");
                setLoading(false);
                contextVariable.setAddRemoveCartLoading(false);
                console.error("Place Order error", error);
                contextVariable.setUserCartIdDetails(contextVariable.UserCartIdDetails);
                contextVariable.setUserCartItems(contextVariable.UserCartItems);
                contextVariable.setCartStatusView("ProductsInTheCart");
                setPlaceOrdersListLoading(false);
            }
        }
        else
            if (OutofStockArray.length > 0) {
                showToast(t(`Please place your order before removing out-of-stock products from your cart.`), "info", 3000)
            }


    }



    const removeView = (props) => {
        const BalanceProudct = CartProducts.filter((p) => p.id !== props.id)
        // console.log("balanceProduct", BalanceProudct)
        setCartProducts(BalanceProudct);
        TotalSummeryFn(BalanceProudct);
    }

    const [totalSummary, setTotalSummery] = useState(0);

    const TotalSummeryFn = (updatedProducts) => {
        let newTotalSummary = 0;
        for (const item of updatedProducts) {
            // console.log("updatedproudcts", updatedProducts)
            if (
                item &&
                item.Productdetails.ProductPrice !== null &&
                item.Productdetails.ProductPrice > 0 &&
                item.Productdetails.ProductsDiscounts &&
                item.Productdetails.ProductsDiscounts.items &&
                item.Productdetails.ProductsDiscounts.items.length > 0
            ) {
                const ProductDiscount = Math.abs(parseFloat(item.Productdetails.ProductsDiscounts.items[0].ProductDiscount));
                const ProductPriceAfterDiscount = parseFloat(item.Productdetails.ProductPrice) - (parseFloat(item.Productdetails.ProductPrice) * ProductDiscount / 100)
                const summary = item.ProductQuantity * ProductPriceAfterDiscount * (item.ProductPriceUOMCode === item.Productdetails.ProductPriceQuantityCode ? 1 : item.UOMCodeQuantity);
                newTotalSummary += summary;

            }
            else {
                const summary = item.ProductQuantity * item.Productdetails.ProductPrice * (item.ProductPriceUOMCode === item.Productdetails.ProductPriceQuantityCode ? 1 : item.UOMCodeQuantity);
                newTotalSummary += summary;

            }
        }
        // console.log("newTotalSummary", newTotalSummary)
        setTotalSummery(newTotalSummary)
    }

    const TotalAmount = async (props) => {
        if (props.Update === "QtyUpdate") {
            setCartProducts((prevCartProducts) => {
                const updatedProducts = prevCartProducts.map(product =>
                    product.id === props.id
                        ? {
                            ...product, ProductQuantity: props.ProductQuantity,
                            UOMCodeQuantity: props.UOMCodeQuantity,
                            ProductStock: props.ProductStock,
                            CalculatedUOMQuantity: props.CalculatedUOMQuantity
                        }
                        : product
                );
                TotalSummeryFn(updatedProducts);

                return updatedProducts;
            });

        }
        else if (props.Update === "PriceAndDiscountUpdate") {
            setCartProducts((prevCartProducts) => {
                const updatedProducts = prevCartProducts.map(product =>
                    product.userProductsCartProductdetailsId === props.id
                        ? {
                            ...product,
                            ProductDiscount: props.ProductDiscount, ProductPrice: props.ProductPrice
                        }
                        : product
                );
                TotalSummeryFn(updatedProducts);
                return updatedProducts;
            });
            contextVariable.setUserCartItems((prevCartProducts) => {
                const updatedProducts = prevCartProducts.map(product =>
                    product.userProductsCartProductdetailsId === props.id
                        ? {
                            ...product,
                            ProductDiscount: props.ProductDiscount, ProductPrice: props.ProductPrice
                        }
                        : product
                );
                return updatedProducts;
            });

        }

    }


    const changeIsInputFocused = (props) => {
        setIsInputFocused(props);
    }
    const itemTemplate = (data1, layout, Loading) => {
        if (!data1) {
            return;
        }

        if (layout === "list") {
            return (
                <>
                    <PlaceOrderListView
                        data={data1}
                        removeView={removeView}
                        TotalAmount={TotalAmount}
                        TotalSummeryFn={TotalSummeryFn}
                        isInputFocused={changeIsInputFocused}
                        isLoadingEffect={Loading}
                        CartProducts={CartProducts}
                    >
                    </PlaceOrderListView>
                </>
            );
        }
    };

    const handleCloseDialogBox = () => {
        contextVariable.setDialogBox(false);

    }

    // const divClassName = PlaceOrdersListLoading ? "row loading-div loading-effect" : "row loading-div";

    const handlePoChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const { fadeIn } = useFade();

    const summeryValue = getSymbolFromCurrency(
        contextVariable.taxDetails.CurrencyCode
    )
    const totalamount = CurrencyFormat(totalSummary);

    const PlaceOrderHandling = (getData) => {
        setIsInputFocused(getData)
    }
    const [SelectedDeliveryAddress, setSelectedDeliveryAddress] = useState([]);
    const skeletonContainers = Array.from({ length: 6 }, (_, index) => (
        <div className="border-1 border-round-xl p-2 " key={index}>
            <div className="flex w-full gap-2 ">
                <div className="w-50">
                    <Skeleton animation="wave" className="border-round-xl w-100" variant="rectangular" height={130} />
                </div>
                <div className='w-50'>
                    <Skeleton animation="wave" className="border-round-xl w-100 " height={35} />
                    <Skeleton animation="wave" className="border-round-xl w-100 -mt-2" height={35} />
                    <Skeleton animation="wave" className="border-round-xl w-100 -mt-2" height={35} />
                    <Skeleton animation="wave" className="border-round-xl w-100 -mt-2 " height={35} />
                </div>
            </div>
        </div>
    ));

    const navigate = useNavigate();
    const [isInactive, setIsInactive] = useState(false);

    // useeffect for more then 5 min in the cart page its navigate to catalog page 
    useEffect(() => {


        let timeout;

        const resetTimeout = () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => setIsInactive(true), 300000);

        };

        const handleUserActivity = () => {
            setIsInactive(false);
            resetTimeout();
        };

        const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

        events.forEach((event) =>
            window.addEventListener(event, handleUserActivity)
        );

        resetTimeout();

        return () => {
            // Clean up the event listeners and timeout on component unmount
            events.forEach((event) =>
                window.removeEventListener(event, handleUserActivity)
            );
            if (timeout) clearTimeout(timeout);
        };
    }, []);


    useEffect(() => {
        if (isInactive) {
            navigate('/productCatalog');
        }
    }, [isInactive, navigate]);


    useEffect(() => {
        if (contextVariable.SalesBlockList === true) {
            showToast(t(`Orders has been blocked by ${contextVariable.taxDetails.CompanyName}. Please contact admin.`))
        }
    }, [contextVariable.SalesBlockList])




    return (
        <>
            {/* Web  app view  */}
            <OverlayPanel ref={op} className='w-auto md:w-22rem'>
                <div>
                    <div>
                        <span className='font-semibold mb-4'>Charges Info</span>
                    </div>
                    <hr />
                    <div className='mt-1'>
                        <span className="text-xs md:text-sm">
                            The gross charge includes any additional charges, such as <strong>freight, service charges</strong> etc, if applicable.
                            Please <strong>refer to the Order PDF</strong> for any price changes.
                        </span>
                    </div>
                </div>
            </OverlayPanel>

            {!contextVariable.isMobile &&
                <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`} onClick={handleCloseDialogBox}>
                    {contextVariable.pageInfo === "PlaceOrder" && <InfoBar header="Place Order"></InfoBar>}
                    <PriceUpdateDialog />

                    {contextVariable.CartStatusView === "EmptyCart" && <>
                        <Link to={'/productCatalog'}>
                            <Button className="secondary-button p-button-sm mb-2"
                                icon={contextVariable.rtl ? 'pi pi-arrow-right' : 'pi pi-arrow-left'}
                                label={t('Go to Catalog')}
                            >
                            </Button>
                        </Link>
                        <div className='flex justify-content-center'>
                            <img style={{ height: "70vh" }} src={CheckOut} alt="checkout" /></div>
                        <span style={{ marginTop: "-100px" }} className='flex justify-content-center font-semibold text-lg'>{t('Your cart is empty')}</span>
                    </>}

                    {contextVariable.CartStatusView === "ProductsInTheCart" &&
                        <div>
                            {
                                PlaceOrdersListLoading &&
                                <div className='place-oreder-loading'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div style={{ marginTop: '20%' }}>
                                            <div className='text-center'><Loader /></div> <br />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div dir={contextVariable.rtl ? 'rtl' : 'ltr'} className='flex justify-content-between align-items-center'>
                                <div>
                                    <Link to={'/productCatalog'}>
                                        <Button
                                            className="secondary-button p-button-sm mb-2"
                                            icon={contextVariable.rtl ? 'pi pi-arrow-right' : 'pi pi-arrow-left'}
                                            label={t('Go to Catalog')}
                                        >
                                        </Button>
                                    </Link>
                                </div>
                                <div
                                    onClick={() => contextVariable.setPageInfo("PlaceOrder")}
                                    className='cursor-pointer border-1 border-secondary-800 border-round-lg py-1 px-2 flex justify-content-center gap-1 align-items-center'>
                                    <InfoRounded className='text-base text-gray-400' />
                                    <span>Info</span>
                                </div>

                            </div>

                            <div dir={contextVariable.rtl ? 'rtl' : 'ltr'} className='container-fluid'>
                                <div className='grid custom-grid justify-content-center md:justify-content-start gap-2'>
                                    <div className='col-12 md:col-12 w-20rem sm:23rem md:w-full mt-2 border-2 border-round-md' style={{ height: "85vh", overflow: "auto" }}>
                                        {/* Items Accordion */}
                                        {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2bh-content"
                                                id="panel2bh-header"
                                            >
                                                <span className='text-lg md:text-2xl lg:3xl font-semibold'>Items</span>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                                <DataView
                                                    value={CartProducts}
                                                    layout="list"
                                                    itemTemplate={itemTemplate}
                                                    layoutoptions={{ divider: false }}
                                                    loading={PlaceOrdersListLoading}
                                                />
                                            </AccordionDetails>
                                        </Accordion> */}
                                        <DataView
                                            value={CartProducts}
                                            layout="list"
                                            itemTemplate={itemTemplate}
                                            layoutoptions={{ divider: false }}
                                            loading={PlaceOrdersListLoading}
                                        />
                                    </div>

                                    {/* Summary Section */}
                                    <div className='col-12 md:col-12'>
                                        <div>
                                            <div className='mb-1'>
                                                <span className='text-lg md:text-2xl lg:3xl font-semibold'>Summary</span>
                                            </div>
                                            <Divider sx={{ backgroundColor: 'var(--secondary-color)' }} />
                                            <div className='col-sm-12'>
                                                <Divider sx={{ backgroundColor: 'var(--secondary-color)' }} />

                                                <div className='row mt-4 mb-1'>

                                                    <div className='col-4 text-right'>
                                                        <label className="mt-1 text-xs md:text-base lg:text-sm font-semibold lg:w-max">Deliver To* : </label>
                                                    </div>
                                                    <div className='col-7'>
                                                        <Tooltip
                                                            title={SelectedDeliveryAddress}
                                                            componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: 'var(--sidebar-color)',
                                                                        fontSize: '12px'
                                                                    },
                                                                },
                                                            }}
                                                            placement="bottom"
                                                            TransitionComponent={Zoom}
                                                            enterDelay={500}
                                                        >
                                                            <Autocomplete
                                                                disabled={!userAddress}
                                                                size='small'
                                                                value={(userAddress && userAddress.find(
                                                                    (option) => option.FormattedAddress === SelectedDeliveryAddress
                                                                )) || null}
                                                                onChange={(e, value) => {
                                                                    setSelectedDeliveryAddress(value?.FormattedAddress || "");
                                                                    setcustomerAddress(value || null);
                                                                }}
                                                                options={userAddress || []}
                                                                getOptionKey={(option) => option.FormattedAddress_ObjectID}
                                                                getOptionLabel={(option) => option.FormattedAddress}
                                                                renderInput={(params) => <TextField {...params}
                                                                    helperText={SelectedDeliveryAddress === "" ? "Please select a delivery address" : ""}
                                                                    error={SelectedDeliveryAddress === ""}
                                                                    FormHelperTextProps={{
                                                                        style: { color: SelectedDeliveryAddress === "" ? 'red' : 'inherit' }
                                                                    }}
                                                                />}
                                                            />
                                                        </Tooltip>
                                                    </div>

                                                    <div className='col-4 text-right'>
                                                        <label className='mt-1 text-xs md:text-base lg:text-sm font-semibold lg:w-max'>PO Reference : </label>                                            </div>
                                                    <div className='col-7'>
                                                        <input
                                                            // value={FormValue.PORef}
                                                            name='PORef'
                                                            type='text'
                                                            className='form-control form-control-sm po-reference w-11rem md:w-20rem lg:w-12rem'
                                                            onChange={handlePoChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mb-4'>
                                                    <div className='col-4 text-right'>
                                                        <label className='mt-2 text-xs md:text-base lg:text-sm font-semibold lg:w-max'>Notes : </label>
                                                    </div>
                                                    <div className='col-7'>
                                                        <textarea rows={2} cols={32}
                                                            name='Notes'
                                                            onChange={handlePoChange}
                                                            className='form-control form-control-sm po-reference w-11rem md:w-20rem lg:w-12rem'>
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <Divider sx={{ backgroundColor: 'var(--secondary-color)' }} />

                                                {CartProducts &&
                                                    <div className="row mt-4">
                                                        <div className='col-sm-12 col-form-label'>
                                                            <div className='flex gap-2 justify-content-start align-items-center'>
                                                                <div className='flex gap-1 lg:w-max'>
                                                                    <div className='line-height-1'>
                                                                        <label className="text-base md:text-lg font-semibold">
                                                                            Subtotal
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <span className='text-xs'>{" "}
                                                                            ({CartProducts.length} Item(s))
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='flex gap-4 align-items-bottom'>
                                                                    <span className="ml-2 font-bold text-xl">
                                                                        {totalSummary ? getSymbolFromCurrency(
                                                                            contextVariable.taxDetails.CurrencyCode
                                                                        ) : <span className='text-sm'>Calculating...</span>}
                                                                        {totalSummary ? CurrencyFormat(totalSummary) : ""}
                                                                    </span>

                                                                    <div
                                                                        onClick={(e) => op.current.toggle(e)}
                                                                        className='flex cursor-pointer justify-content-center gap-1 align-items-center'>
                                                                        <InfoRounded fontSize='small' className=' text-gray-400' />
                                                                        <span className='text-xs d-none md-d-block'>Charges</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    contextVariable.taxDetails.Tax === "InclusiveTax" ?
                                                                        <>
                                                                            <span className="text-red-700 text-xs md:text-xs font-semibold">
                                                                                *Inclusive of all taxes
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <span className="text-red-700 text-xs md:text-xs font-semibold">
                                                                                *Exclusive of all taxes
                                                                            </span>
                                                                        </>
                                                                }
                                                            </div>

                                                            {/* <span className='text-xs italic ml-2'>*Inclusive of all taxes</span> */}
                                                        </div>
                                                    </div>}
                                                {!CartProducts && <div className="row">
                                                    <div className='col-sm-8 col-form-label text-base'>
                                                        <label className=""> Empty </label>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className='col-md-12 mt-4'>

                                                <Button
                                                    className='primary-button placeorder-button'
                                                    raised

                                                    loading={loading}
                                                    style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    onClick={handlePlaceOrder}
                                                    disabled={!CartProducts.length > 0 || isInputFocused ||
                                                        !userAddress || SelectedDeliveryAddress === "" || totalSummary === 0
                                                        || contextVariable.SalesBlockList === true
                                                    }
                                                >
                                                    {!loading && <CardTravelOutlined />}&nbsp;
                                                    <span style={{ fontWeight: '600' }}>
                                                        {
                                                            loading ? 'Placing your order' : 'Place Order'
                                                        }
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                    {contextVariable.CartStatusView === "OrderInProgress" && <>
                        <Link to={'/productCatalog'}>
                            <Button
                                icon="pi pi-arrow-left"
                                label={t('Go to Catalog')}
                                className="secondary-button p-button-sm mb-2"
                            >
                            </Button>
                        </Link>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "80vh" }}>
                                <img style={{ maxWidth: "100%", height: "auto", maxHeight: "50vh" }} src={ProcessGif} alt="" />
                                <div style={{ display: "flex", justifyContent: "center", fontSize: "26px", color: "var(--success-color)" }} >
                                    Your order request is saved and under processing!
                                </div>
                                {/* <Link to={"/order"}>
                                <Button
                                    className='primary-button placeorder-button'
                                    raised
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}
                                >
                                    Go To Orders
                                    <ArrowForward />
                                </Button>
                            </Link> */}
                            </div>
                        </div>

                    </>
                    }

                    {contextVariable.CartStatusView === "OrderRequestPlaced" && <>
                        <ConfettiEffect />
                        <Link to={'/productCatalog'}>
                            <Button
                                className="secondary-button p-button-sm mb-2"
                                icon="pi pi-arrow-left"
                                label={t('Go to Catalog')}
                            >
                            </Button>
                        </Link>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "80vh" }}>
                                <div style={{ fontSize: "20px" }}>
                                    <span>Your order request has been processed with Order ID:
                                        <span className='font-bold'> {contextVariable.SalesOrderIdwithPlaced}
                                            {/* {OrderResponse.data.CreatePlaceOrder.SalesOrderId} */}
                                        </span>
                                    </span>
                                </div>
                                {/* <img style={{ maxWidth: "100%", height: "auto", maxHeight: "50vh" }} src={orderedImage} /> */}
                                <img style={{ maxWidth: "100%", height: "auto", maxHeight: "50vh" }} src={orderedImage} alt="orderImage" />
                                {/* <div style={{ display: "flex", justifyContent: "center", fontSize: "26px", color: "var(--success-color)" }} >
                            Your order request is saved and being processed!
                        </div> */}
                                <Link to={"/order"}>
                                    <Button
                                        className='primary-button placeorder-button'
                                        raised
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}
                                    // onClick={contextVariable.setIsFiltered(false)}
                                    >
                                        View Order {contextVariable.SalesOrderIdwithPlaced}
                                        {/* {OrderResponse.data.CreatePlaceOrder.SalesOrderId} */}
                                        {/* Go To Sales Order <ArrowForward /> */}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </>
                    }

                </Box>}

            {contextVariable.isMobile &&
                <div>
                    {/* Cart Top bar   */}
                    <div style={{ maxHeight: "10vh" }}>
                        <CardTopbar
                            header={`Cart`}
                            link="productCatalog"
                            buttonNeeded={true}
                        />
                    </div>

                    {contextVariable.CartStatusView === "ProductsInTheCart" &&
                        <>
                            {/* Cart items list view */}
                            {/* Cart Cards part  */}
                            <div className="border-bottom-2" style={{ maxHeight: "75vh", overflowY: "auto" }} >
                                <CartCard removeView={removeView} TotalAmount={TotalAmount}
                                    PlaceOrderHandling={PlaceOrderHandling} CartProducts={CartProducts} />
                            </div>

                            {/* Placeorder button */}
                            <div className='p-2' style={{ position: "absolute", bottom: 80, zIndex: "100", width: "100%" }}>
                                <button
                                    disabled={!contextVariable.UserCartItems.length > 0 || isInputFocused || !userAddress || SelectedDeliveryAddress === "" || totalSummary === 0 || contextVariable.SalesBlockList === true}
                                    onClick={() => setOpenDialog(true)}
                                    className='custom-place-order-btn bg-primary pb-2 w-100 text-base font-semibold border-round-md focus:outline-none'
                                >
                                    <div className='flex justify-content-center align-items-center gap-2'>
                                        <div>
                                            <ShoppingCartCheckoutOutlined />
                                        </div>
                                        <div className='pt-1'>
                                            <span>Place Order</span>
                                        </div>
                                    </div>
                                </button>
                            </div>

                            {/* Order confirmation sidebar */}
                            <Sidebar
                                header={customHeader}
                                visible={openDialog}
                                onHide={() => setOpenDialog(false)}
                                position='bottom'
                                style={{ height: "25rem" }}
                            >
                                <div>
                                    {/* {
                                    PlaceOrdersListLoading &&
                                    <div style={{ backgroundColor: 'rgba(100, 100, 100, 0.2)' }} className='z-0 absolute w-100 flex justify-content-center align-items-center h-screen'>
                                        <Loader />
                                    </div>
                                } */}

                                    <div style={{ pointerEvents: PlaceOrdersListLoading ? 'none' : 'auto' }}>
                                        {/* Summary Section  */}
                                        <div className='' style={{ maxHeight: "40vh" }}>
                                            {/* Address */}
                                            <div className='flex justify-content-between align-items-center mt-2'>
                                                <div className=''>
                                                    <span className='text-sm sidebar-color'>Delivery To</span>
                                                </div>
                                                <div className='w-75'>
                                                    <Autocomplete
                                                        disabled={!userAddress}
                                                        size='small'
                                                        value={(userAddress && userAddress.find(
                                                            (option) => option.FormattedAddress === SelectedDeliveryAddress
                                                        )) || null}
                                                        onChange={(e, value) => {
                                                            setSelectedDeliveryAddress(value?.FormattedAddress || "");
                                                            setcustomerAddress(value || null);
                                                        }}
                                                        options={userAddress || []}
                                                        getOptionKey={(option) => option.FormattedAddress_ObjectID}
                                                        getOptionLabel={(option) => option.FormattedAddress}
                                                        renderInput={(params) => <TextField {...params}
                                                            helperText={SelectedDeliveryAddress === "" ? "Please select a delivery address" : ""}
                                                            error={SelectedDeliveryAddress === ""}
                                                            FormHelperTextProps={{
                                                                style: { color: SelectedDeliveryAddress === "" ? 'red' : 'inherit' }
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: null,
                                                            }}
                                                        />}
                                                    />
                                                </div>
                                            </div>
                                            {/* PO Reference */}
                                            <div className='flex justify-content-between gap-2 align-items-center mt-3'>
                                                <div className=''>
                                                    <span className='text-sm sidebar-color'>PO Reference</span>
                                                </div>

                                                <div className='w-75'>
                                                    <input
                                                        name='PORef'
                                                        type='text'
                                                        className='form-control form-control-sm po-reference w-full'
                                                        onChange={handlePoChange}
                                                    />
                                                </div>
                                            </div>
                                            {/* Notes */}
                                            <div className='flex justify-content-between align-items-center mt-3'>
                                                <div className='text-sm sidebar-color'>
                                                    Notes
                                                </div>
                                                <div className='w-75'>
                                                    <input
                                                        name='Notes'
                                                        onChange={handlePoChange}
                                                        className='form-control form-control-sm po-reference w-full'>
                                                    </input>
                                                </div>
                                            </div>
                                            {/* Subtotal */}
                                            <div className='flex justify-content-between align-items-center mt-3'>
                                                <div>
                                                    <div className='text-sm font-semibold sidebar-color'>
                                                        Subtotal
                                                    </div>

                                                </div>
                                                <div className='w-75'>
                                                    <input
                                                        disabled
                                                        className='form-control text-xl font-semibold form-control-sm po-reference border-none w-full'
                                                        value={summeryValue + ' ' + totalamount}

                                                    >
                                                    </input>
                                                    <div>
                                                        {
                                                            contextVariable.taxDetails.Tax === "InclusiveTax" ?
                                                                <div className='flex gap-2 align-items-center'>
                                                                    <div>
                                                                        <span className="text-red-700 text-xs ">
                                                                            *Inclusive of all taxes
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            onClick={(e) => op.current.toggle(e)}
                                                                        >
                                                                            <InfoRounded fontSize='small' className=' text-gray-400' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='flex gap-2 align-items-center'>
                                                                    <div>
                                                                        <span className="text-red-700 text-xs ">
                                                                            *Exclusive of all taxes
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            onClick={(e) => op.current.toggle(e)}
                                                                        >
                                                                            <InfoRounded fontSize='small' className=' text-gray-400' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full p-2 mt-3'>
                                    <Button
                                        disabled={!contextVariable.UserCartItems.length > 0 || isInputFocused || !userAddress || SelectedDeliveryAddress === "" || totalSummary === 0}
                                        loading={loading}
                                        onClick={handlePlaceOrder}
                                        size='small'
                                        icon="pi pi-check"
                                        className='w-100 text-base' label="Submit">
                                    </Button>
                                </div>
                            </Sidebar>
                        </>
                    }

                    {contextVariable.CartStatusView === "Loading" && <>
                        <div className="p-1  ">
                            {skeletonContainers}
                        </div>

                    </>}

                    {contextVariable.CartStatusView === "EmptyCart" && <>

                        <div className='flex flex-column justify-content-center' style={{ height: "75vh" }}>
                            <div className="flex justify-content-center">
                                <img style={{ height: "45vh" }} src={CheckOut} alt="No Products" />

                            </div>
                            <span className="flex justify-content-center font-semibold text-sm -mt-5">{t('Your cart is Empty')}</span>
                        </div>
                    </>}

                    {contextVariable.CartStatusView === "OrderInProgress" && <>

                        <div className='flex flex-column justify-content-center align-items-center  mt-8'>
                            <div>
                                <img className='h-full' src={ProcessGif} alt="" />
                            </div>
                            <div style={{ color: "var(--success-color)" }} className='text-base'>
                                Your order request is saved and under processing!
                            </div>

                        </div>

                    </>
                    }

                    {contextVariable.CartStatusView === "OrderRequestPlaced" && <>
                        <ConfettiEffect />

                        <div className='flex flex-column justify-content-center align-items-center mt-8'>
                            <div className='text-base'>
                                <div>
                                    <span >Your order request has been processed.</span>
                                </div>
                                <div>
                                    Order ID: <span className='font-bold'> {contextVariable.SalesOrderIdwithPlaced}</span>
                                </div>
                            </div>

                            <div>
                                <img src={orderedImage} alt="orderImage" />
                            </div>

                            <div>
                                <Link to={"/order"}>
                                    <Button
                                        className='primary-button placeorder-button'
                                        raised
                                    >
                                        View Order {contextVariable.SalesOrderIdwithPlaced}

                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </>
                    }
                </div>
            }
        </>
    )
}

export default PlaceOrder;