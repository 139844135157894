import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../Layout/Layout';
import { TabPanel, TabView } from 'primereact/tabview';

export default function AdminTapBar() {

  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const navigate = useNavigate();
  const [view, setview] = useState({ SAPAndStripeDetails: false, ProductsImageUpload: false, ProductsView: false, ContectDetails: false, NotificationsView: false })

  useEffect(() => {
    if (contextVariable.OrganizationDetails === "Loading") {
      setview({ SAPAndStripeDetails: false, ProductsImageUpload: false, ProductsView: false, ContectDetails: false })
    }
    else if (contextVariable.OrganizationDetails === "Empty") {
      setview({ SAPAndStripeDetails: false, ProductsImageUpload: false, ProductsView: false, ContectDetails: false })
    }
    else if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.PriceDefaultIndicator === "false") {
      if (contextVariable.OrganizationDetails.SAPCredentialIndicator === "false" || contextVariable.OrganizationDetails.SAPCredentialIndicator === "true") {
        setview({ SAPAndStripeDetails: true, ProductsImageUpload: false, ProductsView: false, ContectDetails: false })
      }
      else {
        setview({ SAPAndStripeDetails: false, ProductsImageUpload: false, ProductsView: false, ContectDetails: false })
      }

    }
    else if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.PriceDefaultIndicator === "true") {
      if (contextVariable.OrganizationDetails.SAPCredentialIndicator === "false") {
        setview({ SAPAndStripeDetails: true, ProductsImageUpload: false, ProductsView: false, ContectDetails: false })
      }
      else {
        setview({ SAPAndStripeDetails: true, ProductsImageUpload: true, ProductsView: true, ContectDetails: true, NotificationsView: true })
      }
    }

  }, [contextVariable.OrganizationDetails])

  //handle Change for Tab view
  const handleTabChange = (e) => {
    const newValue = e.index;
    if (newValue === 0) {
      navigate("/customerportalsetup");
    }
    else if (newValue === 1) {
      navigate("/customerportalsetup/SAPandStripeDetails");
    }
    else if (newValue === 2) {
      navigate("/customerportalsetup/contactDetails");
    }
    else if (newValue === 3) {
      navigate("/customerportalsetup/productImageUpload");
    }
    else if (newValue === 4) {
      navigate("/customerportalsetup/productImageView");
    }
    else if (newValue === 5) {
      navigate("/customerportalsetup/notificationsView");
    }
    contextVariable.setTapPage(newValue);
    sessionStorage.setItem("TapPagNumber", newValue)

  };

  return (
    <Box className="admin-tapbar-container">

      <TabView activeIndex={contextVariable.TapPage} onTabChange={handleTabChange} className='m-2 uppercase text-xs'>
        <TabPanel header={t("general")} className='focus:bg-red text-white'>
        </TabPanel>

        {/* SAP & Stripe Details */}
        {view.SAPAndStripeDetails && <TabPanel header={t('SAP Credentials & Stripe Details')}
        >
        </TabPanel>}
        {/* contact details */}
        {view.ContectDetails && <TabPanel header={t('Contact Details')} >
        </TabPanel>}

        {/* Product Image Upload */}
        {view.ProductsImageUpload && <TabPanel header={t('Product Image Upload')} >
        </TabPanel>}

        {/* product Image View */}
        {view.ProductsView && <TabPanel header={t('Products View')} >
        </TabPanel>}

        {/* Notifications View */}
        {view.NotificationsView && <TabPanel header={t('Notifications')} >
        </TabPanel>}

      </TabView>

    </Box>
  )
}
