import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext } from '../../Layout/Layout';
import ZipFileImage from './Images/zipFile.png';
import MaximumFileSize from './Images/MaximumFileSize.png';
import NamingConvention from './Images/NamingConvention.png';
import { useTranslation } from 'react-i18next';

const AdminDialogInstructions = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [displayResponsive, setDisplayResponsive] = useState(contextVariable.InstructionsDialog);
    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setInstructionsDialog(false);
    }



    const renderFooter = (name) => {
        return (
            <div className='flex justify-content-end align-items-baseline'>
                <Button
                    className='primary-button btn'
                    onClick={() => onHide('displayResponsive')}
                >
                    Got it
                </Button>
            </div>
        );
    }
    return (
        <>
            <Dialog header={props.header} visible={displayResponsive} dismissableMask={true}
                onHide={() => onHide('displayResponsive')}
                breakpoints={{ '960px': '75vw' }}
                style={{ width: '50vw' }}
                footer={renderFooter('displayResponsive')}
            >

                {["MoreInstractions"].includes(contextVariable.InstructionsDialog) &&
                    <>
                        <div className='grid gap-2 w-full mt-1'>

                            <div className='card card-shadow col'>
                                <span className='flex justify-content-center align-items-center text-lg'>{t(`File Format`)}</span>
                                <div className='flex justify-content-center align-items-center'>
                                    <img src={ZipFileImage} alt="ZipFileImage" />
                                </div>
                                <div className=' m-2'>


                                    <span>
                                        {t(`All images included in the`)} <span className='font-semibold'>{t(`zip file must be in the PNG or JPEG format`)}</span>. {t(`Please ensure that each image is saved with the ".png" or ".jpeg" extension.`)}
                                    </span>
                                </div>
                            </div>

                            <div className='card card-shadow w-4 flex col'>
                                <span className='flex justify-content-center align-items-center text-lg'>{t(`Maximum File Size`)}</span>
                                <div className='flex justify-content-center align-items-center'>
                                    <img src={MaximumFileSize} alt="MaximumFileSize" />
                                </div>
                                <div className=' m-2'>
                                    <span>
                                        The total size of the <span className='font-semibold'>zip file should not exceed 200 megabytes (MB)</span>. Please make sure that the sum of the sizes of all PNG or JPEG files does not exceed this limit.
                                    </span>
                                </div>
                            </div>

                            <div className='card card-shadow w-4 flex col'>
                                <span className='flex justify-content-center align-items-center text-lg'>Naming Convention</span>
                                <div className='flex justify-content-center align-items-center'>
                                    <img src={NamingConvention} alt="NamingConvention" />
                                </div>
                                <div className=' m-2 flex row'>
                                    <span>
                                        <p className='font-semibold text-sm'> Product ID : </p>
                                        <p className='text-sm'>
                                            For each image, prepend the file name with the product's unique identifier (Product ID).
                                        </p>
                                    </span>
                                    <span>
                                        <p className='font-semibold text-sm'>
                                            Example:
                                        </p>
                                        <p className='text-sm'>
                                            If the Product ID is "12345" name it as "12345.png."
                                        </p>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </>
                }
            </Dialog>
        </>
    )
}

export default AdminDialogInstructions;