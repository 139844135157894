import { Box, Tooltip, Zoom } from '@mui/material';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { AppContext } from '../../../../Layout/Layout';
import AdminGridData from '../../../../components/Admin/AdminGridData';
import { API, Storage } from 'aws-amplify';
import { useEffect } from 'react';
import AdminDialogChangeImage from '../../../../components/Admin/AdminDialogChangeImage';
import { UpdateTrending } from '../../../../components/Admin/AdminCUDFunctions';
import DiscountCustomerPage from './DiscountCustomerPage';
import Loader from '../../../../components/Loader';
// import { CountInDashBoard } from '../../../../components/Admin/AdminListDetailsFunction';
import ImageUnavailable from '../../../ProductCatalog/Images/Image_not_available.png';
import CurrencyFormat from '../../../../components/CurrencyFormat';
// import { showToast } from '../../../../components/ToastUtils';
import AdminDialogComponent from '../../../../components/Admin/AdminDialogComponent';
import { updateProductsList } from '../../../../graphql/mutations';


const ProductImageRenderer = (props) => {
  // console.log("props",props)
  const contextVariable = useContext(AppContext);
  const [imageUrl, setImageUrl] = useState(null);

  const fetchData = async () => {
    try {
      const imageKey = props.value.split("public/")[1];
      const authorizedUrlss = await Storage.get(imageKey);
      setImageUrl(authorizedUrlss);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line 
  }, [props.value]);

  const handleEditImage = async () => {
    fetchData();

    contextVariable.setShow("ImageEdit");
    contextVariable.setEditImageData(props);
  };

  return (
    <div className='flex justify-content-center gap-2 mt-2 w-50'>
      {imageUrl ? (
        <img className='w-25' src={imageUrl} alt=""
          onError={(e) => {
            e.target.src = ImageUnavailable;
            e.target.alt = "";
          }} />
      ) : (
        <div className='w-25 mt-2'>
          <Loader />
        </div>
      )}
      <Edit className='cursor-pointer' onClick={handleEditImage} />
    </div>
  );
};

export default function ProductImageView() {
  const contextVariable = useContext(AppContext);
  const { t } = useTranslation();
  const [ProductListDetails, setProductListDetails] = useState(1);
  const [DiscoutPageView, setDiscountPageView] = useState(false);
  const [TrendingLoading, setTrendingLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const productDetails = contextVariable.ProductListDetails;
      if (productDetails && productDetails.length > 0) {
        // console.log("productDetails", productDetails)
        setProductListDetails(productDetails);
      }
      else if (productDetails === 0) {
        setProductListDetails(0);
        contextVariable.setShow("ProductFirstSync");
      }
    };

    fetchData();
    // eslint-disable-next-line 
  }, [contextVariable.ProductListDetails])

  const Refresh = async () => {
    setProductListDetails(1);
    await contextVariable.fetchProductDetails();
    // await contextVariable.FetchProductDetailsLoadMoreUse();
  }

  const UpdateActiveStatus = async (ParamsData, contextVariable, UpdateAndSetAllProducts, setTrendingLoading) => {
    setTrendingLoading(true)
    const ActiveSelection = ParamsData.ProductActiveStatus === null ? true : ParamsData.ProductActiveStatus === false ? true : false;
    try {
      const DataOfUpdate = {
        id: ParamsData.id,
        ProductActiveStatus: ActiveSelection
      }

      const UpdatedActiveResponse = await API.graphql(
        {
          query: updateProductsList,
          variables: {
            input: DataOfUpdate
          }
        }

      )

      if (UpdatedActiveResponse) {
        const UpdateProductActive = contextVariable.ProductListDetails.map((p) => {
          if (p.id === UpdatedActiveResponse.data.updateProductsList.id) {
            return { ...p, ProductActiveStatus: ActiveSelection }
          }
          else {
            return p;
          }
        });
        UpdateAndSetAllProducts(UpdateProductActive);
        setTimeout(() => {

          setTrendingLoading(false);
        }, 2000);
      }

    }
    catch (error) {
      console.error("Update Enable  Error", error);
      setTrendingLoading(false);
    }
  }

  const columnDefs = [
    {
      field: "ProductImage",
      headerName: " Product Image",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: ProductImageRenderer
    },
    {
      field: "ProductId",
      headerName: " Product ID",
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "ProductName",
      headerName: "Product Description",
      filter: true,
      flex: 0.4,
      headerClass: "custom-ag-header",
      sortable: true,
      tooltipField: "ProductName"
    },
    {
      field: "ProductPrice",
      headerName: `Base Price`,
      filter: true,
      flex: 0.2,
      headerClass: "custom-ag-header",
      sortable: true,
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        return CurrencyFormat(params.value) + " " + params.data.ProductPriceCurrencyCode;
      }
    },
    {
      field: "ProductUOM",
      headerName: "Base UOM",
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "ProductPriceQuantity",
      headerName: `Price Quantity`,
      filter: true,
      flex: 0.2,
      headerClass: "custom-ag-header",
      sortable: true,
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        return CurrencyFormat(params.value) ;
      }
    },
    {
      field: "ProductPriceUOM",
      headerName: "Price UOM",
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
    },

    // Fields for Base Price CSV Download
    {
      field: "ProductPriceCSV",
      headerName: `ProductPrice`,
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
      hide: true,
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        return CurrencyFormat(params.value);
      }
    },
    {
      field: "ProductPriceCurrencyCode",
      headerName: `ProductPriceCurrencyCode`,
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
      cellStyle: { textAlign: 'right' },
      hide: true,
      cellRenderer: (params) => {
        return contextVariable.OrganizationDetails.CurrencyCode;
      }
    },
    {
      field: "ProductPriceCurrencyCodeText",
      headerName: `ProductPriceCurrencyCodeText`,
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
      cellStyle: { textAlign: 'right' },
      hide: true,
      cellRenderer: (params) => {
        return contextVariable.OrganizationDetails.CurrencyCodeText;
      }
    },
    ///////////////////
    {
      field: "ProductStatus",
      headerName: "Trending Product",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: (params) => {
        const UpdateAndSetAllProducts = (UpdatedData) => {
          contextVariable.setProductListDetails(UpdatedData)
        }

        return (<>
          <div className='mt-1'>
            <Tooltip componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'var(--sidebar-color)',
                  fontSize: '12px'
                },
              },
            }}
              title={params.data.ProductStatus === "Trending" ? "Trending product" : "Non-trending product"}
              placement={params.data.ProductStatus === "Trending" ? "right" : "left"}
              TransitionComponent={Zoom}
              enterDelay={300}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={params.data.ProductStatus === "Trending"}
                  onChange={(e) => UpdateTrending(params.data, contextVariable, UpdateAndSetAllProducts, setTrendingLoading)}
                />
                <span className="slider"></span>
              </label>
            </Tooltip>
            {/* <InputSwitch
              className='p-1 border-circle'
              checked={params.data.ProductStatus === "Trending"}
              onChange={(e) => UpdateTrending(params.data, contextVariable, UpdateAndSetAllProducts)}
            /> */}
          </div></>);
      }

    },
    {
      field: "ProductActiveStatus",
      headerName: "Enable/Disable",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: (params) => {
        const UpdateAndSetAllProducts = (UpdatedData) => {
          contextVariable.setProductListDetails(UpdatedData)
        }

        return (<>
          <div className='mt-1'>
            <Tooltip componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'var(--sidebar-color)',
                  fontSize: '12px'
                },
              },
            }}
              title={params.data.ProductActiveStatus === null ? "Product disabled" : params.data.ProductActiveStatus === false ? "Product disabled" : "Product enabled"}
              placement={params.data.ProductActiveStatus !== null ? "right" : "left"}
              TransitionComponent={Zoom}
              enterDelay={300}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={params.data.ProductActiveStatus === false ? false : params.data.ProductActiveStatus === null ? false : true}
                  onChange={(e) => UpdateActiveStatus(params.data, contextVariable, UpdateAndSetAllProducts, setTrendingLoading)}
                />
                <span className="slider"></span>
              </label>
            </Tooltip>

          </div></>);
      }
    },
    // {
    //   field: "ProductStatus",
    //   headerName: "Trending Product ",
    //   filter: true,
    //   flex: 0.5,
    //   headerClass: "custom-ag-header",
    //   sortable: true,
    //   hide: true
    // },
  ]
  //set the Top bar Title
  sessionStorage.setItem("title", t("Products View"));

  const ShowDiscountCustomerPage = () => {
    setDiscountPageView(true)
  };
  const HideDiscountCustomerPage = () => {
    setDiscountPageView(false)
  };
  const selectedRowsHandler = (rows) => { };

  //For Hard back button click
  useEffect(() => {
    if (sessionStorage.getItem("TapPageNumber") !== "4") {
      const currentURL = window.location.href;
      if (currentURL.includes("/customerportalsetup/productImageView")) {
        contextVariable.setTapPage(4);
        sessionStorage.setItem("TapPagNumber", 4);
      }
    }
    // eslint-disable-next-line 
  }, [])

  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const RefreshTableRow = () => {
    setRefreshTrigger(!refreshTrigger)
  }
  return (
    <>
      <Box className="admin-box-container">
        {
          TrendingLoading &&
          <div className='ProudctTrendingChange-loading'>
            <div className='flex justify-content-center align-items-center'>
              <div style={{ marginTop: '20%' }}>
                <div className='text-center'><Loader /></div> <br />
              </div>
            </div>
          </div>
        }
        {
          contextVariable.show === "ProductFirstSync" &&
          <AdminDialogComponent
            header={t(`Info`)}
          />
        }

        {
          DiscoutPageView &&
          <DiscountCustomerPage HideDiscountCustomerPage={HideDiscountCustomerPage}>
          </DiscountCustomerPage>
        }

        {/* DataGrid Card  */}
        {
          !DiscoutPageView && <div className="card card-shadow">
            <div className="card-body">
              <AdminGridData
                rowData={ProductListDetails}
                columnDefs={columnDefs}
                component={"ProductsView"}
                ShowDiscountCustomerPage={ShowDiscountCustomerPage}
                tableHeight={"70vh"}
                // tableHeight={"68vh"}
                needFilters={true}
                refreshTrigger={refreshTrigger}
                Refresh={Refresh}
                showPagination={true}
                selectedRows={selectedRowsHandler}
                filter={"ProductsView"}
              // customProductIDFilter={customProductIDFilter}
              />
            </div>
          </div>
        }

        {contextVariable.show === "ImageEdit" && <AdminDialogChangeImage
          // header="Change Image"
          header={`Edit image for the product - ${contextVariable.EditImageData.data.ProductName} (${contextVariable.EditImageData.data.ProductId})`}
          title="Are you sure you want to save this?" selectedData={contextVariable.EditImageData} RefreshTableRow={RefreshTableRow}></AdminDialogChangeImage>}
      </Box>
    </>
  )
}
