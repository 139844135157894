import React, { useContext } from 'react'
import { AppContext } from '../../../Layout/Layout'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Button } from 'primereact/button';

function PriceUpdateDialog() {
    const contextVariable = useContext(AppContext);

    return (
        <Dialog open={contextVariable.DialogBox}>
            <DialogTitle style={{ backgroundColor: "var(--sidebar-color)", color: "white", fontWeight: 600, fontSize: "18px" }}>
                <div className="d-flex gap-1">
                    <h2>Note</h2>
                    <InfoOutlined></InfoOutlined>
                </div>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent style={{ fontWeight: 550, fontSize: "15px" }}>
                We've noticed price changes in your cart. Please review them before placing your order. If you have any questions, feel free to contact us. We appreciate your business.
            </DialogContent>
            <DialogActions style={{ fontWeight: 550, fontSize: "15px" }}>
                <Button onClick={() => contextVariable.setDialogBox(false)} autoFocus>Got it</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PriceUpdateDialog