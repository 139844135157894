import React from 'react';

const BackgroundSVG = () => {
    return (
        <div className="background-svg">
            {/* Your SVG code goes here */}
        </div>
    );
};

export default BackgroundSVG;
