import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, IconButton, Tooltip, Zoom, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme 
import '../GridData.css';
// import './AdminGridData.css';
import { Add, HighlightOffRounded, InfoRounded } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import Loader from '.././Loader';
import NoRowsToDisplay from '.././NoRowsToDisplay';
import { AppContext } from '../../Layout/Layout';
// import { Badge } from 'primereact/badge';
import AdminDialogInstructions from './AdminDialogInstructions';
import { showToast } from '../ToastUtils';
import { API, Storage } from 'aws-amplify';
import { Menu } from 'primereact/menu'
import { listLambdaLastRunTimes } from '../../Layout/AdminMutationsAndQueries';
import InfoBar from '../InfoBar';
import { updateNotificationList } from './AdminListDetailsFunction';
import UploadExcel from './Products View/PricingOptions';
import SyncButton from './SyncButton';
import { GetByBasePriceCSVDownload, GetByDiscountCSVDownload, GetByPriceCSVDownload } from '../../graphql/queries';

const AdminGridData = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);

    const [Search, setSearch] = useState("");
    const [RowData, setRowData] = useState([]);
    const [syncTime, setSyncTime] = useState("Empty");
    const menuRight = useRef(null);
    const [menuItems, setMenuItems] = useState([
        {
            idName: 'Base price',
            label: 'Base Price',
            icon: '',
            style: { fontSize: '14px' },
            command: () => downloadCSVFromBase64('BP')
        },
    ]);
    const [menuLoading, setMenuLoading] = useState(false);

    const getFullDateTime = () => {
        // Generating current date and time
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        // const seconds = currentDate.getSeconds();
        const fullDateTime = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}_${hours < 10 ? '0' + hours : hours}H${minutes < 10 ? '0' + minutes : minutes}M`;
        return fullDateTime;
    }

    // Dummy base64 encoded CSV data

    const downloadCSVFromBase64 = async (pricingOption) => {
        setMenuLoading(true);

        // console.log("pricingOption", pricingOption);
        let base64String;

        try {
            if (pricingOption === 'CSP') {
                const response = await API.graphql({
                    query: GetByPriceCSVDownload
                });
                base64String = response.data.GetByPriceCSVDownload.base64;
            }
            else if (pricingOption === 'CSD') {
                const response = await API.graphql({
                    query: GetByDiscountCSVDownload
                });
                base64String = response.data.GetByDiscountCSVDownload.base64;
            }
            else if (pricingOption === 'BP') {
                const response = await API.graphql({
                    query: GetByBasePriceCSVDownload
                });
                base64String = response.data.GetByBasePriceCSVDownload.base64;
            }

            const csv = atob(base64String);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);

            const fullDateTime = getFullDateTime();

            link.setAttribute("download", `${pricingOption === 'CSD' ? 'Customer_Specific_Discount' : pricingOption === 'CSP' ? 'Customer_Specific_Price' : 'Base_Price'}_${fullDateTime}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.error("Failed to decode base64 string:", e);
        }
        finally {
            setMenuLoading(false);
        }
    }

    // const handleMenuItemClick = (menuName) => {
    //     if (menuName === 'BP') {
    //         const columnDefs = gridRef.current.api.getColumnDefs();
    //         // console.log("columnDefs", columnDefs)

    //         const columnNames = columnDefs
    //             .filter(column => column.field == "ProductPriceCurrencyCodeText" ||
    //                 column.field == "ProductPriceCurrencyCode" ||
    //                 column.field == "ProductId" ||
    //                 column.field == "ProductPriceCSV")
    //             .map(column => column.field);

    //         const selectedYear = getFullDateTime();

    //         const title = sessionStorage.getItem("title");

    //         const csvParams = {
    //             skipHeader: false,
    //             skipFooters: true,
    //             skipGroups: true,
    //             fileName: `Base_Price_${selectedYear}.csv`,
    //             columnKeys: columnNames
    //         };
    //         gridRef.current.api.exportDataAsCsv(csvParams);
    //     }
    //     else {
    //         downloadCSVFromBase64(menuName)
    //     }
    // }

    useEffect(() => {
        let updatedMenuItems = [...menuItems];
        if (contextVariable.OrganizationDetails.AdditionalPricingOptions === 'CSD') {
            updatedMenuItems.push({
                idName: 'CSD',
                label: 'Customer Specific Discount',
                icon: '',
                style: { fontSize: '14px', whiteSpace: "nowrap" },
                command: () => downloadCSVFromBase64('CSD')
            });
        }
        else if (contextVariable.OrganizationDetails.AdditionalPricingOptions === 'CSP') {
            updatedMenuItems.push({
                idName: 'CSP',
                label: 'Customer Specific Price',
                icon: '',
                style: { fontSize: '14px', whiteSpace: "nowrap" },
                command: () => downloadCSVFromBase64('CSP')
            });
        }
        setMenuItems(updatedMenuItems);
        // eslint-disable-next-line  
    }, [contextVariable.OrganizationDetails.AdditionalPricingOptions])

    useEffect(() => {
        if (props.rowData === 1) {
            setRowData(false)
        }
        else if (props.rowData === 0) {
            setRowData("NoData")
        }
        else
            if (props.rowData.length > 0) {
                if (contextVariable.isFiltered === true && props.needFilters === true && contextVariable.globalSearch !== '') {
                    setRowData(contextVariable.filteredData);

                }
                else {
                    setRowData(props.rowData);
                    if (contextVariable.isFiltered === false && contextVariable.globalSearch !== '' && props.needFilters === true) {
                        gridApi.setQuickFilter(contextVariable.globalSearch);
                        const displayedRowData = gridApi.getModel().rowsToDisplay.map((rowNode) => rowNode.data);
                        if (displayedRowData.length > 0) {
                            contextVariable.setFilteredData(displayedRowData);
                            contextVariable.setIsFiltered(true);
                        }

                    }
                    else if (contextVariable.globalSearch === '' && props.needFilters === true) {
                        contextVariable.setFilteredData(props.rowData)
                    }
                }
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rowData, RowData])

    const getsyncTime = async () => {
        try {
            const LastSyncDetailsProducts = await API.graphql({
                query: listLambdaLastRunTimes
            })
            // console.log("LastSyncDetailsProducts", LastSyncDetailsProducts)
            setSyncTime(LastSyncDetailsProducts.data.listLambdaLastRunTimes.items[0])
        }
        catch (error) {
            console.error("Lambda sync get error", error);
            setSyncTime("Empty")
        }
    }

    useEffect(() => {
        if (props.component === "ProductsView" || props.component === "contactDetails") {
            getsyncTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const gridReadyAPI = (e) => {
        setGridApi(e.api);
        // setCurrentPage(e.api.paginationGetCurrentPage());
    }

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        // floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 100,
        sortingOrder: ['desc', 'asc'],
        filterParams: {
            maxNumConditions: 1,
        },
    }), []);

    // const cellClickedListener = useCallback(event => {
    //     // copyToClipboard(event.value);
    // }, []);

    const rowClickListener = (event) => {
        if (props.component === 'productImageUpload') {
            // console.log("EVENT", event.data);
            contextVariable.setRootObject(event.data);
            if (event.data.FailedImages.items.length !== 0) {
                props.descriptionView();
            }
            else {
                showToast(t('No item level data(s) to list.'), "info", 3000);
            }
        }
        if (props.component === "ProductsView") {
            // contextVariable.setRootObject(event.data);
            // props.ShowDiscountCustomerPage();
        }
    }

    let notificationIdArray = [], MandatoryCount = 0, notMandatoryCount = 0;

    if (contextVariable.OrganizationDetails &&
        contextVariable.OrganizationDetails.NotificationList &&
        contextVariable.OrganizationDetails.NotificationList !== '[]') {
        notificationIdArray = JSON.parse(contextVariable.OrganizationDetails.NotificationList);

        if (RowData !== "NoData" && RowData.length > 0) {
            MandatoryCount = RowData.filter(item => item.NotificationMandatoryStatus === "Mandatory").length;
            notMandatoryCount = RowData.filter(item => item.NotificationMandatoryStatus === "NotMandatory").length;

        }
        // console.log(MandatoryCount,notMandatoryCount)
        // console.log("notificationIdArray", notificationIdArray)
    }
    else {
        notificationIdArray = [];
        MandatoryCount = 0;
        notMandatoryCount = 0;
    }

    // const [enableClicked, setEnableClicked] = useState(false);

    const handleEnableAll = async () => {
        let notificationIDs = RowData.reduce((acc, curr) => {

            acc[curr.NotificationID] = "true";

            return acc;
        }, {});
        // console.log("first", notificationIDs);

        try {
            const response = await updateNotificationList(JSON.stringify([notificationIDs]), contextVariable);

            if (response === true) {
                // setEnableClicked(true);
            }
        } catch (error) {
            console.error("Error updating notification list:", error);
        }

    }

    const handleDisableAll = async () => {
        let notificationIDs = RowData.reduce((acc, curr) => {
            if (curr.NotificationMandatoryStatus === "Mandatory") {
                acc[curr.NotificationID] = "true";
            }
            return acc;
        }, {});

        try {
            const response = await updateNotificationList(JSON.stringify([notificationIDs]), contextVariable);
            if (response === true) {
                // setEnableClicked(false);
            }
        }
        catch (error) {
            console.error("Error updating notification list:", error);
        }
    }


    const exportData = useCallback(() => {
        const columnDefs = gridRef.current.api.getColumnDefs();
        let columnNames = [];

        if (props.component === 'ProductsView') {
            columnNames = columnDefs
                .filter(column => !column.hide && column.field !== 'ProductImage')
                .map(column => column.field);
        }
        else {
            columnNames = columnDefs
                .filter(column => !column.hide)
                .map(column => column.field);
        }

        const selectedYear = getFullDateTime();

        const title = sessionStorage.getItem("title");

        const csvParams = {
            skipHeader: false,
            skipFooters: true,
            skipGroups: true,
            fileName: `${title}_${selectedYear}.csv`,
            columnKeys: columnNames.filter(key => key !== 'preview'),
        };
        gridRef.current.api.exportDataAsCsv(csvParams);
        // gridRef.current.api.exportDataAsCsv();
        // eslint-disable-next-line  
    }, []);

    // if (props.orderInput !== '') {
    //     gridApi.setQuickFilter(props.orderInput);
    // }
    // useEffect(() => {
    //     const defaultValue = contextVariable.globalSearch ? contextVariable.globalSearch : "";

    //     if (RowData.length> 0) {

    //         setRowData()
    //     }
    // }, [RowData]);

    const handleSearch = (event) => {
        setSearch(event)
        gridApi.setQuickFilter(event);
        // contextVariable.setGlobalSearch(event);
        // const displayedRowData = gridApi.getModel().rowsToDisplay.map((rowNode) => rowNode.data);
        gridApi.getModel().rowsToDisplay.map((rowNode) => rowNode.data);
        // contextVariable.setFilteredData(displayedRowData);
        // contextVariable.setIsFiltered(true);
    }

    const RollBackData = () => {
        if (props.rowData) {
            setRowData(props.rowData)
        }
    }

    const handleSelectionChanged = (event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map(
            (node) => node.data);
        // setSelectedRows(selectedData);
        if (selectedData.length !== 0) {
            props.selectedRows(selectedData);
        }
        if (selectedData.length === 0) {

        }
        // else {
        //     setShowPayButton(false);
        // }
    };

    const gridOptions = {
        enableCellTextSelection: true,
        ensureDomOrder: true,
        getRowClass: (params) => {
            // Apply the "rounded-row" class to each row
            return 'rounded-row';
        },
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [LoadingButton, setLoadingButton] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    const handleUpload = async () => {

        if ((selectedFile.type === "application/x-zip-compressed") || (selectedFile.type === "application/zip")) {
            setLoadingButton(true)
            const stringWithoutSpaces = selectedFile.name.replace(/\s/g, '');
            const TenetId = contextVariable.userDetails["custom:tenant"];
            try {
                const key = `${TenetId}/TobeProcessed/${stringWithoutSpaces}`;

                await Storage.put(key, selectedFile, {
                    level: 'public', // Change the access level as needed
                });
                setLoadingButton(false)
                showToast(t('File uploaded successfully. Please refresh the page after some time to view the new data.'), "success");
                setSelectedFile(null)
            } catch (error) {
                console.error('Error uploading image:', error);
                showToast(t('Something went wrong. Please contact the admin.'), "error", 4000);
            }

            // setLoadingButton(false)
        }
        else {
            showToast(t('Please upload ZIP file.'), "error")
        }

    }

    // UseEffect for Rerender the row data when Image change 
    useEffect(() => {
        if (props.filter === 'ProductsView' && contextVariable.EditImageData) {
            const index = contextVariable.EditImageData ? contextVariable.EditImageData.rowIndex : 0;
            setTimeout(() => {
                redrawTopRows(index)
            }, 3000);
        }
        //eslint-disable-next-line
    }, [props.refreshTrigger])

    useEffect(() => {
        if (props.component === 'Notifications' && contextVariable.notificationData) {
            const index = contextVariable.notificationData ? contextVariable.notificationData.rowIndex : 0;
            // console.log("index", index);
            redrawNotificationsRows(index);
        }
        // eslint-disable-next-line 
    }, [props.refreshNotifications])

    const redrawNotificationsRows = (Index) => {
        var rows = [];
        try {
            var row = gridRef.current.api.getDisplayedRowAtIndex(Index);
            rows.push(row);
            // console.log("row", rows)

            gridRef.current.api.redrawRows({ rowNodes: rows });
            contextVariable.setNotificationData("");
        }
        catch (error) { console.error("redraw rows fetching error", error) }

    }

    const redrawTopRows = (Index) => {
        var rows = [];
        try {
            var row = gridRef.current.api.getDisplayedRowAtIndex(Index);
            rows.push(row);
            //   console.log("row",row)

            gridRef.current.api.redrawRows({ rowNodes: rows });
            // contextVariable.setEditImageData("")
        }
        catch (error) { console.error("redraw rows fetching error", error) }

    }

    const PrimaryButton = "primary-button p-2 pr-4 pl-4";
    // const footer = <div className='flex justify-content-end mr-3 mt-2 -mb-2 align-items-center '>
    //     <Button type="text" label="Next" onClick={() => contextVariable.FetchProductDetailsLoadMoreUse()}>
    //         <i className="pi pi-angle-double-right text-xs" style={{ marginLeft: '5px' }}></i>
    //     </Button>
    // </div>;
    const DataLoader = RowData === "NoData" ? NoRowsToDisplay : Loader
    return (
        <>
            {contextVariable.pageInfo === "ProductsView" && <InfoBar header="Products View"></InfoBar>}
            {contextVariable.pageInfo === "contactDetails" && <InfoBar header="Contact Details"></InfoBar>}
            {contextVariable.pageInfo === "ProductsImageUpload" && <InfoBar header="Products Image Upload"></InfoBar>}
            <Box
                sx={{
                    "& .ag-theme-alpine .ag-root-wrapper, .ag-theme-alpine": {
                        border: 'none'
                    },
                    "& .ag-theme-alpine .ag-header-cell, .ag-header": {
                        backgroundColor: 'white',
                        color: 'black',
                        fontWeight: 'none'
                    },
                    "& .ag-theme-alpine .ag-root-wrapper": {
                        background: colors.primary[400]
                    },
                    "& .ag-theme-alpine .ag-row": {
                        color: colors.grey[100],
                        background: 'white',
                        borderBottomColor: colors.grey[100]
                    },
                    "& .ag-theme-alpine .my-cell-class": {
                        border: '1px solid black'
                    },
                }}
            >

                {/* Product Images upload  */}
                {
                    props.component === "productImageUpload" &&
                    <div className='flex justify-content-between gap-2 align-items-center'>
                        <div>
                            <div className='flex gap-2'>
                                {selectedFile &&
                                    <Button
                                        onClick={handleUpload}
                                        loading={LoadingButton}
                                        className={`${PrimaryButton}`}
                                        // icon={<DriveFolderUploadOutlined className='pr-1' />}
                                        icon="pi pi-upload pl-1"
                                        label=' Upload File '>
                                    </Button>
                                }

                                {!selectedFile && <FormControl style={{ border: "2px solid var(--primary-color)", borderRadius: "0.375rem" }}>
                                    <div className='text-primary'>
                                        <label htmlFor="file-upload" className='flex justify-content-center align-items-center cursor-pointer  p-1'>
                                            <Add className='text-2xl'></Add>  <span className='font-semibold p-1'>{'Choose File'}</span>
                                            <input type="file" id="file-upload" className="text-base" style={{ display: 'none' }} accept=".zip" onChange={handleFileChange} />
                                        </label>
                                        {/* <DriveFolderUploadOutlined /> */}
                                    </div>
                                </FormControl>}

                                {selectedFile && <div className='font-semibold  text-blue-600 '>
                                    {selectedFile.name.length > 30
                                        ? selectedFile.name.substring(0, 25) + '...'
                                        : selectedFile.name}

                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title="Remove"
                                        placement="auto"
                                        TransitionComponent={Zoom}
                                        enterDelay={300}
                                    >   <IconButton onClick={() => setSelectedFile(null)}><HighlightOffRounded></HighlightOffRounded></IconButton>
                                    </Tooltip>
                                </div>}

                                {!selectedFile && <div className='mt-2 flex gap-1'>
                                    <p className='text-gray-600 text-sm font-semibold'>Only zip files are allowed</p>
                                    <p className='text-gray-600 cursor-pointer' onClick={(e) => (contextVariable.setInstructionsDialog("MoreInstractions"))}>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'var(--sidebar-color)',
                                                        fontSize: '12px'
                                                    },
                                                },
                                            }}
                                            title="More info"
                                            placement="bottom"
                                            TransitionComponent={Zoom}
                                            enterDelay={300}
                                        >
                                            <InfoRounded className='text-sm mb-1'></InfoRounded>
                                        </Tooltip>
                                    </p>
                                </div>}

                                {contextVariable.InstructionsDialog === "MoreInstractions" && <><AdminDialogInstructions header={t(`Instructions for Uploading Files`)}></AdminDialogInstructions></>}
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            <div>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Refresh the table"
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <span>
                                        <Button
                                            disabled={RowData.length > 0 ? false : true}
                                            className={`${PrimaryButton}`}
                                            onClick={props.Refresh}
                                            label='Refresh'
                                            icon="pi pi-refresh pl-1"
                                            loading={RowData.length > 0 ? false : true}
                                        >
                                            <span className='hide-in-mobileview font-semibold'></span>
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>

                            <div>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Download as CSV"
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <span>
                                        <Button
                                            disabled={RowData.length > 0 ? false : true}
                                            className={`${PrimaryButton}`}
                                            icon="pi pi-download pl-1"
                                            label='CSV'
                                            onClick={exportData}
                                        >
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>

                            <div className='mt-1'>
                                {/* <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    
                                    <div
                                        onClick={() => contextVariable.setPageInfo("ProductsImageUpload")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg py-1 px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='text-base text-gray-400' />
                                        <span>Info</span>
                                    </div>
                                </Tooltip> */}
                            </div>
                        </div>
                    </div>
                }

                {/* productimageView */}
                {
                    props.filter === 'ProductsView' ? (
                        <div className="flex gap-2 align-items-center mt-1">
                            <div style={{ marginTop: '-0.1rem' }}>    </div>
                            <span className="flex-1 p-input-icon-left order-searchbar">
                                <i className="pi pi-search" />
                                <InputText
                                    autoFocus
                                    type='search'
                                    disabled={RowData.length > 0 ? false : true}
                                    value={Search}
                                    className="p-inputtext-sm block primary-input-text"
                                    // placeholder="Product  Id, Description "
                                    placeholder="Search"
                                    style={{ width: '100%', height: "35px", textAlign: "center", borderRadius: "5px" }}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            RollBackData();
                                        }
                                    }}
                                />
                            </span>

                            <div className='flex gap-2'>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Refresh the table"
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <span>
                                        <Button
                                            disabled={RowData.length > 0 ? false : true}
                                            className={`${PrimaryButton}`}
                                            icon="pi pi-refresh pl-1"
                                            loading={RowData.length > 0 ? false : true}
                                            onClick={props.Refresh}
                                            label="Refresh"
                                        />
                                    </span>
                                </Tooltip>

                                <SyncButton RowDatalength={RowData.length} header="Info" component={props.component} syncTime={syncTime}></SyncButton>

                                <UploadExcel contextVariable={contextVariable} RowDatalength={RowData.length} />

                                <div>
                                    <Menu model={menuItems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                                    <Button
                                        disabled={RowData.length > 0 ? false : true}
                                        className='primary-button p-2 pr-4 pl-4'
                                        icon="pi pi-download pl-1"
                                        label='Price List'
                                        loading={menuLoading}
                                        onClick={(event) => menuRight.current.toggle(event)}
                                    >
                                    </Button>
                                </div>

                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Download as CSV"
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <span>
                                        <Button
                                            disabled={RowData.length > 0 ? false : true}
                                            className={`${PrimaryButton}`}
                                            icon="pi pi-download pl-1"
                                            label='CSV'
                                            onClick={exportData}
                                        >
                                        </Button>
                                    </span>
                                </Tooltip>

                            </div>

                            <div className='mt-1'>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("ProductsView")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg py-1 px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='text-base text-gray-400' />
                                        <span>Info</span>
                                    </div>
                                    {/* <span>
                                    <IconButton size="small" className="-mt-1" onClick={() => contextVariable.setPageInfo("ProductsView")} >
                                        <InfoRounded className='text-base text-gray-400' />
                                    </IconButton> Info
                                </span> */}
                                </Tooltip>
                            </div>
                        </div>) : ''
                }

                {/* contactDetails */}
                {
                    props.filter === 'contactDetails' ? (<div className="flex gap-2 justify-content-between align-items-center">
                        {/* <div style={{ marginTop: '-0.1rem' }}>    </div> */}

                        <span className="flex-1 p-input-icon-left order-searchbar">
                            <i className="pi pi-search" />
                            <InputText
                                autoFocus
                                type='search'
                                disabled={RowData.length > 0 ? false : true}
                                value={Search}
                                className="p-inputtext-sm block primary-input-text"
                                // placeholder="Customer Id, Contact Id, Status, Customer Name, Contact Name"
                                placeholder="Search"
                                style={{ width: '100%', height: "35px", textAlign: "center", borderRadius: "5px" }}
                                onChange={(e) => handleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        RollBackData();
                                    }
                                }}
                            />

                        </span>

                        <div className='flex column gap-2'>
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'var(--sidebar-color)',
                                            fontSize: '12px'
                                        },
                                    },
                                }}
                                title="Refresh the table"
                                placement="bottom"
                                TransitionComponent={Zoom}
                                enterDelay={300}
                            >
                                <span>
                                    <Button
                                        disabled={RowData.length > 0 ? false : true}
                                        className={`${PrimaryButton}`}
                                        icon="pi pi-refresh pl-1"
                                        loading={RowData.length > 0 ? false : true}
                                        onClick={props.Refresh}
                                        label="Refresh"
                                    />

                                </span>
                            </Tooltip>
                            <SyncButton RowDatalength={RowData.length} component={props.component} syncTime={syncTime}></SyncButton>
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'var(--sidebar-color)',
                                            fontSize: '12px'
                                        },
                                    },
                                }}
                                title="Download as CSV"
                                placement="bottom"
                                TransitionComponent={Zoom}
                                enterDelay={300}
                            >
                                <span>
                                    <Button
                                        disabled={RowData.length > 0 ? false : true}
                                        className={`${PrimaryButton}`}
                                        icon="pi pi-download pl-1"
                                        label='CSV'
                                        onClick={exportData}
                                    >
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className='mt-1'>
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: 'var(--sidebar-color)',
                                            fontSize: '12px'
                                        },
                                    },
                                }}
                                title="Page Info"
                                placement="left"
                                TransitionComponent={Zoom}
                                enterDelay={300}
                            >

                                <div
                                    onClick={() => contextVariable.setPageInfo("contactDetails")}
                                    className='cursor-pointer border-1 border-secondary-800 border-round-lg py-1 px-2 flex justify-content-center gap-1 align-items-center'>
                                    <InfoRounded className='text-base text-gray-400' />
                                    <span>Info</span>
                                </div>

                                {/* <span>
                                    <IconButton size="small" className="-mt-1" onClick={() => contextVariable.setPageInfo("contactDetails")} >
                                        <InfoRounded className='text-base text-gray-400' />
                                    </IconButton> Info
                                </span> */}
                            </Tooltip>

                        </div>
                    </div>) : ''
                }

                {/* Notifications */}
                {
                    props.filter === 'Notifications' ? (
                        <div className="d-flex justify-content-center align-items-center gap-5 mt-1">
                            <div style={{ marginTop: '-0.1rem' }}></div>

                            <span className="w-50 p-input-icon-left order-searchbar">
                                <i className="pi pi-search" />
                                <InputText
                                    autoFocus
                                    type='search'
                                    disabled={RowData.length > 0 ? false : true}
                                    value={Search}
                                    className="p-inputtext-sm block primary-input-text"
                                    // placeholder="Customer Id, Contact Id, Status, Customer Name, Contact Name"
                                    placeholder="Search"
                                    style={{ width: '100%', height: "35px", textAlign: "center", borderRadius: "5px" }}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            RollBackData();
                                        }
                                    }}
                                />

                            </span>
                            <div>
                                {
                                    ((notificationIdArray && notificationIdArray[0] &&
                                        Object.keys(notificationIdArray[0]).length) === RowData.length) &&
                                    <>
                                        <Button
                                            className='secondary-button w-6rem h-2rem'
                                            label='Disable all'
                                            onClick={handleDisableAll}
                                        />
                                    </>
                                }
                                {
                                    ((notificationIdArray && notificationIdArray[0] &&
                                        Object.keys(notificationIdArray[0]).length) < RowData.length) &&
                                    <>
                                        <Button
                                            className='w-6rem h-2rem'
                                            label='Enable all'
                                            onClick={handleEnableAll}
                                        />
                                    </>
                                }
                            </div>
                        </div>) : ''
                }

                {/* AGGrid Section */}
                <div className='ag-theme-alpine mt-2' style={{ width: '100%', height: props.tableHeight }}>
                    <div style={{ width: '100%', height: '100%' }}>

                        <AgGridReact
                            enableRtl={contextVariable.rtl ? true : false}
                            rowHeight={50}
                            onGridReady={gridReadyAPI}
                            ref={gridRef}
                            rowData={RowData}
                            columnDefs={props.columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            rowSelection='multiple'
                            // onCellClicked={cellClickedListener}
                            onRowDoubleClicked={rowClickListener}
                            suppressCellSelection={false}
                            // suppressPaginationPanel={props.filter === 'ProductsView' ? true : false}
                            suppressScrollOnNewData={true}
                            pagination={props.showPagination}
                            // paginationPageSize={props.filter === 'ProductsView' ? 50000 : 20}
                            paginationPageSize={20}
                            onSelectionChanged={handleSelectionChanged}
                            gridOptions={gridOptions}
                            noRowsOverlayComponent={DataLoader}
                            includeHiddenColumnsInQuickFilter={true}
                            tooltipShowDelay={1000}
                            tooltipHideDelay={9000}
                        />


                    </div>

                </div>
                {/* {props.filter === 'ProductsView' && footer}  */}
            </Box>
            {/* {
                !props.component && <div className='d-flex justify-content-between gap-1 mt-3 pt-2'>
                    <button className='btn btn-sm' style={{ background: 'lightgray' }} onClick={goToPreviousPage}><NavigateBeforeOutlined /> Previous</button>
                    <button className='btn btn-sm' style={{ background: 'lightgray' }} onClick={goToNextPage}>Next <NavigateNextOutlined /></button>
                </div>
            } */}
        </>
    )
}

export default AdminGridData;