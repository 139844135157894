import { API } from "aws-amplify"
import {
    CreateandUpdateContacts, CreateandUpdateCustomerSecrets,
    createOrganizationDetails, updateOrganizationDetails,
    updateProductsList
} from "../../graphql/mutations";
import { showToast } from "../ToastUtils";
import i18n from '../../i18/i18nConfig';

// Update the Trending products (Trending Active or inActive)
export const UpdateTrending = async (ParamsData, contextVariable, UpdateAndSetAllProducts, setTrendingLoading) => {
    setTrendingLoading(true)
    const TrendingSelection = ParamsData.ProductStatus === "Trending" ? " " : "Trending";
    try {
        const DataOfUpdate = {
            id: ParamsData.id,
            ProductStatus: TrendingSelection
        }

        const UpdatedTrendingResponse = await API.graphql(
            {
                query: updateProductsList,
                variables: {
                    input: DataOfUpdate
                }
            }

        )
        if (UpdatedTrendingResponse) {
            const UpdateProductTrending = contextVariable.ProductListDetails.map((p) => {
                if (p.id === UpdatedTrendingResponse.data.updateProductsList.id) {
                    return { ...p, ProductStatus: TrendingSelection }
                }
                else {
                    return p;
                }
            });
            UpdateAndSetAllProducts(UpdateProductTrending);
            const ProductName = UpdatedTrendingResponse.data.updateProductsList.ProductName;

            setTimeout(() => {
                if (UpdatedTrendingResponse.data.updateProductsList.ProductStatus === "Trending") {
                    showToast(`${ProductName} ${i18n.t('has been added to the trending products.')}`, "success");
                }
                else {
                    showToast(`${ProductName} ${i18n.t('has been removed from the trending products.')}`, "success");
                }
                setTrendingLoading(false);
            }, 2000);
        }

    }
    catch (error) {
        console.error("Update Trending Error", error);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Update Trending Error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }


    }
    finally {

    }
}

export const createOrganizationDetailsAPI = async (OrganizationFormDetails, contextVariable, setDisableUse) => {

    try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const response = await API.graphql({
            query: createOrganizationDetails,
            variables:
            {
                input: OrganizationFormDetails
            }
        })

        contextVariable.setOrganizationDetails(response.data.createOrganizationDetails);
        contextVariable.setShow("Hide");
        localStorage.removeItem("Logouploaded");
        localStorage.removeItem("previewImage");
        setDisableUse(false);
        showToast(`${i18n.t('Details have been saved successfully.')}`, 'success');
        return true;
    }
    catch (error) {
        contextVariable.setShow("Hide");
        setDisableUse(false);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Organizations Create error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }

        console.error("Organizations Create error", error);
        return false;
    }
}

export const CreateSAPandStripeDetailsFn = async (SAPandStripeDetails, contextVariable, setDisableUse) => {
    const StripeDefaultIndicator = (SAPandStripeDetails.PublishableKey && SAPandStripeDetails.SecretKey && SAPandStripeDetails.PublishableKey !== "" && SAPandStripeDetails.SecretKey !== "") ? true : false;
    const UpdateOrganizationFormData = {
        id: contextVariable.OrganizationDetails.id,
        StripeDefaultIndicator: StripeDefaultIndicator,
        SAPCredentialIndicator: "true"

    }
    try {
        const CreateandUpdateCustomerSecretsresponse = await API.graphql({
            query: CreateandUpdateCustomerSecrets,
            variables:
            {
                input: SAPandStripeDetails
            }
        })
        // if (CreateandUpdateCustomerSecretsresponse.data.CreateandUpdateCustomerSecrets.Status === "Success")
        if (CreateandUpdateCustomerSecretsresponse) {
            try {

                const updateOrganizationDetailsresponse = await API.graphql(
                    {
                        query: updateOrganizationDetails,
                        variables:
                        {
                            input: UpdateOrganizationFormData
                        }
                    }
                )
                contextVariable.setOrganizationDetails(updateOrganizationDetailsresponse.data.updateOrganizationDetails);
                contextVariable.setShow("Hide");
                setDisableUse(false);
                if (UpdateOrganizationFormData.StripeDefaultIndicator) {
                    showToast(`${i18n.t('SAP and Stripe details saved successfully.')}`, "success");
                }
                else {
                    showToast(`${i18n.t('SAP details saved successfully.')}`, "success");
                }
            }
            catch (error) {
                contextVariable.setShow("Hide");
                setDisableUse(false);
                showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, 'error', 3000);
                console.error("Organization form Indicator update error", error)
            }
        }
        else {
            contextVariable.setShow("Hide");
            setDisableUse(false);
            if (UpdateOrganizationFormData.StripeDefaultIndicator) {
                showToast(`${i18n.t('SAP and Stripe details saved successfully.')}`, "success")
            }
            else {
                showToast(`${i18n.t('SAP details saved successfully.')}`, "success")
            }
        }
        return true;
    }
    catch (error) {
        contextVariable.setShow("Hide");
        setDisableUse(false);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Sap details create error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }

        console.error('Sap details create error', error);
        return false;
    }
}
export const updateContactDetails = async (contextVariable, params, TotalContactDetails, setTotalConteacDetails, setContactLoading) => {
    setContactLoading(true);
    const status = params.PortalStatus === "Enabled" ? "Disable" : "Enable";
    const cleanedPhoneNumber = params.PhoneNumber.replace(/[()\s-]/g, '');
    const data = {
        CognitoUserId: params.PortalStatus === "Enabled" ? params.CognitoUserId : "",
        ContactId: params.ContactId,
        ContactUUID: params.ContactUUID,
        ContactName: params.ContactName,
        CustomerId: params.CustomerId,
        CustomerName: params.CustomerName,
        CustomerUUID: params.CustomerUUID,
        EmailId: params.EmailId,
        PhoneNumber: cleanedPhoneNumber,
        PortalStatus: status,
        id: params.id,
    }

    try {
        const updateContactDetailsResponse = await API.graphql({
            query: CreateandUpdateContacts,
            variables: {
                input: data
            }
        })
        // console.log("updateContactDetailsResponse", updateContactDetailsResponse)
        const successResponse = updateContactDetailsResponse.data.CreateandUpdateContacts;
        // console.log("TotalContactDetails", TotalContactDetails)
        if (successResponse) {
            const updatedTotalContractDetails = TotalContactDetails.map((element) => {
                if (element.id === successResponse.id) {
                    return {
                        ...element,
                        PortalStatus: successResponse.Status,
                        CognitoUserId: successResponse.CognitoUserId
                    };
                }
                // Add a default return for elements that don't match the condition
                return element;
            });
            showToast(`${params.CustomerName} ${i18n.t('customer')} ${successResponse.Status} ${i18n.t('Successfully')}`, 'success', 3000);
            setTotalConteacDetails(updatedTotalContractDetails);
            setContactLoading(false);
        }

    }
    catch (error) {

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Error createandUpdateContacts", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }

        console.error("Error createandUpdateContacts", error);
        setContactLoading(false);
    }
}

export const updateSAPCredentialsFn = async (SAPCredentials, contextVariable, setButtonLoader) => {

    setButtonLoader(true)

    const trimmedObject = {};
    for (const key in SAPCredentials) {
        if (SAPCredentials.hasOwnProperty(key) && typeof SAPCredentials[key] === 'string') {
            trimmedObject[key] = SAPCredentials[key].trim();
        } else {
            trimmedObject[key] = SAPCredentials[key];
        }
    }
    const updateSapDetails = {
        OdataUsername: trimmedObject.SAPODataUserName,
        OdataPassword: trimmedObject.SAPODataPassword,
        SoapUsername: trimmedObject.SOAPUserName,
        SoapPassword: trimmedObject.SOAPPassword
    }
    try {
        await API.graphql(
            {
                query: CreateandUpdateCustomerSecrets,
                variables:
                {
                    input: updateSapDetails
                }
            }
        )
        showToast(`${i18n.t('SAP details updated successfully')}`, "success")
        contextVariable.setShow(false);
        setButtonLoader(false);
    }
    catch (error) {
        console.error("SAP details Update Error", error)

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("SAP details Update Error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }

        setButtonLoader(false);
    }


}
export const updateOrganizationsDetailsFn = async (updateDetails, contextVariable, setButtonLoader) => {
    setButtonLoader(true)
    const trimmedObject = {};
    for (const key in updateDetails) {
        if (updateDetails.hasOwnProperty(key) && typeof updateDetails[key] === 'string') {
            trimmedObject[key] = updateDetails[key].trim();
        } else {
            trimmedObject[key] = updateDetails[key];
        }
    }
    // console.log("trimmedObject", trimmedObject)
    try {
        const response = await API.graphql({
            query: updateOrganizationDetails,
            variables:
            {
                input: trimmedObject
            }
        })
        console.log("response", response);
        contextVariable.setOrganizationDetails(response.data.updateOrganizationDetails)
        setButtonLoader(false);
        contextVariable.setShow(false);
        if (updateDetails.PriceListName) {
            showToast(`${i18n.t('Price details updated successfully. Please synchronize the price details in the products view tab.')}`, "success", 4000);
        }
    }
    catch (error) {
        console.error("Price Details Update Error", error);
        setButtonLoader(false);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Price Details Update Error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }
    }
}
export const updateBankDetailsFn = async (BankDetails, contextVariable, setButtonLoader) => {
    setButtonLoader(true)
    const trimmedObject = {};

    for (const key in BankDetails) {
        if (BankDetails.hasOwnProperty(key) && typeof BankDetails[key] === 'string') {
            trimmedObject[key] = BankDetails[key].trim();
        } else {
            trimmedObject[key] = BankDetails[key];
        }
    }

    try {
        const response = await API.graphql({
            query: updateOrganizationDetails,
            variables:
            {
                input: trimmedObject
            }
        })
        contextVariable.setOrganizationDetails(response.data.updateOrganizationDetails)
        setButtonLoader(false);
        contextVariable.setShow(false);
        showToast(`${i18n.t('Updated bank details successfully.')}`, "success", 2000)
    }
    catch (error) {
        console.error("Price Details Update Error", error)
        setButtonLoader(false);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Price Details Update Error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }

    }
}
export const updateStripeDetailsFn = async (StripeDetails, contextVariable, setButtonLoader) => {
    setButtonLoader(true)
    const trimmedObject = {};

    for (const key in StripeDetails) {
        if (StripeDetails.hasOwnProperty(key) && typeof StripeDetails[key] === 'string') {
            trimmedObject[key] = StripeDetails[key].trim();
        } else {
            trimmedObject[key] = StripeDetails[key];
        }
    }

    try {

        const response = await API.graphql({
            query: CreateandUpdateCustomerSecrets,
            variables:
            {
                input: {
                    EndpointSecret: trimmedObject.EndpointSecret,
                    PublishableKey: trimmedObject.PublishableKey,
                    SecretKey: trimmedObject.SecretKey
                }
            }
        })
        if (response.data.CreateandUpdateCustomerSecrets.Status === "Success" && contextVariable.OrganizationDetails.StripeDefaultIndicator === "false") {

            try {

                const updateOrganizationDetailsresponse = await API.graphql(
                    {
                        query: updateOrganizationDetails,
                        variables:
                        {
                            input: {
                                id: trimmedObject.id,
                                StripeDefaultIndicator: trimmedObject.StripeDefaultIndicator
                            }
                        }
                    }
                )
                contextVariable.setOrganizationDetails(updateOrganizationDetailsresponse.data.updateOrganizationDetails);
                contextVariable.setShow("Hide");
                setButtonLoader(false);
                showToast(`${i18n.t('Stripe Details updated successfully')}`, "success")

            }
            catch (error) {
                console.error("Organization form Indicator update error", error)
                setButtonLoader(false);
                showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, 'error', 4000);
            }


        }
        else {
            contextVariable.setShow("Hide");
            setButtonLoader(false);
            showToast(`${i18n.t('Stripe Details updated successfully')}`, "success")
        }


    }
    catch (error) {
        console.error("Stripe Details Update Error", error);
        setButtonLoader(false);

        if (error.message && error.message.includes('No current user')) {
            console.error('Session timeout or unauthenticated state detected:', error);
            contextVariable.setIsSessionExpired(true);
        }
        else {
            console.error("Price Details Update Error", error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
        }
    }
}