import { API, Auth } from "aws-amplify";
import {
  createUserCart,
} from "../graphql/mutations";
import { showToast } from "./ToastUtils";
import { userCartsByCartId, userProductsCartsByCarttableID } from "../graphql/queries";
import { createUserProductsCart, deleteUserProductsCart, updateUserProductsCart } from "../Layout/MutaionsAndQueries";
import i18n from '../i18/i18nConfig';

export const handleAddToCart = async (
  contextVariable,
  ProductListData,
  addDataProps,
) => {
  try {
    const UserDefaultAddress =
      contextVariable.accountDetails.CustomerAddressInformation.find(
        (defaultAddress) => defaultAddress.DefaultAddress !== ""
      );


    const AddUserDataToCart = {
      CartId: contextVariable.userDetails.sub,
      CartStatus: "Open",
      UserAddress: UserDefaultAddress.FormattedAddress,
      CreatedBy: contextVariable.userDetails["custom:contactperson_UUID"]
    };

    if (
      contextVariable.UserCartIdDetails &&
      contextVariable.UserCartIdDetails[0] &&
      contextVariable.UserCartIdDetails[0].CartStatus === "InProgress"
    ) {
      // contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
      try {
        let nextToken = null;
        let UserCartParticulars = [];
        do {
          const UserCartResponse = await API.graphql({
            query: userCartsByCartId,
            variables: {
              CartId: contextVariable.userDetails.sub,
              filter: {
                or: [
                  { id: { eq: contextVariable.UserCartIdDetails[0].id } },
                ],
              },
              nextToken: nextToken,
            },
          });

          nextToken = UserCartResponse.data.userCartsByCartId.nextToken;
          const UserCartDetail = UserCartResponse.data.userCartsByCartId.items;
          UserCartParticulars = UserCartParticulars.concat(UserCartDetail);

        } while (nextToken);
        if (UserCartParticulars.length > 0) {
          if (UserCartParticulars[0].CartStatus === "Open") {
            contextVariable.setCartStatusView("ProductsInTheCart");
            contextVariable.setUserCartIdDetails(UserCartParticulars);
            showToast(`${i18n.t('Ordered request Failed. Contact admin.')}`, "error")
          }
          else if (UserCartParticulars[0].CartStatus === "InProgress") {
            contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
            contextVariable.setCartStatusView("EmptyCart");
            contextVariable.setUserCartIdDetails(UserCartParticulars);
            showToast(`${i18n.t('Your previous order request is currently being processed. Please try again after the order request has been processed.')}`);
          }
          else if (UserCartParticulars[0].CartStatus === "Ordered") {
            showToast(`${i18n.t('Your previous order request submitted  with Order ID:')}` `${ UserCartParticulars[0].SalesOrderId }.`, "success")
            contextVariable.setUserCartIdDetails([]);
            contextVariable.setCartCount(0);
            contextVariable.setCartItemsIds([]);
            contextVariable.setCartStatusView("OrderRequestPlaced");
            contextVariable.setSalesorderIdwithPlaced(UserCartParticulars[0].SalesOrderId)
          }

        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error as needed
      }

    }

    else {
      // Card Creation When User No have Cart that time Create cart and Add data to Cart
      if (contextVariable.UserCartIdDetails.length === 0) {
        try {
          const newUserAddToCartResponse = await API.graphql({
            query: createUserCart,
            variables: { input: AddUserDataToCart },
          });

          const userCartArray = [newUserAddToCartResponse.data.createUserCart];
          // console.log("userCartArray", userCartArray)
          contextVariable.setUserCartIdDetails(userCartArray);
          const Adddata = {
            ...addDataProps,
            carttableID: userCartArray[0].id,
          };

          try {
            const addcartResult = await API.graphql({
              query: createUserProductsCart,
              variables: {
                input: Adddata,
                customerUUID: contextVariable.userDetails["custom:customer_UUID"]
              },
            });


            contextVariable.setCartCount(p => p + 1);
            contextVariable.setCartStatusView("ProductsInTheCart");
            contextVariable.setUserCartItems(p => [
              ...p,
              addcartResult.data.createUserProductsCart,
            ]);

          } catch (error) {
            console.error("Response Error", error);
            contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
          }
        } catch (error) {
          contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
          console.error("An error occurred while adding a user to the cart:", error);
        }
      }
      // This portion Already have Cart that time Add data to that particular cart
      else if (contextVariable.UserCartIdDetails.length > 0) {
        const Adddata = {
          ...addDataProps,
          carttableID: contextVariable.UserCartIdDetails[0].id,
        };
        const filter = {
          and: [
            { userProductsCartProductdetailsId: { eq: ProductListData.id } },
            { ProductPriceUOMCode: { eq: Adddata.ProductPriceUOMCode } }
          ]
        };
        try {
          const CartDetailsResponse = await API.graphql({
            query: userProductsCartsByCarttableID,
            variables: {
              carttableID: contextVariable.UserCartIdDetails[0].id,
              filter,
            },
          });
          // console.log("CartDetailsResponse", CartDetailsResponse)
          const CartDetailsResponseItems = CartDetailsResponse.data.userProductsCartsByCarttableID.items;

          if (CartDetailsResponseItems.length > 0) {
            let upDateQuantity = Adddata.ProductQuantity + CartDetailsResponseItems[0].ProductQuantity;
            let CalculatedUOMQuantity = Adddata.CalculatedUOMQuantity + CartDetailsResponseItems[0].CalculatedUOMQuantity;

            if (!contextVariable.taxDetails.InventoryStatus && upDateQuantity > 9999) {
              showToast(`${ i18n.t('You have reached the maximum quantity limit for this product.') } `, 3000)
            }
            else {
              try {
                const UpdateResponse = await API.graphql({
                  query: updateUserProductsCart,
                  variables:
                  {
                    input: {
                      id: CartDetailsResponseItems[0].id,
                      ProductQuantity: upDateQuantity,
                      CalculatedUOMQuantity: CalculatedUOMQuantity
                    }
                  }
                })
                // console.log("UpdateResponse", UpdateResponse)
                contextVariable.setUserCartItems((prevItems) =>
                  prevItems.map((item) =>
                    item.id === CartDetailsResponseItems[0].id
                      ? {
                        ...item, ProductQuantity: UpdateResponse.data.updateUserProductsCart.ProductQuantity,
                        CalculatedUOMQuantity: UpdateResponse.data.updateUserProductsCart.CalculatedUOMQuantity
                      }
                      : item
                  )
                );
              }
              catch (error) {
                contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
                console.error("Product Quantity Updated error", error)
              }
            }


          }
          else
            if (CartDetailsResponseItems.length === 0 && contextVariable.UserCartItems.length !== 100) {
              // console.log("First Adddata", Adddata)
              try {
                const addcartResult = await API.graphql({
                  query: createUserProductsCart,
                  variables: {
                    input: Adddata,
                    customerUUID: contextVariable.userDetails["custom:customer_UUID"]
                  },
                });
                // console.log("AddCartREsult First", addcartResult)
                contextVariable.setCartCount(p => p + 1);
                contextVariable.setCartStatusView("ProductsInTheCart");
                contextVariable.setUserCartItems(p => [
                  ...p,
                  addcartResult.data.createUserProductsCart,
                ]);

                contextVariable.setCartItemsIds((prevIds) => {
                  const AddId = new Set(prevIds);
                  AddId.add(ProductListData.id);
                  return Array.from(AddId)
                })

              } catch (error) {
                contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
                console.error("Response Error", error);
                if (error && error.errors && error.errors.length > 0) {
                  if (error.errors[0].message === "Variable 'ProductQuantity' has an invalid value.")
                    showToast(`${ i18n.t(`Enter Valid Product Quantity`) } `, "error")
                }
              }
            } else {
              contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
              showToast(`${ i18n.t(`Limit Reached! You can only add 100 products in cart.`) } `);
            }
        } catch (error) {
          console.error("Removing Error", error);
          contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
        }
      }
    }
  } catch (error) {
    // showToast(`Cannot add this product to cart ${ error.message } `, "error");
    contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
    console.error(error);
    if (error.message === "Cannot read properties of undefined (reading 'find')") {
      if (contextVariable.accountDetails === "NoAddress") {
        showToast(`${ i18n.t('Cannot add the product to cart because address details are unavailable. Please contact admin for assistance.') } `, 'error')
      }
      else {
        showToast(`${ i18n.t('Please wait a moment before clicking again. Thank you for your patience!') } `, "info", 2000)
      }
    }
  }
};



export const removeFromCartGlobal = async (
  contextVariable,
  ProductListData,
) => {

  contextVariable.setCartItemsIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.productID))
  // console.log("setAddCartRemoveCartUsingProductAdded", setAddCartRemoveCartUsingProductAdded)
  if (contextVariable.UserCartIdDetails.length > 0) {
    const filter = {
      id: { eq: ProductListData.id },
    };

    try {
      const CartDetailsResponse = await API.graphql({
        query: userProductsCartsByCarttableID,
        variables: {
          carttableID: contextVariable.UserCartIdDetails[0].id,
          filter,
        },
      });
      // console.log("CartDetailsResponse", CartDetailsResponse)
      const CartDetailsResponseItems = CartDetailsResponse.data.userProductsCartsByCarttableID.items;
      if (CartDetailsResponseItems.length > 0) {
        const itemToDeleteId = CartDetailsResponseItems[0].id;
         await API.graphql({
          query: deleteUserProductsCart,
          variables: {
            input: {
              id: itemToDeleteId,
            },
          },
        });
        contextVariable.setUserCartItems(prevItems => prevItems.filter(
          item => item.id !== ProductListData.id
        ));
        contextVariable.setCartCount(p => p - 1);
        if (contextVariable.UserCartItems.length === 1) {
          contextVariable.setCartStatusView("EmptyCart");
        }
        // showToast(`"${ProductListData.ProductName}" removed from your cart`, "success");
      } else {
        showToast(`${i18n.t('Already removed in your cart')}`, "error");
        window.location.reload();
      }
    } catch (error) {
      showToast(`${i18n.t('Cannot remove this product from cart')}` `${ error.errors[0].message } `, "error");
      console.error("Removing Error", error);
      contextVariable.setCartItemsIds((prevIds) => {
        const AddId = new Set(prevIds);
        AddId.add(ProductListData.id);
        return Array.from(AddId)
      })
    }
  } else {
    window.location.reload();
  }
};


