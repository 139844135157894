import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import PayInvoiceList from './PayInvoiceList';
import './Invoice.css';
import { AppContext } from '../../Layout/Layout';
import Loader from '../../components/Loader';
import StripeCheckoutButton from '../../components/StripeCheckoutButton';
import { useFade } from '../../components/FadeContext/FadeContext.jsx';
import CurrencyFormat from '../../components/CurrencyFormat.js';
import { useTranslation } from 'react-i18next';

function PayComponent(props) {
    const[t]=useTranslation();
    const { selectedInvoices } = props;
    const contextVariable = useContext(AppContext);
    // const selectedInvoices=contextVariable.invoicesToPay;
    const [selectedInvoicesWithAmountToPay, setSelectedInvoicesWithAmountToPay] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [checkoutDetails, setCheckoutDetails] = useState([]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);

        const selectedInvoicesWithAmountToPay = selectedInvoices.map((invoice) => ({
            ...invoice,
            AmountToPay: invoice.OpenAmount,
        }));
        setSelectedInvoicesWithAmountToPay(selectedInvoicesWithAmountToPay);
        totalAmountDisplay(selectedInvoicesWithAmountToPay);
        //eslint-disable-next-line
    }, []);

    const totalAmountDisplay = (params) => {

        let totalAmount = params.reduce((acc, element) => {
            return acc + parseFloat(element.AmountToPay);
        }, 0);
        setTotalAmount((totalAmount.toFixed(2)));
    }

    const handleTotalAmountToPay = (invoiceID, amount) => {
        const updatedInvoices = [...selectedInvoicesWithAmountToPay];
        const index = updatedInvoices.findIndex((invoice) => invoice.InvoiceID === invoiceID);
        if (index !== -1) {
            updatedInvoices[index].AmountToPay = amount;
        }
        else {
        }
        totalAmountDisplay(updatedInvoices);

        let data = updatedInvoices.map((el) => ({
            description: `${el.InvoiceID}`,
            price: el.AmountToPay,
            referenceId: el.ExternalReference
        }));
        setCheckoutDetails(data);
    }

    const changeIsInputFocused = (props) => {
    }

    const itemTemplate = (data1, layout, index) => {
        if (!data1) {
            return;
        }

        if (layout === "list") {
            return (
                <>
                    <PayInvoiceList
                        index={index}
                        data={data1}
                        changeTotalAmountToPay={handleTotalAmountToPay}
                        isInputFocused={changeIsInputFocused}
                    >
                    </PayInvoiceList>
                </>
            );
        }
    };

    const goToInvoiceView = () => {
        props.backToInvoiceView();
    }

    const { fadeIn } = useFade();
    return (
        <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
            <Button
                icon="pi pi-arrow-left"
                label={t('Back to invoice list')}
                className="secondary-button p-button-sm mb-2"
                onClick={goToInvoiceView}
            ></Button>
            {
                contextVariable.pageLoading && <div className='ProudctTrendingChange-loading -mt-6 '>
                    <div className='flex justify-content-center align-items-center'>
                        <div style={{ marginTop: '20%' }}>
                            <div className='text-center'><Loader /></div> <br />
                        </div>
                    </div>
                </div>

            }

            <div className="container">

                <div
                    className="card-shadow block w-full rounded-lg bg-white text-left shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="pt-3 d-flex">
                        <div className='mx-auto'>
                            <h3 className="mt-0 text-lg font-normal uppercase leading-tight text-secondary">
                                {t('Total Amount to pay')}
                            </h3>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className='mx-auto'>
                            <h5 className="mb-2 border-b font-bold p-2 text-4xl uppercase leading-tight">
                                {/* <CountUp end={totalAmount} duration={1} /> */}
                                {CurrencyFormat(totalAmount)}
                                &nbsp; {contextVariable.taxDetails.CurrencyCode}
                                {/* {CurrencyFormat(totalAmount)} {contextVariable.taxDetails.CurrencyCode} */}
                            </h5>
                        </div>
                    </div>
                </div>

                <div className='mt-3 mb-2'>

                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            <h3 className="mt-0 text-xl font-medium leading-tight text-secondary">
                                {t('Invoices')} ({selectedInvoices.length})
                            </h3>
                        </div>
                        <div>
                            {/* <Button
                                disabled={isInputFocused}
                                className='primary-button px-6 py-1 text-sm md:text-base font-medium'
                            >
                                Pay
                            </Button> */}
                            {
                                (contextVariable.taxDetails.StripeDefaultIndicator === "true") &&
                                <StripeCheckoutButton
                                    product={checkoutDetails}
                                    invoiceList={selectedInvoices}
                                    totalAmountToPay={totalAmount} />
                            }

                            {/* <StripePayment product={checkoutDetails} invoiceList={selectedInvoices} /> */}
                            {/* <button
                                type="button"
                                style={{ borderRadius: '100px' }}
                                class="block bg-primary px-6 py-1 text-sm md:text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                                Pay
                            </button> */}
                        </div>
                    </div>
                    <div className='divide-y divide-blue-600'></div>
                    <div style={{ maxHeight: '63vh', overflowY: 'auto' }}>
                        {
                            loading ?
                                <div className='flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <DataView
                                    value={selectedInvoicesWithAmountToPay}
                                    layout="list"
                                    itemTemplate={itemTemplate}
                                    layoutoptions={{ divider: false }}
                                />
                        }
                    </div>
                </div>

                {/* <table className="table table-responsive table-bordered">
                    <thead>
                        <tr>
                            <th>Invoice ID</th>
                            <th>Invoice Amount</th>
                            <th>Open Amount</th>
                            <th>Amount To Pay</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedInvoices.map((invoice, index) => (
                            <tr key={index}>
                                <td>{invoice.InvoiceID}</td>
                                <td>{invoice.InvoiceAmount}</td>
                                <td>{invoice.OpenAmount}</td>
                                <td>{invoice.NetAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Badge className='secondary-badge' value="Total : 40,000 INR" />
                </div> */}
                {/* <div className='text-right mt-1'>
                    <button className='btn btn-block secondary-button text-center' style={{ width: '20rem' }}>
                    <button className='btn primary-button text-center' style={{ width: '8rem' }}>
                        <CreditScore></CreditScore>
                        Pay
                    </button>
                </div> */}

            </div>
        </Box>
    )
}

export default PayComponent;