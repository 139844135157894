import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout';
import { API } from 'aws-amplify';
import { getByCustomerCreditNotes } from '../../../Layout/MutaionsAndQueries';
import { showToast } from '../../ToastUtils';
import CardTopbar from '../CardTopbar';
import getSymbolFromCurrency from 'currency-symbol-map';
import SkeletonLoader from '../../SkeletonLoader';
import DataNotFound from '../dataNotFound/DataNotFound';
import CurrencyFormat from '../../CurrencyFormat';
import { useTranslation } from 'react-i18next';

const CreditNoteDetailsCard = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  // console.log("contextVariable", contextVariable.rootObject);
  const [generalDetails, setGeneralDetails] = useState({});
  const [itemArray, setItemArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const fetchCreditNoteItemDetails = async () => {
    // console.log("contextVariable.rootObject.CreditNoteID", contextVariable.rootObject)
    setLoading(true);
    try {
      const response = await API.graphql({
        query: getByCustomerCreditNotes,
        variables: {
          InvoiceID: contextVariable.rootObject.CreditNoteID
        }
      });
      setGeneralDetails(response.data.GetByCustomerCreditNotes.items[0]);
      // console.log(generalDetails);
      setItemArray(response.data.GetByCustomerCreditNotes.items[0].CustomerCreditNotesItem);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      setIsError(error);
      console.error("fetchCreditNoteItemDetails", error);
      showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      // showToast(`Cannot get Credit Note Item list : ${error.errors[0].message}`, "error");
    }
  }

  useEffect(() => {
    fetchCreditNoteItemDetails();
    // eslint-disable-next-line 
  }, [])

  return (
    <div
      className='h-screen'
      style={{ background: "#ededed", fontFamily: "helvetica" }}
    >
      {/* Topbar */}
      <CardTopbar
        header={`Credit Note ID : ${contextVariable.rootObject.CreditNoteID}`}
        link="creditNote"
        buttonNeeded={true}
      />

      <div className='m-2 overflow-auto'>
        {
          (Object.keys(generalDetails).length !== 0) && !loading && isError === null &&
          <>
            {/* Reference, status and credit note id */}
            <div
              className='bg-white p-3 flex flex-column justify-content-center align-items-left'
              style={{ gap: "6px" }}
            >
              <div className='flex justify-content-between'>
                {/* Reference and status*/}
                <div>
                  <div>
                    <span className='text-base'>
                      #{" "}{generalDetails.ExternalReference || ''}
                    </span>
                  </div>
                  <div className='text-green-600 text-xs pt-2'>
                    {generalDetails.ReleaseStatusCodeText}
                  </div>
                </div>
                {/* Date and amount */}
                <div>
                  <div className='text-right'>
                    <span className='text-xs value-color'>
                      {contextVariable.rootObject.CreditNoteDate}
                    </span>
                  </div>
                  <div className='text-base pt-2 text-right'>
                    {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(generalDetails.GrossAmount)}
                  </div>
                </div>
              </div>
              <hr />
              {/* Terms */}
              <div>
                <div className='flex gap-2'>
                  <span className='text-xs'>Payment Terms</span>
                  <span className='text-xs value-color'>{generalDetails.PaymentTermsText}</span>
                </div>
                <div className='flex gap-2 pt-2'>
                  <span className='text-xs'>Inco Terms</span>
                  <span className='text-xs value-color'>{generalDetails.IncotermsText}</span>
                </div>
              </div>
            </div>
          </>
        }

        {/* producct details */}
        {
          (itemArray.length !== 0 && !loading && isError === null) &&
          <>
            <span className='text-base font-semibold'>Items</span>
            {
              itemArray
                .map((product, index) => (
                  <div key={index} className='card p-3'>
                    {/* Product name */}
                    <div className='flex justify-content-between align-items-center'>
                      <div>
                        <span className='text-base'>{product.ProductDescription}</span>
                      </div>
                      <div>
                        <span className='text-sm'>{(parseFloat(product.Quantity).toFixed(2))}{" "} {product.QuantityUOMText}</span>
                      </div>
                    </div>

                    {/* Tax and discount */}
                    <div className='mt-2 flex justify-content-between align-items-baseline'>
                      <div className='flex gap-5'>
                        <div className='flex gap-2 align-items-baseline'>
                          <div className='text-xs' >Discount : </div>
                          <div className='text-sm'>{Math.abs(product.Discount)}%</div>
                        </div>
                        <div className='flex gap-2 align-items-baseline'>
                          <div className='text-xs' >Tax : </div>
                          <div className='text-sm'>
                            {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(product.TaxRate)}
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className='text-sm text-right'>
                          {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(product.NetValue)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
            }
          </>
        }

        {
          isError &&
          <>
            <DataNotFound />
          </>
        }

        {/* Loading */}
        {
          loading === true &&
          <>
            <div className="flex flex-column">
              {
                [...Array(10)].map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              }
            </div>
          </>
        }
      </div>

    </div>
  )
}

export default CreditNoteDetailsCard;