import { Badge, Box, IconButton, Menu, Tooltip, Zoom } from "@mui/material";
import './AdminTopbar.css';
import {
  CheckOutlined,
  CloseOutlined, LogoutOutlined, MoreVertOutlined,
  NotificationsActive,
  NotificationsOutlined, PersonOutlineOutlined,
  SyncOutlined,
  WarningAmberOutlined
} from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../Layout/Layout";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Divider } from "@aws-amplify/ui-react";
import NotificationImage from "../../../Pages/global/NotificationEmpty.png";
import { Avatar } from "primereact/avatar";
import { Storage } from "aws-amplify";
import { BatchDeleteNotifications, deleteNotification } from "../../../graphql/mutations";
import { CreateNotify } from "../../../Layout/MutaionsAndQueries";
import { handleShowNotification } from "../../../components/Notification/NotificationComponent";
import ProCustomer from "../../../logo/ProCustomerPNG.png"
import { OverlayPanel } from "primereact/overlaypanel";
import Loader from "../../../components/Loader";
import NotificationComponent from "./NotificationComponent/NotificationComponent";
import { getNotificationDescription } from "../../../components/Notification/NotificationDescription";
import { useTranslation } from "react-i18next";

// import { async } from "q";

const AdminTopbar = ({ cartCount }) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [UserName, setUserName] = useState("");
  const [title, setTitle] = useState(sessionStorage.getItem("title"));
  // const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  // const [anchorElCart, setAnchorElCart] = useState(null);
  const [logoImage, setLogoImage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);


  // const handleToggleRtl = () => {
  //   contextVariable.setRtl(!contextVariable.rtl);
  // };

  const handleMenuNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
    // setAnchorElCart(null);
  }

  const handleMarkAllAsRead = async () => {
    setButtonLoading(true);
    const UpdateAllNotifications = contextVariable.NotificationMsg.map((p) => ({
      id: p.id,
    }));

    try {
      contextVariable.setNotificationMsg([]);
      const response = await API.graphql(
        {
          query: BatchDeleteNotifications,
          variables:
          {
            input: UpdateAllNotifications
          }
        }
      )
      if (response.data.BatchDeleteNotifications && response) {
        setAnchorElNotification(null);
        setButtonLoading(false);
      }
    }
    catch (error) {
      contextVariable.setNotificationMsg([]);
      setButtonLoading(false);
      // console.error("Notification Mark All as Read error", error)
    }
  };

  const handleMenuProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const handleMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const filter = {
      filter: {
        CustomerId: { eq: contextVariable.userDetails.sub + "-" + contextVariable.userDetails["custom:tenant"] },
        NotificationStatus: { eq: 'Unread' }
      }
    }

    const subscription = API.graphql(
      graphqlOperation(CreateNotify, filter)
    ).subscribe({
      next: ({ value }) => {
        const CreatedNotification = value.data.onCreateNotification;
        // console.log("CreatedNotification", CreatedNotification);
        contextVariable.setLoadingPriceList(false);
        // const SplitnotificationArray = JSON.parse(CreatedNotification.NotificationData);
        const updatedData = [CreatedNotification, ...contextVariable.NotificationMsg];
        // Desktop notification
        handleShowNotification(CreatedNotification);
        // console.log("createNofication", CreatedNotification)
        if (CreatedNotification.NotificationReasonCode === "PD_Sync") {
          // showToast(`The product details have been synchronized successfully. Please refresh the table.`, "success",12000);
          contextVariable.setShow("ProductSyncFinish");
          contextVariable.setNotificationMsg(updatedData);
        }
        if (CreatedNotification.NotificationReasonCode === "PP_Sync") {
          // showToast(`The product price details have been synchronized successfully. Please refresh the table.`, "success",12000);
          contextVariable.setNotificationMsg(updatedData);
          contextVariable.setShow("PriceSyncFinish");
        }
        if (CreatedNotification.NotificationReasonCode === "CD_Sync") {
          // showToast(`The contact details have been synchronized successfully. Please refresh the table.`, "success",12000);
          contextVariable.setNotificationMsg(updatedData);
          contextVariable.setShow("ContactSyncFinish");
        }
        else {
          contextVariable.setNotificationMsg(updatedData);
          contextVariable.setLoadingPriceList(false);
        }
      },
      error: (error) => {
        console.error('Subscription Error:', error);
        contextVariable.setLoadingPriceList(false);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const storedTitle = sessionStorage.getItem("title");
      if (storedTitle !== title) {
        setTitle(storedTitle);
      }
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, [title]);

  const getLogo = async () => {
    // console.log("TAX---", contextVariable);
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.signInUserSession && user.signInUserSession.idToken) {
      setUserName(user.signInUserSession.idToken.payload["name"])
    }

    if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.Logo) {
      const imageKey = contextVariable.OrganizationDetails.Logo.split("public/")[1];
      try {
        const authorizedUrl = await Storage.get(imageKey);
        // console.log(authorizedUrl);
        setLogoImage(authorizedUrl);
        sessionStorage.setItem("ogol", authorizedUrl)

        const img = new Image();
        img.src = authorizedUrl;
        img.onload = () => {
          // const aspectRatio = img.width / img.height;
          // const width = aspectRatio === 1 ? '35px' : '100px';
        };
      } catch (error) {
        console.error("ERROR", error)
        window.location.reload()
      }
    }
  };

  useEffect(() => {
    getLogo();
    //eslint-disable-next-line
  }, [contextVariable]);

  const isMobileView = () => {
    return window.innerWidth <= 768;
  };

  const handleToDeleteReadFile = async (Notify) => {
    try {
      const DeleteData = {
        id: Notify.id,
      }
      await API.graphql({
        query: deleteNotification,
        variables:
        {
          input: DeleteData
        }
      })
      const updateNotificationdata = contextVariable.NotificationMsg.filter((p) => p.id !== Notify.id);
      contextVariable.setNotificationMsg(updateNotificationdata);
      // showToast("Notification deleted");
    }
    catch (error) {
      console.error("Notification Double Click error", error)
    }
  }

  const calculateTimeDifference = (current, initial) => {
    const timeDifference = (current - initial) / 1000; // Convert to seconds

    if (timeDifference >= 3600) {
      const hours = Math.floor(timeDifference / 3600);
      const remainingSeconds = timeDifference % 3600;
      if (remainingSeconds >= 60) {
        const minutes = Math.floor(remainingSeconds / 60);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      }
    } else if (timeDifference >= 60) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      const seconds = Math.floor(timeDifference);
      return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
    }
  };

  const renderMobileDropdown = () => {
    // Desktop View
    return (
      <>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElProfile}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElProfile)}
          onClose={handleCloseProfile}
        >
          <div className="p-3">
            <div className="text-center text-lg text-slate-300">
              {/* <MenuItem disabled> */}
              Profile
              {/* </MenuItem> */}
            </div>

            <Divider />
            <div className="text-center p-3 w-15rem">
              <div className="mt-3">
                <Avatar
                  style={{ borderRadius: '50%', background: "var(--sidebar-color)", color: "white" }}
                  className="font-semibold"
                  label={UserName ? UserName.charAt(0).toUpperCase() : ""}
                  icon="pi pi-user"
                  size="xlarge"
                  shape="circle"
                />
              </div>
              <div className="mt-3">
                <span className="text-base font-semibold">
                  Hi, {UserName ? UserName.charAt(0).toUpperCase() + UserName.slice(1).toLowerCase() : ""} 👋
                </span>
              </div>
              <div>
                <span className="text-sm text-gray-400 font-semibold">
                  {contextVariable.userDetails["custom:customer_id"]}
                </span>
              </div>
            </div>
            <div
              onClick={() => {
                contextVariable.setShow("Logout");
                handleCloseProfile();
              }}
              style={{ background: "var(--sidebar-color)" }}
              className="mt-3 border-round-md cursor-pointer p-2 flex justify-content-center gap-2">
              <LogoutOutlined className="text-white" />
              <span className="text-white font-semibold">Logout</span>
            </div>
          </div>

        </Menu>

        <Menu
          id="menu-appbar"
          anchorEl={anchorElNotification}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNotification)}
          onClose={handleCloseNotification}
          className="notification"
          PaperProps={{ style: { width: '400px' } }}
        >
          <div className="text-center text-lg font-semibold z-5 shadow-md"
            style={{ position: 'sticky', top: 0, background: "white", color: "var(--sidebar-color)" }}>
            Notifications
          </div>

          {(contextVariable.NotificationMsg.length > 0) &&
            <div>
              {
                buttonLoading === false &&
                <div
                  className="text-center px-4 py-2 hover:cursor-pointer m-2 border-round-md"
                  style={{ background: 'var(--primary-color)', cursor: 'pointer' }}
                  onClick={handleMarkAllAsRead}
                >
                  <span className="font-semibold text-white border-rounded">
                    Mark all as read
                  </span>
                </div>
              }
              {
                buttonLoading === true &&
                <div className="flex justify-content-center mt-2">
                  <Loader />
                </div>
              }
            </div>
          }

          {
            contextVariable.NotificationMsg.length === 0 &&
            <div>
              <div className="flex justify-content-center">
                <img style={{ height: '30vh' }} src={NotificationImage} alt="notifyImage" />

              </div>
              <span className="flex justify-content-center font-semibold text-sm">There are no notifications to display at the moment.</span>
            </div>
          }

          {
            contextVariable.NotificationMsg.length > 0 && contextVariable.NotificationMsg.map((Notify, index) => {
              const initialDate = new Date(Notify.updatedAt);
              const currentDate = new Date();
              const timeDifference = (currentDate - initialDate) / 1000;
              const timeDifferenceText =
                timeDifference < 60
                  ? "Just now"
                  : timeDifference >= 86400
                    ? `${initialDate.toLocaleString()}`
                    : calculateTimeDifference(currentDate, initialDate);

              return (
                <div
                  // onDoubleClick={() => handleNavigate(Notify)}
                  style={{
                    backgroundColor: Notify.NotificationStatus === "Unread" ? 'white' : "rgba(229, 229, 229, 0.792)",
                  }}
                  className="mt-1 mb-1"
                  key={index}
                >
                  <div className="text-left p-2 hover:bg-pink-50 hover:cursor-pointer">
                    <div className="flex justify-content-center align-items-center gap-2">

                      {
                        Notify.NotificationReasonCode === "Request failed with status code 500" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          {
                            Notify.NotificationReasonCode !== "Sync"
                            && <WarningAmberOutlined />
                          }
                        </div>
                      }

                      {/* ProductDetails Notifications */}
                      {
                        Notify.NotificationReasonCode === "PD_Sync" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          {
                            <SyncOutlined />
                          }
                        </div>
                      }

                      {/* ProductPrice Notifications */}
                      {
                        Notify.NotificationReasonCode === "PP_Sync" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          {
                            <SyncOutlined />
                          }
                        </div>
                      }

                      {/* {
                        Notify.NotificationReasonCode !== "Request failed with status code 500" &&
                        Notify.NotificationReasonCode !== "BasePrice Uploaded" &&
                        Notify.NotificationReasonCode !== "BasePrice Failed" &&
                        Notify.NotificationReasonCode !== "BasePrice Empty" &&
                        Notify.NotificationReasonCode !== "BasePrice_P Uploaded" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          {
                            Notify.NotificationReasonCode !== "Sync"
                            && <SyncOutlined />
                          }
                        </div>
                      } */}

                      {/* Base Price Notifications */}
                      {
                        Notify.NotificationReasonCode === "BP_U" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          <CheckOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "BP_P_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "BP_E" &&
                        <div className="bg-yellow-600 text-white p-2 border-circle">
                          <WarningAmberOutlined sx={{ transform: 'scaleX(-1)' }} />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "BP_F" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }

                      {/* CSP Notifications */}
                      {
                        Notify.NotificationReasonCode === "CSP_U" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          <CheckOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSP_P_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSP_C_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSP_P_C_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSP_F" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {/* {
                        Notify.NotificationReasonCode === "CSP_D_F" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      } */}

                      {/* CSD Notifications */}
                      {
                        Notify.NotificationReasonCode === "CSD_U" &&
                        <div className="bg-cyan-500 text-white p-2 border-circle">
                          <CheckOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSD_P_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSD_C_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSD_P_C_U" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {
                        Notify.NotificationReasonCode === "CSD_F" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      }
                      {/* {
                        Notify.NotificationReasonCode === "CSD_D_F" &&
                        <div className="bg-red-500 text-white p-2 border-circle">
                          <WarningAmberOutlined />
                        </div>
                      } */}

                      <div className="text-xs font-medium flex-1"> {Notify.NotificationReasonCode && showNotificationDescription(Notify.NotificationReasonCode)}</div>
                      {/* <div className="text-xs font-medium flex-1"> {Notify.MessageText}</div> */}

                      <div className="align-self-baseline">
                        <p className="text-xs">
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            title="Delete"
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                          >
                            <button className="p-1 text-cyan-600" onClick={() => handleToDeleteReadFile(Notify)}>
                              <CloseOutlined fontSize="small" />
                            </button>
                          </Tooltip>
                        </p>
                      </div>

                    </div>
                    {/* <p className="text-xs" style={{ display: "flex", justifyContent: "space-between", color: "gray", alignItems: "center" }}>
                      {timeDifferenceText}
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'var(--sidebar-color)',
                              fontSize: '12px'
                            },
                          },
                        }}
                        title="Delete"
                        placement="bottom"
                        TransitionComponent={Zoom}
                        enterDelay={500}
                      >
                        <button className="p-1 text-cyan-500" onClick={() => handleToDeleteReadFile(Notify)}>
                          <CloseOutlined fontSize="small" />
                        </button>
                      </Tooltip>
                    </p> */}
                    <div className="flex justify-content-between align-items-center">
                      <p className="text-xs text-gray-500">{timeDifferenceText}</p>
                      {
                        (Notify.NotificationReasonCode === "BP_P_U" ||
                          Notify.NotificationReasonCode === "CSP_P_U" ||
                          Notify.NotificationReasonCode === "CSP_C_U" ||
                          Notify.NotificationReasonCode === "CSP_P_C_U" ||

                          Notify.NotificationReasonCode === "CSD_P_U" ||
                          Notify.NotificationReasonCode === "CSD_C_U" ||
                          Notify.NotificationReasonCode === "CSD_P_C_U") &&
                        <NotificationComponent Notify={Notify} />
                      }
                    </div>
                  </div>
                  <Divider />
                </div>
              );
            })
          }
        </Menu>

        {/* icon buttons for open Menu items */}
        <img
          src={ProCustomer}
          alt="logo"
          style={{ height: '40px', opacity: "60%" }}
        />

        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'var(--sidebar-color)',
                fontSize: '12px'
              },
            },
          }}
          title="Notifications"
          placement="bottom"
          TransitionComponent={Zoom}
          enterDelay={1000}
        >
          <IconButton
            onClick={handleMenuNotification}
          >
            <Badge color="primary" badgeContent={contextVariable.NotificationMsg.length}>
              {
                contextVariable.NotificationMsg.length !== 0 ?
                  <NotificationsActive
                    className="swing-animation text-base md:text-2xl"
                  />
                  :
                  <NotificationsOutlined
                    className='text-base md:text-xl'
                  />
              }
              {/* <NotificationsOutlined className={contextVariable.NotificationMsg.length !== 0 ? 'swing-animation text-base md:text-2xl' : 'text-base md:text-xl'} /> */}
            </Badge>
          </IconButton>
        </Tooltip>

        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'var(--sidebar-color)',
                fontSize: '12px'
              },
            },
          }}
          title="Profile"
          placement="bottom"
          TransitionComponent={Zoom}
          enterDelay={1000}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuProfile}
          >
            <PersonOutlineOutlined />
          </IconButton>
        </Tooltip>

        {/* Toggle RTL */}
        {/* {
          contextVariable.rtl ?
            <>
              <IconButton
                onClick={handleToggleRtl}
              >
                <ToggleOn
                  style={{ color: "var(--sidebar-color)" }}
                  className='text-base md:text-xl'
                />
              </IconButton>
            </>
            :
            <>
              <IconButton
                onClick={handleToggleRtl}
              >
                <ToggleOffOutlined
                  style={{ color: "var(--sidebar-color)" }}
                  className='text-base md:text-xl'
                />
              </IconButton>
            </>
        } */}

        {/* <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'var(--sidebar-color)',
                  fontSize: '12px'
                },
              },
            }}
            title="Toggle Hebrew"
            placement="bottom"
            TransitionComponent={Zoom}
            enterDelay={1000}
          >

            <IconButton
              onClick={handleToggleDirection}
            >
              {contextVariable.isRTL ? <ToggleOnOutlined /> : <ToggleOffOutlined />}
            </IconButton>
          </Tooltip> */}
      </>
    );
  };
  const op = useRef(null);

  const showNotificationDescription = (NotifyCode) => {
    const notifiyData = getNotificationDescription(NotifyCode);
    return t(notifiyData.description);
  }

  return (
    <>
      <Box
        className="py-1 topbar">
        <OverlayPanel ref={op} className='w-12rem border-round-xl' >
          <div className="text-center">
            <img src={logoImage} alt="logo" />
            <span className="text-base font-semibold">
              {contextVariable.OrganizationDetails?.CompanyName}
            </span>
          </div>
        </OverlayPanel>
        <div className='flex justify-content-between align-items-center'>
          <div>
            {logoImage && <div className="flex gap-2 justify-content-around align-items-center ml-1 mr-1">
              <img
                className=" cursor-pointer"
                onClick={(e) => op.current.toggle(e)}
                // src={ProCustomer}
                src={logoImage}
                alt="logo"
                style={{ height: '40px', marginLeft: "0.3rem"}}
              />
              <span
                className="font-semibold opacity-75"
                style={{ fontSize: "10px", fontStyle: "oblique 10deg" }}
              >
                {contextVariable.OrganizationDetails?.CompanyName}
              </span>
            </div>
            }
          </div>

          <div className="Page_header">
            <span className="text-sm md:text-lg font-semibold">
              {title}
            </span>
          </div>

          {/* <div style={isMobile ? { marginRight: '3rem' } : { marginRight: '2rem' }}> */}
          <div className="topbar-items flex justify-content-center align-items-center">
            {/* Show the 'MoreVertOutlined' icon to open the dropdown menu only in mobile view */}
            {isMobileView() && (
              <IconButton onClick={handleMenuOpen}>
                <MoreVertOutlined />
              </IconButton>
            )}

            {/* Render the dropdown menu conditionally based on the view */}
            {renderMobileDropdown()}
          </div>
        </div>

      </Box>
    </>
  );
};

export default AdminTopbar;
