import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react'
import './OrderCard.css';
import { SimCardDownload } from '@mui/icons-material';
import GetOrderPDF from '../../../Pages/order/GetOrderPDF';
import { showToast } from '../../ToastUtils';
import CurrencyFormat from '../../CurrencyFormat.js';
import { useTranslation } from 'react-i18next';

const OrderCard = ({ index, orderDetails, contextVariable }) => {
    // console.log("orderDetails", typeof (orderDetails), orderDetails);
    const { t } = useTranslation();
    let order = orderDetails;

    const handlePreviewClick = (uuid, orderID) => {
        GetOrderPDF("order", uuid, orderID);
        showToast(t(`Started downloading...`), "info", 1000);
    }

    const handleCardClick = (params) => {
        contextVariable.setScrollPosition(window.scrollY);
        contextVariable.setRootObject(params);
        contextVariable.setShowView((prev) => ({ ...prev, showOrderDetails: true }));
        // navigate('/orderDetails');
    }

    return (
        <>
            <div key={index} className="card m-2" style={{ fontFamily: "helvetica", marginTop: "0" }}>
                <div
                    className={`order-container ${order.OrderStatusCode === '1' ? 'card-border-open' :
                        order.OrderStatusCode === '2' ? 'card-border-in-process' :
                            order.OrderStatusCode === '3' ? 'card-border-completed' :
                                order.OrderStatusCode === '4' ? 'card-border-in-preparation' :
                                    ''
                        }`}
                >
                    {/* PDF container */}
                    {
                        (order.PDFUUID).trim() !== '' ?
                            <div
                                onClick={() => handlePreviewClick(order.PDFUUID, order.OrderID)}
                                style={{ background: "#f8f8f8" }}
                                className='p-3 flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <SimCardDownload
                                        style={{ fontSize: "2rem", color: "#ff6c6c" }} />
                                </div>
                                <div>
                                    <span className='font-10'>PDF</span>
                                </div>
                            </div>
                            :
                            <div
                                style={{ background: "#f8f8f8" }}
                                className='p-3 flex flex-column justify-content-center align-items-center'>
                                <div>
                                    <span className='text-sm' style={{ color: "#c5c5c5" }}>NO <br />PDF</span>
                                </div>
                            </div>
                    }

                    {/* Reference and status */}
                    <div
                        onClick={() => handleCardClick(orderDetails)}
                        className='bg-white flex flex-column justify-content-center align-items-left' style={{ gap: "6px" }}>
                        <div>
                            <span className='text-base'>#{" "}{order.ExternalReference}</span>
                        </div>
                        <div className='flex justify-content-start gap-2 align-items-left'>
                            {
                                (order.OrderStatusCode === "1") &&
                                <span className='text-xs' style={{ color: "var(--info-color)" }}>{order.OrderStatus}</span> //In process
                            }
                            {
                                (order.OrderStatusCode === "2") &&
                                <span className='text-xs' style={{ color: "var(--sidebar-color)" }}>{order.OrderStatus}</span> //In process
                            }
                            {
                                (order.OrderStatusCode === "3") &&
                                <span className='text-xs' style={{ color: "#00b321" }}>{order.OrderStatus}</span> //Completed
                            }
                            {
                                (order.OrderStatusCode === "4") &&
                                <span className='text-xs' style={{ color: "var(--primary-color)" }}>{order.OrderStatus}</span> //In preparation
                            }
                            {
                                (order.OrderStatusCode !== "2" && order.OrderStatusCode !== "3" && order.OrderStatusCode !== "4") &&
                                <span className='text-xs'>{order.OrderStatus}</span>
                            }
                            <span className='text-sm' style={{ color: "#8a8a8a" }}>{order.OrderID}</span>
                        </div>
                    </div>

                    {/* Date and total amount */}
                    <div
                        onClick={() => handleCardClick(orderDetails)}
                        className='bg-white flex flex-column justify-content-center' style={{ alignItems: 'flex-end', gap: "6px" }}>
                        <div>
                            <span className='text-sm' style={{ color: "#8a8a8a" }}>{order.CreatedDate}</span>
                        </div>
                        <div>
                            <span className='text-base'>
                                {/* {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(order.GrossAmount)).toFixed(2)} */}
                                {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(order.GrossAmount)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderCard;