import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { useEffect } from 'react'

function DialogOfClearingInvoice(props) {

   

    // console.log("props", props)
    // const unCheckAll = () => {
    //     props.setSelectedInvoices((prev) => prev.filter(p => {
    //         const test = props.UnClearInvoice.Data.some(s => s.InvoiceID === p.InvoiceID)
    //         if (!test) {
    //             return p
    //         }
    //     }
    //     ))
    //     props.setUnClearInvoice({ DialogShow: false, Data: [] })
    // }

    const closeBox = () => {
        props.setUnClearInvoice({ DialogShow: false, Data: [] })
    }

    const renderFooter = (name) => {
        return (
            <>

                {/* <div className='flex justify-content-end'>
                    <Button label="Uncheck all" icon="pi pi-check"
                        onClick={() => unCheckAll()}
                    />
                </div> */}
                <Button
                    className={`${props.isMobile && "mt-2"}`}
                    label='Got it' icon="pi pi-check"
                    onClick={() => closeBox()}
                />
            </>
        );
    }
    return (
        <>
            <Dialog

                visible={props.UnClearInvoice.DialogShow}
                onHide={() => props.setUnClearInvoice({ DialogShow: false, Data: [] })}
                header="Uncleared Invoices"
                style={{ width: props.isMobile ? "90vw" : "50vw" }}
                footer={renderFooter}
            >
                <div className={`${props.isMobile && "mt-3"}`}>
                    <span>The following invoices cannot be selected as there are open payments that need to be applied in our ERP system.</span>
                </div>

                <div className={`${props.isMobile && "mt-4"} flex  gap-2 flex-wrap mt-2  `}>
                    {props.UnClearInvoice.Data.map((p, index) =>
                        <div key={index} >
                            <div className='flex flex-column p-2 border-1 border-blue-200  border-round-md   font-semibold'>
                                {/* <span className='text-xs text-gray-500'>Invoice ID </span> */}
                                <span className='text-sm'>{p.InvoiceID}</span>
                                {/* <span className='text-xs text-gray-500 mt-2'>Clearing ID </span>  */}
                                {/* <span className='text-xs text-gray-500'>{p.ClearingID}</span> */}
                            </div>

                        </div>)}
                </div>
            </Dialog>
        </>
    )
}

export default DialogOfClearingInvoice