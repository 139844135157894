import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout';

export default function UOMSection({ UOMobj, index, selectedData, setSelectedData, ProductStock, setCount }) {

  const [UOMStockAvl, setUOmStockAvl] = useState(0);
  const contextVariable = useContext(AppContext);
  useEffect(() => {
    if ((ProductStock !== "NoLimit" || ProductStock > 0)) {

      setUOmStockAvl(parseInt(ProductStock / UOMobj.Quantity))
    }
    else if (ProductStock === "NoLimit") {
      // setUOmStockAvl(parseInt(9999 / UOMobj.Quantity))
      setUOmStockAvl(9999)
    }
  }, [ProductStock, UOMobj.Quantity])


  let CorrespondingQuantityTypeCodeText = contextVariable.AllUOMDetails.find(P =>
    P.Value === UOMobj.CorrespondingQuantityTypeCode
  )?.Description || '';
  let QuantityUnitCodeText = contextVariable.AllUOMDetails.find(P =>
    P.Value === UOMobj.unitCode
  )?.Description || '';
  const handleUOMChange = () => {
    // console.log("UOMobj", UOMobj)
    setSelectedData({ ...selectedData, ChangeUOM: UOMobj.CorrespondingQuantityTypeCode, UOMStockAvl: UOMStockAvl, UOMQuantity: parseFloat(UOMobj.Quantity).toFixed(2) })
    setCount(1)
  }

  return (
    <div key={index} >
     

      {UOMStockAvl > 0 && <div
        className={`border-1  border-round-md  cursor-pointer 
          ${selectedData?.ChangeUOM === UOMobj.CorrespondingQuantityTypeCode ? ' border-blue-500 text-center border-2 ' : 'hover:border-blue-500 hover:bg-blue-50  border-gray-400'}`}
      >

        <Button className={` cursor-pointer capitalize flex gap-1 justify-content-center
          ${selectedData?.ChangeUOM === UOMobj.CorrespondingQuantityTypeCode ?
            'text-blue-500 text-center font-semibold '
            : 'hover:text-blue-700  text-black'
          }`} size='small' onClick={handleUOMChange} >
          <i className={` ${selectedData?.ChangeUOM === UOMobj.CorrespondingQuantityTypeCode ? 'pi pi-check' : ''}`}
            style={{ fontSize: '0.7rem' }}></i>
          <div>

            {parseInt(UOMobj.CorrespondingQuantity)} {CorrespondingQuantityTypeCodeText} = {parseInt(UOMobj.Quantity)} {QuantityUnitCodeText}</div>
        </Button>
      </div>}

      {
        UOMStockAvl <= 0 && <div className='border-2  border-round-md border-dashed'>
          <Button disabled size='small' className='capitalize text-gray-400 flex'>
            <div>
              {parseInt(UOMobj.CorrespondingQuantity)} {CorrespondingQuantityTypeCodeText}
            </div>
            <div>  = {parseInt(UOMobj.Quantity)} {QuantityUnitCodeText}</div>
          </Button>
        </div>
      }

      {(ProductStock !== "NoLimit" && selectedData?.ChangeUOM ===UOMobj.CorrespondingQuantityTypeCode) &&
        <span style={{ fontSize: '0.6rem' }} className='text-gray-500 flex justify-content-end justify-items-center'>
          (Avl: {UOMStockAvl}  {CorrespondingQuantityTypeCodeText})</span>}
    </div>
  )
}
