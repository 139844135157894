// ProductsListsByTenant 
export const BasePriceProducts = /* GraphQL */ `
  query ProductsListsByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductsListsByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductImage
        ProductStatus
        ProductActiveStatus
        ProductQuantityDetails
        ProductDetailDescription
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        UserFavouriteProducts {
          items {
            FavouriteStatus
            id
            owner
            productID
          }
        }
      
      }
      nextToken
      __typename
    }
  }
`;

export const BaseAndCSDProducts = /* GraphQL */ `
  query ProductsListsByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
    $customerUUID: String
  ) {
    ProductsListsByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductImage
        ProductStatus
        ProductActiveStatus
        ProductQuantityDetails
        ProductDetailDescription
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceCurrencyCode
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        ProductsDiscounts(filter: {
        CustomerUUID: { eq: $customerUUID } 
      }) {
          items {
            Customer
            CustomerUUID
            ProductDiscount
            productID
            id
          }
        }
        UserFavouriteProducts {
          items {
            FavouriteStatus
            id
            owner
            productID
          }
        }
      
      }
      nextToken
      __typename
    }
  }
`;

export const BaseAndCSPProducts = /* GraphQL */ `
  query ProductsListsByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
    $customerUUID: String
  ) {
    ProductsListsByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductImage
        ProductStatus
        ProductActiveStatus
        ProductQuantityDetails
        ProductDetailDescription
        ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
        CustomerSpecificPrices(filter: {
        CustomerUUID: { eq: $customerUUID } 
      }) {
        items {
          Customer
          CustomerUUID
          ProductID
          ProductPrice
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          ProductPriceQuantity
          ProductPriceQuantityCode
          id
          productID
          tenant
        }
        }
        UserFavouriteProducts {
          items {
            FavouriteStatus
            id
            owner
            productID
          }
        }
      
      }
      nextToken
      __typename
    }
  }
`;

//CartDetails fetch userProductsCartsByCarttableID
export const BasePriceCartDetails = /* GraphQL */ `
  query UserProductsCartsByCarttableID(
    $carttableID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProductsCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProductsCartsByCarttableID(
      carttableID: $carttableID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Productdetails {
          ProductQuantityDetails
          ProductCategoryId
          ProductCategoryName
          ProductId
          ProductImage
          ProductName
          ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
          ProductStatus
          ProductUOM
          ProductUOMText
          id
        }
        id
        ProductQuantity
        ProductQuantityText
        ProductPriceUOMCode
       CalculatedUOMQuantity
       owner
       ProductPrice
        ProductDiscount
        carttableID
        userProductsCartProductdetailsId
      }
      nextToken
      __typename
    }
  }
`;
export const BasePriceAndCSDCartDetails = /* GraphQL */ `
  query UserProductsCartsByCarttableID(
    $carttableID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProductsCartFilterInput
    $discountFilter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProductsCartsByCarttableID(
      carttableID: $carttableID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Productdetails {
          ProductsDiscounts(filter: $discountFilter) {
            items {
              Customer
              CustomerUUID
              ProductDiscount
              id
              productID
            }
          }
          ProductQuantityDetails
          ProductCategoryId
          ProductCategoryName
          ProductId
          ProductImage
          ProductName
          ProductsPrices {
          items {
            id
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
           
          }
        }
          ProductStatus
          ProductUOM
          ProductUOMText
          id
        }
        ProductQuantity
        ProductQuantityText
        ProductPriceUOMCode
       CalculatedUOMQuantity
       owner
       ProductPrice
        ProductDiscount
        carttableID
        userProductsCartProductdetailsId
      }
      nextToken
      __typename
    }
  }
`;
export const BasePriceAndCSPCartDetails = /* GraphQL */ `
  query UserProductsCartsByCarttableID(
    $carttableID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProductsCartFilterInput
    $limit: Int
    $nextToken: String
    $customerUUID: String
  ) {
    userProductsCartsByCarttableID(
      carttableID: $carttableID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductQuantity
        ProductQuantityText
        ProductPriceUOMCode
       CalculatedUOMQuantity
       owner
       ProductPrice
        ProductDiscount
        carttableID
        userProductsCartProductdetailsId
      Productdetails {
        ProductQuantityDetails
        ProductActiveStatus
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductId
        ProductImage
        ProductName
        ProductStatus
        ProductUOM
        ProductUOMText
        id
        tenant
        CustomerSpecificPrices(filter: {CustomerUUID: {eq: $customerUUID}}) {
          items {
            Customer
            CustomerUUID
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            id
            productID
            tenant
          }
        }
        ProductsPrices {
          items {
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            id
            productID
            tenant
          }
        }
      }
    }
      nextToken
      __typename
    }
  }
`;

// Individual Product Price Details
export const productPricesByProductID = /* GraphQL */ `
  query ProductPricesByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productPricesByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        productID
        tenant
      }
      nextToken
      __typename
    }
  }
`;
