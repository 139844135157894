import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout'
import { API } from 'aws-amplify';
import SkeletonLoader from '../../SkeletonLoader';
import { ContentCopyOutlined } from '@mui/icons-material';
import { getByCustomerOutboundDeliveryDetail } from '../../../Layout/MutaionsAndQueries';
import { showToast } from '../../ToastUtils';
import './DeliveryCard.css';
import { IconButton } from '@mui/material';
import copyToClipboard from '../../copyToClipboard';
import CardTopbar from '../CardTopbar';
import DataNotFound from '../dataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';

const DeliveryDetailsCard = () => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    // console.log("contextVariable", contextVariable.rootObject);
    const [generalDetails, setGeneralDetails] = useState({});
    const [itemArray, setItemArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(null);

    const fetchDeliveryItemDetails = async () => {
        setLoading(true);
        try {
            const response = await API.graphql({
                query: getByCustomerOutboundDeliveryDetail,
                variables: {
                    DeliveryID: contextVariable.rootObject.DeliveryID
                }
            });
            // console.log("response.data.GetByCustomerOutboundDeliveryDetail.items[0]", response.data.GetByCustomerOutboundDeliveryDetail.items[0]);
            setGeneralDetails(response.data.GetByCustomerOutboundDeliveryDetail.items[0]);
            setItemArray(response.data.GetByCustomerOutboundDeliveryDetail.items[0].OutboundDeliveryItem);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            setIsError(error);
            console.error("Outbound delivery Error", error);
            showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            // showToast(`Cannot get Outbound delivery list : ${error.errors[0].message}`, "error");
        }
    }
    useEffect(() => {
        fetchDeliveryItemDetails();
        // eslint-disable-next-line 
    }, [])

    const handleCopyToClipboard = (params) => {
        if (params) {
            copyToClipboard(params);
        }
    }

    return (
        <>
            <div className='h-screen' style={{ background: "#ededed", fontFamily: "helvetica" }}>

                {/* Topbar */}
                <CardTopbar
                    header={`Delivery ID : ${contextVariable.rootObject.DeliveryID}`}
                    link="delivery"
                    buttonNeeded={true}
                />
                {/* Body section */}
                <div className="m-2 overflow-auto">
                    {
                        (Object.keys(generalDetails).length !== 0) && !loading && isError === null &&
                        <>
                            <div className='card p-2 bg-white' style={{ color: "var(--sidebar-color)" }}>
                                {/* 1st section */}
                                <div className='p-2 flex gap-1'>
                                    <span className='text-xs'>Track ID :</span>
                                    <span className='text-xs'>{generalDetails.TrackingID}</span>
                                </div>
                                <hr />
                                {/* 2nd section */}
                                <div className='p-2'>
                                    {/* Delivery and gross weight */}
                                    <div className='flex justify-content-between align-items-center'>
                                        <div className='flex gap-1'>
                                            <span className='text-xs'>Delivery :</span>
                                            <span className='text-xs value-color'>{contextVariable.rootObject.DeliveryDate}</span> {/* delivery date not presented in general details*/}
                                        </div>
                                        <div className='flex gap-1'>
                                            <span className='text-xs'>Gross Weight :</span>
                                            <span className='text-xs value-color'>{(Number(generalDetails.GrossWeight)).toFixed(2)}{" "}{generalDetails.GrossWeightUnitCode}</span>
                                        </div>
                                    </div>
                                    {/* Freight forwarder */}
                                    <div className='pt-2'>
                                        <span className='text-xs'>Freight Forwarder :</span>
                                        <span className='text-xs value-color'>{generalDetails.FreightForwarder}</span>
                                    </div>
                                    {/* Inco terms */}
                                    <div className='pt-2'>
                                        <span className='text-xs'>Inco Terms :</span>
                                        <span className='text-xs value-color'>{generalDetails.Incoterms}</span>
                                    </div>
                                    {/* Tracking URL */}
                                    <div className='mt-2 border-1 border-gray-300 border-round-md'>
                                        <div className='p-2 flex justify-content-between align-items-top'>
                                            <div>
                                                <span className='text-sm value-color'>Tracking URL :</span> <br />
                                                <span
                                                >
                                                    {generalDetails.TrackingURI || ""}
                                                </span>
                                            </div>
                                            <div>
                                                <IconButton
                                                    onClick={() => handleCopyToClipboard(generalDetails.TrackingURI)}
                                                >
                                                    <ContentCopyOutlined
                                                        className='value-color'
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {/* product details */}
                    {
                        (itemArray.length !== 0 && !loading && isError === null) &&
                        <>
                            <div>
                                <span className='text-base font-semibold'>Items</span>
                                {
                                    itemArray
                                        .map((product, index) => (
                                            <div key={index} className='card p-3'>
                                                {/* Product description and quantity */}
                                                <div>
                                                    <span className='text-sm'>{product.ProductDescription}</span>
                                                </div>

                                                {/* product item id and reference */}
                                                <div className='flex gap-3 pt-2'>
                                                    <span className='value-color text-xs'>ID : {product.OrderNO}</span>
                                                    <span className='value-color text-xs'>#  {product.ExternalReferenceNO || '2342323343223'}</span>
                                                </div>

                                                {/* Batch and amount */}
                                                <div className='flex justify-content-between align-items-center pt-2'>
                                                    <div className='flex gap-2'>
                                                        <div>
                                                            <span className='valu-color'>Batch : </span>
                                                        </div>
                                                        <div>
                                                            <span className='valu-color'>{product.BatchID || '98098908099809'}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className='text-sm'>
                                                            <span>{(Number(product.Quantity)).toFixed(2)}{" "}{product.QuantityUOMText}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </>
                    }

                    {/* Error */}
                    {
                        isError !== null &&
                        <>
                            <DataNotFound />
                        </>
                    }

                    {/* Loading */}
                    {
                        loading === true &&
                        <>
                            <div className="flex flex-column">
                                {
                                    [...Array(10)].map((_, index) => (
                                        <SkeletonLoader key={index} />
                                    ))
                                }
                            </div>
                        </>
                    }


                </div>
            </div>
        </>
    )
}

export default DeliveryDetailsCard