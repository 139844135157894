import { Box, Divider, FormControl, IconButton, InputAdornment, TextField, Tooltip, Zoom } from '@mui/material'
import { Button } from 'primereact/button';
import AdminDialogComponent from '../../../../components/Admin/AdminDialogComponent';
import { useEffect, useState } from 'react';
import { AppContext } from '../../../../Layout/Layout';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ClearOutlined, EditOutlined, InfoRounded, SaveOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Field, Formik } from 'formik';
import { showToast } from '../../../../components/ToastUtils';

import InfoBar from '../../../../components/InfoBar';

export default function SAPandStripeDetails() {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [SubmitedValues, setSubmitedValues] = useState({});

    const initialValues = {
        SAPHostName: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        OdataUsername: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        OdataPassword: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        SoapUsername: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        SoapPassword: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",

        PublishableKey: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.StripeDefaultIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        SecretKey: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.StripeDefaultIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        // EndpointSecret: contextVariable.OrganizationDetails !== "Empty" ?
        //     (contextVariable.OrganizationDetails.StripeDefaultIndicator === "true" ? "XXXXXXXXXXXXX" : "")
        //     : "",
        showSAPPassword: false,
        showSOAPPassword: false
    }

    //set the Top bar Title
    sessionStorage.setItem("title", t("Customer Portal Setup"));

    // Form Validatation Section 
    const validateURL = (value) => {
        let error;
        if (!value) {
            error = "Field is required.For  E.g., https://my000000.sapbydesign.com";
        }
        else if (!value.startsWith("https://")) {
            error = "Invalid URL.For  E.g., https://my000000.sapbydesign.com";
        }
        else if (value.trim().length === 0) {
            error = "Field is required.For  E.g., https://my000000.sapbydesign.com"
        }
        return error;
    };

    const validateOdataUsername = (value) => {
        let error;
        if (!value) {
            error = "Field is required";
        }
        else if (value.trim().length === 0) {
            error = "Field is required"
        }
        return error;
    }
    const validateOdataPassword = (value) => {
        let error;
        if (!value) {
            error = "Field is required";
        }
        else if (value.trim().length === 0) {
            error = "Field is required"
        }
        return error;
    }
    const validateSoapUsername = (value) => {
        let error;
        if (!value) {
            error = "Field is required";
        }
        else if (value.trim().length === 0) {
            error = "Field is required"
        }
        return error;
    }
    const validateSoapPassword = (value) => {
        let error;
        if (!value) {
            error = "Field is required";
        }
        else if (value.trim().length === 0) {
            error = "Field is required"
        }
        return error;
    }

    const resetFormValues = (resetForm) => {
        resetForm();
    };

    const handleFormSubmit = (values) => {
        const { showSAPPassword, showSOAPPassword, ...RequiredValues } = values;

        if ((values.PublishableKey === "" && values.SecretKey === "") ||
            (values.PublishableKey !== "" && values.SecretKey !== "")) {
            contextVariable.setShow("SAPAndStripeConfirm");
            if (values.PublishableKey === "" && values.SecretKey === "") {
                const { PublishableKey, SecretKey, ...SAPDetailsOnly } = RequiredValues;
                setSubmitedValues(SAPDetailsOnly);
            }
            else {
                setSubmitedValues(RequiredValues);
            }

        }
        else {
            showToast(t(`Stripe details are incomplete. Please complete all fields.`), "error");
        }
    };

    //For Hard back button click
    useEffect(() => {
        if (sessionStorage.getItem("TapPageNumber") !== "1") {
            const currentURL = window.location.href;
            if (currentURL.includes("/customerportalsetup/SAPandStripeDetails")) {
                contextVariable.setTapPage(1);
                sessionStorage.setItem("TapPagNumber", 1);
            }
        }
        // eslint-disable-next-line 
    }, [])


    return (
        <Box className="admin-box-container">
            {contextVariable.pageInfo === "SapCredentialsInForm" && <InfoBar header="SAP Credentials"></InfoBar>}
            {contextVariable.pageInfo === "StripeDetailsInForm" && <InfoBar header="Stripe Details"></InfoBar>}
            <div style={{ height: '80vh', overflowY: "scroll" }}  >

                {contextVariable.OrganizationDetails !== "Loading" &&
                    <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
                        {({ values, errors, handleChange, handleSubmit, touched, resetForm, setFieldValue }) => (
                            <>
                                {/* SAP Credentials */}
                                <div className='flex justify-content-start align-items-center'>
                                    <div>
                                        <span className='text-base font-semibold gradient-badge pb-1'>SAP Credentials
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="More info"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                <span>
                                                    <IconButton size="small" onClick={() => contextVariable.setPageInfo("SapCredentialsInForm")} >
                                                        <InfoRounded className='text-base text-gray-400' />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    {contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" && <div>
                                        {/* <EditNoteOutlined onClick={() => contextVariable.setShow("SAPCredentialsEdit")} className='text-2xl ml-2  cursor-pointer hover:bg-gray-50 border-round-md' /></>} */}
                                        <Button className='p-button-sm' onClick={() => contextVariable.setShow("SAPCredentialsEdit")}>
                                            <EditOutlined fontSize='small' />
                                        </Button>
                                    </div>
                                    }
                                </div>

                                <Divider className='mt-1 mb-3'></Divider>
                                <div className='formgrid grid  m-1 mt-2 '>

                                    <div className='field col-4 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="SAP URL"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.SAPHostName && errors.SAPHostName) ? errors.SAPHostName : contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? "" : "For E.g., https://my000000.sapbydesign.com"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SAPHostName && Boolean(errors.SAPHostName)}
                                                name="SAPHostName"
                                                value={values.SAPHostName}
                                                InputProps={{ style: { color: '#0000FF' } }}
                                                validate={validateURL}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className='field col '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="SAP OData User Name*"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.OdataUsername && errors.OdataUsername)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.OdataUsername && Boolean(errors.OdataUsername)}
                                                name="OdataUsername"
                                                value={values.OdataUsername}
                                                validate={validateOdataUsername}

                                            />
                                        </FormControl>
                                    </div>
                                    <div className='field col '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="SAP OData Password*"
                                                type={values.showSAPPassword ? 'text' : 'password'}
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.OdataPassword && errors.OdataPassword)}
                                                size="small"
                                                onChange={handleChange}
                                                error={touched.OdataPassword && Boolean(errors.OdataPassword)}
                                                name="OdataPassword"
                                                value={values.OdataPassword.trim()}
                                                validate={validateOdataPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setFieldValue('showSAPPassword', !values.showSAPPassword)}
                                                                edge="end"
                                                            >
                                                                {values.showSAPPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>

                                </div>

                                <div className='formgrid grid  m-1'>
                                    <div className='field col-4 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="SOAP User Name*"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.SoapUsername && errors.SoapUsername)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SoapUsername && Boolean(errors.SoapUsername)}
                                                name="SoapUsername" value={values.SoapUsername} validate={validateSoapUsername}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className='field col-4 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="SOAP Password*"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.SoapPassword && errors.SoapPassword)}
                                                size="small"
                                                type={values.showSOAPPassword ? 'text' : 'password'}
                                                onChange={handleChange}
                                                error={touched.SoapPassword && Boolean(errors.SoapPassword)}
                                                name="SoapPassword" value={values.SoapPassword} validate={validateSoapPassword}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setFieldValue('showSOAPPassword', !values.showSOAPPassword)}
                                                                edge="end"
                                                            >
                                                                {values.showSOAPPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                                {/* Stripe details  */}
                                <div className='flex justify-content-start align-items-center'>
                                    <div>
                                        <span className='text-base font-semibold gradient-badge'>Stripe Details
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="More info"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                <span>
                                                    <IconButton size="small" onClick={() => contextVariable.setPageInfo("StripeDetailsInForm")} >
                                                        <InfoRounded className='text-base text-gray-400' />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    {
                                        contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" && <div>
                                            {/* <EditNoteOutlined onClick={() => contextVariable.setShow("StripeDetailsEdit")} className='text-2xl ml-2 cursor-pointer hover:bg-gray-50 border-round-md ' /></>} */}
                                            <Button className='p-button-sm' onClick={() => contextVariable.setShow("StripeDetailsEdit")}>
                                                <EditOutlined fontSize='small' />
                                            </Button>
                                        </div>
                                    }
                                </div>


                                <Divider className='mt-1 mb-3'></Divider>

                                <div className=' grid  m-1  mt-2'>
                                    <div className='col-4'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Publishable Key"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.PublishableKey && errors.PublishableKey)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.PublishableKey && Boolean(errors.PublishableKey)}
                                                name="PublishableKey" value={values.PublishableKey}
                                            //  validate={validateBankID}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className='field col-4'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Secret Key"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.SecretKey && errors.SecretKey)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SecretKey && Boolean(errors.SecretKey)}
                                                name="SecretKey" value={values.SecretKey}
                                            // validate={validateBankName}
                                            />
                                        </FormControl>
                                    </div>

                                    {/* <div className='field col'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Endpoint Secret"
                                                disabled={contextVariable.OrganizationDetails.SAPCredentialIndicator === "true" ? true : false}
                                                helperText={(touched.EndpointSecret && errors.EndpointSecret)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.EndpointSecret && Boolean(errors.EndpointSecret)}
                                                name="EndpointSecret"
                                                value={values.EndpointSecret}
                                            // validate={validatePaymentgatewayURL}
                                            />
                                        </FormControl>
                                    </div> */}
                                </div>

                                {contextVariable.OrganizationDetails.SAPCredentialIndicator === "false" &&
                                    <div className='flex justify-content-end gap-2 p-1'>

                                        <div>
                                            <Button outlined className='secondary-outlined-button block w-6rem '
                                                onClick={() => resetFormValues(resetForm)}>
                                                <ClearOutlined className='pb-1'></ClearOutlined>
                                                Clear
                                            </Button>
                                        </div>

                                        <div>
                                            <Button onClick={handleSubmit} type="submit" className='p-ripple text-sm block w-6rem'>
                                                <SaveOutlined className='pb-1'></SaveOutlined>
                                                <span >{" "} Save</span>
                                            </Button>
                                        </div>

                                    </div>}

                            </>)}
                    </Formik>}
            </div>

            {
                contextVariable.show === "SAPAndStripeConfirm" && (
                    <AdminDialogComponent
                        header={t(`Confirmation`)}
                        title={t(`Are you sure you want to save this?`)}
                        // Note="Note : After saving this details, you won't have option to make any changes."
                        ConfirmValues={SubmitedValues}
                    // signout={props.SīgnOut}
                    />
                )
            }
            {
                contextVariable.show === "SAPCredentialsEdit" && (
                    <AdminDialogComponent
                        header={t(`Edit SAP Credentials`)}
                        title=""
                        Note=""
                    />
                )
            }

            {
                contextVariable.show === "StripeDetailsEdit" && (
                    <AdminDialogComponent header={t(`Edit Stripe Details`)} title="" Note="" />
                )
            }

        </Box>
    )
}
