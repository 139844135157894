import React, { useState, useEffect, useRef, useContext, Suspense } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Tabs from "@mui/material/Tabs";
import "./ProductCatalog.css";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { AppContext } from "../../Layout/Layout";
import CatalogDataView from "./CatalogDataView";
import FavouriteDataView from "./FavouriteDataView";
import NoFavourites from "./Images/NoFavourites.png";
import EmptyProduct from "./Images/EmptyProduct.png"
// import ProductDetails from "./PlaceOrder/ProductDetails";
// import { useFade } from "../../components/FadeContext/FadeContext";
import CatalogCard from "../../components/cards/CatalogCard";
// import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
// import CardTopbar from "../../components/cards/CardTopbar";
import { Badge } from "primereact/badge";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../components/cards/dataNotFound/DataNotFound";
import { ScrollTop } from "primereact/scrolltop";
// import { showToast } from "../../components/ToastUtils";
// import { Button } from "primereact/button";
// import { DataScroller } from 'primereact/datascroller';

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const ProductCatalog = (props) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const dataViewRef = useRef(null);
  // const cardDataViewRef = useRef(null);
  //UseState declaration part
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [favouriteFilteredValue, setFavouriteFilteredValue] = useState([]);
  const countArray = [];
  // const [goToTop, setGoToTop] = useState(0);
  const [ProductList, setProductList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = 1;
  // const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [favProductsList, setfavProductsList] = useState([])
  const itemsPerPage = 60;

  const calculateFirstRow = () => {
    return (currentPage - 1) * itemsPerPage;
  };
  //UseRef declation part
  const divRef = useRef(null);

  //set the Top bar Title
  sessionStorage.setItem("title", t("Product Catalog"));

  if (countArray.length !== 0) {
    props.getItemDetails(countArray);
  }

  const fetchfavourites = async (getData) => {
    getData.forEach(item => {
      item.ProductQuantity = 1
    })
    if (selectedTab === 0) {
      setProductList(getData);
    }
    else if (selectedTab === 1) {

      setfavProductsList(getData);
    }
    else if (selectedTab === 2) {
      setProductList(getData);
    }
    setIsLoading(false);


  };

  useEffect(() => {
    if (selectedTab === 0) {
      if (contextVariable.ProductListDetails.length > 0 && contextVariable.ProductListDetails !== "NoProducts") {
        fetchfavourites(contextVariable.ProductListDetails);
      }
    }
    else if (selectedTab === 1) {
      if (contextVariable.AllFavDetailsWithTenent.length > 0) {
        setIsLoading(true);
        fetchfavourites(contextVariable.FavouriteProductDetails);
      }
    }
    else if (selectedTab === 2) {
      if (contextVariable.TrendingProductsList.length > 0 && contextVariable.TrendingProductsList !== "NoProducts") {
        setIsLoading(true);
        fetchfavourites(contextVariable.TrendingProductsList);
      }
    }
    if (contextVariable.ProductListDetails === "NoProducts") {
      setIsLoading(false);
    }

    //eslint-disable-next-line
  }, [contextVariable.ProductListDetails,
  contextVariable.TrendingProductsList, contextVariable.FavIds,
  contextVariable.FavouriteProductDetails, contextVariable.AllFavDetailsWithTenent, selectedTab]);

  //UseEffect for using Dashboard Favourite Link
  useEffect(() => {
    if (contextVariable.favouritesTab === 1) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [contextVariable.favouritesTab]);

  // Handling back navigation for mobile
  const location = useLocation();
  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname])



  //handle Change for Tab view
  const handleTabChange = async (event, newValue) => {
    if (newValue === 0) {
      setFavouriteFilteredValue([]);
      setNoFilterItems(false);
      setGlobalFilterValue(contextVariable.OpenSearchInputText);
    }
    else if (newValue === 1) {
      setGlobalFilterValue("");
      setFavouriteFilteredValue([]);
      setNoFilterItems(false);
    }
    else if (newValue === 2) {

      setGlobalFilterValue("");
      setFavouriteFilteredValue([]);
      setNoFilterItems(false);
      await contextVariable.TrendingProductDetails();

    }
    setSelectedTab(newValue);
  };

  const [NoFilterItems, setNoFilterItems] = useState(false);
  const [TrendingProductFilterValue, setTrendingProductFilterValue] = useState([]);


  const [typingTimeout, setTypingTimeout] = useState(null);
  const onStopTyping = async (value) => {
    // if (value.trim().length >= 3) {
    await contextVariable.OpenSearchProductsDetails((value || "ListByProduct"), 0);
    // }

  };
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  //Filter Section (Search bar filter)
  const onFilter = async (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    if (selectedTab === 0) {
      setIsLoading(true);
      contextVariable.setOpenSearchInputText(value);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(setTimeout(() => {
        onStopTyping(value);
      }, 500));

    }
    else
      if (selectedTab === 1) {

        const filtered = favProductsList.filter((product) => {
          //   const searchValue = value.toLowerCase().trim();

          //   // If the search value contains a ':' (colon)
          //   if (searchValue.includes(':')) {
          //     const [category, name] = searchValue.split(':').map((str) => str.trim());
          //     const categoryMatch = product.category.toLowerCase().includes(category);
          //     const nameMatch = name ? product.name.toLowerCase().includes(name) : true;
          //     return categoryMatch && nameMatch;
          //   }

          //   // If the search value doesn't contain a ':', search in both name and category
          //   const nameOrCategoryMatch =
          //     product.name.toLowerCase().includes(searchValue) ||
          //     product.category.toLowerCase().includes(searchValue);
          //   return nameOrCategoryMatch;
          // });
          const nameMatch = product.ProductName.toLowerCase().includes(value.toLowerCase());
          const categoryMatch = product.ProductCategoryName.toLowerCase().includes(value.toLowerCase());
          const IdMatch = product.ProductId.toLowerCase().includes(value.toLowerCase());
          return nameMatch || categoryMatch || IdMatch; // Return true if either name or category matches the given value
        });
        setFavouriteFilteredValue(filtered);
        if (filtered.length === 0) {
          setNoFilterItems(true);
        }
        else {
          setNoFilterItems(false);
        }
      }
      else
        if (selectedTab === 2) {

          const filtered = contextVariable.TrendingProductsList.filter((product) => {
            const nameMatch = product.ProductName.toLowerCase().includes(value.toLowerCase());
            const categoryMatch = product.ProductCategoryName.toLowerCase().includes(value.toLowerCase());
            const IdMatch = product.ProductId.toLowerCase().includes(value.toLowerCase());
            return nameMatch || categoryMatch || IdMatch; // Return true if either name or category matches the given value
          });
          setTrendingProductFilterValue(filtered);
          if (filtered.length === 0) {
            setNoFilterItems(true);
          }
          else {
            setNoFilterItems(false);
          }

        }
  };


  const skeletonContainers = Array.from({ length: 6 }, (_, index) => (
    <div className="border-1 border-round-xl p-2 w-50" key={index}>
      <div className=" flex flex-column ">
        <Skeleton animation="wave" className="border-round-xl w-100" variant="rectangular" height={110} />
        <Skeleton animation="wave" className="border-round-sm w-100 -mt-1" height={35} />
        <Skeleton animation="wave" className="border-round-sm w-100 -mt-2" height={35} />
        <Skeleton animation="wave" className="border-round-sm w-50 -mt-2" height={30} />
        <Skeleton animation="wave" className="border-round-3xl w-75 -mt-1 mx-auto" height={55} />
      </div>
    </div>
  ));

  const [TouchValue, setTouchValue] = useState(null);


  const handleTouchStart = (e) => {
    // console.log("e.button", e.touches[0].clientX)
    setTouchValue(e.touches[0].clientX)

  };

  const handleTouchMove = (e) => {

    // console.log("dragStartX",dragStartX)
    const TouchSwipeDistance = 90;

    if (selectedTab === 0) {
      // console.log("e.clientX", e.touches[0].clientX < (MouseClickValue - TouchSwipeDistance))
      if (e.touches[0].clientX < (TouchValue - TouchSwipeDistance)) {
        setSelectedTab(1);
        setTouchValue(null);
      }
    }
    else if (selectedTab === 1) {
      if (e.touches[0].clientX < (TouchValue - TouchSwipeDistance)) {
        setSelectedTab(2);
        setTouchValue(null);
      }
      else
        if (e.touches[0].clientX > (TouchValue + TouchSwipeDistance)) {
          setSelectedTab(0);
          setTouchValue(null);
        }
    }
    else if (selectedTab === 2) {
      if (e.touches[0].clientX > (TouchValue + TouchSwipeDistance)) {
        setSelectedTab(1);
        setTouchValue(null);
      }
    }



  };

  const handleTouchEnd = (e) => {
    setTouchValue(null);
  };

  const [MouseClickValue, setMouseClickValue] = useState(null);
  const handleMouseDown = (e) => {
    // console.log("y axix",e.clientY)
    setMouseClickValue(e.clientX)

  };

  const handleMouseMove = (e) => {

    // console.log("dragStartX",dragStartX)
    const TouchSwipeDistance = 90;

    if (selectedTab === 0 && MouseClickValue !== null) {
      // console.log("e.clientX", e.touches[0].clientX < (MouseClickValue - TouchSwipeDistance))
      if (e.clientX < (MouseClickValue - TouchSwipeDistance)) {
        setSelectedTab(1);
        setMouseClickValue(null);
      }
    }
    else if (selectedTab === 1 && MouseClickValue !== null) {
      if (e.clientX < (MouseClickValue - TouchSwipeDistance)) {
        setSelectedTab(2);
        setMouseClickValue(null);
      }
      else
        if (e.clientX > (MouseClickValue + TouchSwipeDistance)) {
          setSelectedTab(0);
          setMouseClickValue(null);
        }
    }
    else if (selectedTab === 2 && MouseClickValue !== null) {

      if (e.clientX > (MouseClickValue + TouchSwipeDistance)) {
        setSelectedTab(1);
        setMouseClickValue(null);
      }
    }



  };

  const handleMouseUp = () => {
    setMouseClickValue(null);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey && (event.key === 's' || event.key === 'S')) {
        inputRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);



  // useEffect(() => {
  //   if (Object.keys(contextVariable.taxDetails).length > 0) {
  //     const intervalId = setInterval(() => {
  //       contextVariable.FetchInventoryDetails();
  //     }, 300000);

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }
  //   //eslint-disable-next-line
  // }, [contextVariable.taxDetails]);

  // overlay panel for mobile
  const [overlayVisible, setOverlayVisible] = useState(false);
  // const mobileInputRef = useRef(null);

  const handleFocus = () => {
    setOverlayVisible(true);
  };

  const handleBlur = () => {
    setOverlayVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOverlayVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    // <Box className={`box-container mt-6 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
    <Box>
      {/* HEADER */}
      {
        !contextVariable.isMobile && !contextVariable.showProductDetails.show &&
        <div className="flex flex-column justify-content-center align-items-center sm:flex-row sm:justify-content-between">
          <div>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              {/* CatalogDataView */}
              <Tab
                label={<span className="font-bold" style={{ fontSize: "13px", whiteSpace: 'nowrap' }}>
                  {t("All Products")}
                </span>}
                className="sidebar-color"
                sx={{
                  "&.Mui-selected": {
                    color: "var(--sidebar-color)", // Set your desired font color for the selected tab
                  },
                }}
              />
              {/* FavouriteDataView */}
              <Tab
                label={(contextVariable.AllFavDetailsWithTenent.length === 0 || contextVariable.AllFavDetailsWithTenent === "Loading") ? (
                  <Typography fontWeight="bold">{t('favourites')}</Typography>
                ) : (
                  <Typography fontWeight="bold">
                    {t('Favourites')}({contextVariable.AllFavDetailsWithTenent.length})
                  </Typography>
                )}
                className="sidebar-color"
                sx={{
                  "&.Mui-selected": {
                    color: "var(--sidebar-color)", // Set your desired font color for the selected tab
                  },
                }}
              />
            </Tabs>
          </div>

          {/* Search Bar Div  */}
          <div
            className="flex justify-content-center w-20rem md:w-6 mt-2">
            <span className="p-input-icon-left flex justify-content-center align-items-start" style={{ width: "75%" }}>
              <div className="p-inputgroup flex-1">
                <InputText
                  ref={inputRef}
                  type="search"
                  className="p-inputtext-sm"
                  value={globalFilterValue}
                  onChange={(e) => onFilter(e)}
                  onPaste={(e) => onFilter(e)}
                  // onKeyDown={onFilterForOpenSearch}
                  placeholder={[t("Product Name"), t("Product Category")]}
                  style={{ width: "100%", textAlign: "center" }}
                  autoFocus
                />
                <Button
                  // onClick={() => onFilterForOpenSearch({ key: "Enter" })}
                  icon="pi pi-search" className="p-button-warning cursor-auto" />
              </div>
            </span>
          </div>

          <div className="mt-2 flex p-2">
            {
              contextVariable.taxDetails.Tax === "InclusiveTax" ?
                <>
                  <span className="text-red-700 text-xs font-semibold">
                    *{t('Inclusive of all taxes')}
                  </span>
                </>
                :
                <>
                  <span className="text-red-700 text-xs font-semibold">
                    *{t('Exclusive of all taxes')}
                  </span>
                </>
            }
          </div>
        </div>
      }

      {
        !contextVariable.isMobile &&
        <Box>
          <div>
            <div className="col-12">
              <div className="card mt-2" style={{ border: "1px solid white" }}>

                <TabPanel value={selectedTab} index={0} >
                  <div style={{ height: "82vh", overflowY: "auto" }}>
                    {/* {goToTop >= 300 && (
                      <div className="fixed-icon-button-container">
                        <IconButton
                          className="circle-border"
                          onClick={handleGoToTop}
                        >
                          <KeyboardArrowUpOutlined />
                        </IconButton>
                      </div>
                    )} */}

                    {isLoading && <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80.5vh",
                      }}
                    >
                      <Loader />
                    </div>}

                    {!isLoading && contextVariable.ProductListDetails === "NoProducts" && <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "80vh" }}>
                      <img
                        src={EmptyProduct}
                        style={{ maxWidth: "100%", height: "auto", maxHeight: "70vh", marginBottom: "-50px" }}
                        alt="No Products"
                      />
                      <label className="font-normal text-sm md:text-base">
                        No Products Found
                      </label>
                    </div>}

                    {!(contextVariable.isMobile) && !isLoading && contextVariable.ProductListDetails !== "NoProducts" &&
                      <>

                        <CatalogDataView
                          ProductList={ProductList}
                          // filteredValue={filteredValue}
                          dataViewRef={dataViewRef}
                          calculateFirstRow={calculateFirstRow}
                        // favouriteProducts={favouriteProducts}
                        >
                        </CatalogDataView>
                      </>
                    }

                    <ScrollTop target="parent" threshold={80} style={{ backgroundColor: 'var(--sidebar-color)' }} className="w-2rem h-2rem border-circle scroll-top-right"
                      icon="pi pi-arrow-up text-base" />

                  </div>
                </TabPanel>

                {/* Favourites Tab Panel */}
                <TabPanel value={selectedTab} index={1}>
                  {
                    (contextVariable.AllFavDetailsWithTenent.length > 0 && !NoFilterItems) &&
                    <div ref={divRef} style={{ height: "82vh", overflowY: "auto" }}>

                      {isLoading &&
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80.5vh", // Optional: Set the height of the container to center vertically
                          }}
                        >
                          <Loader />
                        </div>}

                      {!isLoading &&
                        <FavouriteDataView
                          ProductList={favProductsList}
                          filteredValue={favouriteFilteredValue}
                          // dataViewRef={dataViewRef}
                          calculateFirstRow={calculateFirstRow}
                        ></FavouriteDataView>}
                    </div>
                  }

                  {(contextVariable.AllFavDetailsWithTenent.length === 0 || NoFilterItems) && <div ref={divRef}
                  >
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "50vh" }}>
                      <Suspense fallback={<Loader />}>
                        <img
                          src={NoFavourites}
                          style={{ maxWidth: "100%", height: "auto", maxHeight: "70vh", marginBottom: "-50px" }}
                          alt="No Favourites"
                        ></img>
                      </Suspense>
                      <label className="font-semibold text-sm md:text-base">
                        No favourite products found.
                      </label>
                    </div>
                  </div>}
                </TabPanel>
              </div>
            </div>
          </div>
        </Box>
      }



      {/* Mobile Tab veiw */}
      {
        contextVariable.isMobile && !contextVariable.showProductDetails.show &&

        <div className="sticky top-0 z-1">
          {/* <CardTopbar header="Catalog" /> */}

          <div className="overflow-auto ">
            <div className="border-bottom-2 p-2 shadow-2" style={{ backgroundColor: "var(--sidebar-color)" }}>
              <div className="flex  gap-2 ">
                <div className=" flex align-items-baseline w-100 border-round-lg ">
                  <div className="flex-1" style={{ height: "3rem" }}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="tabs"
                      // sx={{
                      //   padding: "8px"
                      // }}
                      textColor="primary"
                      indicatorColor="primary"
                    >
                      {/* CatalogDataView */}
                      <Tab
                        label={<span className={selectedTab !== 0 ? `text-white font-semibold` : `font-semibold`}
                          style={{ padding: "1px", fontSize: "14px", letterSpacing: 1, textTransform: "capitalize" }}>
                          ALL
                        </span>}
                      />

                      {/* FavouriteDataView */}
                      <Tab
                        label={contextVariable.AllFavDetailsWithTenent.length === 0 ? (
                          <Typography style={{ letterSpacing: 1 }} className={selectedTab !== 1 ? `text-white font-semibold` : `font-semibold`}>{t('favourites')}</Typography>
                        ) : (
                          <Typography style={{ letterSpacing: 1 }} className={selectedTab !== 1 ? `text-white font-semibold` : `font-semibold`}>
                            {t('favourites')}{" "}
                            <Badge value={contextVariable.AllFavDetailsWithTenent.length} ></Badge>
                          </Typography>
                        )}
                        className="sidebar-color"
                      />

                      {/* Trending */}
                      <Tab
                        label={<Typography style={{ letterSpacing: 1 }}
                          className={selectedTab !== 2 ? `text-white font-semibold` : `font-semibold`}>Trending</Typography>}
                        className="sidebar-color"
                      />
                    </Tabs>
                  </div>
                  {/* <div className="pr-3">
                    <ShoppingCart color="primary" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      }


      {/* Mobile Tabpanel view */}
      {
        contextVariable.isMobile &&
        <>
          <div className="w-full bg-white">

            <div className="p-inputgroup pb-2 flex"
              style={{ position: "fixed", zIndex: "25", marginTop: "-0.2rem" }} >
              <InputText
                type="search"
                className="p-inputtext-sm z-0"
                value={globalFilterValue}
                onChange={onFilter}
                onPaste={onFilter}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={`${t("Product Name")} / ${t("Product Category")}`}
                style={{ width: "100%", textAlign: "center" }}
                ref={inputRef}
              />
              <Button
                // onClick={() => onFilterForOpenSearch({ key: "Enter" })}
                icon="pi pi-search" className="p-button-warning"
              />
              {overlayVisible && (
                <div id="popup-overlay"></div>
              )}
              <style jsx>{`
                .overlay {
                    position: absolute;
                    top: ${inputRef.current ? inputRef.current.getBoundingClientRect().bottom + 'px' : '0'};
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5); /* Adjust as needed */
                    z-index: 20;
                    height:"100vh";
                }
            `}</style>
            </div>

            {/* all product Tab panel */}
            <TabPanel value={selectedTab} index={0}>
              <div ref={divRef} style={{ height: "82vh", overflowY: "auto" }}
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
              >

                {isLoading && <div className="p-1 flex  flex-wrap ">
                  {skeletonContainers}
                </div>}

                {
                  (contextVariable.isMobile && !isLoading && contextVariable.ProductListDetails !== "NoProducts") &&
                  <div className="mt-6">
                    <CatalogCard
                      productList={ProductList}
                      selectedTab={selectedTab}
                    />
                  </div>
                }

                {(!isLoading && contextVariable.ProductListDetails === "NoProducts" && !contextVariable.ErrorDetails.ProductDetails) &&
                  <div>

                    <div className='flex flex-column justify-content-center' style={{ height: "70vh" }} >
                      <div className="flex justify-content-center">
                        <img style={{ height: "40vh" }} src={EmptyProduct} alt="No Products" />

                      </div>
                      <span className="flex justify-content-center font-semibold text-sm">  No Products Found.</span>
                    </div>
                  </div>
                }
                {(!isLoading && contextVariable.ProductListDetails === "NoProducts" && contextVariable.ErrorDetails.ProductDetails) &&
                  <div>
                    <DataNotFound></DataNotFound>
                  </div>
                }
              </div>
            </TabPanel>

            {/* Favourites Tab Panel */}
            <TabPanel value={selectedTab} index={1} >
              <div
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
              >
                {
                  (contextVariable.AllFavDetailsWithTenent.length > 0 && !NoFilterItems) &&
                  <div ref={divRef} style={{ height: "82vh", overflowY: "auto" }}>

                    {isLoading && <div className="p-1 flex  flex-wrap ">
                      {skeletonContainers}
                    </div>}

                    {
                      (contextVariable.isMobile && !isLoading) &&
                      <div className="mt-6">
                        <CatalogCard
                          productList={favProductsList}
                          selectedTab={selectedTab}
                          filteredValue={favouriteFilteredValue}
                        />
                      </div>
                    }
                  </div>
                }
                {((contextVariable.AllFavDetailsWithTenent.length === 0 || NoFilterItems) && !contextVariable.ErrorDetails.FavDetails) &&
                  <div ref={divRef}>

                    <div className='flex flex-column justify-content-center' style={{ height: "70vh" }}>
                      <div className="flex justify-content-center">
                        <img style={{ height: "40vh" }} src={NoFavourites} alt="No Products" />

                      </div>
                      <span className="flex justify-content-center font-semibold text-sm">     No Favourite Products Found.</span>
                    </div>
                  </div>
                }
                {((contextVariable.AllFavDetailsWithTenent.length === 0 || NoFilterItems) && contextVariable.ErrorDetails.FavDetails) &&
                  <div>
                    <DataNotFound />
                  </div>
                }
              </div>
            </TabPanel>

            {/* Trending Tab panel  */}
            <TabPanel value={selectedTab} index={2} >
              <div ref={divRef} style={{ height: "82vh", overflowY: "auto" }}
                onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}
              >

                {isLoading && <div className="p-1 flex  flex-wrap ">
                  {skeletonContainers}
                </div>}

                {
                  (contextVariable.isMobile && !isLoading && contextVariable.TrendingProductsList.length > 0 && !NoFilterItems) &&
                  <div className="mt-6">
                    <CatalogCard
                      productList={ProductList}
                      selectedTab={selectedTab}
                      filteredValue={TrendingProductFilterValue}
                    />
                  </div>
                }

                {((contextVariable.TrendingProductsList.length === 0 || NoFilterItems) && !contextVariable.ErrorDetails.TrendingDetails) &&
                  <div>

                    <div className='flex flex-column justify-content-center' style={{ height: "70vh" }}>
                      <div className="flex justify-content-center">
                        <img style={{ height: "40vh" }} src={EmptyProduct} alt="No Products" />

                      </div>
                      <span className="flex justify-content-center font-semibold text-sm">  No Products Found.</span>
                    </div>
                  </div>
                }
                {((contextVariable.TrendingProductsList.length === 0 || NoFilterItems) && contextVariable.ErrorDetails.TrendingDetails) &&
                  <div>
                    <DataNotFound />
                  </div>
                }
              </div>
            </TabPanel>


          </div>
        </>
      }

    </Box>
  );
};

export default ProductCatalog;
