import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';

function NotificationComponent({ Notify }) {
    // console.log("Notify1111",Notify);
    // const [dialogOpen, setDialogOpen] = useState(false);
    // const [productData, setProductData] = useState([]);
    const [csvdata, setCsvdata] = useState(null);

    // Function to decode Base64
    function decodeBase64(base64String) {
        try {
            return decodeURIComponent(atob(base64String).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        } catch (error) {
            console.error('Error decoding Base64:', error);
            return null;
        }
    }

    // Extract and decode messageText and createdBy
    useEffect(() => {
        if (Notify.NotificationData) {
            try {
                const notificationDataArray = JSON.parse(Notify.NotificationData);
                const notificationData = notificationDataArray[0];

                // console.log("PriceOption", notificationData);

                const decodedMessageText = decodeBase64(notificationData.baseText);
                setCsvdata(decodedMessageText);

                // For showing failed details in Dialog
                // const stringText = (notificationData.failedProductId);
                // // Split the messageText by comma
                // console.log("stringText", stringText);

                // let productArray = stringText.split(',').map(item => item.trim());
                // console.log("productArray", productArray);

                // // Handle the last item if it contains 'and'
                // const lastItem = productArray.pop();
                // if (lastItem.includes(' and ')) {
                //     console.log("first", productArray);
                //     const lastItems = lastItem.split(' and ').map(item => item.trim());
                //     productArray = productArray.concat(lastItems);
                // } else {
                //     productArray.push(lastItem);
                // }
                // console.log("productArray", productArray);
                // setProductData(productArray);
            } catch (error) {
                console.error('Error parsing NotificationData:', error);
            }
        }
    }, [Notify.NotificationData]);

    const handleDownload = () => {
        const decodedCsvContent = csvdata;
        const blob = new Blob([decodedCsvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Failed_Product_Details.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const handleClickOpen = () => {
    //     setDialogOpen(true);
    // };

    // const handleClose = () => {
    //     setDialogOpen(false);
    // };

    return (
        <div className="notification-component">
            <div className="text-xs">
                <Button
                    className='text-xs align-self-baseline'
                    size='small'
                    icon="pi pi-download"
                    label="Details"
                    onClick={handleDownload}
                    text />
            </div>
            {/* <ProductDialog
                open={dialogOpen}
                onClose={handleClose}
                csvData={csvdata}
                productData={productData} /> */}
        </div>
    );
}

export default NotificationComponent;
