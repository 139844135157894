import { useContext, useState } from "react";
import { Collapse } from "@mui/material";
import CurrencyFormat from "../../components/CurrencyFormat";
import { AppContext } from "../../Layout/Layout";
import { useTranslation } from "react-i18next";
// import Loader from "../../components/Loader";  
// import { FetchInvoiceWisePaymentDetails } from "../../components/ListDetailsFunction";

const PayInvoiceList = (props) => {
  const{t}=useTranslation();
  const { data } = props;
  const contextVariable = useContext(AppContext);
  const [amountToPay, setAmountToPay] = useState((parseFloat(data.OpenAmount)).toFixed(2));
  // const [expanded, setExpanded] = useState(false);
  // const [PaymentDetailsOfInvoice, setPaymentDetailsOfInvoice] = useState([]);

  const handleAmountToPayChange = (event) => {
    const enteredValue = event.target.value.trim();
    if (
      event.target.value === "" ||
      event.key === "Backspace" ||
      event.key === "Delete"
    ) {
      props.changeTotalAmountToPay(data.InvoiceID, 0);
      setAmountToPay("");
    }
    else {
      // const numericValue = enteredValue.replace(/[^0-9]/g, "");
      // const value = Number(numericValue);
      const newValue = Math.min(Math.max(enteredValue, 1).toFixed(2), (parseFloat(data.OpenAmount)).toFixed(2));
      setAmountToPay(newValue);
      props.changeTotalAmountToPay(data.InvoiceID, newValue);
    }

  }
  const handleInputBlur = () => {
    if (amountToPay < 1) {
      props.changeTotalAmountToPay(data.InvoiceID, 1);
      setAmountToPay(1);
    }
    // If input is left empty, set it to 1 on blur
    if (amountToPay === "") {
      const value = parseFloat(data.OpenAmount).toFixed(2);
      props.changeTotalAmountToPay(data.InvoiceID, value);
      setAmountToPay(value);
    }
    props.isInputFocused(false);
  };

  // const ExpandMore = styled((props) => {
  //   const { expand, ...other } = props;
  //   return <IconButton {...other} />;
  // })(({ theme, expand }) => ({
  //   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  //   marginLeft: 'auto',
  //   transition: theme.transitions.create('transform', {
  //     duration: theme.transitions.duration.shortest,
  //   }),
  // }));

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  //   if (CallOnceFetch) {
  //     setCallOnceFetch(false)
  //     FetchInvoiceWisePaymentDetails(data.InvoiceID, setPaymentDetailsOfInvoice);
  //   }

  // };

  const handleInputFocus = () => {
    props.isInputFocused(true);
  };

  return (
    <>
      {
        data &&
        <div
          className="mb-2 block w-full rounded-lg text-left">

          <div className="pl-2 pt-2 d-flex gap-2">
            <span className="text-xs md:text-base p-1 text-green-600 font-bold border-round font-sm">{data.InvoiceID}</span>
          </div>

          <div className="pl-2 pt-1 pr-1 flex gap-2 flex-column text-center md:flex-row md:text-left sm:flex-row sm:md:text-left sm:justify-content-around md:justify-content-around">

            <div>
              <span className="text-xs md:text-xs font-semibold text-gray-400">{t('Payment Status')}</span><br />
              <span className="text-xs md:text-sm font-semibold">{data.PaymentStatus}</span>
            </div>
            <div>
              <span className="text-xs md:text-xs font-semibold text-gray-400">{t('Due Date')}</span><br />
              <span className="text-xs md:text-sm font-semibold">{data.PaymentDueDate}</span>
            </div>
            <div>
              <span className="text-xs md:text-xs font-semibold text-gray-400">{t('Gross Amount')} ({contextVariable.taxDetails.CurrencyCode})</span><br />
              <span className="text-xs md:text-sm font-semibold">{data.GrossAmount}</span>
            </div>
            <div>
              <span className="text-xs md:text-xs font-semibold text-gray-400">{t('Open Amount')} ({contextVariable.taxDetails.CurrencyCode})</span><br />
              <span className="text-xs md:text-sm font-semibold">{data.OpenAmount}</span>
            </div>
            <div>
              <span className="text-xs md:text-xs font-semibold text-green-600">{t('Amount To Pay')}</span><br />
              <div
                className="mt-1 input-group mx-auto w-8 md:ml-0 md:mr-0">
                <input
                  name='amountToPay'
                  value={amountToPay}
                  onChange={handleAmountToPayChange}
                  onBlur={handleInputBlur}
                  onFocus={handleInputFocus}
                  type='number'
                  className="form-control form-control-sm po-reference"
                  aria-label="Amount (to the nearest dollar)"
                />
                <div className="input-group-append">
                  <span
                    style={{
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px'
                    }}
                    className="input-group-text text-xs">
                    {contextVariable.taxDetails.CurrencyCode}
                  </span>
                </div>
              </div>
              {/* <span className="small text-xs">Note : Minimum amount to pay is 1000 {contextVariable.taxDetails.CurrencyCode}</span> */}
            </div>

          </div>

          <div className="text-center">
            {
              data.PaymentStatus === "Partially Cleared" ?
                <>
                  {/* <span className="text-xs text-blue-500 font-semibold cursor-pointer" onClick={handleExpandClick}>Recent payments</span>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreOutlined fontSize="small" />
                  </ExpandMore> */}
                </>
                :
                <div className="mb-4"></div>
            }
          </div>
          <Collapse 
          // in={expanded} 
          timeout="auto" unmountOnExit>

            {/* <div className="d-flex"> */}
            {/* <div className="md:ml-auto"> */}
            <div className="flex justify-content-center md:justify-content-center gap-3 p-2 text-xs md:text-sm sm:pr-8">
              <div className="text-right">
                <div className="text-xs md:text-sm font-bold mb-1 text-green-600 gap-8">
                  <span>Gross Amount</span>
                  <span className="mr-8 ml-8">:</span>
                </div>
                {/* {
                  PaymentDetailsOfInvoice.map((p, index) => (
                    <div key={index} className="text-xs md:text-sm font-bold mb-1 gap-8">
                      <span>Paid on {p.DateOfTransaction}</span>
                      <span className="mr-8 ml-8">:</span>
                    </div>
                  ))
                } */}

                <div className="text-xs md:text-sm font-bold mb-1 text-green-600 gap-8">
                  <span>Open Amount</span>
                  <span className="mr-8 ml-8">:</span>
                </div>
              </div>
              <div>
                <div className="flex gap-3 justify-content-between align-items-baseline text-xs md:text-sm mb-1">
                  <span className="font-semibold flex-1 text-right">
                    {(data.GrossAmount && CurrencyFormat((parseFloat(data.GrossAmount)).toFixed(2)))}
                  </span>
                  <span>
                    {contextVariable.taxDetails.CurrencyCode}
                  </span>
                </div>
                {/* {
                  PaymentDetailsOfInvoice.map((p) => (
                    <div className="flex gap-3 justify-content-between align-items-baseline text-xs md:text-sm mb-1">
                      <span className="font-semibold flex-1 text-right">
                        {CurrencyFormat(parseFloat(p.Amount.replace(/,/g, '')))}
                      </span>
                      <span>
                        {contextVariable.taxDetails.CurrencyCode}
                      </span>
                    </div>
                  ))
                } */}
                <div className="flex gap-3 justify-content-between align-items-baseline text-xs md:text-sm mb-1">
                  <span className="font-semibold flex-1 text-right">
                    {(data.OpenAmount && CurrencyFormat((parseFloat(data.OpenAmount)).toFixed(2)))}
                  </span>
                  <span>
                    {contextVariable.taxDetails.CurrencyCode}
                  </span>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            {/* <div className="flex flex-column text-center">
              <div>
                <span className="font-semibold text-xs">Gross Amount : </span>
                <span className="text-sm">{(data.GrossAmount && CurrencyFormat((parseFloat(data.GrossAmount)).toFixed(2)))}</span>
              </div>
              <div>
                <span className="text-sm">{data.PaymentDueDate} : </span>
                <span className="text-sm">101 USD</span>
              </div>
              <div>
                <span className="text-sm">{data.PaymentDueDate} : </span>
                <span className="text-sm">101 USD</span>
              </div>
              <div style={{ borderTop: '1px solid lightgray' }} >
                <span className="font-semibold text-sm">Open Amount : </span>
                <span className="text-sm">{(data.OpenAmount && CurrencyFormat((parseFloat(data.OpenAmount)).toFixed(2)))}</span>
              </div>
            </div> */}
          </Collapse>
        </div>
      }

    </>
  )
}

export default PayInvoiceList;