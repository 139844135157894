import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Timer = ({ ShowResendOTP, onTimerComplete, callBackOtpResendFun }) => {
    const [seconds, setSeconds] = useState(120);
    const { t } = useTranslation();

    useEffect(() => {
        if (ShowResendOTP === false) {
            setSeconds(120);
        }
    }, [ShowResendOTP]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(prevSeconds => prevSeconds - 1);
            } else {
                clearInterval(intervalId);
                onTimerComplete();
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [seconds, onTimerComplete]);

    const handleResendOtp = (e) => {
        setSeconds(60);
        callBackOtpResendFun(e);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        return formattedTime;
    };

    return (
        <>
            {
                !ShowResendOTP && (
                    <>
                        <div className='flex justify-content-between align-items-center gap-2'>
                            <div>
                                <span className='text-xs text-gray-600'>{t(`OTP Expires in:`)}</span>
                            </div>
                            <div>
                                <span className='text-lg text-gray-600 font-semibold'>{formatTime(seconds)}</span>
                            </div>
                        </div>
                    </>
                )
            }
            {ShowResendOTP && (

                <div className='flex gap-3'>
                    <div>
                        <span className='text-xs bg-yellow-400 border-round px-3 py-1 font-bold'>{t('OTP Expired')} </span>
                    </div>
                    <button onClick={(e) => handleResendOtp(e)}
                        className='primary-color text-sm cursor-pointer font-semibold underline'
                    >
                        {t('Resend OTP')}
                    </button>
                </div>
            )}
        </>
    );
};

export default Timer;
