import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

const SessionExpired = () => {
    const [visible, setVisible] = useState(true);

    const footerContent = (
        <div>
            <Link to={"/"}>
                <Button label="Go to login" autoFocus />
            </Link>
        </div>
    );

    return (
        <>
            <Dialog className="text-base md:text-lg"
                header="Session Expired"
                visible={visible}
                onHide={() => setVisible(false)}
                footer={footerContent}
                style={{ width: '50vw' }}
                breakpoints={{ '960px': '75vw', '641px': '100vw', '320px': '100vw' }}>
                <p className="mt-3 text-base">
                    Please log in again to continue using the app.
                </p>
            </Dialog>
        </>
    )
}

export default SessionExpired;