import React, { useContext } from "react";
import { Box, Tooltip, Zoom } from "@mui/material";
import { useState, useEffect } from "react";
import "./Invoice.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import { Button } from "primereact/button";
import { NavigateBeforeOutlined, NavigateNextOutlined } from "@mui/icons-material";
import { AppContext } from "../../Layout/Layout";
import { API } from "aws-amplify";
import Loader from "../../components/Loader";
import GetOrderPDF from "../order/GetOrderPDF";
import { showToast } from "../../components/ToastUtils";
import { useFade } from "../../components/FadeContext/FadeContext";
import CurrencyFormat from "../../components/CurrencyFormat";
import TruncateText from "../../components/TruncateText";
import { GetByCustomerInvoiceDetail } from "../../graphql/queries";
import { useTranslation } from "react-i18next";

const InvoiceView = (props) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [GrossAmountIndicator, setGrossAmountIndicator] = useState("true");
  const [rowData, setRowData] = useState(1);
  const [generalDetails, setGeneralDetails] = useState({});
  const [netAndGrossAmount, setnetAndGrossAmount] = useState({
    netAmount: '',
    grossAmount: '',
    GACurrencyCode: '',
    NACurrencyCode: ''
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);


  // Net invoice type
  const columnDefsNetInvoiceType = [
    {
      field: "LineItem",
      headerName: t('Line Item'),  // Original headerName text wrapped with t()
      filter: true,
      minWidth: 90,
      maxWidth: 90,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value !== '') {
          return params.value;
        }
        else {
          return '-';
        }
      },
      comparator: (valueA, valueB) => {
        return parseInt(valueA, 10) - parseInt(valueB, 10);
      },
    },
    { field: "ProductID", headerName: t("Product ID"), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "ProductDescription", headerName: t("Description"), filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: 'ProductDescription' },
    {
      field: "Quantity", headerName: t("Qty"), filter: true, flex: 0.1, headerClass: 'custom-ag-header',
      maxWidth: 80,
      cellRenderer: (params) => {
        return ((parseFloat(params.value)).toFixed(2));
      }
    },
    { field: "QuantityUOMText", headerName: t("Qty UOM"), filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
    {
      field: "UnitPrice", headerName: `${t('Unit Price')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3,
      headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "PriceQuantity", headerName: t("Price Qty / UOM"), filter: true, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "Discount", headerName: `${t('Discount')} (%)`, filter: true, flex: 0.2, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value !== '') {
          return (Math.abs(parseFloat(params.data.Discount).toFixed(2)));
        } else {
          return '-';
        }
      }
    },
    {
      field: "NetPrice", headerName: `${t('Net Price')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3,
      headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    { field: "TaxRate", headerName: `${t('Tax Amount')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    {
      field: "NetValue", headerName: `${t("net Value")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true,
      flex: 0.5, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    { field: "POReference", headerName: t('PO Reference'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    // { field: "SalesOrderReference", headerName: "Sales Order Reference", filter: true, flex: 0.6, headerClass: 'custom-ag-header' },
  ];

  // Gross invoice type
  const columnDefsGrossInvoiceType = [
    {
      field: "LineItem",
      headerName: t('Line Item'),
      filter: true,
      minWidth: 90,
      maxWidth: 90,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value !== '') {
          return params.value;
        }
        else {
          return '-';
        }
      },
      comparator: (valueA, valueB) => {
        return parseInt(valueA, 10) - parseInt(valueB, 10);
      },
    },
    { field: "ProductID", headerName: t('Product ID'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "ProductDescription", headerName: t('Description'), filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: 'ProductDescription' },
    {
      field: "Quantity", headerName: t('Qty'), filter: true, flex: 0.1, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      maxWidth: 80,
      cellRenderer: (params) => {
        return ((parseFloat(params.value)).toFixed(0));
      }
    },
    { field: "QuantityUOMText", headerName: t('Qty UOM'), filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
    {
      field: "UnitPrice", headerName: `${t('Unit Price')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true,
      flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "PriceQuantity", headerName: t('Price Qty / UOM'), filter: true, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "Discount", headerName: `${t('Discount')} (%)`, filter: true, flex: 0.2, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value !== '') {
          return (Math.abs(parseFloat(params.data.Discount).toFixed(2)));
        } else {
          return '-';
        }
      }
    },
    {
      field: "NetPrice", headerName: `${t('Gross Price')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    { field: "TaxRate", headerName: `${t('Tax Amount')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    {
      field: "NetValue", headerName: `${t('Gross Value')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true,
      flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    { field: "POReference", headerName: t('PO Reference'), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    // { field: "SalesOrderReference", headerName: "Sales Order Reference", filter: true, flex: 0.6, headerClass: 'custom-ag-header' },
  ];


  const fetchInvoiceDetails = async () => {
    try {
      const response = await API.graphql({
        query: GetByCustomerInvoiceDetail,
        variables: {
          InvoiceID: contextVariable.rootObject.InvoiceID
        }
      });
      setGeneralDetails(response.data.GetByCustomerInvoiceDetail.items[0]);
      if (response.data.GetByCustomerInvoiceDetail.items[0].GrossAmountIndicator === 'false') {
        setGrossAmountIndicator("false");
      }
      else {
        setGrossAmountIndicator("true");
      }
      setRowData(response.data.GetByCustomerInvoiceDetail.items[0].CustomerInvoiceItem);
    }
    catch (error) {
      console.error("fetchInvoiceDetails Error", error);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchInvoiceDetails Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  }

  const fetchInvoiceDetails1 = async (invoiceId) => {
    setGeneralDetails(1);
    setRowData(1);
    try {
      const response = await API.graphql({
        query: GetByCustomerInvoiceDetail,
        variables: {
          InvoiceID: invoiceId
        }
      });
      setGeneralDetails(response.data.GetByCustomerInvoiceDetail.items[0]);
      if (response.data.GetByCustomerInvoiceDetail.items[0].GrossAmountIndicator === 'false') {
        setGrossAmountIndicator("false");
      }
      else {
        setGrossAmountIndicator("true");
      }
      setRowData(response.data.GetByCustomerInvoiceDetail.items[0].CustomerInvoiceItem);
    }
    catch (error) {
      console.error("fetchDeliveryItemDetails Error", error);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchDeliveryItemDetails Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  }

  const BeforeDeliveryItem = () => {
    if (currentIndex > 0) {
      contextVariable.setRootObject(contextVariable.filteredData[currentIndex - 1]);
      fetchInvoiceDetails1(contextVariable.filteredData[currentIndex - 1].InvoiceID);
      setCurrentIndex(currentIndex - 1)
    }
    else {
      showToast(t(`End of records`), 'info');
    }
  }

  const nextDeliveryItem = () => {
    if (currentIndex < contextVariable.filteredData.length - 1) {
      contextVariable.setRootObject(contextVariable.filteredData[currentIndex + 1]);
      fetchInvoiceDetails1(contextVariable.filteredData[currentIndex + 1].InvoiceID);
      setCurrentIndex(currentIndex + 1)
    }
    else {
      showToast(t(`End of records`), 'info');
    }
  }

  useEffect(() => {
    fetchInvoiceDetails();
    if (contextVariable.filteredData.length === 0) {
      const initialIndex = props.deliveryDetails.findIndex(item => item.InvoiceID === contextVariable.rootObject.InvoiceID);
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
    }
    else {
      const initialIndex = contextVariable.filteredData.findIndex(item => item.InvoiceID === contextVariable.rootObject.InvoiceID);
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contextVariable.rootObject) {
      // console.log("first", contextVariable.rootObject)
      const newNetAndGrossAmount = {
        netAmount: contextVariable.rootObject.NetAmount,
        grossAmount: contextVariable.rootObject.GrossAmount,
        GACurrencyCode: contextVariable.rootObject.GrossAmountCurrencyCode,
        NACurrencyCode: contextVariable.rootObject.NetAmountCurrencyCode
      };
      setnetAndGrossAmount(newNetAndGrossAmount);
    }
    //eslint-disable-next-line
  }, [rowData]);

  const goToInvoice = () => {
    props.backToInvoice();
  }

  const showPdf = () => {
    setLoading(true);
    GetOrderPDF("invoice", contextVariable.rootObject.InvoiceID, contextVariable.rootObject.InvoiceID)
      .then(() => {
        setLoading(false);
      });
  }

  const selectedRowsHandler = (rows) => {
  }

  const { fadeIn } = useFade();

  return (
    <>
      <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
        {sessionStorage.setItem("title", `Details of Invoice ID : ${contextVariable.rootObject.InvoiceID}`)}
        {/* HEADER */}
        <div className="d-flex justify-content-between">
          <div>
            <Button className="secondary-button p-button-sm  mb-2"
              onClick={goToInvoice} label="Back to invoice list" icon="pi pi-arrow-left">
            </Button>

            {
              (contextVariable.rootObject.InvoiceID).trim() !== '' ?
                <>
                  <Button
                    className="primary-button p-button-sm mb-2 ml-2"
                    loading={loading}
                    onClick={showPdf}
                    // icon={<PictureAsPdfOutlined />}
                    icon="pi pi-file-pdf"
                    label="View PDF"
                  >
                  </Button>
                </> : ''
            }
          </div>
        </div>

        {/* general Details */}
        <div className="headerDetails card-shadow bg-white">
          {
            rowData.length > 0 ?
              <>
                <div className="containers">
                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Invoice ID')} :</label>
                    <span>{generalDetails.InvoiceID}</span>
                  </div>
                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Customer Name')} :</label>
                    <span>{generalDetails.CustomerName}</span>
                  </div>
                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Reference Number')} :</label>
                    <span>{generalDetails.ExternalReference}</span>
                  </div>
                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Payment Terms')} :</label>
                    <span>{generalDetails.PaymentTermsText}</span>
                  </div>

                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Inco Terms')} :</label>
                    <span>{generalDetails.Incoterms}</span>
                  </div>

                  <div>
                    <label className="text-xs md:text-xs font-semibold text-gray-400">Address :</label>
                    <span>{generalDetails.CustomerAddress}</span>
                  </div>
                </div>

                <div className="flex justify-content-between align-items-center">
                  {rowData.length > 0 ? (
                    <>
                      <div className="flex flex-column justify-content-left align-items-left">
                        <div className="text-left">
                          <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Notes')} :</label>
                        </div>
                        <div>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            title={generalDetails.Notes}
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                          >
                            <span className="font-semibold text-left">
                              {
                                (generalDetails.Notes) &&
                                <TruncateText
                                  text={generalDetails.Notes}
                                  maxLength={70}
                                />
                              }
                              {
                                !(generalDetails.Notes) && '-'
                              }
                            </span>
                          </Tooltip>
                        </div>
                      </div>

                      <div className="flex justify-content-center align-items-center gap-2 mt-2 mb-2 md:mt-0 md:mb-0">

                        {/* (GrossAmountIndicator === "false" && netAndGrossAmount.netAmount) && */}
                        {
                          <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                            {t('Net Amount')} : {netAndGrossAmount.netAmount ? parseFloat(netAndGrossAmount.netAmount).toFixed(2) : '-'} {netAndGrossAmount.netAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                          </span>
                        }
                        {/* (GrossAmountIndicator === "true" && netAndGrossAmount.grossAmount) && */}
                        {
                          <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                            {t('Gross Amount')} : {netAndGrossAmount.grossAmount ? parseFloat(netAndGrossAmount.grossAmount).toFixed(2) : '-'} {netAndGrossAmount.grossAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                          </span>
                        }
                      </div>
                    </>
                  ) : ''
                  }

                </div>
              </>
              :
              <div
                style={{ height: '5vh' }}
                className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
          }
        </div>

        {/* DataGrid Card  */}
        <div className="mt-5">
          {/* <div> */}
          <GridData
            rowData={rowData}
            columnDefs={GrossAmountIndicator === "true" ? columnDefsGrossInvoiceType : columnDefsNetInvoiceType}
            tableHeight={"58vh"}
            needFilters={false}
            showPagination={false}
            selectedRows={selectedRowsHandler}
          />
          {/* </div> */}
        </div>

        <div className='d-flex justify-content-between gap-1 mt-3'>
          <button className='btn btn-sm' disabled={currentIndex === 0} style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={BeforeDeliveryItem}
          ><NavigateBeforeOutlined /> {t('Previous')}</button>
          <div>
            <span style={{ fontWeight: 600 }}>{currentIndex + 1} </span> {t('of')}
            <span style={{ fontWeight: 600 }}> {contextVariable.filteredData.length}</span>
          </div>
          <button className='btn btn-sm' disabled={(contextVariable.filteredData.length - 1) - currentIndex === 0} style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={nextDeliveryItem}
          >{t('Next')} <NavigateNextOutlined style={{ fontSize: '18px' }} /></button>
        </div>

      </Box>
    </>
  );
};

export default InvoiceView;
