import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Layout/Layout'
import GridData from '../../components/GridData';
import { Button } from 'primereact/button';
import { Box } from '@mui/material';
import { useFade } from '../../components/FadeContext/FadeContext';
import CurrencyFormat from '../../components/CurrencyFormat';
import { useTranslation } from 'react-i18next';

export default function PaymentHistoryView(props) {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [rowData, setRowData] = useState([]);

  const columnDefs = [
    {
      field: "InvoiceId",
      headerName: t("Invoice number"),
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header"
    },
    {
      field: "Amount",
      headerName: `${t("Amount")} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    }
  ];

  const goToPaymentView = () => {
    props.closePaymentHistoryView();
  }
  const updateRowData = useCallback(() => {
    if (contextVariable.rootObject) {
      setRowData(JSON.parse(contextVariable.rootObject.PaymentHistoryDetails));
    }
  }, [contextVariable.rootObject]);

  useEffect(() => {
    updateRowData();
  }, [updateRowData]);

  const { fadeIn } = useFade();
  return (
    <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>

      <div>
        <Button
          className="secondary-button p-button-sm mb-2"
          onClick={goToPaymentView}
          icon="pi pi-arrow-left"
          label={t('Back to payment history')}>
        </Button>

        <>
          <div className="headerDetails card-shadow bg-white">
            <div className="grid">
              <div className='col-6 md:col-4 lg:col-3 flex flex-column text-left '>
                <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Transaction Reference No')} :</label>
                <span className="font-semibold" >{contextVariable.rootObject.TransactionReferenceNo}</span>
              </div>
              <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Date Of Transaction')} :</label>
                <span className="font-semibold ">{contextVariable.rootObject.DateOfTransaction}</span>
              </div>
              <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Payment Amount')} :</label>
                <span className="font-semibold ">{CurrencyFormat(contextVariable.rootObject.PaymentAmount)}{"  "}{contextVariable.taxDetails.CurrencyCode}</span>
              </div>
              <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Payment Status')} :</label>
                <span className="font-semibold ">{contextVariable.rootObject.PaymentStatus}</span>
              </div>
              <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                <label className="text-xs md:text-xs font-semibold text-gray-400">{t('SAP Bank Payment Advice')} :</label>
                <span className="font-semibold ">{contextVariable.rootObject.SAPBankPaymentadviceId}</span>
              </div>
            </div>
          </div>
        </>

        <div className='mt-5'>
          <GridData
            rowData={rowData}
            columnDefs={columnDefs}
            needFilters={false}
            showPagaination={false}
            tableHeight={"58vh"}
          />
        </div>

      </div>
    </Box>
  )
}
