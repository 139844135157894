import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";

export const SuccessRegisterDialog = (props) => {
    const [displayResponsive, setDisplayResponsive] = useState(true);
    
    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const renderFooter = (name) => {
        return (
            <div>
               <Button label="Okay" onClick={()=>props.CloseSuccess()}></Button>
            </div>
        );
    }
  return (
    <>
    <Dialog
        header="Registration Success"
        visible={displayResponsive}
        dismissableMask
        onHide={() => onHide('displayResponsive')}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '50vw' }}
        closable
        footer={renderFooter('displayResponsive')}
    >
      <p className='mb-2 text-lg'>Your registration is complete! Please verify your email by clicking the link we've sent to proceed to the login page</p>
    </Dialog>
</>
  )
}
