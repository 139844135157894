import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import LoadingImage from '../../Pages/ProductCatalog/Images/loading_image.jpg';
import ImageUnavailable from '../../Pages/ProductCatalog/Images/Image_not_available.png';
import { Image } from 'primereact/image';

export default function Authorizedimages(props) {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const fetchImageUrls = async () => {
            setImageUrl(LoadingImage);
            const imageKey = props.ProductImage.split("public/")[1];
            if (imageKey) {
                try {
                    const imageValue = await Storage.get(imageKey);
                    setImageUrl(imageValue);
                } catch (error) {
                    console.error('Error fetching image from storage:', error);
                    setImageUrl(ImageUnavailable);
                }
            }
        };
        if (props.ProductImage) {
            fetchImageUrls();
        }
    }, [props.ProductImage]);
    const icon = (<i className="pi pi-search"></i>)
    return (
        <div>
            {props.From === "mobileDialog" &&
                <img src={imageUrl ? imageUrl : LoadingImage}
                    indicatorIcon={icon} alt="Productimage"
                    // preview
                    className='fadein animation-duration-500 mx-auto object-cover rounded-md'
                    style={{ height: "85%", width: '85%' }}
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }} />
            }

            {/* mobile card Image tag/  */}
            {props.From === "MobileCard" &&
                <Image src={imageUrl ? imageUrl : LoadingImage}
                    indicatorIcon={icon} alt="Image"
                    className='fadein animation-duration-500'
                    // preview
                    imageStyle={props.mobileS ? { height: "6rem", width: "7rem", objectFit: "contain" } : { height: "7rem", width: "9rem", objectFit: "contain" }}
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }} />

            }
            {/* Catalog page image tag  */}
            {props.From === "CatalogPageView" &&
                <Image src={imageUrl ? imageUrl : LoadingImage} indicatorIcon={icon} alt="Image"
                    imageStyle={{  height: "130px", objectFit: "contain" }}
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }}
                />

            }
            {/* Catalog dialogbox image view  */}
            {props.From === "CatalogPageDialogView" &&
                <Image src={imageUrl ? imageUrl : LoadingImage} indicatorIcon={icon}
                    alt="Image"
                    imageStyle={{ height: "30vh", objectFit: "contain" }}
                    // preview
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }}
                />

            }
            {/* Trending product image view  */}
            {props.From === "TrendingCard" &&
                <Image src={imageUrl ? imageUrl : LoadingImage} indicatorIcon={icon}
                    alt="Image"
                    imageStyle={{  height: "20vh", objectFit: "contain" }}
                    // preview
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }}
                />

            }
            {/* PlaceOrderListView image view  */}
            {props.From === "PlaceOrderListView" &&
                <Image src={imageUrl ? imageUrl : LoadingImage} indicatorIcon={icon}
                    alt="Image"
                    imageStyle={{  height: "20vh", objectFit: "contain" }}
                    // preview
                    onError={(e) => {
                        e.target.src = ImageUnavailable;
                        e.target.alt = "Product";
                    }}
                />

            }

        </div>
    )
}
