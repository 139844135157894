import {
  AddShoppingCart,
  Favorite,
  FavoriteBorder,
  TrendingUpOutlined,
} from "@mui/icons-material";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import "./ProductCatalog.css";
import { AppContext } from "../../Layout/Layout";
import { showToast } from "../../components/ToastUtils";
import { API } from "aws-amplify";
import { deleteUserFavouriteProducts } from "../../graphql/mutations";
import { handleAddToCart } from "../../components/AddAndRemoveFunctions";
import { Dialog } from "primereact/dialog";
// import ImageUnavailable from './Images/Image_not_available.png';
// import LoadingImage from './Images/loading_image.jpg';
// import NoFavourite from "./Images/NoFavourites.png";
import CurrencyFormat from "../../components/CurrencyFormat";
import { createUserFavouriteProducts } from "../../Layout/MutaionsAndQueries";
import Authorizedimages from "../../components/cards/Authorizedimages";
import ProductCard from "./ProductCard";
import { Button } from "primereact/button";
import UOMSection from "./ProductCardComponents/UOMSection";
import { ListByProductInventoryDetails } from "../../graphql/queries";
import UOMConversion from "../../components/UOMConversion";
import { useTranslation } from "react-i18next";

const DataViewGridItem = (props) => {
  const { t } = useTranslation();
  const getSymbolFromCurrency = require("currency-symbol-map");
  const contextVariable = useContext(AppContext);
  const ProductListData = props.ProductListData;
  // console.log("ProductListData", ProductListData)
  const [count, setCount] = useState(1);
  const [AddCartRemoveCartUsingProductAdded, setAddCartRemoveCartUsingProductAdded] = useState(ProductListData.Added)
  const [FavAdded, setFavAdded] = useState(ProductListData.FavAdded)
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (ProductListData) {
      if (ProductListData.ProductQuantity !== "") {
        setCount(ProductListData.ProductQuantity)
      }
      else {
        setCount(1)
      }
      setIsLoading(false);
    }

  }, [ProductListData]);

  useEffect(() => {
    // console.log("contextVAriable", contextVariable)
    setFavAdded((p) => contextVariable.FavIds.includes(ProductListData.id));
    setAddCartRemoveCartUsingProductAdded((p) => contextVariable.CartItemsIds.includes(ProductListData.id))
  }, [contextVariable.FavIds, contextVariable.CartItemsIds, ProductListData])


  const [ProductStock, setProductStock] = useState("Loading");
  const [StockUom, setStockUom] = useState("");

  useEffect(() => {
    if (contextVariable.taxDetails.InventoryStatus && contextVariable.AllUOMDetails.length > 0) {
      const FetchProductStock = async () => {
        try {
          const ProductStockDetails = await API.graphql({
            query: ListByProductInventoryDetails,
            variables:
            {
              MaterialID: ProductListData.id
            }
          })

          if (ProductStockDetails && ProductStockDetails.data.ListByProductInventoryDetails.SearchResult.length > 0) {
            const ProductStockCount = Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) > 0 ?
              Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) : 0;
            if (contextVariable.UserCartItems.length > 0) {
              const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === ProductListData.id);
              if (FindSameProductStockReduce.length > 0) {
                const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                // console.log("TotalStockValue", TotalStockValue)
                setProductStock(ProductStockCount - TotalStockValue < 0 ? 0 : ProductStockCount - TotalStockValue)
              }
              else {
                setProductStock(ProductStockCount)
              }

            }
            else {
              setProductStock(ProductStockCount);

            }
            const GetUOMText = contextVariable.AllUOMDetails.find(P => P.Value === ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].AvailableStockUOM)
            setStockUom(GetUOMText.Description);

          }
          else {
            setProductStock(0);
          }

        } catch (error) {
          setProductStock(0);
          console.error("Fetching Individual product stock", error)
        }
      }
      FetchProductStock();
    }
    else if (!contextVariable.taxDetails.InventoryStatus) {
      setProductStock("NoLimit")
    }
    // eslint-disable-next-line 
  }, [contextVariable.taxDetails.InventoryStatus, contextVariable.AllUOMDetails])



  //input Box Numbers handlinginput
  const handleInputChange = (event) => {
    const enteredValue = event.target.value.trim();
    if (
      event.target.value === "" ||
      event.key === "Backspace" ||
      event.key === "Delete"
    ) {
      setCount("");
    } else {
      const numericValue = enteredValue.replace(/[^0-9]/g, "");
      const value = Number(numericValue);
      const ShowToastUOM = contextVariable.AllUOMDetails.find(P => P.Value === data.ChangeUOM || StockUom)?.Description || '';
      if (ProductStock === "NoLimit") {
        const newValue = Math.min(Math.max(value, 1), (data.UOMStockAvl || 9999));
        setCount(newValue);
        if (value > 9999 || value > data.UOMStockAvl) {
          showToast(`${t(`Maximum limit of`)} ${data.UOMStockAvl || 9999} ${ShowToastUOM} ${t('reached')}`, "Info", 2000)
        }
      }
      else if (ProductStock > 0) {
        const newValue = Math.min(Math.max(value, 1), (data.UOMStockAvl || ProductStock));
        setCount(newValue);
        if (value > ProductStock || value > data.UOMStockAvl) {
          showToast(`${t(`Only`)} ${data.UOMStockAvl || ProductStock} ${ShowToastUOM} ${t(`are currently available in stock`)}.`, "Info", 3000);
        }
      }

    }


  };

  const handleInputBlur = () => {
    // If input is left empty, set it to 1 on blur
    if (count === "") {
      setCount(1);
    }
  };


  const productDiscountConfirmation = ProductListData.ProductsDiscounts.items.length !== 0 ? Math.abs(ProductListData.ProductsDiscounts.items[0].ProductDiscount) > 0 ? Math.abs(ProductListData.ProductsDiscounts.items[0].ProductDiscount) : 0.00 : 0.00;
  const handleAddToCartCount = async () => {
    if ((contextVariable.taxDetails.InventoryStatus ? ProductStock : 9999) < count) {
      showToast(t(`You are trying to add more than the available stock.`), "Info", 2000);
    }
    else if ((contextVariable.taxDetails.InventoryStatus ? ProductStock : 9999) >= count) {
      ProductListData.buttonLoading = true;
      contextVariable.setAddRemoveCartLoading(true);
      const addDataProps = {
        ProductQuantity: count,
        ProductQuantityText: data.ProductUOM,
        ProductPriceUOMCode: data.ChangeUOM || data.ProductPriceQuantityCode,
        CalculatedUOMQuantity: data.UOMQuantity ? count * parseFloat(parseFloat(data.UOMQuantity).toFixed(2)) : count,
        owner: contextVariable.userDetails.sub,
        ProductPrice: parseFloat(data.ProductPrice),
        ProductDiscount: productDiscountConfirmation,
        userProductsCartProductdetailsId: data.id,
      };


      contextVariable.setCartItemsIds((prevIds) => {
        const AddId = new Set(prevIds);
        AddId.add(data.id);
        return Array.from(AddId)
      })
      // console.log("data", data)
      // console.log("addDataProps", addDataProps)
      await handleAddToCart(contextVariable, data, addDataProps);
      if (contextVariable.taxDetails.InventoryStatus && ((contextVariable.UserCartIdDetails &&
        contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus !== "InProgress") ||
        contextVariable.UserCartIdDetails.length === 0)) {
        setProductStock((prevStock) => prevStock - (data.UOMQuantity ? count * parseFloat(parseFloat(data.UOMQuantity).toFixed(2)) : count))
      }
      sessionStorage.removeItem("cartDetails")
      ProductListData.buttonLoading = false;
      contextVariable.setAddRemoveCartLoading(false);
      setVisible(false);
      setData({});
      setCount(1);

    }


  };


  const [addFavDisable, setAddFavDisable] = useState(false);

  const addToFavorite = async () => {
    setAddFavDisable(true);
    setFavAdded(true);
    const data = {
      FavouriteStatus: 'active',
      owner: contextVariable.userDetails.sub,
      productID: ProductListData.id,
      userFavouriteProductsProductDetailId: ProductListData.id
    }
    if (contextVariable.AllFavDetailsWithTenent.length !== 100) {
      contextVariable.setFavIds((prevIds) => {
        const AddId = new Set(prevIds);
        AddId.add(ProductListData.id);
        return Array.from(AddId)
      })
      try {
        const result = await API.graphql({
          query: createUserFavouriteProducts,
          variables: {
            input: data
          }
        });
        contextVariable.setAllFavDetailsWithTenent((prevItms) => [...prevItms, result.data.createUserFavouriteProducts])
        // contextVariable.setAllFavDetailsWithTenent([...contextVariable.AllFavDetailsWithTenent, result.data.createUserFavouriteProducts]);
      }
      catch (error) {
        showToast(t(`Cannot add this product as favourite.Please contact admin for assistance.`), "error");
        contextVariable.setFavIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id));
        setFavAdded(false);
        console.error("Add to Favourites Error", error);
      }
      finally {
        setAddFavDisable(false);
      }
    }
    else {
      setAddFavDisable(false);
      setFavAdded(false);
      showToast(t(`Limit Reached! You can only add 100 products in favourite.`))
    }
  };

  const removeFromFavourite = async () => {
    setAddFavDisable(true);
    try {
      const RemoveItemDetails = contextVariable.AllFavDetailsWithTenent.filter((p) => p.productID === ProductListData.id);
      const itemToDeleteId = RemoveItemDetails[0].id;
      const response = await API.graphql({
        query: deleteUserFavouriteProducts,
        variables: {
          input: {
            id: itemToDeleteId
          }
        }
      });
      if (response) {
        contextVariable.setFavIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
        const balanceFav = contextVariable.AllFavDetailsWithTenent.filter((p) => p.id !== itemToDeleteId);
        contextVariable.setAllFavDetailsWithTenent(balanceFav)
      }


    } catch (error) {
      console.error("Error in Removing", error);
      showToast(t(`Cannot remove this product from favourite. Please contact admin for assistance.`), "error");
      // contextVariable.setFavIds((prevIds) => {
      //   const AddId = new Set(prevIds);
      //   AddId.add(ProductListData.id);
      //   return Array.from(AddId)
      // })
      // setFavAdded(true);
    }
    finally {
      setAddFavDisable(false);
    }
  };



  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});

  const [UOMDetails, setUOMDetails] = useState([])
  useEffect(() => {

    if (Object.keys(data).length > 0) {
      try {
        const ProductUOM = JSON.parse(data.ProductQuantityDetails);
        // console.log("ProductUOm", ProductUOM)
        if (ProductUOM.length > 0) {
          setUOMDetails(ProductUOM)
        }
      }
      catch (error) {
        console.error(error)
      }

      // }
    }

  }, [data])

  const [ShowBaseUom, setShowBaseUom] = useState(true);
  const handleDisplayProductDetails = () => {
    const SelectedUomDetails = JSON.parse(props.ProductListData.ProductQuantityDetails);
    // console.log('SelectedUomDetails', SelectedUomDetails)
    if (SelectedUomDetails.length > 0) {
      // console.log("props.ProductListData.ProductUOM", props.ProductListData.ProductUOM)
      const SelectedUomDetailsFirst = SelectedUomDetails.find((p) => {

        if (p.CorrespondingQuantityTypeCode === props.ProductListData.ProductUOM) {
          setShowBaseUom(false);
          return p;
        }
      })
      // console.log("SelectedUomDetailsFirst", SelectedUomDetailsFirst)
      if (SelectedUomDetailsFirst) {
        setData({ ...props.ProductListData, ChangeUOM: SelectedUomDetailsFirst.CorrespondingQuantityTypeCode, UOMStockAvl: ProductStock, UOMQuantity: parseFloat(SelectedUomDetailsFirst.Quantity).toFixed(2) });
      }
      else {
        setData({ ...props.ProductListData, ChangeUOM: props.ProductListData.ProductUOM });
      }
      // console.log("SelectedUomDetailsFirst", SelectedUomDetailsFirst)

    }
    else {
      setData({ ...props.ProductListData, ChangeUOM: props.ProductListData.ProductUOM });
    }

    setVisible(true);
  };
  // const handleUOMDialog = () => {
  //   setData({ ...props.ProductListData, ChangeUOM: props.ProductListData.ProductUOM });
  //   setVisible(true);
  // };
  const handleDefaultUOMChange = () => {
    setData({ ...props.ProductListData, ChangeUOM: props.ProductListData.ProductUOM });
  }

  const priceBold = 500;






  return (
    <>

      <Dialog visible={visible}
        onHide={() => {
          setVisible(false);
          setData({});
          setCount(1);
        }}
        style={{ width: '50vw' }}
        header={data.ProductCategoryName}
      >

        <div className=" w-100  flex  justify-content-center ">

          {/* image section  */}
          <div className="w-50 flex justify-content-center align-items-center">
            <div className="">
              <Authorizedimages ProductImage={data.ProductImage} From="CatalogPageDialogView" />
            </div>
          </div>


          <div className=" w-50  flex flex-column">


            {/* ProductName */}
            <div className="text-sm md:text-xl font-bold flex justify-content-start gap-2 align-items-end">

              {data.ProductName}

              {data.ProductStatus === "Trending" && (
                <div className="pb-1">
                  <TrendingUpOutlined
                    className="glowing text-sm md:text-base"
                    style={{
                      boxShadow: "none", // Remove the box-shadow property
                    }}
                  >
                  </TrendingUpOutlined>
                </div>
              )}

              {
                FavAdded &&

                <IconButton onClick={removeFromFavourite}>
                  <Favorite className="text-sm md:text-lg " style={{ color: 'red' }} />
                </IconButton>
              }
              {
                (FavAdded === false) &&

                <IconButton onClick={addToFavorite} className="">
                  <FavoriteBorder className="text-sm md:text-lg " style={{ color: '#D80032' }} />
                </IconButton>
              }


            </div>

            {/* ProductDetailDescription */}
            <div>
              <p className="text-sm text-gray-500 md:text-base leading-relaxed whitespace-normal max-w-2/4">
                {data.ProductDetailDescription || 'Product description is unavailable'}
              </p>
            </div>

            {/* ProductPrice */}
            <div>
              {(data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0) &&
                (<div>
                  {(data.ProductsDiscounts.items.length) === 0 && (
                    <div className="w-100">
                      <span className=''>
                        {getSymbolFromCurrency(
                          data.ProductPriceCurrencyCode
                        )}
                      </span>
                      <span className='text-4xl' style={{ fontWeight: priceBold }}>
                        {parseFloat(data.ProductPrice).toFixed(2)}

                      </span>
                      <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                        {/* ({data.ProductUOMText}) */}
                        {" "}({parseInt(data.ProductPriceQuantity)}  <UOMConversion UOMCode={data.ProductPriceQuantityCode} />)

                      </span>
                    </div>
                  )}

                  {data.ProductsDiscounts.items.length !== 0 && (
                    <div className="flex flex-column w-100">
                      <div className="">
                        <strike style={{ textDecorationColor: 'gray', fontSize: '14px' }}>
                          <span style={{ color: 'gray', fontSize: '13px' }}>
                            {getSymbolFromCurrency(
                              data.ProductPriceCurrencyCode
                            )}
                          </span>
                          <span style={{ color: 'gray', fontSize: '13px' }}>
                            {data.ProductPrice}

                          </span>
                        </strike>

                        <span style={contextVariable.isRTL ? { marginRight: '0.2rem', color: '' } : { marginLeft: '0.2rem', color: '' }}>
                          {getSymbolFromCurrency(
                            data.ProductPriceCurrencyCode
                          )}
                        </span>

                        <span
                          style={{ fontWeight: priceBold }}
                          className={data.ProductPrice.toString().length >= 8 ? 'text-lg ' : 'text-4xl'}
                        >
                          {CurrencyFormat(
                            parseFloat(data.ProductPrice) -
                            parseFloat((Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount)) / 100) *
                              parseFloat(data.ProductPrice))
                          )}

                        </span>

                        <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                          {/* ({data.ProductUOMText}) */}
                          {" "}({parseInt(data.ProductPriceQuantity)}  <UOMConversion UOMCode={data.ProductPriceQuantityCode} />)
                        </span>
                      </div>

                      <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                        <div className="saved-amount">
                          Save : {getSymbolFromCurrency(
                            data.ProductPriceCurrencyCode
                          )}
                          {CurrencyFormat(
                            parseFloat((Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount)) / 100) * parseFloat(data.ProductPrice))
                          )}
                        </div>
                        <span className="discount-badge"
                        // style={contextVariable.isRTL ? { marginRight: isTab ? '0rem' : '0.7rem' } : { marginLeft: isTab ? '0rem' : '0.7rem' }}
                        >
                          {Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount))}%off
                        </span>
                      </div>

                    </div>
                  )}
                </div>)}

              {/* Does not contains Product Price */}
              {
                (data.ProductPrice === null || ProductListData.ProductPrice <= 0) &&
                <div className="flex flex-column justify-content-center align-items-center"
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'var(--sidebar-color)',
                          fontSize: '12px'
                        },
                      },
                    }}
                    title="This product is currently unavailable"
                    placement="bottom"
                    TransitionComponent={Zoom}
                    enterDelay={500}
                  >
                    <span className="font-semibold text-xs md:text-xs border-round-lg py-1 px-3 mt-3"
                      style={{ color: "var(--sidebar-color)", border: "1px solid var(--sidebar-color)" }}>
                      {t(`Unavailable`)}
                    </span>
                  </Tooltip>
                </div>
              }
            </div>

            {/* Inventory details  */}
            <div>
              {
                (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && ProductStock > 0) &&
                <div>
                  <div className="text-xs font-semibold pt-1 border-round-sm ">
                    <span className="text-red-700  border-round-3xl " >{t(`Avl.Stock`)}: {ProductStock} {StockUom}</span>
                  </div>

                </div>
              }
              {
                (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && ProductStock === 0) &&
                <div className="text-base font-semibold pl-2 pr-2 pt-4 border-round-sm text-center fadein animation-duration-2000 ">
                  <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl " >{t(`Out of Stock`)}</span>
                </div>
              }
            </div>


            {(data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 &&
              (ProductStock === "NoLimit" || ProductStock > 0)) && <div className="flex flex-column gap-2 w-100 mt-2">

                <div className="">
                  <div className="flex gap-3 flex-wrap ">

                    {ShowBaseUom && 
                    <div onClick={handleDefaultUOMChange} style={data?.ChangeUOM === data.ProductUOM ? { fontSize: "0.7rem" } : { height: "1.8rem", fontSize: "0.7rem" }}
                      className={`flex border-1 border-round-md justify-content-center  p-1 cursor-pointer ${data?.ChangeUOM === data.ProductUOM ? 'border-blue-500 border-2' : 'hover:border-blue-500 hover:bg-blue-50  border-gray-400 '}`}>
                      <button
                        className={`${data?.ChangeUOM === data.ProductUOM ? "text-blue-500 font-semibold" : "hover:text-blue-700  text-black"}`}>
                        <span>
                          <i className={` ${data?.ChangeUOM === data.ProductUOM ? 'pi pi-check pl-1 pr-1' : 'pl-1 '}`}
                            style={{ fontSize: '0.7rem' }}></i>
                          <UOMConversion UOMCode={data.ProductUOM} /></span>
                      </button>
                    </div>
                    }

                    {UOMDetails.map((UOMobj, index) => (
                      <div key={index}>
                        <UOMSection UOMobj={UOMobj} index={index} selectedData={data}
                          setCount={setCount} setSelectedData={setData} ProductStock={ProductStock} />
                      </div>
                    ))}

                  </div>


                </div>

                <div className="flex">
                  <div className="w-50 pt-1">
                    <input
                      type="text"
                      value={count === "" ? "" : count}
                      className=" bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 w-50 "
                      style={{
                        fontSize: "14px",
                        padding: "0px",
                        height: '28px',
                        textAlign: "center",
                        outline: "none",
                        borderRadius: '5px'
                      }}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                    <span
                      className="mt-2 pr-2 pl-2"
                      style={{ fontSize: "10px" }}
                    >
                      {data.ChangeUOM && <UOMConversion UOMCode={data.ChangeUOM} />}
                      {!data.ChangeUOM && <UOMConversion UOMCode={data.ProductUOM} />}
                    </span>
                  </div>

                  <div className="w-50">
                    <Button rounded raised
                      className="primary-button font-bold  w-75 text-sm"
                      onClick={handleAddToCartCount}
                      loading={data.buttonLoading}
                    >
                      <span className='w-100'>
                        <AddShoppingCart ></AddShoppingCart>
                      </span>

                    </Button>
                  </div>
                </div>
              </div>}


          </div>

        </div>
      </Dialog>

      {/* Favourites View */}
      {
        (props.component === "favourite" && FavAdded) &&
        (<ProductCard contextVariable={contextVariable} ProductListData={ProductListData}
          handleAddToCartCount={handleAddToCartCount}
          handleInputChange={handleInputChange} handleInputBlur={handleInputBlur}
          count={count} handleDisplayProductDetails={handleDisplayProductDetails}
          addFavDisable={addFavDisable} addToFavorite={addToFavorite} FavAdded={FavAdded} removeFromFavourite={removeFromFavourite}
          AddCartRemoveCartUsingProductAdded={AddCartRemoveCartUsingProductAdded} isLoading={isLoading} handleUOMDialog={handleDisplayProductDetails}
          ProductStock={ProductStock} StockUom={StockUom}
        />)
      }

      {/* Normal View  */}
      {
        props.component !== "favourite" &&
        (
          <ProductCard contextVariable={contextVariable} ProductListData={ProductListData}
            handleAddToCartCount={handleAddToCartCount}
            handleInputChange={handleInputChange} handleInputBlur={handleInputBlur}
            count={count} handleDisplayProductDetails={handleDisplayProductDetails}
            addFavDisable={addFavDisable} addToFavorite={addToFavorite} FavAdded={FavAdded} removeFromFavourite={removeFromFavourite}
            AddCartRemoveCartUsingProductAdded={AddCartRemoveCartUsingProductAdded} isLoading={isLoading} handleUOMDialog={handleDisplayProductDetails}
            ProductStock={ProductStock} StockUom={StockUom}

          />
        )
      }
    </>
  );
};

export default DataViewGridItem;
