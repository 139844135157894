import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./i18/en.json";
import translationHB from "./i18/hb.json";
import translationFR from "./i18/fr.json";
import translationGE from "./i18/ge.json";
import translationSP from "./i18/sp.json";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./theme.css";
import { Amplify, Auth } from "aws-amplify";
import config from "./aws-exports";

export const LanguageContext = createContext();

// Amplify configure for api id token purpose 
Amplify.configure({
  ...config,
  API: {
    graphql_headers: async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        return {
          Authorization: `${idToken}`,
        };

      } catch (error) {
        console.log("error", error)
      }


    }

  },
});

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      hb: {
        translation: translationHB,
      },
      fr: {
        translation: translationFR,
      },
      ge: {
        translation: translationGE,
      },
      sp: {
        translation: translationSP,
      },
    },
    lng: "en", // Default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

const RootApp = () => {
  const [language, setLanguage] = useState('en');
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <App />
    </LanguageContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <RootApp />
  </I18nextProvider>
);
