import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import CurrencyFormat from "../../components/CurrencyFormat";
import { AppContext } from "../../Layout/Layout";
import PaymentHistoryView from "./PaymentHistoryView";
import { FetchDataOfPaymentHistory } from "../../components/ListDetailsFunction";
import copyToClipboard from "../../components/copyToClipboard";
import { ContentCopyOutlined } from "@mui/icons-material";
import { showToast } from "../../components/ToastUtils";
import { useFade } from "../../components/FadeContext/FadeContext";
import CardTopbar from "../../components/cards/CardTopbar";
import SkeletonLoader from "../../components/SkeletonLoader";
import NoResultsIcon from "../order/NoResultsIcon";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import PaymentHistoryCard from "../../components/cards/paymentHistory/PaymentHistoryCard";
import PaymentHistoryDetailsCard from "../../components/cards/paymentHistory/PaymentHistoryDetailsCard";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../components/cards/dataNotFound/DataNotFound";
import { useTranslation } from "react-i18next";

const PaymentHistory = () => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState(1);
  const [PaymentHistory, setPaymentHistoryView] = useState(false);
  const contextVariable = useContext(AppContext);
  const isMobile = contextVariable.isMobile;
  // const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  // const currentYear = new Date().getFullYear();
  // const dates = [
  //   { label: String(currentYear), year: currentYear },
  //   { label: String(currentYear - 1), year: currentYear - 1 },
  //   { label: String(currentYear - 2), year: currentYear - 2 },
  //   { label: String(currentYear - 3), year: currentYear - 3 },
  //   { label: String(currentYear - 4), year: currentYear - 4 },
  //   { label: String(currentYear - 5), year: currentYear - 5 },
  //   { label: String(currentYear - 6), year: currentYear - 6 },
  //   { label: String(currentYear - 7), year: currentYear - 7 },
  //   { label: String(currentYear - 8), year: currentYear - 8 },
  //   { label: String(currentYear - 9), year: currentYear - 9 },
  //   { label: String(currentYear - 10), year: currentYear - 10 },
  //   { label: String(currentYear - 11), year: currentYear - 11 },
  //   { label: String(currentYear - 12), year: currentYear - 12 },
  //   { label: String(currentYear - 13), year: currentYear - 13 },
  //   { label: String(currentYear - 14), year: currentYear - 14 },
  //   { label: String(currentYear - 15), year: currentYear - 15 }
  // ];
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  // Each Column Definition results in one Column.
  const columnDefs = [
    {
      field: "TransactionReferenceNo", headerName: t('Transaction Reference Number'), filter: true, flex: 1, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const handleCopyToClipboard = () => {
          copyToClipboard(params.value);
          showToast(t(`Copied to your clipboard`), 'info');
        }

        return (
          <>
            {
              params.value !== '' ?
                <div className='d-flex justify-content-start align-items-center gap-5'>
                  <span className="">{params.value}</span>
                  <IconButton onClick={handleCopyToClipboard}>
                    <ContentCopyOutlined style={{ fontSize: '15px' }} />
                  </IconButton>
                </div>
                : ''
            }
          </>
        )

      }
    },
    {
      field: "DateOfTransaction", headerName: t('Date of Transaction'), filter: true, flex: 1, headerClass: 'custom-ag-header',
      // sort:"desc",
    },
    {
      field: "PaymentAmount",
      headerName: `${t('Payment Amount')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 1, headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        const netAmountString = params.value;
        const netAmountFloat = parseFloat(netAmountString);
        if (!isNaN(netAmountFloat)) {
          const formattedValue = netAmountFloat.toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return params.value; // Return the original value if parsing fails
        }
      },
    },
    {
      field: "PaymentHistoryDetails",
      headerName: "Invoice Ids",
      filter: true,
      flex: 1,
      headerClass: "custom-ag-header",
      hide: true,
    },
    {
      field: "PaymentStatus",
      headerName: t('Status'),
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const colorClass = {
          "In Process": "text-gray-600 bg-gray-100",
          "Open": "text-blue-600 bg-blue-100",
          "Success": "text-green-600 bg-green-100",
          "Partially Cleared": "text-yellow-600 bg-yellow-100",
        };

        return (
          <span
            className={`px-2 py-1 border-round-md font-semibold ${colorClass[params.value]}`}
          >
            {params.value}
          </span>
        );
      }
    },
    // {
    //   field: "SAPBankPaymentadviceId", headerName: "SAP Bank Payment Advice", filter: true, flex: 1, headerClass: 'custom-ag-header'
    // },
  ]

  const fetchPaymentHistory = async () => {
    setRowData(1);
    try {
      setLoading(true);
      if (contextVariable.userDetails["custom:customer_UUID"]) {
        await FetchDataOfPaymentHistory(contextVariable, setRowData, setError);
      }
      else {
        console.error("error in getting userdetails", contextVariable.userDetails["custom:customer_id"]);
      }
    }
    catch (error) {
      setRowData(0);
      showToast(t(`Something went wrong. Please contact the admin.`), "error");
      console.error("fetching payment history error", error);
      setError(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (contextVariable.userDetails["custom:customer_id"]) {
      setLoading(true);
      FetchDataOfPaymentHistory(contextVariable, setRowData, setError)
        .then(setLoading(false))
        ;
    }
    //eslint-disable-next-line
  }, [contextVariable.userDetails["custom:customer_id"]]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (!contextVariable.showView.showPaymentHistoryDetails) {
      window.scrollTo(0, contextVariable.scrollPosition);
    }
  }, [contextVariable.showView.showPaymentHistoryDetails, contextVariable.scrollPosition]);


  const selectedRowsHandler = (rows) => {
  }
  const openPaymentHistoryView = () => {
    setPaymentHistoryView(true);
  };
  const closePaymentHistoryView = () => {
    setPaymentHistoryView(false);
  };

  // Handler for updating the search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const handleDateChange = (e) => {
  //   sessionStorage.setItem("date", e.value.year);
  //   setSelectedDate(e.value.year);
  //   // contextVariable.setGlobalData(null);
  //   contextVariable.setGlobalSearch(contextVariable.globalSearch)
  //   // contextVariable.setFilteredData([]);
  //   contextVariable.setIsFiltered(false);
  //   contextVariable.setDate(e.value.year);
  // };

  const { fadeIn } = useFade();

  return (
    <>
      {PaymentHistory && <PaymentHistoryView
        closePaymentHistoryView={closePaymentHistoryView}>
      </PaymentHistoryView>}

      {
        (!isMobile && contextVariable.showView.showPaymentHistoryDetails === false) &&
        <>
          {!PaymentHistory && <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
            {sessionStorage.setItem("title", "Payment History")}

            {/* DataGrid Card  */}
            <div className="card card-shadow">
              <div className="card-body">
                <GridData
                  rowData={rowData}
                  columnDefs={columnDefs}
                  component={'paymentHistory'}
                  PaymentHistoryView={openPaymentHistoryView}
                  tableHeight={'78vh'}
                  needFilters={true}
                  showPagination={true}
                  selectedRows={selectedRowsHandler}
                  filter={'PaymentHistory'}
                  RefreshFn={fetchPaymentHistory}
                />
              </div>
            </div>
          </Box>}
        </>
      }

      {
        (isMobile && contextVariable.showView.showPaymentHistoryDetails === false) &&
        <>
          <div className="bg-gray-50">
            <div className="sticky top-0 z-1">
              {/* Topbar */}
              <CardTopbar
                header={`Payment History`}
                buttonNeeded={false}
                refresh={fetchPaymentHistory}
                loading={loading}
              />
              {/* Dropdown and Search */}
              <div className="bg-gray-50 border-bottom-2 pt-2 pr-2 pl-2 shadow-2">
                <div className="grid">
                  {/* <div className="col-4">
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 w-full mobile-dropdown"
                    />
                  </div> */}
                  <div className="col-12">
                    <span className="p-input-icon-left w-full">
                      <i className="pt-1 pi pi-search" />
                      <InputText
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by reference, status etc..."
                        className="p-inputtext-sm p-2 pl-5 w-full"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto">

              {
                (rowData !== 0 && rowData !== 1) &&

                rowData
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .map((filteredEl, index) => (
                    <PaymentHistoryCard
                      key={index}
                      index={index}
                      rowData={filteredEl}
                      contextVariable={contextVariable}
                    />
                  ))
              }
              {/* Loading skeleton */}
              {
                rowData === 1 &&
                <div className="flex flex-column">
                  {
                    [...Array(7)].map((_, index) => (
                      (rowData === 0 || rowData === 1) &&
                      <SkeletonLoader key={index} />
                    ))
                  }
                </div>
              }

              {/* No data foundd for selected year */}
              {
                (rowData === 0 && !error) &&
                <>
                  <div className="mt-3 h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                </>
              }

              {/* No data found on search */}
              {
                (rowData !== 0 && rowData !== 1) &&
                rowData
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .length === 0 && (
                  <div className="h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                )
              }

            </div>

          </div>
        </>
      }

      {/* Api error */}
      {
        (error) &&
        <DataNotFound />
      }

      {
        (isMobile && contextVariable.showView.showPaymentHistoryDetails === true) &&
        <>
          <PaymentHistoryDetailsCard />
        </>
      }

    </>
  );
};

export default PaymentHistory;

