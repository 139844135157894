import { API } from 'aws-amplify'
import React, { useContext, useEffect, useState } from 'react'
import { listLambdaGroupings } from '../../../../graphql/queries'
import AdminGridData from '../../../../components/Admin/AdminGridData';
import { Box, Tooltip, Zoom } from '@mui/material';
import { AppContext } from '../../../../Layout/Layout';
import { useTranslation } from 'react-i18next';
import { updateNotificationList } from '../../../../components/Admin/AdminListDetailsFunction';

const Notifications = () => {
    const { t } = useTranslation();
    sessionStorage.setItem("title", t("Notifications"));
    const [notificationList, setNotificationList] = useState(1);
    // const [refreshNotifications, setRefreshNotifications] = useState(false);
    const contextVariable = useContext(AppContext);

    const [DisableChecked, setDisableChecked] = useState(false);

    function handleStatusChange(event, params, notificationIdArray) {
        console.log("params", params)
        // setDisableChecked(true);
        let NotificationIds = notificationIdArray;
        const Checking = NotificationIds.some(notification => notification[params.value]);
        if (!Checking) {
            if (NotificationIds.length === 0) {
                const AddedId = [{ [params.value]: "true" }];
                const JsonConvert = JSON.stringify(AddedId);
                updateOrganisationDetails(JsonConvert)
            }
            else {
                const AddedId = NotificationIds.map(notification => {
                    const newNotification = { ...notification };
                    newNotification[params.value] = "true";
                    return newNotification;
                });
                const JsonConvert = JSON.stringify(AddedId);
                updateOrganisationDetails(JsonConvert)
            }

        }
        else if (Checking) {
            const RemainingNotifications = NotificationIds.map(notification => {
                const newNotification = { ...notification };
                delete newNotification[params.value];
                return newNotification;
            }
            )
            const JsonConvert = JSON.stringify(RemainingNotifications);

            updateOrganisationDetails(JsonConvert)
        }
    }

    const updateOrganisationDetails = async (JsonData) => {

        // const UpdateOrganizationFormData = {
        //     id: contextVariable.OrganizationDetails.id,
        //     NotificationList: JsonData
        // }
        try {
            await updateNotificationList(JsonData, contextVariable);
        } catch (error) {
            console.error("Update organizations details error", error)
        }
        finally {
            setDisableChecked(false);
        }
    }

    const columnDefs = [
        // {
        //     field: "NotificationID",
        //     headerName: "ID",
        //     filter: true,
        //     flex: 0.5,
        //     sortable: true,
        //     headerClass: "custom-ag-header"
        // },
        {
            field: "NotificationName",
            headerName: "Name",
            filter: true,
            flex: 0.2,
            sortable: true,
            headerClass: "custom-ag-header"
        },
        {
            field: "NotificationID",
            headerName: "Status",
            filter: true,
            flex: 0.1,
            sortable: true,
            headerClass: "custom-ag-header",
            cellRenderer: (params) => {
                let notificationIdArray = [];
                if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.NotificationList) {
                    notificationIdArray = JSON.parse(contextVariable.OrganizationDetails.NotificationList);
                }
                let CheckboxValue = false;
                if (notificationIdArray.length > 0) {
                    notificationIdArray.some(notification => {
                        if (notification[params.value]) {
                            CheckboxValue = true;
                            return true;
                        }
                        else {
                            CheckboxValue = false;
                            return false;
                        }
                    })

                } else {
                    CheckboxValue = false;
                }
                return (
                    <>
                        {
                            params.data.NotificationMandatoryStatus === 'Mandatory' ?
                                <>
                                    <div className='mt-2 text-red-800 cursor-not-allowed'>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'var(--sidebar-color)',
                                                        fontSize: '12px'
                                                    },
                                                },
                                            }}
                                            title="This notification is mandatory"
                                            placement="left"
                                            TransitionComponent={Zoom}
                                            enterDelay={300}>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={true}
                                                    disabled={true}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </Tooltip>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='mt-2'>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={CheckboxValue}
                                                onChange={(e) => handleStatusChange(e, params, notificationIdArray)}
                                                disabled={DisableChecked}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </>
                        }

                    </>
                )
            }
        },
        {
            field: "NotificationDescription",
            headerName: "Description",
            filter: true,
            flex: 0.6,
            sortable: true,
            headerClass: "custom-ag-header"
        },
    ]

    const FetchNotificationDetails = async () => {
        try {
            const response = await API.graphql({
                query: listLambdaGroupings,
                variables: {
                    filter: { not: { NotificationID: { notContains: "" } } }
                }
            });
            // console.log("response", response)
            setNotificationList(response.data.listLambdaGroupings.items)
        }
        catch (error) {
            setNotificationList(0);
            console.error("Notification Fetch error", error)
        }
    }

    useEffect(() => {
        FetchNotificationDetails();
    }, [])

    const selectedRowsHandler = (rows) => { };

    return (
        <Box className="admin-box-container">

            <div className="card card-shadow">
                <div className="card-body">
                    {/* <div className='flex justify-content-between align-items-to'> */}

                    {/* Table section */}
                    <div className='flex-1'>
                        <AdminGridData
                            rowData={notificationList}
                            columnDefs={columnDefs}
                            component={"Notifications"}
                            filter={"Notifications"}
                            tableHeight={"70vh"}
                            needFilters={true}
                            showPagination={true}
                            selectedRows={selectedRowsHandler}
                            // refreshNotifications={refreshNotifications}
                        />
                    </div>

                    {/* Info section */}
                    {/* <div className='m-2grid text-gray-500 w-20rem justify-content-end'>
                            <div className="col-6">
                                <h3 className='font-semibold mb-1'>Payment Status</h3>
                                <p className='text-xs'>
                                    lorem Ipsum
                                </p>
                            </div>
                            <div className="col-6">
                                <h4 className='font-semibold mb-1'>Payment Status</h4>
                                <p className='text-xs'>
                                    lorem Ipsum
                                </p>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>


            </div>
        </Box>
    )
}

export default Notifications