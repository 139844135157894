import { Box } from '@mui/material';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import AdminGridData from '../../../../components/Admin/AdminGridData';
import { useState } from 'react';
import { useEffect } from 'react';
import { productImageUploadFunction } from '../../../../components/Admin/AdminListDetailsFunction';
import ProductImageUploadView from './ProductImageUploadView';
import { AppContext } from '../../../../Layout/Layout';

const DateAndTimeChange = ({ value, data }) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    // hour12: true,
  };

  const CreateAtDate = new Date(value);
  const CreateAtDateFinal = CreateAtDate.toLocaleString('en-GB', options);
  return CreateAtDateFinal;
}

export default function ProductImageUpload() {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [productImageUploadData, setProductImageUploadData] = useState(1);

  useEffect(() => {
    productImageUploadFunction(setProductImageUploadData);
  }, []);

  const columnDefs = [
    // {
    //   headerName: "Line Item",
    //   field: "SNo",
    //   valueGetter: function (params) {
    //     return params.node.rowIndex + 1;
    //   },
    //   filter: true,
    //   flex: 0.1,
    //   headerClass: 'custom-ag-header',
    //   sortable: true,
    // },
    // {
    //   headerName: "Tenant ID",
    //   field: "tenant",
    //   filter: true,
    //   flex: 0.1,
    //   headerClass: 'custom-ag-header',
    // },
    {
      field: "DocumentFile",
      headerName: "Document Type",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "DocumentDescription",
      headerName: "Description",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "CreatedBy",
      headerName: "Created By",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    }, {
      field: "createdAt",
      headerName: "Created On",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: DateAndTimeChange,
      sort: "desc",
    },
    {
      field: "FileSize",
      headerName: "File size",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "Status",
      headerName: "Status",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    }, {
      field: "ImageCount",
      headerName: "Total no of images",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "FailedImagesFull",
      headerName: "Failed Images",
      filter: true,
      flex: 0.7,
      headerClass: "custom-ag-header",
      sortable: true,
      tooltipField: "FailedImagesFull",
      cellRenderer: (params) => {
        if (params.value === '') {
          return (
            <span>
              None
            </span>
          )
        }
        else {
          return (
            <span>
              {params.value}
            </span>
          )
        }
      }
    },
  ]

  const [descriptionView, setDescriptionView] = useState(false);
  const Refresh = () => {
    productImageUploadFunction(setProductImageUploadData);
  }
  //set the Top bar Title
  sessionStorage.setItem("title", t("Product Image Upload"));

  const openDescriptionView = () => {
    setDescriptionView(true);
  };

  const closeDescriptionView = () => {
    setDescriptionView(false);
  }

  const selectedRowsHandler = (rows) => { };

  //For Hard back button click
  useEffect(() => {
    if (sessionStorage.getItem("TapPageNumber") !== "3") {
      const currentURL = window.location.href;
      if (currentURL.includes("/customerportalsetup/productImageUpload")) {
        contextVariable.setTapPage(3);
        sessionStorage.setItem("TapPagNumber", 3);
      }
    }
    // eslint-disable-next-line 
  }, [])

  return (
    <>
      {
        descriptionView &&
        <ProductImageUploadView
          rowData={productImageUploadData}
          backToProductImageUpload={closeDescriptionView}
        />
      }
      {
        !descriptionView &&
        <Box className="admin-box-container">
          {/* DataGrid Card  */}
          <div className="card card-shadow">
            <div className="card-body">
              <AdminGridData
                rowData={productImageUploadData}
                columnDefs={columnDefs}
                descriptionView={openDescriptionView}
                component={"productImageUpload"}
                tableHeight={"70vh"}
                needFilters={true}
                showPagination={true}
                selectedRows={selectedRowsHandler}
                filter={"productImageUpload"}
                Refresh={Refresh}
              // customProductIDFilter={customProductIDFilter}
              />
            </div>
          </div>
        </Box>
      }
    </>
  )
}
