import { Button } from "primereact/button";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import { useContext, useState } from "react";
import { API } from "aws-amplify";
import { CreatePaymentsHistoryData } from "../graphql/mutations";
import { showToast } from "./ToastUtils";
import { AppContext } from "../Layout/Layout";
import i18n from '../i18/i18nConfig';
// const secretStripe = require("stripe")("sk_test_51O7YcaSBrp9Zmg6IU4As7vThlQvgxV6vFEYe5qACu8jwjaVcrlexzNZHdpBfKcmmx3KOm05LAqRyrYZNDGmG3tK8003vWxUYz7");
// const secretStripe = require("stripe")("sk_test_51OAV2dSJ0HpEjX6nrd5D6DIYIgS6MxKBm6YY71dzJiy828ngkkyqVG6MSjdvO4hGT94OaNWfD8Fq8yw18wPmSw5p00ZxW7me7b");

const StripeCheckout = (props) => {
    // console.log("Inside props of stripe", props);
    const contextVariable = useContext(AppContext);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
   
    let checkoutItems, invoicePaymentDetails; 

    // const getStripe = async () => {
    //     // const stripe = await loadStripe("pk_test_51O7YcaSBrp9Zmg6IIahjX06ijnCu5OATMPMGY0BgXFYPXU3RPaJQ4PGec3j6AExKSZzLPoORtV0mb7PyjPHLbL5K00xe8BOlCh");
    //     const stripe = await loadStripe("pk_test_51OAV2dSJ0HpEjX6n5eYM2LutZMNWZZPBpxaB4VhJ9YrkCKQDkRQvkXGPDEE2Y43DMcBb7E9iUJLDFhldwBtTALYl00pqWUbcRY");
    //     return stripe;
    // }

    const makePayment = () => {

        // console.log("props.product", props.product);
        // console.log("props.invoiceList", props.invoiceList);

        if (props.product.length !== 0) {
            checkoutItems = (props.product).map(productItem => ({
                price_data: {
                    currency: contextVariable.taxDetails.CurrencyCode,
                    product_data: {
                        name: productItem.description
                    },
                    // unit_amount: (parseFloat(productItem.price).toFixed(2)) * 100
                    unit_amount: Number((productItem.price * 100).toFixed(2)) //Stripe takes amount only in paise
                },
                quantity: 1
            }));

            invoicePaymentDetails = (props.product).map(productItem => ({
                Amount: parseFloat(productItem.price).toFixed(2),
                InvoiceId: productItem.description,
                ExternalReference: productItem.referenceId
            }));
        }
        else {

            checkoutItems = (props.invoiceList).map((productItem) => (

                {

                    price_data: {
                        currency: contextVariable.taxDetails.CurrencyCode,
                        product_data: {
                            name: productItem.InvoiceID
                        },
                        //  unit_amount: (parseFloat(productItem.OpenAmount).toFixed(2)) * 100 
                        unit_amount: Number((productItem.OpenAmount * 100).toFixed(2)) //Stripe takes amount only in paise
                    },
                    quantity: 1
                }));

            invoicePaymentDetails = (props.invoiceList).map(productItem => ({
                Amount: parseFloat(productItem.OpenAmount).toFixed(2),
                InvoiceId: productItem.InvoiceID,
                ExternalReference: productItem.ExternalReference
            }));
        }

        console.log("checkoutItems", checkoutItems)
        // console.log("invoicePaymentDetails", invoicePaymentDetails)

        // Creating data in Payment History table
        createPaymentRecord();

    }

    function getFormattedDate() {
        const today = new Date();

        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();

        const hours = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}  🕒 ${hours}:${minutes}`;
    }

    const createPaymentRecord = async () => {
        let successUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
        let cancelUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

        let inputData = {
            CustomerId: contextVariable.userDetails["custom:customer_UUID"],
            ContactId: contextVariable.userDetails["custom:contactperson_UUID"],
            DateOfTransaction: getFormattedDate(),
            PaymentAmount: parseFloat(props.totalAmountToPay).toFixed(2).toString(),
            PaymentHistoryDetails: JSON.stringify(invoicePaymentDetails),
            PaymentStatus: "In Process",
            SAPBankPaymentadviceId: "-",
            SAPBankPaymentadviceStatus: "In Process",
            SAPBankPaymentadviceUUID: "000000-0001-1DDF-BA84-90CC6DCE3",
            TransactionReferenceNo: "",
            Successurl:successUrl,
            Cancelurl:cancelUrl
        }
        setCheckoutLoading(true);
        contextVariable.setPageLoading(true);
        // console.log("input", inputData)
        try {
            const response = await API.graphql({
                query: CreatePaymentsHistoryData,
                variables: {
                    input: inputData
                }
            })
            window.location.href = response.data.CreatePaymentsHistoryData.SessionURL;
            // const secretKey = response.data.CreatePaymentsHistoryData.StripeSecretKey;
            // const publishableKey = response.data.CreatePaymentsHistoryData.StripePublishableKey;
            // const tableId = response.data.CreatePaymentsHistoryData.id;
            // const secretStripe = require("stripe")(secretKey);
            // const stripe = await loadStripe(publishableKey);

            // console.log("STRIPE", stripe);

            // createSession(secretStripe, stripe, tableId);

        }
        catch (error) {
            console.error(error);
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, 'error', 3000);
            // showToast(`${error.errors[0].message}`, "error");
        }
        finally {
            setCheckoutLoading(false);
            contextVariable.setPageLoading(false);
        }
    }

    // const createSession = async (secretStripe, stripe, tableId) => {
    //     // Creating customer
    //     // const customer = await secretStripe.customers.create();
    //     // const paymentDetails = {
    //     //     customerID: customer.id,
    //     //     productDetails: checkoutItems,
    //     //     totalAmount: props.totalAmountToPay
    //     // }

    //     let successUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/paymentHistory`;
    //     let failureUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/invoice`;
    //     let invoiceIds = invoicePaymentDetails.map((el) => {
    //         return el.InvoiceId
    //     });

    //     try {
    //         // Creating Session
    //         const session = await secretStripe.checkout.sessions.create({
    //             payment_method_types: ["card"],
    //             line_items: checkoutItems,
    //             mode: 'payment',
    //             success_url: successUrl,
    //             cancel_url: failureUrl,
    //             // customer: customer.id,
    //             // client_reference_id: tableId + "," + contextVariable.userDetails["custom:tenant"] + "," + contextVariable.userDetails["custom:contactperson_UUID"],
    //             client_reference_id: tableId + "," + contextVariable.userDetails["custom:tenant"],
    //             metadata: {
    //                 client_invoice_ids: JSON.stringify(invoiceIds)
    //             }
    //             // shipping_address_collection: {
    //             //     allowed_countries: ['IN'],
    //             // }
    //             // custom_text: {
    //             //     shipping_address: {
    //             //         message: 'Shipping address in India is not allowed',
    //             //         // message: 'Please note that we can\'t guarantee 2-day delivery for PO boxes at this time.',
    //             //     },
    //             // }

    //         });

    //         // Redirecting to checkout
    //         redirectToCheckout(session, stripe);

    //     } catch (error) {
    //         console.error('Stripe error:', error);
    //         showToast(`Something went wrong. Please contact the admin`, 'error', 3000);
    //         // showToast(error.toString(), "error", 5000);
    //     }
    // }

    // const redirectToCheckout = async (session, stripe) => {
    //     stripe.redirectToCheckout({
    //         sessionId: session.id
    //     });
    // }

    // const redirectToCheckout = async (session, stripe) => {
    //     stripe.redirectToCheckout({
    //         sessionId: "cs_test_a1aG8iFWvcHvjjoiLyVqQ4S1f4ZwYIgVpe8EOntfNDpYTVrBIDE1g0MtHf"
    //     });
    // }

    return (
        <div>
            <Button
                raised
                loading={checkoutLoading}
                disabled={checkoutLoading}
                className="primary-button flex gap-2 px-3 text-base"
                onClick={makePayment}
            >
                <AccountBalanceWalletOutlined /> Pay
                {/* Submit */}
            </Button>
        </div>
    )
}

export default StripeCheckout;