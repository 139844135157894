import { ArrowBack } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button } from 'primereact/button'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../Layout/Layout'
import AdminGridData from '../../../../components/Admin/AdminGridData'

export default function DiscountCustomerPage(props) {

  const contextVariable = useContext(AppContext)
  const [rowData, setRowData] = useState(1);
  // console.log("rowData", rowData)

  useEffect(() => {

    if (contextVariable.rootObject.ProductsDiscounts.items.length !== 0) {
      setRowData(contextVariable.rootObject.ProductsDiscounts.items);
    }
    else {
      setRowData(0);
    }
  }, [contextVariable.rootObject]);

  const gotToProductImageUpload = () => {
    props.HideDiscountCustomerPage();
  }

  const columnDefs = [
    {
      headerName: "S.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      flex: 0.3,
      headerClass: "custom-ag-header",
    },
    { field: "ProductID", headerName: "Product ID", filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    { field: "Customer", headerName: "Customer", filter: true, flex: 0.7, headerClass: 'custom-ag-header' },
    {
      field: "ProductDiscount", headerName: "Discount %", filter: true, flex: 0.7, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const discountPercentage = Math.abs(parseFloat(params.data.ProductDiscount).toFixed(2)) + "%"
        return discountPercentage;
      }

    }

  ];

  const selectedRowsHandler = (rows) => {
  }

  return (
    <>
      {sessionStorage.setItem("title", "Discount Details")}

      <Box className="admin-box-container">
        <Button className="secondary-button p-button-sm mb-2"
          onClick={gotToProductImageUpload}
        >
          <ArrowBack />
          <span>  Back to Products view</span>
        </Button>

        <div>
          <AdminGridData
            rowData={rowData}
            columnDefs={columnDefs}
            tableHeight={'70vh'}
            needFilters={false}
            showPagination={true}
            selectedRows={selectedRowsHandler}
          />
        </div>

      </Box>
    </>
  )
}
