import { Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Tile from "../../../components/Tile"; // Import Tile component
import { useTranslation } from 'react-i18next';
import './AdminDashboard.css';
import { useEffect } from "react";
import { useState } from "react";
import CountUp from 'react-countup';
import Loader from "../../../components/Loader";
import BackgroundSVG from '../../../components/BackgroundSVG';
import '../../../components/BackgroundSVG.css';
import {  FileUploadOutlined, Inventory2Outlined, PeopleAltOutlined, ViewInArOutlined } from "@mui/icons-material";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { AppContext } from "../../../Layout/Layout";
import { useContext } from "react";
import { CountInDashBoard } from '../../../components/Admin/AdminListDetailsFunction';
import AdminDialogComponent from '../../../components/Admin/AdminDialogComponent';


const AdminDashboard = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  sessionStorage.setItem("title", t('Dashboard'));
  const [UsersCount, setUsersCount] = useState("");
  const [loading, setLoading] = useState(true);
  const [ProductsCount, setProductsCount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    CountInDashBoard(setUsersCount, setLoading, setProductsCount)
    if (contextVariable.OrganizationDetails === "Empty" ||
      contextVariable.OrganizationDetails.SAPCredentialIndicator === "false" ||
      contextVariable.OrganizationDetails.PriceDefaultIndicator === "false") {
      contextVariable.setShow("Info")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoPage = (text) => {
    if (text === "productImageUpload") {
      contextVariable.setTapPage(3);
      sessionStorage.setItem("TapPagNumber", 3)
      contextVariable.setShow("MoreInstractions");
      navigate("/customerportalsetup/productImageUpload");
    }
    if (text === "productsView") {
      contextVariable.setTapPage(4);
      sessionStorage.setItem("TapPagNumber", 4)
      navigate("/customerportalsetup/productImageView");
    }
    if (text === "contactDetails") {
      contextVariable.setTapPage(2);
      sessionStorage.setItem("TapPagNumber", 2)
      navigate("/customerportalsetup/contactDetails");
    }
  }


  return (
    <div dir={contextVariable.rtl ? "rtl" : "ltr"}>
      <BackgroundSVG />
      <Box>

        <div className="">
          {/* Tile Secti̥on */}
          <div className="flex gap-8  justify-content-center mt-7">
            {/* <Button onClick={(e) => getData()}>Delete Discount</Button>
            <Button onClick={(e) => Deleteprice()}>Delete price</Button>
            <Button onClick={(e) => Deleteproduct()}>Delete products</Button> */}

            {/* No. of Users Section */}
            <div className="card-shadow tile-card d-flex justify-content-between dashboardCard border-3 border-round-lg">
              <div className="">
                {loading ? (
                  <div style={{ marginTop: '1rem' }}>
                    <Loader></Loader>
                  </div>
                ) : (
                  <div className="tile-number font-semibold">
                    <CountUp end={UsersCount} duration={2} />
                  </div>
                )}

                <h2 className='tile-title'>{t('No of users')}</h2>
              </div>
              <div className="icon-div">
                <PeopleAltOutlined sx={{ color: '#ffffff' }} fontSize="large" />
              </div>
            </div>

            {/*No. of Products section */}
            <div className="card-shadow tile-card d-flex justify-content-between dashboardCard border-3 border-round-lg">
              <div className="tile">
                {loading ? (
                  <div style={{ marginTop: '1rem' }}>
                    <Loader></Loader>
                  </div>
                ) : (
                  // Show the CountUp component once the data is loaded
                  <div className="tile-number font-semibold">
                    <CountUp end={ProductsCount} duration={2} />
                  </div>
                )}
                <h2 className='tile-title'>{t('No of products')}</h2>
              </div>
              <div className="icon-div align-items-top">
                <Inventory2Outlined sx={{ color: '#ffffff' }} fontSize="large" />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {/* Tile Secti̥on */}
          <div className="flex gap-8 justify-content-center mt-7">

            {/* Contact Details */}
            <div className="card-shadow tile-card flex justify-content-between dashboardCard cursor-pointer border-2 border-round-lg"
              onClick={(e) => handleGoPage("contactDetails")}>
              <div className="tile flex flex-column items-center gap-2">
                <ContactMailIcon className='text-5xl  border-gray-900' />
                <span className='text-xl'>Contact Details</span>
              </div>
            </div>

            {/* Product Image Upload */}
            <div className="card-shadow tile-card flex justify-content-between dashboardCard cursor-pointer border-2 border-round-lg"
              onClick={(e) => handleGoPage("productImageUpload")}>
              <div className="tile flex flex-column items-center gap-2 ">
                <FileUploadOutlined className='text-5xl  border-gray-900' />
                <span className='text-xl'>Product Image Upload</span>
              </div>
            </div>

            {/* Products View */}
            <div className="card-shadow tile-card flex justify-content-between dashboardCard cursor-pointer border-3 border-round-lg"
              onClick={(e) => handleGoPage("productImageUpload")}>
              <div className="tile flex flex-column items-center gap-2 ">
                <ViewInArOutlined className='text-5xl   border-gray-900' />
                <span className='text-xl'>Products View</span>
              </div>
            </div>

          </div>
        </div>

      </Box>

      {
        contextVariable.show === "Info" && (
          <AdminDialogComponent
            header={t('Info')}
            title=""
            Note=""
          />
        )
      }

    </div>
  );
};

export default AdminDashboard;
