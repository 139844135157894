import React from 'react'
import { BarLoader } from 'react-spinners';

const Loader = () => {
    // const Logo = sessionStorage.getItem("ogol")
    return (
        <>
            <div >

                {/* <div className='flex justify-content-center align-items-center'>
                    {Logo && <img src={Logo} alt="logo" style={{ height: '30px' }} />}
                </div> */}

                <div className=' flex justify-content-center align-items-center mt-1'>
                    <BarLoader
                        color="#344955" />
                </div>
            </div>
        </>
    )
}

export default Loader;