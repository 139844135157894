import GetOrderPDF from '../../../Pages/order/GetOrderPDF';
import './InvoiceCard.css';
import {  SimCardDownload } from '@mui/icons-material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { showToast } from '../../ToastUtils';
import { useTranslation } from 'react-i18next';

const InvoiceCard = ({ index, invoiceDetails, contextVariable, selectedInvoices }) => {
  const { t } = useTranslation();
  let item = invoiceDetails;
  // console.log("selectedInvoices", "-", selectedInvoices);

  const handlePreviewClick = (invoiceID) => {
    GetOrderPDF("invoice", invoiceID, invoiceID);
    showToast(t(`Started downloading...`), "info", 1000);
  }

  const handleCheckbox = (event, items) => {
    if (event.target.checked === true) {
      // console.log("first", items);
      contextVariable.setSelectedInvoices(prevInvoices => new Set([...prevInvoices, items]));
      // console.log("contextVariable.selectedInvoices", contextVariable.selectedInvoices)
    } else {
      const updatedInvoices = new Set(contextVariable.selectedInvoices);
      updatedInvoices.delete(items);
      contextVariable.setSelectedInvoices(updatedInvoices);
    }
  };

  const handleCardClick = (params) => {
    // console.log("inside", params);
    contextVariable.setScrollPosition(window.scrollY);
    contextVariable.setRootObject(params);
    contextVariable.setShowView((prev) => ({ ...prev, showInvoiceDetails: true }));
    // navigate('/orderDetails');
  }

  return (
    <>
      <div key={index} className="card m-2" style={{ fontFamily: "helvetica", marginTop: "0" }}>
        <div
          className={`invoice-container ${item.PaymentStatus === 'Cleared' ? 'card-border-cleared' :
            item.PaymentStatus === 'Partially Cleared' ? 'card-border-partially-cleared' :
              item.PaymentStatus === 'Open' ? 'card-border-open' :
                ''
            }`}
        >
          {/* PDF container */}
          {
            (item.InvoiceID).trim() !== '' ?
              <div
                onClick={() => handlePreviewClick(item.InvoiceID)}
                style={{ background: "#f8f8f8" }}
                className='p-3 flex flex-column justify-content-center align-items-center'>
                <div>
                  <SimCardDownload
                    style={{ fontSize: "2rem", color: "#ff6c6c" }} />
                </div>
                <div>
                  <span className='font-10'>PDF</span>
                </div>
              </div>
              :
              <div
                style={{ background: "#f8f8f8" }}
                className='p-3 flex flex-column justify-content-center align-items-center'>
                <div>
                  <span className='text-sm' style={{ color: "#c5c5c5" }}>NO <br />PDF</span>
                </div>
              </div>
          }

          {/* Reference and status */}
          <div
            onClick={() => handleCardClick(invoiceDetails)}
            className='bg-white flex flex-column justify-content-center align-items-left' style={{ gap: "6px" }}>
            <div>
              <span className='text-base'>#{" "}{item.ExternalReference}</span>
            </div>
            <div className='flex gap-2 align-items-center'>
              {
                (item.PaymentStatus === "Open") &&
                <span className='text-xs' style={{ color: "var(--info-color)" }}>{item.PaymentStatus}</span>
              }
              {
                (item.PaymentStatus === "Cleared") &&
                <span className='text-xs' style={{ color: "#00b321" }}>{item.PaymentStatus}</span>
              }
              {
                (item.PaymentStatus === "Partially Cleared") &&
                <span className='text-xs' style={{ color: "#ff7b00", width: "2.8rem" }}>{item.PaymentStatus}</span>
              }
              {
                (item.PaymentStatus !== "Open" && item.PaymentStatus !== "Cleared" && item.PaymentStatus !== "Partially Cleared") &&
                <span className='text-xs'>{item.PaymentStatus}</span>
              }

              <span className='text-xs' style={{ color: "#8a8a8a", whiteSpace: "nowrap" }}>{item.InvoiceID}</span>
            </div>
          </div>

          {/* Date and total amount */}
          <div
            onClick={() => handleCardClick(invoiceDetails)}
            className='bg-white flex flex-column justify-content-center' style={{ alignItems: 'flex-end', gap: "6px" }}>
            <div>
              <span className='text-sm' style={{ color: "#8a8a8a" }}>{item.InvoiceDate}</span>
            </div>
            <div>
              <span className='text-base'>
                {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)}
                {item.PaymentStatus === 'Partially Cleared' ? (Number(item.OpenAmount)).toFixed(2) : (Number(item.TotalAmount)).toFixed(2)}
              </span>
            </div>
          </div>

          {/* Checkbox */}
          {
            (item.PaymentStatus !== "Cleared") &&
            <>
              <div className='flex flex-column justify-content-center'>
                <input type='checkbox'
                  checked={selectedInvoices.some(el => el.InvoiceID === item.InvoiceID)}
                  // onChange={(event) => onCheckboxChange(event.target.checked)}
                  onChange={(event) => handleCheckbox(event, item)}
                  style={{ transform: 'scale(1.2)' }} />
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default InvoiceCard;