import { AddShoppingCart, TrendingUpOutlined } from '@mui/icons-material';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useRef, useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map';
import TruncateText from '../TruncateText';
import CurrencyFormat from '../CurrencyFormat';
import { Tooltip, Zoom, useMediaQuery } from '@mui/material';
import { AppContext } from '../../Layout/Layout';
import { showToast } from '../ToastUtils';
import { handleAddToCart } from '../AddAndRemoveFunctions';
import Authorizedimages from './Authorizedimages';
import './CatalogCard.css';
import FavChoose from './FavChoose';
import AddedIndicate from './AddedIndicate';
import PriceAndButtonCard from './PriceAndButtonCard';
import { Dialog } from 'primereact/dialog';
import UOMConversion from '../UOMConversion';
import UOMSection from '../../Pages/ProductCatalog/ProductCardComponents/UOMSection';
import { useTranslation } from 'react-i18next';
const CatalogCard = (props) => {
    const { t } = useTranslation();
    const [productList, setProductList] = useState([]);
    const contextVariable = useContext(AppContext);
    const [visible, setVisible] = useState(false);
    const [count, setCount] = useState(1);
    const [SelectedProduct, setSelectedProduct] = useState({});



    //input Box Numbers handling
    const handleInputChange = (event) => {
        const enteredValue = event.target.value.trim();
        if (
            event.target.value === "" ||
            event.key === "Backspace" ||
            event.key === "Delete"
        ) {
            setCount("");
        } else {
            const numericValue = enteredValue.replace(/[^0-9]/g, "");
            const value = Number(numericValue);
            if (SelectedProduct.ProductStock === "NoLimit") {
                const newValue = Math.min(Math.max(value, 1), 9999);
                setCount(newValue);
                if (value > 9999) {
                    showToast(t('Maximum limit of 9999 reached.'), "Info", 2000)
                }
            }
            else if (SelectedProduct.ProductStock > 0) {
                const newValue = Math.min(Math.max(value, 1), SelectedProduct.ProductStock);
                setCount(newValue);
                if (value > SelectedProduct.ProductStock) {
                    showToast(`${t('Only')} ${SelectedProduct.ProductStock} ${SelectedProduct.ProductUOMText} ${t('available')}.`, "Info", 2000)
                }
            }
        }
    };

    const handleInputBlur = () => {
        // If input is left empty, set it to 1 on blur
        if (count === "") {
            setCount(1);
        }
    };

    const handleAddToCartCount = async () => {
        const productDiscountConfirmation = SelectedProduct.ProductsDiscounts.items.length !== 0 ? SelectedProduct.ProductsDiscounts.items[0].ProductDiscount : 0.00;


        if ((contextVariable.taxDetails.InventoryStatus ? SelectedProduct.ProductStock : 9999) < count) {
            showToast(t('You are trying to add more than the available stock.'), "Info", 2000);
        }
        else if ((contextVariable.taxDetails.InventoryStatus ? SelectedProduct.ProductStock : 9999) >= count) {
            SelectedProduct.buttonLoading = true;
            contextVariable.setAddRemoveCartLoading(true);
            const addDataProps = {
                ProductQuantity: count,
                owner: contextVariable.userDetails.sub,
                CalculatedUOMQuantity: SelectedProduct.UOMQuantity ? count * parseFloat(parseFloat(SelectedProduct.UOMQuantity).toFixed(2)) : count,
                ProductPrice: parseFloat(SelectedProduct.ProductPrice),
                ProductDiscount: productDiscountConfirmation,
                userProductsCartProductdetailsId: SelectedProduct.id,
                ProductQuantityText: SelectedProduct.ProductUOM,
                ProductPriceUOMCode: SelectedProduct.ChangeUOM || SelectedProduct.ProductPriceQuantityCode
            };
            contextVariable.setCartItemsIds((prevIds) => {
                const AddId = new Set(prevIds);
                AddId.add(SelectedProduct.id);
                return Array.from(AddId)
            })
            await handleAddToCart(contextVariable, SelectedProduct, addDataProps);

            sessionStorage.removeItem("cartDetails")
            SelectedProduct.buttonLoading = false;
            contextVariable.setAddRemoveCartLoading(false);
            setVisible(false);
        }


    };

    useEffect(() => {
        if (props.productList) {
            if (props.selectedTab === 1 || props.selectedTab === 2) {
                if (props.filteredValue.length > 0) {
                    setProductList(props.filteredValue);
                }
                else {
                    setProductList(props.productList);
                }

            }
            else {
                setProductList(props.productList);
            }


        }
    }, [props])

    useEffect(() => {
        if (SelectedProduct) {
            if (SelectedProduct.ProductQuantity !== "") {
                setCount(SelectedProduct.ProductQuantity)
            }
            else {
                setCount(1)
            }
        }

    }, [SelectedProduct]);

    const loadMoreRef = useRef(null);
    // useEffcet for automatic load more 
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                handleLoad();
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            observer.disconnect();
        };
        //eslint-disable-next-line
    }, [loadMoreRef, productList]);

    const handleLoad = async (e) => {
        if (contextVariable.OpenSearchInputText === "") {
            await contextVariable.FetchProductDetailsLoadMoreUse();
        }
        else if (contextVariable.OpenSearchInputText !== "") {
            let events = { key: "Enter" };
            let Fromcount = contextVariable.ProductListDetails.length;
            await contextVariable.OpenSearchProductsDetails(events, Fromcount, contextVariable.OpenSearchInputText)
        }
    }

    const footer = contextVariable.productListNextToken &&
        <div className='flex justify-content-center align-items-center' ref={loadMoreRef}>
            <Button
                type="text"
                style={{ color: "var(--sidebar-color)" }}
                rounded outlined
                className='mt-3 px-2 py-1 text-xs'
                // loading={loadmoreLoading}
                loading={true}
                label="Loading"
                onClick={(e) => handleLoad(e)} />
        </div>;

    const mobileS = useMediaQuery("(max-width:321px)");
    const mobileM = useMediaQuery("(max-width:376px)");
    const mobileL = useMediaQuery("(max-width:426px)");



    const [UOMDetails, setUOMDetails] = useState([]);
    const handleDisplayProductDetails = (SelectedProduct, ProductStock, StockUom) => {

        if (ProductStock === "NoLimit") {
            setSelectedProduct({ ...SelectedProduct, ChangeUOM: SelectedProduct.ProductUOM, ProductStock: ProductStock, StockUom: StockUom });
        }
        else {
            if (contextVariable.UserCartItems.length > 0) {

                const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === SelectedProduct.id);
                if (FindSameProductStockReduce.length > 0) {
                    const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                    // console.log("TotalStockValue", TotalStockValue)
                    const FinalStock = ProductStock - TotalStockValue < 0 ? 0 : ProductStock - TotalStockValue;
                    setSelectedProduct({ ...SelectedProduct, ChangeUOM: SelectedProduct.ProductUOM, ProductStock: FinalStock, StockUom: StockUom });
                    
                }
                else {
                    setSelectedProduct({ ...SelectedProduct, ChangeUOM: SelectedProduct.ProductUOM, ProductStock: ProductStock, StockUom: StockUom });
                }

            }
            else {
                setSelectedProduct({ ...SelectedProduct, ChangeUOM: SelectedProduct.ProductUOM, ProductStock: ProductStock, StockUom: StockUom });
            }
        }

        if (Object.keys(SelectedProduct).length > 0) {
            try {
                const ProductUOM = JSON.parse(SelectedProduct.ProductQuantityDetails);
                if (ProductUOM.length > 0) {
                    setUOMDetails(ProductUOM);
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        setVisible(true);

    };

    const handleDefaultUOMChange = () => {
        setSelectedProduct({ ...SelectedProduct, ChangeUOM: SelectedProduct.ProductUOM });
    }




    return (
        <>
            <Dialog
                dismissableMask
                visible={visible}
                onHide={() => {
                    setVisible(false);
                }}
                style={{ width: '90vw' }}
                header={"Product Details"}
            >
                <section className='p-2 flex flex-column gap-2'>

                    {/* Image section */}
                    <div>
                        <div className="">
                            <Authorizedimages ProductImage={SelectedProduct.ProductImage} From="mobileDialog" />
                        </div>
                    </div>

                    {/* Category and Favourite Section */}
                    <div className="flex justify-content-between align-items-center">

                        <div>
                            <span className="flex justify-content-center align-items-center gap-1">
                                <i
                                    className="pi pi-tag text-xs"
                                />
                                <span className='text-sm font-medium'>
                                    {SelectedProduct.ProductCategoryName}
                                </span>
                            </span>
                        </div>

                        <div>
                            {SelectedProduct.ProductStatus === "Trending" && (

                                <TrendingUpOutlined
                                    className="glowing text-sm"
                                >
                                </TrendingUpOutlined>
                            )}

                            <FavChoose Data={SelectedProduct} selectedTab={props.selectedTab} />
                        </div>
                    </div>

                    {/* Description section */}
                    <div>
                        <p className="text-base leading-relaxed whitespace-normal">
                            {SelectedProduct.ProductDetailDescription || 'Product description is unavailable'}
                        </p>
                    </div>

                    {/* ProductPrice Section*/}
                    <div>
                        {(SelectedProduct.ProductPrice !== null && SelectedProduct.ProductPrice !== 0 && SelectedProduct.ProductPrice > 0) &&
                            (<div>
                                {(SelectedProduct.ProductsDiscounts.items.length) === 0 && (
                                    <div className="w-100">
                                        <span className=''>
                                            {getSymbolFromCurrency(
                                                SelectedProduct.ProductPriceCurrencyCode
                                            )}
                                        </span>
                                        <span className='text-4xl' style={{ fontWeight: 500 }}>
                                            {parseFloat(SelectedProduct.ProductPrice).toFixed(2)}

                                        </span>
                                        <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                            ({SelectedProduct.ProductUOMText})
                                        </span>
                                    </div>
                                )}

                                {SelectedProduct.ProductsDiscounts.items.length !== 0 && (
                                    <div className="flex justify-content-between align-items-center flex-wrap w-100">
                                        <div className="">
                                            <strike style={{ textDecorationColor: 'gray', fontSize: '14px' }}>
                                                <span style={{ color: 'gray', fontSize: '13px' }}>
                                                    {getSymbolFromCurrency(
                                                        SelectedProduct.ProductPriceCurrencyCode
                                                    )}
                                                </span>
                                                <span style={{ color: 'gray', fontSize: '13px' }}>
                                                    {SelectedProduct.ProductPrice}
                                                </span>
                                            </strike>

                                            <span style={contextVariable.isRTL ? { marginRight: '0.2rem', color: '' } : { marginLeft: '0.2rem', color: '' }}>
                                                {getSymbolFromCurrency(
                                                    SelectedProduct.ProductPriceCurrencyCode
                                                )}
                                            </span>

                                            <span
                                                style={{ fontWeight: 500 }}
                                                className={SelectedProduct.ProductPrice.toString().length >= 8 ? 'text-lg ' : 'text-4xl'}
                                            >
                                                {CurrencyFormat(
                                                    parseFloat(SelectedProduct.ProductPrice) -
                                                    parseFloat((Math.abs(parseFloat(SelectedProduct.ProductsDiscounts.items[0].ProductDiscount)) / 100) *
                                                        parseFloat(SelectedProduct.ProductPrice))
                                                )}
                                            </span>

                                            <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                                ({SelectedProduct.ProductUOMText})
                                            </span>
                                        </div>

                                        <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                                            <div className="saved-amount">
                                                Save : {getSymbolFromCurrency(
                                                    SelectedProduct.ProductPriceCurrencyCode
                                                )}
                                                {CurrencyFormat(
                                                    parseFloat((Math.abs(parseFloat(SelectedProduct.ProductsDiscounts.items[0].ProductDiscount)) / 100) * parseFloat(SelectedProduct.ProductPrice))
                                                )}
                                            </div>
                                            <span className="discount-badge"
                                            // style={contextVariable.isRTL ? { marginRight: isTab ? '0rem' : '0.7rem' } : { marginLeft: isTab ? '0rem' : '0.7rem' }}
                                            >
                                                {Math.abs(parseFloat(SelectedProduct.ProductsDiscounts.items[0].ProductDiscount))}%off
                                            </span>
                                        </div>

                                    </div>
                                )}
                            </div>)}

                        {/* Does not contains Product Price */}
                        {
                            (SelectedProduct.ProductPrice === null || SelectedProduct.ProductPrice <= 0) &&
                            <div className="flex flex-column justify-content-center align-items-center"
                            >
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="This product is currently unavailable"
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={500}
                                >
                                    <span className="font-semibold text-xs md:text-xs border-round-lg py-1 px-3 mt-3"
                                        style={{ color: "var(--sidebar-color)", border: "1px solid var(--sidebar-color)" }}>
                                      {t(`Unavailable`)}
                                    </span>
                                </Tooltip>
                            </div>
                        }
                    </div>

                    {/* Inventory Section */}
                    <div>
                        {
                            (SelectedProduct.ProductPrice !== null && SelectedProduct.ProductPrice !== 0 &&
                                SelectedProduct.ProductPrice > 0 && SelectedProduct.ProductStock > 0) &&
                            <div>
                                <div className="text-xs font-semibold pt-1 border-round-sm">
                                    <span className="text-red-700  border-round-3xl " >{t(`Avl.Stock`)}: {SelectedProduct.ProductStock} {SelectedProduct.StockUom}</span>
                                </div>

                            </div>
                        }
                        {
                            (SelectedProduct.ProductPrice !== null && SelectedProduct.ProductPrice !== 0
                                && SelectedProduct.ProductPrice > 0 && SelectedProduct.ProductStock === 0) &&
                            <div className="text-base font-semibold pl-2 pr-2 pt-4 border-round-sm text-center fadein animation-duration-500">
                                <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl" >{t(`Out of Stock`)}</span>
                            </div>
                        }
                    </div>

                    {/* Add to cart Section */}
                    {(SelectedProduct.ProductPrice !== null && SelectedProduct.ProductPrice !== 0 &&
                        SelectedProduct.ProductPrice > 0 && (SelectedProduct.ProductStock === "NoLimit" || SelectedProduct.ProductStock > 0)) && <div className="flex flex-column gap-2 w-100 mt-2">

                            <div className="">
                                <div className="flex gap-3 flex-wrap ">

                                    <div onClick={handleDefaultUOMChange} style={SelectedProduct?.ChangeUOM === SelectedProduct.ProductUOM ? { fontSize: "0.7rem" } : { height: "1.8rem", fontSize: "0.7rem" }}
                                        className={`flex border-1 border-round-md justify-content-center  p-1 cursor-pointer ${SelectedProduct?.ChangeUOM === SelectedProduct.ProductUOM ? 'border-blue-500 border-2' : 'hover:border-blue-500 hover:bg-blue-50  border-gray-400 '}`}>
                                        <button
                                            className={`${SelectedProduct?.ChangeUOM === SelectedProduct.ProductUOM ? "text-blue-500 font-semibold" : "hover:text-blue-700  text-black"}`}>
                                            <span>
                                                <i className={` ${SelectedProduct?.ChangeUOM === SelectedProduct.ProductUOM ? 'pi pi-check pl-1 pr-1' : 'pl-1 '}`}
                                                    style={{ fontSize: '0.7rem' }}></i>
                                                <UOMConversion UOMCode={SelectedProduct.ProductUOM} /></span>
                                        </button>
                                    </div>

                                    {UOMDetails.map((UOMobj, index) => (
                                        <div key={index}>
                                            <UOMSection UOMobj={UOMobj} index={index}
                                                selectedData={SelectedProduct} setCount={setCount}
                                                setSelectedData={setSelectedProduct}
                                                ProductStock={SelectedProduct.ProductStock} />
                                        </div>
                                    ))}

                                </div>
                            </div>

                            <div className="mt-2 flex justify-content-center align-items-center">
                                <div className="w-50 pt-1 text-center">
                                    <input
                                        type="text"
                                        value={count === "" ? "" : count}
                                        className=" bg-gray-50 hover:bg-gray-100 focus:bg-gray-100"
                                        style={{
                                            fontSize: "14px",
                                            padding: "0px",
                                            height: '35px',
                                            textAlign: "center",
                                            outline: "none",
                                            borderRadius: '5px',
                                            width: "50px"
                                        }}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                    />
                                    <span
                                        className="mt-2 p-2"
                                        style={{ fontSize: "10px" }}
                                    >
                                        {SelectedProduct.ChangeUOM && <UOMConversion UOMCode={SelectedProduct.ChangeUOM} />}
                                        {!SelectedProduct.ChangeUOM && <UOMConversion UOMCode={SelectedProduct.ProductUOM} />}
                                    </span>
                                </div>

                                <div className="w-50">
                                    <Button rounded raised
                                        className="primary-button font-bold  w-75 text-sm"
                                        onClick={handleAddToCartCount}
                                        loading={SelectedProduct.buttonLoading}
                                    >
                                        <span className='w-100'>
                                            <AddShoppingCart></AddShoppingCart>
                                        </span>

                                    </Button>
                                </div>
                            </div>
                        </div>}
                </section>
            </Dialog>



            <div className='container'>
                <div className='CatalogCardGrid'>

                    {/* Mapping goes here */}
                    {
                        (productList.length > 0) &&
                        productList.map((filteredEl, index) => (

                            <div key={index}
                                className=''
                                style={{ height: "37vh", maxHeight: "37vh" }}>

                                <div
                                    className=' border-round-md text-center shadow-2 
                                flex flex-column justify-content-between  h-full  ' >


                                    {/* Category and favourite section */}
                                    <div style={{ position: "relative" }}>
                                        <AddedIndicate Data={filteredEl} />
                                        <div className='flex align-items-baseline gap-1 w-full text-white border-round-sm'
                                            style={{ background: 'var(--sidebar-color)' }}>
                                            <i className='ml-1 pi pi-tag' style={mobileS ? { fontSize: "8px" } : { fontSize: "12px" }}></i>
                                            <span style={mobileS ? { fontSize: "10px" } : { fontSize: "12px" }}> <TruncateText
                                                text={filteredEl.ProductCategoryName}
                                                maxLength={mobileS ? 16 : mobileM ? 21 : mobileL ? 24 : 17}
                                            /></span>

                                        </div>
                                        <div style={{ position: "absolute", top: "8px", right: "4px", zIndex: "1" }}>
                                            <div
                                                className='flex align-items-center bg-white border-circle border-1'>
                                                <FavChoose Data={filteredEl} selectedTab={props.selectedTab} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Image section*/}
                                    <div
                                        // onClick={() => handleDisplayProductDetails(filteredEl)}
                                        className=' flex flex-column'>
                                        <div className='flex justify-content-center' style={mobileS ? { height: "12vh" } : { height: "15vh" }}>
                                            <Authorizedimages ProductImage={filteredEl.ProductImage} From="MobileCard" mobileS={mobileS} />
                                        </div>
                                        {/* Description  */}
                                        <div className='text-left  pl-2 pr-1' >
                                            <span style={mobileS ? { fontSize: "11px" } : { fontSize: "14px" }}>
                                                <TruncateText
                                                    text={filteredEl.ProductName}
                                                    maxLength={mobileS ? 35 : mobileM ? 30 : mobileL ? 40 : 17}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    {/* price section */}
                                    <div
                                        // onClick={() => handleDisplayProductDetails(filteredEl)}
                                        className=''>
                                        <PriceAndButtonCard ProductDetails={filteredEl} part="PriceSection"
                                         handleDisplayProductDetails={handleDisplayProductDetails} />
                                    </div>


                                </div>
                            </div>
                        ))
                    }


                </div>

                {props.selectedTab === 0 && <div className='flex justify-content-center w-100'>
                    <div>
                        {footer}
                    </div>
                </div>}
                <div className='pb-6'>
                    {" "}
                </div>
            </div>
        </>
    )
}

export default CatalogCard;