import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AdminGridData from '../../../components/Admin/AdminGridData';
import { API } from 'aws-amplify';
import { ListByAPIErrorlogs, listLambdaGroupings } from '../../../graphql/queries';
import { showToast } from '../../../components/ToastUtils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { listContactInformations } from '../../../Layout/MutaionsAndQueries';
import FormControl from '@mui/material/FormControl';
import { Button } from 'primereact/button';

export default function ErrorLogs() {
  const { t } = useTranslation();
  const today = new Date();
  const [errorLogDetails, setErrorDetails] = useState(1);

  const [groupItems, setGroupItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({
    groupFunctions: "",
    groupName: ""
  });
  // const [defaultGroup, setDefaultGroup] = useState({});
  // const [selectedGroupLabel, setSelectedGroupLabel] = useState("");

  const [customerItems, setCustomerItems] = useState([]);
  const [selectedCustomer, setselectedCustomer] = useState({
    id: "",
    name: ""
  });
  // const [defaultCustomer, setDefaultCustomer] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getGroups = async () => {
    try {
      const response = await API.graphql({
        query: listLambdaGroupings,
        variables: {
          filter: { not: { GroupingName: { notContains: "" } } }
        }
      });

      let groupArray = (response.data.listLambdaGroupings.items).map((el, index) => ({
        functions: el.LambdaFunctions,
        label: el.GroupingName,
        value: el.GroupingName
      }))

      setGroupItems(groupArray);
      // setDefaultGroup(groupArray[0]);

      if (groupArray.length !== 0) {
        // setSelectedGroup({
        //   groupFunctions: groupArray[0].functions,
        //   groupName: groupArray[0].label
        // });
      }
      // setSelectedGroup(groupArray[0].value || '');
      // setSelectedGroupLabel(groupArray[0].label || '');
    }
    catch (error) {
      console.error("getGroups", error);
    }
  }

  const getCustomers = async () => {
    let nextToken = null;
    let CustomerDetails = [];
    do {
      try {
        const getCustomersresponse = await API.graphql({
          query: listContactInformations,
          variables:
          {
            limit: 3000,
            nextToken: nextToken,
          }
        });
        // console.log("getCustomersresponse", getCustomersresponse)

        if (getCustomersresponse.data.listContactInformations.items.length === 0 && getCustomersresponse.data.listContactInformations.nextToken === null && CustomerDetails.length === 0) {
          nextToken = null;
          setCustomerItems([]);
        }
        else {
          nextToken = getCustomersresponse.data.listContactInformations.nextToken;
          // const customerArray1 = getCustomersresponse.data.listContactInformations.items.filter((el) => {
          //   return el.CustomerName === "Shogun Management & Dev'T Corp";
          // });
          // console.log("customerArray1", customerArray1);

          const customerArray = (getCustomersresponse.data.listContactInformations.items).map((el, index) => ({
            label: el.CustomerName + "(" + el.CustomerId + ")",
            value: el.CustomerId
          }));

          CustomerDetails = CustomerDetails.concat(customerArray);
          // Create a Set to track unique labels
          const uniqueLabels = new Set();

          // Filter out duplicates based on the label
          CustomerDetails = CustomerDetails.filter((item) => {
            if (!uniqueLabels.has(item.label)) {
              uniqueLabels.add(item.label);
              return true;
            }
            return false;
          });

          // console.log(CustomerDetails);
          setCustomerItems(CustomerDetails);

          if (CustomerDetails.length !== 0) {
            // setselectedCustomer({
            //   id: CustomerDetails[0].value,
            //   name: CustomerDetails[0].label
            // });
          }
          // setselectedCustomer(CustomerDetails[0]?.value || '');
        }
      }
      catch (error) {
        console.error("Error getCustomers", error);
      }

    } while (nextToken);
    // setDefaultCustomer(CustomerDetails[0]);
    return true;
    // getErrorLogDetails();
  }

  // Group Autocomplete
  const handleGroupChange = (event, newValue) => {
    // console.log(newValue);
    if (newValue !== null) {
      setSelectedGroup({
        groupFunctions: newValue.functions,
        groupName: newValue.label
      });
      // setDefaultGroup(newValue);
    }
    else {
      setSelectedGroup({
        groupFunctions: "",
        groupName: ""
      })
    }
    // setSelectedGroup(newValue);
    // const selectedValue = event.target.value;
    // const selectedItem = groupItems.find(item => item.value === selectedValue);
    // const selectedLabel = selectedItem ? selectedItem.label : '';
    // setSelectedGroupLabel(selectedLabel || '');
  };

  // Customer Autocomplete 
  const handleCustomerChange = (event, newValue) => {
    // console.log("handleCustomerChange", event, newValue);
    if (newValue !== null) {
      setselectedCustomer({
        id: newValue.value,
        name: newValue.label
      });
      // setDefaultCustomer(newValue);
    }
    else {
      setselectedCustomer({
        id: "",
        name: ""
      });
    }
  }

  // Start Date Autocomplete
  const handleStartDateChange = (data) => {
    const formattedDate = data.format('YYYY-MM-DD');
    setStartDate(formattedDate);
  }

  // End Date Autocomplete
  const handleEndDateChange = (data) => {
    const formattedDate = data.format('YYYY-MM-DD');
    setEndDate(formattedDate);
  }

  const getStartDate = () => {
    // const startDate = dayjs();
    // const formattedDate = startDate.format('YYYY-MM-DD');
    const StartOfMonth = dayjs().startOf('month');
    const formattedDate = StartOfMonth.toISOString().replace('T', 'T').replace('Z', '000Z');
    return formattedDate;
  }

  // const getEndDate = () => {
  //   // const endOfMonth = dayjs().endOf('month');
  //   // const formattedDate = endOfMonth.toISOString().replace('T', 'T').replace('Z', '000Z');
  //   const today = new Date();
  //   const formattedDate = today
  //     .toISOString()
  //     .replace("T", "T")
  //     .replace("Z", "000Z");
  //   return formattedDate;
  // }

  const getErrorLogDetails = async () => {
    if (selectedGroup.groupName === '') {
      showToast(t(`Please select a group.`), "error", 3000);
    }
    else {
      setErrorDetails(1);
      setLoading(true);
      try {
        const inputData = {
          CustomerId: selectedCustomer.id,
          GroupingName: JSON.stringify(selectedGroup.groupFunctions),
          StartDateandTime: startDate,
          EndDateandTime: endDate,
          GroupName: selectedGroup.groupName,
        }
        // console.log("SELECTED INPUT", inputData);
        const response = await API.graphql({
          query: ListByAPIErrorlogs,
          variables: inputData
        });
        if (response.data.ListByAPIErrorlogs.length === 0) {
          setErrorDetails(0);
        }
        else {
          // console.log("RESPONSE", response);
          setErrorDetails(response.data.ListByAPIErrorlogs);
        }
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        console.error("ERRORLOGSERROR", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error",4000);
        setErrorDetails(0);
      }
    }
  }

  useEffect(() => {
    const getDates = () => {
      const startDate = dayjs().startOf('month');
      const formattedDate = startDate.format('YYYY-MM-DD');
      setStartDate(formattedDate);

      const endOfMonth = dayjs();
      const formattedEndOfMonth = endOfMonth.format('YYYY-MM-DD');
      setEndDate(formattedEndOfMonth);
    }

    const fetchData = async () => {
      try {
        await Promise.all([getGroups(), getCustomers()]);
        // await getErrorLogDetails();
      } catch (error) {
        console.error('Error fetching Error Logs data:', error);
      }
    };
    fetchData();
    getDates();
  }, []);

  useEffect(() => {
    setErrorDetails(0);
  }, [])

  const columnDefs = [
    {
      field: "APIName",
      headerName: "API Name",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "ErrorDescription",
      headerName: "Error Description ",
      filter: true,
      flex: 0.8,
      headerClass: "custom-ag-header",
      sortable: true,
      tooltipField: "ErrorDescription"
    },
    {
      field: "DateandTime",
      headerName: "Date & Time",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    },
  ]

  //set the Top bar Title
  sessionStorage.setItem("title", t("Error Logs"));

  const openOrderItemView = () => {
  };
  const selectedRowsHandler = (rows) => { };

  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    getErrorLogDetails();
  }

  return (
    <Box className="admin-box-container mt-4">
      {/* DataGrid Card  */}
      <div className="card card-shadow">
        <div className="card-body">
          <div className='flex justify-content-around align-items-center gap-2 mt-2 mb-3'>
            {/* Group */}
            <div>
              <FormControl sx={{ width: 200 }}>
                {/* <InputLabel id="group-label">Group</InputLabel> */}
                <Autocomplete
                  fullWidth
                  size="small"
                  // disableClearable
                  // value={defaultGroup}
                  onChange={handleGroupChange}
                  options={groupItems}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField variant="outlined" {...params} label="Groups" />}
                />
              </FormControl>
            </div>
            {/* Customer */}
            <div>
              <FormControl sx={{ width: 200 }}>
                {/* <InputLabel id="customer-label">Customer</InputLabel> */}
                <Autocomplete
                  fullWidth
                  size="small"
                  // value={defaultCustomer}
                  onChange={handleCustomerChange}
                  options={customerItems}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField variant="outlined" {...params} label="Customers" />}
                />
                {/* <Select
                  labelId="customer-label"
                  id="customer"
                  value={selectedCustomer}
                  onChange={handleCustomerChange}
                  label="customer"
                >
                  {customerItems.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
            </div>
            {/* Start Date */}
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="Start Date"
                  format="DD/MM/YYYY"
                  disableFuture
                  value={dayjs(getStartDate())}
                  onChange={handleStartDateChange}
                  maxDate={dayjs(today)}
                  TextFieldComponent={({ ...props }) => (
                    <div>
                      <DatePicker.TextField {...props} />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
            {/* End Date */}
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="End Date"
                  disableFuture
                  format="DD/MM/YYYY"
                  // value={dayjs(getEndDate())}
                  value={dayjs(endDate)}
                  onChange={handleEndDateChange}
                  maxDate={dayjs(today)}
                  TextFieldComponent={({ ...props }) => (
                    <div>
                      <DatePicker.TextField {...props} />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
            <div>
              <Button
                loading={loading}
                style={{ height: "2.3rem" }}
                icon="pi pi-search"
                label='Search'
                className='px-3 p-button-sm'
                onClick={handleSearch}
              />
            </div>
          </div>

          <div className='mt-2'>
            <AdminGridData
              rowData={errorLogDetails}
              columnDefs={columnDefs}
              orderView={openOrderItemView}
              component={"ErrorLogDetails"}
              tableHeight={"75vh"}
              needFilters={true}
              showPagination={true}
              selectedRows={selectedRowsHandler}
              filter={"ErrorLogDetails"}
            />
          </div>
        </div>
      </div>

    </Box>
  )
}
