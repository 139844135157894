import { Box, Tooltip, Zoom } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AdminGridData from '../../../../components/Admin/AdminGridData';
import { ListContactDetails } from '../../../../components/Admin/AdminListDetailsFunction';
import { updateContactDetails } from '../../../../components/Admin/AdminCUDFunctions';
import Loader from '../../../../components/Loader';
// import { showToast } from '../../../../components/ToastUtils';
import { AppContext } from '../../../../Layout/Layout';
import AdminDialogComponent from '../../../../components/Admin/AdminDialogComponent';

export default function ContactDetails() {
  const contextVariable = useContext(AppContext);
  const { t } = useTranslation();
  const [TotalContactDetails, setTotalConteacDetails] = useState(1);
  const [ContactLoading, setContactLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await ListContactDetails(setTotalConteacDetails);
    }
    getData();
  }, []);

  //For Hard back button click
  useEffect(() => {
    if (sessionStorage.getItem("TapPageNumber") !== "2") {
      const currentURL = window.location.href;
      if (currentURL.includes("/customerportalsetup/contactDetails")) {
        contextVariable.setTapPage(2);
        sessionStorage.setItem("TapPagNumber", 2);
      }
    }
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    if (TotalContactDetails === 0) {
      contextVariable.setShow("ContactFirstSync");
    }
    // eslint-disable-next-line  
  }, [TotalContactDetails]);

  const columnDefs = [
    // {
    //   headerName: "Line Item",
    //   field: "SNo",
    //   valueGetter: function (params) {
    //     return params.node.rowIndex + 1;
    //   },
    //   filter: true,
    //   flex: 0.1,
    //   headerClass: 'custom-ag-header',
    //   sortable: true,
    // },
    // {
    //   headerName: "Tenant ID",
    //   field: "tenant",
    //   filter: true,
    //   flex: 0.1,
    //   headerClass: 'custom-ag-header',
    // },
    {
      field: "CustomerId",
      headerName: "Customer ID",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,

    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "ContactId",
      headerName: "Contact Id",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "ContactName",
      headerName: "Full Name",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "EmailId",
      headerName: "Login Email Id",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
      tooltipField: "EmailId"
    },
    {
      field: "UpdatedEmailId",
      headerName: "Email Id",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
      tooltipField: "EmailId"
    },
    {
      field: "PhoneNumber",
      headerName: "Login Phone Number",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "UpdatedPhoneNumber",
      headerName: "Phone Number",
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "SAPStatus",
      headerName: "SAP Status",
      filter: true,
      flex: 0.1,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: (params) => {

        if (params.value === 'Active') {
          return (
            <span
              className='px-2 py-1 text-green-600 bg-green-100 border-round-md font-semibold'
            >
              Active
            </span>
          )
        }
        else {
          return (
            <span
              className='px-2 py-1 text-gray-600 bg-gray-100 border-round-md font-semibold'
            >
              Inactive
            </span>
          )
        }
      }
    },
    {
      field: "PortalStatus",
      headerName: "Login Status",
      filter: true,
      flex: 0.3,
      headerClass: "custom-ag-header",
      sortable: true,
      cellRenderer: (params) => {
        const PhoneOrEmail = Boolean(params.data.EmailId || params.data.PhoneNumber);
        return (
          <>
            {PhoneOrEmail &&
              <div className='mt-2'>
                <Tooltip componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'var(--sidebar-color)',
                      fontSize: '12px'
                    },
                  },
                }}
                  title={params.value === "Enabled" ? "Click to Disable" : "Click to Enable"}
                  placement="left"
                  TransitionComponent={Zoom}
                  enterDelay={300}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={params.value === "Enabled" ? true : false}
                      onChange={(e) => updateContactDetails(contextVariable, params.data, TotalContactDetails, setTotalConteacDetails, setContactLoading)}
                    />
                    <span className="slider"></span>
                  </label>
                </Tooltip>
              </div>
            }

          </>
        )
      }
    },
  ]

  const Refresh = () => {
    ListContactDetails(setTotalConteacDetails);
  }

  //set the Top bar Title
  sessionStorage.setItem("title", t("Contact Details"));

  const openOrderItemView = () => {
  };
  const selectedRowsHandler = (rows) => { };

  return (
    <Box className="admin-box-container">
      {
        ContactLoading &&
        <div className='ProudctTrendingChange-loading'>
          <div className='flex justify-content-center align-items-center'>
            <div style={{ marginTop: '20%' }}>
              <div className='text-center'><Loader /></div> <br />
            </div>
          </div>
        </div>

      }
      {
        contextVariable.show === "ContactFirstSync" &&
        <AdminDialogComponent
          header={t(`Info`)}
        />
      }

      {/* DataGrid Card  */}
      <div className="card card-shadow">
        <div className="card-body">
          <AdminGridData
            rowData={TotalContactDetails}
            columnDefs={columnDefs}
            orderView={openOrderItemView}
            component={"contactDetails"}
            tableHeight={"70vh"}
            needFilters={true}
            showPagination={true}
            selectedRows={selectedRowsHandler}
            filter={"contactDetails"}
            Refresh={Refresh}
          // customProductIDFilter={customProductIDFilter}
          />
        </div>
      </div>
    </Box>
  )
}
