import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import { Box, Tooltip, Zoom, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from '../theme';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './GridData.css';
import { DownloadOutlined, InfoRounded, PictureAsPdfOutlined } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import NoRowsToDisplay from './NoRowsToDisplay';
import { AppContext } from '../Layout/Layout';
import InfoBar from './InfoBar';

const GridData = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const gridRef = useRef();
    const [gridApi, setGridApi] = useState(null);
    // const [currentPage, setCurrentPage] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const currentYear = new Date().getFullYear();
    const [RowData, setRowData] = useState([]);
    const [showPayButton, setShowPayButton] = useState(false);

    const initialDateRef = useRef(contextVariable.date);
    useEffect(() => {
        sessionStorage.setItem("date", initialDateRef.current);
    }, [initialDateRef]); //Dependency Added

    const isTab = useMediaQuery('(max-width: 768px)');


    useEffect(() => {

        if (props.rowData === 1) {
            setRowData(false)
        }
        else if (props.rowData === 0) {
            // setRowData([])
            setRowData("NoData")
        }
        else if (props.rowData.length > 0) {
            if (contextVariable.isFiltered === true && props.needFilters === true && contextVariable.globalSearch !== '') {
                setRowData(contextVariable.filteredData);

            }
            else {
                setRowData(props.rowData);
                if (contextVariable.isFiltered === false && contextVariable.globalSearch !== '' && props.needFilters === true) {
                    gridApi.setQuickFilter(contextVariable.globalSearch);
                    const displayedRowData = gridApi.getModel().rowsToDisplay.map((rowNode) => rowNode.data);
                    if (displayedRowData.length > 0) {
                        contextVariable.setFilteredData(displayedRowData);
                        contextVariable.setIsFiltered(true);
                    }


                }
                else if (contextVariable.globalSearch === '' && props.needFilters === true) {
                    contextVariable.setFilteredData(props.rowData)
                }
            }
        }



    }, [props.rowData, RowData, contextVariable, gridApi, props.needFilters])

    // useEffect(() => {
    //     if (contextVariable.isLoading == true) {
    //         setReRenderGrid(true);
    //     }
    // }, [contextVariable.isLoading]);

    const gridReadyAPI = (e) => {
        setGridApi(e.api);
        // setCurrentPage(e.api.paginationGetCurrentPage());
    }

    const dates = [
        { label: String(currentYear), year: currentYear },
        { label: String(currentYear - 1), year: currentYear - 1 },
        { label: String(currentYear - 2), year: currentYear - 2 },
        { label: String(currentYear - 3), year: currentYear - 3 },
        { label: String(currentYear - 4), year: currentYear - 4 },
        { label: String(currentYear - 5), year: currentYear - 5 },
        { label: String(currentYear - 6), year: currentYear - 6 },
        { label: String(currentYear - 7), year: currentYear - 7 },
        { label: String(currentYear - 8), year: currentYear - 8 },
        { label: String(currentYear - 9), year: currentYear - 9 },
        { label: String(currentYear - 10), year: currentYear - 10 },
        { label: String(currentYear - 11), year: currentYear - 11 },
        { label: String(currentYear - 12), year: currentYear - 12 },
        { label: String(currentYear - 13), year: currentYear - 13 },
        { label: String(currentYear - 14), year: currentYear - 14 },
        { label: String(currentYear - 15), year: currentYear - 15 }
    ];

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        // floatingFilter: true,
        flex: 1,
        resizable: true,
        minWidth: 100,
        sortingOrder: ['desc', 'asc'],
        filterParams: {
            maxNumConditions: 1,
        },
    }), []);

    const cellClickedListener = useCallback(event => {
        // if (props.component !== 'Invoice') {
        //     let copyText = event.value;
        //     navigator.clipboard.writeText(copyText)
        //         .then(() => {
        //             showToast(`"${copyText}" is copied to your clipboard`, 'info');
        //         })
        //         .catch((error) => {
        //             showToast(`Cannot copy the cell value...${error}`, 'error');
        //         })
        // }
    }, []);

    const rowClickListener = (event) => {
        if (props.component === 'delivery') {
            event.data.selectedYear = selectedDate;
            contextVariable.setRootObject(event.data);
            props.deliveryView();
        }
        else if (props.component === 'order') {
            event.data.selectedYear = selectedDate;
            contextVariable.setRootObject(event.data);
            props.orderView();
        }
        else if (props.component === 'Invoice') {
            event.data.selectedYear = selectedDate;
            contextVariable.setRootObject(event.data);
            props.InvoiceView();
        }
        else if (props.component === 'creditNote') {
            event.data.selectedYear = selectedDate;
            contextVariable.setRootObject(event.data);
            props.CreditNoteView();
        }
        else if (props.component === 'paymentHistory') {
            // event.date.selectedYear =selectedDate;
            contextVariable.setRootObject(event.data);
            props.PaymentHistoryView();
        }
    }
    const exportData = useCallback(() => {
        // const utterance = new SpeechSynthesisUtterance("Downloading CSV");
        // window.speechSynthesis.speak(utterance);
        const columnDefs = gridRef.current.api.getColumnDefs();
        const columnNames = columnDefs
            .filter(column => !column.hide)
            .map(column => column.field);

        // Generating current date and time
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        // const seconds = currentDate.getSeconds();
        const fullDateTime = `T${hours < 10 ? '0' + hours : hours}H${minutes < 10 ? '0' + minutes : minutes}M`;
        const selectedYear = selectedDate;

        const csvParams = {
            skipHeader: false,
            skipFooters: true,
            skipGroups: true,
            fileName: `${props.component}_${selectedYear}_${fullDateTime}.csv`,
            columnKeys: columnNames.filter(key => key !== 'preview'),
        };
        gridRef.current.api.exportDataAsCsv(csvParams);
        // eslint-disable-next-line 
    }, [selectedDate]);

    // if (props.orderInput !== '') {
    //     gridApi.setQuickFilter(props.orderInput);
    // }
    // useEffect(() => {
    //     const defaultValue = contextVariable.globalSearch ? contextVariable.globalSearch : "";

    //     if (RowData.length> 0) {

    //         setRowData()
    //     }
    // }, [RowData]);

    const handleSearch = (event) => {
        gridApi.setQuickFilter(event);
        // gridRef.current.api.setGridOption(
        //     "quickFilterText",
        //    event
        //   );
        contextVariable.setGlobalSearch(event);
        const displayedRowData = gridApi.getModel().rowsToDisplay.map((rowNode) => rowNode.data);
        if (displayedRowData.length === 0) {
            contextVariable.setFilteredData("NoData");

            contextVariable.setIsFiltered(true);
        }
        else {
            contextVariable.setFilteredData(displayedRowData);
            contextVariable.setIsFiltered(true);
        }

    }

    const RollBackData = () => {
        if (props.rowData) {
            setRowData(props.rowData)
        }
    }



    const handleSelectionChanged = (event) => {
        // console.log("SelectedRow", event.api.getSelectedNodes())
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map(
            (node) => node.data);
        if (selectedData.length !== 0) {
            setShowPayButton(true);
            // contextVariable.setInvoicesToPay(selectedData);
            props.selectedRows(selectedData);
        }
        if (selectedData.length === 0) {
            setShowPayButton(false);
        }
        // else {
        //     setShowPayButton(false);
        // }
    };

    const [GotoPaymentLoading, setGotoPaymentLoading] = useState(false);
    const handlepay = async () => {
        setGotoPaymentLoading(true);
        await props.handlepay();
        setGotoPaymentLoading(false);
    }

    const customTheme = {
        components: {
            dropdown: {
                focusBorderColor: '#ffffffff', // Change the color as per your requirement
            },
        },
    };

    const gridOptions = {
        suppressRowClickSelection: true,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        animateRows: true,
        onRowClicked: (event) => {

            // if (props.component === 'Invoice') {
            //     // const selectedRows = gridOptions.api.getSelectedNodes();
            //     const node = event.node;

            //     if (node.isSelected()) {
            //         // Deselect the row and checkbox
            //         node.setSelected(false);
            //     } else {
            //         // Select the row and checkbox
            //         node.setSelected(true);
            //     }
            // }

        },
        getRowClass: (params) => {
            // Apply the "rounded-row" class to each row
            const isSelected = params.node.isSelected();
            return `rounded-row ${isSelected ? 'custom-selected-row' : ''}`;
        },
    };

    const handleDateChange = (e) => {
        sessionStorage.setItem("date", e.value.year);
        setSelectedDate(e.value.year);
        // contextVariable.setGlobalData(null);
        contextVariable.setGlobalSearch(contextVariable.globalSearch)
        // contextVariable.setFilteredData([]);
        contextVariable.setIsFiltered(false);
        contextVariable.setDate(e.value.year);
        setShowPayButton(false)
    };

    const handleDownloadPDF = () => {
        contextVariable.setCallDownloadPDF({
            isDataLoaded: true,
            executeDownloadPDFFunction: true
        });
    }





    const CSVButton = <Tooltip
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: 'var(--sidebar-color)',
                    fontSize: '12px'
                },
            },
        }}
        title="Download as CSV file"
        placement="bottom"
        TransitionComponent={Zoom}
        enterDelay={300}
    >
        <span>
            <Button
                disabled={!RowData || RowData === "NoData"}
                className="primary-button p-button-sm "
                onClick={exportData}
            >
                <DownloadOutlined className='customIcon' />
                <span className='hide-in-mobileview'>CSV</span>
            </Button>
        </span>
    </Tooltip>;

    const RefreshButton = <Button label="Refresh" loading={!RowData} icon="pi pi-refresh" onClick={props.RefreshFn}></Button>
    const DataLoader = RowData === "NoData" ? NoRowsToDisplay : Loader;
    return (
        <>
            {contextVariable.pageInfo === "Order" && <InfoBar header={t(`Order`)}></InfoBar>}
            {contextVariable.pageInfo === "Delivery" && <InfoBar header={t(`Delivery`)}></InfoBar>}
            {contextVariable.pageInfo === "Invoice" && <InfoBar header={t(`Invoice & Payments`)}></InfoBar>}
            {contextVariable.pageInfo === "CreditNote" && <InfoBar header={t(`CreditNote`)}></InfoBar>}
            {contextVariable.pageInfo === "PaymentHistory" && <InfoBar header={t(`Payment History`)}></InfoBar>}
            <Box
                sx={{
                    "& .ag-theme-alpine .ag-root-wrapper, .ag-theme-alpine": {
                        border: 'none'
                    },
                    "& .ag-theme-alpine .ag-header-cell, .ag-header": {
                        backgroundColor: 'white',
                        color: 'black',
                        fontWeight: 'none'
                    },
                    "& .ag-theme-alpine .ag-root-wrapper": {
                        background: colors.primary[400]
                    },
                    "& .ag-theme-alpine .ag-row": {
                        color: colors.grey[100],
                        background: 'white',
                        borderBottomColor: colors.grey[100]
                    },
                    "& .ag-theme-alpine .my-cell-class": {
                        border: '1px solid black'
                    },
                }}
            >

                {/* Order Filter */}
                {
                    props.filter === 'Order' ? (
                        <div className="fadeindown animation-duration-100 flex justify-content-between align-items-center mb-1">
                            <div>
                                <Dropdown
                                    disabled={!RowData}
                                    value={selectedDate}
                                    options={dates}
                                    onChange={handleDateChange}
                                    placeholder={contextVariable.date}
                                    className="YearDropDown"
                                    theme={customTheme}
                                />
                            </div>

                            {/* <span className="p-input-icon-left order-searchbar" */}
                            <span className="p-input-icon-left GeneralSearchBar">
                                <i className="pi pi-search" />
                                <InputText
                                    type='search'
                                    disabled={!RowData}
                                    value={contextVariable.globalSearch}
                                    autoFocus
                                    className='SearchInput'
                                    // className="p-inputtext-sm block primary-input-text"
                                    // placeholder="Product, Order ID, Reference"
                                    placeholder={`${t('Product ID')} ${t('Order ID')} ${t('Reference No')}`}
                                    // style={{ width: '100%', height: "35px", textAlign: "center", borderRadius: "5px" }}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            RollBackData();
                                        }
                                    }}
                                />
                            </span>

                            <div>
                                {CSVButton}
                            </div>

                            <div>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("Order")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg lg:py-1 lg:px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='customIcon text-gray-400' />
                                        <span className='hide-in-mobileview'>{t('Info')}</span>
                                    </div>

                                </Tooltip>
                            </div>

                        </div>) :
                        ''
                }

                {/* Delivery Filter */}
                {
                    props.filter === 'Delivery' ? (
                        <div className="fadeindown animation-duration-100 flex justify-content-between align-items-center mb-1">
                            <div>
                                <Dropdown
                                    disabled={!RowData}
                                    value={selectedDate}
                                    options={dates}
                                    onChange={handleDateChange}
                                    // placeholder={selectedDate === currentYear ? "This Year" : selectedDate}
                                    placeholder={contextVariable.date}
                                    className="YearDropDown"
                                    theme={customTheme}
                                />

                            </div>

                            <span className="p-input-icon-left GeneralSearchBar">
                                <i className="pi pi-search" />
                                <InputText
                                    autoFocus
                                    type='search'
                                    disabled={!RowData}
                                    value={contextVariable.globalSearch}
                                    className="SearchInput"
                                    // placeholder="Product, Order No, Reference"
                                    placeholder={`${t('Product ID')}, ${t('Delivery ID')}, ${t('Tracking No')}`}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            RollBackData();
                                        }
                                    }}
                                />

                            </span>

                            {/* <Button className='secondary-button-outlined' onClick={clearFilters}>Clear Filters</Button> */}

                            <div>
                                {CSVButton}
                            </div>

                            <div>
                                {/* <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("Delivery")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg lg:py-1 lg:px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='customIcon text-gray-400' />
                                        <span className='hide-in-mobileview'>Info</span>
                                    </div>

                                </Tooltip> */}
                            </div>
                        </div>) : ''
                }

                {/* Invoice Filter */}
                {
                    props.filter === 'Invoice' ? (
                        <div className="fadeindown animation-duration-100 InvoiceTopLine ">

                            <div className='flex flex-column align-items-center gap-1'>
                                <div><p className='YearLabelSpan'>{t('Financial Year')}</p></div>
                                <div><Dropdown
                                    value={selectedDate}
                                    disabled={!RowData}
                                    options={dates}
                                    onChange={handleDateChange}
                                    placeholder={contextVariable.date}
                                    className="YearDropDown"
                                    theme={customTheme}
                                /></div>
                            </div>

                            <div className='InvoiceSearchDiv' >
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText
                                        type='search'
                                        disabled={!RowData}
                                        value={contextVariable.globalSearch}
                                        autoFocus
                                        className="InvoiceSearchInput"
                                        // placeholder="Invoice ID, Reference"
                                        placeholder={`${t('Invoice ID')}, ${t('Reference No')}, ${t('Status')}`}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Backspace') {
                                                RollBackData();
                                            }
                                        }}
                                    />
                                </span>
                            </div>

                            <div>
                                {CSVButton}
                            </div>

                            <div>
                                {
                                    (contextVariable.taxDetails.StripeDefaultIndicator === "true") &&
                                    <>
                                        <Tooltip
                                            title={showPayButton ? "" : "Select atleast 1 invoice to go to payment page"}
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'var(--sidebar-color)',
                                                        fontSize: '12px'
                                                    },
                                                },
                                            }}
                                            placement="bottom"
                                            TransitionComponent={Zoom}
                                            enterDelay={500}
                                        >
                                            <span>
                                                <Button
                                                    outlined
                                                    disabled={!showPayButton ? true : false}
                                                    className={!showPayButton ? 'secondary-button-outlined p-button-sm PayButton' :
                                                        'secondary-button p-button-sm  PayButton'}
                                                    onClick={handlepay}
                                                    label={t('Go to payment')}
                                                    icon="pi pi-arrow-right"
                                                    loading={GotoPaymentLoading}
                                                >
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </>
                                }
                            </div>



                            <div>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("Invoice")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg lg:py-1 lg:px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='customIcon text-gray-400' />
                                        <span className='hide-in-mobileview'>{t('Info')}</span>
                                    </div>

                                </Tooltip>
                            </div>

                        </div>) : ''
                }

                {/* CreditNote Filter */}
                {
                    props.filter === 'CreditNote' ? (
                        <div className="fadeindown animation-duration-100 CreditNoteTopLine ">

                            <div className='flex flex-column align-items-center gap-1'>
                                <div><p className='YearLabelSpan'>Calendar Year</p></div>
                                <div>   <Dropdown
                                    value={selectedDate}
                                    disabled={!RowData}
                                    options={dates}
                                    onChange={handleDateChange}
                                    placeholder={contextVariable.date}
                                    className="YearDropDown"
                                    theme={customTheme}
                                /></div>
                            </div>

                            <div className='CreditNoteSearchDiv'>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText
                                        type='search'
                                        disabled={!RowData}
                                        value={contextVariable.globalSearch}
                                        autoFocus
                                        className="CreditNoteSearchInput"
                                        // placeholder="Credit, Reference Number, Status"
                                        placeholder={`${t('Credit Note ID')}, ${t('Reference No')}`}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Backspace') {
                                                RollBackData();
                                            }
                                        }}
                                    />
                                </span>
                            </div>

                            <div>
                                {CSVButton}
                            </div>

                            <div>
                                {/* <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("CreditNote")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg lg:py-1 lg:px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='customIcon text-gray-400' />
                                        <span className='hide-in-mobileview'>Info</span>
                                    </div>

                                </Tooltip> */}
                            </div>
                        </div>) : ''
                }

                {/* payment History Filter */}
                {
                    props.filter === 'PaymentHistory' ? (
                        <div className="fadeindown animation-duration-100 flex justify-content-between align-items-center mb-1">


                            <div>

                            </div>

                            <span className="w-50 p-input-icon-left GeneralSearchBar">
                                <i className="pi pi-search " />
                                <InputText
                                    autoFocus
                                    type='search'
                                    className="SearchInput"
                                    // placeholder="Invoice ID"
                                    placeholder={`${t('Transaction Reference No')}, ${t('Status')}`}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </span>

                            <div>
                                {RefreshButton}
                            </div>
                            <div>
                                {CSVButton}
                            </div>

                            <div>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="Page Info"
                                    placement="left"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <div
                                        onClick={() => contextVariable.setPageInfo("PaymentHistory")}
                                        className='cursor-pointer border-1 border-secondary-800 border-round-lg lg:py-1 lg:px-2 flex justify-content-center gap-1 align-items-center'>
                                        <InfoRounded className='text-base text-gray-400' />
                                        <span className='hide-in-mobileview'>{t('Info')}</span>
                                    </div>

                                </Tooltip>
                            </div>
                        </div>) : ''
                }

                {/* Account Statement */}
                {
                    props.filter === 'accountStatement' ? (
                        <div className="fadeindown animation-duration-100 flex justify-content-between align-items-center mb-1">
                            {/* <div>
                            <span className='text-sm md:text-base font-semibold'>Year of Transaction Type : </span>
                            <Dropdown
                                value={selectedDate}
                                style={{ height: "35px", borderRadius: "5px" }}
                                options={dates}
                                onChange={(e) => {
                                    setSelectedDate(e.value);
                                    contextVariable.setGlobalData(null);
                                    contextVariable.setGlobalSearch("");
                                    contextVariable.setDate(e.value.year);
                                }}
                                placeholder={contextVariable.date}
                                className="w-full md:w-10rem custom-dropdown"
                                theme={customTheme}
                            />
                        </div> */}

                            <div>
                                {/* <Button onClick={openPdfPreview} className='primary-button p-button-sm'>Preview</Button> */}
                                <Tooltip
                                    title="Download statement as PDF file"
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    placement="bottom"
                                    TransitionComponent={Zoom}
                                    enterDelay={500}
                                >
                                    <div>
                                        <Button className='primary-button p-button-sm mr-2'
                                            disabled={!(contextVariable.callDownloadPDF.isDataLoaded)}
                                            onClick={handleDownloadPDF}
                                        >
                                            <PictureAsPdfOutlined />
                                            <span className='ml-1 hide-in-mobileview'>PDF</span>
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                            {
                                props.rowData.length > 0 && <span
                                    className='px-2 py-1 text-primary-600 bg-primary-100 border-round-md font-semibold'
                                >
                                    No.of transactions : {props.rowData.length || 0}
                                </span>
                            }

                            <div>
                                {CSVButton}
                            </div>
                        </div>) : ''
                }

                {/* AGGrid Section */}
                <div className='ag-theme-alpine mt-2' style={{ width: '100%', height: props.tableHeight }}>
                    <div style={{ width: '100%', height: '100%' }}>

                        <AgGridReact
                            enableRtl={contextVariable.rtl ? true : false}
                            rowHeight={isTab ? 40 : 50}
                            onGridReady={gridReadyAPI}
                            ref={gridRef}
                            rowData={RowData}
                            columnDefs={props.columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection='multiple'
                            onCellClicked={cellClickedListener}
                            onRowDoubleClicked={rowClickListener}
                            suppressCellSelection={true}
                            pagination={props.showPagination}
                            paginationPageSize={20}
                            onSelectionChanged={handleSelectionChanged}
                            gridOptions={gridOptions}
                            noRowsOverlayComponent={DataLoader}
                            includeHiddenColumnsInQuickFilter={true}
                            tooltipShowDelay={500}
                            tooltipHideDelay={9000}
                        />
                    </div>
                </div>
            </Box>
            {/* {
                !props.component && <div className='d-flex justify-content-between gap-1 mt-3 pt-2'>
                    <button className='btn btn-sm' style={{ background: 'lightgray' }} onClick={goToPreviousPage}><NavigateBeforeOutlined /> Previous</button>
                    <button className='btn btn-sm' style={{ background: 'lightgray' }} onClick={goToNextPage}>Next <NavigateNextOutlined /></button>
                </div>
            } */}
        </>
    )
}

export default GridData;