import React, { useState, useEffect, useContext } from 'react';
import jsPDF from "jspdf";
// import 'jspdf/dist/jspdf.umd';
import autoTable from 'jspdf-autotable'
import { AppContext } from '../../Layout/Layout';
import { Tooltip, Zoom } from '@mui/material';
import CurrencyFormat from '../../components/CurrencyFormat';
import Loader from '../../components/Loader';
import { Dropdown } from 'primereact/dropdown';
import { Storage } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

const FormComponent = (props) => {
    const { t } = useTranslation();
    const FormComponentValue = props.FormComponentValue;
    const contextVariable = useContext(AppContext);
    const isMobile = contextVariable.isMobile;
    const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
    const [PdfData, setPdfData] = useState([]);
    const [dateRange, setDateRange] = useState(new Date().getFullYear() + "-" + (new Date().getFullYear() + 1));
    const [imageUrl, setImageUrl] = useState('');
    const year = contextVariable.date;
    const currentYear = new Date().getFullYear();
    const [imgAsBase64, setImgAsBase64] = useState('');
    // const imgUrl = 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Push_van_cat.jpg';
    // const imgUrl = 'https://employee-timessheet.s3.amazonaws.com/Add+to+cart.png';

    const dates = [
        { label: String(currentYear), year: currentYear },
        { label: String(currentYear - 1), year: currentYear - 1 },
        { label: String(currentYear - 2), year: currentYear - 2 },
        { label: String(currentYear - 3), year: currentYear - 3 },
        { label: String(currentYear - 4), year: currentYear - 4 },
        { label: String(currentYear - 5), year: currentYear - 5 },
        { label: String(currentYear - 6), year: currentYear - 6 },
        { label: String(currentYear - 7), year: currentYear - 7 },
        { label: String(currentYear - 8), year: currentYear - 8 },
        { label: String(currentYear - 9), year: currentYear - 9 },
        { label: String(currentYear - 10), year: currentYear - 10 },
        { label: String(currentYear - 11), year: currentYear - 11 },
        { label: String(currentYear - 12), year: currentYear - 12 },
        { label: String(currentYear - 13), year: currentYear - 13 },
        { label: String(currentYear - 14), year: currentYear - 14 },
        { label: String(currentYear - 15), year: currentYear - 15 }
    ];

    // useEffect(() => {
    //     const today = new Date();
    //     const currentYear = today.getFullYear();

    //     if (year === currentYear) {
    //         const start = new Date(year, 0, 1);
    //         setDateRange(`${formatDate(start)} to ${formatDate(today)}`);
    //     } else {
    //         const start = new Date(year, 0, 1);
    //         const end = new Date(year, 11, 31);
    //         setDateRange(`${formatDate(start)} to ${formatDate(end)}`);
    //     }
    // }, [year]);

    useEffect(() => {
        if (contextVariable.callDownloadPDF.executeDownloadPDFFunction) {
            downloadPDF();
        }
        //eslint-disable-next-line
    }, [contextVariable.callDownloadPDF]);

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    useEffect(() => {
        if (props.CustomerStatementsOfAccountsDetails.length > 0) {
            setPdfData(props.CustomerStatementsOfAccountsDetails)
        }
        else {
            setPdfData([])
        }
        //eslint-disable-next-line
    }, [FormComponentValue])

    const getLogo = async () => {
        if (contextVariable.taxDetails && contextVariable.taxDetails.Logo) {
            const imageKey = contextVariable.taxDetails.Logo.split("public/")[1];
            const authorizedUrl = await Storage.get(imageKey);
            // console.log("AUTHORIZED URL", authorizedUrl);
            setImageUrl(authorizedUrl);
        }
    }

    useEffect(() => {
        getLogo();
    },)

    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    useEffect(() => {
        toDataURL(imageUrl, (data) => {
            setImgAsBase64(data);
        });
    }, [imageUrl]);


    // Webview data transfer
    const sendMessageToNative = async (base64String) => {

        const NotifyObject = {
            Message: "PDFDownload",
            data: base64String
        }

        if (window.ReactNativeWebView) {
            await window.ReactNativeWebView.postMessage(JSON.stringify(NotifyObject));
        } else {
            console.log("window.ReactNativeWebView is not available. Cannot send message to native app.");
        }
    }

    // PDF generation
    const downloadPDF = () => {
        const doc = new jsPDF();
        const defaultFontSize = 12;
        const maxImageWidth = 45; // Maximum width for the image
        const maxImageHeight = 15; // Maximum height for the image
        const xPosition = 10;
        const yPosition = 2;

        doc.addImage(imgAsBase64, 'PNG', xPosition, yPosition, maxImageWidth, maxImageHeight);

        doc.setFontSize(defaultFontSize);

        // Buyer Section
        doc.setFontSize(14);
        doc.setFont("bold");
        doc.text("Buyer", 85, 10, null, null, "left");
        doc.setFont("normal");
        doc.setFontSize(10);
        doc.text(FormComponentValue.BuyerName, 85, 15, null, null, "left");

        // Seller Section (8px)
        doc.setFontSize(14);
        doc.setFont("bold");
        doc.text("Seller", 200, 10, null, null, "right");
        doc.setFont("normal");
        doc.setFontSize(10);

        const sellerAddress = FormComponentValue.SellerAddress;

        // Check if sellerAddress is defined before trying to split
        if (sellerAddress) {
            const addressParts = sellerAddress.split(',');

            // Display each part using doc.text
            for (let i = 0; i < addressParts.length; i++) {
                const yPos = 15 + i * 4;
                doc.text(addressParts[i].trim(), 200, yPos, null, null, "right");
            }
        } else {
            console.error("Seller address is undefined");
        }

        // doc.text("Zyiker", 200, 15, null, null, "right");
        // doc.setFontSize(8); 
        // doc.text("23,Kelly Drive", 200, 19, null, null, "right");
        // doc.text("Charieston Wv 25302", 200, 23, null, null, "right");
        // doc.text("U S A", 200, 26, null, null, "right");

        doc.setFontSize(14);
        doc.text("Statement Period", 200, 42, null, null, "right");
        doc.setFontSize(10);
        // doc.text("_______________________", 200, 43, null, null, "right");
        doc.text(dateRange.toString(), 200, 47, null, null, "right");
        // doc.text("_______________________", 200, 50, null, null, "right");

        // doc.text("-------------------------------------------------------------------------------",200, 42, null, null, "right");
        doc.setFontSize(12);
        doc.setFont("bold");
        doc.text("Account Summary", 200, 59, null, null, "right");
        doc.setFontSize(10);
        doc.setFont("normal");
        doc.text("________________________________________________", 200, 60, null, null, "right");
        doc.text("Opening Balance", 120, 65, null, null, "left");
        doc.text(CurrencyFormat(FormComponentValue.OpeningBalance.toFixed(2)).toString(), 200, 65, null, null, "right");
        doc.text("Invoice Amount", 120, 70, null, null, "left");
        doc.text(CurrencyFormat(FormComponentValue.InvoiceAmount.toFixed(2)).toString(), 200, 70, null, null, "right");
        doc.text("Amount Paid", 120, 75, null, null, "left");
        doc.text(CurrencyFormat(FormComponentValue.PaymentAmount.toFixed(2)).toString(), 200, 75, null, null, "right");
        doc.text("________________________________________________", 200, 76, null, null, "right");
        doc.setFontSize(11);
        doc.text("Balance Due", 120, 82, null, null, "left");
        doc.text(CurrencyFormat(FormComponentValue.ClosedBalance.toFixed(2)).toString(), 200, 82, null, null, "right");
        doc.setFontSize(10);
        doc.text("________________________________________________", 200, 84, null, null, "right");
        //    -----------------------------------------------------

        const sampleData = [
            { HeaderName: 'Date', field: 'Date' },
            { HeaderName: 'Transaction', field: 'Transaction' },
            // { HeaderName: 'Details', field: 'Details' },
            { HeaderName: 'Due Date', field: 'DueDate' },
            { HeaderName: 'Reference Number', field: 'ExternalReference' },
            { HeaderName: 'Transaction Amount', field: 'Amount' },
        ];
        const sourceData = PdfData;
        // Create an empty array to hold the data rows
        const rows = [];

        // rows.push(columnDefs.map(item => item.headerName));
        rows.push(sampleData.map(item => item.HeaderName));
        // You may have your own data source. Assuming you have a dataSource array:

        // // Push each data row into the rows array
        // dataSource.forEach(row => {
        //     rows.push(columnDefs.map(item => row[item.field]));
        // });
        // Push each data row into the rows array
        sourceData.forEach(row => {
            rows.push(sampleData.map(item => row[item.field]));
        });
        // Set table options (optional)
        const options = {
            theme: 'grid', // Other themes: 'striped', 'plain'
            styles: { overflow: 'linebreak' }, // Handles text overflow by breaking the text into multiple lines
            // Other available overflow values:
            // styles: { overflow: 'visible' }, // Allows text overflow beyond cell boundaries
            // styles: { overflow: 'hidden' }, // Clips text that exceeds cell boundaries
            // styles: { overflow: 'ellipsize' }, // Adds an ellipsis to the end of the text if it overflows the cell
            // styles: { overflow: 'break-word', columnWidth: 'auto' }, // Allows the text to break and wrap to the next line if it exceeds the cell width
            // headStyles: { fillColor: [228, 76, 68] }, // Header background color
            headStyles: { fillColor: [52, 73, 85] }, // Header background color
            columnStyles: { 5: { halign: 'right' } }, // Center-align the 'Transaction' column (index 1)
        };

        autoTable(doc, {
            head: [rows[0]],
            body: rows.slice(1),
            startY: 90,
            startX: 80,
            ...options,
            tableWidth: 185,
        });


        const base64String = doc.output('datauristring');
        const parts = base64String.split(',');
        if (parts.length < 2) {
            throw new Error('Invalid data URI string');
        }
        sendMessageToNative(parts[1])
        // console.log(parts[1]);

        // Save or display the PDF
        doc.save(`Statement of ${contextVariable.date}.pdf`);
        // -------------------------------------------------------------
    }

    const customTheme = {
        components: {
            dropdown: {
                focusBorderColor: '#ffffffff',
            },
        },
    };

    return (
        <>
            {
                !isMobile ?
                    <>
                        <div className='mb-2'>
                            {/* <span className='text-xs md:text-base font-semibold'>Year of Transactions : </span> */}
                            <span className='text-xs md:text-base font-semibold'>Financial Year : </span>
                            <Dropdown
                                value={selectedDate}
                                options={dates}
                                onChange={(e) => {
                                    console.log("e", e)
                                    setSelectedDate(e.value);
                                    setDateRange(e.value.year + "-" + (e.value.year + 1))
                                    contextVariable.setGlobalData(null);
                                    contextVariable.setGlobalSearch("");
                                    contextVariable.setDate(e.value.year);
                                    contextVariable.setCallDownloadPDF({
                                        isDataLoaded: false,
                                        executeDownloadPDFFunction: false
                                    });
                                    FormComponentValue.SellerID = '';
                                }}
                                className='SOADropdown'
                                placeholder={contextVariable.date}
                                theme={customTheme}
                            />
                        </div>
                        {
                            FormComponentValue.SellerID !== '0' &&
                            <div className='card-shadow block w-full border-round-xl bg-white text-left shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]'>
                                <div className="container">
                                    <div className="row p-2">
                                        {
                                            FormComponentValue.SellerID === '' &&
                                            <div className='flex justify-content-center align-items-center' style={{ height: '10vh' }}>
                                                <Loader />
                                            </div>
                                        }

                                        {(FormComponentValue.SellerID !== "" && FormComponentValue.SellerID !== "0") && <>
                                            <div className='flex justify-content-between align-items-top flex-wrap'>
                                                {/* Buyer Details */}
                                                <div>
                                                    <div className='mb-1'>
                                                        <span className='text-base md:text-lg font-semibold mb-2'>
                                                            {t('Buyer')}
                                                        </span>
                                                    </div>
                                                    <div className='text-sm pb-1'>
                                                        {FormComponentValue.BuyerName}
                                                    </div>
                                                    {/* Statement heading */}
                                                    <div className='mb-1' >
                                                        <span className='text-base md:text-lg font-semibold'>
                                                            {t('Statement Period')}
                                                        </span>
                                                    </div>

                                                    {/* Date range */}
                                                    <div className='mb-1'>
                                                        <span className='text-sm mb-1'>
                                                            {dateRange}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* Seller Address */}
                                                <div>
                                                    <div className='mb-1' >
                                                        <span className='text-base md:text-lg font-semibold mb-2'>
                                                            {t('Seller Address')}
                                                        </span>
                                                    </div>
                                                    <div className='text-sm pb-1'>
                                                        {FormComponentValue.SellerAddress.split(',').map((part, index) => (
                                                            <div key={index}>{part.trim()}</div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div>
                                                    {/* Account Suummary */}
                                                    <div className='flex text-left'>
                                                        <span className='text-lg font-semibold mb-2'>
                                                            {t('Account Summary')}
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-content-end gap-3">
                                                        <div className="text-right">
                                                            <div className="text-xs md:text-sm font-semibold pb-1">{t('Opening Balance')} :</div>
                                                            <div className="text-xs md:text-sm font-semibold pb-1">{t('Invoice Amount')} :</div>
                                                            <div className="text-xs md:text-sm font-semibold pb-1 w-max">{t('Credit/Payment Amount')} :</div>
                                                            <div className="text-xs md:text-sm font-semibold pb-1">{t('Balance Amount')} :</div>
                                                        </div>
                                                        <div>
                                                            <div className="flex gap-3 justify-content-between align-items-baseline text-sm">
                                                                <span className="text-xs md:text-sm font-semibold flex-1 text-right pb-1">
                                                                    {CurrencyFormat(FormComponentValue.OpeningBalance)}
                                                                </span>
                                                                <span className='text-xs md:text-sm'>
                                                                    {contextVariable.taxDetails.CurrencyCode}
                                                                </span>
                                                            </div>

                                                            <div className="flex gap-3 justify-content-between align-items-baseline text-sm">
                                                                <span className="text-xs md:text-sm font-semibold flex-1 text-right pb-1">
                                                                    {CurrencyFormat(FormComponentValue.InvoiceAmount)}
                                                                </span>
                                                                <span className='text-xs md:text-sm'>
                                                                    {contextVariable.taxDetails.CurrencyCode}
                                                                </span>
                                                            </div>
                                                            <div className='flex gap-3 justify-content-between align-items-baseline text-sm'>
                                                                <span className="text-xs md:text-sm font-semibold flex-1 text-right pb-1">
                                                                    {CurrencyFormat(FormComponentValue.PaymentAmount)}
                                                                </span>
                                                                <span className='text-xs md:text-sm'>
                                                                    {contextVariable.taxDetails.CurrencyCode}
                                                                </span>
                                                            </div>
                                                            <div className='flex gap-3 justify-content-between align-items-baseline text-sm'>
                                                                <span className="text-xs md:text-sm font-semibold flex-1 text-right pb-1">
                                                                    {CurrencyFormat(FormComponentValue.ClosedBalance)}
                                                                </span>
                                                                <span className='text-xs md:text-sm'>
                                                                    {contextVariable.taxDetails.CurrencyCode}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className='bg-red mb-1'>
                                                <Tooltip
                                                    title={t('Download as PDF file')}
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: 'var(--sidebar-color)',
                                                                fontSize: '12px'
                                                            },
                                                        },
                                                    }}
                                                    placement="bottom"
                                                    TransitionComponent={Zoom}
                                                    enterDelay={500}
                                                >
                                                    <div

                                                    >
                                                        {/* <Button
                                                    className='primary-button p-button-sm'
                                                    onClick={downloadPdf}
                                                    disabled={props.CustomerStatementsOfAccountsDetails == 1}>
                                                    <DownloadOutlined /> PDF
                                                </Button> */}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    ''
            }

        </>

    )
}

export default FormComponent;