import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Layout/Layout';

export default function AddedIndicate(props) {

    const [Added, setAdded] = useState(false);
    const contextVariable = useContext(AppContext);
    useEffect(() => {
        setAdded((p) => contextVariable.CartItemsIds.includes(props.Data.id))
    }, [contextVariable.CartItemsIds, props.Data])


    return (
        <>
            {Added && contextVariable.UserCartIdDetails &&
                (contextVariable.UserCartIdDetails[0] && contextVariable.UserCartIdDetails[0].CartStatus !== "InProgress") && <div className='ribbonCard'>Added</div>}
        </>
    )
}
