import { API } from "aws-amplify";
import {
    GetCreditNotePDF,
    GetDeliveryPDF,
    GetInvoicePDF,
    GetSalesOrderPDF,
} from "../../graphql/queries";
import { showToast } from "../../components/ToastUtils";

const GetOrderPDF = async (component, PDFUUID, OrderID,t) => {
    // const { t } = useTranslation();

    const WebViewPdfdownload = async (base64String) => {
        const NotifyObject = {
            Message: "PDFDownload",
            data: base64String
        }

        if (window.ReactNativeWebView) {
            await window.ReactNativeWebView.postMessage(JSON.stringify(NotifyObject));
        } else {
            console.error("window.ReactNativeWebView is not available. Cannot send message to native app.");
        }
    }

    switch (component) {
        case "order":
            try {
                if (PDFUUID) {
                    // contextVariable.setIsLoading(true);
                    const response = await API.graphql({
                        query: GetSalesOrderPDF,
                        variables: {
                            PDFUUID: PDFUUID,
                        },
                    });
                    let pdfStatus =
                        response.data.GetSalesOrderPDF.items.PDFStatus;

                    // contextVariable.setIsLoading(false);

                    if (pdfStatus) {
                        const base64String = response.data.GetSalesOrderPDF.items.OutputPDF;
                        // Create a new blob from the base64 string
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/pdf" });
                        const pdfURL = URL.createObjectURL(blob);
                        WebViewPdfdownload(base64String);
                        window.open(pdfURL, "_blank");
                    }
                    else {
                        showToast(`${t('PDF not available for Order ID:')} ${OrderID}`, "warning");
                    }
                }
                else {
                    showToast(t(`Sales Order PDF UUID not found.`), "warning");
                }
            }
            catch (error) {
                console.error("Error in getting PDF", error);
                showToast(t(`Error in getting PDF.`));
            }
            break;
        case "delivery":
            try {
                if (PDFUUID) {
                    // contextVariable.setIsLoading(true);
                    const response = await API.graphql({
                        query: GetDeliveryPDF,
                        variables: {
                            PDFUUID: PDFUUID,
                        },
                    });

                    let pdfStatus =
                        response.data.GetDeliveryPDF.items.PDFStatus;

                    // contextVariable.setIsLoading(false);

                    if (pdfStatus) {
                        const base64String = response.data.GetDeliveryPDF.items.OutputPDF;
                        // Create a new blob from the base64 string
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/pdf" });
                        const pdfURL = URL.createObjectURL(blob);
                        WebViewPdfdownload(base64String);
                        window.open(pdfURL, "_blank");
                    }
                    else {
                        showToast(`${t('PDF not available for Order ID:')} ${OrderID}`, "warning");
                    }
                }
                else {
                    showToast(t('Delivery PDF ID not found.'), "warning");
                }
            }
            catch (error) {
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            }
            break;
        case "invoice":
            try {
                if (PDFUUID) {
                    // contextVariable.setIsLoading(true);
                    const response = await API.graphql({
                        query: GetInvoicePDF,
                        variables: {
                            InvoiceID: PDFUUID,
                        },
                    });

                    let pdfStatus =
                        response.data.GetInvoicePDF.items.PDFStatus;

                    // contextVariable.setIsLoading(false);

                    if (pdfStatus) {
                        const base64String = response.data.GetInvoicePDF.items.OutputPDF;
                        // Create a new blob from the base64 string
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/pdf" });
                        const pdfURL = URL.createObjectURL(blob);
                        WebViewPdfdownload(base64String)
                        window.open(pdfURL, "_blank");
                    }
                    else {
                        showToast(`PDF not available for Order ID: ${OrderID}`, "warning");
                    }
                }
                else {
                    showToast(t(`Sales Order PDF UUID not found.`), "warning");
                }
            }
            catch (error) {
                console.error("Error in getting PDF", error);
                showToast(t(`Error in getting PDF.`));
            }
            break;
        case "creditNote":
            try {
                if (PDFUUID) {
                    const response = await API.graphql({
                        query: GetCreditNotePDF,
                        variables: {
                            PDFUUID: PDFUUID,
                        },
                    });
                    let pdfStatus =
                        response.data.GetCreditNotePDF.items.PDFStatus;

                    // contextVariable.setIsLoading(false);

                    if (pdfStatus) {
                        const base64String = response.data.GetCreditNotePDF.items.OutputPDF;
                        // Create a new blob from the base64 string
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/pdf" });
                        const pdfURL = URL.createObjectURL(blob);
                        WebViewPdfdownload(base64String);
                        window.open(pdfURL, "_blank");
                    }
                    else {
                        showToast(`${t(`PDF not available for Credit Note ID:`)} ${OrderID}`, "warning");
                    }
                }
                else {
                    showToast(t(`Credit Note PDF UUID not found.`), "warning");
                }
            }
            catch (error) {
                console.error("Error in getting PDF", error);
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            }
            break;
        default:
            showToast(t('No PDF available.'), "warning");
    }
}

export default GetOrderPDF;