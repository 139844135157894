import { AccountBox, CreditScore, Dns, History, LocalShipping, LogoutOutlined, RequestQuoteOutlined } from '@mui/icons-material';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Layout/Layout';
// import { Dialog } from 'primereact/dialog';
import { Auth, Hub } from 'aws-amplify';
import { LoginContext } from '../App';
import { Button } from 'primereact/button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

const FooterMenu = ({ openSidebar, closeSidebar }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const contextVariable = useContext(AppContext);
    const [visibleRight, setVisibleRight] = useState(openSidebar);
    const [openDialog, setOpenDialog] = useState(false);
    // const [user, setUser] = useState(null);
    const contextVariableLogin = useContext(LoginContext);

    const handleMenuClick = (params) => {
        setVisibleRight(false);
        navigate(`/${params}`);
        closeSidebar();
    }

    const signOut = (currentUser) => {
        if (currentUser) {
            try {
                Auth.signOut();
            }
            catch (error) {
                alert(error);
            }
            contextVariableLogin.setUserGroups("");
            localStorage.clear();
            sessionStorage.clear()
            // localStorage.setItem("isLoggedIn", "false");
            // setUser(null);
            if (contextVariable.username === "demouser") {
                navigate('/Register')
            }
            else {
                navigate('/');
            }

        }
    };

    const verifyUserSignout = () => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {

            switch (event) {
                case "signOut":
                    break;
                default:
                    return null;
            }
        });
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                signOut(currentUser);
            }
            )
            .catch((err) => console.error("Not signed in", err));

        return unsubscribe;
    }

    const handleLogout = () => {
        setVisibleRight(false);
        closeSidebar();
        setOpenDialog(true);
        verifyUserSignout();
    }

    const openLogoutDialog = () => {
        setOpenDialog(true);
        // closeSidebar();
    }

    return (
        <>
            <Dialog
                fullWidth
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Logout')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Are you sure you want to logout?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='flex justify-content-end gap-2'>
                        <Button label="No" icon="pi pi-times" onClick={() => setOpenDialog(false)} className="p-button" outlined />
                        <Button label="Yes" icon="pi pi-check" onClick={handleLogout} autoFocus />
                    </div>
                </DialogActions>
            </Dialog>

            <div className="card">
                <Sidebar
                    // style={{ background: "var(--sidebar-color)", color: "white" }}
                    visible={visibleRight}
                    position="bottom"
                    onHide={() => {
                        setVisibleRight(false);
                        closeSidebar();
                    }}
                    style={{ height: "13.5rem" }}
                >
                    {/* <div className='grid justify-content-start gap-2 flex-wrap text-xs value-color'> */}
                    <div className='grid text-xs value-color'>

                        {/* Ordder */}
                        <div className='col-3'>
                            <div
                                className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("order")}
                            >
                                <div>
                                    <Dns />
                                </div>
                                <div>
                                    <span>Order</span>
                                </div>
                            </div>
                        </div>

                        {/* Invoice */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("invoice")}>
                                <div>
                                    <RequestQuoteOutlined />
                                </div>
                                <div>
                                    <span>Invoice</span>
                                </div>
                            </div>
                        </div>

                        {/* Delivery */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("delivery")}>
                                <div>
                                    <LocalShipping />
                                </div>
                                <div>
                                    <span>Delivery</span>
                                </div>
                            </div>
                        </div>

                        {/*Credit note */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("creditNote")}>
                                <div>
                                    <CreditScore />
                                </div>
                                <div>
                                    <span>Credit Note</span>
                                </div>
                            </div>
                        </div>

                        {/* Payment history */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("paymentHistory")}>
                                <div>
                                    <History />
                                </div>
                                <div className='ml-4'>
                                    <span>Payment History</span>
                                </div>
                            </div>
                        </div>

                        {/* Account statement */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={() => handleMenuClick("statementOfAccount")}>
                                <div>
                                    <AccountBox />
                                </div>
                                <div className='ml-4'>
                                    <span>Account Statement</span>
                                </div>
                            </div>
                        </div>

                        {/* Logout */}
                        <div className='col-3'>
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'
                                onClick={openLogoutDialog}
                            >
                                <div>
                                    <LogoutOutlined />
                                </div>
                                <div>
                                    <span>Log out</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Sidebar>
            </div>
        </>
    )
}

export default FooterMenu