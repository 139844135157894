/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClientDetails = /* GraphQL */ `
  mutation CreateClientDetails(
    $input: CreateClientDetailsInput!
    $condition: ModelClientDetailsConditionInput
  ) {
    createClientDetails(input: $input, condition: $condition) {
      id
      tenant
      UserName
      Email
      PhoneNumber
      OrganizationName
      Environment
      PortalURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientDetails = /* GraphQL */ `
  mutation UpdateClientDetails(
    $input: UpdateClientDetailsInput!
    $condition: ModelClientDetailsConditionInput
  ) {
    updateClientDetails(input: $input, condition: $condition) {
      id
      tenant
      UserName
      Email
      PhoneNumber
      OrganizationName
      Environment
      PortalURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientDetails = /* GraphQL */ `
  mutation DeleteClientDetails(
    $input: DeleteClientDetailsInput!
    $condition: ModelClientDetailsConditionInput
  ) {
    deleteClientDetails(input: $input, condition: $condition) {
      id
      tenant
      UserName
      Email
      PhoneNumber
      OrganizationName
      Environment
      PortalURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDemoUsers = /* GraphQL */ `
  mutation CreateDemoUsers(
    $input: CreateDemoUsersInput!
    $condition: ModelDemoUsersConditionInput
  ) {
    createDemoUsers(input: $input, condition: $condition) {
      id
      UserName
      Email
      PhoneNumber
      VerifiedUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDemoUsers = /* GraphQL */ `
  mutation UpdateDemoUsers(
    $input: UpdateDemoUsersInput!
    $condition: ModelDemoUsersConditionInput
  ) {
    updateDemoUsers(input: $input, condition: $condition) {
      id
      UserName
      Email
      PhoneNumber
      VerifiedUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDemoUsers = /* GraphQL */ `
  mutation DeleteDemoUsers(
    $input: DeleteDemoUsersInput!
    $condition: ModelDemoUsersConditionInput
  ) {
    deleteDemoUsers(input: $input, condition: $condition) {
      id
      UserName
      Email
      PhoneNumber
      VerifiedUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLambdaLastRunTime = /* GraphQL */ `
  mutation CreateLambdaLastRunTime(
    $input: CreateLambdaLastRunTimeInput!
    $condition: ModelLambdaLastRunTimeConditionInput
  ) {
    createLambdaLastRunTime(input: $input, condition: $condition) {
      id
      ProductPrimaryDetails
      ProductPriceDetails
      ContactDetails
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLambdaLastRunTime = /* GraphQL */ `
  mutation UpdateLambdaLastRunTime(
    $input: UpdateLambdaLastRunTimeInput!
    $condition: ModelLambdaLastRunTimeConditionInput
  ) {
    updateLambdaLastRunTime(input: $input, condition: $condition) {
      id
      ProductPrimaryDetails
      ProductPriceDetails
      ContactDetails
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLambdaLastRunTime = /* GraphQL */ `
  mutation DeleteLambdaLastRunTime(
    $input: DeleteLambdaLastRunTimeInput!
    $condition: ModelLambdaLastRunTimeConditionInput
  ) {
    deleteLambdaLastRunTime(input: $input, condition: $condition) {
      id
      ProductPrimaryDetails
      ProductPriceDetails
      ContactDetails
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductsList = /* GraphQL */ `
  mutation CreateProductsList(
    $input: CreateProductsListInput!
    $condition: ModelProductsListConditionInput
  ) {
    createProductsList(input: $input, condition: $condition) {
      id
      ProductId
      ProductName
      ProductUOM
      ProductUOMText
      ProductCategoryId
      ProductCategoryName
      ProductDetailDescription
      ProductImage
      ProductStatus
      createdAt
      updatedAt
      tenant
      ProductActiveStatus
      ProductQuantityDetails
      ProductsPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ProductsDiscounts {
        items {
          id
          ProductID
          ProductDiscount
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerSpecificPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserFavouriteProducts {
        items {
          id
          FavouriteStatus
          owner
          CustomerUUID
          productID
          productDetail {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userFavouriteProductsProductDetailId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateProductsList = /* GraphQL */ `
  mutation UpdateProductsList(
    $input: UpdateProductsListInput!
    $condition: ModelProductsListConditionInput
  ) {
    updateProductsList(input: $input, condition: $condition) {
      id
      ProductId
      ProductName
      ProductUOM
      ProductUOMText
      ProductCategoryId
      ProductCategoryName
      ProductDetailDescription
      ProductImage
      ProductStatus
      createdAt
      updatedAt
      tenant
      ProductActiveStatus
      ProductQuantityDetails
      ProductsPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ProductsDiscounts {
        items {
          id
          ProductID
          ProductDiscount
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerSpecificPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserFavouriteProducts {
        items {
          id
          FavouriteStatus
          owner
          CustomerUUID
          productID
          productDetail {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userFavouriteProductsProductDetailId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteProductsList = /* GraphQL */ `
  mutation DeleteProductsList(
    $input: DeleteProductsListInput!
    $condition: ModelProductsListConditionInput
  ) {
    deleteProductsList(input: $input, condition: $condition) {
      id
      ProductId
      ProductName
      ProductUOM
      ProductUOMText
      ProductCategoryId
      ProductCategoryName
      ProductDetailDescription
      ProductImage
      ProductStatus
      createdAt
      updatedAt
      tenant
      ProductActiveStatus
      ProductQuantityDetails
      ProductsPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ProductsDiscounts {
        items {
          id
          ProductID
          ProductDiscount
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerSpecificPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserFavouriteProducts {
        items {
          id
          FavouriteStatus
          owner
          CustomerUUID
          productID
          productDetail {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userFavouriteProductsProductDetailId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createProductPrices = /* GraphQL */ `
  mutation CreateProductPrices(
    $input: CreateProductPricesInput!
    $condition: ModelProductPricesConditionInput
  ) {
    createProductPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductPrices = /* GraphQL */ `
  mutation UpdateProductPrices(
    $input: UpdateProductPricesInput!
    $condition: ModelProductPricesConditionInput
  ) {
    updateProductPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductPrices = /* GraphQL */ `
  mutation DeleteProductPrices(
    $input: DeleteProductPricesInput!
    $condition: ModelProductPricesConditionInput
  ) {
    deleteProductPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerSpecificPrices = /* GraphQL */ `
  mutation CreateCustomerSpecificPrices(
    $input: CreateCustomerSpecificPricesInput!
    $condition: ModelCustomerSpecificPricesConditionInput
  ) {
    createCustomerSpecificPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerSpecificPrices = /* GraphQL */ `
  mutation UpdateCustomerSpecificPrices(
    $input: UpdateCustomerSpecificPricesInput!
    $condition: ModelCustomerSpecificPricesConditionInput
  ) {
    updateCustomerSpecificPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerSpecificPrices = /* GraphQL */ `
  mutation DeleteCustomerSpecificPrices(
    $input: DeleteCustomerSpecificPricesInput!
    $condition: ModelCustomerSpecificPricesConditionInput
  ) {
    deleteCustomerSpecificPrices(input: $input, condition: $condition) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductDiscounts = /* GraphQL */ `
  mutation CreateProductDiscounts(
    $input: CreateProductDiscountsInput!
    $condition: ModelProductDiscountsConditionInput
  ) {
    createProductDiscounts(input: $input, condition: $condition) {
      id
      ProductID
      ProductDiscount
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductDiscounts = /* GraphQL */ `
  mutation UpdateProductDiscounts(
    $input: UpdateProductDiscountsInput!
    $condition: ModelProductDiscountsConditionInput
  ) {
    updateProductDiscounts(input: $input, condition: $condition) {
      id
      ProductID
      ProductDiscount
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductDiscounts = /* GraphQL */ `
  mutation DeleteProductDiscounts(
    $input: DeleteProductDiscountsInput!
    $condition: ModelProductDiscountsConditionInput
  ) {
    deleteProductDiscounts(input: $input, condition: $condition) {
      id
      ProductID
      ProductDiscount
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProductsCart = /* GraphQL */ `
  mutation CreateUserProductsCart(
    $input: CreateUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    createUserProductsCart(input: $input, condition: $condition) {
      id
      ProductQuantity
      ProductQuantityText
      ProductPriceUOMCode
      CalculatedUOMQuantity
      owner
      ProductPrice
      ProductPriceCurrencyCode
      ProductDiscount
      carttableID
      Productdetails {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;
export const updateUserProductsCart = /* GraphQL */ `
  mutation UpdateUserProductsCart(
    $input: UpdateUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    updateUserProductsCart(input: $input, condition: $condition) {
      id
      ProductQuantity
      ProductQuantityText
      ProductPriceUOMCode
      CalculatedUOMQuantity
      owner
      ProductPrice
      ProductPriceCurrencyCode
      ProductDiscount
      carttableID
      Productdetails {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;
export const deleteUserProductsCart = /* GraphQL */ `
  mutation DeleteUserProductsCart(
    $input: DeleteUserProductsCartInput!
    $condition: ModelUserProductsCartConditionInput
  ) {
    deleteUserProductsCart(input: $input, condition: $condition) {
      id
      ProductQuantity
      ProductQuantityText
      ProductPriceUOMCode
      CalculatedUOMQuantity
      owner
      ProductPrice
      ProductPriceCurrencyCode
      ProductDiscount
      carttableID
      Productdetails {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;
export const createUserCart = /* GraphQL */ `
  mutation CreateUserCart(
    $input: CreateUserCartInput!
    $condition: ModelUserCartConditionInput
  ) {
    createUserCart(input: $input, condition: $condition) {
      id
      CartId
      CartStatus
      UserAddress
      SalesOrderId
      CreatedBy
      Userproductcarts {
        items {
          id
          ProductQuantity
          ProductQuantityText
          ProductPriceUOMCode
          CalculatedUOMQuantity
          owner
          ProductPrice
          ProductPriceCurrencyCode
          ProductDiscount
          carttableID
          Productdetails {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userProductsCartProductdetailsId
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserCart = /* GraphQL */ `
  mutation UpdateUserCart(
    $input: UpdateUserCartInput!
    $condition: ModelUserCartConditionInput
  ) {
    updateUserCart(input: $input, condition: $condition) {
      id
      CartId
      CartStatus
      UserAddress
      SalesOrderId
      CreatedBy
      Userproductcarts {
        items {
          id
          ProductQuantity
          ProductQuantityText
          ProductPriceUOMCode
          CalculatedUOMQuantity
          owner
          ProductPrice
          ProductPriceCurrencyCode
          ProductDiscount
          carttableID
          Productdetails {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userProductsCartProductdetailsId
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserCart = /* GraphQL */ `
  mutation DeleteUserCart(
    $input: DeleteUserCartInput!
    $condition: ModelUserCartConditionInput
  ) {
    deleteUserCart(input: $input, condition: $condition) {
      id
      CartId
      CartStatus
      UserAddress
      SalesOrderId
      CreatedBy
      Userproductcarts {
        items {
          id
          ProductQuantity
          ProductQuantityText
          ProductPriceUOMCode
          CalculatedUOMQuantity
          owner
          ProductPrice
          ProductPriceCurrencyCode
          ProductDiscount
          carttableID
          Productdetails {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userProductsCartProductdetailsId
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserFavouriteProducts = /* GraphQL */ `
  mutation CreateUserFavouriteProducts(
    $input: CreateUserFavouriteProductsInput!
    $condition: ModelUserFavouriteProductsConditionInput
  ) {
    createUserFavouriteProducts(input: $input, condition: $condition) {
      id
      FavouriteStatus
      owner
      CustomerUUID
      productID
      productDetail {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userFavouriteProductsProductDetailId
      __typename
    }
  }
`;
export const updateUserFavouriteProducts = /* GraphQL */ `
  mutation UpdateUserFavouriteProducts(
    $input: UpdateUserFavouriteProductsInput!
    $condition: ModelUserFavouriteProductsConditionInput
  ) {
    updateUserFavouriteProducts(input: $input, condition: $condition) {
      id
      FavouriteStatus
      owner
      CustomerUUID
      productID
      productDetail {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userFavouriteProductsProductDetailId
      __typename
    }
  }
`;
export const deleteUserFavouriteProducts = /* GraphQL */ `
  mutation DeleteUserFavouriteProducts(
    $input: DeleteUserFavouriteProductsInput!
    $condition: ModelUserFavouriteProductsConditionInput
  ) {
    deleteUserFavouriteProducts(input: $input, condition: $condition) {
      id
      FavouriteStatus
      owner
      CustomerUUID
      productID
      productDetail {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userFavouriteProductsProductDetailId
      __typename
    }
  }
`;
export const createOrganizationDetails = /* GraphQL */ `
  mutation CreateOrganizationDetails(
    $input: CreateOrganizationDetailsInput!
    $condition: ModelOrganizationDetailsConditionInput
  ) {
    createOrganizationDetails(input: $input, condition: $condition) {
      id
      CompanyID
      CompanyName
      CommencementYear
      SetofBooks
      LanguageCode
      SalesOrganizationName
      SalesOrganizationID
      DistributionChannel
      EmployeeResponsibleID
      CurrencyCode
      BankID
      BankName
      SAPCredentialIndicator
      BankDefaultIndicator
      PriceAutoSyncIndicator
      StripeDefaultIndicator
      PriceDefaultIndicator
      PriceListName
      DiscountListName
      SystemID
      Tax
      Logo
      Address
      tenant
      NotificationList
      AdditionalPricingOptions
      SiteID
      InventoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganizationDetails = /* GraphQL */ `
  mutation UpdateOrganizationDetails(
    $input: UpdateOrganizationDetailsInput!
    $condition: ModelOrganizationDetailsConditionInput
  ) {
    updateOrganizationDetails(input: $input, condition: $condition) {
      id
      CompanyID
      CompanyName
      CommencementYear
      SetofBooks
      LanguageCode
      SalesOrganizationName
      SalesOrganizationID
      DistributionChannel
      EmployeeResponsibleID
      CurrencyCode
      BankID
      BankName
      SAPCredentialIndicator
      BankDefaultIndicator
      PriceAutoSyncIndicator
      StripeDefaultIndicator
      PriceDefaultIndicator
      PriceListName
      DiscountListName
      SystemID
      Tax
      Logo
      Address
      tenant
      NotificationList
      AdditionalPricingOptions
      SiteID
      InventoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganizationDetails = /* GraphQL */ `
  mutation DeleteOrganizationDetails(
    $input: DeleteOrganizationDetailsInput!
    $condition: ModelOrganizationDetailsConditionInput
  ) {
    deleteOrganizationDetails(input: $input, condition: $condition) {
      id
      CompanyID
      CompanyName
      CommencementYear
      SetofBooks
      LanguageCode
      SalesOrganizationName
      SalesOrganizationID
      DistributionChannel
      EmployeeResponsibleID
      CurrencyCode
      BankID
      BankName
      SAPCredentialIndicator
      BankDefaultIndicator
      PriceAutoSyncIndicator
      StripeDefaultIndicator
      PriceDefaultIndicator
      PriceListName
      DiscountListName
      SystemID
      Tax
      Logo
      Address
      tenant
      NotificationList
      AdditionalPricingOptions
      SiteID
      InventoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      CustomerId
      MessageText
      NotificationStatus
      createdAt
      NotificationReason
      NotificationReasonCode
      NotificationData
      tenant
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      CustomerId
      MessageText
      NotificationStatus
      createdAt
      NotificationReason
      NotificationReasonCode
      NotificationData
      tenant
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      CustomerId
      MessageText
      NotificationStatus
      createdAt
      NotificationReason
      NotificationReasonCode
      NotificationData
      tenant
      updatedAt
      __typename
    }
  }
`;
export const createPaymentHistory = /* GraphQL */ `
  mutation CreatePaymentHistory(
    $input: CreatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    createPaymentHistory(input: $input, condition: $condition) {
      id
      TransactionReferenceNo
      CustomerId
      ContactId
      DateOfTransaction
      createdAt
      PaymentAmount
      SAPBankPaymentadviceId
      SAPBankPaymentadviceUUID
      SAPBankPaymentadviceStatus
      PaymentStatus
      PaymentStatusCode
      PaymentHistoryDetails
      tenant
      updatedAt
      __typename
    }
  }
`;
export const updatePaymentHistory = /* GraphQL */ `
  mutation UpdatePaymentHistory(
    $input: UpdatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    updatePaymentHistory(input: $input, condition: $condition) {
      id
      TransactionReferenceNo
      CustomerId
      ContactId
      DateOfTransaction
      createdAt
      PaymentAmount
      SAPBankPaymentadviceId
      SAPBankPaymentadviceUUID
      SAPBankPaymentadviceStatus
      PaymentStatus
      PaymentStatusCode
      PaymentHistoryDetails
      tenant
      updatedAt
      __typename
    }
  }
`;
export const deletePaymentHistory = /* GraphQL */ `
  mutation DeletePaymentHistory(
    $input: DeletePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    deletePaymentHistory(input: $input, condition: $condition) {
      id
      TransactionReferenceNo
      CustomerId
      ContactId
      DateOfTransaction
      createdAt
      PaymentAmount
      SAPBankPaymentadviceId
      SAPBankPaymentadviceUUID
      SAPBankPaymentadviceStatus
      PaymentStatus
      PaymentStatusCode
      PaymentHistoryDetails
      tenant
      updatedAt
      __typename
    }
  }
`;
export const createImageErrorLog = /* GraphQL */ `
  mutation CreateImageErrorLog(
    $input: CreateImageErrorLogInput!
    $condition: ModelImageErrorLogConditionInput
  ) {
    createImageErrorLog(input: $input, condition: $condition) {
      id
      type
      DocumentFile
      DocumentDescription
      CreatedBy
      FileSize
      Status
      ImageCount
      FailedImages {
        items {
          id
          ProductImageName
          ErrorMessage
          ImageErrorLogTableId
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImageErrorLog = /* GraphQL */ `
  mutation UpdateImageErrorLog(
    $input: UpdateImageErrorLogInput!
    $condition: ModelImageErrorLogConditionInput
  ) {
    updateImageErrorLog(input: $input, condition: $condition) {
      id
      type
      DocumentFile
      DocumentDescription
      CreatedBy
      FileSize
      Status
      ImageCount
      FailedImages {
        items {
          id
          ProductImageName
          ErrorMessage
          ImageErrorLogTableId
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImageErrorLog = /* GraphQL */ `
  mutation DeleteImageErrorLog(
    $input: DeleteImageErrorLogInput!
    $condition: ModelImageErrorLogConditionInput
  ) {
    deleteImageErrorLog(input: $input, condition: $condition) {
      id
      type
      DocumentFile
      DocumentDescription
      CreatedBy
      FileSize
      Status
      ImageCount
      FailedImages {
        items {
          id
          ProductImageName
          ErrorMessage
          ImageErrorLogTableId
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImageErrorLogDetails = /* GraphQL */ `
  mutation CreateImageErrorLogDetails(
    $input: CreateImageErrorLogDetailsInput!
    $condition: ModelImageErrorLogDetailsConditionInput
  ) {
    createImageErrorLogDetails(input: $input, condition: $condition) {
      id
      ProductImageName
      ErrorMessage
      ImageErrorLogTableId
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImageErrorLogDetails = /* GraphQL */ `
  mutation UpdateImageErrorLogDetails(
    $input: UpdateImageErrorLogDetailsInput!
    $condition: ModelImageErrorLogDetailsConditionInput
  ) {
    updateImageErrorLogDetails(input: $input, condition: $condition) {
      id
      ProductImageName
      ErrorMessage
      ImageErrorLogTableId
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImageErrorLogDetails = /* GraphQL */ `
  mutation DeleteImageErrorLogDetails(
    $input: DeleteImageErrorLogDetailsInput!
    $condition: ModelImageErrorLogDetailsConditionInput
  ) {
    deleteImageErrorLogDetails(input: $input, condition: $condition) {
      id
      ProductImageName
      ErrorMessage
      ImageErrorLogTableId
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactInformation = /* GraphQL */ `
  mutation CreateContactInformation(
    $input: CreateContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    createContactInformation(input: $input, condition: $condition) {
      id
      CognitoUserId
      CustomerUUID
      CustomerId
      CustomerName
      ContactUUID
      ContactId
      ContactName
      EmailId
      UpdatedEmailId
      PhoneNumber
      UpdatedPhoneNumber
      SAPStatus
      PortalStatus
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactInformation = /* GraphQL */ `
  mutation UpdateContactInformation(
    $input: UpdateContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    updateContactInformation(input: $input, condition: $condition) {
      id
      CognitoUserId
      CustomerUUID
      CustomerId
      CustomerName
      ContactUUID
      ContactId
      ContactName
      EmailId
      UpdatedEmailId
      PhoneNumber
      UpdatedPhoneNumber
      SAPStatus
      PortalStatus
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactInformation = /* GraphQL */ `
  mutation DeleteContactInformation(
    $input: DeleteContactInformationInput!
    $condition: ModelContactInformationConditionInput
  ) {
    deleteContactInformation(input: $input, condition: $condition) {
      id
      CognitoUserId
      CustomerUUID
      CustomerId
      CustomerName
      ContactUUID
      ContactId
      ContactName
      EmailId
      UpdatedEmailId
      PhoneNumber
      UpdatedPhoneNumber
      SAPStatus
      PortalStatus
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLambdaGrouping = /* GraphQL */ `
  mutation CreateLambdaGrouping(
    $input: CreateLambdaGroupingInput!
    $condition: ModelLambdaGroupingConditionInput
  ) {
    createLambdaGrouping(input: $input, condition: $condition) {
      id
      GroupingName
      LambdaFunctions
      tenant
      NotificationID
      NotificationName
      NotificationDescription
      NotificationMandatoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLambdaGrouping = /* GraphQL */ `
  mutation UpdateLambdaGrouping(
    $input: UpdateLambdaGroupingInput!
    $condition: ModelLambdaGroupingConditionInput
  ) {
    updateLambdaGrouping(input: $input, condition: $condition) {
      id
      GroupingName
      LambdaFunctions
      tenant
      NotificationID
      NotificationName
      NotificationDescription
      NotificationMandatoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLambdaGrouping = /* GraphQL */ `
  mutation DeleteLambdaGrouping(
    $input: DeleteLambdaGroupingInput!
    $condition: ModelLambdaGroupingConditionInput
  ) {
    deleteLambdaGrouping(input: $input, condition: $condition) {
      id
      GroupingName
      LambdaFunctions
      tenant
      NotificationID
      NotificationName
      NotificationDescription
      NotificationMandatoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTenantUsers = /* GraphQL */ `
  mutation CreateTenantUsers(
    $input: CreateTenantUsersInput!
    $condition: ModelTenantUsersConditionInput
  ) {
    createTenantUsers(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      UserType
      UserName
      createdAt
      updatedAt
      TenantMasters {
        items {
          id
          CognitoUserId
          CompanyId
          CompanyName
          ContactName
          ContactPersonId
          ContactPersonUUID
          CustomerId
          CustomerUUID
          CustomerName
          UserType
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTenantUsers = /* GraphQL */ `
  mutation UpdateTenantUsers(
    $input: UpdateTenantUsersInput!
    $condition: ModelTenantUsersConditionInput
  ) {
    updateTenantUsers(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      UserType
      UserName
      createdAt
      updatedAt
      TenantMasters {
        items {
          id
          CognitoUserId
          CompanyId
          CompanyName
          ContactName
          ContactPersonId
          ContactPersonUUID
          CustomerId
          CustomerUUID
          CustomerName
          UserType
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTenantUsers = /* GraphQL */ `
  mutation DeleteTenantUsers(
    $input: DeleteTenantUsersInput!
    $condition: ModelTenantUsersConditionInput
  ) {
    deleteTenantUsers(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      UserType
      UserName
      createdAt
      updatedAt
      TenantMasters {
        items {
          id
          CognitoUserId
          CompanyId
          CompanyName
          ContactName
          ContactPersonId
          ContactPersonUUID
          CustomerId
          CustomerUUID
          CustomerName
          UserType
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTenantMasters = /* GraphQL */ `
  mutation CreateTenantMasters(
    $input: CreateTenantMastersInput!
    $condition: ModelTenantMastersConditionInput
  ) {
    createTenantMasters(input: $input, condition: $condition) {
      id
      CognitoUserId
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      UserType
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTenantMasters = /* GraphQL */ `
  mutation UpdateTenantMasters(
    $input: UpdateTenantMastersInput!
    $condition: ModelTenantMastersConditionInput
  ) {
    updateTenantMasters(input: $input, condition: $condition) {
      id
      CognitoUserId
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      UserType
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTenantMasters = /* GraphQL */ `
  mutation DeleteTenantMasters(
    $input: DeleteTenantMastersInput!
    $condition: ModelTenantMastersConditionInput
  ) {
    deleteTenantMasters(input: $input, condition: $condition) {
      id
      CognitoUserId
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      UserType
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSessionDetails = /* GraphQL */ `
  mutation CreateUserSessionDetails(
    $input: CreateUserSessionDetailsInput!
    $condition: ModelUserSessionDetailsConditionInput
  ) {
    createUserSessionDetails(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSessionDetails = /* GraphQL */ `
  mutation UpdateUserSessionDetails(
    $input: UpdateUserSessionDetailsInput!
    $condition: ModelUserSessionDetailsConditionInput
  ) {
    updateUserSessionDetails(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSessionDetails = /* GraphQL */ `
  mutation DeleteUserSessionDetails(
    $input: DeleteUserSessionDetailsInput!
    $condition: ModelUserSessionDetailsConditionInput
  ) {
    deleteUserSessionDetails(input: $input, condition: $condition) {
      id
      CognitoUserId
      Email
      PhoneNumber
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const UpdateProductPrimaryDetails = /* GraphQL */ `
  mutation UpdateProductPrimaryDetails {
    UpdateProductPrimaryDetails {
      count
      response
      __typename
    }
  }
`;
export const UpdateProductPriceDetails = /* GraphQL */ `
  mutation UpdateProductPriceDetails {
    UpdateProductPriceDetails {
      count
      response
      __typename
    }
  }
`;
export const CreatePlaceOrder = /* GraphQL */ `
  mutation CreatePlaceOrder($input: PlaceOrderInput!) {
    CreatePlaceOrder(input: $input) {
      PlaceOrderStatus
      UserCartTableID
      __typename
    }
  }
`;
export const BatchDeleteNotifications = /* GraphQL */ `
  mutation BatchDeleteNotifications($input: [BatchDeleteNotificationInput]!) {
    BatchDeleteNotifications(input: $input) {
      MessageText
      __typename
    }
  }
`;
export const UpdateContactInformationDetails = /* GraphQL */ `
  mutation UpdateContactInformationDetails {
    UpdateContactInformationDetails {
      response
      count
      __typename
    }
  }
`;
export const CreateandUpdateContacts = /* GraphQL */ `
  mutation CreateandUpdateContacts($input: ContactInput!) {
    CreateandUpdateContacts(input: $input) {
      Status
      id
      CognitoUserId
      __typename
    }
  }
`;
export const CreateandUpdateCustomerSecrets = /* GraphQL */ `
  mutation CreateandUpdateCustomerSecrets($input: SecretsInput!) {
    CreateandUpdateCustomerSecrets(input: $input) {
      Status
      message
      OdataUsername
      OdataPassword
      SoapUsername
      SoapPassword
      SAPHostName
      SecretKey
      PublishableKey
      __typename
    }
  }
`;
export const CreatePaymentsHistoryData = /* GraphQL */ `
  mutation CreatePaymentsHistoryData($input: PaymentHistoryInput!) {
    CreatePaymentsHistoryData(input: $input) {
      SessionURL
      __typename
    }
  }
`;
