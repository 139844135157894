import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';


const ConfettiEffect = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        document.body.style.overflow = 'hidden';

        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.style.overflow = 'auto';
        };
    }, []);
    return (
        <>
            <Confetti
                width={windowWidth}
                height={windowHeight}
                recycle={false}
                numberOfPieces={200}
            />
        </>
    )
}

export default ConfettiEffect;