import { API } from "aws-amplify";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { showToast } from "../ToastUtils";
import { UpdateContactInformationDetails, UpdateProductPriceDetails, UpdateProductPrimaryDetails } from "../../graphql/mutations";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
// import { SyncOutlined } from "@mui/icons-material";
// import { Tooltip, Zoom } from "@mui/material";

const SyncButton = (props) => {
    const { t } = useTranslation();
    // console.log("props", props)
    const [loading, setLoading] = useState({ ProductLoading: false, PriceLoading: false, DiscountLoading: false, contactLoading: false });
    const menuRight = useRef(null);
    const toast = useRef(null);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // hour12: true, 
    };

    const formatPriceDate = new Date(props.syncTime.ProductPriceDetails);
    // const formatDiscountDate = new Date(props.syncTime.ProductDiscountDetails);
    const formattedContact = new Date(props.syncTime.ContactDetails);
    const formatProductDate = new Date(props.syncTime.ProductPrimaryDetails);

    const lastSyncProduct = formatProductDate.toLocaleString('en-GB', options);
    const lastSyncPrice = formatPriceDate.toLocaleDateString('en-GB', options);
    // const lastSyncDiscount = formatDiscountDate.toLocaleDateString('en-GB', options);
    const lastSyncContact = formattedContact.toLocaleDateString('en-GB', options);

    const DateWithProductLabel = (<div className="flex flex-column gap-2 "> <span>Product </span>
        {lastSyncProduct !== "Invalid Date" && <span style={{ fontSize: "10px", width: "110%", color: "#8d8d8d" }} >{lastSyncProduct} (Last Sync)</span>}
    </div>)
    const DateWithPriceLabel = (<div className="flex flex-column gap-2"> <span>Price</span>
        {lastSyncPrice !== "Invalid Date" && <span style={{ fontSize: "10px", width: "110%", color: "#8d8d8d" }}>{lastSyncPrice} (Last Sync)</span>}
    </div>)
    // const DateWithDiscountLabel = (<div className="flex flex-column gap-2"> <span>Discount</span>
    //     {lastSyncDiscount !== "Invalid Date" && <span style={{ fontSize: "10px", width: "110%", color: "#8d8d8d" }}>{lastSyncDiscount} (Last Sync)</span>}
    // </div>)
    const DateWithContactLabel = (<div className="flex flex-column gap-2"> <span>Contacts</span>
        {lastSyncContact !== "Invalid Date" && <span style={{ fontSize: "10px", width: "110%", color: "#8d8d8d" }}>{lastSyncContact} (Last Sync)</span>}
    </div>)



    const [menuItems, setMenuItems] = useState([
        {
            idName: "Product",
            label: "Product",
            icon: '',
            disabled: loading.ProductLoading,
            style: { fontSize: '14px' }
        },
        {
            idName: 'Price',
            label: 'Base Price',
            icon: '',
            disabled: loading.PriceLoading,
            style: { fontSize: '14px' }
        },
    ]);

    const [ContactMenuItems, setContactMenuItems] = useState([
        {
            idName: "Contacts",
            label: "Contacts",
            icon: '',
            disabled: loading.contactLoading,
            style: { fontSize: '14px' }
        },
    ]);

    const [Visible, setVisible] = useState(false)
    useEffect(() => {
        if (props.syncTime && props.component === "ProductsView") {
            // setMenuItems(prevItems => prevItems.map(item => {
            //     if (item.idName === 'Product') {
            //         return {
            //             ...item, label: DateWithProductLabel, command: async () => {
            //                 try {
            //                     setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: true }));
            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Product') {
            //                             return { ...item, label: 'Syncing Products...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
            //                         }
            //                         return item;
            //                     }));
            //                     showToast("Products synchronization is in progress... Please check after some time.")
            //                     const response = await API.graphql({
            //                         query: UpdateProductPrimaryDetails
            //                     });

            //                     // console.log("Response UpdateProductPrimaryDetails", response);
            //                     if (response) {
            //                         setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: false }));
            //                     }

            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Product') {
            //                             return {
            //                                 ...item, label: DateWithProductLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
            //                             };
            //                         }
            //                         return item;
            //                     }));
            //                 } catch (error) {
            //                     console.error("Product Sync error", error);
            //                     setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: false }));
            //                     // console.log("error", error.errors[0].message)
            //                     if (error.errors[0].message === "Execution timed out.") {
            //                         showToast("Please wait while we're synchronizing your products. You will receive a notification once synchronization is finished.", 10000);
            //                     }
            //                     else {
            //                         showToast("something wents wrong", "error");
            //                     }

            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Product') {
            //                             return { ...item, label: DateWithProductLabel, disabled: false, icon: '', style: { fontStyle: 'normal' }, };
            //                         }
            //                         return item;
            //                     }));

            //                 }
            //             }
            //         };
            //     }
            //     if (item.idName === 'Price') {
            //         return {
            //             ...item, label: DateWithPriceLabel, command: async () => {
            //                 try {
            //                     setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: true }));

            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Price') {
            //                             return { ...item, label: 'Syncing Price...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
            //                         }
            //                         return item;
            //                     }));
            //                     showToast("Price sync in progress... Please check after some time.")
            //                     const response = await API.graphql({
            //                         query: UpdateProductPriceDetails
            //                     });

            //                     // console.log("Response UpdateProductPriceDetails", response);
            //                     if (response) {
            //                         setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: false }));
            //                     }

            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Price') {
            //                             return {
            //                                 ...item, label: DateWithPriceLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
            //                             };
            //                         }
            //                         return item;
            //                     }));
            //                 } catch (error) {
            //                     console.error("Price Sync error", error);
            //                     setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: false }));
            //                     if (error.errors[0].message === "Execution timed out.") {
            //                         showToast("Please wait while we're synchronizing your price. You will receive a notification once synchronization is finished.", 1000);
            //                     }
            //                     else {
            //                         showToast("something wents wrong", "error");
            //                     }
            //                     setMenuItems(prevItems => prevItems.map(item => {
            //                         if (item.idName === 'Price') {
            //                             return {
            //                                 ...item, label: DateWithPriceLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
            //                             };
            //                         }
            //                         return item;
            //                     }));
            //                 }
            //             }
            //         };
            //     }
            //     if (item.idName === 'Discount') {
            //         return {
            //             ...item, label: DateWithDiscountLabel
            //             , command: async () => {
            //                 if (props.syncTime.ContactDetails !== "") {
            //                     try {
            //                         setLoading((prevLoading) => ({ ...prevLoading, DiscountLoading: true }));

            //                         setMenuItems(prevItems => prevItems.map(item => {
            //                             if (item.idName === 'Discount') {
            //                                 return { ...item, label: 'Syncing Discounts...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
            //                             }
            //                             return item;
            //                         }));
            //                         showToast("Discount sync in progress... Please check after some time.")
            //                         const response = await API.graphql({
            //                             // query: UpdateProductDiscountDetails
            //                         });

            //                         // console.log("Response UpdateProductDiscountDetails", response);
            //                         if (response) {
            //                             setLoading((prevLoading) => ({ ...prevLoading, DiscountLoading: false }));
            //                         }

            //                         setMenuItems(prevItems => prevItems.map(item => {
            //                             if (item.idName === 'Discount') {
            //                                 return {
            //                                     ...item, label: DateWithDiscountLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
            //                                 };
            //                             }
            //                             return item;
            //                         }));
            //                     } catch (error) {
            //                         console.error("Discount Sync error", error);
            //                         setLoading((prevLoading) => ({ ...prevLoading, DiscountLoading: false }));
            //                         // showToast("something wents wrong", "error");
            //                         showToast("Please wait while we're synchronizing your discounts. You will receive a notification once synchronization is finished.", 10000);
            //                         setMenuItems(prevItems => prevItems.map(item => {
            //                             if (item.idName === 'Discount') {
            //                                 return {
            //                                     ...item, label: DateWithDiscountLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
            //                                 };
            //                             }
            //                             return item;
            //                         }));
            //                     }
            //                 }
            //                 else {
            //                     showToast("Please ensure to synchronize contact details first, as discount details cannot be synchronized without it")
            //                 }

            //             }
            //         };
            //     }
            //     return item;
            // }));

            setMenuItems(prevItems => prevItems.map(item => {
                if (item.idName === 'Product') {
                    return {
                        ...item, label: DateWithProductLabel, command: async () => {
                            try {
                                setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: true }));
                                setMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Product') {
                                        return { ...item, label: 'Syncing Products...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
                                    }
                                    return item;
                                }));
                                showToast(t('Products synchronization is in progress... Please check after some time.'))
                                const response = await API.graphql({
                                    query: UpdateProductPrimaryDetails
                                });

                                // console.log("Response UpdateProductPrimaryDetails", response);
                                if (response) {
                                    setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: false }));
                                }

                                setMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Product') {
                                        return {
                                            ...item, label: DateWithProductLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
                                        };
                                    }
                                    return item;
                                }));
                            } catch (error) {
                                console.error("Product Sync error", error);
                                setLoading((prevLoading) => ({ ...prevLoading, ProductLoading: false }));
                                // console.log("error", error.errors[0].message)
                                if (error.errors[0].message === "Execution timed out.") {
                                    showToast(t(`Please wait while we're synchronizing your products. You will receive a notification once synchronization is finished.`), 10000);
                                }
                                else {
                                    showToast(t(`Something went wrong. Please contact the admin.`), "error");
                                }

                                setMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Product') {
                                        return { ...item, label: DateWithProductLabel, disabled: false, icon: '', style: { fontStyle: 'normal' }, };
                                    }
                                    return item;
                                }));

                            }
                        }
                    };
                }
                if (item.idName === 'Price') {
                    return {
                        ...item,
                        // label: DateWithPriceLabel, 
                        command: async () => {
                            setVisible(true)

                        }
                    };
                }

                return item;
            }));

        }
        if (props.syncTime && props.component === "contactDetails") {
            setContactMenuItems(prevItems => prevItems.map(item => {
                if (item.idName === 'Contacts') {
                    return {
                        ...item, label: DateWithContactLabel, command: async () => {
                            try {
                                setLoading((prevLoading) => ({ ...prevLoading, contactLoading: true }));
                                setContactMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Contacts') {
                                        return { ...item, label: 'Syncing Contacts...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
                                    }
                                    return item;
                                }));
                                showToast(t(`Contacts sync in progress... Please check after some time.`))
                                const response = await API.graphql({
                                    query: UpdateContactInformationDetails
                                });

                                // console.log("Response UpdateProductPrimaryDetails", response);
                                if (response) {
                                    setLoading((prevLoading) => ({ ...prevLoading, contactLoading: false }));
                                }

                                setContactMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Contacts') {
                                        return {
                                            ...item, label: DateWithContactLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
                                        };
                                    }
                                    return item;
                                }));
                            } catch (error) {
                                console.error("Contacts Sync error", error);
                                setLoading((prevLoading) => ({ ...prevLoading, contactLoading: false }));
                                if (error.errors[0].message.toString() === "Execution timed out.") {
                                    showToast(t(`Please wait while we're synchronizing your contacts. You will receive a notification once synchronization is finished.`), 10000);
                                }
                                else {
                                    showToast(t(`Something went wrong. Please contact the admin.`), "error");
                                }
                                setContactMenuItems(prevItems => prevItems.map(item => {
                                    if (item.idName === 'Contacts') {
                                        return {
                                            ...item, label: DateWithContactLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
                                        };
                                    }
                                    return item;
                                }));
                            }
                        }
                    };
                }

                return item;
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.syncTime])

    const SyncPrice = async () => {
        setVisible(false);
        try {
            setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: true }));

            setMenuItems(prevItems => prevItems.map(item => {
                if (item.idName === 'Price') {
                    return { ...item, label: 'Syncing Price...', disabled: true, icon: 'pi pi-spinner pi-spin', style: { fontStyle: 'italic' } };
                }
                return item;
            }));
            showToast(t(`Price sync in progress... Please check after some time.`))
            const response = await API.graphql({
                query: UpdateProductPriceDetails
            });

            // console.log("Response UpdateProductPriceDetails", response);
            if (response) {
                setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: false }));
            }

            setMenuItems(prevItems => prevItems.map(item => {
                if (item.idName === 'Price') {
                    return {
                        ...item, label: DateWithPriceLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
                    };
                }
                return item;
            }));
        } catch (error) {
            console.error("Price Sync error", error);
            setLoading((prevLoading) => ({ ...prevLoading, PriceLoading: false }));
            if (error.errors[0].message === "Execution timed out.") {
                showToast(t(`Please wait while we're synchronizing your price. You will receive a notification once synchronization is finished.`), 1000);
            }
            else {
                showToast(t(`something wents wrong. Please contact the admin.`), "error");
            }
            setMenuItems(prevItems => prevItems.map(item => {
                if (item.idName === 'Price') {
                    return {
                        ...item, label: DateWithPriceLabel, disabled: false, icon: '', style: { fontStyle: 'normal' },
                    };
                }
                return item;
            }));
        }
    }

    const PrimaryButton = "primary-button p-2 pr-4 pl-4";
    const footer = <>
        <div>
            <Button label={t(`No`)} outlined raised className="w-5rem" onClick={() => setVisible(false)}></Button>
            <Button label={t(`Yes`)} raised className="w-5rem" onClick={SyncPrice}></Button>
        </div>
    </>;
    return (
        <div>
            <Toast ref={toast}></Toast>

            <Dialog visible={Visible}
                style={{ width: '50vw' }}
                header={props.header}
                onHide={() => setVisible(false)}
                footer={footer}>

                <span >Please be cautious, if there are any base price updates done via upload option, it will be overwritten by SAP synchronization </span>
            </Dialog>
            {props.component === "ProductsView" && <span>
                <Menu model={menuItems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                <Button
                    label="Synchronize"
                    disabled={!(props.RowDatalength) || (loading.ProductLoading && loading.PriceLoading && loading.DiscountLoading)}
                    icon={(loading.ProductLoading && loading.PriceLoading && loading.DiscountLoading) ? 'pi pi-spinner pi-spin pl-1' : "pi pi-sync pl-1"}
                    className={`${PrimaryButton} `}
                    onClick={(event) => menuRight.current.toggle(event)}
                    aria-controls="popup_menu_right" aria-haspopup />

            </span>}
            {props.component === "contactDetails" && <span>
                <Menu model={ContactMenuItems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                <Button label="Synchronize"
                    disabled={!(props.RowDatalength) || (loading.contactLoading)}
                    icon={(loading.contactLoading) ? 'pi pi-spinner pi-spin pl-1' : "pi pi-sync pl-1"}
                    className={`${PrimaryButton}`}
                    onClick={(event) => menuRight.current.toggle(event)}
                    aria-controls="popup_menu_right" aria-haspopup />
            </span>}

        </div>
    )
}

export default SyncButton