import { Divider, FormControl, IconButton, InputAdornment, Tab, Tabs, TextField, useMediaQuery } from '@mui/material'
import { Button } from 'primereact/button'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Field, Formik } from 'formik'
// import RegisterForm from './RegisterForm.png';
import ProCustomer from "../../logo/ProCustomerPNG.png";
import MuiPhoneNumber from 'mui-phone-number';
import { API, Auth } from 'aws-amplify';
import { createClientDetails, createDemoUsers } from '../../graphql/mutations';
import { listClientDetails, listDemoUsers } from '../../graphql/queries';
import { InfoOutlined, YouTube } from '@mui/icons-material';
import { OverlayPanel } from 'primereact/overlaypanel';
import { showToast } from '../../components/ToastUtils';
import Loader from '../../components/Loader';
// import { Checkbox } from 'primereact/checkbox';
import './style.css';
import { TypeAnimation } from 'react-type-animation';
import { SuccessRegisterDialog } from './SuccessRegisterDialog';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../App';
import Nipurna from '../Login/nipurna.png'
import { useTranslation } from 'react-i18next';

const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
        <div hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
};

export default function Register() {
    const { t } = useTranslation();
    const [ButtonLoader, setButtonLoader] = useState(false);
    const [PageLoader, setPageLoader] = useState({ start: true, submit: false });
    const [SuccessPageView, setSuccessPageView] = useState(false)
    const op = useRef(null);
    // const [checked, setChecked] = useState(false);

    // const handleCheckboxChange = (e) => {
    //     setChecked(e.checked);
    // };


    const initialValues = {
        UserName: "",
        Email: "",
        PhoneNumber: "",
        tenant: "",
        CompanyID: "",
        Environment: "",
        OrganizationName: "",
        PortalURL: window.location.href.toLowerCase().split("/register")[0]
    }
    useEffect(() => {
        setTimeout(() => {
            setPageLoader({ ...PageLoader, start: false })
        }, 1000);
        //eslint-disable-next-line
    }, [])


    const validateUserName = (value) => {
        if (!value || value.trim() === "") {
            return "Field is Required";
        }
    }
    const validateEmail = (value) => {
        if (!value) {
            return "Field is Required"
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(value)) {
            return "Invalid email address";
        }
    }
    const validatePhoneNumber = (value) => {
        if (value.length <= 3) {
            return "Field is Required"
        }
    }
    const validateTenantID = (value) => {
        const tenantIDPattern = /^my\d{6}$/;
        if (!value || value.trim() === "") {
            return "Field is Required";
        }
        if (!tenantIDPattern.test(value)) {
            return "Enter a proper tenant ID";
        }
    }
    const validateCompanyID = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., 1210";
        }
        return error;
    }
    const validateOrganizationName = (value) => {
        if (!value || value.trim() === "") {
            return "Field is Required";
        }
    }
    const signOut = () => {

        try {
            Auth.signOut();
        }
        catch (error) {
            alert(error);
        }
        localStorage.clear();
        sessionStorage.clear();


    };

    const handleRegisterSumbitValue = async (SumbitValues, { resetForm }) => {
    const VerifyPhoneNumber = SumbitValues.PhoneNumber.replace(/[\s\-(){}]/g, '');
    const tenantWithCompanyID = SumbitValues.tenant + "-" + SumbitValues.CompanyID;

    const processedValues = {
        UserName: SumbitValues.UserName,
        Email: SumbitValues.Email,
        PhoneNumber: VerifyPhoneNumber,
        tenant: tenantWithCompanyID,   
        Environment: SumbitValues.Environment,
        OrganizationName: SumbitValues.OrganizationName,
        PortalURL: SumbitValues.PortalURL
    };
        const trimmedObject = {};
        for (const key in processedValues) {
            if (processedValues.hasOwnProperty(key) && typeof processedValues[key] === 'string') {
                trimmedObject[key] = processedValues[key].trim();
            } else {
                trimmedObject[key] = processedValues[key];
            }
        }
        // console.log("values", trimmedObject)
        setButtonLoader(true)
        try {
            const authResponse = await Auth.signIn({
                username: `nipurnaadmin`,
                password: 'Nipurna@321'
            })

            if (authResponse.storage["CognitoIdentityServiceProvider.7oeb5vap37arerq6bsf9hs445e.nipurnaadmin.idToken"]) {
                try {
                    // const RegisterFormResponse = await API.graphql({
                    //     query: listClientDetails,
                    //     variables:
                    //     {
                    //         filter: {
                    //             or: [
                    //                 { tenant: { eq: trimmedObject.tenant } },
                    //                 { PhoneNumber: { eq: trimmedObject.PhoneNumber.toString() } },
                    //                 { Email: { eq: trimmedObject.Email } }
                    //             ]

                    //         }
                    //     }
                    // })
                    // // console.log("RegisterFormResponse", RegisterFormResponse)

                    // const clientDetails = RegisterFormResponse && RegisterFormResponse.data && RegisterFormResponse.data.listClientDetails && RegisterFormResponse.data.listClientDetails.items[0];
                    // const PhoneNumberVerify = Boolean(clientDetails && clientDetails.PhoneNumber === trimmedObject.PhoneNumber.toString())
                    // const EmailVerify = Boolean(clientDetails && clientDetails.Email === trimmedObject.Email);
                    // if (RegisterFormResponse.data.listClientDetails.items.length === 0) {
                    //     try {
                            await API.graphql({
                                query: createClientDetails,
                                variables:
                                {
                                    input: trimmedObject
                                }
                            })
                            setPageLoader({ ...PageLoader, submit: true })
                            setButtonLoader(false)
                            signOut();
                            resetForm();
                            setTimeout(() => {
                                setSuccessPageView(true);
                                setPageLoader({ ...PageLoader, submit: false })
                            }, 2000);
                    //     }
                    //     catch (error) {
                    //         console.error("Register form create error", error)
                    //         setButtonLoader(false)
                    //     }

                    // }
                    // else if (EmailVerify) {
                    //     showToast(t('Email ID already registered.'), "warning");
                    //     setButtonLoader(false);
                    // }
                    // else if (PhoneNumberVerify) {
                    //     showToast(t(`Phone number already registered.`), "warning");
                    //     setButtonLoader(false);
                    // }
                    // else {
                    //     showToast(t(`Tenant ID already registered.`), "warning");
                    //     setButtonLoader(false);
                    // }

                }
                catch (error) {
                    console.error("RegisterForm List error", error)
                    setButtonLoader(false)
                    signOut()
                }

            }
            // console.log("authResponse", authResponse)
        }
        catch (error) {
            console.error("User Error Occured in Registor form", error);
            setButtonLoader(false)
        }




    }

    const handleDemoSubmitValue = async (SumbitValues, { resetForm }) => {
        let values = SumbitValues;
        values.PhoneNumber = values.PhoneNumber.replace(/[\s\-(){}]/g, '');

        const trimmedObject = {};
        for (const key in values) {
            if (values.hasOwnProperty(key) && typeof values[key] === 'string') {
                trimmedObject[key] = values[key].trim();
            } else {
                trimmedObject[key] = values[key];
            }
        }

        if (['@gmail', '@yahoo', '@hotmail'].some(domain => trimmedObject.Email.toLowerCase().includes(domain))) {
            showToast("Personal emails are not allowed. Use your company email.")
        }
        else {
            SignUp(trimmedObject, resetForm);
        }

    }

    const handleValidateFunctions = (values) => {
        if (!values.UserName || !values.Email || !values.PhoneNumber || !values.tenant || !values.OrganizationName) {
            showToast(t(`All fields are mandatory. Please ensure they are filled before proceeding.`), "info");
        }
    }
    const handleDemoValidateFunctions = (values) => {
        if (!values.UserName || !values.Email || !values.PhoneNumber) {
            showToast(t(`All fields are mandatory. Please ensure they are filled before proceeding.`), "info");
        }
    }

    const CloseSuccess = () => {
        setSuccessPageView(false);
    }

    const navigate = useNavigate();
    const contextVariableLogin = useContext(LoginContext);
    const [exploreBtnLoader, setExpolorBtnLoader] = useState(false);


    const SignUp = async (DemoDetails, resetForm) => {
        // console.log("DemoDetails", DemoDetails)
        setExpolorBtnLoader(true);
        try {
            const authResponse = await Auth.signIn({
                username: `demouser`,
                password: 'DemoUser@01'
            })
            if (authResponse) {
                // console.log("authResponse", authResponse)

                try {
                    const DemoUserAvailableResponse = await API.graphql(
                        {
                            query: listDemoUsers,
                            variables: {
                                filter: {
                                    Email: { eq: DemoDetails.Email }

                                }
                            },
                        }
                    )
                    if (DemoUserAvailableResponse.data.listDemoUsers.items.length > 0) {

                        if (DemoUserAvailableResponse.data.listDemoUsers.items[0].VerifiedUser === true) {
                            const groups = authResponse.signInUserSession.idToken.payload["cognito:groups"];
                            localStorage.setItem("CognitoIdentityServiceProvider", "true");
                            contextVariableLogin.setUserGroups(groups[0]);
                            navigate("/");
                            resetForm();
                        }
                        else {
                            showToast("Thank you for reaching us. We will send a mail soon.","success")
                            signOut();
                        }

                    }
                    else {
                        try {
                            const DemoUserDetail = {
                                UserName: DemoDetails.UserName,
                                Email: DemoDetails.Email,
                                PhoneNumber: DemoDetails.PhoneNumber,
                                VerifiedUser: false
                            }
                            // const DemoDetailsCreate =
                            await API.graphql({
                                query: createDemoUsers,
                                variables:
                                {
                                    input: DemoUserDetail
                                }
                            })
                            showToast("Thank you for reaching us. We will send a mail soon.","success")
                            // console.log("DemoDetailsCreate", DemoDetailsCreate);
                            signOut();
                        }
                        catch (error) {
                            console.error("Demo Login Error", error)
                            signOut();
                        }
                    }
                    // console.log("DemoUserAvailableResponse", DemoUserAvailableResponse)
                } catch (error) {
                    signOut();
                    console.log("Demo Users list Table ERror", error);
                }



            }


        }
        catch (error) {
            console.error("Demo Error", error);
            signOut();
        }
        finally {
            setExpolorBtnLoader(false);
        }

    }

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const IsTab = useMediaQuery("(max-width: 769px)");
    return (
        <>

            {SuccessPageView && <SuccessRegisterDialog CloseSuccess={CloseSuccess}></SuccessRegisterDialog>}



            {(PageLoader.submit || PageLoader.start) &&
                <div style={{ position: "absolute", zIndex: "100", backgroundColor: "rgba(251, 245, 245, 0.667)" }}
                    className='w-100 h-screen flex justify-content-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div style={{ marginTop: '20%' }}>
                            <div className='text-center'>
                                <img src={ProCustomer} alt="proCustomer" style={{ height: '30px' }}></img>
                                <Loader />
                            </div> <br />
                        </div>
                    </div>
                </div>}

            {(!PageLoader.start) &&
                <div className='w-100 flex '>

                    {/* First Half */}
                    <div className={IsTab ? "w-50 hidden" : "w-50"}>
                        <div className="background-layer"></div>
                        <video id='BG_video' autoPlay loop muted>
                            <source src={process.env.PUBLIC_URL + '/BG.mp4'} type="video/mp4" />
                        </video>
                        <div className="animated-text">
                            <p className='font-bold text-whit text-xl'>
                                <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        "Unlocking B2B potential with cutting-edge solutions.",
                                        1000,
                                        "Real-Time Order Placement & Tracking.",
                                        1000,
                                        "Effortless Payment and Invoicing.",
                                        1000,
                                        "Superior data control.",
                                        1000,
                                        "Simplified Integration.",
                                        1000,
                                        "Unparalleled User Experience and Reliability.",
                                        1000,
                                        "User-Friendly Interface",
                                        1000,
                                        "Enhanced Security",
                                        1000

                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    style={{ fontSize: '2em', display: 'inline-block' }}
                                    repeat={Infinity}
                                />
                            </p>
                        </div>
                    </div>

                    {/* Second half  */}
                    <div className={IsTab ? 'w-100 pt-1 pr-4 pl-4 pb-2' : "w-50 pl-5 pr-5 mt-5"}>


                        <div className='flex justify-content-center align-items-center lg:ml-8'>
                            {IsTab ? <img src={ProCustomer} style={{ height: "60px", width: "200px" }} alt='Pro customer logo' /> :
                                <img src={ProCustomer} style={{ height: "80px", width: "280px" }} alt='Pro customer logo' />}
                        </div>

                        <div className='lg:ml-8 flex flex-column shadow-1 border-round-xl '>
                            <Tabs value={value} onChange={handleChange} className='w-100'>
                                <Tab label="Register Form " className='text-base lg:text-base capitalize w-50' />
                                <Tab label="Explore Demo " className='text-base capitalize w-50 ' />
                            </Tabs>

                            <TabPanel value={value} index={0}>
                                <div className='mt-3 md:mt-4 lg:mt-4'>
                                    <Formik initialValues={initialValues}
                                        onSubmit={handleRegisterSumbitValue}
                                    >
                                        {({ values, handleSubmit, setFieldValue, errors, touched }) =>
                                            <div className='pl-5 pr-5'>
                                                {/* <div className='font-bold flex justify-content-start text-xl  text-primary'>Register</div>
                                        <Divider></Divider> */}

                                                <div className='flex gap-2 align-items-center'>
                                                    <span className='text-base md:text-xl lg:text-xl font-semibold' style={{ color: "#344955" }}>{"Hello there 👋 "}</span>
                                                </div>

                                                <div className='w-100 mt-3'>

                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={TextField} label="Fullname" size="small"
                                                            name="UserName" value={values.UserName} validate={validateUserName}
                                                            onChange={(e) => {
                                                                const alphabeticValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                                                setFieldValue('UserName', alphabeticValue);
                                                            }}
                                                            // helperText={(touched.UserName && errors.UserName)}
                                                            error={touched.UserName && Boolean(errors.UserName)}
                                                        >
                                                        </Field>
                                                    </FormControl>
                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={TextField} label="Email ID" size="small"
                                                            name="Email"
                                                            value={values.Email}
                                                            onChange={(e) => {
                                                                setFieldValue('Email', e.target.value.toLowerCase());
                                                            }}
                                                            // helperText={(touched.Email && errors.Email)}
                                                            error={touched.Email && Boolean(errors.Email)}
                                                            validate={validateEmail}
                                                        >
                                                        </Field>
                                                    </FormControl>
                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={MuiPhoneNumber}
                                                            defaultCountry={'us'}
                                                            onChange={(value) => setFieldValue('PhoneNumber', value)}
                                                            variant='outlined'
                                                            size="small"
                                                            name="PhoneNumber"
                                                            validate={validatePhoneNumber}
                                                            // helperText={(touched.PhoneNumber && errors.PhoneNumber)}
                                                            error={touched.PhoneNumber && Boolean(errors.PhoneNumber)}
                                                            label="Phone Number"
                                                        />
                                                    </FormControl>
                                                    <FormControl fullWidth style={{ marginBottom: "2rem" }}>
                                                        <Field
                                                            as={TextField} label="Tenant ID" size="small"
                                                            name="tenant"
                                                            value={values.tenant}
                                                            onChange={(e) => {
                                                                setFieldValue('tenant', e.target.value.toLowerCase())
                                                            }

                                                            }
                                                            validate={validateTenantID}
                                                            // helperText={(touched.tenant && errors.tenant)}
                                                            error={touched.tenant && Boolean(errors.tenant)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <IconButton onClick={(e) => op.current.toggle(e)}>
                                                                        <InfoOutlined>
                                                                        </InfoOutlined>
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }} >  </Field>

                                                    </FormControl>
                                                    <FormControl fullWidth style={{ marginBottom: "2rem" }} >
                                                        <Field
                                                            as={TextField} label="Company ID*"
                                                            // helperText={touched.CompanyID && errors.CompanyID} 
                                                            // helperText={(touched.CompanyID && errors.CompanyID) ? errors.CompanyID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 1210"}
                                                            size="small" type="text"
                                                            onChange={(e) => {
                                                                setFieldValue('CompanyID', e.target.value);
                                                            }}
                                                            error={touched.CompanyID && Boolean(errors.CompanyID)}
                                                            name="CompanyID"
                                                            value={values.CompanyID}
                                                            validate={validateCompanyID}
                                                        />
                                                    </FormControl>

                                                    {/* <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                    <Field name="Environment" validate={validateEnvironment}>
                                                        {({ field, form }) => (
                                                            <Autocomplete
                                                                size="small"
                                                                disableClearable
                                                                fullWidth
                                                                options={EnvironmentDropDown}
                                                                getOptionLabel={(option) => option.label}
                                                                value={EnvironmentDropDown.find(
                                                                    (option) => option.value === field.value
                                                                ) || null
                                                                }
                                                                onChange={(event, value) => {
                                                                    form.setFieldValue(
                                                                        "Environment",
                                                                        value?.value || ""
                                                                    );

                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Environment"
                                                                        variant="outlined"
                                                                        error={
                                                                            form.touched.Environment &&
                                                                            Boolean(form.errors.Environment)
                                                                        }
                                                                    // helperText={
                                                                    //     form.touched.Environment &&
                                                                    //     form.errors.Environment
                                                                    // }
                                                                    />
                                                                )}
                                                            >

                                                            </Autocomplete>
                                                        )}

                                                    </Field>
                                                </FormControl> */}

                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={TextField} label="Organization Name" size="small"
                                                            name="OrganizationName"
                                                            value={values.OrganizationName}
                                                            onChange={(e) => setFieldValue('OrganizationName', e.target.value)}
                                                            validate={validateOrganizationName}
                                                            // helperText={(touched.OrganizationName && errors.OrganizationName)}
                                                            error={touched.OrganizationName && Boolean(errors.OrganizationName)}
                                                        >
                                                        </Field>
                                                    </FormControl>
                                                    {/* <FormControl fullWidth style={{ marginBottom: "2rem" }}>
                                                    <div className='flex justify-content-between align-items-center'>
                                                        <div>
                                                            <Checkbox
                                                                inputId="acceptTerms"
                                                                checked={checked}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor="acceptTerms" className="ml-2 font-semibold">
                                                                I accept the terms and conditions
                                                            </label>
                                                        </div>
                                                    </div>
                                                </FormControl> */}
                                                </div>

                                                <div className='mt-2'>
                                                    <Button
                                                        type='button'
                                                        className='flip  animation-duration-1000 block font-semibold text-base raised w-100'
                                                        icon={ButtonLoader ? "pi pi-spinner pi-spin" : ""}
                                                        disabled={ButtonLoader}
                                                        onClick={() => {
                                                            handleValidateFunctions(values);
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        {" "} Register
                                                    </Button>
                                                </div>
                                            </div>}
                                    </Formik>

                                    <div className='flex w-100 lg:mt-3 pl-5 pr-5'>

                                        <div className='mt-3 md:pl-7 pb-2 flex justify-content-center  w-100   '>

                                            <div className='youtube-link '>
                                                <a href="https://www.youtube.com/@NipurnaIT" target="_blank" rel="noopener noreferrer">
                                                    <IconButton>
                                                        {/* <YouTube sx={{ color: "var(--secondary-color)" }} /> */}
                                                        <YouTube sx={{ color: "red" }} /> <span className='text-xs ml-1 font-semibold'>Youtube</span>
                                                    </IconButton>
                                                </a>
                                            </div>
                                            <div className="vertical-line"></div>
                                            <div className='website-link ml-2  w-25 flex justify-content-center align-items-center'>
                                                <a href="https://nipurnait.com/" target="_blank" rel="noopener noreferrer">
                                                    <img src={Nipurna} className='w-14 md:w-6 lg:w-8' alt="" />
                                                    {/* <IconButton>
                                                        <LanguageOutlined sx={{ color: "skyblue" }} />
                                                    </IconButton> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>


                            <TabPanel value={value} index={1}>
                                <div className='mt-3 md:mt-4 lg:mt-4'>
                                    <Formik initialValues={initialValues}
                                        onSubmit={handleDemoSubmitValue}
                                    >
                                        {({ values, handleSubmit, setFieldValue, errors, touched }) =>
                                            <div className='pl-5 pr-5'>


                                                <div className='flex gap-2 align-items-center'>
                                                    <span className='text-base md:text-xl lg:text-xl font-semibold' style={{ color: "#344955" }}>{"Hello there 👋 "}</span>
                                                </div>

                                                <div className=' mt-3'>
                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={TextField} label="Fullname" size="small"
                                                            name="UserName" value={values.UserName} validate={validateUserName}
                                                            onChange={(e) => {
                                                                const alphabeticValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                                                                setFieldValue('UserName', alphabeticValue);
                                                            }}
                                                            // helperText={(touched.UserName && errors.UserName)}
                                                            error={touched.UserName && Boolean(errors.UserName)}
                                                        >
                                                        </Field>
                                                    </FormControl>

                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={TextField} label="Email ID" size="small"
                                                            name="Email"
                                                            value={values.Email}
                                                            onChange={(e) => {
                                                                setFieldValue('Email', e.target.value.toLowerCase());
                                                            }}
                                                            // helperText={(touched.Email && errors.Email)}
                                                            error={touched.Email && Boolean(errors.Email)}
                                                            validate={validateEmail}
                                                        >
                                                        </Field>
                                                    </FormControl>

                                                    <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                        <Field
                                                            as={MuiPhoneNumber}
                                                            defaultCountry={'us'}
                                                            onChange={(value) => setFieldValue('PhoneNumber', value)}
                                                            variant='outlined'
                                                            size="small"
                                                            name="PhoneNumber"
                                                            validate={validatePhoneNumber}
                                                            // helperText={(touched.PhoneNumber && errors.PhoneNumber)}
                                                            error={touched.PhoneNumber && Boolean(errors.PhoneNumber)}
                                                            label="Phone Number"
                                                        />
                                                    </FormControl>


                                                </div>

                                                <div className='mt-2 pb-3'>
                                                    <Button
                                                        className=' flip   animation-duration-1000 block font-semibold text-base raised w-100 '
                                                        icon={exploreBtnLoader ? "pi pi-spinner pi-spin" : "pi pi-external-link"}
                                                        disabled={exploreBtnLoader}
                                                        label='Demo'
                                                        onClick={() => {
                                                            handleDemoValidateFunctions(values);
                                                            handleSubmit();
                                                        }}
                                                        type='button'
                                                    ></Button>

                                                </div>
                                            </div>}
                                    </Formik>

                                    <div className='flex w-100 lg:mt-3 pl-5 pr-5'>

                                        <div className='mt-2 md:pl-7 pb-2 flex justify-content-center  w-100'>

                                            <div className='youtube-link '>
                                                <a href="https://www.youtube.com/@NipurnaIT" target="_blank" rel="noopener noreferrer">
                                                    <IconButton>
                                                        {/* <YouTube sx={{ color: "var(--secondary-color)" }} /> */}
                                                        <YouTube sx={{ color: "red" }} /> <span className='text-xs ml-1 font-semibold'>Youtube</span>
                                                    </IconButton>
                                                </a>
                                            </div>
                                            <div className="vertical-line"></div>
                                            <div className='website-link ml-2  w-25 flex justify-content-center align-items-center'>
                                                <a href="https://nipurnait.com/" target="_blank" rel="noopener noreferrer">
                                                    <img src={Nipurna} className='w-14 md:w-6 lg:w-8' alt="" />
                                                    {/* <IconButton>
                                                        <LanguageOutlined sx={{ color: "skyblue" }} />
                                                    </IconButton> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>

                    </div>


                </div>}
            <OverlayPanel ref={op} className='w-15rem' >
                <div>
                    <div className='font-semibold'>Info</div>
                    <Divider className='bg-secondary'></Divider>
                    <div className='text-xs' style={{ overflow: 'auto', maxHeight: '200px' }}>
                        <div>Please provide your Tenant ID</div>
                        <div>For example, if your SAP URL is 'https://my123456.sapbydesign.com/', then your </div>
                        <div className='font-semibold'>Tenant ID is 'my123456'."</div>
                    </div>
                </div>
            </OverlayPanel>

        </>
    )
}