import { Storage } from 'aws-amplify';
import { Button } from 'primereact/button';
import React from 'react'
import { useTranslation } from 'react-i18next';

const DownloadExcelTemplate = (props) => {
  const { t } = useTranslation();


  const handleDownload = async () => {
    let fileName;
    if (props.priceOption.value === 'BP') {
      fileName = 'Base_Price_Template.csv';
    }
    else if (props.priceOption.value === 'CSD') {
      fileName = 'Customer_Specific_Discount_Template.csv';
    }
    else if (props.priceOption.value === 'CSP') {
      fileName = 'Customer_Specific_Price_Template.csv';
    }

    try {
      // const url = await Storage.get(`my343736/AdditionalPricingOptions/CSV_Template/${fileName}`);
      const url = await Storage.get(`CSV_Template/${fileName}`);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'BasePrice_Template.csv');
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      props.closeDialog();
    }
    catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className='flex gap-2 justify-content-start align-items-center w-full'>
      <Button
        className='w-11rem'
        icon="pi pi-download"
        label={t(`Template`)}
        onClick={handleDownload}
      />
      <span className='text-xs text-gray-500'>
        *{t(`Download the template to enter your price details, ensuring`)}{" "}
        <span className="font-semibold">
          {t(`not to change the column names`)}.
        </span>
        {" "}{t(`Alternatively, you can also download the existing price, modify and upload the same`)}.
      </span>
    </div>
  )
}

export default DownloadExcelTemplate; 