import { ArrowBack } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react'
import AdminGridData from '../../../../components/Admin/AdminGridData';
import { AppContext } from '../../../../Layout/Layout';

const ProductImageUploadView = (props) => {
  const contextVariable = useContext(AppContext);
  const [rowData, setRowData] = useState(1);
  const columnDefs = [
    {
      headerName: "S.No",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      flex: 0.3,
      headerClass: "custom-ag-header",
    },
    { field: "ProductImageName", headerName: "Image Name", filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    { field: "ErrorMessage", headerName: "Error Message", filter: true, flex: 0.7, headerClass: 'custom-ag-header' },
  ];

  useEffect(() => {
    if (contextVariable.rootObject.FailedImages.items.length !== 0) {
      setRowData(contextVariable.rootObject.FailedImages.items);
    }
    else {
      setRowData(0);
    }
  }, [contextVariable]);

  const gotToProductImageUpload = () => {
    props.backToProductImageUpload();
  }

  const selectedRowsHandler = (rows) => {
  }

  return (
    <>
      {sessionStorage.setItem("title", "Error Details")}

      <Box className="admin-box-container">
        <Button className="secondary-button p-button-sm mb-2" onClick={gotToProductImageUpload}>
          <ArrowBack />
          <span>  Back to Product Image Upload</span>
        </Button>
        {/* Datagrid Card */}
        <div>
          <div className='text-center mb-3'>
            <span
              className='px-2 py-1 text-primary-600 bg-primary-100 border-round-md font-semibold'
            >
              Total no.of failed images : {rowData.length || 0}
            </span>
          </div>
          <div>
            <AdminGridData
              rowData={rowData}
              columnDefs={columnDefs}
              tableHeight={'65vh'}
              needFilters={false}
              showPagination={false}
              selectedRows={selectedRowsHandler}
            />
          </div>
        </div>
      </Box>
    </>
  )
}

export default ProductImageUploadView;