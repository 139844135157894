import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Tooltip, Typography, Zoom, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import './Sidebar.css';
import {
  AccountBoxOutlined, CloseOutlined, CreditScoreOutlined, DeliveryDining, History,
  InsertChartOutlinedOutlined, InventoryOutlined, LogoutOutlined, MenuBook, RequestQuoteOutlined,
} from "@mui/icons-material";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import { AppContext } from "../../Layout/Layout";
import { useFade } from "../../components/FadeContext/FadeContext";
import DialogComponent from "../../components/DialogComponent";

const Item = ({ title, to, icon, setSelected, setLogout }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { triggerFadeIn } = useFade();
  const contextVariable = useContext(AppContext);
  const [isSelected, setIsSelected] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTab = useMediaQuery('(max-width: 768px)');
  // const { show, setShow } = useContext(AppContext);

  useEffect(() => {
    if (to === "/") {
      setIsSelected(location.pathname === to);
    } else {
      setIsSelected(location.pathname.startsWith(to));
    }

  }, [location.pathname, to]);

  const handleNavLinkClick = () => {
    if (to) {
      triggerFadeIn();
    }
    contextVariable.setGlobalSearch("");
    contextVariable.setDate(new Date().getFullYear())
    setSelected(title);
    contextVariable.setIsCollapsed(true);
    if (to === '/productCatalog') {
      contextVariable.setFavouritesTab(0);
    }
    if (isMobile || isTab) {
      contextVariable.setIsSidebarOpen(!contextVariable.isSidebarOpen);
    }
  };

  return (
    <>
      {
        contextVariable.isCollapsed === true ?
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'var(--sidebar-color)',
                },
              },
            }}
            title={
              <span className="tooltip-fontsize">
                {contextVariable.isCollapsed ? t(title) : ''}
              </span>
            }
            style={{ fontSize: '25px' }}
            placement="right"
            TransitionComponent={Zoom}
            enterDelay={500}
          >
            <div>
              <MenuItem
                className="menuIcon"
                active={isSelected}
                style={{
                  color: 'var(--menuItem-color)',
                  // color: 'var(--primary-color)',
                }}
                onClick={handleNavLinkClick}
                icon={icon}
              >
                {contextVariable.isCollapsed ? '' : <Typography>{title}</Typography>}
                {!setLogout && <NavLink to={to}></NavLink>}
              </MenuItem>
            </div>
          </Tooltip>

          :
          <>
            <MenuItem
              className="menuIcon"
              active={isSelected}
              style={{
                color: 'var(--menuItem-color)',
                // color: 'var(--primary-color)',
              }}
              onClick={handleNavLinkClick}
              icon={icon}
            >
              {
                contextVariable.isCollapsed ? '' : <Typography>{title}</Typography>
              }
              {
                !setLogout && <NavLink to={to}></NavLink>
              }
            </MenuItem>
          </>
      }
    </>
  );
};

const Sidebar = (props) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [selected, setSelected] = useState("");
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleLogoutClick = () => {
    contextVariable.setShow("Logout");
  };

  useEffect(() => {
    if (isMobile === false) {
      contextVariable.setIsSidebarOpen(false);
    }
    //eslint-disable-next-line
  }, [isMobile, contextVariable.setIsSidebarOpen]);

  return (
    <Box className="sidebarContainer"
      sx={{
        "& .pro-sidebar": {
          display: contextVariable.isSidebarOpen ? 'none' : 'block',
        },
        "& .pro-sidebar-inner": {
          background: `var(--sidebar-color)`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          paddingTop: "7px !important",
          paddingBottom: "5px !important",
          paddingLeft: contextVariable.rtl ? '0px !important' : '17px !important',
          paddingRight: contextVariable.rtl ? '17px !important' : '0px !important'
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center"
        },
        "& .pro-menu-item.active": {
          color: `var(--sidebar-color) !important`,
          background: `white`,
        },
        '& [data-testid="MenuOutlinedIcon"]': {
          color: 'var(--menuItem-color)', // Set the color of the element with data-testid="MenuOutlinedIcon"
        },
        "& .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul": {
          width: "100%"
        },
        "& .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout ul div li :hover": {
          color: `var(--sidebar-color)`,
          background: `white`,
        },

        "& .MuiBox-root css-1m3bo6 > button": {
          color: `red !important`,
        }
      }}
    >
      <div>
        <ProSidebar
          rtl={false}
          collapsed={contextVariable.isCollapsed}
          breakPoint={props.isOpen ? "xxl" : ""}
        // closeOnClick ={true}
        >
          <div>
            <Menu iconShape="square">
              <IconButton
                style={{ display: isMobile ? 'block' : 'none' }}
                onClickCapture={() => contextVariable.setIsSidebarOpen(!contextVariable.isSidebarOpen)}>
                <CloseOutlined style={{ color: "#ffffff" }} />
              </IconButton>
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClickCapture={() => {
                  contextVariable.setIsCollapsed(!contextVariable.isCollapsed);
                  // setIsSidebarCollapsed(!isSidebarCollapsed);
                }
                }
                // icon={isCollapsed ? <MenuOutlinedIcon style={{ marginRight: '-5rem !important' }} /> : undefined}
                icon={<MenuOutlinedIcon />}
                style={{
                  margin: "12px 0 12px 0",
                  // borderBottom: "1px solid lightgray",
                  // color: "#FF8A00",
                }}
              >
                {!contextVariable.isCollapsed && (
                  <Box
                    display="flex"
                    gap={5}
                    alignItems="center"
                    // ml="10px"
                    style={{
                      // borderBottom: "1px solid lightgray",
                      color: "#ffffff",
                    }}
                  >
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      Pro Customer
                      {/* {contextVariable.taxDetails.CompanyName} */}
                    </Typography>
                  </Box>
                )}
              </MenuItem>

              <Box display="flex" flexDirection="column" height="100%">
                {/* Your other menu items */}
                <Item
                  title={t('Dashboard')}
                  style={{ fontSize: '20px !important' }}
                  to="/"
                  // icon={<HomeOutlinedIcon />}
                  icon={<InsertChartOutlinedOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Product Catalog')}
                  to="/productCatalog"
                  icon={<MenuBook />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Order')}
                  to="/order"
                  icon={<InventoryOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                {/* Your other menu items */}
                <Item
                  title={t('Delivery')}
                  to="/delivery"
                  icon={<DeliveryDining />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Invoice & payments')}
                  to="/invoice"
                  icon={<RequestQuoteOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Credit Note')}
                  to="/creditNote"
                  icon={<CreditScoreOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                {
                  (contextVariable.taxDetails.StripeDefaultIndicator === "true") &&
                  <Item
                    title={t('Payment history')}
                    to="/paymentHistory"
                    icon={<History />}
                    selected={selected}
                    setSelected={setSelected}
                    // setIsCollapsed={setIsCollapsed}
                    setLogout={false}
                  />
                }

                <Item
                  title={t('Account Statement')}
                  to="/statementOfAccount"
                  icon={<AccountBoxOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />

                {/* Move the Logout item below */}
                <div
                  onClick={handleLogoutClick}
                  style={{ position: 'absolute', top: '92%', width: '100%' }}>

                  <Item
                    title={t('Logout')}
                    selected={selected}
                    setSelected={setSelected}
                    // setIsCollapsed={setIsCollapsed}
                    icon={<LogoutOutlined />}
                    setLogout={true}
                  />
                </div>
              </Box>

              {/* Logout item */}
              {/* <Box >
                <LogoutItem
                  title={t('logout')}
                  // to="/logOut"
                  icon={<Logout />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsCollapsed={setIsCollapsed}
                />
              </Box> */}
            </Menu>
          </div>
        </ProSidebar>
      </div>
      {contextVariable.show === "Logout" && (
        <DialogComponent
          header={t('Logout')}
          title={t('Are you sure you want to logout?')}
        // signout={props.SīgnOut}
        />
      )}
    </Box>
  );
};

export default Sidebar;
