import React, { useContext, useEffect, useState } from 'react'
import TruncateText from '../../components/TruncateText';
import { Tooltip, Zoom, useMediaQuery } from '@mui/material';
import { Button } from 'primereact/button';
import { handleAddToCart } from '../../components/AddAndRemoveFunctions';
import Authorizedimages from '../../components/cards/Authorizedimages';
import { AppContext } from '../../Layout/Layout';
import { showToast } from '../../components/ToastUtils';
import CurrencyFormat from '../../components/CurrencyFormat';
import { Dialog } from "primereact/dialog";
import { AddShoppingCart } from '@mui/icons-material';
import UOMSection from '../ProductCatalog/ProductCardComponents/UOMSection';
import { ListByProductInventoryDetails } from '../../graphql/queries';
import { API } from 'aws-amplify';
import UOMConversion from '../../components/UOMConversion';
import { useTranslation } from 'react-i18next';

function TrendingProductCard({ TrendingProduct, index, AllTrendingProducts }) {
    const { t } = useTranslation();
    const getSymbolFromCurrency = require("currency-symbol-map");
    const isMobile = useMediaQuery('(max-width: 599px)');
    const isTab = useMediaQuery('(max-width: 768px)');
    const contextVariable = useContext(AppContext);
    // const [buttonLoading, setButtonLoading] = useState(false);
    const ProductListData = TrendingProduct;

    const [count, setCount] = useState(1);

    const handleInputChange = (event) => {
        const enteredValue = event.target.value.trim();
        if (
            event.target.value === "" ||
            event.key === "Backspace" ||
            event.key === "Delete"
        ) {
            setCount("");
        } else {
            const numericValue = enteredValue.replace(/[^0-9]/g, "");
            const value = Number(numericValue);
            const ShowToastUOM = contextVariable.AllUOMDetails.find(P => P.Value === data.ChangeUOM || StockUom)?.Description || '';
            if (ProductStock === "NoLimit") {
                const newValue = Math.min(Math.max(value, 1), (data.UOMStockAvl || 9999));
                setCount(newValue);
                if (value > data.UOMStockAvl || value > 9999) {
                    showToast(`${t(`Maximum limit of`)} ${data.UOMStockAvl || 9999} ${ShowToastUOM} ${t(`reached`)}.`, "Info", 2000)
                }
            }
            else if (ProductStock > 0) {
                const newValue = Math.min(Math.max(value, 1), (data.UOMStockAvl || ProductStock));
                setCount(newValue);
                if (value > ProductStock || value > data.UOMStockAvl) {
                    showToast(`${t(`Only`)} ${data.UOMStockAvl || ProductStock} ${ShowToastUOM} ${t(`are currently available in stock`)}.`, "Info", 3000);
                }
            }

        }


    };

    const handleInputBlur = () => {
        // If input is left empty, set it to 1 on blur
        if (count === "") {
            setCount(1);
        }
    };

    const productDiscountConfirmation = (ProductListData.ProductsDiscounts && ProductListData.ProductsDiscounts.items && ProductListData.ProductsDiscounts.items.length !== 0) ? ProductListData.ProductsDiscounts.items[0].ProductDiscount : 0.00;
    const handleAddToCartCount = async () => {

        if ((contextVariable.taxDetails.InventoryStatus ? ProductStock : 9999) < count) {
            showToast(t(`You are trying to add more than the available stock.`), "Info", 2000);
        }
        else if ((contextVariable.taxDetails.InventoryStatus ? ProductStock : 9999) >= count) {
            ProductListData.buttonLoading = true;
            contextVariable.setAddRemoveCartLoading(true);
            const addDataProps = {
                ProductQuantity: count,
                ProductQuantityText: ProductListData.ProductUOM,
                ProductPriceUOMCode: data.ChangeUOM || data.ProductPriceQuantityCode,
                CalculatedUOMQuantity: data.UOMQuantity ? count * parseFloat(parseFloat(data.UOMQuantity).toFixed(2)) : count,
                owner: contextVariable.userDetails.sub,
                ProductPrice: parseFloat(ProductListData.ProductPrice),
                ProductDiscount: productDiscountConfirmation,
                userProductsCartProductdetailsId: ProductListData.id,

            };
            contextVariable.setCartItemsIds((prevIds) => {
                const AddId = new Set(prevIds);
                AddId.add(ProductListData.id);
                return Array.from(AddId)
            })
            await handleAddToCart(contextVariable, ProductListData, addDataProps);
            if (contextVariable.taxDetails.InventoryStatus) {
                setProductStock((prevStock) => prevStock - (data.UOMQuantity ? count * parseFloat(parseFloat(data.UOMQuantity).toFixed(2)) : count))
            }
            sessionStorage.removeItem("cartDetails")
            ProductListData.buttonLoading = false;
            contextVariable.setAddRemoveCartLoading(false);
            setVisible(false);
            setData({});
            setCount(1);
        }
    };

    const [visible, setVisible] = useState(false);
    const handleSlideAddToCart = async () => {
        setVisible(true);

    };
    const [data, setData] = useState({});


    const [ProductStock, setProductStock] = useState("Loading");
    const [StockUom, setStockUom] = useState("");

    useEffect(() => {
        if (contextVariable.taxDetails.InventoryStatus && contextVariable.AllUOMDetails.length > 0) {
            const FetchProductStock = async () => {
                try {
                    const ProductStockDetails = await API.graphql({
                        query: ListByProductInventoryDetails,
                        variables:
                        {
                            MaterialID: ProductListData.id
                        }
                    })
                    // console.log("productStockdetails", ProductStockDetails)
                    if (ProductStockDetails && ProductStockDetails.data.ListByProductInventoryDetails.SearchResult.length > 0) {
                        const ProductStockCount = Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) > 0 ?
                            Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) : 0;
                        if (contextVariable.UserCartItems.length > 0) {
                            const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === ProductListData.id);
                            if (FindSameProductStockReduce.length > 0) {
                                const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                                // console.log("TotalStockValue", TotalStockValue)
                                setProductStock(ProductStockCount - TotalStockValue < 0 ? 0 : ProductStockCount - TotalStockValue)
                            }
                            else {
                                setProductStock(ProductStockCount)
                            }

                        }
                        else {
                            setProductStock(ProductStockCount);

                        }
                        const GetUOMText = contextVariable.AllUOMDetails.find(P => P.Value === ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].AvailableStockUOM)
                        setStockUom(GetUOMText.Description);
                    }
                    else {
                        setProductStock(0);
                    }

                } catch (error) {
                    console.error("Fetching Individual product stock", error)
                }
            }
            FetchProductStock();
        }
        else if (!contextVariable.taxDetails.InventoryStatus) {
            setProductStock("NoLimit")
        }
        // eslint-disable-next-line 
    }, [contextVariable.taxDetails.InventoryStatus, contextVariable.AllUOMDetails])

    const handleDisplayProductDetails = () => {
        setData({ ...TrendingProduct, ChangeUOM: TrendingProduct.ProductUOM });
        setVisible(true);
    };
    // const handleUOMDialog = () => {
    //     setData({ ...TrendingProduct, ChangeUOM: TrendingProduct.ProductUOM });
    //     setVisible(true);
    // };
    const handleDefaultUOMChange = () => {
        setData({ ...TrendingProduct, ChangeUOM: TrendingProduct.ProductUOM });
    }

    const priceBold = 500;



    const [UOMDetails, setUOMDetails] = useState([])
    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const ProductUOM = JSON.parse(data.ProductQuantityDetails);
            // console.log("ProductUOm", ProductUOM)
            if (ProductUOM.length > 0) {
                setUOMDetails(ProductUOM)
            }
        }

    }, [data])

    const [PriceUOMText, setPriceUOMText] = useState('');
    useEffect(() => {
        if (TrendingProduct && TrendingProduct.ProductPriceQuantityCode) {
            setPriceUOMText(contextVariable.AllUOMDetails.find(P => P.Value === TrendingProduct.ProductPriceQuantityCode)?.Description || '')
        }
        // eslint-disable-next-line 
    }, [TrendingProduct.ProductPriceQuantityCode])

    return (
        <>
            <div
                onClick={ProductListData.ProductPrice !== null ? handleDisplayProductDetails : null}
                className='fadein animate-duration-1000 cursor-pointer border-round-md mb-2 p-1 h-full flex flex-column justify-content-between  w-100'
            // style={{ background: AllTrendingProducts.Added ? 'whitesmoke' : '' }}
            >
                <div className=''>
                    <div className='flex justify-content-center align-items-center'>
                        <Authorizedimages ProductImage={TrendingProduct.ProductImage} From="TrendingCard" />
                    </div>

                    <div>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'var(--sidebar-color)',
                                        fontSize: '12px'
                                    },
                                },
                            }}
                            title={TrendingProduct.ProductName}
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                        >
                            <span className='slide-title' style={{ color: "rgb(21, 102, 149)" }}>
                                {
                                    AllTrendingProducts.length === 1 ?
                                        TrendingProduct.ProductName
                                        :
                                        <TruncateText text={TrendingProduct.ProductName} maxLength={25} />
                                }
                            </span>
                        </Tooltip>
                    </div>

                    <div className="flex justify-content-center">
                        {/* Contains Product Price and Discount */}

                        {(TrendingProduct.ProductPrice !== null && TrendingProduct.ProductPrice > 0 && TrendingProduct.ProductsDiscounts.items.length !== 0) &&
                            <div className='d-flex justify-content-between align-items-center gap-1'
                                style={{ height: '6vh' }}>
                                <div className='flex justify-content-center align-items-center gap-2'>
                                    <div>
                                        <strike style={{ textDecorationColor: 'gray', fontSize: '14px' }}>
                                            <span style={{ color: 'gray', fontSize: '13px' }} >
                                                {getSymbolFromCurrency(
                                                    TrendingProduct.ProductPriceCurrencyCode
                                                )}{TrendingProduct.ProductPrice}
                                            </span>
                                        </strike>
                                    </div>
                                    <div>
                                        {getSymbolFromCurrency(
                                            TrendingProduct.ProductPriceCurrencyCode
                                        )}<span style={{ fontSize: isTab ? '100%' : "130%", fontWeight: 500 }}>
                                            {(
                                                parseFloat(TrendingProduct.ProductPrice) -
                                                parseFloat((Math.abs(parseFloat(TrendingProduct.ProductsDiscounts.items[0].ProductDiscount)) / 100) * parseFloat(TrendingProduct.ProductPrice))
                                            ).toFixed(2)}
                                        </span>
                                        {(TrendingProduct.ProductPriceQuantity && PriceUOMText) && <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                            {/* ({TrendingProduct.ProductUOM}) */}
                                            {" "}({parseInt(TrendingProduct.ProductPriceQuantity)} {PriceUOMText})
                                        </span>}
                                    </div>
                                    <div>
                                        {!isMobile &&
                                            <span className="text-xs font-semibold text-white bg-green-700 border-round-md px-1">
                                                {Math.abs(parseFloat(TrendingProduct.ProductsDiscounts.items[0].ProductDiscount, 10).toFixed(2))}%off
                                            </span>}
                                    </div>
                                </div>

                            </div>
                        }


                        {/* No discount */}
                        {
                            (TrendingProduct.ProductPrice !== null && TrendingProduct.ProductPrice > 0 && TrendingProduct.ProductsDiscounts.items.length === 0) &&
                            <div className='d-flex justify-content-center align-items-center'
                                style={{ height: '6vh' }}>
                                <div>
                                    {getSymbolFromCurrency(
                                        TrendingProduct.ProductPriceCurrencyCode
                                    )}<span style={{ fontSize: isTab ? '100%' : "130%", fontWeight: 500 }}>
                                        {CurrencyFormat(parseFloat(TrendingProduct.ProductPrice))}
                                    </span>
                                    {(TrendingProduct.ProductPriceQuantity && PriceUOMText) && <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                        {/* ({TrendingProduct.ProductUOM}) */}
                                        {" "}({parseInt(TrendingProduct.ProductPriceQuantity)} {PriceUOMText})
                                    </span>}
                                </div>
                            </div>
                        }



                    </div>
                </div>

                <div className=''>
                    {/* Add to cart button */}
                    {
                        (TrendingProduct.ProductPrice !== null && TrendingProduct.ProductPrice > 0 && (ProductStock === "NoLimit" || ProductStock > 0)) &&
                        <div className='mt-2'>
                            {TrendingProduct.productPrice}
                            <Button
                                icon="pi pi-cart-plus"
                                label={t('Add to cart')}
                                rounded
                                raised
                                // loading={contextVariable.addRemoveCartLoading}
                                // loading={buttonLoading}
                                onClick={() => handleSlideAddToCart()}
                            >
                            </Button>
                        </div>
                    }
                    {/* Product unavailable */}
                    {
                        (TrendingProduct.ProductPrice === null || TrendingProduct.ProductPrice <= 0) &&
                        <div className="flex flex-column justify-content-center align-items-center"
                        >
                            <span className="font-semibold text-xs md:text-sm border-round-lg py-1 px-3 mt-3"
                                style={{ color: "var(--sidebar-color)", border: "1px solid var(--sidebar-color)" }}>
                                {t(`Unavailable`)}
                            </span>
                        </div>
                    }
                    {
                        (TrendingProduct.ProductPrice !== null && TrendingProduct.ProductPrice !== 0 && TrendingProduct.ProductPrice > 0 && ProductStock === 0) &&
                        <div className="text-base font-semibold pl-2 pr-2  border-round-sm text-center fadein animation-duration-1000 ">
                            <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50 border-round-xl " >{t(`Out of Stock`)}</span>
                        </div>
                    }

                </div>
            </div>

            <Dialog visible={visible}
                onHide={() => {
                    setVisible(false);
                    setData({});
                    setCount(1);
                }}
                style={{ width: '50vw' }}
                header={data.ProductCategoryName}
            >

                <div className=" w-100  flex  justify-content-center gap-3">

                    {/* image section  */}
                    <div className="w-50 flex justify-content-center align-items-center">
                        <div className="">
                            <Authorizedimages ProductImage={data.ProductImage} From="CatalogPageDialogView" />
                        </div>
                    </div>


                    <div className=" w-50  flex flex-column">


                        {/* ProductName */}
                        <div className="text-sm md:text-xl font-bold flex justify-content-start gap-2 align-items-end">

                            {data.ProductName}

                        </div>

                        {/* ProductDetailDescription */}
                        <div>
                            <p className="text-sm text-gray-500 md:text-base leading-relaxed whitespace-normal max-w-2/4">
                                {data.ProductDetailDescription || 'Product description is unavailable'}
                            </p>
                        </div>

                        {/* ProductPrice */}
                        <div>
                            {(data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0) &&
                                (<div>
                                    {(data.ProductsDiscounts.items.length) === 0 && (
                                        <div className="w-100">
                                            <span className=''>
                                                {getSymbolFromCurrency(
                                                    data.ProductPriceCurrencyCode
                                                )}
                                            </span>
                                            <span className='text-4xl' style={{ fontWeight: priceBold }}>
                                                {parseFloat(data.ProductPrice).toFixed(2)}
                                            </span>


                                            {(data.ProductPriceQuantity && PriceUOMText) && <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                                {/* ({data.ProductUOMText}) */}
                                                {" "}({parseInt(data.ProductPriceQuantity)} <UOMConversion UOMCode={data.ProductPriceQuantityCode} />)
                                            </span>}
                                        </div>
                                    )}

                                    {data.ProductsDiscounts.items.length !== 0 && (
                                        <div className="flex flex-column w-100">
                                            <div className="">
                                                <strike style={{ textDecorationColor: 'gray', fontSize: '14px' }}>
                                                    <span style={{ color: 'gray', fontSize: '13px' }}>
                                                        {getSymbolFromCurrency(
                                                            data.ProductPriceCurrencyCode
                                                        )}
                                                    </span>
                                                    <span style={{ color: 'gray', fontSize: '13px' }}>
                                                        {data.ProductPrice}

                                                    </span>
                                                </strike>

                                                <span style={contextVariable.isRTL ? { marginRight: '0.2rem', color: '' } : { marginLeft: '0.2rem', color: '' }}>
                                                    {getSymbolFromCurrency(
                                                        data.ProductPriceCurrencyCode
                                                    )}
                                                </span>

                                                <span
                                                    style={{ fontWeight: priceBold }}
                                                    className={data.ProductPrice.toString().length >= 8 ? 'text-lg ' : 'text-4xl'}
                                                >
                                                    {CurrencyFormat(
                                                        parseFloat(data.ProductPrice) -
                                                        parseFloat((Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount)) / 100) *
                                                            parseFloat(data.ProductPrice))
                                                    )}

                                                </span>

                                                {(data.ProductPriceQuantity && PriceUOMText) && <span style={{ color: "var(--sidebar-color)", fontSize: "60%" }}>
                                                    {/* ({data.ProductUOMText}) */}

                                                    {" "}({parseInt(data.ProductPriceQuantity)}
                                                    <UOMConversion UOMCode={data.ProductPriceQuantityCode} />)
                                                </span>}
                                            </div>

                                            <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                                                <div className="saved-amount">
                                                    Save : {getSymbolFromCurrency(
                                                        data.ProductPriceCurrencyCode
                                                    )}
                                                    {CurrencyFormat(
                                                        parseFloat((Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount)) / 100) * parseFloat(data.ProductPrice))
                                                    )}
                                                </div>
                                                <span className="discount-badge"
                                                // style={contextVariable.isRTL ? { marginRight: isTab ? '0rem' : '0.7rem' } : { marginLeft: isTab ? '0rem' : '0.7rem' }}
                                                >
                                                    {Math.abs(parseFloat(data.ProductsDiscounts.items[0].ProductDiscount))}%off
                                                </span>
                                            </div>

                                        </div>
                                    )}
                                </div>)}

                            {/* Does not contains Product Price */}
                            {
                                (data.ProductPrice === null || ProductListData.ProductPrice <= 0) &&
                                <div className="flex flex-column justify-content-center align-items-center"
                                >
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title="This product is currently unavailable"
                                        placement="bottom"
                                        TransitionComponent={Zoom}
                                        enterDelay={500}
                                    >
                                        <span className="font-semibold text-xs md:text-xs border-round-lg py-1 px-3 mt-3"
                                            style={{ color: "var(--sidebar-color)", border: "1px solid var(--sidebar-color)" }}>
                                            {t(`Unavailable`)}
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        </div>

                        {/* Inventory details  */}
                        <div>
                            {
                                (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && ProductStock > 0) &&
                                <div>
                                    <div className="text-xs font-semibold pt-1 border-round-sm ">
                                        <span className="text-red-700  border-round-3xl " >{t(`Avl.Stock`)}: {ProductStock} {StockUom}</span>
                                    </div>

                                </div>
                            }
                            {
                                (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && ProductStock === 0) &&
                                <div className="text-base font-semibold pl-2 pr-2 pt-4 border-round-sm text-center fadein animation-duration-2000 ">
                                    <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl " >{t(`Out of Stock`)}</span>
                                </div>
                            }
                        </div>


                        {(data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && (ProductStock === "NoLimit" || ProductStock > 0)) && <div className="flex flex-column gap-2 w-100 mt-4">

                            <div className="" key={data.id}>
                                <div className="flex gap-2 flex-wrap ">
                                    <div style={data?.ChangeUOM === data.ProductUOM ? { fontSize: "0.7rem" } : { height: "1.8rem", fontSize: "0.7rem" }}
                                        className={`flex border-1 border-round-md justify-content-center  p-1 cursor-pointer ${data?.ChangeUOM === data.ProductUOM ? 'border-blue-500 border-2' : 'hover:border-blue-500 hover:bg-blue-50  border-gray-400 '}`}>
                                        <button onClick={handleDefaultUOMChange}
                                            className={`${data?.ChangeUOM === data.ProductUOM ? "text-blue-500 font-semibold" : "hover:text-blue-700  text-black"}`}>
                                            <span>
                                                <i className={` ${data?.ChangeUOM === data.ProductUOM ? 'pi pi-check pl-1 pr-1' : 'pl-1 '}`}
                                                    style={{ fontSize: '0.7rem' }}></i>
                                                <UOMConversion UOMCode={data.ProductUOM} /></span>
                                        </button>
                                    </div>
                                    {UOMDetails.map((UOMobj, index) => (

                                        <UOMSection UOMobj={UOMobj} index={index}
                                            selectedData={data} setCount={setCount}
                                            setSelectedData={setData} ProductStock={ProductStock} />

                                    ))}


                                </div>
                            </div>

                            <div className="flex">
                                <div className="w-50 pt-1">
                                    <input
                                        type="text"
                                        value={count === "" ? "" : count}
                                        className=" bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 w-50 "
                                        style={{
                                            fontSize: "14px",
                                            padding: "0px",
                                            height: '28px',
                                            textAlign: "center",
                                            outline: "none",
                                            borderRadius: '5px'
                                        }}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                    />
                                    <span
                                        className="mt-2 pr-2 pl-2"
                                        style={{ fontSize: "10px" }}
                                    >
                                        {data.ChangeUOM && <UOMConversion UOMCode={data.ChangeUOM} />}
                                        {!data.ChangeUOM && <UOMConversion UOMCode={data.ProductUOM} />}

                                    </span>
                                </div>

                                <div className="w-50">
                                    <Button rounded raised
                                        className="primary-button font-bold  w-75 text-sm"
                                        onClick={handleAddToCartCount}
                                        loading={data.buttonLoading}
                                    >
                                        <span className='w-100'>
                                            <AddShoppingCart ></AddShoppingCart>
                                        </span>

                                    </Button>
                                </div>
                            </div>
                        </div>}

                    </div>

                </div>
            </Dialog>
        </>
    )
}

export default TrendingProductCard