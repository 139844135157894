import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout'
import { API } from 'aws-amplify';
import { getByCustomerSalesOrderDetail } from '../../../Layout/MutaionsAndQueries';
import getSymbolFromCurrency from 'currency-symbol-map';
import SkeletonLoader from '../../SkeletonLoader';
import { showToast } from '../../ToastUtils';
import CardTopbar from '../CardTopbar';
import DataNotFound from '../dataNotFound/DataNotFound';
import CurrencyFormat from '../../CurrencyFormat';
import { useTranslation } from 'react-i18next';

const OrderDetailsCard = () => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    // console.log("contextVariable", contextVariable.rootObject);
    const [generalDetails, setGeneralDetails] = useState({});
    const [itemArray, setItemArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(null);

    const fetchSalesorderDetails = async () => {
        setLoading(true);
        try {
            const response = await API.graphql({
                query: getByCustomerSalesOrderDetail,
                variables: {
                    OrderID: contextVariable.rootObject.OrderID
                }
            });
            setGeneralDetails(response.data.GetByCustomerSalesOrderDetail.items[0]);
            setItemArray(response.data.GetByCustomerSalesOrderDetail.items[0].SalesOrderItem);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            setIsError(error);
            // showToast(`Cannot get orders list : ${error.errors[0].message}`, "error");
            showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            console.error("fetchSalesorderDetails Error", error);
        }
    }

    useEffect(() => {
        fetchSalesorderDetails();
    // eslint-disable-next-line 
    }, [])

    return (
        <>
            <div className='h-screen' style={{ background: "#ededed", fontFamily: "helvetica" }}>

                {/* Topbar */}
                <CardTopbar
                    header={`Order ID : ${contextVariable.rootObject.OrderID}`}
                    link="order"
                    buttonNeeded={true}
                />

                <div className="m-2 overflow-auto">
                    {
                        (Object.keys(generalDetails).length !== 0) && !loading && isError === null &&
                        <>
                            <div className='card bg-white' style={{ color: "var(--sidebar-color)" }}>
                                {/* Reference and status section */}
                                <div className='p-2 flex justify-content-between align-items-baseline'>
                                    <div>
                                        <div>
                                            <span className='text-base'>#{" "}{generalDetails.ExternalReference || ''}</span>
                                        </div>
                                        <div>
                                            {
                                                (generalDetails.OrderStatusCode === "1") &&
                                                <span className='text-xs' style={{ color: "var(--info-color)" }}>{generalDetails.OrderStatus}</span> //In process
                                            }
                                            {
                                                (generalDetails.OrderStatusCode === "2") &&
                                                <span className='text-xs' style={{ color: "var(--sidebar-color)" }}>{generalDetails.OrderStatus}</span> //In process
                                            }
                                            {
                                                (generalDetails.OrderStatusCode === "3") &&
                                                <span className='text-xs' style={{ color: "#00b321" }}>{generalDetails.OrderStatus}</span> //Completed
                                            }
                                            {
                                                (generalDetails.OrderStatusCode === "4") &&
                                                <span className='text-xs' style={{ color: "var(--primary-color)" }}>{generalDetails.OrderStatus}</span> //In preparation
                                            }

                                        </div>
                                    </div>
                                    {/* Date and amount section */}
                                    <div className='text-right'>
                                        <div>
                                            <span className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.CreatedDate}</span>
                                        </div>
                                        <div>
                                            <span className='text-base'>
                                                {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(generalDetails.GrossAmount)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {/* Details section */}
                                <div className='p-2'>
                                    <div className='flex gap-2 align-items-baseline'>
                                        <div className='text-xs'>Payment Terms :</div>
                                        <div className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.PaymentTermText}</div>
                                    </div>
                                    <div className='flex gap-2 align-items-baseline'>
                                        <div className='text-xs'>Incoterms : </div>
                                        <div className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.IncotermsCode}</div>
                                    </div>
                                    {/* Delivery and invoice status */}
                                    <div className='flex justify-content-between align-items-baseline'>
                                        <div className='flex gap-2 align-items-baseline'>
                                            <div className='text-xs' >Delivery : </div>
                                            <div className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.DeliveryStatus}</div>
                                        </div>
                                        <div className='flex gap-2 align-items-baseline'>
                                            <div className='text-xs' >Invoice : </div>
                                            <div className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.InvoiceStatus}</div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                {/* Notes */}
                                <div className='p-2'>
                                    <div className='flex gap-2 align-items-baseline'>
                                        <div className='text-xs' >Note : </div>
                                        <div className='text-sm' style={{ color: "#8a8a8a" }}>{generalDetails.Note}</div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                    {/* Product details */}
                    {
                        (itemArray.length !== 0 && !loading && isError === null) &&
                        <>
                            <span className='text-base font-semibold'>Items</span>
                            {
                                itemArray
                                    .map((product, index) => (
                                        <div key={index} className='card p-3'>
                                            {/* Product name */}
                                            <div className='flex justify-content-between align-items-center'>
                                                <div>
                                                    <span className='text-sm'>{product.ProductDescription}</span>
                                                </div>
                                                <div>
                                                    <span className='text-sm' style={{ color: "#8a8a8a" }}>{product.Quantity}{" "} {product.QuantityUOMText}</span>
                                                </div>
                                            </div>

                                            {/* Tax and discount */}
                                            <div className='mt-2 flex justify-content-between align-items-baseline'>
                                                <div className='flex gap-2'>
                                                    <div className='flex gap-2 align-items-baseline'>
                                                        <div className='text-xs' >Discount : </div>
                                                        <div className='text-sm' style={{ color: "#8a8a8a" }}>{Math.abs(product.Discount)}</div>
                                                    </div>
                                                    <div className='flex gap-2 align-items-baseline'>
                                                        <div className='text-xs' >Tax : </div>
                                                        <div className='text-sm' style={{ color: "#8a8a8a" }}>{product.TaxRate}</div>
                                                    </div>
                                                </div>
                                                <div className='flex justify-content-end'>
                                                    <span className='text-sm text-right'>
                                                        {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(product.NetValue)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </>
                    }

                    {/* Error */}
                    {
                        isError &&
                        <>
                            <DataNotFound />
                        </>
                    }

                    {/* Loading */}
                    {
                        loading === true &&
                        <>
                            <div className="flex flex-column">
                                {
                                    [...Array(10)].map((_, index) => (
                                        <SkeletonLoader key={index} />
                                    ))
                                }
                            </div>
                        </>
                    }

                </div>

            </div>
        </>
    )
}

export default OrderDetailsCard;