import { Sidebar } from 'primereact/sidebar'
import React, { useContext, useState } from 'react'
import { AppContext } from '../Layout/Layout'
import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next';

export default function InfoBar(props) {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [visibleRight, setVisibleRight] = useState(true)

  const customHeader = (
    <div className="mt-3">
      <span className="font-semibold text-xl">{props.header}</span>
    </div>
  );
  const pTag = `mt-1 text-sm mb-1 text-justify`;
  const navTag = `mt-2 mb-2 font-semibold`;
  const paraHeader = `font-semibold text-base`;
  const DivContent = 'mt-2';
  const spanBold = 'font-medium';

  return (
    <Sidebar
      dir={contextVariable.rtl ? "rtl" : "ltr"}
      visible={visibleRight}
      position={contextVariable.rtl ? "left" : "right"}
      header={customHeader}
      onHide={() => {
        setVisibleRight(false)
        contextVariable.setPageInfo("Hide")
      }}>

      {contextVariable.pageInfo === "creditLimitsCalculations" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <ul style={{ textAlign: 'justify' }} className={pTag}>
              <li>{t(`Each open order has a corresponding total amount.`)}</li>
              <li>{t(`There is a separate credit limit assigned to each user or entity.`)}</li>
            </ul>
          </div>

          <div className={DivContent}>
            <span className={paraHeader}>{t(`Calculation Formula`)}</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              {t(`The available credit limits can be calculated using the following formula`)}:
            </p>

            <pre className={`mt-3 ${pTag}`}>
              {t(`Available Credit Limits = 
 (Total Credit Limits - 
  Total Order Amounts)`)}
            </pre>
          </div>
          <hr />
        </>
      }
      {
        contextVariable.pageInfo === "PlaceOrder" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>{t(`PO Reference`)} </span>
            <p className={pTag}>
              {t(`Provide the customer Po number for internal reference`)}.
            </p>
          </div>



        </>
      }
      {contextVariable.pageInfo === "Order" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>{t(`Status`)}</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              {t(`PDF cannot be viewed when the status is in preparation`)}.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>{t(`Charges`)}</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              {t(`The gross charge includes any additional charges, such as freight, service charges etc, if applicable.
              Please refer to the Order PDF for any price changes`)}.
            </p>
          </div>
        </>
      }
      {contextVariable.pageInfo === "Delivery" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>{t(`Status`)}</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              {t(`PDF cannot be viewed when the status is in`)} <br /> "{t(`In preparation`)}".
            </p>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "Invoice" &&
        <>
          <Divider className='bg-primary'></Divider>

          <div className={DivContent}>
            <span className={paraHeader}>{t(`Payment`)}</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              {t(`Payment can be done for one or more invoice either partially or fully`)}.
            </p>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "CreditNote" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>{t(`Status`)}</span>
            <p className={pTag}>
              {t(`PDF cannot be viewed when the status is in preparation`)}.
            </p>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "PaymentHistory" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div style={{ textAlign: 'center' }} className={DivContent}>
            <span className={paraHeader}>{t(`Status`)}</span></div>
          <div className='flex flex-column align-items-left'>
            <div className='mt-3'>
              <span className='px-2 py-1 mb-3 text-yellow-600 bg-yellow-100 border-round-md font-semibold '>
                {t(`In Process`)}
              </span>
            </div>
            <div>
              <p style={{ textAlign: 'justify' }} className={pTag}>{t(`Your payment transaction was initiated but waiting for payment gateway response`)}.</p>
            </div>
          </div>
          <hr />
          <div className='flex flex-column  align-items-left mt-3'>
            <div>
              <span className='px-2 py-1 text-green-600 bg-green-100 border-round-md font-semibold'>
                {t(`Success`)}
              </span>
            </div>
            <div>
              <p style={{ textAlign: 'justify' }} className={pTag}>{t(`Your payment transaction has been successfully processed, and the corresponding bank payment advice has been generated`)}.</p>
            </div>
          </div>
          <hr />
          <div className='flex flex-column align-items-left mt-3'>
            <div>
              <span className='px-2 py-1 text-red-600 bg-red-100 border-round-md font-semibold'>
                {t(`Failed`)}
              </span>
            </div>
            <div>
              <p style={{ textAlign: 'justify' }} className={pTag}>{t(`Your payment transaction has been failed`)}.</p>
            </div>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "CompanyDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Company Name</span>
            <p className={pTag}>
              Provide the Company's Name.
              <p className={navTag}>
                General ledger ----&gt; companies ----&gt; company Name (Eg.Almika)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Company ID</span>
            <p className={pTag}>
              Provide the Company ID from the organization structure setup in SAP.
              <p className={navTag}>
                general ledger ----&gt; companies ----&gt; company id (Eg.1000)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Commencement Year</span>
            <p className={pTag}>
              Commencement year of SAP application or starting year from which SAP transactions are exposed to Procustomer (E.g., 2024).
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Company Address</span>
            <p className={pTag}>
              Provide a detailed postal address along with other contact details such as phone number, website...etc.
            </p>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "OrganizationDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Language Code</span>
            <p className={pTag}>
              Provide the communication language code. (E.g., 01 EN, 02 FR).
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Currency Code</span>
            <p className={pTag}>
              The Currency Code selected here will be the default currency for the entire Pro Customer portal.
              <p className={navTag}>
                Currency Code - general ledger ----&gt; Definitions ----&gt; Default Currency ----&gt; (Eg.USD - US Dollar)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Sales Organization ID</span>
            <p className={pTag}>
              Provide the Sales Organization ID responsible for the online sales. Please consult your SAP advisor.
              <p className={navTag}>
                Organization Management ----&gt; Org Structure ----&gt; Org Unit ID (Eg.P1110)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Sales Organization Name</span>
            <p className={pTag}>
              Provide the Sales Organization Name.
              <p className={navTag}>
                Organization Management ----&gt; Org Structure ----&gt; Org Unit Name (Eg.P1110)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Distribution Channel </span>
            <p className={pTag}>
              Provide the distribution channel mapped for online sales. Please consult your SAP advisor (E.g., 01 Direct Sales, 02 Indirect Sales, 03 Online Sales).
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Employee Responsible ID</span>
            <p className={pTag}>
              Provide the SAP Employee ID of the sales person responsible for online sales.
              <p className={navTag}>
                Employee responsible Id - Account Management ----&gt; Contacts ----&gt; Responsibilities ----&gt; Employee (Eg.E0103 - Jacob, Kate)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Set of Books</span>
            <p style={{ textAlign: 'justify' }} className={pTag}>
              Provide a SAP Set of Book ID(s) which will be used for retrieving the customer statement of accounts.
              <p className={navTag}>
                general ledger ----&gt; Set of books and assigned Companies ----&gt;set the book ID ----&gt; (Eg.9011)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>System ID</span>
            <p className={pTag}>
              Provide the SAP System ID.
              <p className={navTag}>
                Service Control Center ----&gt; Systems ----&gt; System ID (Ex:740780858)
              </p>
            </p>
          </div>
          <hr />
        </>
      }
      {contextVariable.pageInfo === "InventoryDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Inventory Indicator</span>
            <p className={pTag}>
              If Inventory is enabled, product current stock will be displayed in Product Catalog & Product Cart. Customer can verify the stock and place the order.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Location ID or Site ID</span>
            <p className={navTag}>
              Product data --&gt; Logistics --&gt; Site (Eg.400)
            </p>
          </div>




        </>
      }
      {contextVariable.pageInfo === "PriceDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Price List Name</span>
            <p className={pTag}>
              Provide the SAP base price list name, which should be in released status.
              <p className={navTag}>
                Product and Service Portal ----&gt; pricing ----&gt; Price list ----&gt; Base price list (Eg.NEW BASE PL ALMIKA (1000))
              </p>
            </p>
          </div>
          <hr />

          <div className={DivContent}>
            <span className={paraHeader}>Additional Pricing Options</span>
            <p className={pTag}>
              <p className=' font-semibold'>None</p> Shows the base price from SAP or an uploaded base price.
            </p>
            <p className={pTag}>
              <p className=' font-semibold'>Customer Specific Discount</p> Displays the product discount specific to the customer.
            </p>
            <p className={pTag}>
              <p className=' font-semibold'>Customer Specific Price</p> Shows the customer-specific price for the product.
            </p>
          </div>
          <hr />

          <div className={DivContent}>
            <span className={paraHeader}>Inclusive Tax</span>
            <p className={pTag}>
              Tax inclusive indicates that the price of an item is inclusive of taxes.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Exclusive Tax</span>
            <p className={pTag}>
              Tax exclusive indicates that the price of an item is exclusive of taxes.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Auto Sync</span>
            <p className={pTag}>
              <p className=' font-semibold'>Yes</p> Automatically syncs the base price from SAP, disabling manual upload.
            </p>
            <p className={pTag}>
              <p className=' font-semibold'>No</p> Stops automatic synchronization from SAP and allows you to manually upload base prices in the Products View tab.
            </p>

          </div>
          <hr />

        </>
      }
      {contextVariable.pageInfo === "BankDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Bank ID (SAP BANK ID)</span>
            <p className={pTag}>
              Bank ID can be taken from SAP workcenter - "My Banks". Select and open the bank. Navigate to the bank account tab.
              The bank account ID maintained in this tab should be used.
              <p className={navTag}>
                Liquidity Management ----&gt; My Banks ----&gt; Bank Account ----&gt; Bank Account ID (E.g.BOC-00911)
              </p>
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Bank Name (SAP BANK NAME)</span>
            <p className={pTag}>
              The Bank name can be taken from SAP workcenter - "My Banks". Select and open the bank. Navigate to the general tab.
              My bank name maintained in this tab should be used.
              <p className={navTag}>
                Liquidity Management ----&gt; My Banks ----&gt; General ----&gt; My Bank Name (Ex.BDO - Alabang)
              </p>
            </p>
          </div>
          <hr />


        </>
      }
      {contextVariable.pageInfo === "SapCredentialsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>SAP Tenant URL</span>
            <p className={pTag}>
              Provide the SAP Tenant/System URL link which needs to be connected with the Procustomer.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>SAP Odata username</span>
            <p className={pTag}>
              Provide the Odata username for API access. Please reach Procustomer contact point, info@nipurnait.com.
              {/* <p className={navTag}>
                Please reach support team that who can configured Odata User name.
              </p> */}
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>SAP Odata password</span>
            <p className={pTag}>
              Provide the Odata password for API access. Please reach Procustomer contact point, info@nipurnait.com.
              {/* <p className={navTag}>
                Please reach support team that who can configured Odata Password.
              </p> */}
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Soap username </span>
            <p className={pTag}>
              Provide the Soap username for web service access. Please reach Procustomer contact point, info@nipurnait.com.
              {/* <p className={navTag}>
                Please reach support team that who can configured Soap User Name.
              </p> */}
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Soap password</span>
            <p className={pTag}>
              Provide the Soap Password for web service access. Please reach Procustomer contact point, info@nipurnait.com.
              {/* <p className={navTag}>
                Please reach support team that who can configured Soap Password.
              </p> */}
            </p>
          </div>
          <hr />


        </>
      }
      {
        contextVariable.pageInfo === "StripeDetailsInForm" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>1. Log in to Your Stripe Account</span>
            <p className={pTag}>
              Visit the Stripe website and log in with your credentials
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>2. Go to Developers Section</span>
            <p className={pTag}>
              In the Stripe dashboard, look for the <span className={spanBold}> Developers </span> section (Mostly at right top corner).
              This is where you manage your API keys and other developer-related settings.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>3. Go to API Keys tab</span>
            <p className={pTag}>
              Within the Developers section, you should find a subsection specifically for <span className={spanBold}> API keys </span> or Secret keys.
              Click on it to view your API keys.
            </p>
          </div>
          <hr />
          {/* <div className={DivContent}>
            <span className={paraHeader}>4.Locate Your Secret Key</span>
            <p className={pTag}>
              Your Secret key should be listed there. Stripe usually provides both a publishable key
              (for client-side operations) and a secret key (for server-side operations).
            </p>
          </div>
          <hr /> */}
          <div className={DivContent}>
            <span className={paraHeader}>4. Copy Publishable and Secret key</span>
            <p className={pTag}>
              Click on the key to copy the Publishable key and to copy the secret key you have to click <span className={spanBold}> Reveal Key </span>
              and then copy the secret key.
            </p>
          </div>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>5. Add Webhook URL</span>
            <p className={pTag}>
              In the Webhooks tab, click <span className={spanBold}>Add endpoint</span>,
              then enter the provided URL in the <span className={spanBold}>Endpoint URL</span> field.
              Then click <span className={spanBold}>Select events to listen to</span>,
              choose <span className={spanBold}>Account</span> and check <span className={spanBold}>Select all Account events</span>.
              Finally click Add endpoint.
            </p>
          </div>
          <hr />

        </>
      }
      {contextVariable.pageInfo === "ProductsView" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Synchronize</span>
            <p className={pTag} style={{ textAlign: "justify" }}>
              Click the "Synchronize" button to initiate the synchronization process for products and base prices from SAP to the portal.
              {/* Please be aware that this operation may take some time as it involves comprehensive data retrieval and integration. */}
            </p>
            <span className="font-semibold text-base">During the synchronization process, the following actions occur:</span>
          </div>

          <div className={DivContent}>
            <span className="font-semibold text-base">Products:</span>
            <p className={pTag}>
              New products and changes will be reflected.
            </p>
            <span className="font-semibold text-base">Base Prices:</span>
            <p className={pTag}>
              The latest base pricing information will be updated.
            </p>
            {/* <span className="font-semibold text-base">Discounts:</span>
            <p className={pTag}>
              Updated discount details will be synchronized.
            </p> */}
          </div>
          <p className="text-xs font-italic font-semibold mt-3 text-justify">
            * Kindly note that the duration of the sync process depends on the volume of data and network conditions.
            We appreciate your patience. *
          </p>
          <hr />

          {!contextVariable.OrganizationDetails.PriceAutoSyncIndicator &&
            <>
              <div className={DivContent}>
                <span className={paraHeader}>Price List Upload</span>
                <div className="font-semibold text-sm mt-2">Template:</div>
                <p className={pTag}>
                  Download the template to enter your price details, ensuring not to change the column names.
                  Alternatively, you can also download the existing price, modify and upload the same.
                </p>
                <div className="font-semibold text-sm mt-2"> CSV Upload:</div>
                <p className={pTag}>
                  Upload a CSV file with a maximum size limit of 5 MB, ensuring the 'UOM' column contains Codes, not text.
                  All UOM codes is also available for download and use.
                </p>


              </div>


              <hr /> </>}

          <div className={DivContent}>
            <span className={paraHeader}>Price List Download</span>
            <p className={pTag}> Download the price list in CSV format for your reference.</p>
          </div>
          <hr />

          <div className={DivContent}>
            <span className={paraHeader}>Active Trending</span>
            <p className={pTag}>
              Active to show trending items on the customer dashboard.
            </p>
          </div>

          <div className={DivContent}>
            <span className={paraHeader}> Inactive Trending</span>
            <p className={pTag}>
              Inactive to hide trending items from the customer dashboard.
            </p>
          </div>
          <hr />

        </>
      }
      {
        contextVariable.pageInfo === "contactDetails" &&
        <>
          <Divider className='bg-primary'></Divider>
          <div className={DivContent}>
            <span className={paraHeader}>Synchronize</span>
            <p className={pTag}>
              Click the "Synchronize" button to initiate the synchronization process for contacts from SAP to portal.
              {/* Please be aware that this operation may take some time as it involves comprehensive data retrieval and integration. */}
            </p>
            <span className={paraHeader}>During the synchronization process, the following actions occur:</span>
          </div>

          <div className={DivContent}>
            <span className={paraHeader}>Contacts:</span>
            <p className={pTag}>
              New contacts and changes will be reflected.
            </p>

          </div>
          <p className="text-xs font-italic font-semibold mt-3 text-justify mb-2">
            * Kindly note that the duration of the sync process depends on the volume of data and network conditions.
            We appreciate your patience. *
          </p>
          <hr />
          <div className={DivContent}>
            <span className={paraHeader}>Enable Customer</span>
            <p className={pTag}>
              Enable to activate user login. Additionally, our
              Pro Customer application will send the client an email, informing them that the login has been enabled.
            </p>
          </div>
          <div className={DivContent}>
            <span className={paraHeader}> Disable Customer</span>
            <p className={pTag}>
              Disable to deactivate and delete user login
            </p>
          </div>
          <hr />
          <div className='mt-3'>
            <strong>Note:</strong>
            <p className={pTag}>
              Enable/Disable option is visible only if phone number or email id fields are provided.
            </p>
          </div>
        </>
      }

    </Sidebar>
  )
}
