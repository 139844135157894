import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout';
import { Divider } from '@mui/material';
import { AccountBalanceOutlined, CloseOutlined, Info, InventoryOutlined, LaunchOutlined, LocalShippingOutlined, PriceCheckOutlined, ReceiptLongOutlined, RequestQuoteOutlined, SettingsSuggestOutlined, WarningAmber } from '@mui/icons-material';
import { API } from 'aws-amplify';
import { deleteNotification } from '../../../graphql/mutations';
import NotificationImages from "./NotificationEmptyImage.png";
// import Loader from '../../Loader';
// import { showToast } from '../../ToastUtils';
// import { handleShowNotification } from '../../Notification/NotificationComponent';
import { BatchDeleteNotifications } from '../../../Layout/MutaionsAndQueries';
import { Button } from 'primereact/button';
import CardTopbar from '../CardTopbar';
import { useLocation } from 'react-router-dom';
import { getNotificationDescription } from '../../Notification/NotificationDescription';

export default function NotificationCard() {
    const contextVariable = useContext(AppContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (contextVariable) {
            contextVariable.setShowView((prev) => ({
                showOrderDetails: false,
                showDeliveryDetails: false,
                showPaymentHistoryDetails: false,
                showCreditNoteDetails: false,
                showInvoiceDetails: false,
                showInvoicPayment: false
            }));
        }
        //eslint-disable-next-line
    }, [location.pathname])

    const handleToDeleteReadFile = async (Notify) => {

        try {
            const DeleteData = {
                id: Notify.id,
            }
            await API.graphql({
                query: deleteNotification,
                variables:
                {
                    input: DeleteData
                }
            })
            const updateNotificationdata = contextVariable.NotificationMsg.filter((p) => p.id !== Notify.id);
            contextVariable.setNotificationMsg(updateNotificationdata);
            // showToast("Notification deleted");
        }
        catch (error) {
            console.error("Notification Double Click error", error)
        }
    }

    const calculateTimeDifference = (current, initial) => {
        const timeDifference = (current - initial) / 1000; // Convert to seconds

        if (timeDifference >= 3600) {
            const hours = Math.floor(timeDifference / 3600);
            const remainingSeconds = timeDifference % 3600;
            if (remainingSeconds >= 60) {
                const minutes = Math.floor(remainingSeconds / 60);
                return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
            } else {
                return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
            }
        } else if (timeDifference >= 60) {
            const minutes = Math.floor(timeDifference / 60);
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else {
            const seconds = Math.floor(timeDifference);
            return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
    };

    const handleMarkAllAsRead = async () => {
        setButtonLoading(true);
        const UpdateAllNotifications = contextVariable.NotificationMsg.map((p) => ({
            id: p.id,
        }));

        try {
            contextVariable.setNotificationMsg([]);
            const response = await API.graphql(
                {
                    query: BatchDeleteNotifications,
                    variables:
                    {
                        input: UpdateAllNotifications
                    }
                }
            )
            if (response.data.BatchDeleteNotifications && response) {
                setButtonLoading(false);
            }

        }
        catch (error) {
            contextVariable.setNotificationMsg([]);
            setButtonLoading(false);
            // console.error("Notification Mark All as Read error", error)
        }

    };

    const showNotificationDescription = (notifyData) => {
        let notifyDetails;
        const notifyCode = notifyData.NotificationReasonCode;
        const parsedNotificationData = JSON.parse(notifyData.NotificationData);
    
        // console.log("parsedNotificationData", notifyCode, parsedNotificationData);
    
        switch (notifyCode) {
          // Saleorders Notifications
          case 'SO_C':
            notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, referenceID: parsedNotificationData[0].POReferanceID });
            return notifyDetails.description;
    
          case 'SO_P':
            notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
            return notifyDetails.description;
    
          case 'SO_R':
            notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId });
            return notifyDetails.description;
    
          case 'SO_S':
            notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });
            return notifyDetails.description;
    
          case 'SO_S_I':
            notifyDetails = getNotificationDescription(notifyCode, { orderID: parsedNotificationData[0].SalesOrderId, deliveryID: parsedNotificationData[0].DeliveryID });
            return notifyDetails.description;
    
          // Invoice Notifications
          case 'I_I':
            notifyDetails = getNotificationDescription(notifyCode, { orderIDs: parsedNotificationData[0].OrderID });
            return notifyDetails.description;
    
          case 'I_C':
            notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceId, orderID: parsedNotificationData[0].OrderID });
            return notifyDetails.description;
    
          // Creditmemo Notifications
          case 'C_C':
            notifyDetails = getNotificationDescription(notifyCode, { creditMemoID: parsedNotificationData[0].CreditmemoID });
            return notifyDetails.description;
    
          case 'C_I':
            notifyDetails = getNotificationDescription(notifyCode, { invoiceID: parsedNotificationData[0].InvoiceID });
            return notifyDetails.description;
    
          // Payment Advice Notifications
          case 'PS_S':
            notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });
            return notifyDetails.description;
    
          case 'PS_F':
            notifyDetails = getNotificationDescription(notifyCode, { transactionNO: parsedNotificationData[0].TransactionNo });
            return notifyDetails.description;
    
          case 'BPA_C':
            notifyDetails = getNotificationDescription(notifyCode, { bankPaymentID: parsedNotificationData[0].BankPaymentId });
            return notifyDetails.description;
    
          default:
            notifyDetails = getNotificationDescription(notifyCode);
            return notifyDetails.description;
    
        }
      }

    return (
        <div>

            <div style={{ position: 'sticky', top: 0 }}>
                <div>
                    <CardTopbar
                        header={`Notifications`}
                        link="dashboard"
                        buttonNeeded={true}
                    />
                </div>
            </div>

            {contextVariable.NotificationMsg.length === 0 &&
                <>
                    <div>
                        <div className='flex flex-column justify-content-center' style={{ height: "80vh" }}>
                            <div className="flex justify-content-center">
                                <img style={{ height: "40vh" }} src={NotificationImages} alt="No Products" />

                            </div>
                            <span className="flex justify-content-center font-semibold text-sm"> No Notifications to Display.</span>
                        </div>
                    </div>


                </>}

            <div style={{marginTop:"2.5rem"}}>
                {contextVariable.NotificationMsg.length > 0 && contextVariable.NotificationMsg.map((Notify, index) => {
                    const initialDate = new Date(Notify.updatedAt);
                    const currentDate = new Date();
                    const timeDifference = (currentDate - initialDate) / 1000;
                    const timeDifferenceText =
                        timeDifference < 60
                            ? "Just now"
                            : timeDifference >= 86400
                                ? `${initialDate.toLocaleString()}`
                                : calculateTimeDifference(currentDate, initialDate);
                    let iconComponent;
                    let iconColor;
                    
                    switch (Notify.NotificationReasonCode) {
                case "SO_C":
                  iconComponent = <InventoryOutlined />;
                  break;
                case "SO_S":
                  iconComponent = <LocalShippingOutlined />;
                  break;
                case "SO_P":
                  iconComponent = <SettingsSuggestOutlined />;
                  break;
                case "SO_R":
                  iconComponent = <LaunchOutlined />;
                  break;
                case "PS_S":
                  iconComponent = <PriceCheckOutlined />;
                  break;
                case "BPA_C":
                  iconComponent = <AccountBalanceOutlined />;
                  break;
                case "SO_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                case "I_C":
                  iconComponent = <RequestQuoteOutlined />
                  break;
                case "C_C":
                  iconComponent = <ReceiptLongOutlined />;
                  break;
                case "PS_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                case "BPA_F":
                  iconComponent = <WarningAmber className="text-2xl" />;
                  break;
                default:
                  iconComponent = <Info />;
              }

                    if (Notify.NotificationReasonCode === "SO_F" || 
                        Notify.NotificationReasonCode === "PS_F") {
                        iconColor = "red-600"
                    }
                    else if (Notify.NotificationReasonCode === "PS_S") {
                        iconColor = "primary-500";
                    }
                    else {
                        iconColor = "cyan-500"
                    }
                    return (
                        <div
                            // onDoubleClick={() => handleNavigate(Notify)}
                            style={{
                                backgroundColor: Notify.NotificationStatus === "Unread" ? 'white' : "rgba(229, 229, 229, 0.792)",
                            }}
                            className="mt-1 mb-1"
                            key={index}
                        >
                            <div className="text-left p-2 hover:bg-pink-50 hover:cursor-pointer">
                                <div className="flex justify-content-center align-items-center gap-2">

                                    {iconComponent &&
                                        <>
                                            {/* <div className={`text-${iconColor} border-${iconColor} border-3  p-2 border-circle`}> */}
                                            <div className={`bg-${iconColor} text-white p-2 border-circle`}>
                                                {iconComponent}
                                            </div>
                                        </>
                                    }

                                    <div className="text-xs font-semibold flex-1"> {Notify.NotificationReasonCode && showNotificationDescription(Notify)}</div>
                                </div>
                                <p className="text-xs" style={{ display: "flex", justifyContent: "space-between", color: "gray", alignItems: "center" }}>
                                    {timeDifferenceText}

                                    {/* <button className="border-2 p-1 border-circle border-cyan-500 text-cyan-500" onClick={() => handleToDeleteReadFile(Notify)}> */}
                                    <button className="p-1 text-cyan-500" onClick={() => handleToDeleteReadFile(Notify)}>
                                        <CloseOutlined fontSize="small" />
                                    </button>

                                </p>
                            </div>
                            <Divider />
                        </div>
                    );
                })
                }
            </div>

            {/* Mark all as read */}
            <div className='bg-white'>
                {
                    contextVariable.NotificationMsg.length > 0 &&
                    <div
                        className=''
                        style={{ position: "fixed", top: 63, zIndex: "10", width: "100%" }}
                    >
                        <Button
                            onClick={handleMarkAllAsRead}
                            loading={buttonLoading}
                            label='Mark all as read'
                            className=' w-100 text-base'
                            style={{ borderRadius: 0 }}
                        >
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}
