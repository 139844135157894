import React, { useContext, useEffect, useState } from 'react'
import CardTopbar from '../CardTopbar';
import { AppContext } from '../../../Layout/Layout';
import './InvoiceCard.css';
import StripeCheckout from '../../StripeCheckoutButton';
import CurrencyFormat from '../../CurrencyFormat';
import getSymbolFromCurrency from 'currency-symbol-map';

const InvoicePaymentCard = () => {
    const contextVariable = useContext(AppContext);
    const invoiceDetails = [...contextVariable.selectedInvoices];

    const invoiceDetailsWithEditableOpenAmount = invoiceDetails.map(invoice => ({
        ...invoice,
        editableOpenAmount: invoice.OpenAmount
    }));

    // console.log("invoiceDetails", invoiceDetails);
    // const [amountToPay, setAmountToPay] = useState((parseFloat(data.OpenAmount)).toFixed(2));
    const [totalAmount, setTotalAmount] = useState(0);
    const [checkoutDetails, setCheckoutDetails] = useState([]);

    const [inputFields, setInputFields] = useState(invoiceDetailsWithEditableOpenAmount);

    const generateCheckoutDetailsForStripe = (params) => {
        let data = params.map((el) => ({
            description: `${el.InvoiceID}`,
            price: el.editableOpenAmount || el.OpenAmount,
            referenceId: el.ExternalReference
        }));
        setCheckoutDetails(data);
        // console.log("checkoutDetails", checkoutDetails);
    }

    useEffect(() => {
        if (invoiceDetails.length !== 0) {
            let sumOfOpenAmounts = invoiceDetails.reduce((acc, element) => {
                return acc + parseFloat(element.OpenAmount);
            }, 0);
            setTotalAmount(Number((sumOfOpenAmounts).toFixed(2)))
        }
        generateCheckoutDetailsForStripe(invoiceDetails);
        // eslint-disable-next-line 
    }, [])

    const updateTotal = (fields) => {
        let sum = 0;
        fields.forEach(field => {
            const num = parseFloat(field.editableOpenAmount) || 0;
            sum += num;
        });
        setTotalAmount(Number(sum.toFixed(2)));
    };

    const handleInputChange = (id, value) => {

        if (/^\d*\.?\d{0,2}$/.test(value)) {
            const newInputFields = inputFields.map(field => {
                if (Number(value) > Number(field.OpenAmount)) {
                    return field.InvoiceID === id ? { ...field, editableOpenAmount: field.OpenAmount } : field;
                }
                else {
                    return field.InvoiceID === id ? { ...field, editableOpenAmount: value } : field;
                }
            });
            setInputFields(newInputFields);
            updateTotal(newInputFields);
            generateCheckoutDetailsForStripe(newInputFields);
        }
    };

    const handleInputBlur = (id, value) => {
        const newInputFields = inputFields.map(field => {
            if (value === '' || value === 0) {
                return field.InvoiceID === id ? { ...field, editableOpenAmount: field.OpenAmount } : field;
            }
            else {
                return field.InvoiceID === id ? { ...field, editableOpenAmount: value } : field;
            }
        });

        setInputFields(newInputFields);
        updateTotal(newInputFields);
        generateCheckoutDetailsForStripe(newInputFields);
    };

    return (
        <>
            <div className='h-screen' style={{ background: "#ededed", fontFamily: "helvetica" }}>

                <div style={{ background: "#ededed" }} className='sticky top-0 z-1'>

                    {/* Topbar */}
                    <CardTopbar
                        header={`Payment`}
                        link="invoiceDetails"
                        buttonNeeded={true}
                    />

                    {/* Total pay */}
                    <div className='m-2 card shadow-2 bg-white p-3 border-1' style={{ color: "var(--sidebar-color)" }}>
                        <div className="pt-3 d-flex">
                            <div className='mx-auto'>
                                <h3 className="mt-0 text-lg font-normal uppercase leading-tight text-secondary">
                                    Total Amount to pay
                                </h3>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className='mx-auto'>
                                <h5 className="mb-1 border-b font-bold p-2 text-4xl uppercase leading-tight">
                                    {/* <CountUp end={totalAmount} duration={1} /> */}
                                    {CurrencyFormat(totalAmount)}
                                    &nbsp; {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)}
                                    {/* {CurrencyFormat(totalAmount)} {contextVariable.taxDetails.CurrencyCode} */}
                                </h5>
                            </div>
                        </div>
                    </div>

                    {/* Body */}
                    <div className='pl-3 pr-2 flex justify-content-between align-items-center'>
                        <div>
                            <span className='text-sm font-semibold'>Invoices({inputFields.length || ''})</span>
                        </div>
                        <div className='mb-2'>
                            {
                                (contextVariable.taxDetails.StripeDefaultIndicator === "true") &&
                                <StripeCheckout
                                    product={checkoutDetails}
                                    invoiceList={inputFields}
                                    totalAmountToPay={totalAmount} />
                            }
                        </div>
                    </div>
                </div>

                <div className='overflow-auto'>

                    {/* Invoice details */}
                    {
                        (inputFields.length !== 0) &&
                        <>
                            {
                                inputFields
                                    .map((invoice, index) => (
                                        <>
                                            <div key={index} className='card bg-white p-3 mb-2' style={{ color: "var(--sidebar-color)" }}>
                                                {/* 1st section */}
                                                <div className='flex justify-content-between align-items-center pb-2'>
                                                    <div>
                                                        <span className='text-sm'>{invoice.InvoiceID}</span>
                                                    </div>
                                                    <div className='flex gap-2 text-xs'>
                                                        <span className='value-color'>Total :</span>
                                                        <span className='value-color'>
                                                            {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(invoice.TotalAmount)}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* 2nd section */}
                                                <div className='flex justify-content-between align-items-center pb-2 text-xs'>
                                                    <div className='flex gap-2'>
                                                        <span className='value-color'>Due :</span>
                                                        <span className='value-color'>{invoice.InvoiceDate}</span>
                                                    </div>
                                                    <div className='flex gap-2'>
                                                        <span className='value-color'>Open :</span>
                                                        <span className='value-color'>
                                                            {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(invoice.OpenAmount)}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* 3rd section */}
                                                <div className='flex justify-content-around align-items-center'>
                                                    <div>
                                                        <span className='text-xs value-color'>Amount to Pay :</span>
                                                    </div>
                                                    <div className='w-50'>
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                min={1.00}
                                                                max={invoice.OpenAmount}
                                                                className="form-control form-control-sm mobile-invoice-input"
                                                                style={{ zIndex: 0 }}
                                                                name='amountToPay'
                                                                value={invoice.editableOpenAmount} // Set initial value from OpenAmount
                                                                onChange={(e) => handleInputChange(invoice.InvoiceID, e.target.value)}
                                                                onBlur={(e) => handleInputBlur(invoice.InvoiceID, e.target.value)}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-xs value-color" id="basic-addon2">
                                                                    {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    ))
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default InvoicePaymentCard;