// toastUtils.js

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToast = (message, status, time = 5000) => {
    switch (status) {
        case 'success':
            toast.success(message, {
                position: "top-center",
                autoClose: time,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                // theme: "dark",
            });
            break;

        case 'info':
            toast.info(message, {
                position: "top-center",
                autoClose: time,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: "dark",
            });
            break;

        case 'warning':
            toast.warning(message, {
                position: "top-center",
                autoClose: time,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: "dark",
            });
            break;

        case 'error':
            toast.error(message, {
                position: "top-center",
                autoClose: time,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // transition: Slide
                // theme: "dark",
            });
            break;

        default:
            toast.info(message, {
                position: "top-center",
                autoClose: time,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: "dark",
            });
            break;
    }
};
