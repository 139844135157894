import React, { useContext } from "react";
import { Box, Tooltip, Zoom } from "@mui/material";
// import Header from "../../components/Header";
import { useState, useEffect } from "react";
import "./Order.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import { Button } from "primereact/button";
import { NavigateBeforeOutlined, NavigateNextOutlined } from "@mui/icons-material";
import { AppContext } from "../../Layout/Layout";
import { API } from "aws-amplify";
import Loader from '../../components/Loader';
import GetOrderPDF from "./GetOrderPDF";
import CurrencyFormat from "../../components/CurrencyFormat";
import { useFade } from "../../components/FadeContext/FadeContext";
import TruncateText from "../../components/TruncateText";
import { useTranslation } from "react-i18next";
import { GetByCustomerSalesOrderDetail } from "../../graphql/queries";
import { showToast } from "../../components/ToastUtils";

const OrderView = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [GrossAmountIndicator, setGrossAmountIndicator] = useState("true");
    const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
    const [netAndGrossAmount, setnetAndGrossAmount] = useState({
        netAmount: '',
        grossAmount: '',
        GACurrencyCode: '',
        NACurrencyCode: ''
    });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemArray, setItemArray] = useState([]);
    const [loading, setLoading] = useState(false);

    // Net order type
    const columnDefsNetOrderType = [
        {
            field: "LineItem",
            headerName: `${t("Line Item")}`,
            filter: true,
            flex: 0.1,
            headerClass: 'custom-ag-header'
        },

        { field: "ProductID", headerName: `${t("Product ID")}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
        { field: "ProductDescription", headerName: `${t("Description")}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: "ProductDescription", },
        { field: "Quantity", headerName: `${t("Qty")}`, filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
        { field: "QuantityUOMText", headerName: `${t("Qty UOM")}`, filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
        {
            field: "UnitPrice", headerName: `${t("Unit Price")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue);
                }
                else {
                    return '-';
                }
            }
        },
        {
            field: "PriceQuantity", headerName: `${t("Price Qty / UOM")}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
                }
                else {
                    return '-';
                }
            }
        },
        {
            field: "Discount", headerName: `${t('Discount')} (%)`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value !== '') {
                    return (Math.abs(parseFloat(params.data.Discount).toFixed(2)));
                } else {
                    return '-';
                }
            }
        },
        {
            field: "NetPrice", headerName: `${t("Net price")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue);
                }
                else {
                    return '-';
                }
            }
        },
        { field: "TaxRate", headerName: `${t("Tax Amount")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
        {
            field: "NetValue", headerName: `${t("net Value")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue);
                }
                else {
                    return '-';
                }
            }
        },
    ];

    // Gross order type
    const columnDefsGrossOrderType = [
        {
            field: "LineItem",
            headerName: `${t('Line Item')}`,
            filter: true,
            flex: 0.1,
            headerClass: 'custom-ag-header'
        },

        { field: "ProductID", headerName: `${t('Product ID')}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
        { field: "ProductDescription", headerName: `${t('Description')}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: "ProductDescription", },
        { field: "Quantity", headerName: `${t('Qty')}`, filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
        { field: "QuantityUOMText", headerName: `${t('Qty UOM')}`, filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
        {
            field: "UnitPrice", headerName: `${t("Unit Price")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.1,
            headerClass: 'custom-ag-header',
            cellStyle: { textAlign: 'right' },
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue);
                }
                else {
                    return '-';
                }
            }
        },
        {
            field: "PriceQuantity", headerName: `${t("Price Qty / UOM")}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
                }
                else {
                    return '-';
                }
            }
        },
        {
            field: "Discount", headerName: `${t('Discount')} (%)`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellStyle: { textAlign: 'right' },
            cellRenderer: (params) => {
                if (params.value !== '') {
                    return (Math.abs(parseFloat(params.data.Discount).toFixed(2)));
                } else {
                    return '-';
                }
            }
        },
        {
            field: "NetPrice", headerName: `${t("Gross Price")} (${contextVariable.taxDetails.CurrencyCode})`,
            filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
            cellRenderer: (params) => {
                return CurrencyFormat(params.value);
                // if (params.value) {
                //     const formattedValue = (parseFloat(params.value)).toFixed(2);
                //     return CurrencyFormat(formattedValue);
                // }
                // else {
                //     return '-';
                // }
            }
        },
        {
            field: "TaxRate", headerName: `${t("Tax Amount")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true,
            cellStyle: { textAlign: 'right' },
            flex: 0.3, headerClass: 'custom-ag-header'
        },
        {
            field: "NetValue", headerName: `${t("Gross Value")} (${contextVariable.taxDetails.CurrencyCode})`,
            filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
            cellRenderer: (params) => {
                if (params.value) {
                    const formattedValue = (parseFloat(params.value)).toFixed(2);
                    return CurrencyFormat(formattedValue);
                }
                else {
                    return '-';
                }
            }
        },
    ];


    const fetchSalesorderDetails = async () => {
        setRowData(1);
        setItemArray(1);
        try {
            const response = await API.graphql({
                query: GetByCustomerSalesOrderDetail,
                variables: {
                    OrderID: contextVariable.rootObject.OrderID
                }
            });
            // console.log("response", response);
            // Set rowData
            setRowData(response.data.GetByCustomerSalesOrderDetail.items);
            if (response.data.GetByCustomerSalesOrderDetail.items[0].GrossAmountIndicator === 'false') {
                setGrossAmountIndicator("false");
            }
            else {
                setGrossAmountIndicator("true");
            }
            setItemArray(response.data.GetByCustomerSalesOrderDetail.items[0].SalesOrderItem);
        }
        catch (error) {
            console.error("fetchSalesorderDetails Error", error);

            if (error.message && error.message.includes('No current user')) {
                console.error('Session timeout or unauthenticated state detected:', error);
                contextVariable.setIsSessionExpired(true);
            }
            else {
                console.error("fetchSalesorderDetails Error", error);
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            }
        }
    }

    const fetchSalesorderDetails1 = async (OrderId) => {
        setRowData(1);
        setItemArray(1);
        try {
            const response = await API.graphql({
                query: GetByCustomerSalesOrderDetail,
                variables: {
                    OrderID: OrderId
                }
            });
            // Set rowData
            setRowData(response.data.GetByCustomerSalesOrderDetail.items);
            if (response.data.GetByCustomerSalesOrderDetail.items[0].GrossAmountIndicator === 'false') {
                setGrossAmountIndicator("false");
            }
            else {
                setGrossAmountIndicator("true");
            }
            setItemArray(response.data.GetByCustomerSalesOrderDetail.items[0].SalesOrderItem);

        }
        catch (error) {
            console.error("fetchSalesorderDetails ERROR", error);

            if (error.message && error.message.includes('No current user')) {
                console.error('Session timeout or unauthenticated state detected:', error);
                contextVariable.setIsSessionExpired(true);
            }
            else {
                console.error("fetchSalesorderDetails Error", error);
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            }
        }
    }

    useEffect(() => {
        fetchSalesorderDetails();
        const initialIndex = contextVariable.filteredData.findIndex(item => item.OrderID === contextVariable.rootObject.OrderID);
        if (initialIndex !== -1) {
            setCurrentIndex(initialIndex);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // This effect runs when rowData is updated
        if (contextVariable.rootObject) {
            const newNetAndGrossAmount = {
                netAmount: contextVariable.rootObject.NetAmount, // Use [0] to access the first item in the array
                grossAmount: contextVariable.rootObject.GrossAmount,
                GACurrencyCode: contextVariable.rootObject.GrossAmountCurrencyCode,
                NACurrencyCode: contextVariable.rootObject.NetAmountCurrencyCode
            };
            setnetAndGrossAmount(newNetAndGrossAmount);
        }
        //eslint-disable-next-line
    }, [rowData]);

    const goToOrder = () => {
        props.backToOrder();
        contextVariable.setIsFiltered(true);
    }

    const selectedRowsHandler = (rows) => {
    }

    const showPdf = () => {
        setLoading(true);
        GetOrderPDF("order", contextVariable.rootObject.PDFUUID, contextVariable.rootObject.OrderID).then(() => {
            setLoading(false);
        });
    }


    const BeforeSaleOrder = () => {
        if (currentIndex > 0) {
            contextVariable.setRootObject(contextVariable.filteredData[currentIndex - 1]);
            fetchSalesorderDetails1(contextVariable.filteredData[currentIndex - 1].OrderID);
            setCurrentIndex(currentIndex - 1)
        }
    }
    const NextSaleOrder = () => {
        if (currentIndex < contextVariable.filteredData.length - 1) {
            contextVariable.setRootObject(contextVariable.filteredData[currentIndex + 1]);
            fetchSalesorderDetails1(contextVariable.filteredData[currentIndex + 1].OrderID);
            setCurrentIndex(currentIndex + 1)
        }
    }

    const { fadeIn } = useFade();

    return (
        <>
            <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
                {sessionStorage.setItem("title", `Details of Order ID : ${contextVariable.rootObject.OrderID}`)}
                {/* HEADER */}
                <div className="d-flex justify-content-between">
                    <div>
                        <Button className="secondary-button p-button-sm  mb-2"
                            onClick={goToOrder} label={t('Back to order list')} icon="pi pi-arrow-left">
                        </Button>

                        {
                            (contextVariable.rootObject.PDFUUID).trim() !== '' ?
                                <>
                                    <Button
                                        className="primary-button p-button-sm mb-2 ml-2"
                                        loading={loading}
                                        onClick={showPdf}
                                        icon="pi pi-file-pdf"
                                        label={t('View PDF')}
                                    >
                                    </Button>
                                </> : ''
                        }
                    </div>
                </div>

                {/* General Details */}
                <div className="headerDetails card-shadow bg-white">
                    {
                        rowData.length > 0 ? (
                            <>
                                <div className="containers">
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Created Date')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].CreatedDate}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Requested Date')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].RequestedDate}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Contact Person Name')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].ContactPersonName}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Reference Number')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].ExternalReference}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Payment Terms')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].PaymentTermText}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Inco Terms')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].Incoterms}</span>
                                    </div>
                                    <div>
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Delivery Address')} :</label>
                                        <span className="font-semibold text-left">{rowData[0].CustomerAddress}</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{ height: '5vh' }}
                                className="d-flex justify-content-center align-items-center">
                                <Loader />
                            </div>
                        )}


                    <div className="flex justify-content-between align-items-center flex-wrap">
                        {rowData.length > 0 ? (
                            <>
                                <div className="flex flex-column justify-content-left align-items-left">
                                    <div className="text-left">
                                        <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Notes')}:</label>
                                    </div>
                                    <div>
                                        <Tooltip
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        bgcolor: 'var(--sidebar-color)',
                                                        fontSize: '12px'
                                                    },
                                                },
                                            }}
                                            title={rowData[0].Note}
                                            placement="bottom"
                                            TransitionComponent={Zoom}
                                            enterDelay={500}
                                        >
                                            <span className="font-semibold text-left">
                                                {
                                                    (rowData[0].Note) &&
                                                    <TruncateText
                                                        text={rowData[0].Note}
                                                        maxLength={70}
                                                    />
                                                }
                                                {
                                                    !(rowData[0].Note) && '-'
                                                }
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="flex flex-column">
                                    <label className="text-left text-xs md:text-xs font-semibold text-gray-400">{t('Order Status')} :</label>
                                    <span className="font-semibold text-left">{rowData[0].OrderStatus}</span>
                                </div>

                                <div className="flex justify-content-center align-items-center gap-2 mt-2 mb-2 md:mt-0 md:mb-0">
                                    {/* (GrossAmountIndicator === "false" && netAndGrossAmount.netAmount) && */}
                                    {
                                        <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                                            {t('Net Amount')} : {netAndGrossAmount.netAmount ? parseFloat(netAndGrossAmount.netAmount).toFixed(2) : ''} {netAndGrossAmount.netAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                                        </span>
                                    }
                                    {/* (GrossAmountIndicator === "true" && netAndGrossAmount.grossAmount) && */}
                                    {
                                        <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                                            {t('Gross Amount')} : {netAndGrossAmount.grossAmount ? parseFloat(netAndGrossAmount.grossAmount).toFixed(2) : ''} {netAndGrossAmount.grossAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                                        </span>
                                    }
                                </div>
                            </>
                        ) : (
                            <div>
                                {/* <Loader /> */}
                            </div>
                        )
                        }

                    </div>
                </div>


                {/* DataGrid Card  */}
                <div className="mt-5">
                    {/* <div> */}
                    <GridData
                        rowData={itemArray}
                        columnDefs={GrossAmountIndicator === "true" ? columnDefsGrossOrderType : columnDefsNetOrderType}
                        tableHeight={'58vh'}
                        needFilters={false}
                        showPagination={false}
                        selectedRows={selectedRowsHandler}
                    />
                    {/* </div> */}
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <button disabled={currentIndex === 0} className='btn btn-sm' style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={BeforeSaleOrder}><NavigateBeforeOutlined /> {t('Previous')}</button>
                    <div>
                        {/* <span className="font-semibold text-sm">{t('Order')} </span> */}
                        <span className="font-semibold text-sm">{currentIndex + 1} </span> {t('of')}
                        <span className="font-semibold text-sm"> {contextVariable.filteredData.length}</span>
                    </div>
                    <button disabled={(contextVariable.filteredData.length - 1) - currentIndex === 0} className='btn btn-sm' style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={NextSaleOrder}>{t('Next')} <NavigateNextOutlined /></button>
                </div>
            </Box>
        </>
    );
};

export default OrderView;
