// import { Tooltip, Zoom } from '@mui/material'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import React, { useEffect, useRef, useState } from 'react'
import CSVFileUpload from './CSVFileUpload';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next'

const PricingOptions = ({ RowDatalength, contextVariable }) => {
    const { t } = useTranslation();
    const menuRight = useRef(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState({
        label: '',
        value: '',
        folderName: ''
    });
    const [loading, setLoading] = useState(false);

    const handleLoading = () => {
        // alert("handle")
        setLoading(!loading);
    }

    const handleMenuItemClick = (label, value, folderName) => {
        setSelectedOption({
            label: label,
            value: value,
            folderName: folderName
        });
        // console.log(selectedOption);
        setOpenDialog(true);
    }

    const handleClose = () => {
        // console.log("Inside");
        setOpenDialog(false);
    }

    const [menuItems, setMenuItems] = useState([
        {
            idName: 'Base price',
            label: 'Base Price',
            icon: '',
            style: { fontSize: '14px' },
            command: () => handleMenuItemClick(t(`Base Price`), 'BP', 'BasePriceList')
        },
    ]);

    useEffect(() => {
        // console.log("useeffect", contextVariable);
        let updatedMenuItems = [...menuItems];

        if (contextVariable.OrganizationDetails.PriceAutoSyncIndicator === true) {
            // console.log("INSIDE",contextVariable.OrganizationDetails.PriceAutoSyncIndicator)
            // Disable the "Base Price" menu item if PriceAutoSyncIndicator is true
            updatedMenuItems = updatedMenuItems.map(item =>
                item.idName === 'Base price' ? { ...item, disabled: true } : item
            );
        } else {
            // console.log("ELSE",contextVariable.OrganizationDetails.PriceAutoSyncIndicator)
            // Enable the "Base Price" menu item if PriceAutoSyncIndicator is false
            updatedMenuItems = updatedMenuItems.map(item =>
                item.idName === 'Base price' ? { ...item, disabled: false } : item
            );
        }

        // Add additional menu items based on AdditionalPricingOptions
        if (contextVariable.OrganizationDetails.AdditionalPricingOptions === 'CSD') {
            updatedMenuItems.push({
                idName: 'CSD',
                label: 'Customer Specific Discount',
                icon: '',
                style: { fontSize: '14px', whiteSpace: "nowrap" },
                disabled: loading,
                command: () => handleMenuItemClick('Customer Specific Discount', 'CSD', 'CustomerSpecificDiscountList')
            });
        } else if (contextVariable.OrganizationDetails.AdditionalPricingOptions === 'CSP') {
            updatedMenuItems.push({
                idName: 'CSP',
                label: 'Customer Specific Price',
                icon: '',
                style: { fontSize: '14px', whiteSpace: "nowrap" },
                command: () => handleMenuItemClick('Customer Specific Price', 'CSP', 'CustomerSpecificPriceList')
            });
        }

        setMenuItems(updatedMenuItems);
        //eslint-disable-next-line 
    }, [contextVariable.OrganizationDetails.PriceAutoSyncIndicator, contextVariable.OrganizationDetails.AdditionalPricingOptions]);

    useEffect(() => {
        // console.log("Inside ---", contextVariable.loadingPriceList);
        if (contextVariable.loadingPriceList === false) {
            setLoading(false);
        }
    }, [contextVariable])
    // console.log("contextvariable", contextVariable)
    return (
        <>
            {
                loading ?
                    <Button
                        disabled
                        className='primary-button p-2 pr-4 pl-4 flex gap-2'
                        onClick={(event) => menuRight.current.toggle(event)}
                    >
                        <i className="pi pi-spin pi-spinner"
                            style={{ fontSize: '1rem' }}></i>
                        Price List
                    </Button>
                    :
                    <>
                        <Menu
                            model={menuItems}
                            popup ref={menuRight}
                            id="popup_menu_right"
                            popupAlignment="right"
                        />
                        {!(contextVariable.OrganizationDetails.PriceAutoSyncIndicator === true &&
                            contextVariable.OrganizationDetails.AdditionalPricingOptions === "None") &&
                            <Button
                                disabled={RowDatalength > 0 ? false : true}
                                className='primary-button p-2 pr-4 pl-4'
                                icon='pi pi-upload pl-1'
                                label='Price List'
                                onClick={(event) => menuRight.current.toggle(event)}
                            >
                            </Button>}
                    </>
            }
            {
                openDialog &&
                <>
                    <Dialog
                        // dismissableMask={true}
                        header={selectedOption.label}
                        visible={openDialog}
                        style={{ width: '60vw' }}
                        onHide={() => { if (!openDialog) return; setOpenDialog(false); }}
                        draggable={false}
                    >
                        <CSVFileUpload
                            selectedOption={selectedOption}
                            handleClose={handleClose}
                            handleLoading={handleLoading}
                        />
                    </Dialog>
                </>
            }
        </>
    )
}

export default PricingOptions