import React, { useContext, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
// import required modules
import { Autoplay, Navigation } from 'swiper/modules';
import { ArrowForwardOutlined, TrendingUpOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { AppContext } from '../../Layout/Layout';
import TrendingProductCard from './TrendingProductCard';
import { useTranslation } from 'react-i18next';

const TrendingSwiper = ({ TrendingProducts }) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const isLaptop = useMediaQuery('(min-width: 1024px)');
  // const isTablet = useMediaQuery('(max-width: 1023px) and (min-width: 600px)');
  const isMobile = useMediaQuery('(max-width: 599px)');
  const [AllTrendingProducts, setAllTrendingProducts] = useState([]);
  const [swiperDir, setSwiperDir] = useState(contextVariable.isRTL ? 'rtl' : 'ltr');

  let slidesPerView = 3; // Default value for laptops

  if (isMobile) {
    slidesPerView = 1;
  } else if (isLaptop) {
    slidesPerView = 2;
  }

  useEffect(() => {
    if (TrendingProducts !== "Loading" && TrendingProducts.length > 0) {
      setAllTrendingProducts(TrendingProducts)
    }
    setSwiperDir(contextVariable.isRTL ? 'rtl' : 'ltr');
    //eslint-disable-next-line
  }, [TrendingProducts]);





  const responsiveText = 'lg:text-sm';

  // console.log('AllTrendingProducts',AllTrendingProducts)
  return (
    <div>

      <div className='flex justify-content-between align-items-baseline p-2 '>

        <div>
          <span className='gap-2 text-base font-semibold md:text-xl'>{t("Trending")}</span>
          <TrendingUpOutlined className='glowing text-base mr-2 ml-2' />
        </div>

        <div>
          <Link to={'/productCatalog'}>
            <span className={`flex gap-1 justify-content-between align-items-center font-semibold px-2 py-1 text-white border-round-md ${responsiveText}`}
              style={{ background: "var(--secondary-color)" }}>
              {t("View more")}
              <ArrowForwardOutlined fontSize='small' className='mt-1' />
            </span>
          </Link>
        </div>
      </div>

      {AllTrendingProducts.length === 0 &&
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}
        >
          <Loader />
        </div>
      }

      {/* Swiper */}
      {
        AllTrendingProducts &&
        <Swiper
          dir={swiperDir}
          modules={[Navigation, Autoplay]}
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false, // Allow autoplay to continue even after user interaction
          }}
          slidesPerView={AllTrendingProducts.length === 1 ? 1 : slidesPerView}
          spaceBetween={20}
          className="p-1"
        >
          {AllTrendingProducts.map((TrendingProduct, index) => (
            <SwiperSlide key={TrendingProduct.id}>
              <div className='w-100 h-full'>
                <TrendingProductCard TrendingProduct={TrendingProduct} AllTrendingProducts={AllTrendingProducts}
                  index={index} />
              </div>
            </SwiperSlide>
          ))}

        </Swiper>}

    </div>
  );
};
export default TrendingSwiper;