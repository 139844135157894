import React from 'react';
import CartImage from './Images/EmptyCart.png'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';

const EmptyCart = () => {
    const { t } = useTranslation();
    return (
        <div
            className="d-flex justify-content-center align-items-center text-center">
            <div>
                <img src={CartImage} alt='Your cart is empty' className="img-fluid" style={{ maxWidth: '200px' }} />
                <div className='font-weight-light font-italic'>
                    <span style={{ fontSize: '16px', marginLeft: '1rem' }}>{t('Your cart is empty')}</span>
                </div>
            </div>
        </div>
    );
}

export default EmptyCart;
