import React, { useEffect } from "react";
import Topbar from "../Pages/global/Topbar";
import Sidebar from "../Pages/global/Sidebar";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { createContext } from "react";
import DialogComponent from "../components/DialogComponent.jsx";
import { API, Auth } from "aws-amplify";
import { GetUOMCodeList, notificationsByCustomerIdAndCreatedAt, userCartsByCartId } from "../graphql/queries";
import {
  AdminlistProductsLists, ListByCustomerAddressList, ListByCustomerSalesBlockDetails, SearchByProductList,
  customerSpecificPricesByCustomerUUID, listProductsLists, productDiscountsByCustomerUUID, productPricesByTenant, userFavouriteProductsByOwner
} from "./MutaionsAndQueries";
import AdminSidebar from "../Pages/Admin/global/AdminSidebar.jsx";
import AdminDialogComponent from "../components/Admin/AdminDialogComponent.jsx";
import AdminTopbar from '../Pages/Admin/global/AdminTopbar.jsx';
import { showToast } from "../components/ToastUtils.jsx";
import { OraganizationList } from "../components/Admin/AdminListDetailsFunction.js";
import { FadeProvider } from "../components/FadeContext/FadeContext.jsx";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMediaQuery } from "@mui/material";
import Footer from "../Pages/global/Footer.jsx";
import { BaseAndCSDProducts, BaseAndCSPProducts, BasePriceAndCSDCartDetails, BasePriceAndCSPCartDetails, BasePriceCartDetails, BasePriceProducts } from "./PriceAndDiscountQueries.js";
import SessionExpired from "../components/SessionExpired.jsx";
import { useTranslation } from "react-i18next";

export const AppContext = createContext();

const Layout = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState("hide");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [username, setUsername] = useState("");
  const [ProductListDetails, setProductListDetails] = useState([]);
  const [favouritesTab, setFavouritesTab] = useState(0);
  const [UserCartIdDetails, setUserCartIdDetails] = useState([]);
  // const [hasCheckedUserCart, setHasCheckedUserCart] = useState(false);
  const [CartCount, setCartCount] = useState(0);
  const [favouriteCount, setFavouriteCount] = useState(0);
  const [UserCartItems, setUserCartItems] = useState([]);
  const [date, setDate] = useState(new Date().getFullYear());
  const [rootObject, setRootObject] = useState({});
  const [globalSearch, setGlobalSearch] = useState("");
  const [globalData, setGlobalData] = useState([]);
  const [DialogBox, setDialogBox] = useState(false);
  const [accountDetails, setaccountDetails] = useState({});
  const [addRemoveCartLoading, setAddRemoveCartLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [taxDetails, setTaxDetails] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showProductDetails, setShowProductDetails] = useState({
    show: false,
    productDetails: []
  });
  const [CartStatusView, setCartStatusView] = useState("Loading");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [NotificationMsg, setNotificationMsg] = useState([]);
  const [SalesOrderIdwithPlaced, setSalesorderIdwithPlaced] = useState("");
  const [InstructionsDialog, setInstructionsDialog] = useState("");
  const [TapPage, setTapPage] = useState(0);
  const [pageInfo, setPageInfo] = useState(false);
  const [OrganizationDetails, setOrganizationDetails] = useState("Loading");
  const [callDownloadPDF, setCallDownloadPDF] = useState({
    isDataLoaded: false,
    executeDownloadPDFFunction: false
  });
  const [FetchLimit, setFetchLimit] = useState({ order: 125, delivery: 125, invoice: 125, crditNote: 125 });
  const [pageLoading, setPageLoading] = useState(false);
  const [EditImageData, setEditImageData] = useState('');
  const [visible, setVisible] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // getting checkbox invoices from grid
  const [invoicesToPay, setInvoicesToPay] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useMediaQuery("(max-width:500px)");
  const [showView, setShowView] = useState({
    showOrderDetails: false,
    showDeliveryDetails: false,
    showPaymentHistoryDetails: false,
    showCreditNoteDetails: false,
    showInvoiceDetails: false,
    showInvoicPayment: false
  });

  // getting checkbox invoices from card
  const [selectedInvoices, setSelectedInvoices] = useState(new Set());

  const [rtl, setRtl] = useState(false);
  const [notificationData, setNotificationData] = useState('');
  const [additionalPricingOptions, setAdditionalPricingOptions] = useState('');
  const [loadingPriceList, setLoadingPriceList] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  // For Reload option when tokens disappear
  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     // console.log("INSIDE")
  //     if (localStorage.getItem('CognitoIdentityServiceProvider') === 'true') {
  //       // window.location.reload();
  //     }
  //   };
  //   window.addEventListener('storage', handleStorageChange);
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  //    // eslint-disable-next-line
  // }, [localStorage.getItem('amplify-signin-with-hostedUI')]);


  //getting Login User Data


  async function fetchUserAttributes() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log("user 1",user)
      setUsername(user.signInUserSession.idToken.payload.name);
      setUserDetails(user.signInUserSession.idToken.payload);
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  }

  // Get a  User Cart for user  first time
  const CartCreateFunction = async () => {
    try {
      let nextToken = null;
      let UserCartParticulars = [];
      const userDetails = await Auth.currentAuthenticatedUser();
      do {

        const UserCartResponse = await API.graphql({
          query: userCartsByCartId,
          variables: {
            CartId: userDetails.signInUserSession.idToken.payload.sub,
            filter: {
              or: [
                { CartStatus: { eq: "Open" } },
                { CartStatus: { eq: "InProgress" } }
              ],
            },
            nextToken: nextToken,
          },
        });

        nextToken = UserCartResponse.data.userCartsByCartId.nextToken;
        const UserCartDetail = UserCartResponse.data.userCartsByCartId.items;
        UserCartParticulars = UserCartParticulars.concat(UserCartDetail);

      } while (nextToken);
      if (UserCartParticulars.length > 0) {
        if (UserCartParticulars[0].CartStatus === "Open") {
          setUserCartIdDetails(UserCartParticulars);
          FetchCartProducts(UserCartParticulars);
        }
        else if (UserCartParticulars[0].CartStatus === "InProgress") {
          setUserCartIdDetails(UserCartParticulars);
          FetchCartProducts(UserCartParticulars);
        }

      }
      else if (UserCartParticulars.length === 0) {
        setCartStatusView("EmptyCart")
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  //Fetch Cart Products 
  const [CartItemsIds, setCartItemsIds] = useState([]);
  const FetchCartProducts = async (params) => {
    const userDetails = await Auth.currentAuthenticatedUser();
    let ProductWithPriceTable;
    ProductWithPriceTable = taxDetails.AdditionalPricingOptions === "None" ? BasePriceCartDetails :
      taxDetails.AdditionalPricingOptions === "CSD" ? BasePriceAndCSDCartDetails : BasePriceAndCSPCartDetails;


    if (userDetails !== "" && params && params[0] && (params[0].CartStatus === "Open" || params[0].CartStatus === "InProgress")) {
      const discountFilter = {
        CustomerUUID: taxDetails.AdditionalPricingOptions === "CSD" ? { eq: userDetails.signInUserSession.idToken.payload["custom:customer_UUID"] } : "",
      };
      let nextToken;
      let CartDetailsResponseItems = [];

      do {
        try {
          const CartDetailsResponse = await API.graphql({
            query: ProductWithPriceTable,
            variables: {
              carttableID: params[0].id,
              discountFilter,
              customerUUID: taxDetails.AdditionalPricingOptions === "CSP" ? userDetails.signInUserSession.idToken.payload["custom:customer_UUID"] : "",
            },
          });
          CartDetailsResponseItems = CartDetailsResponse.data.userProductsCartsByCarttableID.items.concat(CartDetailsResponseItems);
          nextToken = CartDetailsResponse.data.userProductsCartsByCarttableID.nextToken;

        } catch (error) {
          setCartStatusView("EmptyCart")
          console.error("Fetching Error", error);
        }
      }
      while (nextToken)
      // console.log("befor change CartDetailsResponseItems", CartDetailsResponseItems)
      CartDetailsResponseItems.map((p) => {
        const newProduct = { ...p };
        if (taxDetails.AdditionalPricingOptions === "None") {
          newProduct.Productdetails.ProductsDiscounts = { items: [] };
        }
        else if (taxDetails.AdditionalPricingOptions === "CSP") {
          if (p.Productdetails && p.Productdetails.CustomerSpecificPrices && p.Productdetails.CustomerSpecificPrices.items && p.Productdetails.CustomerSpecificPrices.items[0] && p.Productdetails.CustomerSpecificPrices.items[0].ProductPrice > 0) {
            newProduct.Productdetails.ProductsPrices = p.Productdetails.CustomerSpecificPrices.items[0];

          }
          newProduct.Productdetails.ProductsDiscounts = { items: [] };
        }
        return newProduct;

      })
      // console.log("CartDetailsResponseItems", CartDetailsResponseItems)
      setCartCount(CartDetailsResponseItems.length);
      setUserCartItems(CartDetailsResponseItems);
      setCartItemsIds(CartDetailsResponseItems.map(p => p.userProductsCartProductdetailsId))
      if (CartDetailsResponseItems.length > 0) {
        if (params[0].CartStatus === "InProgress") {
          setCartStatusView("EmptyCart");
        }
        else {
          setCartStatusView("ProductsInTheCart")
        }

      }
      else if (CartDetailsResponseItems.length === 0) {
        setCartStatusView("EmptyCart")
      }

    }
    // else if (userDetails !== "" && params && params[0] && params[0].CartStatus === "InProgress") {
    //   setCartStatusView("EmptyCart")
    // }
  };

  //Fetching ProductDetails for show in catalog page using use context
  const fetchProductDetails = async () => {

    const userDetails = await Auth.currentAuthenticatedUser();

    if (props.role === 'Admin') {
      setProductListDetails(1)
      let nextToken = null;
      let productList = [];

      try {

        do {
          const result = await API.graphql({
            query: AdminlistProductsLists,
            variables: {
              limit: 3000,
              nextToken: nextToken,
            },
          });

          if (result.data.listProductsLists.items.length === 0 && productList.length === 0 && result.data.listProductsLists.nextToken === null) {
            nextToken = null;
            setProductListDetails(0)
          }
          else {
            nextToken = result.data.listProductsLists.nextToken;
            const productItems = result.data.listProductsLists.items.map((p) => {
              const newProduct = { ...p };
              if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
                newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
                newProduct.ProductPriceCSV = p.ProductsPrices.items[0].ProductPrice;
                newProduct.ProductIdCSV = p.ProductsPrices.items[0].ProductId;
                newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode === null ? "" : p.ProductsPrices.items[0].ProductPriceCurrencyCode;
                newProduct.ProductPriceCurrencyCodeText = p.ProductsPrices.items[0].ProductPricecurrencyCodeText;
                newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity === null ? null : parseFloat(p.ProductsPrices.items[0].ProductPriceQuantity);
                newProduct.ProductPriceUOM = p.ProductsPrices.items[0].ProductPriceQuantityCode === null ? "" : p.ProductsPrices.items[0].ProductPriceQuantityCode;
              }
              else {
                newProduct.ProductPrice = null;
                newProduct.ProductPriceCurrencyCode = "";
                newProduct.ProductPriceQuantity = null;
                newProduct.ProductPriceUOM = ""
              }
              // if (p.ProductsPrices.items[0].ProductPriceQuantity === null) {

              //   console.log("newProduct", newProduct);
              // }
              return newProduct;

            });

            productList = productList.concat(productItems);
            // console.log("productList", productList)
            // if (productList.concat(productItems).length >= 10 || productList.concat(productItems).length <= 0) {
            //   nextToken = null;
            // }

          }

        } while (nextToken);
        if (productList.length === 0) {
          setProductListDetails(0)
        } else {
          setProductListDetails(productList);
        }

      }
      catch (error) {
        setProductListDetails(0)
        // showToast("Oops! Something went wrong while fetching products. ", "error")
        console.error("Fetching error in Admin Products", error)
      }


    }

    else if (props.role === 'Customers') {
      let nextToken = null;
      let productList = [];
      const discountFilter = {
        CustomerUUID: { eq: userDetails.signInUserSession.idToken.payload["custom:customer_UUID"] },
      };

      try {
        do {
          const result = await API.graphql({
            query: listProductsLists,
            variables: {
              discountFilter,
              filter: {
                ProductActiveStatus: {
                  eq: true
                }
              },
              limit: 3000,
              nextToken: nextToken,
            },
          });
          // console.log("resulet", result)
          if (result.data.listProductsLists.items.length === 0 && productList.length === 0 && result.data.listProductsLists.nextToken === null) {
            nextToken = null;
            setProductListDetails("NoProducts")
          }
          else {
            nextToken = result.data.listProductsLists.nextToken;
            // nextToken = 0;

            const productItems = result.data.listProductsLists.items.map((p) => {
              const newProduct = { ...p };
              if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
                newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
                newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
              }
              else {
                newProduct.ProductPrice = null;
                newProduct.ProductPriceCurrencyCode = null;
              }

              return newProduct;

            });

            productList = productList.concat(productItems);
            // console.log("productList", productList)
            // console.log("WithouTrending", productList.concat(productItems))
            if (productList.concat(productItems).length >= 10 || productList.concat(productItems).length <= 0) {
              nextToken = null;
            }
          }
        } while (nextToken);
        // console.log("productList", productList)
        if (productList.length === 0) {
          setProductListDetails("NoProducts")
        }
        else {
          setProductListDetails(productList);
        }

      }
      catch (error) {
        setProductListDetails(0)
        // showToast("Oops! Something went wrong while fetching products. ", "error")
        console.error("Fetching error in Customers Products", error)
      }

    }

  };

  // Load More for products
  const [productListNextToken, setProductListNextToken] = useState(null);
  const [ErrorDetails, setErrorDetails] = useState(
    {
      ProductDetails: false, PriceDetails: false,
      DiscountDetails: false, FavDetails: false, TrendingDetails: false
    });

  const ProductListGettingLimit = isMobile ? 30 : 18;

  const FetchProductDetailsLoadMoreUse = async () => {
    // const userDetails = await Auth.currentAuthenticatedUser();

    if (props.role === 'Admin') {
      let nextToken = null;
      try {

        do {
          const result = await API.graphql({
            query: AdminlistProductsLists,
            variables: {
              limit: 50,
              nextToken: nextToken || productListNextToken,
            },
          });

          nextToken = result.data.listProductsLists.nextToken;
          setProductListNextToken(nextToken);


          if (result.data.listProductsLists.items.length === 0 && ProductListDetails.length === 0 && nextToken === null) {
            setProductListDetails("NoProducts");
          }
          else {
            const productItems = result.data.listProductsLists.items.map((p) => {
              const newProduct = { ...p };
              if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
                newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
                newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
              }
              else {
                newProduct.ProductPrice = null;
                newProduct.ProductPriceCurrencyCode = null;
              }

              return newProduct;

            });
            if (ProductListDetails.length === 0 || ProductListDetails === "NoProducts") {
              setProductListDetails(productItems)
            }
            else if (productItems.length > 0) {
              setProductListDetails(p => [...p, ...productItems]);
            }
            nextToken = null;
          }
        } while (nextToken);


      }
      catch (error) {
        setProductListDetails(0)
        console.error("Fetching error in Admin Products", error)
      }


    }


  };


  // Fetching user's all price
  const [AllPriceDetailswithTenent, setAllPriceDetailswithTenent] = useState("Loading")
  const FetchPriceDetailsUserTenent = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    let nextToken = null;
    let TenentPriceList = [];

    do {
      try {
        const result = await API.graphql({
          query: productPricesByTenant,
          variables: {
            tenant: userDetails.signInUserSession.idToken.payload["custom:tenant"],
            limit: 10000,
            nextToken: nextToken,
            // filter: {
            //   productID: { eq: "919-my343736" }
            // }
          }
        })
        nextToken = result.data.productPricesByTenant.nextToken;
        TenentPriceList = result.data.productPricesByTenant.items.concat(TenentPriceList);
        // console.log("TenentPriceList", TenentPriceList)
        setErrorDetails((p) => ({ ...p, PriceDetails: false }))

      }
      catch (error) {
        setErrorDetails((p => ({ ...p, PriceDetails: true })));
        console.error("Fetching Price details Error", error)
      }
    }
    while (nextToken)

    if (taxDetails.AdditionalPricingOptions === "CSP") {
      let CSPpriceList = [];
      let CSPNextToken = null;
      do {
        try {
          const result = await API.graphql({
            query: customerSpecificPricesByCustomerUUID,
            variables: {
              CustomerUUID: userDetails.signInUserSession.idToken.payload["custom:customer_UUID"],
              limit: 10000,
              nextToken: CSPNextToken,

            }
          })
          CSPNextToken = result.data.customerSpecificPricesByCustomerUUID.nextToken;
          const CSPItems = result.data.customerSpecificPricesByCustomerUUID.items;
          CSPpriceList = CSPItems.concat(CSPpriceList);

          setErrorDetails((p) => ({ ...p, PriceDetails: false }))

        } catch (error) {
          setErrorDetails((p => ({ ...p, PriceDetails: true })))
          console.error("Fetching Price details Error", error)
        }
      } while (CSPNextToken);
      if (CSPpriceList && CSPpriceList.length > 0) {

        let basePriceMap = new Map(TenentPriceList.map(item => [item.productID, item]));

        CSPpriceList.forEach(item => {

          if (item.ProductPrice > 0) {
            basePriceMap.set(item.productID, item);
          }

        });

        TenentPriceList = Array.from(basePriceMap.values());

      }
    }
    setAllPriceDetailswithTenent(TenentPriceList)
  }

  // Fetching user's all products
  const [AllDiscountDetailsWithTenent, setAllDiscountDetailsWithTenent] = useState("Loading");
  const FetchDiscountDetailsUserTenent = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    if (taxDetails.AdditionalPricingOptions === "CSD") {
      let nextToken = null;
      let TenentDiscountList = [];
      do {
        try {
          const result = await API.graphql({
            query: productDiscountsByCustomerUUID,
            variables: {
              // tenant: userDetails.attributes["custom:tenant"],
              limit: 10000,
              nextToken: nextToken,
              CustomerUUID: userDetails.signInUserSession.idToken.payload["custom:customer_UUID"]
            }
          })
          nextToken = result.data.productDiscountsByCustomerUUID.nextToken;
          TenentDiscountList = result.data.productDiscountsByCustomerUUID.items.concat(TenentDiscountList)
          setErrorDetails((p) => ({ ...p, DiscountDetails: false }));
        }
        catch (error) {
          setErrorDetails((p) => ({ ...p, DiscountDetails: true }))
          console.error("Fetching Discount details Error", error)
        }

      }
      while (nextToken)
      setAllDiscountDetailsWithTenent(TenentDiscountList);
    }
    else {
      setAllDiscountDetailsWithTenent([]);
    }
  }

  // Fetching user's all favourites
  const [AllFavDetailsWithTenent, setAllFavDetailsWithTenent] = useState("Loading");
  const [FavIds, setFavIds] = useState([]);
  const FetchFavDetailsUserTenent = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    let nextToken = null;
    let TenentFavList = [];

    try {
      do {
        const result = await API.graphql({
          query: userFavouriteProductsByOwner,
          variables: {
            owner: userDetails.signInUserSession.accessToken.payload.sub,
            limit: 10000,
            nextToken: nextToken,
          }
        })
        nextToken = result.data.userFavouriteProductsByOwner.nextToken;
        TenentFavList = result.data.userFavouriteProductsByOwner.items.concat(TenentFavList)
      } while (nextToken);
      // console.log("Tenent fav", TenentFavList)
      setAllFavDetailsWithTenent(TenentFavList);
      setFavIds(TenentFavList.map(p => p.userFavouriteProductsProductDetailId));
      setErrorDetails(p => ({ ...p, FavDetails: false }))
    }
    catch (error) {
      console.error("Fetching Fav details Error", error);
      setErrorDetails(p => ({ ...p, FavDetails: true }))
    }
  }

  // Fetching user's Opensearch products
  const [OpenSearchInputText, setOpenSearchInputText] = useState("");

  // Fn for Restructured the productlist with discount,fav,price  for opensearch
  const DataRestructedFunction = (data) => {
    // const restructuredData = data.map(item => item._source);
    const combined = data.map(product => {
      const priceItem = AllPriceDetailswithTenent ? AllPriceDetailswithTenent.find(item => item.productID === product.id) : false;
      const discountItems = AllDiscountDetailsWithTenent ? AllDiscountDetailsWithTenent.find(item => item.productID === product.id) : false;
      const favItems = AllFavDetailsWithTenent ? AllFavDetailsWithTenent.find(item => item.productID === product.id) : false;
      return {
        ...product,
        ProductsPrices: priceItem ? { items: [priceItem] } : { items: [] },
        ProductsDiscounts: discountItems ? { items: [discountItems] } : { items: [] },
        UserFavouriteProducts: favItems ? { items: [favItems] } : { items: [] },
      };
    });

    const productItems = combined.map((p) => {
      const newProduct = { ...p };
      if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
        newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
        newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
        newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
        newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
      }
      else {
        newProduct.ProductPrice = null;
        newProduct.ProductPriceCurrencyCode = null;
        newProduct.ProductPriceQuantity = '';
        newProduct.ProductPriceQuantityCode = "";
      }
      if (p.ProductsDiscounts && p.ProductsDiscounts.items[0] && p.ProductsDiscounts.items[0].ProductDiscount) {
        newProduct.ProductDiscount = Math.abs(p.ProductsDiscounts.items[0].ProductDiscount)
      }
      else {
        newProduct.ProductDiscount = "";
      }
      return newProduct;
    })
    return productItems;
  }

  const OpenSearchProductsDetails = async (EnteredValue, FromCount) => {

    const userDetails = await Auth.currentAuthenticatedUser();
    try {
      if (ErrorDetails.PriceDetails || ErrorDetails.DiscountDetails) {
        showToast(t(`Price or Discount details are unavailable for searching products. Please contact admin for assistance.`), "error", 6000);
        setProductListDetails("NoProducts");
        setErrorDetails(p => ({ ...p, ProductDetails: true }))
      }
      else {
        const ProductDetailsResponse = await API.graphql({
          query: SearchByProductList,
          variables:
          {
            q: EnteredValue,
            tenant: userDetails.signInUserSession.idToken.payload["custom:tenant"],
            from: FromCount,
            size: ProductListGettingLimit
          }
        })
        // console.log("ProductDetailsResponse", ProductDetailsResponse)
        if (ProductDetailsResponse && ProductDetailsResponse.data.SearchByProductList.SearchResult && ProductDetailsResponse.data.SearchByProductList.SearchResult.length > 0) {
          const FullProductDetails = DataRestructedFunction(ProductDetailsResponse.data.SearchByProductList.SearchResult);
          if (FromCount === 0) {
            setProductListDetails(FullProductDetails);
            if (ProductDetailsResponse.data.SearchByProductList.TotalValue === FullProductDetails.length) {
              setProductListNextToken(null);
            }
            else {
              setProductListNextToken(true);
            }
          }
          else if (FromCount !== 0) {
            setProductListDetails(p => [...p, ...FullProductDetails]);
            const productlistCount = ProductListDetails.length;
            const loadmoreProductListCount = FullProductDetails.length;
            // console.log("productlistCount", productlistCount + loadmoreProductListCount)
            if (ProductDetailsResponse.data.SearchByProductList.TotalValue === (productlistCount + loadmoreProductListCount)) {
              setProductListNextToken(null);
            }

          }

        }
        else {
          setProductListDetails("NoProducts");
        }
        setErrorDetails(p => ({ ...p, ProductDetails: false }))
      }

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setProductListDetails("NoProducts");
      setErrorDetails(p => ({ ...p, ProductDetails: true }))
    }




  }


  const [FavouriteProductDetails, setFavouriteProductDetails] = useState([]);
  // useEffect for Fav tab data restructure and get 
  useEffect(() => {
    // Fn for Restructured the productlist with discount,fav,price 
    const DataRestructedFunction = () => {
      const combined = AllFavDetailsWithTenent.map(product => {
        const priceItem = AllPriceDetailswithTenent ? AllPriceDetailswithTenent.find(item => item.productID === product.productID) : false;
        // console.log("priceItem", priceItem)
        const discountItems = AllDiscountDetailsWithTenent ? AllDiscountDetailsWithTenent.find(item => item.productID === product.productID) : false;
        if (product && product.productDetail) {
          return {
            ProductQuantityDetails: product.productDetail.ProductQuantityDetails,
            ProductCategoryId: product.productDetail.ProductCategoryId,
            ProductCategoryName: product.productDetail.ProductCategoryName,
            ProductDetailDescription: product.productDetail.ProductDetailDescription,
            ProductId: product.productDetail.ProductId,
            ProductImage: product.productDetail.ProductImage,
            ProductName: product.productDetail.ProductName,
            ProductStatus: product.productDetail.ProductStatus,
            ProductUOM: product.productDetail.ProductUOM,
            ProductUOMText: product.productDetail.ProductUOMText,
            id: product.productDetail.id,
            tenant: product.productDetail.tenant,
            ProductsPrices: priceItem ? { items: [priceItem] } : { items: [] },
            ProductsDiscounts: discountItems ? { items: [discountItems] } : { items: [] },
            UserFavouriteProducts: { items: [product] },
          };
        }
        else {
          return {
            ProductQuantityDetails: [],
            ProductCategoryId: null,
            ProductCategoryName: null,
            ProductDetailDescription: null,
            ProductId: null,
            ProductImage: null,
            ProductName: null,
            ProductStatus: null,
            ProductUOM: null,
            ProductUOMText: null,
            id: null,
            tenant: null,
            ProductsPrices: priceItem ? { items: [priceItem] } : { items: [] },
            ProductsDiscounts: discountItems ? { items: [discountItems] } : { items: [] },
            UserFavouriteProducts: { items: [product] },
          };
        }

      });
      const productItems = combined.map((p) => {
        const newProduct = { ...p };
        if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
          newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
          newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
          newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
          newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
        }
        else {
          newProduct.ProductPrice = null;
          newProduct.ProductPriceCurrencyCode = null;
          newProduct.ProductPriceQuantity = "";
          newProduct.ProductPriceQuantityCode = "";
        }
        if (p.ProductsDiscounts && p.ProductsDiscounts.items[0] && p.ProductsDiscounts.items[0].ProductDiscount) {
          newProduct.ProductDiscount = Math.abs(p.ProductsDiscounts.items[0].ProductDiscount)
        }
        else {
          newProduct.ProductDiscount = "";
        }
        return newProduct;
      });
      setFavouriteProductDetails(productItems);
      // return productItems;

    }
    if (AllPriceDetailswithTenent !== "Loading" && AllDiscountDetailsWithTenent !== "Loading" && AllFavDetailsWithTenent !== "Loading") {
      DataRestructedFunction();
    }

  }, [AllFavDetailsWithTenent, AllDiscountDetailsWithTenent, AllPriceDetailswithTenent, FavIds])

  const [TrendingProductsList, setTrendingProductsList] = useState([]);
  const TrendingProductDetails = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    try {
      let nextToken = null;
      let productList = [];
      const filter = {
        ProductStatus: {
          eq: "Trending"
        },
        ProductActiveStatus: { eq: true }
      };
      let ProductWithPriceTable;
      ProductWithPriceTable = taxDetails.AdditionalPricingOptions === "None" ? BasePriceProducts : taxDetails.AdditionalPricingOptions === "CSD" ? BaseAndCSDProducts : BaseAndCSPProducts;
      do {
        const result = await API.graphql({
          query: ProductWithPriceTable,
          variables: {
            limit: 5000,
            tenant: userDetails.signInUserSession.idToken.payload["custom:tenant"],
            nextToken: nextToken,
            filter,
            customerUUID: userDetails.signInUserSession.idToken.payload["custom:customer_UUID"]
          },
        });
        // console.log("result", result)
        nextToken = result.data.ProductsListsByTenant.nextToken;
        const productItems = result.data.ProductsListsByTenant.items;
        productList = productList.concat(productItems);
      } while (nextToken);

      // Fetch authorized URLs with a delay

      const productItems = productList.map((p) => {
        const newProduct = { ...p };
        if (taxDetails.AdditionalPricingOptions === "None") {
          if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
            newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
            newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
            newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
          }
          else {
            newProduct.ProductPrice = null;
            newProduct.ProductPriceCurrencyCode = null;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = '';
            newProduct.ProductPriceQuantityCode = "";
          }
        }
        else if (taxDetails.AdditionalPricingOptions === "CSD") {
          if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
            newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
            newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
            newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
          }
          else {
            newProduct.ProductPrice = null;
            newProduct.ProductPriceCurrencyCode = null;
            newProduct.ProductPriceQuantity = '';
            newProduct.ProductPriceQuantityCode = "";
          }
          if (p.ProductsDiscounts && p.ProductsDiscounts.items[0] && p.ProductsDiscounts.items[0].ProductDiscount) {
            newProduct.ProductDiscount = Math.abs(p.ProductsDiscounts.items[0].ProductDiscount)
          }
          else {
            newProduct.ProductDiscount = "";
          }

        }
        else if (taxDetails.AdditionalPricingOptions === "CSP") {
          if (p.CustomerSpecificPrices && p.CustomerSpecificPrices.items && p.CustomerSpecificPrices.items.length > 0) {
            newProduct.ProductPrice = p.CustomerSpecificPrices.items[0].ProductPrice;
            newProduct.ProductPriceCurrencyCode = p.CustomerSpecificPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = p.CustomerSpecificPrices.items[0].ProductPriceQuantity;
            newProduct.ProductPriceQuantityCode = p.CustomerSpecificPrices.items[0].ProductPriceQuantityCode ? p.CustomerSpecificPrices.items[0].ProductPriceQuantityCode : "";
          }
          else
            if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
              newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
              newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
              newProduct.ProductDiscount = "";
              newProduct.ProductsDiscounts = { items: [] };
              newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
              newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
            }
            else {
              newProduct.ProductPrice = null;
              newProduct.ProductPriceCurrencyCode = null;
              newProduct.ProductDiscount = "";
              newProduct.ProductsDiscounts = { items: [] };
              newProduct.ProductPriceQuantity = '';
              newProduct.ProductPriceQuantityCode = "";
            }
        }
        return newProduct;

      });
      if (productItems.length > 0) {
        setTrendingProductsList(productItems);
      }
      else {
        setTrendingProductsList([]);
      }
      setErrorDetails(p => ({ ...p, TrendingDetails: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setTrendingProductsList([]);
      setErrorDetails(p => ({ ...p, TrendingDetails: true }));
    }
  };

  const fetchCustomerAddress = async () => {
    try {
      const response = await API.graphql({
        query: ListByCustomerAddressList
      })

      // setuserAddress(response.data.ListByCustomerAddressList.items[0].CustomerAddressInformation);
      setaccountDetails(response.data.ListByCustomerAddressList.items[0]);
    }
    catch (error) {
      // showToast(` ${error.errors[0].message}`, "error");
      showToast(t(`Address details unavailable. Please contact admin for assistance.`), 'error')
      console.error("listByCustomerAddressList ERROR", error);
      setaccountDetails("NoAddress")

    }
  }

  const fetchNotificationDetails = async () => {
    const userDetails = await Auth.currentAuthenticatedUser();
    // console.log("userDetails", userDetails)
    const FilterId = props.role === 'Customers' ? userDetails.signInUserSession.idToken.payload["custom:contactperson_UUID"] : userDetails.signInUserSession.idToken.payload.sub + "-" + userDetails.signInUserSession.idToken.payload["custom:tenant"];
    // console.log("FilterId", FilterId)
    try {
      const notificationResponse = await API.graphql({
        query: notificationsByCustomerIdAndCreatedAt,
        variables: {
          CustomerId: FilterId,
          sortDirection: 'DESC',
          filter: {
            NotificationStatus: { eq: "Unread" },
          },
        },
      })
      // console.log("notificationResponse", notificationResponse)  
      setNotificationMsg(notificationResponse.data.notificationsByCustomerIdAndCreatedAt.items)
    }
    catch (error) {
      console.error("Notification Catch  Error", error);
      showToast(t(`Something went wrong. Please contact the admin.`), 'error', 4000);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        setIsSessionExpired(true);
      }
    }
  }

  useEffect(() => {
    if (props.role) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
      setIsSessionExpired(true);
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    let intervalId;

    if (Object.keys(taxDetails).length > 0) {
      props.getOrganizationDetails(taxDetails);
      if (props.role === "Customers") {
        FetchCustomerBlockDetails();
        FetchPriceDetailsUserTenent();
        FetchDiscountDetailsUserTenent();
        CartCreateFunction();
        FetchFavDetailsUserTenent();
        FetchUOMDetails();

        intervalId = setInterval(() => {
          FetchPriceDetailsUserTenent();
          FetchDiscountDetailsUserTenent();
          FetchFavDetailsUserTenent();
        }, 3600000);
      }
    }
    // eslint-disable-next-line 
  }, [taxDetails]);

  useEffect(() => {
    if (props.role === 'Customers') {
      if (AllPriceDetailswithTenent !== "Loading" && AllDiscountDetailsWithTenent !== "Loading" && AllFavDetailsWithTenent !== "Loading") {
        OpenSearchProductsDetails("ListByProduct", 0);
      }
    }

  },
    // eslint-disable-next-line
    [props.role, AllFavDetailsWithTenent, AllDiscountDetailsWithTenent, AllPriceDetailswithTenent,])

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (rootElement) {
      let direction = rtl ? "rtl" : "ltr";
      rootElement.setAttribute("dir", direction);
    }
  }, [rtl])

  useEffect(() => {
    // user based function called 
    if (props.role === 'Customers') {
      fetchCustomerAddress();
      fetchUserAttributes();
      fetchNotificationDetails();
      OraganizationList(setTaxDetails, setShow);
    }

    // Admin based panel setup 
    if (props.role === 'Admin') {
      fetchProductDetails();
      fetchUserAttributes();
      fetchNotificationDetails();
      OraganizationList(setOrganizationDetails, setShow);
    }
    // eslint-disable-next-line 
  }, [rtl]);

  useEffect(() => {
    if (parseInt(sessionStorage.getItem("TapPagNumber")) > 0) {
      setTapPage(parseInt(sessionStorage.getItem("TapPagNumber")));
    }
    else {
      setTapPage(0);
    }
  }, [TapPage])




  const [AllUOMDetails, setAllUOMDetails] = useState([]);

  const FetchUOMDetails = async () => {
    try {
      const result = await API.graphql({
        query: GetUOMCodeList
      })
      setAllUOMDetails(result.data.GetUOMCodeList.items)
    } catch (error) {
      console.error("Fetching All UOM Details:", error)

    }
  }

  const [SalesBlockList, setSalesBlockList] = useState(false);
  const FetchCustomerBlockDetails = async () => {
    let BlockList;
    try {
      const response = await API.graphql({
        query: ListByCustomerSalesBlockDetails
      })
      // console.log("response", response)
      if (response && response.data && response.data.ListByCustomerSalesBlockDetails && response.data.ListByCustomerSalesBlockDetails.items[0]) {
        setSalesBlockList(response.data.ListByCustomerSalesBlockDetails.items[0].SaleOrderBlockedIndicator);
        BlockList = response.data.ListByCustomerSalesBlockDetails.items[0];
        // BlockList = {
        //   DeliveryBlockedIndicator: true,
        //   InvoiceBlockedIndicator: true,
        //   SaleOrderBlockedIndicator: false
        // }
      }
      else {
        setSalesBlockList(true)
        BlockList = true;
      }



      // if ((BlockList.DeliveryBlockedIndicator && BlockList.InvoiceBlockedIndicator) || BlockList === true)
      if (BlockList.SaleOrderBlockedIndicator && BlockList.DeliveryBlockedIndicator && BlockList.InvoiceBlockedIndicator) {
        showToast(t(`Order, delivery and invoice has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
      }
      else
        if (BlockList.SaleOrderBlockedIndicator && BlockList.DeliveryBlockedIndicator) {
          showToast(t(`Order and delivery has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
        }
        else
          if (BlockList.SaleOrderBlockedIndicator && BlockList.InvoiceBlockedIndicator) {
            showToast(t(`Order and invoice has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
          }
          else
            if (BlockList.DeliveryBlockedIndicator && BlockList.InvoiceBlockedIndicator) {
              showToast(t(`Delivery and invoice has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
            }
            else
              if (BlockList.DeliveryBlockedIndicator) {
                showToast(t(`Delivery has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
              }
              else
                if (BlockList.InvoiceBlockedIndicator) {
                  showToast(t(`Invoice has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
                }
                else if (BlockList.SaleOrderBlockedIndicator === true) {
                  showToast(t(`Orders has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
                }
                else if (BlockList === true) {
                  showToast(t(`Orders,delivery and invoice has been blocked by ${taxDetails.CompanyName}. Please contact admin.`))
                }
      // console.log("response", response.data.ListByCustomerSalesBlockDetails.items[0])
    } catch (error) {
      setSalesBlockList(true);
      console.error("Customer Block Details", error)
    }
  }

  //Customer context variables
  const AppContextValue = {
    isMobile,
    show,
    setShow,
    rtl,
    setRtl,
    isSidebarOpen,
    setIsSidebarOpen,
    username,
    setUsername,
    userDetails,
    setUserDetails,
    ProductListDetails,
    setProductListDetails,
    favouritesTab,
    setFavouritesTab,
    UserCartIdDetails,
    setUserCartIdDetails,
    CartCount,
    setCartCount,
    UserCartItems,
    setUserCartItems,
    favouriteCount,
    setFavouriteCount,
    date,
    setDate,
    rootObject,
    setRootObject,
    DialogBox,
    setDialogBox,
    globalSearch,
    setGlobalSearch,
    globalData,
    setGlobalData,
    accountDetails,
    addRemoveCartLoading,
    setAddRemoveCartLoading,
    isFiltered,
    setIsFiltered,
    filteredData,
    setFilteredData,
    taxDetails,
    setTaxDetails,
    isCollapsed, setIsCollapsed,
    CartStatusView, setCartStatusView,
    showProductDetails, setShowProductDetails,
    isLoggedIn, setIsLoggedIn,
    NotificationMsg, setNotificationMsg,
    SalesOrderIdwithPlaced, setSalesorderIdwithPlaced,
    callDownloadPDF, setCallDownloadPDF,
    FetchLimit, setFetchLimit,
    pageInfo, setPageInfo,
    TapPage, setTapPage,
    pageLoading, setPageLoading,
    // fetchProductDetails,
    productListNextToken, setProductListNextToken,
    AllPriceDetailswithTenent,
    AllDiscountDetailsWithTenent,
    AllFavDetailsWithTenent, setAllFavDetailsWithTenent,
    FetchProductDetailsLoadMoreUse,
    OpenSearchInputText, setOpenSearchInputText,
    OpenSearchProductsDetails,
    FavouriteProductDetails, setFavouriteProductDetails,
    showView, setShowView,
    TrendingProductsList, setTrendingProductsList,
    TrendingProductDetails,
    selectedInvoices, setSelectedInvoices,
    invoicesToPay, setInvoicesToPay,
    ErrorDetails, setErrorDetails,
    FavIds, setFavIds,
    CartItemsIds, setCartItemsIds,
    scrollPosition, setScrollPosition,
    AllUOMDetails, setAllUOMDetails,
    SalesBlockList,
    isSessionExpired, setIsSessionExpired
  };

  //Admin context variables
  const adminContextValue = {
    isSessionExpired, setIsSessionExpired,
    loadingPriceList, setLoadingPriceList,
    additionalPricingOptions, setAdditionalPricingOptions,
    isMobile,
    show,
    setShow,
    rtl,
    setRtl,
    isSidebarOpen,
    setIsSidebarOpen,
    ProductListDetails,
    setProductListDetails,
    username,
    setUsername,
    userDetails,
    setUserDetails,
    date,
    setDate,
    rootObject,
    setRootObject,
    taxDetails,
    setTaxDetails,
    isCollapsed, setIsCollapsed,
    isLoggedIn, setIsLoggedIn,
    NotificationMsg, setNotificationMsg,
    TapPage, setTapPage,
    InstructionsDialog, setInstructionsDialog,
    OrganizationDetails, setOrganizationDetails,
    pageInfo, setPageInfo,
    EditImageData, setEditImageData,
    fetchProductDetails,
    productListNextToken, setProductListNextToken,
    FetchProductDetailsLoadMoreUse,
    notificationData, setNotificationData
  };

  const handleRefresh = () => {
    window.location.reload();
    setVisible(false);
  }

  const footerContent = (
    <div>
      <Button label="Refresh" icon="pi pi-refresh" onClick={handleRefresh} autoFocus />
    </div>
  );

  return (
    <>
      {/* Checks the session */}
      {
        isSessionExpired &&
        <SessionExpired />
      }
      {/* Checks internet connection */}
      {
        !isOnline && (

          <div className="card flex justify-content-center">
            <Dialog className="text-base md:text-lg" header="You're offline" visible={visible} onHide={() => setVisible(false)} footer={footerContent}
              style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
              <div className="network-error fadein animation-duration-1000 animation-iteration-infinite">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-wifi-off"
                >
                  <line x1="1" y1="1" x2="23" y2="23" />
                  <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55M5 12.55a10.94 10.94 0 0 1 5.17-2.39M10.71 5.05A16 16 0 0 1 22.58 9M1.42 9a15.91 15.91 0 0 1 4.7-2.88M8.53 16.11a6 6 0 0 1 6.95 0M12 20h0" />
                  <circle cx="12" cy="12" r="1" />
                </svg>
              </div>
              <p className="mt-3 font-semibold text-sm md:text-base flex justify-content-center align-items-center">
                Please check your network connection and try refreshing the page.
              </p>
            </Dialog>
          </div>
        )
      }

      {/* Admin Logout */}
      {
        (props.role === 'Admin' && isLoggedIn) &&
        <AppContext.Provider value={adminContextValue}>

          {/* {!rtl && <AdminSidebar />} */}
          <AdminSidebar />

          <main className="content">
            <AdminTopbar cartCount={CartCount} />
            {show === "Logout" && (
              <AdminDialogComponent
                header={t(`Logout`)}
                title={t(`Are you sure you want to logout?`)}
                signout={props.SīgnOut}
              />
            )}
            {show === "ContactSyncFinish" && (
              <AdminDialogComponent
                header={t(`Info`)}
                title={t(`The contact details have been synchronized successfully. Please refresh the table.`)}
              // signout={props.SīgnOut}
              />
            )}
            {show === "ProductSyncFinish" && (
              <AdminDialogComponent
                header={t(`Info`)}
                title={t(`The product details have been synchronized successfully. Please refresh the table.`)}
              // signout={props.SīgnOut}
              />
            )}
            {show === "PriceSyncFinish" && (
              <AdminDialogComponent
                header={t(`Info`)}
                title={t(`The product price details have been synchronized successfully. Please refresh the table.`)}
              // signout={props.SīgnOut}
              />
            )}
            <Outlet />
          </main>

          {/* {rtl && <AdminSidebar />} */}

        </AppContext.Provider>
      }

      {/* User outlet */}
      {
        (props.role === 'Customers' && isLoggedIn) &&
        <AppContext.Provider value={AppContextValue}>
          <FadeProvider>

            {(!isMobile && !rtl) && <Sidebar />}

            <main className="content">
              {/* <Topbar cartCount={CartCount} /> */}
              {!isMobile && <Topbar cartCount={CartCount} />}

              {show === "PaymentSuccess" && (
                <DialogComponent
                  header={t(`Payment Success`)}
                  title={t(`Your Payment recieved successfully 👍`)}

                />
              )}

              {show === "PaymentAdvice Updated" && (
                <DialogComponent
                  header={t(`Payment Advice Updated`)}
                  title={t(`Your recent Bank Payment advice cleared successfully 👍`)}
                />
              )}
              {show === "PaymentAdviceCreationError" && (
                <DialogComponent
                  header={t(`Payment Advice Creation Error`)}
                  title={t(`Your payment is confirmed. However we are unable to clear invoices in the SAP system as of now. Please reach out to your point of contact.`)}
                />
              )}

              <Outlet />
            </main>

            {(!isMobile && rtl) && <Sidebar />}

            {
              (isMobile && !showView.showOrderDetails && !showView.showDeliveryDetails &&
                !showView.showPaymentHistoryDetails && !showView.showCreditNoteDetails &&
                !showView.showInvoiceDetails && !showView.showInvoicPayment) &&
              <Footer />
            }
          </FadeProvider>
        </AppContext.Provider>
      }

    </>
  )
};

export default Layout;
