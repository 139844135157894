import React, { useContext, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext } from '../../Layout/Layout';
import { CreateSAPandStripeDetailsFn, createOrganizationDetailsAPI, updateBankDetailsFn, updateOrganizationsDetailsFn, updateSAPCredentialsFn, updateStripeDetailsFn } from './AdminCUDFunctions';
import Welcome from './Images/Welcome.png'
import { useNavigate } from 'react-router-dom';
import { ArrowForwardOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material';
import Timer from '../Timer';
import { showToast } from '../ToastUtils';
import { Auth, Hub } from 'aws-amplify';
import { LoginContext } from '../../App';
import contactSync from './Images/contactSync.gif';
import ProductSync from './Images/ProductSync.gif';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';

const AdminDialogComponent = (props) => {
    const contextVariable = useContext(AppContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [displayResponsive, setDisplayResponsive] = useState(contextVariable.show);
    const [disabledUse, setDisableUse] = useState(false)
    const [ButtonLoader, setButtonLoader] = useState(false)
    const [SAPCredentials, setSAPCredentials] = useState({
        SAPODataUserName: "", SAPODataPassword: "", SOAPUserName: "", SOAPPassword: ""
    });
    const [priceDetails, setPriceDetails] = useState({
        PriceListName: contextVariable.OrganizationDetails.PriceListName,
        Tax: contextVariable.OrganizationDetails.Tax, AdditionalPricingOptions: contextVariable.OrganizationDetails.AdditionalPricingOptions
    });
    const [BankDetails, setBankDetails] = useState({ BankID: contextVariable.OrganizationDetails.BankID, BankName: contextVariable.OrganizationDetails.BankName })
    const [StripeDetails, setStripeDetails] = useState({ PublishableKey: "", SecretKey: "" })
    const [error, setError] = useState('');
    const [OtpDialog, setOtpDialog] = useState(false)
    const [header, setHeader] = useState(props.header);
    const [ShowResendOTP, setShowResendOTP] = useState(false)
    const [showPassword, setShowPassword] = useState({ ODataPassword: false, SOAPPassword: false });
    const [GetOtp, setGetOtp] = useState("");
    const [session, setSession] = useState(null);
    const [OrganizationDetails, setOrganizationDetails] = useState({ SiteID: contextVariable.OrganizationDetails.SiteID, InventoryStatus: contextVariable.OrganizationDetails.InventoryStatus })

    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setShow(false);
    }

    const [user, setUser] = useState(null);
    // const [customState, setCustomState] = useState(null);
    const contextVariableLogin = useContext(LoginContext);

    const signOut = () => {
        if (user) {
            Auth.signOut();
            contextVariableLogin.setUserGroups("");
            localStorage.clear();
            sessionStorage.clear()
            // localStorage.setItem("isLoggedIn", "false");
            setUser(null);
            navigate('/');
        }
    };

    const verifyUserSignout = () => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signOut":
                    setUser(null);
                    break;
                case "customOAuthState":
                    // setCustomState(data);
                    break;
                default:
                    // Handle unexpected events (optional)
                    break;
            }
        });
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                setUser(currentUser);

                signOut();
            }
            )
            .catch((err) => console.error("Not signed in", err));

        return unsubscribe;
    }

    useEffect(() => {
        verifyUserSignout();
        //eslint-disable-next-line
    }, []);

    const options = ['Yes', 'No'];
    const [PriceAutoSyncIndicator, setPriceAutoSyncIndicator] = useState(contextVariable.OrganizationDetails !== "Empty" ?
        contextVariable.OrganizationDetails.PriceAutoSyncIndicator ? options[0] : options[1] : options[0]);

    useEffect(() => {
        if (["StripeDetailsEdit", "SAPCredentialsEdit"].includes(contextVariable.show)) {
            setDisableUse(true);
        }
    }, [contextVariable.show])


    const handleYesButton = async (name) => {
        setDisableUse(true)
        if (contextVariable.show === "Confirm") {
            const response = await createOrganizationDetailsAPI(props.ConfirmValues, contextVariable, setDisableUse);
            if (response === true) {
                // Navigating to SAP Credentials tab
                contextVariable.setTapPage(1);
                sessionStorage.setItem("TapPagNumber", 1);
                navigate("/customerportalsetup/SAPandStripeDetails");
            }
            else {
                return null;
            }
        }
        else if (contextVariable.show === "SAPAndStripeConfirm") {
            CreateSAPandStripeDetailsFn(props.ConfirmValues, contextVariable, setDisableUse);
        }
        else if (contextVariable.show === "Info") {
            if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.PriceDefaultIndicator === "true") {
                contextVariable.setTapPage(1);
                sessionStorage.setItem("TapPagNumber", 1)
                navigate("/customerportalsetup/SAPandStripeDetails");
                contextVariable.setShow(false);
            }
            else {
                contextVariable.setTapPage(0);
                sessionStorage.setItem("TapPagNumber", 0)
                navigate("/customerportalsetup");
                contextVariable.setShow(false);
            }

        }
        else if ((["PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish"].includes(contextVariable.show))) {
            contextVariable.setShow(false);
        }
        else if (contextVariable.show === "Logout") {

            dialogFuncMap[`${name}`](false);
            contextVariable.setShow(false);
            // localStorage.clear();
            verifyUserSignout();
        }
        else if (contextVariable.show === "ContactFirstSync") {
            contextVariable.setShow(false);
        }
        else if (contextVariable.show === "ProductFirstSync") {
            contextVariable.setShow(false);
        }
    }

    const handleUpdateFunction = () => {

        if (contextVariable.show === "SAPCredentialsEdit") {
            updateSAPCredentialsFn(SAPCredentials, contextVariable, setButtonLoader)
        }
        else if (contextVariable.show === "PriceDetailsEdit") {
            if (priceDetails.PriceListName === "") {
                const fieldName = "PriceListName"
                setError({ ...error, [fieldName]: "Field is Required" });

            }
            else {
                updateOrganizationsDetailsFn({ ...priceDetails, id: contextVariable.OrganizationDetails.id, PriceAutoSyncIndicator: PriceAutoSyncIndicator === 'Yes' ? true : false, PriceDefaultIndicator: "true" }, contextVariable, setButtonLoader)
            }
        }
        else if (contextVariable.show === "OrganizationDetailsEdit") {
            updateOrganizationsDetailsFn({ ...OrganizationDetails, id: contextVariable.OrganizationDetails.id }, contextVariable, setButtonLoader)
        }
        else if (["BankDetailsEdit", "StripeDetailsEdit"].includes(contextVariable.show)) {
            setButtonLoader(true);
            VerifyUser();
        }
    }
    const VerifyUser = async () => {
        setShowResendOTP(false)
        const user = await Auth.currentAuthenticatedUser();
        Auth.signIn({
            username: user.username,
        })
            .then((result) => {
                setSession(result);
                setOtpDialog(true);
                setHeader("Verify OTP");
                setButtonLoader(false);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const handleVerifyOTP = () => {
        setButtonLoader(true);
        const TenantDetail = {
            tenantId: contextVariable.userDetails["custom:tenant"],
            companyId: contextVariable.userDetails["custom:company_id"],
            name: contextVariable.userDetails.name
        }
        Auth.sendCustomChallengeAnswer(session, GetOtp, TenantDetail)
            .then((user) => {
                if (user.challengeName === 'CUSTOM_CHALLENGE' && !user.challengeParam.success) {
                    setGetOtp('');
                    showToast(`${t('Incorrect OTP. Please try again.')}`, "error");
                    setButtonLoader(false);
                }
                else {
                    if (contextVariable.show === "BankDetailsEdit") {
                        updateBankDetailsFn({
                            ...BankDetails, id: contextVariable.OrganizationDetails.id, BankDefaultIndicator: "true"
                        }, contextVariable, setButtonLoader)
                    }
                    else
                        if (contextVariable.show === "StripeDetailsEdit") {
                            updateStripeDetailsFn({ ...StripeDetails, id: contextVariable.OrganizationDetails.id, StripeDefaultIndicator: "true" }, contextVariable, setButtonLoader)
                        }
                }

            })
            .catch((err) => {
                showToast(`${t('Too many incorrect attempts. Please try again later.')}`, "error");
                setGetOtp('');
                contextVariable.setShow('');
                console.error(err);
                setButtonLoader(false);
            });
          


    }

    const SAPCredentialsEdit = (fieldName, value) => {
        if (fieldName === "SAPODataUserName") {
            if (!value.trim()) {
                setError({ ...error, [fieldName]: "Field is Required" });
            }
            else {
                setError({ ...error, [fieldName]: "" });
            }
            setSAPCredentials({ ...SAPCredentials, [fieldName]: value })
        }

        else if (fieldName === "SAPODataPassword") {
            if (!value.trim()) {
                setError({ ...error, [fieldName]: "Field is Required" });
            }
            else {
                setError({ ...error, [fieldName]: "" });
            }
            setSAPCredentials({ ...SAPCredentials, [fieldName]: value.trim() })
        }
        else if (fieldName === "SOAPUserName") {
            if (!value.trim()) {
                setError({ ...error, [fieldName]: "Field is Required" });
            }
            else {
                setError({ ...error, [fieldName]: "" });
            }
            setSAPCredentials({ ...SAPCredentials, [fieldName]: value })
        }
        else if (fieldName === "SOAPPassword") {
            if (!value.trim()) {
                setError({ ...error, [fieldName]: "Field is Required" });
            }
            else {
                setError({ ...error, [fieldName]: "" });
            }
            setSAPCredentials({ ...SAPCredentials, [fieldName]: value.trim() })
        }

        const DetailsOfSAPCredentialObj = { ...SAPCredentials, [fieldName]: value }
        if (DetailsOfSAPCredentialObj.SAPODataUserName !== "" &&
            DetailsOfSAPCredentialObj.SAPODataPassword !== "" &&
            DetailsOfSAPCredentialObj.SOAPUserName !== "" &&
            DetailsOfSAPCredentialObj.SOAPPassword !== "") {
            setDisableUse(false);
        }
        else {
            setDisableUse(true)
        }

    };

    const handlePriceDetailsChange = (fieldName, value) => {
        setDisableUse(false);

        if (!value.trim()) {
            setError({ ...error, [fieldName]: "Field is Required" });

            if (value.length === 0 || fieldName === "PriceListName") {
                setPriceDetails({ ...priceDetails, [fieldName]: "" });
                setDisableUse(true);
            }
        }
        if (/[^a-zA-Z0-9_-\s]/.test(value)) {
            setDisableUse(true);
            setPriceDetails({ ...priceDetails, [fieldName]: value });
            setError({ ...error, [fieldName]: "Special characters are not allowed, only letters, numbers, underscore (_) and hyphen (-) are allowed." });

        }
        else {
            setError({ ...error, [fieldName]: "" });
            setPriceDetails({ ...priceDetails, [fieldName]: value });
        }
    }
    const handleOrganizationDetailsEdit = (fieldName, value) => {

        if (fieldName === "InventoryStatus") {
            if (value) {
                setOrganizationDetails({ ...OrganizationDetails, [fieldName]: value })
                setDisableUse(true);
            }
            else {
                setOrganizationDetails({ [fieldName]: value, SiteID: "" })
                setDisableUse(false);
            }

        }
        else if (fieldName === "SiteID") {
            if (value.trim().length !== 0) {
                setDisableUse(false);
                setOrganizationDetails({ ...OrganizationDetails, [fieldName]: value })
            }
            else {
                setOrganizationDetails({ ...OrganizationDetails, [fieldName]: value })
                setDisableUse(true);
            }

        }

    }
    const handleAdditionPriceOptionsChange = (value) => {
        setPriceDetails({ ...priceDetails, AdditionalPricingOptions: value });
    }

    const handleBankDetailsChange = (fieldName, value) => {
        if (!value.trim()) {
            setError({ ...error, [fieldName]: "Field is Required" });

            if (value.length === 0) {
                setBankDetails({ ...BankDetails, [fieldName]: "" });
                setDisableUse(true);
            }

        }
        else {
            setError({ ...error, [fieldName]: "" });
            const DetailsOfBankObj = { ...BankDetails, [fieldName]: value }
            setBankDetails(DetailsOfBankObj);
            if (DetailsOfBankObj.BankID !== "" && DetailsOfBankObj.BankName !== "") {
                setDisableUse(false);
            }
        }
    }

    const handleStripeDetailsChange = (fieldName, value) => {
        if (!value.trim()) {
            setError({ ...error, [fieldName]: "Field is Required" });

            if (value.length === 0) {
                setStripeDetails({ ...StripeDetails, [fieldName]: "" });
                setDisableUse(true);
            }

        }
        else {
            setError({ ...error, [fieldName]: "" });
            const DetailsOfStripeObj = { ...StripeDetails, [fieldName]: value }
            setStripeDetails(DetailsOfStripeObj);
            if (DetailsOfStripeObj.PublishableKey !== "" && DetailsOfStripeObj.SecretKey !== ""
                // && DetailsOfStripeObj.EndpointSecret !== ""
            ) {
                setDisableUse(false);
            }
        }
    }

    const onTimerComplete = () => {
        setShowResendOTP(true)
    }
    const callBackOtpResendFun = (e) => {
        VerifyUser();
    }


    // footer 
    const renderFooter = (name) => {
        return (
            <div>
                {["Confirm", "Logout", "SAPAndStripeConfirm"].includes(contextVariable.show) && (
                    <>
                        <Button label={t(`No`)} icon="pi pi-times" onClick={() => onHide(name)} className="p-button-outlined" />
                        <Button label={t(`Yes`)} icon="pi pi-check" disabled={disabledUse} loading={disabledUse} onClick={() => handleYesButton(name)} autoFocus />
                    </>
                )}
                {contextVariable.show === "Info" && <>
                    <Button autoFocus label={t(`Go To Form`)} onClick={() => handleYesButton(name)}>
                        <ArrowForwardOutlined />
                    </Button>
                </>
                }

                {["SAPCredentialsEdit", "BankDetailsEdit", "PriceDetailsEdit", "StripeDetailsEdit", "OrganizationDetailsEdit"].includes(contextVariable.show) &&
                    !OtpDialog &&
                    <div className={`flex justify-content-end align-items-center ${disabledUse ? 'not-allowed' : 'pointer'}`}>
                        <Button
                            className='font-semibold gap-1 p-button-sm'
                            label={t(`Update`)}
                            // outlined={disabledUse}
                            loading={ButtonLoader}
                            disabled={disabledUse}
                            onClick={handleUpdateFunction}
                        >
                            {/* {!ButtonLoader && <UpdateOutlined></UpdateOutlined>} */}
                        </Button>
                    </div>
                }
                {["ContactFirstSync", "ProductFirstSync", "PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish"].includes(contextVariable.show) && <>
                    <Button label={t(`Got it`)} className='pl-2 pr-2' icon="pi pi-check" onClick={() => handleYesButton(name)} ></Button>
                </>}
            </div>
        );
    }


    return (
        <>
            <Dialog
                header={["Confirm", "PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish", "SAPAndStripeConfirm", "Logout", "SAPCredentialsEdit", "BankDetailsEdit", "PriceDetailsEdit", "StripeDetailsEdit", "ContactFirstSync", "ProductFirstSync", "OrganizationDetailsEdit"].includes(contextVariable.show) ? header : ""}
                visible={displayResponsive}
                dismissableMask={["Confirm", "SAPAndStripeConfirm", "PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish", "Logout", "SAPCredentialsEdit", "BankDetailsEdit", "PriceDetailsEdit", "StripeDetailsEdit", "ContactFirstSync", "ProductFirstSync", "OrganizationDetailsEdit"].includes(contextVariable.show) && !OtpDialog ? true : false}
                onHide={() => onHide('displayResponsive')}
                breakpoints={{ '960px': '75vw' }}
                style={{ width: '50vw' }}
                closable={["Confirm", "PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish", "SAPAndStripeConfirm", "Logout", "SAPCredentialsEdit", "BankDetailsEdit", "PriceDetailsEdit", "StripeDetailsEdit", "ContactFirstSync", "ProductFirstSync", "OrganizationDetailsEdit"].includes(contextVariable.show) ? true : false}
                footer={renderFooter('displayResponsive')}
                draggable={false}
            >
                {["Confirm", "Logout", "SAPAndStripeConfirm", "PriceSyncFinish", "ProductSyncFinish", "ContactSyncFinish"].includes(contextVariable.show) && <p className='mb-2 text-lg'>{props.title}</p>}
                {["Confirm", "SAPAndStripeConfirm"].includes(contextVariable.show) && <p className="text-sm">{props.Note}</p>}

                {["Info"].includes(contextVariable.show) && <div>
                    <div className='flex justify-content-center align-items-center'>
                        <img
                            className='w-50'
                            src={Welcome}
                            alt='Welcome Img'
                        >
                        </img>
                    </div>
                    <p className='text-base font-semibold line-height-4'>
                        {t(`Please complete your company details to unlock additional features.
                        You'll gain access to other main sections and features only after completing the form.
                        Please feel free to reach out to our support team at`)}
                        <span className='text-blue-600'> {" "}support@nipurnait.com</span> {t(`for assistance whenever needed.`)}
                    </p>
                </div>}

                {["SAPCredentialsEdit"].includes(contextVariable.show) &&
                    <div className='mt-2 flex flex-column gap-3'>


                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`SAP OData User Name*`)}
                                    variant="outlined"
                                    value={SAPCredentials.SAPODataUserName}
                                    size="small"
                                    onChange={(e) => SAPCredentialsEdit("SAPODataUserName", e.target.value)}
                                    error={Boolean(error.SAPODataUserName)}
                                    helperText={error.SAPODataUserName}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth size='small' variant="outlined">
                                <InputLabel htmlFor="SAP-OData-Password">{t(`SAP OData Password*`)}</InputLabel>
                                <OutlinedInput
                                    id="SAP-OData-Password"
                                    type={showPassword.ODataPassword ? 'text' : 'password'}
                                    value={SAPCredentials.SAPODataPassword}
                                    onChange={(e) => SAPCredentialsEdit("SAPODataPassword", e.target.value)}
                                    error={Boolean(error.SAPODataPassword)}
                                    helperText={error.SAPODataPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword((prevState) => ({ ...prevState, ODataPassword: !prevState.ODataPassword }))}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword.ODataPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="SAP OData Password"
                                />
                            </FormControl>
                        </div>


                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`SOAP User Name*`)}
                                    variant="outlined"
                                    value={SAPCredentials.SOAPUserName}
                                    size="small"
                                    onChange={(e) => SAPCredentialsEdit("SOAPUserName", e.target.value)}
                                    error={Boolean(error.SOAPUserName)}
                                    helperText={error.SOAPUserName}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth size='small' variant="outlined">
                                <InputLabel htmlFor="SOAP-Password">{t(`SOAP Password*`)}</InputLabel>
                                <OutlinedInput
                                    id="SOAP-Password"
                                    type={showPassword.SOAPPassword ? 'text' : 'password'}
                                    value={SAPCredentials.SOAPPassword}
                                    onChange={(e) => SAPCredentialsEdit("SOAPPassword", e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword((prevState) => ({ ...prevState, SOAPPassword: !prevState.SOAPPassword }))}
                                                onMouseDown={(event) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword.SOAPPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="SOAP Password"
                                />
                            </FormControl>
                        </div>
                    </div>
                }

                {["PriceDetailsEdit"].includes(contextVariable.show) &&
                    <div className='mt-2 flex flex-column w-full gap-3'>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Base Price Name*`)}
                                    variant="outlined"
                                    value={priceDetails.PriceListName}
                                    size="small"
                                    onChange={(e) => handlePriceDetailsChange("PriceListName", e.target.value)}
                                    error={Boolean(error.PriceListName)}
                                    helperText={error.PriceListName ? error.PriceListName : "For  E.g., SRP_PRICE_LIST"}
                                />
                            </FormControl>
                        </div>

                        {/* Additional pricing options */}
                        <div className=''>
                            {/* <Dropdown
                                value={priceDetails.AdditionalPricingOptions}
                                onChange={(e) => handleAdditionPriceOptionsChange(e.target.value)}
                                options={menuItemOptions}
                                optionLabel="label"
                                placeholder="Additional pricing options"
                                className="w-full"
                                checkmark={true}
                                highlightOnSelect={false} /> */}

                            <FormControl fullWidth>
                                <InputLabel id="additional-pricing-options"
                                >
                                    {t(`Additional pricing options*`)}</InputLabel>
                                <Select
                                    size="small"
                                    name="AdditionalPricingOptions"
                                    labelId="additional-pricing-options"
                                    id="additional-pricing-options"
                                    value={priceDetails.AdditionalPricingOptions}
                                    label="Additional pricing options"
                                    onChange={(e) => handleAdditionPriceOptionsChange(e.target.value)}
                                >
                                    <MenuItem value='None'>None</MenuItem>
                                    <MenuItem value='CSD'>Customer specific discount</MenuItem>
                                    <MenuItem value='CSP'>Customer specific price</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className='flex'>
                            <div className='w-50'>
                                <FormControl>
                                    <RadioGroup
                                        defaultValue={priceDetails.Tax}
                                        onChange={(e) => handlePriceDetailsChange("Tax", e.target.value)}
                                    >
                                        <div className='flex'>
                                            <FormControlLabel value="InclusiveTax" control={<Radio />} label={t(`Inclusive Tax`)} />
                                            <FormControlLabel value="ExclusiveTax" control={<Radio />} label={t(`Exclusive Tax`)} />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className='flex  gap-2'>
                                <div className='mt-2'>
                                    <span className=' text-xs'>{t(`Auto Sync`)}</span></div>
                                <div className='mt-1'>
                                    <SelectButton
                                        className='' 
                                        value={PriceAutoSyncIndicator}
                                        onChange={(e) => setPriceAutoSyncIndicator(e.value)} options={options} />
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>

                    </div>
                }
                {["OrganizationDetailsEdit"].includes(contextVariable.show) &&
                    <div className='mt-2 flex flex-column w-full gap-3'>
                        <FormControl className='w-50'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => handleOrganizationDetailsEdit("InventoryStatus", e.target.checked)}
                                        checked={OrganizationDetails.InventoryStatus}
                                        name="InventoryStatus" />
                                }
                                label={t(`Inventory Indicator`)}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <TextField
                                label={t(`Location ID or Site ID`)}
                                disabled={OrganizationDetails.InventoryStatus ? false : true}
                                // helperText={(touched.SystemID && errors.SystemID) ? errors.SystemID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 740607774"}
                                size="small" type="text"
                                onChange={(e) => handleOrganizationDetailsEdit("SiteID", e.target.value)}
                                error={Boolean(error.SiteID)}
                                name="SiteID"
                                value={OrganizationDetails.SiteID}
                            />
                        </FormControl>

                    </div>
                }


                {/* BankDetails  2 types otp and without otp dialog */}
                {["BankDetailsEdit"].includes(contextVariable.show) && !OtpDialog &&
                    <div className='mt-2 flex flex-column w-full gap-3'>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Bank ID (SAP Bank A/C Id)*`)}
                                    variant="outlined"
                                    value={BankDetails.BankID}
                                    size="small"
                                    onChange={(e) => handleBankDetailsChange("BankID", e.target.value)}
                                    error={Boolean(error.BankID)}
                                    helperText={error.BankID}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Bank Name*`)}
                                    variant="outlined"
                                    value={BankDetails.BankName}
                                    size="small"
                                    onChange={(e) => handleBankDetailsChange("BankName", e.target.value)}
                                    error={Boolean(error.BankName)}
                                    helperText={error.BankName}
                                />
                            </FormControl></div>

                    </div>}

                {["BankDetailsEdit", "StripeDetailsEdit"].includes(contextVariable.show) && OtpDialog &&
                    <div className='mt-2 flex flex-column justify-content-center align-items-center gap-3'>
                        <div>
                            <Timer onTimerComplete={onTimerComplete} callBackOtpResendFun={callBackOtpResendFun} ShowResendOTP={ShowResendOTP} />
                        </div>
                        <div className=''>
                            <TextField size='small' placeholder='Enter 4-digit OTP'
                                onChange={(e) => setGetOtp(e.target.value.trim())}
                                value={GetOtp}
                                InputProps={{ style: { borderRadius: '16px' } }}
                            >
                            </TextField>
                        </div>
                        <span className='text-xs text-gray-500 font-semibold -mt-2 -mb-2'>Please check your mobile or email for OTP</span>
                        <div className='flex gap-2'>
                            <Button rounded raised
                                className="primary-button pl-5 pr-5"
                                loading={ButtonLoader}
                                disabled={!GetOtp}
                                onClick={handleVerifyOTP}

                            >
                                <span>Verify</span>
                            </Button>
                            {ShowResendOTP
                                &&
                                <Button rounded outlined raised onClick={VerifyUser}> <span className=' text-xs cursor-pointer ml-2'>Resend OTP</span></Button>}
                        </div>


                    </div>
                }



                {["StripeDetailsEdit"].includes(contextVariable.show) && !OtpDialog &&
                    <div className='mt-2 flex flex-column gap-3'>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Publishable Key*`)}
                                    variant="outlined"
                                    value={StripeDetails.PublishableKey}
                                    size="small"
                                    onChange={(e) => handleStripeDetailsChange("PublishableKey", e.target.value)}
                                    error={Boolean(error.PublishableKey)}
                                    helperText={error.PublishableKey}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Secret Key*`)}
                                    variant="outlined"
                                    value={StripeDetails.SecretKey}
                                    size="small"
                                    onChange={(e) => handleStripeDetailsChange("SecretKey", e.target.value)}
                                    error={Boolean(error.SecretKey)}
                                    helperText={error.SecretKey}
                                />
                            </FormControl></div>
                        {/* <div>
                            <FormControl fullWidth>
                                <TextField
                                    label={t(`Endpoint Secret*`)}
                                    variant="outlined"
                                    value={StripeDetails.EndpointSecret}
                                    size="small"
                                    onChange={(e) => handleStripeDetailsChange("EndpointSecret", e.target.value)}
                                    error={Boolean(error.EndpointSecret)}
                                    helperText={error.EndpointSecret}
                                />
                            </FormControl>
                        </div> */}
                    </div>
                }

                {["ContactFirstSync"].includes(contextVariable.show) &&
                    <>
                        <div className='flex justify-content-left align-items-center gap-3'>
                            {/* Kindly click <Button label='Synchronize' icon="pi pi-sync" className='opacity-90' style={{ cursor: "not-allowed" }}></Button> button to transfer data from SAP into the portal.
                        <div className='mt-3 text-sm'>
                            <strong>Note :</strong> After initiating synchronization, please allow some time for the process to complete. You will receive a notification once synchronization is finished.
                        </div> */}
                            <div>
                                Kindly click
                            </div>
                            <img style={{ width: "15%", height: "80px" }} src={contactSync} alt="Contact sync" />
                            <div>
                                button to transfer contact details from SAP to portal.
                            </div>
                        </div>

                        <div className='mt-3 text-sm'>
                            <strong>Note :</strong> After initiating synchronization, please allow some time for the process to complete. You will receive a notification once synchronization is finished.
                        </div>
                    </>
                }

                {["ProductFirstSync"].includes(contextVariable.show) &&
                    <>
                        <div className='flex justify-content-left align-items-center gap-3'>
                            {/* Kindly click <Button label='Synchronize' icon="pi pi-sync" className='opacity-90' style={{ cursor: "not-allowed" }}></Button> button to transfer data from SAP into the portal.
                        <div className='mt-3 text-sm'>
                            <strong>Note :</strong> After initiating synchronization, please allow some time for the process to complete. You will receive a notification once synchronization is finished.
                        </div> */}
                            <div>
                                Kindly click
                            </div>
                            <img style={{ width: "15%", height: "15%" }} src={ProductSync} alt="Product sync" />
                            <div>
                                button to transfer product, price and discount data from SAP to portal.
                            </div>
                        </div>

                        <div className='mt-3 text-sm'>
                            <strong>Note :</strong> After initiating synchronization, please allow some time for the process to complete. You will receive a notification once synchronization is finished.
                        </div>
                    </>
                }
            </Dialog>
        </>
    )
}

export default AdminDialogComponent;