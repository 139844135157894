import React, { createContext, useContext, useEffect, useState } from 'react';

const FadeContext = createContext();

export const FadeProvider = ({ children }) => {
  const [fadeIn, setFadeIn] = useState(false);

  const triggerFadeIn = () => {
    setFadeIn(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFadeIn(true);
    }, 200); 

    return () => clearTimeout(timeoutId);
  }, [fadeIn]);

  const value = {
    fadeIn,
    triggerFadeIn,
  };

  return <FadeContext.Provider value={value}>{children}</FadeContext.Provider>;
};

export const useFade = () => {
  return useContext(FadeContext);
};
