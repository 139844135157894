import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Tooltip, Typography, Zoom, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import './AdminSidebar.css';
import { AssignmentLateOutlined, CloseOutlined, Logout, SettingsOutlined } from "@mui/icons-material";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import { AppContext } from "../../../Layout/Layout";

const Item = ({ title, to, icon, setSelected, setLogout }) => {
  const location = useLocation();
  const contextVariable = useContext(AppContext);
  const [isSelected, setIsSelected] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTab = useMediaQuery('(max-width: 768px)');
  // const { show, setShow } = useContext(AppContext);

  useEffect(() => {
    if (to === "/") {
      setIsSelected(location.pathname === to);
    } else {
      setIsSelected(location.pathname.startsWith(to));
    }

  }, [location.pathname, to]);

  const handleNavLinkClick = () => {
    contextVariable.setDate(new Date().getFullYear())
    setSelected(title);
    contextVariable.setIsCollapsed(true);
    if (to === '/customerportalsetup') {
      contextVariable.setTapPage(0);
      sessionStorage.setItem("TapPagNumber", 0)
    }
    if (isMobile || isTab) {
      contextVariable.setIsSidebarOpen(!contextVariable.isSidebarOpen);
    }
  };

  return (
    <>
      {
        contextVariable.isCollapsed === true ?
          <>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'var(--sidebar-color)',
                  },
                },
              }}
              title={< span className="tooltip-fontsize"> {contextVariable.isCollapsed ? title : ''}</span >}
              // title={contextVariable.isCollapsed ? title : ''}
              style={{ fontSize: '25px' }}
              placement="right"
              TransitionComponent={Zoom}
              enterDelay={500} >
              <MenuItem
                className="menuIcon"
                active={isSelected}
                style={{
                  color: 'var(--menuItem-color)',
                  // color: 'var(--primary-color)',
                }}
                onClick={handleNavLinkClick}
                icon={icon}
              >
                {
                  contextVariable.isCollapsed ? '' : <Typography>{title}</Typography>
                }
                {
                  !setLogout && <NavLink to={to}></NavLink>
                }
              </MenuItem>
            </Tooltip>
          </>
          :
          <>
            <MenuItem
              className="menuIcon"
              active={isSelected}
              style={{
                color: 'var(--menuItem-color)',
                // color: 'var(--primary-color)',
              }}
              onClick={handleNavLinkClick}
              icon={icon}
            >
              {
                contextVariable.isCollapsed ? '' : <Typography>{title}</Typography>
              }
              {
                !setLogout && <NavLink to={to}></NavLink>
              }
            </MenuItem>
          </>
      }
    </>
  );
};

const AdminSidebar = (props) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [selected, setSelected] = useState("");
  const isMobile = useMediaQuery('(max-width: 768px)');
// console.log("first")
  const handleLogoutClick = () => {
    contextVariable.setShow("Logout");
  };

  useEffect(() => {
    if (isMobile === false) {
      contextVariable.setIsSidebarOpen(false);
    }
    //eslint-disable-next-line
  }, [isMobile, contextVariable.setIsSidebarOpen, contextVariable.rtl]);


  return (
    <Box className="sidebarContainer"
      sx={{
        "& .pro-sidebar": {
          display: contextVariable.isSidebarOpen ? 'none' : 'block',
        },
        "& .pro-sidebar-inner": {
          background: `var(--sidebar-color)`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          paddingTop: "7px !important",
          paddingBottom: "5px !important",
          paddingLeft: contextVariable.rtl ? '0px !important' : '17px !important',
          paddingRight: contextVariable.rtl ? '17px !important' : '0px !important'
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center"
        },
        "& .pro-menu-item.active": {
          color: `var(--sidebar-color) !important`,
          background: `white`,
        },
        '& [data-testid="MenuOutlinedIcon"]': {
          color: 'var(--menuItem-color)', // Set the color of the element with data-testid="MenuOutlinedIcon"
        },
        "& .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul": {
          width: "100%"
        },
        "& .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout ul div li :hover": {
          color: `var(--sidebar-color)`,
          background: `white`,
        },

        "& .MuiBox-root css-1m3bo6 > button": {
          color: `red !important`,
        }
      }}
    >
      <div>
        <ProSidebar
          rtl={contextVariable.rtl ? true : false}
          collapsed={contextVariable.isCollapsed}
          breakPoint={props.isOpen ? "xxl" : ""}
        >
          <div>
            <Menu iconShape="square">
              <IconButton
                style={{ display: isMobile ? 'block' : 'none', margin: contextVariable.isRTL ? '0 0.7rem 0 0' : '0 0 0 0.7rem' }}
                onClickCapture={() => contextVariable.setIsSidebarOpen(!contextVariable.isSidebarOpen)}>
                <CloseOutlined style={{ color: "#ffffff" }} />
              </IconButton>
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClickCapture={() => {
                  contextVariable.setIsCollapsed(!contextVariable.isCollapsed);
                }
                }
                icon={<MenuOutlinedIcon />}
                style={{
                  margin: "12px 0 12px 0",
                }}
              >

                {!contextVariable.isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    // ml="10px"
                    style={{
                      // borderBottom: "1px solid lightgray",
                      color: "#ffffff",
                    }}
                  >
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {/* {t('adminportal')} */}
                      Pro Customer
                    </Typography>
                  </Box>
                )}
              </MenuItem>

              <Box display="flex" flexDirection="column" height="100%">
                {/* Your other menu items */}
                <Item
                  title={t('Dashboard')}
                  style={{ fontSize: '20px !important' }}
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Customer Portal Setup')}
                  to="/customerportalsetup"
                  icon={<SettingsOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />
                <Item
                  title={t('Error Logs')}
                  to="/errorlogs"
                  icon={<AssignmentLateOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  // setIsCollapsed={setIsCollapsed}
                  setLogout={false}
                />

                {/* Move the Logout item below */}
                <div
                  onClick={handleLogoutClick}
                  style={{ position: 'absolute', top: '92%', width: '100%' }}>

                  <Item
                    title={t('Logout')}
                    selected={selected}
                    setSelected={setSelected}
                    // setIsCollapsed={setIsCollapsed}
                    icon={<Logout />}
                    setLogout={true}
                  />
                </div>
              </Box>


            </Menu>

          </div>


        </ProSidebar>

      </div>
    </Box>
  );
};

export default AdminSidebar;
