import {
  DeleteOutline,
} from "@mui/icons-material";
import { ClickAwayListener, Skeleton, Tooltip, Zoom } from "@mui/material";
import { Button } from "primereact/button";
import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTranslation } from "react-i18next";
import TruncateText from "../../../components/TruncateText";
import { API } from "aws-amplify";
import {
  removeFromCartGlobal,
} from "../../../components/AddAndRemoveFunctions";
import { AppContext } from "../../../Layout/Layout";
import { useFade } from "../../../components/FadeContext/FadeContext";
import CurrencyFormat from "../../../components/CurrencyFormat";
import { updateUserProductsCart } from "../../../Layout/MutaionsAndQueries";
import Authorizedimages from "../../../components/cards/Authorizedimages";
import { showToast } from "../../../components/ToastUtils";
import { ListByProductInventoryDetails } from "../../../graphql/queries";
import UOMConversion from "../../../components/UOMConversion";
import { useTranslation } from "react-i18next";

const PlaceOrderListView = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  // const isMobileS = useMediaQuery("(max-width: 376px)");
  const isTab = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
  const isLaptop = useMediaQuery("(min-width: 1025px)");

  const data = props.data;
  const [ProductPriceAfterDiscConfirmation, setProductPriceAfterDiscConfirmation] = useState("0.00");
  // const { t } = useTranslation();
  const getSymbolFromCurrency = require("currency-symbol-map");
  const contextVariable = useContext(AppContext);
  const [isLoadingEffect, setIsLoadingEffect] = useState(contextVariable.addRemoveCartLoading);
  const priceAmountResponsive = useMediaQuery("(min-width:300px)");
  const ProductNameResponsive = useMediaQuery("(min-width:1148px)");
  const priceAndAmountDivResponsive = useMediaQuery("(min-width:768px)");
  const divClassName = isLoadingEffect ? "row loading-div loading-effect" : "row loading-div";
  const [AmountValue, setAmountValue] = useState(0);
  const [count, setCount] = useState(data.ProductQuantity);

  useEffect(() => {
    setCount(data.ProductQuantity)
  }, [data.ProductQuantity])

  // const [onceUpdated, setOnceUpdated] = useState(contextVariable.DialogBox);

  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [buttonLoading, setbuttonLoading] = useState(props.data.buttonLoading = false);
  const [UOMCodeQuantity, setUOMCodeQuantity] = useState(1);


  const removeFromCart = async () => {
    setbuttonLoading(true)
    const ProductListData = { id: data.id, productID: data.userProductsCartProductdetailsId, ProductName: data.Productdetails.ProductName };
    await removeFromCartGlobal(contextVariable, ProductListData);
    props.removeView(ProductListData);
    setbuttonLoading(false)
  };
  useEffect(() => {
    let formattedQuantity = 1;
    const FindUOMCodeQuantity = data.ProductUOM.find(p => p.CorrespondingQuantityTypeCode === data.ProductPriceUOMCode)
    if (FindUOMCodeQuantity) {
      const quantity = parseFloat(FindUOMCodeQuantity.Quantity);
      formattedQuantity = parseFloat(quantity.toFixed(2));
      setUOMCodeQuantity(formattedQuantity)
    }

    //if discount in the Array then this conditon will work
    if (
      data && data.Productdetails &&
      data.Productdetails.ProductPrice !== null &&
      data.Productdetails.ProductPrice > 0 &&
      data.Productdetails.ProductsDiscounts &&
      data.Productdetails.ProductsDiscounts.items &&
      data.Productdetails.ProductsDiscounts.items.length > 0
    ) {
      const discountPercentage = Math.abs(parseFloat(
        data.Productdetails.ProductsDiscounts.items[0].ProductDiscount
      )
      ) / 100;

      const discountedPrice = parseFloat(data.Productdetails.ProductPrice) - discountPercentage * parseFloat(data.Productdetails.ProductPrice);
      setProductPriceAfterDiscConfirmation(discountedPrice)
      // console.log("data", data)
      setAmountValue(discountedPrice * data.ProductQuantity * (data.ProductPriceUOMCode === data.Productdetails.ProductPriceQuantityCode ? 1 : formattedQuantity))
    }
    //no Discount in the Array the Else part will work
    else if (data && data.Productdetails && data.Productdetails.ProductPrice !== null) {
      setProductPriceAfterDiscConfirmation(data.Productdetails.ProductPrice)
      // console.log("data1", data)
      setAmountValue(data.Productdetails.ProductPrice * data.ProductQuantity * (data.ProductPriceUOMCode === data.Productdetails.ProductPriceQuantityCode ? 1 : formattedQuantity))
    }
    else if (data && data.Productdetails === null) {
      setProductPriceAfterDiscConfirmation("0.00");
      setAmountValue(0);
    }
    // setAmountValue(ProductPriceAfterDiscConfirmation * data.ProductQuantity * UOMCodeQuantity)
    // eslint-disable-next-line 
  }, [removeFromCart])

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };



  useEffect(() => {
    if (props.isLoadingEffect) {
      setIsLoadingEffect(true);
    }

    //eslint-disable-next-line
  }, [removeFromCart, setCount, count]);




  const [ProductStock, setProductStock] = useState("Loading");


  // UseEffect for  total summery after get cart products 

  useEffect(() => {
    if (contextVariable.taxDetails.InventoryStatus && contextVariable.AllUOMDetails.length > 0) {
      const FetchProductStock = async () => {
        try {
          const ProductStockDetails = await API.graphql({
            query: ListByProductInventoryDetails,
            variables:
            {
              MaterialID: data.Productdetails.id
            }
          })
          // console.log("productStockdetails", ProductStockDetails)
          if (ProductStockDetails && ProductStockDetails.data.ListByProductInventoryDetails.SearchResult.length > 0) {
            const ProductStockCount = Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) > 0 ?
              Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) : 0;
            if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
              const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === data.userProductsCartProductdetailsId);
              if (FindSameProductStockReduce.length > 0) {
                const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                if (TotalStockValue > ProductStockCount) {
                  setProductStock(0)
                }
                else {
                  setProductStock(ProductStockCount);
                }
              }
              else {
                setProductStock(ProductStockCount);
              }
            }

            // const GetUOMText = contextVariable.AllUOMDetails.find(P => P.Value === ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].AvailableStockUOM)
            // setStockUom(GetUOMText.Description);
          }
          else {
            setProductStock(0);
          }

        } catch (error) {
          console.error("Fetching Individual product stock", error)
        }
      }
      FetchProductStock();
    }
    else if (!contextVariable.taxDetails.InventoryStatus) {
      setProductStock("NoLimit")
    }
    // eslint-disable-next-line 
  }, [contextVariable.taxDetails.InventoryStatus, contextVariable.AllUOMDetails, data.Productdetails.id])

  useEffect(() => {
    if (data.ProductPrice > 0 && ProductStock !== "Loading") {
      // console.log("first data", data)
      const CurrentAmountValue = {
        id: data.id,
        ProductQuantity: ProductStock === 0 ? 0 : data.ProductQuantity,
        CalculatedUOMQuantity: ProductStock === 0 ? 0 : data.ProductQuantity * UOMCodeQuantity,
        UOMCodeQuantity: UOMCodeQuantity,
        ProductStock: ProductStock,
        Update: "QtyUpdate"
      };
      // console.log("stockWithCurrent", CurrentAmountValue)
      props.TotalAmount(CurrentAmountValue)

    }

    //eslint-disable-next-line
  }, [ProductStock])


  const [AvlStock, setAvlStock] = useState(0)
  useEffect(() => {
    const CheckingProductStock = ProductStock === "NoLimit" ? 9999 : ProductStock;
    if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
      const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === data.userProductsCartProductdetailsId);
      if (FindSameProductStockReduce.length > 0) {
        const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
        const RemainingStock = parseInt((CheckingProductStock - TotalStockValue) / UOMCodeQuantity);
        setAvlStock(RemainingStock < 0 ? 0 : RemainingStock)
      }
      else {
        setAvlStock(CheckingProductStock)
      }

    }
    else {
      setAvlStock(CheckingProductStock)
    }
  }
    // eslint-disable-next-line 
    , [ProductStock, AvlStock, contextVariable.UserCartItems])

  const GlobalCartQtyChange = (newValue) => {
    const UpdatedWithQty = contextVariable.UserCartItems.map((p) => {
      if (p.id === data.id) {
        return { ...p, ProductQuantity: newValue, CalculatedUOMQuantity: newValue * UOMCodeQuantity, ProductStock: ProductStock }
      }
      else {
        return p
      }
    })
    contextVariable.setUserCartItems(UpdatedWithQty)
  }



  //input Box Numbers handling its onChange event
  const handleInputChange = (event) => {
    // console.log("event", event.target.value)
    const enteredValue = event.target.value.trim();
    let RemainingStock;
    if (
      event.target.value === "" ||
      event.key === "Backspace" ||
      event.key === "Delete"
    ) {
      setCount("");
    } else {
      const numericValue = enteredValue.replace(/[^0-9]/g, "");
      const value = Number(numericValue);
      const CheckingProductStock = ProductStock === "NoLimit" ? 9999 : ProductStock;

      if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
        const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === data.userProductsCartProductdetailsId);
        // console.log("FindSameProductStockReduce", FindSameProductStockReduce)
        if (FindSameProductStockReduce.length > 0) {
          const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
          RemainingStock = parseInt((CheckingProductStock - TotalStockValue + data.CalculatedUOMQuantity) / UOMCodeQuantity);

        }
        else {
          RemainingStock = CheckingProductStock;
        }

      }
      else {
        RemainingStock = CheckingProductStock;
      }
      // console.log("RemainingStock", RemainingStock)
      setAvlStock(RemainingStock - value)
      const newValue = Math.min(Math.max(value, 1), RemainingStock);
      setCount(newValue);
      GlobalCartQtyChange(newValue);
      setAmountValue((ProductPriceAfterDiscConfirmation * newValue).toFixed(2));
      const CurrentAmountValue = {
        id: data.id,
        ProductQuantity: newValue,
        UOMCodeQuantity: UOMCodeQuantity,
        CalculatedUOMQuantity: newValue * UOMCodeQuantity,
        ProductStock: CheckingProductStock,
        Update: "QtyUpdate"
      };
      props.TotalAmount(CurrentAmountValue);
      const ShowToastUOM = contextVariable.AllUOMDetails.find(P => P.Value === data.ProductPriceUOMCode)?.Description || '';
      if (value > RemainingStock) {
        showToast(`${'Only'} ${RemainingStock} ${ShowToastUOM} ${'available'}`, "Info", 2000)
      }
    }
  };

  const handleInputFocus = () => {
    handleTooltipOpen();
    props.isInputFocused(true);
  };

  const handleInputBlur = () => {
    if (!isEnterPressed) {

      // If input is left empty, set it to 1 on blur
      if (count === "") {
        setCount(1);
        GlobalCartQtyChange(1);
        setAmountValue(ProductPriceAfterDiscConfirmation);
        const CurrentAmountValue = {
          id: data.id,
          ProductQuantity: 1,
          UOMCodeQuantity: UOMCodeQuantity,
          CalculatedUOMQuantity: 1 * UOMCodeQuantity,
          ProductStock: ProductStock,
          Update: "QtyUpdate"
        };
        props.TotalAmount(CurrentAmountValue);
      }
      handleSave();
      props.isInputFocused(false);
      handleTooltipClose();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEnterPressed(true);
      setIsLoadingEffect(true);
      setTimeout(() => {
        setIsLoadingEffect(false);
      }, 1000);
      handleSave();
      props.isInputFocused(false);
      event.target.blur();
      setIsEnterPressed(false);
      handleTooltipClose();
    }
  };



  //Discount or price change Condition
  // const [isPaused, setIsPaused] = useState(false);
  // const toggleMarquee = () => {
  //   setIsPaused(!isPaused);
  // };

  const [onceUpdated, setOnceUpdated] = useState(true);
  const updatedFunction = async () => {
    const updateCart = {
      id: data.id,
      ProductDiscount: data.Productdetails.ProductDiscount,
      ProductPrice: data.Productdetails.ProductPrice
    };
    const updateInput = {
      input: updateCart
    };

    try {
      const updateCartDetails = await API.graphql({
        query: updateUserProductsCart,
        variables: updateInput
      });
      const CurrentAmountValue = {
        id: updateCartDetails.data.updateUserProductsCart.userProductsCartProductdetailsId,
        ProductPrice: updateCartDetails.data.updateUserProductsCart.ProductPrice,
        ProductDiscount: updateCartDetails.data.updateUserProductsCart.ProductDiscount,
        Update: "PriceAndDiscountUpdate"
      };
      props.TotalAmount(CurrentAmountValue)
      contextVariable.setDialogBox(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (ProductStock > 0 || ProductStock === "NoLimit") {
    //Discount =0 Discount=0 Condition
    if (
      data.ProductDiscount === 0 &&
      data.Productdetails.ProductsDiscounts &&
      data.Productdetails.ProductsDiscounts.items.length === 0
    ) {

      //Discount =0 Discount=0 Condition but Product Price change
      if (data.ProductPrice !== data.Productdetails.ProductPrice) {
        if (onceUpdated) {
          // contextVariable.setDialogBox(true);
          setOnceUpdated(false);
          updatedFunction();
        }
      }
    }
    //Discount =0 Discount >0 Added (price equal or Change)
    else if (
      data.ProductDiscount === 0 &&
      data.Productdetails.ProductsDiscounts &&
      data.Productdetails.ProductsDiscounts.items.length > 0
    ) {
      if (onceUpdated) {
        // contextVariable.setDialogBox(true);
        setOnceUpdated(false);
        updatedFunction()
      }

    }
    //Discount Available  vs Discount Available part
    else if (
      Math.abs(parseFloat(data.ProductDiscount)) > 0 &&
      data.Productdetails.ProductsDiscounts &&
      data.Productdetails.ProductsDiscounts.items.length > 0
    ) {

      //Discount >0 and Discount >0 in this case discount Changed
      if (
        Math.abs(parseFloat(data.ProductDiscount)) !==
        Math.abs(
          parseFloat(
            data.Productdetails.ProductsDiscounts.items[0].ProductDiscount
          )
        )
      ) {
        if (onceUpdated) {
          // contextVariable.setDialogBox(true);
          setOnceUpdated(false);
          updatedFunction();
        }

      }
      // Discount are Equal but Product price Changed
      else if (
        Math.abs(parseFloat(data.ProductDiscount)) === Math.abs(parseFloat(data.Productdetails.ProductsDiscounts.items[0].ProductDiscount))
        &&
        data.ProductPrice !== data.Productdetails.ProductPrice
      ) {
        if (onceUpdated) {
          // contextVariable.setDialogBox(true);
          setOnceUpdated(false);
          updatedFunction();
        }


      }
    }
    // product Discount Cancelled
    else if (
      Math.abs(parseFloat(data.ProductDiscount)) > 0 &&
      data.Productdetails.ProductsDiscounts &&
      data.Productdetails.ProductsDiscounts.items.length === 0
    ) {
      if (onceUpdated) {
        // contextVariable.setDialogBox(true);
        setOnceUpdated(false);
        updatedFunction();
      }

    }
    // }
    // eslint-disable-next-line 
  }, [ProductStock])


  // const op = useRef(null);

  // Now, you can use the updates variable in your JSX where needed
  const handleSave = async () => {
    setIsLoadingEffect(true);
    const savedValue = count === "" ? 1 : count;
    // console.log("data", data)
    const CurrentAmountValue = {
      id: data.id,
      ProductQuantity: savedValue,
      CalculatedUOMQuantity: data.UOMCodeQuantity * savedValue
    };
    // console.log("currentAMountValue", CurrentAmountValue)
    // Saving API
    try {
      await API.graphql({
        query: updateUserProductsCart,
        variables: {
          input: CurrentAmountValue,
        },
      });
      props.TotalAmount({
        ...CurrentAmountValue, Update: "QtyUpdate", UOMCodeQuantity: UOMCodeQuantity,
        ProductStock: ProductStock,
      })
      // console.log("result", result)
      setTimeout(() => {
        setIsLoadingEffect(false);
      }, 1000);
    }
    catch (error) {
      console.error("Saving Quantity Error", error.data);
      setTimeout(() => {
        setIsLoadingEffect(false);
      }, 1000);
    }
  };
  const { fadeIn } = useFade();
  // console.log('ProductPriceAfterDiscConfirmation', ProductPriceAfterDiscConfirmation)
  // console.log("data", data)
  const unitView = data?.ProductUOM?.find((p) => p.CorrespondingQuantityTypeCode === data.ProductPriceUOMCode);

  const UOMQuantityDetails = unitView ? (
    <span style={{ fontSize: "10px" }} className="text-gray-500">
      (
      {/* {parseInt(unitView.CorrespondingQuantity)}  */}
      {count === "" ? count : data.ProductQuantity} <UOMConversion UOMCode={unitView.CorrespondingQuantityTypeCode} /> ={' '}
      {/* {parseInt(unitView.Quantity)} */}
      {count === "" ? count * parseInt(unitView.Quantity) : data.ProductQuantity * parseInt(unitView.Quantity)} <UOMConversion UOMCode={unitView.unitCode} />)
    </span>
  ) : null;

  const combinedTooltipContent = (
    <span className="flex flex-column">
      {/* {tooltipContent} */}
      {t(`Press 'Enter' or 'Tab' to save the changes`)}
    </span>
  );
  return (
    <>
      {/* <OverlayPanel ref={op} className=''>
      {UOMQuantityDetails}
      </OverlayPanel> */}
      <div className={`box-container ${fadeIn ? "fade-in" : ""} w-100`}>
        {/* laptop view */}
        {
          isLaptop &&
          <>
            <div className="col-sm-12">

              <div className={`${divClassName} shadow-3 border-round-lg mx-2`}>

                {isLoadingEffect && (
                  <div className="centered-content">
                    <div className="loading-circle"></div>
                  </div>
                )}
                <div className="flex flex-column gap-2">

                </div>

                <div className="flex align-items-center gap-2">
                  <div className="col-sm-2 mb-2 flex justify-content-center pt-1">
                    <Authorizedimages
                      ProductImage={data.Productdetails ? data.Productdetails.ProductImage : "path/to/error-image.jpg"}
                      From="PlaceOrderListView" />
                  </div>

                  <div className="flex flex-column gap-4 w-full">
                    <div className=" flex justify-content-between gap-4 align-items-center">

                      <div className="flex flex-column justify-content-between gap-1" style={{ width: "30rem" }}>

                        {ProductNameResponsive ? (
                          <>
                            <div
                              className="text align-items-center"
                            >

                              {data.Productdetails && data.Productdetails.ProductName
                                ? <TruncateText
                                  text={data.Productdetails.ProductName}
                                  maxLength={90}
                                />
                                : "Product Unavailable"}
                            </div>

                          </>
                        ) : (
                          <>
                            <TruncateText
                              text={data.Productdetails.ProductName}
                              maxLength={65}
                            />
                          </>
                        )
                        }
                        {/* <div>{data.userProductsCartProductdetailsId}</div> */}
                        {/* Discount Section */}
                        {(data.ProductPrice !== 0 && data.ProductPrice > 0 && data.ProductDiscount !== 0 &&
                          data.Productdetails.ProductsDiscounts &&
                          data.Productdetails.ProductsDiscounts.items &&
                          data.Productdetails.ProductsDiscounts.items.length > 0) && (
                            <div className="mt-1 d-flex justify-content-start align-items-center gap-2 w-max">
                              <div className="bg-green-600 text-white font-semibold text-xs px-2 border-round">
                                {t('Save:')}{" "}
                                {getSymbolFromCurrency(
                                  data.Productdetails.ProductPriceCurrencyCode
                                )}
                                {CurrencyFormat(
                                  data.Productdetails.ProductPrice *
                                  Math.abs(parseFloat(
                                    data.Productdetails.ProductsDiscounts.items[0].ProductDiscount
                                  )
                                  ) / 100
                                )}
                              </div>
                              <span className="text-green-600 font-semibold text-xs">
                                {Math.abs(parseFloat(data.ProductDiscount))}% {t('off')}
                              </span>
                            </div>
                          )}
                        {
                          (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && (contextVariable.taxDetails.InventoryStatus && ProductStock > 0)) &&
                          <div className="text-xs font-semibold   border-round-sm ">
                            {AvlStock !== 0 && <span className="text-red-700   border-round-3xl " >{t('Avl.Stock:')} {AvlStock}  <UOMConversion UOMCode={data.ProductPriceUOMCode} /></span>}
                            {AvlStock === 0 && <span className="text-red-700 bg-red-50  border-round-3xl  fadeinup animation-duration-2000 animation-iteration-infinite" >{t('Stock Not Available')}</span>}
                          </div>
                        }
                      </div>

                      {/* 2nd Section */}
                      {(data.ProductPrice !== 0 && data.ProductPrice > 0 && (ProductStock === "NoLimit" || ProductStock > 0)) && <div className=" w-100 flex ">
                        <div className="w-50">
                          {/* Price and per piece div */}
                          <div
                            style={{ width: priceAndAmountDivResponsive ? "" : "100%" }}
                          >
                            {data.Productdetails.ProductPrice !== null && data.Productdetails.ProductPrice > 0 &&
                              data.Productdetails.ProductsDiscounts &&
                              data.Productdetails.ProductsDiscounts.items &&
                              data.Productdetails.ProductsDiscounts.items.length > 0 ? (
                              <>
                                <span style={{ color: "red" }}>
                                  <span
                                    style={{
                                      display: priceAmountResponsive ? "" : "none",
                                    }}
                                  >
                                    Price :{" "}
                                  </span>
                                  <span
                                    style={
                                      contextVariable.isRTL
                                        ? { marginRight: "0.2rem", color: "red" }
                                        : { marginLeft: "0.2rem", color: "red" }
                                    }
                                  >
                                    {getSymbolFromCurrency(
                                      data.Productdetails.ProductPriceCurrencyCode
                                    )}
                                  </span>
                                  {CurrencyFormat(ProductPriceAfterDiscConfirmation)} {" "}
                                </span>

                                <strike
                                  style={{
                                    textDecorationColor: "gray",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span style={{ color: "gray", fontSize: "13px" }}>
                                    {getSymbolFromCurrency(
                                      data.Productdetails.ProductPriceCurrencyCode
                                    )}
                                  </span>
                                  <span style={{ color: "gray", fontSize: "13px" }}>
                                    {parseFloat(data.Productdetails.ProductPrice).toFixed(2)}
                                  </span>
                                </strike>

                                <span
                                  className="ml-1"
                                  style={{ color: "black", fontSize: "80%" }}
                                >
                                  {/* {data.Productdetails.ProductUOMText} */}
                                  {" "}({parseInt(data.Productdetails.ProductPriceQuantity)}
                                  <UOMConversion UOMCode={data.Productdetails.ProductUOM} />)
                                </span>
                              </>
                            ) : (
                              <>
                                <span style={{ color: "red" }}>
                                  <span
                                    style={{
                                      display: priceAmountResponsive ? "" : "none",
                                    }}
                                  >
                                    Price :{" "}
                                  </span>
                                  <span
                                    style={
                                      contextVariable.isRTL
                                        ? { marginRight: "0.2rem", color: "red" }
                                        : { marginLeft: "0.2rem", color: "red" }
                                    }
                                  >
                                    {getSymbolFromCurrency(
                                      data.Productdetails.ProductPriceCurrencyCode
                                    )}
                                  </span>
                                  {parseFloat(data.Productdetails.ProductPrice).toFixed(2)}
                                </span>

                                <span
                                  className="ml-1"
                                  style={{ color: "black", fontSize: "80%" }}
                                >
                                  {" "}({parseInt(data.Productdetails.ProductPriceQuantity)} <UOMConversion UOMCode={data.Productdetails.ProductPriceQuantityCode} />)

                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        {/* input box and kgs div  */}
                        <div className="w-25">
                          <div className="flex">
                            <ClickAwayListener onClickAway={handleTooltipClose}>

                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'var(--sidebar-color)',
                                      fontSize: '12px'
                                    },
                                  },
                                }}
                                placement="top"
                                TransitionComponent={Zoom}
                                enterDelay={500}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={combinedTooltipContent}
                              >

                                <input
                                  type="text"
                                  value={count === "" ? count : data.ProductQuantity}

                                  style={{
                                    width: "55px",
                                    padding: "0px",
                                    borderBottom: "1px solid black",
                                    textAlign: "center",
                                    outline: "none"
                                  }}
                                  onChange={handleInputChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  onKeyDown={handleKeyPress}
                                />

                              </Tooltip>
                            </ClickAwayListener>
                            <span
                              className="mt-2"
                              style={{ marginLeft: "5px", fontSize: "10px" }}
                            >
                              <UOMConversion UOMCode={data.ProductPriceUOMCode} />

                            </span>

                          </div>
                          {UOMQuantityDetails}
                        </div>

                      </div>
                      }

                      {(ProductStock === "Loading")
                        && <div className="w-100 flex gap-5">
                          <Skeleton width="10rem" />
                          <Skeleton width="10rem" />

                        </div>}

                      {
                        (data.ProductPrice <= 0 && ProductStock > 0) &&
                        <div className=""
                        >
                          <span className="font-semibold text-xs md:text-xs border-round-lg py-1 px-3 mt-3"
                            style={{ color: "var(--sidebar-color)", border: "1px solid var(--sidebar-color)" }}>
                            {t(`Unavailable`)}
                          </span>
                        </div>
                      }
                      {
                        (data.ProductPrice !== null && data.ProductPrice !== 0 && data.ProductPrice > 0 && ProductStock === 0) &&
                        <div className="text-base font-semibold pl-2 pr-2  border-round-sm text-center fadein animation-duration-1000 ">
                          <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl " >{t(`Out of Stock`)}</span>
                        </div>
                      }
                    </div>
                    {(ProductStock !== "Loading")
                      && <div className="flex align-items-center justify-content-end gap-5">

                        {/* Amount and amountValue div  */}
                        <div className="">

                          <span
                            style={{ display: priceAmountResponsive ? "" : "none" }}
                          >
                            Total Amount :
                          </span>
                          <span
                            className="sidebar-color"
                            style={{ fontWeight: "600" }}
                          >
                            {" "}
                            {getSymbolFromCurrency(
                              data.Productdetails.ProductPriceCurrencyCode
                            )}
                            {CurrencyFormat(AmountValue)}
                          </span>

                        </div>

                        {/* Remove button */}
                        <div className="">
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                            title="Remove Item"
                          >
                            <div className="">
                              <Button
                                className="secondary-button"
                                loading={buttonLoading}
                                onClick={removeFromCart}
                                // loading={contextVariable.addRemoveCartLoading}

                                style={{ display: priceAmountResponsive ? "" : "flex" }}
                              >
                                <DeleteOutline className="primary-scolor-icon"></DeleteOutline>
                                <span
                                  style={{
                                    display: priceAmountResponsive ? "" : "none",
                                  }}
                                  className="remove"
                                >
                                  {/* {t("remove")} */}
                                </span>
                              </Button>
                            </div>
                          </Tooltip>
                        </div>
                      </div>}
                  </div>
                </div>


              </div>
            </div>
          </>
        }

        {/* Mobile view */}
        {
          isMobile &&
          <>
            <div className="shadow-2 border-round-md p-2 mb-3">
              {/* Image Div */}
              <div className="flex justify-content-center">
                <Authorizedimages ProductImage={data.Productdetails.ProductImage} From="MobileCard" />
              </div>
              {/* Description and others */}
              <div>
                {/* Root Flex */}
                <div className="flex flex-column justify-content-center align-items-center">
                  {/* Description */}
                  <div className="text-center text-base md:text-lg font-semibold">
                    {ProductNameResponsive ? (
                      <>
                        <p
                        >
                          {data.Productdetails.ProductName}
                        </p>

                      </>
                    ) : (
                      <p>
                        <TruncateText
                          text={data.Productdetails.ProductName}
                          maxLength={40}
                        />
                      </p>
                    )
                    }
                  </div>
                  {/* Discount */}
                  <div>
                    {data.ProductDiscount !== 0 && (
                      <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                        <div className="bg-green-600 text-white font-semibold text-xs px-2 border-round ">
                          Save:{" "}
                          {getSymbolFromCurrency(
                            data.Productdetails.ProductPriceCurrencyCode
                          )}
                          {CurrencyFormat(
                            data.Productdetails.ProductPrice *
                            Math.abs(parseFloat(
                              data.Productdetails.ProductsDiscounts.items[0].ProductDiscount
                            )
                            ) / 100
                          )}
                        </div>
                        <span className="text-green-600 font-semibold text-xs">
                          {Math.abs(parseFloat(data.ProductDiscount))}% off
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Price */}
                  <div className="">
                    {/* Price and per piece div */}
                    <div
                      style={{ width: priceAndAmountDivResponsive ? "" : "100%" }}
                    >
                      {data.Productdetails.ProductPrice !== null &&
                        data.Productdetails.ProductsDiscounts &&
                        data.Productdetails.ProductsDiscounts.items &&
                        data.Productdetails.ProductsDiscounts.items.length > 0 ? (
                        <>
                          <span style={{ color: "red" }}>
                            <span
                              style={{
                                display: priceAmountResponsive ? "" : "none",
                              }}
                            >
                              Price :{" "}
                            </span>
                            <span
                            >
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            <span className="font-semibold">
                              {CurrencyFormat(ProductPriceAfterDiscConfirmation)} {" "}
                            </span>
                          </span>

                          <strike
                            style={{
                              textDecorationColor: "gray",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray", fontSize: "13px" }}>
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            <span style={{ color: "gray", fontSize: "13px" }}>
                              {data.Productdetails.ProductPrice}
                            </span>
                          </strike>

                          <span
                            className="ml-1 text-xs"
                            style={{ color: "black", fontSize: "80%" }}
                          >
                            {data.Productdetails.ProductUOMText}
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: "red" }}>
                            <span
                              style={{
                                display: priceAmountResponsive ? "" : "none",
                              }}
                            >
                              Price :{" "}
                            </span>
                            <span
                              style={
                                contextVariable.isRTL
                                  ? { marginRight: "0.2rem", color: "red" }
                                  : { marginLeft: "0.2rem", color: "red" }
                              }
                            >
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            {data.Productdetails.ProductPrice}
                          </span>

                          <span
                            className="ml-1 text-xs"
                            style={{ color: "black", fontSize: "80%" }}
                          >
                            {data.Productdetails.ProductUOMText}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Amount and input count divs */}
                  <div className="mt-2 mb-2 flex gap-2">
                    {/* Input count */}
                    <div className="">
                      <div className="flex">
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={t(`Press 'Enter' or 'Tab' to save the changes.`)}
                          >
                            <input
                              type="text"
                              value={count === "" ? count : data.ProductQuantity}
                              style={{
                                width: "40px",
                                padding: "0px",
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                outline: "none"
                              }}
                              onChange={handleInputChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              onKeyDown={handleKeyPress}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                        <span
                          className="mt-2"
                          style={{ marginLeft: "5px", fontSize: "10px" }}
                        >
                          ({data.Productdetails.ProductUOMText})
                        </span>
                      </div>
                    </div>
                    {/* Amount */}
                    <div>
                      {/* <span
                        className="text-xs font-semibold"
                        style={{ display: priceAmountResponsive ? "" : "none" }}
                      >
                        Amount:
                      </span> */}
                      <span
                        className="sidebar-color text-base font-bold"
                      >
                        {" "}
                        {getSymbolFromCurrency(
                          data.Productdetails.ProductPriceCurrencyCode
                        )}
                        {CurrencyFormat(AmountValue)}
                      </span>
                    </div>
                  </div>
                  {/* Remove button */}
                  <div>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'var(--sidebar-color)',
                            fontSize: '12px'
                          },
                        },
                      }}
                      placement="bottom"
                      TransitionComponent={Zoom}
                      enterDelay={500}
                      title="Remove Item"
                    >
                      <div>
                        <Button
                          className="secondary-button"
                          loading={buttonLoading}
                          onClick={removeFromCart}
                          icon="pi pi-trash"
                          label="Remove"
                        >
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {/* Tab veiw */}
        {
          isTab &&
          <>
            <div className="flex justify-content-around align-items-center shadow-2 border-round-md p-2">
              {/* Image Div */}
              <div className="flex justify-content-center">
                <Authorizedimages ProductImage={data.Productdetails.ProductImage} From="MobileCard" />
              </div>
              {/* Description and others */}
              <div>
                {/* Root Flex */}
                <div className="flex flex-column justify-content-center align-items-center">
                  {/* Description */}
                  <div className="text-center text-base md:text-lg font-semibold">
                    {ProductNameResponsive ? (
                      <>
                        <p
                        >
                          {data.Productdetails.ProductName}
                        </p>

                      </>
                    ) : (
                      <p>
                        <TruncateText
                          text={data.Productdetails.ProductName}
                          maxLength={40}
                        />
                      </p>
                    )
                    }
                  </div>
                  {/* Discount */}
                  <div>
                    {data.ProductDiscount !== 0 && (
                      <div className="mt-1 d-flex justify-content-start align-items-center gap-2">
                        <div className="bg-green-600 text-white font-semibold text-xs px-2 border-round">
                          Save:{" "}
                          {getSymbolFromCurrency(
                            data.Productdetails.ProductPriceCurrencyCode
                          )}
                          {CurrencyFormat(
                            data.Productdetails.ProductPrice *
                            Math.abs(parseFloat(
                              data.Productdetails.ProductsDiscounts.items[0].ProductDiscount
                            )
                            ) / 100
                          )}
                        </div>
                        <span className="text-green-600 font-semibold text-xs">
                          {Math.abs(parseFloat(data.ProductDiscount))}% off
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Price */}
                  <div className="">
                    {/* Price and per piece div */}
                    <div
                      style={{ width: priceAndAmountDivResponsive ? "" : "100%" }}
                    >
                      {data.Productdetails.ProductPrice !== null &&
                        data.Productdetails.ProductsDiscounts &&
                        data.Productdetails.ProductsDiscounts.items &&
                        data.Productdetails.ProductsDiscounts.items.length > 0 ? (
                        <>
                          <span style={{ color: "red" }}>
                            <span
                              style={{
                                display: priceAmountResponsive ? "" : "none",
                              }}
                            >
                              Price :{" "}
                            </span>
                            <span
                            >
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            <span className="font-semibold">
                              {CurrencyFormat(ProductPriceAfterDiscConfirmation)} {" "}
                            </span>
                          </span>

                          <strike
                            style={{
                              textDecorationColor: "gray",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray", fontSize: "13px" }}>
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            <span style={{ color: "gray", fontSize: "13px" }}>
                              {data.Productdetails.ProductPrice}
                            </span>
                          </strike>

                          <span
                            className="ml-1 text-xs"
                            style={{ color: "black", fontSize: "80%" }}
                          >
                            {data.Productdetails.ProductUOMText}
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: "red" }}>
                            <span
                              style={{
                                display: priceAmountResponsive ? "" : "none",
                              }}
                            >
                              Price :{" "}
                            </span>
                            <span
                              style={
                                contextVariable.isRTL
                                  ? { marginRight: "0.2rem", color: "red" }
                                  : { marginLeft: "0.2rem", color: "red" }
                              }
                            >
                              {getSymbolFromCurrency(
                                data.Productdetails.ProductPriceCurrencyCode
                              )}
                            </span>
                            {data.Productdetails.ProductPrice}
                          </span>

                          <span
                            className="ml-1 text-xs"
                            style={{ color: "black", fontSize: "80%" }}
                          >
                            {data.Productdetails.ProductUOMText}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Amount and input count divs */}
                  <div className="mt-2 mb-2 flex gap-2">
                    {/* Input count */}
                    <div className="">
                      <div className="flex">
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={t(`Press 'Enter' or 'Tab' to save the changes.`)}
                          >
                            <input
                              type="text"
                              value={count === "" ? count : data.ProductQuantity}
                              style={{
                                width: "40px",
                                padding: "0px",
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                outline: "none"
                              }}
                              onChange={handleInputChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              onKeyDown={handleKeyPress}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                        <span
                          className="mt-2"
                          style={{ marginLeft: "5px", fontSize: "10px" }}
                        >
                          ({data.Productdetails.ProductUOMText})
                        </span>
                      </div>
                    </div>
                    {/* Amount */}
                    <div>
                      {/* <span
                        className="text-xs font-semibold"
                        style={{ display: priceAmountResponsive ? "" : "none" }}
                      >
                        Amount:
                      </span> */}
                      <span
                        className="sidebar-color text-base font-bold"
                      >
                        {" "}
                        {getSymbolFromCurrency(
                          data.Productdetails.ProductPriceCurrencyCode
                        )}
                        {CurrencyFormat(AmountValue)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Remove button */}
              <div>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'var(--sidebar-color)',
                        fontSize: '12px'
                      },
                    },
                  }}
                  placement="bottom"
                  TransitionComponent={Zoom}
                  enterDelay={500}
                  title="Remove Item"
                >
                  <div>
                    <Button
                      className="secondary-button"
                      loading={buttonLoading}
                      onClick={removeFromCart}
                      icon="pi pi-trash"
                      label="Remove"
                    >
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
};
export default PlaceOrderListView;
