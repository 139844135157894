import { API } from "aws-amplify";
import { showToast } from "./ToastUtils";
import { paymentHistoriesByCustomerIdAndDateOfTransaction } from "../Layout/MutaionsAndQueries";
import i18n from '../i18/i18nConfig';

export const FetchDataOfPaymentHistory = async (contextVariable, setRowData, setError) => {
  const customerId = contextVariable.userDetails["custom:customer_UUID"] + '-' + contextVariable.userDetails["custom:tenant"];
  try {
    const ListOfPaymentHistoryResponse = await API.graphql({
      query: paymentHistoriesByCustomerIdAndDateOfTransaction,
      variables: {
        CustomerId: customerId,
        sortDirection: 'DESC'
      }
    });
    if (ListOfPaymentHistoryResponse.data.paymentHistoriesByCustomerIdAndDateOfTransaction.items.length > 0) {
      // const transformedArray = ListOfPaymentHistoryResponse.data.paymentHistoriesByCustomerId.items.map(item => {
      //   const InvoiceIds = item.PaymentHistoryDetails.items.map(PaymentHistoryDetails => PaymentHistoryDetails.InvoiceId).join(',');
      //   return {
      //     ...item,
      //     InvoiceIds: InvoiceIds,
      //   };
      // });
      // setRowData(transformedArray);
      setRowData(ListOfPaymentHistoryResponse.data.paymentHistoriesByCustomerIdAndDateOfTransaction.items);
    }
    else {
      setRowData(0);
    }
  }
  catch (error) {
    setRowData(0);
    setError(error.errors[0].message);

    if (error.message && error.message.includes('No current user')) {
      console.error('Session timeout or unauthenticated state detected:', error);
      contextVariable.setIsSessionExpired(true);
    }
    else {
      console.error("payment history api Error", error);
      showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 4000);
    }

    // showToast(`Error in fetching payment history ${error.errors[0].message}`, "error");
    console.error("Payment History Fetching Error", error);
  }
};

// Recent Payments
// export const FetchInvoiceWisePaymentDetails = async (InvoiceId, setPaymentDetailsOfInvoice) => {

//   try {
//     const response = await API.graphql(
//       {
//         query: paymentHistoryDetailsByInvoiceId,
//         variables:
//         {
//           InvoiceId: InvoiceId
//         }
//       }
//     )
//     if (response.data.paymentHistoryDetailsByInvoiceId.items) {
//       setPaymentDetailsOfInvoice(response.data.paymentHistoryDetailsByInvoiceId.items)
//     }
//   }
//   catch (error) {
//     console.error("Invoice Wise payment details fetching error", error)
//   }


// }
