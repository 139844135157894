import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../Layout/Layout'
// import { API } from 'aws-amplify';
import getSymbolFromCurrency from 'currency-symbol-map';
// import SkeletonLoader from '../../SkeletonLoader';
// import { Link } from 'react-router-dom';
import {ContentCopyOutlined } from '@mui/icons-material';
// import { showToast } from '../../ToastUtils';
import CardTopbar from '../CardTopbar';
import { IconButton } from '@mui/material';
import copyToClipboard from '../../copyToClipboard';
import CurrencyFormat from '../../CurrencyFormat';

const PaymentHistoryDetailsCard = () => {
    const contextVariable = useContext(AppContext);
    // console.log("contextVariable", contextVariable.rootObject);

    const handleCopyToClipboard = (params) => {
        if (params) {
            copyToClipboard(params);
        }
    }
    const [PaymentHistoryDetails, setPaymentHistoryDetails] = useState([]);

    const updateRowData = useCallback(() => {
        if (contextVariable.rootObject.PaymentHistoryDetails) {
            setPaymentHistoryDetails(JSON.parse(contextVariable.rootObject.PaymentHistoryDetails));
        }
    }, [contextVariable.rootObject]);

    useEffect(() => {
        updateRowData();
        // console.log("PaymentHistoryDetails", PaymentHistoryDetails)
    }, [updateRowData]);

    return (
        <div className='h-screen' style={{ background: "#ededed", fontFamily: "helvetica" }}>

            {/* Topbar */}
            <CardTopbar
                // header={`Ref No : ${contextVariable.rootObject.TransactionReferenceNo}`}
                header="Payment Details"
                link="paymentHistory"
                buttonNeeded={true}
            />

            <div className="m-2 overflow-auto">
                <div className='card bg-white' style={{ color: "var(--sidebar-color)" }}>
                    <div className='p-2'>
                        {/* Ref no */}
                        <div className='border-1 border-gray-300 border-round-md'>
                            <div className='p-2 flex justify-content-between align-items-center'>
                                <div className='flex gap-2'>
                                    <span className='text-sm value-color'>Ref No :</span>
                                    <span
                                    >
                                        {contextVariable.rootObject.TransactionReferenceNo}
                                    </span>
                                </div>
                                <div>
                                    <IconButton
                                        onClick={() => handleCopyToClipboard(contextVariable.rootObject.TransactionReferenceNo)}
                                    >
                                        <ContentCopyOutlined
                                            className='value-color'
                                        />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {/* Bamk payment */}
                        <div className='flex gap-2 text-xs pt-2 pl-2'>
                            <span className='value-color'>SAP Bank Payment Advice:</span>
                            <span>{contextVariable.rootObject.SAPBankPaymentadviceId}</span>
                        </div>
                        <div className='grid pl-2 pr-2 pt-2'>
                            <div className='col-3 text-xs'>
                                {
                                    contextVariable.rootObject.PaymentStatus === "In Process" &&
                                    <span className='var(--sidebar-color)'>{contextVariable.rootObject.PaymentStatus}</span>
                                }
                                {
                                    contextVariable.rootObject.PaymentStatus === "Success" &&
                                    <span style={{ color: "#00b321" }}>{contextVariable.rootObject.PaymentStatus}</span>
                                }
                                {
                                    (contextVariable.rootObject.PaymentStatus !== "Success" && contextVariable.rootObject.PaymentStatus !== "In Process") &&
                                    <span className='text-gray-600'>{contextVariable.rootObject.PaymentStatus}</span>
                                }

                            </div>
                            <div className='col-5 text-xs'>
                                <span>{contextVariable.rootObject.DateOfTransaction}</span>
                            </div>
                            <div className='col-4 text-sm text-right'>
                                <span>{getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(contextVariable.rootObject.PaymentAmount)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Invoices */}
                {
                    PaymentHistoryDetails.length !== 0 &&
                    <>
                        <span style={{ paddingLeft: "2px" }}
                            className='text-base font-semibold sidebar-color'>Items</span>
                        {
                            PaymentHistoryDetails
                                .map((data, index) => (
                                    <div key={index} className='card p-3 sidebar-color'>
                                        <div>
                                            <span className='text-xs value-color'>Invoice number</span>
                                        </div>
                                        <div className='flex justify-content-between pt-2 align-items-center'>
                                            <div>
                                                <span className='text-xs'>{data.InvoiceId}</span>
                                            </div>
                                            <div className='text-base text-right'>
                                                <span>
                                                    {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(data.Amount)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default PaymentHistoryDetailsCard;