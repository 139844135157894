import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import "./Order.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import OrderView from "./OrderView";
import { CachedOutlined, PictureAsPdfOutlined } from "@mui/icons-material";
import "./Order.css";
import { API } from "aws-amplify";
import { AppContext } from "../../Layout/Layout";
import { listByCustomerSalesOrderDetails } from "../../Layout/MutaionsAndQueries";
import GetOrderPDF from "./GetOrderPDF";
import { showToast } from "../../components/ToastUtils";
import CurrencyFormat from "../../components/CurrencyFormat";
import { useFade } from "../../components/FadeContext/FadeContext";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import SkeletonLoader from "../../components/SkeletonLoader";
import NoResultsIcon from "./NoResultsIcon";
// import getSymbolFromCurrency from "currency-symbol-map";
import OrderCard from "../../components/cards/orders/OrderCard";
import CardTopbar from "../../components/cards/CardTopbar";
import OrderDetailsCard from "../../components/cards/orders/OrderDetailsCard";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../components/cards/dataNotFound/DataNotFound";
import { useTranslation } from "react-i18next";

const Order = () => {
  const contextVariable = useContext(AppContext);
  const { t } = useTranslation();
  const [orderview, setOrderView] = useState(false);
  const [orderDetails, setOrderDetails] = useState(1);
  const [rowLoadingStates, setRowLoadingStates] = useState({});
  const [error, setError] = useState(null);

  const setRowLoadingState = (rowKey, loading) => {
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [rowKey]: loading,
    }));
  };
  const [loading, setLoading] = useState(false);

  const selectedRowsHandler = (rows) => { };

  const { fadeIn } = useFade();
  const isMobile = contextVariable.isMobile;
  const [disableDate, setDisableDate] = useState(false);
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const dates = [
    { label: String(currentYear), year: currentYear },
    { label: String(currentYear - 1), year: currentYear - 1 },
    { label: String(currentYear - 2), year: currentYear - 2 },
    { label: String(currentYear - 3), year: currentYear - 3 },
    { label: String(currentYear - 4), year: currentYear - 4 },
    { label: String(currentYear - 5), year: currentYear - 5 },
    { label: String(currentYear - 6), year: currentYear - 6 },
    { label: String(currentYear - 7), year: currentYear - 7 },
    { label: String(currentYear - 8), year: currentYear - 8 },
    { label: String(currentYear - 9), year: currentYear - 9 },
    { label: String(currentYear - 10), year: currentYear - 10 },
    { label: String(currentYear - 11), year: currentYear - 11 },
    { label: String(currentYear - 12), year: currentYear - 12 },
    { label: String(currentYear - 13), year: currentYear - 13 },
    { label: String(currentYear - 14), year: currentYear - 14 },
    { label: String(currentYear - 15), year: currentYear - 15 }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  // Each Column Definition results in one Column.
  const columnDefs = [
    {
      field: "OrderStatus",
      headerName: `${t('Status')}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      cellRenderer: (params) => {
        if (params.data.OrderStatusCode === "4") {
          return (
            <span className='px-2 py-1 text-yellow-600 bg-yellow-100 border-round-md font-semibold'>
              {params.value}
            </span>
          );
        } else if (params.data.OrderStatusCode === "1") {
          return (
            <span className='px-2 py-1 text-blue-600 bg-blue-100 border-round-md font-semibold'>
              {params.value}
            </span>
          );
        } else if (params.data.OrderStatusCode === "2") {
          return (
            <span className='px-2 py-1 text-bluegray-600 bg-bluegray-100 border-round-md font-semibold'>
              {params.value}
            </span>

          );
        } else if (params.data.OrderStatusCode === "3") {
          return (
            <span className='px-2 py-1 text-green-600 bg-green-100 border-round-md font-semibold'>
              {params.value}
            </span>
          );
        }
        else {
          return (
            <span className=''>
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "OrderID",
      headerName: `${t('Order ID')}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      sortable: true,
    },
    {
      field: "CreatedDate",
      headerName: `${t('Created Date')}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
    },
    {
      field: "ProductIds",
      headerName: "Product Ids ",
      filter: true,
      flex: 1,
      headerClass: "custom-ag-header",
      hide: true,
    },
    {
      field: "ProductNames",
      headerName: `${t('Product Names')}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      hide: true,
    },
    {
      field: "ExternalReference",
      headerName: `${t('Reference Number')}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
      tooltipField: "ExternalReference",
    },
    {
      field: "TotalAmount",
      headerName: `${t("Total Amount")} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      cellStyle: { textAlign: 'right' },
      flex: 0.5,
      headerClass: "custom-ag-header",
      cellRenderer: (params) => {

        const netAmountString = params.value; // Assuming the value is a string like "190.23"
        const netAmountFloat = parseFloat(netAmountString);
        if (!isNaN(netAmountFloat)) {
          const formattedValue = netAmountFloat.toFixed(2);
          // const formattedNumber = new Intl.NumberFormat(navigator.language).format(formattedValue);
          return CurrencyFormat(formattedValue);
        }
        else {
          return params.value; // Return the original value if parsing fails
        }
      },
      // sort: "asc",
    },
    {
      field: "DeliveryStatus",
      headerName: `${t("Delivery Status")}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",

    },
    {
      field: "InvoiceStatus",
      headerName: `${t("Invoice Status")}`,
      filter: true,
      flex: 0.5,
      headerClass: "custom-ag-header",
    },
    {
      field: "preview",
      headerName: `${t("PDF Preview")}`,
      flex: 0.5,
      headerClass: "custom-ag-header",
      cellRenderer: (params) => {
        const handlePreviewClick = () => {
          setRowLoadingState(params.data.PDFUUID, true);
          GetOrderPDF("order", params.data.PDFUUID, params.data.OrderID).then(() => {
            setRowLoadingState(params.data.PDFUUID, false);
          });
        };
        return (
          rowLoadingStates[params.data.PDFUUID] ? (
            <CachedOutlined
              className="rotating-icon-anticlockwise"
              style={{ marginTop: '-0.3rem' }}
            />
          ) :
            (params.data.PDFUUID).trim() !== '' ?
              <>
                <IconButton
                  style={{ marginTop: '-0.3rem' }}
                  color="var(--sidebar-color)"
                  onClick={handlePreviewClick}
                >
                  <PictureAsPdfOutlined style={{ color: 'red' }} />
                </IconButton>
              </>
              :

              <IconButton
                style={{ marginTop: '-0.3rem' }}
                color="var(--sidebar-color)"
                disabled={true}
              >
                <PictureAsPdfOutlined />
              </IconButton>
        );
      },
    },
  ];

  //Getting Order Details with useEffect
  const fetchSalesorders = async () => {
    setLoading(true);
    setDisableDate(true);
    setOrderDetails(1);
    let OrderSDetails = [];
    let Skip = 0;

    try {
      do {
        const orderResponse = await API.graphql({
          query: listByCustomerSalesOrderDetails,
          variables: {
            year: contextVariable.date,
            limit: contextVariable.FetchLimit.order,
            skip: Skip,
            sortField: "OrderID",
            sortOrder: "desc",
          },
        });
        const sessionYear = parseInt(sessionStorage.getItem("date"))
        if (orderResponse.data.ListByCustomerSalesOrderDetails.year === sessionYear) {
          if (orderResponse.data.ListByCustomerSalesOrderDetails.count < contextVariable.FetchLimit.order) {
            Skip = 0;
            contextVariable.setIsFiltered(false);
          }
          else if (orderResponse.data.ListByCustomerSalesOrderDetails.count >= contextVariable.FetchLimit.order) {
            Skip = Skip + orderResponse.data.ListByCustomerSalesOrderDetails.count;
            contextVariable.setIsFiltered(false);
            // contextVariable.setGlobalSearch(contextVariable.globalSearch)
          }

          OrderSDetails = OrderSDetails.concat(orderResponse.data.ListByCustomerSalesOrderDetails.items)
          if (OrderSDetails.length > 0) {
            const transformedArray = OrderSDetails.map(item => {
              const ProductIds = item.SalesOrderItem.map(salesItem => salesItem.ProductID).join(',');
              const ProductNames = item.SalesOrderItem.map(salesItem => salesItem.ProductDescription).join(',');
              return {
                ...item,
                ProductIds: ProductIds,
                ProductNames: ProductNames
              };
            });
            // console.log("transformedArray", transformedArray);
            setOrderDetails(transformedArray);
          }
          else if (orderResponse.data.ListByCustomerSalesOrderDetails.items.length === 0) {
            setOrderDetails(0);
          }
        }
        else {
          Skip = 0;
        }
      } while (Skip)
      setLoading(false);
      setDisableDate(false);
    }
    catch (error) {
      setOrderDetails(0);
      setDisableDate(false);
      setLoading(false);
      console.error("fetchSalesorders", error);
      setError(error.errors[0].message);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchSalesorders Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  };
  useEffect(() => {
    fetchSalesorders();
    //eslint-disable-next-line
  }, [contextVariable.date]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));

    }
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    sessionStorage.setItem("date", contextVariable.date)
  }, [contextVariable.date]);

  useEffect(() => {
    if (!contextVariable.showView.showOrderDetails) {
      window.scrollTo(0, contextVariable.scrollPosition);
    }
  }, [contextVariable.showView.showOrderDetails, contextVariable.scrollPosition]);

  const handleCardClick = (data) => {
    contextVariable.setRootObject(data);
  }
  // Individual  order open view
  const openOrderItemView = () => {
    setOrderView(true);
  };

  const closeOrderItemView = () => {
    setOrderView(false);
  };

  // Handler for updating the search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateChange = (e) => {
    sessionStorage.setItem("date", e.value.year);
    setSelectedDate(e.value.year);
    // contextVariable.setGlobalData(null);
    contextVariable.setGlobalSearch(contextVariable.globalSearch)
    // contextVariable.setFilteredData([]);
    contextVariable.setIsFiltered(false);
    contextVariable.setDate(e.value.year);
  };

  return (
    <>
      {orderview && (
        <OrderView
          orderDetails={orderDetails}
          backToOrder={closeOrderItemView}
        ></OrderView>
      )}

      {
        (!isMobile && contextVariable.showView.showOrderDetails === false) &&
        <>
          {!orderview && (
            <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
              {/* HEADER */}
              <div>
                {/* <Header title="Sales Orders" /> */}
                {sessionStorage.setItem("title", "Order")}
              </div>

              {/* DataGrid Card  */}
              <>
                <div className="card card-shadow">
                  <div className="card-body">
                    <GridData
                      rowData={orderDetails}
                      columnDefs={columnDefs}
                      orderView={openOrderItemView}
                      component={"order"}
                      tableHeight={"78vh"}
                      needFilters={true}
                      showPagination={true}
                      selectedRows={selectedRowsHandler}
                      filter={"Order"}
                    // customProductIDFilter={customProductIDFilter}
                    />
                  </div>
                </div>
              </>
            </Box>
          )}
        </>
      }
      {
        (isMobile && contextVariable.showView.showOrderDetails === false) &&
        <>
          <div className="bg-gray-50">
            <div className="sticky top-0 z-1">
              {/* Topbar */}
              <CardTopbar
                header={`Orders`}
                buttonNeeded={false}
                refresh={fetchSalesorders}
                loading={loading}
              />
              {/* Dropdown and Search */}
              <div className="bg-gray-50 border-bottom-2 pt-2 pl-2 pr-2 shadow-2">
                <div className="grid">
                  <div className="col-4">
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 w-full mobile-dropdown"
                    />
                  </div>
                  <div className="col-8">
                    <span className="p-input-icon-left w-full">
                      <i className="pt-1 pi pi-search" />
                      <InputText
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by id, status etc..."
                        className="p-inputtext-sm p-2 pl-5 w-full"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto">

              {
                (orderDetails !== 0 && orderDetails !== 1) &&

                orderDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .map((filteredEl, index) => (
                    <OrderCard
                      key={index}
                      index={index}
                      orderDetails={filteredEl}
                      handleCardClick={handleCardClick}
                      contextVariable={contextVariable}
                    />
                  ))
              }
              {/* Loading skeleton */}
              {
                loading &&
                <div className="flex flex-column">
                  {
                    [...Array(7)].map((_, index) => (
                      (orderDetails === 0 || orderDetails === 1) &&
                      <SkeletonLoader key={index} />
                    ))
                  }
                </div>
              }

              {/* No data found for selected year */}
              {
                (orderDetails === 0 && !error) &&
                <>
                  <div className="mt-3 h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                </>
              }

              {/* No data found on search */}
              {
                (orderDetails !== 0 && orderDetails !== 1) &&
                orderDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .length === 0 && (
                  <div className="h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                )
              }
            </div>
          </div>
        </>
      }

      {
        (isMobile && contextVariable.showView.showOrderDetails === true) &&
        <>
          <OrderDetailsCard />
        </>
      }

      {/* Api error */}
      {
        (isMobile && error) &&
        <DataNotFound />
      }
    </>
  );
};

export default Order;
