import React, { useContext, useRef, useState } from 'react'
import { AppContext } from '../../Layout/Layout';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { showToast } from '../ToastUtils';
// import { Add } from '@mui/icons-material';
import { Amplify, Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { useTranslation } from 'react-i18next';

Amplify.configure(awsconfig);
export default function AdminDialogChangeImage(props) {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [displayResponsive, setDisplayResponsive] = useState(contextVariable.show);
    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }
    const [SelectedImage, setSelectedImage] = useState(0)
    const [UploadLoading, setUploadLoading] = useState(false)
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setShow(false);
    }

    const renderFooter = (name) => {
        return (
            <div>

                {/* {contextVariable.show === "ImageEdit" && <>
                    <Button label="Update" icon="pi pi-check" onClick={() => handleLogout(name)} />
                </>
                } */}
            </div>
        );
    }

    const onTemplateSelect = async (e) => {
      
        if (e.files && e.files[0]) {
            setSelectedImage(e);
            setTotalSize(e.files[0].size);
        }
    };

    const onTemplateUpload = async (e) => {
        setUploadLoading(true)
        if (contextVariable.show === "ImageEdit") {
            const selectedFile = e.files[0];
            // const newFilename = props.selectedData.ProductId.replace(/[?*|<>"\\/]/g, '-');
            const newFilename = props.selectedData.data.ProductId.replace(/[?*|<>"\\/.,]/g, '-');

            const extension = selectedFile.type === "image/png" ? ".png" : selectedFile.type === "image/jpeg" ? ".jpg" : selectedFile.type === "image/jpg" ? ".jpg" : "no.ex";
            const modifiedFile = new File([selectedFile], newFilename, { type: selectedFile.type });
            // console.log("newFilename", newFilename)
            // console.log("extension", extension)
            // console.log("modifiedFile", modifiedFile)

            if (!["image/png", "image/jpeg",].includes(selectedFile.type)) {
                showToast(`${t('Please upload only PNG or JPEG files.')}`);
            }
            else
                if (newFilename + extension === modifiedFile.name + extension) {
                    const TenetId = contextVariable.userDetails["custom:tenant"];
                    try {
                        const key = `${TenetId}/TobeProcessed/${modifiedFile.name + extension}`;
                        await Storage.put(key, modifiedFile, {
                            level: 'public',
                        });

                        fileUploadRef.current.clear();
                        showToast(`${t('The image has been uploaded successfully. Please be patient as it may take some time to update.')}`, "success");
                        contextVariable.setShow(false);
                        props.RefreshTableRow();
                        setUploadLoading(false)
                    } catch (error) {
                        console.error('Error uploading image:', error);
                        showToast(`${t('Something went wrong. Please contact the admin.')}`, "error", 4000);
                        setUploadLoading(false)
                    }
                }
                else {
                    setUploadLoading(false);
                    showToast(`${t('File name error. Please make sure that the file name is same as Product ID.')}`, "error")
                }
        }
        else
            if (contextVariable.show === "UploadLogo") {
                // File Reader for image preview
                const selectedFile = e.files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const imageDataURL = event.target.result;
                    // setPreviewImage(imageDataURL);
                    localStorage.setItem('previewImage', imageDataURL);
                };
                reader.readAsDataURL(selectedFile);

                // const selectedFile = e.files[0];
                const TenetId = contextVariable.userDetails["custom:tenant"];
                const extension = selectedFile.type === "image/png" ? ".png" : selectedFile.type === "image/jpeg" ? ".jpg" : selectedFile.type === "image/jpg" ? ".jpg" : "no.ex";
                const modifiedFile = new File([selectedFile], TenetId + extension, { type: extension });

                if (!["image/png", "image/jpeg",].includes(selectedFile.type)) {
                    showToast(`${t('Please upload only PNG or JPEG files.')}`);
                }
                else {
                    try {
                        const key = `${TenetId}/CompanyLogo/${modifiedFile.name}`;
                        await Storage.put(key, modifiedFile, {
                            level: 'public',
                        });
                        const bucket = awsconfig.aws_user_files_s3_bucket;
                        const region = awsconfig.aws_user_files_s3_bucket_region;
                        const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${TenetId}/CompressedCompanyLogo/${TenetId}.png`;
                        fileUploadRef.current.clear();
                        // showToast("Logo Uploaded", "success");
                        localStorage.setItem("Logouploaded", url);
                        props.addedLogo();
                        contextVariable.setShow(false);
                        setUploadLoading(false);
                    }
                    catch (error) {
                        console.error('Error uploading image:', error);
                        if (error.toString() === "AccessDenied: Access Denied") {
                            showToast(`${t('Access Denied.')}`, "error")
                        }
                        setUploadLoading(false)
                    }
                }


            }
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
        setSelectedImage(0);
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setSelectedImage(0);
    };

    const headerTemplate = (options) => {

        const { className, chooseButton } = options;
        const value = totalSize / 20000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        const modifiedChooseButton = React.cloneElement(chooseButton, {
            disable: SelectedImage !== 0,
            onClick: SelectedImage !== 0 ? null : chooseButton.props.onClick, // Optionally, disable the onClick handler
        });
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {SelectedImage === 0 && modifiedChooseButton}
                {/* {SelectedImage !== 0 && <Button className='font-bold gap-1' loading={UploadLoading} onClick={(e) => onTemplateUpload(SelectedImage)} disabled={SelectedImage === 0 ? true : false}>
                    {!UploadLoading && <Add />}
                    Add
                </Button>} */}
                {/* {uploadButton} */}
                {/* {cancelButton} */}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 2 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{
                    fontSize: '5em', borderRadius: '50%',
                    backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'
                }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    {t(`Drag and Drop Image Here`)}
                </span>
            </div>
        );
    };

    return (
        <>
            <Dialog header={props.header} visible={displayResponsive} dismissableMask={false}
                onHide={() => onHide('displayResponsive')}
                breakpoints={{ '960px': '75vw' }}
                style={{ width: '50vw' }}
                footer={renderFooter('displayResponsive')}
            >
                <div className='flex gap-2 text-red-500 align-items-center text-right'>
                    <span className='text-sm font-bold'>{t(`Allowed file types`)} :</span>
                    <p className='text-sm font-semibold'> {t(`PNG (or) JPEG`)}</p>
                </div>
                <div className='flex gap-2 text-red-500 align-items-center text-right'>
                    <span className='text-sm font-bold'>{t(`Allowed maximum size`)} :</span>
                    <p className='text-sm font-semibold'> 2 MB</p>
                </div>

                <div className='mt-3'>
                    {/* <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" /> */}

                    <FileUpload ref={fileUploadRef} name="demo[]"
                        accept="image/*"
                        maxFileSize={2097152}
                        uploadHandler={onTemplateUpload}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear}
                        onClear={onTemplateClear}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                    />
                    <div className='flex justify-content-end'>
                        <Button
                            icon='pi pi-upload'
                            className='px-3 py-1 p-button-sm mt-3'
                            label={t(`Upload`)}
                            loading={UploadLoading}
                            onClick={(e) => onTemplateUpload(SelectedImage)}
                            disabled={SelectedImage === 0 ? true : false}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}
