import { Box } from '@mui/material';
import { Button } from "primereact/button";
import React, { useContext, useEffect, useState } from 'react';
import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material';
import GridData from '../../components/GridData';
import { AppContext } from '../../Layout/Layout';
import { API } from 'aws-amplify';
import { getByCustomerOutboundDeliveryDetail } from "../../Layout/MutaionsAndQueries";
import { showToast } from '../../components/ToastUtils';
import Loader from '../../components/Loader';
import GetOrderPDF from '../order/GetOrderPDF';
import { useFade } from '../../components/FadeContext/FadeContext';
import { useTranslation } from 'react-i18next';

const DeliveryView = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [rowData, setRowData] = useState([]);
    const [generalDetails, setgeneralDetails] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const columnDefs = [
        {
            field: "LineItem", headerName: `${t('Line Item')}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            sortable: true,
            comparator: (valueA, valueB) => {
                return parseInt(valueA, 10) - parseInt(valueB, 10);
            },
        },
        { field: "ProductID", headerName: `${t('Product ID')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
        { field: "ProductDescription", headerName: `${t('Product Description')}`, filter: true, flex: 0.7, headerClass: 'custom-ag-header' },
        {
            field: "Quantity", headerName: `${t('Quantity')}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header',
            cellStyle: { textAlign: 'right' },
            cellRenderer: (params) => {
                return ((parseFloat(params.data.Quantity)).toFixed(2));
            }
        },
        { field: "QuantityUOMText", headerName: `${t('UOM')}`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
        { field: "DeliveryDate", headerName: `${t('Delivery date')}`, filter: true, flex: 0.7, headerClass: 'custom-ag-header' },
        { field: "BatchID", headerName: `${t('Batch ID')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
        { field: "OrderNO", headerName: `${t('Order No')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
        {
            field: "ExternalReferenceNO",
            headerName: `${t('Reference Number')}`,
            filter: true,
            flex: 0.7,
            headerClass: 'custom-ag-header',
            cellRenderer: (params) => {
                if (params.value === "") {
                    return "-";
                }
                else {
                    return params.value;
                }
            }
        }
    ];

    const fetchDeliveryItemDetails = async () => {
        setgeneralDetails(1);
        setRowData(1);
        try {
            const response = await API.graphql({
                query: getByCustomerOutboundDeliveryDetail,
                variables: {
                    DeliveryID: contextVariable.rootObject.DeliveryID
                }
            });
            // console.log("response.data.GetByCustomerOutboundDeliveryDetail.items[0]", response.data.GetByCustomerOutboundDeliveryDetail.items[0]);
            setgeneralDetails(response.data.GetByCustomerOutboundDeliveryDetail.items[0]);
            setRowData(response.data.GetByCustomerOutboundDeliveryDetail.items[0].OutboundDeliveryItem);
        }
        catch (error) {
            console.error("fetchDeliveryItemDetails Error", error);
            if (error.message && error.message.includes('No current user')) {
                console.error('Session timeout or unauthenticated state detected:', error);
                contextVariable.setIsSessionExpired(true);
            }
            else {
                console.error("fetchDeliveryItemDetails Error", error);
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
                // showToast('Something went wrong. Please contact the admin','error',3000);
            }
        }
    }

    const fetchDeliveryItemDetails1 = async (deliveryId) => {
        setgeneralDetails(1);
        setRowData(1);
        try {
            const response = await API.graphql({
                query: getByCustomerOutboundDeliveryDetail,
                variables: {
                    DeliveryID: deliveryId
                }
            });
            setgeneralDetails(response.data.GetByCustomerOutboundDeliveryDetail.items[0]);
            setRowData(response.data.GetByCustomerOutboundDeliveryDetail.items[0].OutboundDeliveryItem);
        }
        catch (error) {
            console.error("fetchDeliveryItemDetails Error", error);
            if (error.message && error.message.includes('No current user')) {
                console.error('Session timeout or unauthenticated state detected:', error);
                contextVariable.setIsSessionExpired(true);
            }
            else {
                console.error("fetchDeliveryItemDetails Error", error);
                showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
            }
        }
    }

    const BeforeDeliveryItem = () => {
        if (currentIndex > 0) {
            contextVariable.setRootObject(contextVariable.filteredData[currentIndex - 1]);
            fetchDeliveryItemDetails1(contextVariable.filteredData[currentIndex - 1].DeliveryID);
            setCurrentIndex(currentIndex - 1)
        }
        else {
            showToast(t(`End of records`), 'info');
        }

    }

    const nextDeliveryItem = () => {
        if (currentIndex < contextVariable.filteredData.length - 1) {
            contextVariable.setRootObject(contextVariable.filteredData[currentIndex + 1]);
            fetchDeliveryItemDetails1(contextVariable.filteredData[currentIndex + 1].DeliveryID);
            setCurrentIndex(currentIndex + 1)
        }
        else {
            showToast(t(`End of records`), 'info');
        }

    }

    useEffect(() => {
        fetchDeliveryItemDetails();
        if (contextVariable.filteredData.length === 0) {
            const initialIndex = props.deliveryDetails.findIndex(item => item.DeliveryID === contextVariable.rootObject.DeliveryID);
            if (initialIndex !== -1) {
                setCurrentIndex(initialIndex);
            }
        }
        else {
            const initialIndex = contextVariable.filteredData.findIndex(item => item.DeliveryID === contextVariable.rootObject.DeliveryID);
            if (initialIndex !== -1) {
                setCurrentIndex(initialIndex);
            }
        }
        //eslint-disable-next-line 
    }, []);

    const showPdf = () => {
        setLoading(true);
        GetOrderPDF("delivery", contextVariable.rootObject.PDFUUID, contextVariable.rootObject.DeliveryID)
            .then(() => {
                setLoading(false);
            });
    }
    const goToDelivery = () => {
        props.backToDelivery();
    }

    const selectedRowsHandler = (rows) => {
    }

    const { fadeIn } = useFade();
    return (
        <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
            {sessionStorage.setItem("title", `Details of Delivery ID : ${contextVariable.rootObject.DeliveryID}`)}
            <div className="d-flex justify-content-between">
                <div>

                    <Button className="secondary-button p-button-sm  mb-2"
                        onClick={goToDelivery} label="Back to delivery list" icon="pi pi-arrow-left">
                    </Button>

                    {
                        (contextVariable.rootObject.PDFUUID).trim() !== '' ?
                            <>
                                <Button
                                    className="primary-button p-button-sm mb-2 ml-2"
                                    loading={loading}
                                    onClick={showPdf}
                                    icon="pi pi-file-pdf"
                                    label="View PDF"
                                >
                                </Button>
                            </> : ''
                    }
                </div>
            </div>
            {/* General Details */}
            <div className="headerDetails card-shadow bg-white ">

                {
                    rowData.length > 0 ?
                        <>
                            <div className="grid ">
                                <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Customer Name')} :</label>
                                    <span className='font-semibold text-left'>{generalDetails.CustomerName}</span>
                                </div>

                                <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400">{t('Tracking ID/URI')} :</label>
                                    <span className='font-semibold text-left'>{generalDetails.TrackingID}</span>
                                    <span className='tex-left'>
                                        <u className='tex-left'>
                                            <a className='tex-left'
                                                href={generalDetails.TrackingURI}
                                                target='_blank' rel='noopener noreferrer'>
                                                <span className='text-blue-500 text-left font-semibold'>{generalDetails.TrackingURI}</span>
                                            </a>
                                        </u>
                                    </span>
                                </div>
                                <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400" >{t('Freight Forwarder')} :</label>
                                    <span className='font-semibold text-left'>{generalDetails.FreightForwarderID + "-" + generalDetails.FreightForwarder}</span>
                                </div>
                                <div className=' col-6 md:col-4 lg:col-1 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400" > {generalDetails.GrossWeightUnitCode ? `Gross Weight (${generalDetails.GrossWeightUnitCode})` : "Gross Weight"} :</label>
                                    <span className='font-semibold text-left'>{(Number(generalDetails.GrossWeight)).toFixed(2)}</span>
                                </div>
                                <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400" >{t('Incoterms')} :</label>
                                    <span className='font-semibold text-left'>{generalDetails.IncotermsText}</span>
                                </div>
                                <div className='col-6 md:col-4 lg:col-2 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400" >{t('Shipping Address')}:</label>
                                    <span className='font-semibold text-left'>{generalDetails.ShippingAddress}</span>
                                </div>
                                <div className='col-6 md:col-4 lg:col-1 flex flex-column text-left'>
                                    <label className="text-xs md:text-xs font-semibold text-gray-400" >{t('Status')} :</label>
                                    <span className='font-semibold text-left'>{generalDetails.ReleaseStatusText}</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div
                                style={{ height: '5vh' }}
                                className="d-flex justify-content-center align-items-center">
                                <Loader />
                            </div>
                        </>
                }


                {/* <div className="Note">
                    <label></label>
                    <span></span>
                    <div className="BadgeView gap-2">
                        <Badge className="mb-1 secondary-badge" value="Net Amount : 1212121 INR"></Badge>
                        <Badge className="mb-1 secondary-badge" value="Gross Amount : 1212121 INR"></Badge>
                    </div>
                </div> */}
            </div>
            {/* Datagrid Card */}
            <div className="mt-5">
                <div>
                    <GridData
                        rowData={rowData}
                        columnDefs={columnDefs}
                        tableHeight={'58vh'}
                        needFilters={false}
                        showPagination={false}
                        selectedRows={selectedRowsHandler}
                    />
                </div>
            </div>
            <div className='d-flex justify-content-between gap-1 mt-3'>
                <button className='btn btn-sm' disabled={currentIndex === 0} style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={BeforeDeliveryItem}
                ><NavigateBeforeOutlined /> {t('Previous')}</button>
                <div>
                    <span style={{ fontWeight: 600 }}>{currentIndex + 1} </span> {t('of')}
                    <span style={{ fontWeight: 600 }}> {contextVariable.filteredData.length}</span>
                </div>
                <button className='btn btn-sm' disabled={(contextVariable.filteredData.length - 1) - currentIndex === 0} style={{ background: 'var(--sidebar-color)', color: '#ffffff' }} onClick={nextDeliveryItem}
                >{t('Next')} <NavigateNextOutlined style={{ fontSize: '18px' }} /></button>
            </div>
        </Box>
    )
}

export default DeliveryView;