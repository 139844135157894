import React, { useState, useContext, useEffect } from 'react'
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { AppContext } from '../../../Layout/Layout';
import { showToast } from '../../ToastUtils';
import { API, Storage } from 'aws-amplify';
import DownloadExcelTemplate from './DownloadExcelTemplate';
import { GetUOMCodeList } from '../../../graphql/queries';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';

const CSVFileUpload = (props) => {
    const { t } = useTranslation();
    // console.log("CSVFileUpload", props);
    // const [file, setFile] = useState(null);\
    const [selectedFile, setSelectedFile] = useState(null);
    const contextVariable = useContext(AppContext);
    const [loadingButton, setLoadingButton] = useState(false);
    const [uomList, setUomList] = useState({
        list: [],
        loading: false
    });

    const fetchUOMCodeList = async () => {
        setUomList({
            list: [],
            loading: true
        });
        try {
            const response = await API.graphql({
                query: GetUOMCodeList
            });
            // console.log("object", response);
            if (response.data.GetUOMCodeList.items.length > 0) {
                setUomList({
                    list: response.data.GetUOMCodeList.items,
                    loading: false
                });
            }
        } catch (error) {
            setUomList({
                list: [],
                loading: false
            });
            console.log("fetchUOMCodeList", error);
            showToast(t(`Something went wrong. Please contact the admin.`), 'error', 3000);
        }
    }

    useEffect(() => {
        if (props.selectedOption.value === 'BP' || props.selectedOption.value === 'CSP') {
            fetchUOMCodeList();
        }
        // eslint-disable-next-line 
    }, [])

    const generateCSV = () => {
        const header = "Code,Text\n";
        const rows = uomList.list.map(uom => `${uom.Value},${uom.Description}`).join("\n");
        return header + rows;
    };

    const downloadCSV = () => {
        try {
            const csvContent = generateCSV();
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "UOMCodes.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            showToast('Something went wrong. Please contact the admin.', 'error', 3000);
        }
    };

    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const parseCSV = (text) => {
        const rows = text.split('\n').filter(row => row.trim() !== '');
        const headers = rows[0].split(',');
        const data = rows.slice(1).map(row => {
            const values = row.split(',');
            return headers.reduce((obj, header, index) => {
                obj[header.trim()] = values[index] ? values[index].trim() : '';
                return obj;
            }, {});
        });
        return data;
    };

    const uploadToS3 = () => {

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD
        const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g, '-')
        const newFilename = `${props.selectedOption.folderName}_${formattedDate}_T${formattedTime}${selectedFile.name.substring(selectedFile.name.lastIndexOf('.'))}`;
        // console.log("newFilename", newFilename);
        contextVariable.setLoadingPriceList(true);
        props.handleLoading();

        if (selectedFile.type === "text/csv") {
            console.log("contextVariable.setLoadingPriceList Inside CSVUplaod", contextVariable.loadingPriceList);
            const TenetId = contextVariable.userDetails["custom:tenant"];
            // const stringWithoutSpaces = selectedFile.name.replace(/\s/g, '');
            try {
                const key = `${TenetId}/AdditionalPricingOptions/${props.selectedOption.folderName}/${newFilename}`;
                // console.log(key);
                Storage.put(key, selectedFile, {
                    level: 'public',
                });
                props.handleLoading();
                setLoadingButton(false)
                showToast(t(`File uploaded successfully. You will be notified once your data are processed.`), "success", 4000);
                props.handleClose();
                setSelectedFile(null);
                contextVariable.setLoadingPriceList(false);
            } catch (error) {
                contextVariable.setLoadingPriceList(false);
                props.handleLoading();

                console.error('Error uploading image:', error);
                showToast(t(`Error while uploading image.`), "error")
            }
        }
        else {
            contextVariable.setLoadingPriceList(false);
            props.handleLoading();
            showToast(t(`Please select a CSV file.`), "error");
        }
    }

    const validateAndCleanData = (csvData, props, uomList) => {
        let cleanedData = [];
        let isDiscountGreaterThan100 = [];
        let invalidUOMCodes = [];

        // Helper function to check if UOMCode is valid
        const isUOMCodeValid = (uomCode) => {
            return uomList.list.some(uom => uom.Value === uomCode);
        };

        if (props.selectedOption.value === 'BP') {
            cleanedData = csvData.filter(item =>
                item.ProductID && item.ProductID.trim() !== "" &&
                item.ProductPrice && item.ProductPrice.trim() !== "" &&
                item.PriceQty && item.PriceQty.trim() !== "" &&
                item.PriceUOMcode && item.PriceUOMcode.trim() !== "" &&
                isUOMCodeValid(item.PriceUOMcode)
            );
            // console.log("object", cleanedData)
        }
        else if (props.selectedOption.value === 'CSP') {
            cleanedData = csvData.filter(item =>
                item.ProductID && item.ProductID.trim() !== "" &&
                item.CustomerID && item.CustomerID.trim() !== "" &&
                item.PriceQty && item.PriceQty.trim() !== "" &&
                item.PriceUOMcode && item.PriceUOMcode.trim() !== "" &&
                isUOMCodeValid(item.PriceUOMcode)
            );
            // console.log("cleanedData CSP", cleanedData);

        }
        else if (props.selectedOption.value === 'CSD') {
            cleanedData = csvData.filter(item =>
                item.ProductID && item.ProductID.trim() !== "" &&
                item.ProductDiscount && item.ProductDiscount.trim() !== "" &&
                item.CustomerID && item.CustomerID.trim() !== ""
            );

            isDiscountGreaterThan100 = csvData.filter(item =>
                item.ProductDiscount && item.ProductDiscount.trim() !== "" &&
                Number(item.ProductDiscount) >= 100
            );
        }

        if (props.selectedOption.value === 'BP' || props.selectedOption.value === 'CSP') {
            invalidUOMCodes = csvData.filter(item =>
                item.PriceUOMcode && item.PriceUOMcode.trim() !== "" && !isUOMCodeValid(item.PriceUOMcode)
            );
        }

        if (invalidUOMCodes.length > 0 && (props.selectedOption.value === 'BP' || props.selectedOption.value === 'CSP')) {
            // console.log("invalidUOMCodes", invalidUOMCodes);
          
            let invalidUOMCodesList = invalidUOMCodes.map((el => el.PriceUOMcode))
            // console.log("invalidUOMCodes", invalidUOMCodesList);
            showToast(t(`Invalid UOMCode(s) found . Please check your data.`), "error", 5000);
            setSelectedFile(null);
        }

        return { cleanedData, isDiscountGreaterThan100, invalidUOMCodes };
    };

    const onUpload = async () => {
        if (selectedFile.size > 5 * 1024 * 1024) {
            showToast(t(`File size exceeds 5 MB. Please choose a smaller file.`), "error");
            return;
        }
        // console.log("first")
        try {
            const text = await readFileAsText(selectedFile);
            const csvData = parseCSV(text);
            // console.log("csvData", csvData)
            const { cleanedData, isDiscountGreaterThan100, invalidUOMCodes } = validateAndCleanData(csvData, props, uomList);
            // console.log("cleanedData first", cleanedData)
            if (isDiscountGreaterThan100.length > 0) {
                showToast(t(`Discount value cannot be greater than 100%. Please verify and try again.`), "error", 4000);
            }

            else if (cleanedData.length === 0 && invalidUOMCodes.length === 0) {
                console.log("first", invalidUOMCodes.length)
                showToast(t('The uploaded CSV file contains invalid data. Please check and try again.'), "error", 4000);
                setSelectedFile(null);
            }

            else if (cleanedData.length !== csvData.length) {
                showToast(t('Uploaded file contains empty cells. Please check and try again.'), 'error', 4000);
            }

            else if (cleanedData.length === csvData.length) {
                // alert()
                uploadToS3();
            }

        }
        catch (error) {
            console.error("Error processing file:", error);
        }
    };

    const onSelect = async (event) => {
        const file = event.files[0];
        setSelectedFile(file);
    };

    return (
        <div className='card p-5 border-2 border-dashed'>
            <div className="flex justify-content-center">
                {
                    (!selectedFile && (props.selectedOption.value !== 'CSD') && uomList.list.length !== 0) &&
                    <div className='flex flex-column gap-5 w-full fadein animation-duration-300'>
                        <div className='flex gap-2 justify-content-start align-items-center w-full'>
                            <>
                                <FileUpload
                                    mode="basic"
                                    accept=".csv"
                                    maxFileSize={10000000} //10 mb
                                    onSelect={onSelect}
                                    chooseLabel={t(`Choose file`)}
                                    chooseOptions={{ style: { width: "8rem" } }}
                                />
                                <span className='text-xs text-gray-500'>
                                    *{t(`Please upload a`)} <span className='font-semibold'>CSV</span> {t(`file with a maximum size limit of`)}
                                    <span className='font-semibold'> 5 MB</span>, {t(`ensuring the`)} <span className='font-semibold'>'UOM'</span> {t(`column contains`)} <span className='font-semibold'>{t(`Codes`)}</span>, {t(`not text`)}.
                                    {t(`You can download the UOM Codes`)} <span className='font-semibold'>
                                        <a href='/' className='underline text-primary' onClick={downloadCSV}>{t(`here`)}</a>
                                    </span>
                                </span>
                            </>
                        </div>

                        {/* <span className='text-sm text-gray-400 font-medium'>or</span> */}

                        <DownloadExcelTemplate
                            priceOption={props.selectedOption}
                            closeDialog={props.handleClose}
                        />
                    </div>
                }
                {
                    (!selectedFile && props.selectedOption.value === 'CSD' && uomList.loading === false) &&
                    <div className='flex flex-column gap-5 w-full fadein animation-duration-300'>

                        <div className='flex gap-4 justify-content-start align-items-center w-full'>
                            <>
                                <div>
                                    <FileUpload
                                        mode="basic"
                                        accept=".csv"
                                        maxFileSize={10000000} //10 mb
                                        onSelect={onSelect}
                                        chooseLabel="Choose file"
                                    />
                                </div>
                                <span className='text-xs text-gray-500'>
                                    *{t(`Please upload a`)} <span className='font-semibold'>CSV</span> {t(`file with a maximum size limit of`)}
                                    <span className='font-semibold'> 5 MB.</span>
                                </span>
                            </>
                        </div>

                        {/* <span className='text-sm text-gray-400 font-medium'>or</span> */}

                        <DownloadExcelTemplate
                            priceOption={props.selectedOption}
                            closeDialog={props.handleClose}
                        />
                    </div>
                }

                {selectedFile && (
                    <div className='flex flex-column gap-2 align-items-center fadein animation-duration-300'>
                        <div>
                            <Button
                                label={t(`Upload`)}
                                loading={loadingButton}
                                className='primary-button'
                                onClick={onUpload}
                                icon='pi pi-upload'
                            />
                        </div>
                        <div className='flex gap-2 align-items-center'>
                            <span className='text-sm text-gray-600 font-medium'>{selectedFile.name}</span>
                            <p
                                className='pi pi-times-circle text-sm text-gray-500 cursor-pointer'
                                onClick={() => setSelectedFile(null)}
                            />
                        </div>
                    </div>
                )}
                {
                    (uomList.loading === true) ?
                        <div className='flex flex-column gap-2 justify-content-center align-items-center'>
                            <Loader />
                            <DownloadExcelTemplate
                                priceOption={props.selectedOption}
                                closeDialog={props.handleClose}
                            />
                        </div>
                        :
                        uomList.list.length === 0 && props.selectedOption.value !== 'CSD' ?
                            <div className='flex flex-column gap-2 justify-content-center align-items-center'>
                                <span className='text-sm font-medium text-gray-400'>{t(`Unable to upload file right now`)}.</span>
                                <DownloadExcelTemplate
                                    priceOption={props.selectedOption}
                                    closeDialog={props.handleClose}
                                />
                            </div>
                            :
                            ''
                }
            </div>
        </div>
    )
}

export default CSVFileUpload