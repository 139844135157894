import { Autocomplete, Box, Checkbox, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Tooltip, Zoom } from '@mui/material'
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import AdminDialogComponent from '../../../../components/Admin/AdminDialogComponent';
import { useState } from 'react';
import { AppContext } from '../../../../Layout/Layout';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, ClearOutlined, EditOutlined, HighlightOffOutlined, InfoRounded, SaveOutlined } from '@mui/icons-material';
import { Field, Formik } from 'formik';
import { showToast } from '../../../../components/ToastUtils';
import { Storage } from 'aws-amplify';
import Loader from '../../../../components/Loader';
import langs from 'langs';
import cc from 'currency-codes';
import AdminDialogChangeImage from '../../../../components/Admin/AdminDialogChangeImage';
import InfoBar from '../../../../components/InfoBar';
import { Country } from 'country-state-city';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectButton } from 'primereact/selectbutton';

export default function OrganizationDetailsForm() {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [SubmitedValues, setSubmitedValues] = useState({});
    const [LogoImage, setLogoImage] = useState("");
    const initialValues = {
        CompanyName: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.CompanyName : "",
        CompanyID: contextVariable.userDetails["custom:company_id"],
        CommencementYear: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.CommencementYear : "",
        AddressLine1: "",
        AddressLine2: "",
        Street: "",
        Country: "",
        City: "",
        PostalCode: "",
        Address: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.Address : "",
        LanguageCode: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.LanguageCode : "",
        CurrencyCode: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.CurrencyCode : "",
        SalesOrganizationID: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.SalesOrganizationID : "",
        SalesOrganizationName: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.SalesOrganizationName : "",
        DistributionChannel: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.DistributionChannel : "",
        EmployeeResponsibleID: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.EmployeeResponsibleID : "",
        SetofBooks: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.SetofBooks : "",
        SystemID: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.SystemID : "",
        SiteID: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.SiteID : "",
        InventoryStatus: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.InventoryStatus : false,
        PriceListName: "",
        NotificationList: "[{\"BPA\":\"true\",\"POR\":\"true\",\"PS\":\"true\"}]",
        AdditionalPricingOptions: 'None',
        DiscountListName: "",
        Tax: contextVariable.OrganizationDetails !== "Empty" ? contextVariable.OrganizationDetails.Tax : "InclusiveTax",
        BankID: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.BankDefaultIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
        BankName: contextVariable.OrganizationDetails !== "Empty" ?
            (contextVariable.OrganizationDetails.BankDefaultIndicator === "true" ? "XXXXXXXXXXXXX" : "")
            : "",
    }

    const imageViewFn = async () => {
        if (contextVariable.OrganizationDetails && contextVariable.OrganizationDetails.Logo) {
            const imageKey = contextVariable.OrganizationDetails.Logo.split("public/")[1];
            // console.log("contextVariable.OrganizationDetails.Logo", contextVariable.OrganizationDetails.Logo)
            // console.log("imageKey", imageKey)
            const authorizedUrlss = await Storage.get(imageKey);
            setLogoImage(authorizedUrlss);
        }
    };

    useEffect(() => {
        imageViewFn();
        // eslint-disable-next-line
    }, [contextVariable.OrganizationDetails]); // Run the effect only once when the component mounts

    //set the Top bar Title
    sessionStorage.setItem("title", t("Customer Portal Setup"));

    // Form Validatation Section 
    const validateCompanyName = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required";
        }
        return error;
    }
    // const validateCompanyID = (value) => {
    //     // console.log("value", value)
    //     let error;
    //     if (!value || value.trim().length === 0) {
    //         error = "Field is required.For  E.g., 1210";
    //     }
    //     return error;
    // }
    const validateCommencementYear = (value) => {
        // console.log("value", value)
        const date = new Date();
        const currentYear = date.getFullYear();
        let error;
        if (!value) {
            error = "Field is required.For  E.g., 2024";
        }
        else if (value < 0) {
            error = "Please enter a valid commencement Year"
        }
        else if (value > currentYear) {
            error = "Please enter a valid commencement Year"
        }
        return error;
    }

    const validateAddressLine1 = (value) => {
        // console.log("value", value)
        // let error;
        // if (!value || value.trim().length === 0) {
        //     error = "Field is required";
        // }
        // return error;
    }
    const validateAddressLine2 = (value) => {
        // let error;
        // if (!value || value.trim().length === 0) {
        //     error = "Field is required";
        // }
        // return error;
    }
    const validateStreet = (value) => {
        // let error;
        // if (!value || value.trim().length === 0) {
        //     error = "Field is required";
        // }
        // return error;
    }
    const validateCity = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required";
        }
        return error;
    }
    const validateCountry = (value) => {
        // console.log("value", value)
        let error;
        if (!value) {
            error = "Field is required";
        }
        return error
    }
    const validatePostalCode = (value) => {
        // console.log("value", value)
        let error;
        if (!value) {
            error = "Field is required.  For  E.g., 542105";
        }
        else if (value < 0) {
            error = "Please enter postal code"
        }
        return error
    }
    const validatelanguageCode = (value) => {
        // console.log("value", value)
        let error;
        if (!value) {
            error = "Field is required.  For  E.g., en-English";
        }
        return error;
    }
    const validateCurrency = (value) => {
        // console.log("value", value)
        let error;
        if (!value) {
            error = "Field is required.For  E.g. USD ";
        }
        return error;
    }
    const validateSalesOrganizationID = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., SD1100-10";
        }
        return error;
    }
    const validateSalesOrganizationName = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., Brick and Mortar";
        }
        return error;
    }
    const validateDistributionChannel = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., 01";
        }
        return error;
    }
    const validateEmployeeResponsibleID = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., ERPL PH";
        }
        return error;
    }

    const validateEmployeeSetofBooks = (value) => {
        // console.log("value", value)
        let error;
        if (!value || value.trim().length === 0) {
            error = "Field is required.For  E.g., FCOA";
        }
        return error;
    }
    const validateSystemID = (value) => {

        if (!value || value.trim().length === 0) {
            return "Field is required.For  E.g., 740607774";
        }

    }


    const validateBankID = (value) => {
        // console.log("value", value)
        // let error;
        // if (!value) {
        //     error = "Bank ID is Mandatory.";
        // }
        // return error;
    }
    const validateBankName = (value) => {
        // console.log("value", value)
        // let error;
        // if (!value) {
        //     error = "Bank Name  is Mandatory.";
        // }
        // return error;
    }
    const validateSiteID = (value) => {
        let error;
        if (!value) {
            error = "Location ID or Site ID  is Mandatory.";
        }
        return error;
    }


    const validatePriceListName = (value) => {
        let error;
        if (/[^a-zA-Z0-9_-\s]/.test(value)) {
            error = "Special characters are not allowed, only letters, numbers, underscore (_) and hyphen (-) are allowed.";
        }
        return error;
    }
    // const validateDiscountListName = (value) => {
    // let error;
    // if (!value) {
    //     error = "Discount List Name is Mandatory.";
    // }
    // return error;
    // }

    const resetFormValues = (resetForm) => {
        resetForm();

    };

    // const demo = (values) => {
    //     // console.log("values", values);
    // }

    const options = ['Yes', 'No'];
    const [PriceAutoSyncIndicator, setPriceAutoSyncIndicator] = useState(contextVariable.OrganizationDetails !== "Empty" ?
        contextVariable.OrganizationDetails.PriceAutoSyncIndicator ? options[0] : options[1] : options[0]);



    const handleFormSubmit = (values) => {
        // console.log("Values", values)
        const Logo = localStorage.getItem("Logouploaded") ? localStorage.getItem("Logouploaded") : "";
        const BankDefaultIndicator = (values.BankID !== "" && values.BankName !== "") ? "true" : "false";
        // const StripeDefaultIndicator = (values.PublishableKey !== "" && values.SecretKey !== "" && values.EndpointSecret !== "") ? true : false;
        const PriceDefaultIndicator = values.PriceListName !== "" ? "true" : "false";
        const { AddressLine1, AddressLine2, Street, City, Country, PostalCode, ...NewValues } = values;
        const AddressLine1Confirm = values.AddressLine1 ? values.AddressLine1.trim() + ", " : "";
        const AddressLine2Confirm = values.AddressLine2 ? values.AddressLine2.trim() + ", " : "";
        const StreetConfirm = values.Street ? values.Street.trim() + ", " : "";
        const CityConfirm = values.City ? values.City.trim() + ", " : "";


        const ConfirmValues = {
            ...NewValues,
            Logo: Logo,
            Address: AddressLine1Confirm + AddressLine2Confirm + StreetConfirm + CityConfirm + values.Country + " - " + values.PostalCode,
            BankDefaultIndicator: BankDefaultIndicator,
            PriceDefaultIndicator: PriceDefaultIndicator,
            SAPCredentialIndicator: "false",
            StripeDefaultIndicator: "false",
            tenant: contextVariable.userDetails["custom:tenant"],
            PriceAutoSyncIndicator: PriceAutoSyncIndicator === 'Yes' ? true : false,
            SiteID: values.InventoryStatus ? values.SiteID : "",
        }
        // console.log("ConfirmValues",ConfirmValues)
        const trimmedObject = {};
        for (const key in ConfirmValues) {
            if (ConfirmValues.hasOwnProperty(key) && typeof ConfirmValues[key] === 'string') {
                trimmedObject[key] = ConfirmValues[key].trim();
            } else {
                trimmedObject[key] = ConfirmValues[key];
            }
        }


        // console.log("trimmedObject", trimmedObject)
        if (!Logo) {
            showToast(t(`Please upload the Logo.`), "error");
        }
        else if ((values.BankID === "" && values.BankName === "") ||
            (values.BankID !== "" && values.BankName !== "")) {
            contextVariable.setShow("Confirm");
            setSubmitedValues(trimmedObject);
        }
        else {
            showToast(t(`Bank details are incomplete. Please complete all fields.`), "error");
        }
    };

    const languages = langs.all();
    // const countries = cc.countries();
    const currency = cc.codes();
    const country = Country.getAllCountries()

    const transformedArray = languages.map(language => ({
        value: `${language['1']}`,
        label: `${language['1']} -  ${language.name}`
    }));

    const transformedCountries = country.map(country => {
        return {
            label: country.name,
            value: country.name
        };
    });
    // const transformedCountries = countries.map(country => {
    //     return {
    //         label: country,
    //         value: country
    //     };
    // });
    const transformedCurrencies = currency.map(code => {
        return {
            label: code,
            value: code
        };
    });


    const LogoUploadfn = () => {
        contextVariable.setShow("UploadLogo");
    }

    const [logoUploaded, setLogoUploaded] = useState(localStorage.getItem("Logouploaded"));
    const [previewImage, setPreviewImage] = useState(null);

    // const [AddedLogoImage, setAddedLogoImage] = useState("");
    const [ImgLoading, setImgLoading] = useState(false);

    const addedLogo = async () => {
        setImgLoading(true);
        setLogoUploaded(true);
    }

    const removeUpload = () => {
        localStorage.removeItem("Logouploaded");
        setLogoUploaded(false);
    }

    useEffect(() => {
        setImgLoading(true);
        const first = async () => {
            // const imageKey = localStorage.getItem("Logouploaded").split("public/")[1];
            // const authorizedUrlss = await Storage.get(imageKey);
            const imgPrev = localStorage.getItem('previewImage');
            setPreviewImage(imgPrev);
            // setAddedLogoImage(authorizedUrlss);
            setImgLoading(false);
        }
        if (logoUploaded) {
            const timeoutId = setTimeout(() => {
                first();
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [logoUploaded]);

    //For Hard back button click
    useEffect(() => {
        if (sessionStorage.getItem("TapPageNumber") !== "0") {
            const currentURL = window.location.href;
            if (currentURL.includes("/customerportalsetup")) {
                contextVariable.setTapPage(0);
                sessionStorage.setItem("TapPagNumber", 0);
            }
        }
        // eslint-disable-next-line  
    }, [])



    return (
        <Box className="admin-box-container">
            {contextVariable.pageInfo === "CompanyDetailsInForm" && <InfoBar header="Company Details"></InfoBar>}
            {contextVariable.pageInfo === "OrganizationDetailsInForm" && <InfoBar header="Organization Details"></InfoBar>}
            {contextVariable.pageInfo === "InventoryDetailsInForm" && <InfoBar header="Inventory Details"></InfoBar>}
            {contextVariable.pageInfo === "PriceDetailsInForm" && <InfoBar header="Price Details"></InfoBar>}
            {contextVariable.pageInfo === "BankDetailsInForm" && <InfoBar header="Bank Details"></InfoBar>}
            {contextVariable.OrganizationDetails === "Loading" &&
                <div style={{ height: "60vh" }} className='flex justify-content-center align-items-center'>
                    <Loader></Loader></div>}


            <div style={{ height: '80vh', overflowY: "scroll" }} >
                {contextVariable.OrganizationDetails !== "Loading" &&
                    <>
                        <div className='flex justify-content-between'>
                            <div className='flex'>
                                <span className='text-base font-semibold gradient-badge'>Company Details

                                </span>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: 'var(--sidebar-color)',
                                                fontSize: '12px'
                                            },
                                        },
                                    }}
                                    title="More info"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    enterDelay={300}
                                >
                                    <span>
                                        <IconButton size="small" onClick={() => contextVariable.setPageInfo("CompanyDetailsInForm")} >
                                            <InfoRounded className='text-base text-gray-400' />
                                        </IconButton>
                                    </span></Tooltip>

                            </div>
                        </div>
                        <Divider className='mt-1'></Divider>

                        <div className='col-6'>
                            {
                                contextVariable.OrganizationDetails !== "Empty" &&
                                <>
                                    <div className="flex gap-5 justify-content-start align-items-center">
                                        <div>
                                            <span className='font-semibold text-base ml-1'>Logo</span>
                                        </div>
                                        <div className='flex gap-5 align-items-center'>
                                            <div>
                                                <img style={{ width: "5rem" }} src={LogoImage} alt="LogoImage" />
                                            </div>
                                            {/* <div className='text-gray-600 text-xs'>
                                                <span >This logo will appear on the PDF document in Orders, Invoices and Credit notes.</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            }
                            <>
                                <div className='flex ml-1 gap-2  align-items-center'>
                                    {!logoUploaded && contextVariable.OrganizationDetails === "Empty" &&
                                        <>
                                            <Button rounded raised className='font-semibold' onClick={LogoUploadfn}>
                                                <Add /> Add logo
                                            </Button>
                                            {/* <span className='text-gray-400 text-xs'>This logo will appear on the PDF document in Orders, Invoices and Credit notes.</span> */}
                                        </>}
                                    {logoUploaded &&
                                        <div className='flex justify-content-start gap-8 align-items-center'>
                                            {/* <span onClick={(e) => op.current.toggle(e)} className='text-gray-400 text-sm font-semibold cursor-pointer flex align-items-center '><CheckCircleOutline />
                                                Your Logo Added Successfully.</span> */}

                                            <div style={{ width: "5rem" }} className='-mb-2'>
                                                {!ImgLoading &&
                                                    <div className='flex gap-4'>
                                                        <span className='text-sm font-semibold'>Logo</span>
                                                        {/* <img src={AddedLogoImage} alt="logo" /> */}
                                                        <img src={previewImage} alt="logo" />
                                                    </div>
                                                }
                                                {ImgLoading && <Loader />}
                                            </div>
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="Remove Logo"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                {/* <Button icon="pi pi-times-circle" className='-mb-2' onClick={RemoveUpload}>
                                                </Button> */}
                                                <IconButton
                                                    className='text-gray-400'
                                                    onClick={removeUpload}
                                                >
                                                    <HighlightOffOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                            </>
                        </div>

                    </>

                }


                {contextVariable.OrganizationDetails !== "Loading" &&
                    <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
                        {({ values, errors, handleChange, handleSubmit, touched, resetForm }) => (
                            <>
                                {/* Organization Details */}
                                <div className='grid nested-grid m-1'>
                                    <div className='col-12'>
                                        <div className='grid'>

                                            <div className='col-4'>
                                                <FormControl fullWidth >
                                                    <Field
                                                        as={TextField} label="Company Name*"
                                                        disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                        helperText={(touched.CompanyName && errors.CompanyName)}
                                                        error={touched.CompanyName && Boolean(errors.CompanyName)}
                                                        size="small" type="text"
                                                        onChange={handleChange}
                                                        name="CompanyName" value={values.CompanyName} validate={validateCompanyName}
                                                    />

                                                </FormControl>
                                            </div>

                                            <div className='col-4'>
                                                <FormControl fullWidth >
                                                    <Field
                                                        as={TextField} label="Company ID*"

                                                        disabled
                                                        // helperText={touched.CompanyID && errors.CompanyID} 
                                                        // helperText={(touched.CompanyID && errors.CompanyID) ? errors.CompanyID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 1210"}
                                                        size="small" type="text"
                                                        // onChange={handleChange}
                                                        error={touched.CompanyID && Boolean(errors.CompanyID)}
                                                        name="CompanyID" value={contextVariable.userDetails["custom:company_id"]}
                                                    // validate={validateCompanyID}
                                                    />
                                                </FormControl>
                                            </div>

                                            <div className='col-4'>
                                                <FormControl fullWidth >
                                                    <Field
                                                        as={TextField} label="Commencement Year*"
                                                        type="number"

                                                        disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                        // helperText={touched.CommencementYear && errors.CommencementYear} 
                                                        helperText={(touched.CommencementYear && errors.CommencementYear) ? errors.CommencementYear : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 2024"}
                                                        size="small"
                                                        onChange={handleChange}
                                                        error={touched.CommencementYear && Boolean(errors.CommencementYear)}
                                                        name="CommencementYear" value={values.CommencementYear} validate={validateCommencementYear}
                                                    />
                                                </FormControl>
                                            </div>

                                            {contextVariable.OrganizationDetails !== "Empty" &&
                                                <div className='col-8'>
                                                    <FormControl fullWidth >
                                                        <Field
                                                            as={TextField} label="Address"
                                                            size="small"
                                                            disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                            // helperText={(touched.Address && errors.Address)}
                                                            type="text"
                                                            // onChange={handleChange}
                                                            // error={touched.Address && Boolean(errors.Address)}
                                                            // validate={validateAddress}
                                                            name="Address" value={contextVariable.OrganizationDetails.Address}

                                                        />

                                                    </FormControl>
                                                </div>}

                                            {contextVariable.OrganizationDetails === "Empty" &&
                                                <>
                                                    <div className='col-4 -mt-2'>
                                                        <FormControl fullWidth >
                                                            <Field
                                                                as={TextField} label="Address Line 1"
                                                                size="small"
                                                                helperText={(touched.AddressLine1 && errors.AddressLine1)}
                                                                type="text"
                                                                onChange={handleChange}
                                                                error={touched.AddressLine1 && Boolean(errors.AddressLine1)}
                                                                name="AddressLine1" value={values.AddressLine1} validate={validateAddressLine1}
                                                            />

                                                        </FormControl>
                                                    </div>
                                                    <div className='col-4 -mt-2'>
                                                        <FormControl fullWidth >
                                                            <Field
                                                                as={TextField} label="Address Line 2"
                                                                size="small"
                                                                helperText={(touched.AddressLine2 && errors.AddressLine2)}
                                                                type="text"
                                                                onChange={handleChange}
                                                                error={touched.AddressLine2 && Boolean(errors.AddressLine2)}
                                                                name="AddressLine2" value={values.AddressLine2} validate={validateAddressLine2}
                                                            />

                                                        </FormControl>
                                                    </div>
                                                    <div className='col-4 -mt-2'>
                                                        <FormControl fullWidth >
                                                            <Field
                                                                as={TextField} label="Street"
                                                                size="small"
                                                                helperText={(touched.Street && errors.Street)}
                                                                type="text"
                                                                onChange={handleChange}
                                                                error={touched.Street && Boolean(errors.Street)}
                                                                name="Street" value={values.Street} validate={validateStreet}
                                                            />

                                                        </FormControl>
                                                    </div>
                                                    <div className='col-4'>
                                                        <FormControl fullWidth >
                                                            <Field
                                                                as={TextField} label="City*"
                                                                size="small"
                                                                helperText={(touched.City && errors.City)}
                                                                type="text"
                                                                onChange={handleChange}
                                                                error={touched.City && Boolean(errors.City)}
                                                                name="City" value={values.City} validate={validateCity}
                                                            />

                                                        </FormControl>
                                                    </div>

                                                    <div className='w-6  field col'>
                                                        <FormControl fullWidth  >
                                                            <Field name="Country" validate={validateCountry} onChange={handleChange}

                                                            >
                                                                {({ field, form }) => (
                                                                    <Autocomplete
                                                                        size="small"
                                                                        disableClearable
                                                                        disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                                        fullWidth
                                                                        options={transformedCountries}
                                                                        getOptionLabel={(option) => option.label}
                                                                        value={transformedCountries.find(
                                                                            (option) => option.value === field.value
                                                                        ) || null
                                                                        }
                                                                        onChange={(event, value) => {
                                                                            form.setFieldValue(
                                                                                "Country",
                                                                                value?.value || ""
                                                                            );

                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="Country*"
                                                                                variant="outlined"
                                                                                error={
                                                                                    form.touched.Country &&
                                                                                    Boolean(form.errors.Country)
                                                                                }
                                                                                helperText={
                                                                                    form.touched.Country &&
                                                                                    form.errors.Country
                                                                                }
                                                                            />
                                                                        )}
                                                                    >

                                                                    </Autocomplete>
                                                                )}

                                                            </Field>
                                                        </FormControl>
                                                    </div>

                                                    <div className='col-4'>
                                                        <FormControl fullWidth >
                                                            <Field
                                                                as={TextField} label="Postal Code*"
                                                                size="small"
                                                                // helperText={(touched.PostalCode && errors.PostalCode)}
                                                                helperText={(touched.PostalCode && errors.PostalCode) ? errors.PostalCode : "For  E.g., 542105"}
                                                                type="number"
                                                                onChange={handleChange}
                                                                error={touched.PostalCode && Boolean(errors.PostalCode)}
                                                                name="PostalCode" value={values.PostalCode} validate={validatePostalCode}
                                                                sx={{
                                                                    '.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall::-webkit-inner-spin-button, .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall::-webkit-outer-spin-button': {
                                                                        WebkitAppearance: 'none',
                                                                        margin: 0,
                                                                    },
                                                                    '.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
                                                                        MozAppearance: 'textfield',
                                                                    },
                                                                }}
                                                            />

                                                        </FormControl>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {/* OrganizationDetails */}
                                <div className='flex'>
                                    <div>
                                        <span className='text-base font-semibold gradient-badge -mt-2'>Organization Details
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="More info"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                <span><IconButton size="small" onClick={() => contextVariable.setPageInfo("OrganizationDetailsInForm")} >
                                                    <InfoRounded className='text-base text-gray-400' />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                                <Divider className='mt-1 mb-3'></Divider>
                                <div className='formgrid grid  m-1 mt-2 '>

                                    <div className='w-6  field col'>
                                        <FormControl fullWidth  >
                                            <Field name="LanguageCode" validate={validatelanguageCode} onChange={handleChange}
                                            >
                                                {({ field, form }) => (
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable
                                                        disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                        fullWidth
                                                        options={transformedArray}
                                                        getOptionLabel={(option) => option.label}
                                                        value={transformedArray.find(
                                                            (option) => option.value === field.value
                                                        ) || null
                                                        }
                                                        onChange={(event, value) => {
                                                            form.setFieldValue(
                                                                "LanguageCode",
                                                                value?.value || ""
                                                            );

                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Language code*"
                                                                variant="outlined"
                                                                error={
                                                                    form.touched.LanguageCode &&
                                                                    Boolean(form.errors.LanguageCode)
                                                                }
                                                                helperText={
                                                                    form.touched.LanguageCode &&
                                                                    form.errors.LanguageCode
                                                                }
                                                            />
                                                        )}
                                                    >

                                                    </Autocomplete>
                                                )}

                                            </Field>
                                        </FormControl>
                                    </div>

                                    <div className='field col'>
                                        <FormControl fullWidth >
                                            <Field name="CurrencyCode" validate={validateCurrency} onChange={handleChange} >
                                                {({ field, form }) => (
                                                    <Autocomplete
                                                        size="small"
                                                        disableClearable
                                                        disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                        fullWidth
                                                        options={transformedCurrencies}
                                                        getOptionLabel={(option) => option.label}
                                                        value={transformedCurrencies.find(
                                                            (option) => option.value === field.value
                                                        ) || null
                                                        }
                                                        onChange={(event, value) => {
                                                            form.setFieldValue(
                                                                "CurrencyCode",
                                                                value?.value || ""
                                                            );

                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Currency code*"
                                                                variant="outlined"
                                                                error={
                                                                    form.touched.CurrencyCode &&
                                                                    Boolean(form.errors.CurrencyCode)
                                                                }
                                                                helperText={
                                                                    form.touched.CurrencyCode &&
                                                                    form.errors.CurrencyCode
                                                                }
                                                            />
                                                        )}
                                                    >

                                                    </Autocomplete>
                                                )}

                                            </Field>
                                        </FormControl>
                                    </div>

                                    <div className='field col '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Sales Organization ID*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.SalesOrganizationID && errors.SalesOrganizationID)}
                                                helperText={(touched.SalesOrganizationID && errors.SalesOrganizationID) ? errors.SalesOrganizationID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., SD1100-10"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SalesOrganizationID && Boolean(errors.SalesOrganizationID)}
                                                name="SalesOrganizationID" value={values.SalesOrganizationID} validate={validateSalesOrganizationID}
                                            />
                                        </FormControl>
                                    </div>

                                </div>

                                <div className='formgrid grid  m-1'>
                                    <div className='field col -mt-2'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Sales Organization Name*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.SalesOrganizationName && errors.SalesOrganizationName)}
                                                helperText={(touched.SalesOrganizationName && errors.SalesOrganizationName) ? errors.SalesOrganizationName : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., Brick and Mortar"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SalesOrganizationName && Boolean(errors.SalesOrganizationName)}
                                                name="SalesOrganizationName" value={values.SalesOrganizationName}
                                                validate={validateSalesOrganizationName}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className='field col -mt-2 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Distribution Channel*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.DistributionChannel && errors.DistributionChannel)}
                                                helperText={(touched.DistributionChannel && errors.DistributionChannel) ? errors.DistributionChannel : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 01"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.DistributionChannel && Boolean(errors.DistributionChannel)}
                                                name="DistributionChannel" value={values.DistributionChannel} validate={validateDistributionChannel}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className=' field col -mt-2 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Employee Responsible ID*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.EmployeeResponsibleID && errors.EmployeeResponsibleID)}
                                                helperText={(touched.EmployeeResponsibleID && errors.EmployeeResponsibleID) ? errors.EmployeeResponsibleID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., ERPL PH"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.EmployeeResponsibleID && Boolean(errors.EmployeeResponsibleID)}
                                                name="EmployeeResponsibleID" value={values.EmployeeResponsibleID} validate={validateEmployeeResponsibleID}
                                            />
                                        </FormControl>
                                    </div>

                                </div>

                                <div className='formgrid grid  m-1 '>

                                    <div className='field col-4 -mt-2 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Set of Books*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.SetofBooks && errors.SetofBooks)}
                                                helperText={(touched.SetofBooks && errors.SetofBooks) ? errors.SetofBooks : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., FCOA"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SetofBooks && Boolean(errors.SetofBooks)}
                                                name="SetofBooks" value={values.SetofBooks} validate={validateEmployeeSetofBooks}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='field col-4 -mt-2 '>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="System ID*"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                // helperText={(touched.SystemID && errors.SystemID)}
                                                helperText={(touched.SystemID && errors.SystemID) ? errors.SystemID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 740607774"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SystemID && Boolean(errors.SystemID)}
                                                name="SystemID" value={values.SystemID} validate={validateSystemID}
                                            />
                                        </FormControl>
                                    </div>

                                </div>

                                {/* Inventory Details  */}
                                <div className='flex'>
                                    <div>
                                        <span className='text-base font-semibold gradient-badge -mt-2'>Inventory Details
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="More info"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                <span><IconButton size="small" onClick={() => contextVariable.setPageInfo("InventoryDetailsInForm")} >
                                                    <InfoRounded className='text-base text-gray-400' />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    {contextVariable.OrganizationDetails !== "Empty" &&
                                        <div>
                                            {/* <EditNoteOutlined onClick={() => contextVariable.setShow("PriceDetailsEdit")} className='text-2xl ml-2  cursor-pointer hover:bg-gray-50 border-round-md' /></>} */}
                                            <Button className='p-button-sm -mt-1' onClick={() => contextVariable.setShow("OrganizationDetailsEdit")}>
                                                <EditOutlined fontSize='small' />
                                            </Button>
                                        </div>
                                    }

                                </div>
                                <Divider className='mt-1 mb-3'></Divider>
                                <div className='formgrid grid  m-1 '>

                                    <div className='field col-4 -mt-2 '>
                                        <FormControl className='w-50'>
                                            <FormControlLabel

                                                control={
                                                    <Checkbox
                                                        onChange={handleChange} checked={contextVariable.OrganizationDetails === "Empty" ? values.InventoryStatus : contextVariable.OrganizationDetails.InventoryStatus} name="InventoryStatus" />
                                                }
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                label={<span className=''>Inventory Indicator</span>}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className='field col-4 -mt-2 '>

                                        {(values.InventoryStatus || contextVariable.OrganizationDetails.InventoryStatus) && <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Location ID or Site ID"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : values.InventoryStatus ? false : true}
                                                helperText={(touched.SystemID && errors.SystemID) ? errors.SystemID : contextVariable.OrganizationDetails !== "Empty" ? "" : "For  E.g., 740607774"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.SiteID && Boolean(errors.SiteID)}
                                                name="SiteID"
                                                value={contextVariable.OrganizationDetails === "Empty" ? values.SiteID : contextVariable.OrganizationDetails.SiteID}
                                                validate={validateSiteID}
                                            />
                                        </FormControl>}
                                    </div>
                                </div>


                                {/* price Details  */}
                                <div className='flex justify-content-start align-items-center'>
                                    <div>
                                        <span className='text-base font-semibold gradient-badge'>
                                            Price Details
                                            <Tooltip
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'var(--sidebar-color)',
                                                            fontSize: '12px'
                                                        },
                                                    },
                                                }}
                                                title="More info"
                                                placement="top"
                                                TransitionComponent={Zoom}
                                                enterDelay={300}
                                            >
                                                <span><IconButton size="small" onClick={() => contextVariable.setPageInfo("PriceDetailsInForm")} >
                                                    <InfoRounded className='text-base text-gray-400' />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    {contextVariable.OrganizationDetails !== "Empty" &&
                                        <div>
                                            {/* <EditNoteOutlined onClick={() => contextVariable.setShow("PriceDetailsEdit")} className='text-2xl ml-2  cursor-pointer hover:bg-gray-50 border-round-md' /></>} */}
                                            <Button className='p-button-sm' onClick={() => contextVariable.setShow("PriceDetailsEdit")}>
                                                <EditOutlined fontSize='small' />
                                            </Button>
                                        </div>
                                    }
                                </div>



                                <Divider className='mt-1 mb-3'></Divider>

                                <div className='formgrid grid  m-1  mt-2'>

                                    <div className='field col-4'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Base Price Name"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                helperText={(touched.PriceListName && errors.PriceListName) ? errors.PriceListName : "For  E.g., SRP_PRICE_LIST"}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.PriceListName && Boolean(errors.PriceListName)}
                                                name="PriceListName"
                                                value={contextVariable.OrganizationDetails === "Empty" ? values.PriceListName : contextVariable.OrganizationDetails.PriceListName}
                                                validate={validatePriceListName}
                                            />
                                        </FormControl>
                                    </div>

                                    {/* Additional pricing options */}
                                    <div className='field col-4'>
                                        <FormControl fullWidth>
                                            <InputLabel id="additional-pricing-options"
                                            >
                                                Additional pricing options</InputLabel>
                                            <Select
                                                size="small"
                                                name="AdditionalPricingOptions"
                                                labelId="additional-pricing-options"
                                                id="additional-pricing-options"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                value={contextVariable.OrganizationDetails === "Empty" ? values.AdditionalPricingOptions : contextVariable.OrganizationDetails.AdditionalPricingOptions}
                                                label="Additional pricing options"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value='None'>None</MenuItem>
                                                <MenuItem value='CSD'>Customer specific discount</MenuItem>
                                                <MenuItem value='CSP'>Customer specific price</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>



                                    <div className='field col-4  flex gap-5 '>
                                        <div>
                                            <FormControl fullWidth >
                                                <Field name="Tax" >
                                                    {({ field }) => (
                                                        <RadioGroup
                                                            row
                                                            name="Tax"
                                                            // value={values.Tax}
                                                            value={contextVariable.OrganizationDetails === "Empty" ? values.Tax : contextVariable.OrganizationDetails.Tax}
                                                            onChange={(e) => {
                                                                handleChange(e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        >
                                                            <FormControlLabel value="InclusiveTax" disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false} control={<Radio />} label="Inclusive Tax" />
                                                            <FormControlLabel value="ExclusiveTax" disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false} control={<Radio />} label="Exclusive Tax" />
                                                        </RadioGroup>
                                                    )}
                                                </Field>
                                            </FormControl>
                                        </div>
                                        <div className='flex  gap-2'>
                                            <div className='mt-2'>
                                                <span className={contextVariable.OrganizationDetails === "Empty" ? `text-xs` : 'text-xs text-gray-400'}>Auto Sync</span></div>
                                            <div className='mt-1'>
                                                <SelectButton
                                                
                                                    disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                    value={contextVariable.OrganizationDetails === "Empty" ? PriceAutoSyncIndicator : contextVariable.OrganizationDetails.PriceAutoSyncIndicator ? 'Yes' : 'No'}
                                                    onChange={(e) => setPriceAutoSyncIndicator(e.value)} options={options} />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {/* bank details  */}
                                <span className='text-base font-semibold gradient-badge '>Bank Details
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'var(--sidebar-color)',
                                                    fontSize: '12px'
                                                },
                                            },
                                        }}
                                        title="More info"
                                        placement="top"
                                        TransitionComponent={Zoom}
                                        enterDelay={300}
                                    >
                                        <span><IconButton size="small" onClick={() => contextVariable.setPageInfo("BankDetailsInForm")} >
                                            <InfoRounded className='text-base text-gray-400' />
                                        </IconButton>
                                        </span></Tooltip>
                                </span>
                                {contextVariable.OrganizationDetails !== "Empty" && <>
                                    {/* <EditNoteOutlined onClick={() => contextVariable.setShow("BankDetailsEdit")} className='text-2xl ml-2 cursor-pointer hover:bg-gray-50 border-round-md' /> */}
                                    <Button className='p-button-sm' onClick={() => contextVariable.setShow("BankDetailsEdit")}>
                                        <EditOutlined fontSize='small' />
                                    </Button>
                                </>}
                                <Divider className='mt-1 mb-3'></Divider>

                                <div className='formgrid grid  m-1  mt-2'>
                                    <div className='field col-4'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Bank ID (SAP Bank A/C Id)"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                helperText={(touched.BankID && errors.BankID)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.BankID && Boolean(errors.BankID)}
                                                name="BankID" value={values.BankID} validate={validateBankID}
                                            />
                                        </FormControl>
                                    </div>


                                    <div className='field col-4'>
                                        <FormControl fullWidth >
                                            <Field
                                                as={TextField} label="Bank Name"
                                                disabled={contextVariable.OrganizationDetails !== "Empty" ? true : false}
                                                helperText={(touched.BankName && errors.BankName)}
                                                size="small" type="text"
                                                onChange={handleChange}
                                                error={touched.BankName && Boolean(errors.BankName)}
                                                name="BankName" value={values.BankName} validate={validateBankName}
                                            />
                                        </FormControl>
                                    </div>

                                </div>



                                {
                                    contextVariable.OrganizationDetails === "Empty" && <div className='flex justify-content-end gap-2 p-1'>
                                        <div>
                                            <Button outlined className='secondary-outlined-button block w-6rem '
                                                onClick={() => resetFormValues(resetForm)}>
                                                <ClearOutlined className='pb-1'></ClearOutlined>
                                                Clear
                                            </Button>
                                        </div>

                                        <div>
                                            <Button onClick={handleSubmit} type="submit" className='p-ripple text-sm block w-6rem'>
                                                <SaveOutlined className='pb-1'></SaveOutlined>
                                                <span >{" "} Save</span>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </>)}
                    </Formik>}
            </div>

            {
                contextVariable.show === "Confirm" && (
                    <AdminDialogComponent
                        header={t(`Confirmation`)}
                        title={t(`Are you sure you want to save the details?`)}
                        // Note="Note : After saving this details, you won't have option to make any changes."
                        Note={<><strong>{t(`Note`)} :</strong> {t(`After saving this details, you won't have the option to make any changes.`)}</>}
                        ConfirmValues={SubmitedValues}
                    // signout={props.SīgnOut}
                    />
                )
            }

            {
                contextVariable.show === "OrganizationDetailsEdit" && (
                    <AdminDialogComponent header={t(`Edit Inventory Details`)} title="" Note="" />
                )
            }
            {
                contextVariable.show === "BankDetailsEdit" && (
                    <AdminDialogComponent header={t(`Edit Bank Details`)} title="" Note="" />)
            }

            {
                contextVariable.show === "PriceDetailsEdit" && (
                    <AdminDialogComponent header={t(`Edit Price Details`)} title="" Note="" />
                )
            }


            {
                contextVariable.show === "UploadLogo" &&
                <AdminDialogChangeImage
                    header={t(`Logo upload`)}
                    addedLogo={addedLogo}
                />
            }



        </Box>
    )
}
