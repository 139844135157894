import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Layout/Layout';
import { removeFromCartGlobal } from '../AddAndRemoveFunctions';
import MobileSingleProductCart from './MobileSingleProductCart';

export default function CartCard(props) {

    const contextVariable = useContext(AppContext);
    const [CartItems, setCartItems] = useState([]);


    // Restructure fn for  Final price set in CurrentProductprice field and others
    const RestructureItemsDetails = (CartDetails) => {
        // console.log("CartDetails", CartDetails)
        const ResturedCartDetails = CartDetails.map((p) => {
            let priceDiscount = 0;
            if (p.Productdetails.ProductDiscount > 0) {
                priceDiscount = (
                    parseFloat(p.Productdetails.ProductPrice) -
                    parseFloat((Math.abs(parseFloat(p.Productdetails.ProductDiscount)) / 100) * parseFloat(p.Productdetails.ProductPrice))
                )
            }
            else if (p.Productdetails.ProductDiscount === 0) {
                priceDiscount = parseFloat(p.Productdetails.ProductPrice)
            }

            return {
                ...p,
                CurrentProductPrice: priceDiscount,
                CurrentProudctDiscount: p.ProductDiscount > 0 ? p.ProductDiscount : 0,
                ProductImageURL: p.Productdetails.ProductImage,
                CurrentProductCategoryName: p.Productdetails.ProductCategoryName,
                CurrentProductName: p.Productdetails.ProductName,
                ProductPriceCurrencyCode: p.Productdetails.ProductsPrices.items[0].ProductPriceCurrencyCode
            }
        })
        // console.log("ResturedCartDetails", ResturedCartDetails)
        setCartItems(ResturedCartDetails)
    }

    //useEFfect for restructerd the Props Cartproducts 
    useEffect(() => {
        if (props.CartProducts) {
            RestructureItemsDetails(props.CartProducts);
        }
    }, [props.CartProducts])


    const handleCartandGlobalCartquantityChange = (CurrentAmountValue) => {
        // console.log("value", value, productDetails)
        const newCartItems = CartItems.map((item) => {
            if (item.id === CurrentAmountValue.id) {

                props.TotalAmount(CurrentAmountValue);
                return { ...item, ProductQuantity: CurrentAmountValue.ProductQuantity, CalculatedUOMQuantity: CurrentAmountValue.CalculatedUOMQuantity };

            }
            return item;
        });
        const UpdatedWithQty = contextVariable.UserCartItems.map((p) => {
            if (p.id === CurrentAmountValue.id) {
                return { ...p, ProductQuantity: CurrentAmountValue.ProductQuantity, CalculatedUOMQuantity: CurrentAmountValue.CalculatedUOMQuantity }
            }
            else {
                return p
            }
        })
        contextVariable.setUserCartItems(UpdatedWithQty)
        setCartItems(newCartItems);
    };

    const handleRemoveProduct = async (DeleteItem) => {
        // console.log("DeletedItem", DeleteItem)
        const ProductListData = { id: DeleteItem.id, productID: DeleteItem.userProductsCartProductdetailsId, ProductName: DeleteItem.Productdetails.ProductName };
        await removeFromCartGlobal(contextVariable, ProductListData)
        props.removeView(ProductListData);
    }

    const TotalAmountTransfer = (value) => {
        // console.log("Cart Card", value)
        props.TotalAmount(value);
    }


    return (
        <div>
            {
                CartItems.map((CartProduct, index) => (
                    <div className='border-3 border-round-md mt-1 w-100 flex ' key={index}  >
                        <MobileSingleProductCart CartProduct={CartProduct}
                            handleCartandGlobalCartquantityChange={handleCartandGlobalCartquantityChange}
                            TotalAmountTransfer={TotalAmountTransfer} handleRemoveProduct={handleRemoveProduct} />
                    </div>
                ))
            }

        </div>
    )
}
