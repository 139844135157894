import { Outlet } from 'react-router-dom'
import AdminTapBar from '../../../components/Admin/AdminTapBar';

export default function CustomerPortalSetupLayout() {

    return (
        <>

            <AdminTapBar>
            </AdminTapBar>
            {/* <div ref={divRef} style={{ height: "81vh", overflowY: "auto" }}> */}
            <Outlet />
            {/* </div> */}
            </> 
    )
}
