import React, { useContext, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext } from '../Layout/Layout';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../App';
import ConfettiEffect from './ConfettiEffect';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DialogComponent = (props) => {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [displayResponsive, setDisplayResponsive] = useState(contextVariable.show);
    const [user, setUser] = useState(null);
    // const [customState, setCustomState] = useState(null);
    const contextVariableLogin = useContext(LoginContext);
    const navigate = useNavigate();
    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }

    const isMobile = useMediaQuery("(max-width:426px)");

    const signOut = () => {
        if (user) {
            try {
                Auth.signOut();
            }
            catch (error) {
                alert(error);
            }
            contextVariableLogin.setUserGroups("");
            localStorage.clear();
            sessionStorage.clear()
            // localStorage.setItem("isLoggedIn", "false");
            setUser(null);
            if (contextVariable.username === "demouser") {
                navigate('/Register')
            }
            else {
                navigate('/');
            }

        }
    };

    const verifyUserSignout = () => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signOut":
                    setUser(null);
                    break;
                case "customOAuthState":
                    // setCustomState(data);
                    break;
                default:
                    // Handle unexpected events (optional)
                    break;
            }
        });
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                setUser(currentUser);
                signOut();
            }
            )
            .catch((err) => console.error("Not signed in", err));

        return unsubscribe;
    }

    useEffect(() => {
        verifyUserSignout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setShow(false);
    }

    const handleLogout = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setShow(false);
        // localStorage.clear()
        verifyUserSignout();
    }

    const handleClose = (name) => {
        dialogFuncMap[`${name}`](false);
        contextVariable.setShow(false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                {
                    (props.header === t('Logout')) &&
                    <div className='flex justify-content-end'>
                        <Button label={t('No')} icon="pi pi-times" onClick={() => onHide(name)} className="p-button" outlined />
                        <Button label={t('Yes')} icon="pi pi-check" onClick={() => handleLogout(name)} autoFocus />
                    </div>
                }
                {/* {
                    (contextVariable.show === "PaymentSuccess" || contextVariable.show === "PaymentAdvice Updated") &&
                    <>
                        <Button label={t(`Close`)} autoFocus onClick={() => handleClose(name)} />
                    </>
                } */}
            </div>
        );
    }

    return (
        <>
            {(contextVariable.show === "PaymentSuccess" || contextVariable.show === "PaymentAdvice Updated")
                &&
                <ConfettiEffect />
            }

            <Dialog header={props.header} visible={displayResponsive} modal={props.header !== t('Logout') ? false : true}
                onHide={() => onHide('displayResponsive')}
                // breakpoints={{ '320px': '50vw', '960px': '75vw', '1440px': '50vw' }}
                // breakpoints={{ '960px': '75vw' }}
                // breakpoints={{ '641px': '100vw', '960px': '75vw', '1445px': '50vw' }}
                style={{ width: isMobile ? "100%" : "50vw" }}
                // breakpoints={{ 'max-width': '100vw' }}
                // style={{ width: "100%" }}
                footer={renderFooter('displayResponsive')}
            >
                <p className='text-sm md:text-base'>{props.title}</p>
            </Dialog>
        </>
    )
}

export default DialogComponent;