import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from '../CurrencyFormat'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Button } from 'primereact/button'
import { AppContext } from '../../Layout/Layout';
import { Skeleton, useMediaQuery } from '@mui/material';
// import { handleAddToCart, removeFromCartGlobal } from '../AddAndRemoveFunctions';
import { InputText } from 'primereact/inputtext';
import TruncateText from '../TruncateText';
import { updateUserProductsCart } from '../../Layout/MutaionsAndQueries';
import { API } from 'aws-amplify';
import PriceUpdateDialog from '../../Pages/ProductCatalog/PlaceOrder/PriceUpdateDialog';
import { showToast } from '../ToastUtils';
import { ListByProductInventoryDetails } from '../../graphql/queries';
import UOMConversion from '../UOMConversion';
import { useTranslation } from 'react-i18next';

function PriceAndButtonCard({ ProductDetails, part,
    handleCartandGlobalCartquantityChange, handleQuantityBlur, handleInputFocus, handleRemoveProduct, TotalAmountTransfer,
    handleDisplayProductDetails }) {
    const { t } = useTranslation();
    const [ProductStock, setProductStock] = useState("Loading");
    const [StockUom, setStockUom] = useState("");
    const contextVariable = useContext(AppContext);
    const SearchingId = part === "MobilePlaceOrderCart" ? ProductDetails.userProductsCartProductdetailsId : ProductDetails.id;
    useEffect(() => {
        if (contextVariable.taxDetails.InventoryStatus && contextVariable.AllUOMDetails.length > 0) {
            const FetchProductStock = async () => {
                try {
                    const ProductStockDetails = await API.graphql({
                        query: ListByProductInventoryDetails,
                        variables:
                        {
                            MaterialID: SearchingId
                        }
                    })

                    if (ProductStockDetails && ProductStockDetails.data.ListByProductInventoryDetails.SearchResult.length > 0) {
                        const ProductStockCount = Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) > 0 ?
                            Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) : 0;
                        if (contextVariable.UserCartItems.length > 0) {
                            const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === SearchingId);
                            if (FindSameProductStockReduce.length > 0) {
                                const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                                // console.log("TotalStockValue", TotalStockValue)
                                setProductStock(ProductStockCount - TotalStockValue < 0 ? 0 : ProductStockCount - TotalStockValue)
                            }
                            else {
                                setProductStock(ProductStockCount)
                            }

                        }
                        else {
                            setProductStock(ProductStockCount);

                        }
                        const GetUOMText = contextVariable.AllUOMDetails.find(P => P.Value === ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].AvailableStockUOM)
                        setStockUom(GetUOMText.Description);

                    }
                    else {
                        setProductStock(0);
                    }

                } catch (error) {
                    setProductStock(0);
                    console.error("Fetching Individual product stock", error)
                }
            }
            FetchProductStock();
        }
        else if (!contextVariable.taxDetails.InventoryStatus) {
            setProductStock("NoLimit")
        }
        //eslint-disable-next-line
    }, [])

   




    const [ProductDiscountPrice, setProductDiscountPrice] = useState(0);
    useEffect(() => {
        const ProductDiscountedPriceCheck = parseFloat(ProductDetails.ProductPrice) -
            parseFloat((Math.abs(parseFloat(ProductDetails.ProductDiscount)) / 100) * parseFloat(ProductDetails.ProductPrice));
        setProductDiscountPrice(ProductDiscountedPriceCheck)
    }, [ProductDetails.ProductPrice, ProductDetails.ProductDiscount])


    const [UOMCodeQuantity, setUOMCodeQuantity] = useState(1);
    useEffect(() => {
        if (part === "MobilePlaceOrderCart") {

            const FindUOMCodeQuantity = ProductDetails.ProductUOM.find(p => p.CorrespondingQuantityTypeCode === ProductDetails.ProductPriceUOMCode)
            if (FindUOMCodeQuantity) {
                const quantity = parseFloat(FindUOMCodeQuantity.Quantity);
                const formattedQuantity = parseFloat(quantity.toFixed(2));
                setUOMCodeQuantity(formattedQuantity)
            }
            if (ProductDetails.ProductPrice > 0) {
                if (ProductStock === "NoLimit" || ProductStock > 0) {
                    const CurrentAmountValue = {
                        id: ProductDetails.userProductsCartProductdetailsId,
                        ProductQuantity: ProductDetails.ProductQuantity,
                        Update: "QtyUpdate"
                    };
                    TotalAmountTransfer(CurrentAmountValue)
                }
                else if (ProductStock === 0) {
                    const CurrentAmountValue = {
                        id: ProductDetails.userProductsCartProductdetailsId,
                        ProductQuantity: 0,
                        Update: "QtyUpdate"
                    };
                    TotalAmountTransfer(CurrentAmountValue)
                }


            }
            if (ProductStock > 0 || ProductStock === "NoLimit") {
                //Discount =0 Discount=0 Condition
                if (
                    ProductDetails.ProductDiscount === 0 &&
                    ProductDetails.Productdetails.ProductsDiscounts &&
                    ProductDetails.Productdetails.ProductsDiscounts.items.length === 0
                ) {

                    //Discount =0 Discount=0 Condition but Product Price change
                    if (ProductDetails.ProductPrice !== ProductDetails.Productdetails.ProductPrice) {
                        if (onceUpdated) {
                            // contextVariable.setDialogBox(true);
                            setOnceUpdated(false);
                            updatedFunction();
                        }
                    }
                }
                //Discount =0 Discount >0 Added (price equal or Change)
                else if (
                    ProductDetails.ProductDiscount === 0 &&
                    ProductDetails.Productdetails.ProductsDiscounts &&
                    ProductDetails.Productdetails.ProductsDiscounts.items.length > 0
                ) {
                    if (onceUpdated) {
                        // contextVariable.setDialogBox(true);
                        setOnceUpdated(false);
                        updatedFunction()
                    }

                }
                //Discount Available  vs Discount Available part
                else if (
                    Math.abs(parseFloat(ProductDetails.ProductDiscount)) > 0 &&
                    ProductDetails.Productdetails.ProductsDiscounts &&
                    ProductDetails.Productdetails.ProductsDiscounts.items.length > 0
                ) {

                    //Discount >0 and Discount >0 in this case discount Changed
                    if (
                        Math.abs(parseFloat(ProductDetails.ProductDiscount)) !==
                        Math.abs(
                            parseFloat(
                                ProductDetails.Productdetails.ProductsDiscounts.items[0].ProductDiscount
                            )
                        )
                    ) {
                        if (onceUpdated) {
                            // contextVariable.setDialogBox(true);
                            setOnceUpdated(false);
                            updatedFunction();
                        }

                    }
                    // Discount are Equal but Product price Changed
                    else if (
                        Math.abs(parseFloat(ProductDetails.ProductDiscount)) === Math.abs(parseFloat(ProductDetails.Productdetails.ProductsDiscounts.items[0].ProductDiscount))
                        &&
                        ProductDetails.ProductPrice !== ProductDetails.Productdetails.ProductPrice
                    ) {
                        if (onceUpdated) {
                            // contextVariable.setDialogBox(true);
                            setOnceUpdated(false);
                            updatedFunction();
                        }


                    }
                }
                // product Discount Cancelled
                else if (
                    Math.abs(parseFloat(ProductDetails.ProductDiscount)) > 0 &&
                    ProductDetails.Productdetails.ProductsDiscounts &&
                    ProductDetails.Productdetails.ProductsDiscounts.items.length === 0
                ) {
                    if (onceUpdated) {
                        // contextVariable.setDialogBox(true);
                        setOnceUpdated(false);
                        updatedFunction();
                    }

                }
            }

        }
        //eslint-disable-next-line
    }, [ProductStock])


    const mobileS = useMediaQuery("(max-width:321px)");
    const mobileM = useMediaQuery("(max-width:376px)");
    const mobileL = useMediaQuery("(max-width:426px)");

    const [count, setCount] = useState(ProductDetails.ProductQuantity);

    const handleQuantityChange = (event) => {
        const enteredValue = event.target.value.trim();
        let RemainingStock;
        if (
            event.target.value === "" ||
            event.key === "deleteContentForward" ||
            event.key === "deleteContentBackward"
        ) {
            setCount("");
            // handleCartandGlobalCartquantityChange(0, ProductDetails,UOMCodeQuantity)
        }
        else {

            const numericValue = enteredValue.replace(/[^0-9]/g, "");
            const value = Number(numericValue);
            const CheckingProductStock = ProductStock === "NoLimit" ? 9999 : ProductStock;

            if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
                const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === ProductDetails.userProductsCartProductdetailsId);
                console.log("FindSameProductStockReduce", FindSameProductStockReduce)
                console.log("ProductSTock", ProductStock)
                if (FindSameProductStockReduce.length > 0) {
                    const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                    console.log("TotalStockValue", TotalStockValue)
                    RemainingStock = parseInt((CheckingProductStock - TotalStockValue + ProductDetails.CalculatedUOMQuantity) / UOMCodeQuantity) + 1;

                }
                else {
                    RemainingStock = CheckingProductStock;
                }

            }
            else {
                RemainingStock = CheckingProductStock;
            }
            console.log("RemainingStock", RemainingStock)
            const newValue = Math.min(Math.max(value, 1), RemainingStock);
            setCount(newValue);
            const ShowToastUOM = contextVariable.AllUOMDetails.find(P => P.Value === ProductDetails.ProductPriceUOMCode)?.Description || '';
            if (value > RemainingStock) {
                showToast(`${t(`Only`)} ${RemainingStock} ${ShowToastUOM} ${t(`available`)}`, "Info", 2000)
            }

            handleCartandGlobalCartquantityChange(newValue, ProductDetails, UOMCodeQuantity, CheckingProductStock);

        }


    };



    const [onceUpdated, setOnceUpdated] = useState(true);
    const updatedFunction = async () => {
        const updateCart = {
            id: ProductDetails.id,
            ProductDiscount: ProductDetails.Productdetails.ProductDiscount,
            ProductPrice: ProductDetails.Productdetails.ProductPrice
        };
        const updateInput = {
            input: updateCart
        };

        try {
            const updateCartDetails = await API.graphql({
                query: updateUserProductsCart,
                variables: updateInput
            });
            const CurrentAmountValue = {
                id: updateCartDetails.data.updateUserProductsCart.userProductsCartProductdetailsId,
                ProductPrice: updateCartDetails.data.updateUserProductsCart.ProductPrice,
                ProductDiscount: updateCartDetails.data.updateUserProductsCart.ProductDiscount,
                Update: "PriceAndDiscountUpdate"
            };
            TotalAmountTransfer(CurrentAmountValue)
            contextVariable.setDialogBox(true);
        } catch (error) {
            console.error(error);
        }
    };





    return (
        <>
            <PriceUpdateDialog />

            {part === "PriceSection" &&
                <div onClick={() => handleDisplayProductDetails(ProductDetails, ProductStock, StockUom)}>
                    <div className='pl-1 pr-1 -mt-1'>
                        {(ProductDetails.ProductDiscount !== "" && ProductDetails.ProductPrice !== null && ProductDetails.ProductPrice > 0) &&
                            <div>
                                <div className='h-1.5rem flex align-items-center gap-3 text-sm text-gray-600 '>
                                    <div className=''>
                                        <strike>
                                            {getSymbolFromCurrency(
                                                ProductDetails.ProductPriceCurrencyCode
                                            )}
                                            {CurrencyFormat(ProductDetails.ProductPrice)}
                                        </strike>
                                    </div>
                                    <div className='bg-green-100 pr-1 pl-1 border-round-lg '>
                                        <span className='text-gray-700  font-semibold text-sm'>
                                            {Math.abs(parseFloat(ProductDetails.ProductDiscount))}% Off
                                        </span>
                                    </div>
                                </div>
                                <div className='h-1.5rem flex align-items-center gap-2 '>
                                    <div>
                                        <span className=' text-base font-semibold'>
                                            {getSymbolFromCurrency(
                                                ProductDetails.ProductPriceCurrencyCode
                                            )}{" "}
                                            {CurrencyFormat(ProductDiscountPrice)}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='text-gray-400 text-xs pb-1'>
                                            ({parseInt(ProductDetails.ProductPriceQuantity)} {contextVariable.AllUOMDetails.find(P =>
                                                P.Value === ProductDetails.ProductPriceQuantityCode
                                            )?.Description || ''})
                                        </span>
                                    </div>
                                </div>
                            </div>}

                        {(ProductDetails.ProductDiscount === "" && ProductDetails.ProductPrice !== null && ProductDetails.ProductPrice > 0) &&
                            <div>
                                <div className=' text-left'>
                                    <span>
                                        offer not applicable
                                    </span>
                                </div>
                                <div className='h-1.5rem flex align-items-center gap-2 '>
                                    <div>
                                        <span className=' text-base font-semibold'>
                                            {getSymbolFromCurrency(
                                                ProductDetails.ProductPriceCurrencyCode
                                            )} {" "}
                                            {CurrencyFormat(
                                                parseFloat(ProductDetails.ProductPrice)
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='text-gray-400 text-xs pb-1'>
                                            ({parseInt(ProductDetails.ProductPriceQuantity)} {contextVariable.AllUOMDetails.find(P =>
                                                P.Value === ProductDetails.ProductPriceQuantityCode
                                            )?.Description || ''})
                                        </span>
                                    </div>
                                </div>
                            </div>}
                        {
                            (ProductDetails.ProductPrice === null || ProductDetails.ProductPrice <= 0) &&
                            <div className="flex flex-column justify-content-center  align-items-top ">
                                <span className="font-semibold text-sm  md:text-sm border-round-lg py-1 px-3"
                                    style={{ color: "var(--sidebar-color)", }}>
                                    {t(`Unavailable`)}
                                </span>
                            </div>
                        }

                        {
                            (ProductDetails.ProductPrice !== null && ProductDetails.ProductPrice !== 0 && ProductDetails.ProductPrice > 0 && (contextVariable.taxDetails.InventoryStatus && ProductStock > 0)) &&
                            <div className="text-xs font-semibold   border-round-sm text-start">
                                {/* <span className="text-red-700   border-round-3xl pl-1 pr-1" >{t(`Avl.Stock`)}: {ProductStock} <span style={mobileS ? { fontSize: "0.7rem" } : { fontSize: "0.7rem" }}>{StockUom}</span></span> */}
                            </div>
                        }
                    </div>


                    <div>
                        {(ProductDetails.ProductPrice !== null && ProductDetails.ProductPrice >= 0 &&
                            (ProductStock === "NoLimit" || ProductStock > 0)) &&
                            <Button
                                className='w-full    text-center border-none shadow-none  text-sm  CatalogCardButton h-2rem'
                                label='Add to cart'
                                raised
                                style={{ borderRadius: "0px 0px 3px 3px" }}
                                onClick={() => handleDisplayProductDetails(ProductDetails, ProductStock, StockUom)}
                            >
                            </Button>}
                        {
                            (ProductStock === "Loading") &&

                            <div className='w-100'>
                                <Skeleton width="100%" height='2rem'></Skeleton>
                            </div>

                        }
                        {
                            (ProductDetails.ProductPrice !== null && ProductDetails.ProductPrice !== 0 && ProductDetails.ProductPrice > 0 &&
                                (contextVariable.taxDetails.InventoryStatus && ProductStock === 0)) &&
                            <div className="text-xs font-semibold pl-2 pr-2 mt-1 border-round-sm text-center fadein animation-duration-1000 mb-2 ">
                                <span className="text-red-600  border-red-200 p-1 pl-3 pr-3 bg-red-50  border-round-xl " >{t(`Out of Stock`)}</span>
                            </div>
                        }

                    </div>
                </div>}

            {part === "MobilePlaceOrderCart" &&
                <div className='  h-full flex flex-column justify-content-between'>

                    <div className='pl-2 '>
                        <span className='text-sm'>
                            <TruncateText
                                text={ProductDetails.CurrentProductName}
                                maxLength={mobileS ? 17 : mobileM ? 22 : mobileL ? 24 : 29}
                            />
                        </span>
                    </div>

                    <div className='pl-2 text-base  ' style={{ letterSpacing: 0.5 }}>
                        <span> {getSymbolFromCurrency(
                            ProductDetails.ProductPriceCurrencyCode
                        )} {CurrencyFormat(ProductDetails.CurrentProductPrice)}</span>
                    </div>

                    {(ProductStock > 0 || ProductStock === "NoLimit") &&
                        <div className='pl-2  mt-2 flex align-items-center gap-2 '>

                            <InputText className='p-inputtext-sm w-50'
                                value={count}
                                onChange={handleQuantityChange}
                                onBlur={(e) => {
                                    handleQuantityBlur(e.target.value, ProductDetails);
                                    setCount(e.target.value > 0 ? e.target.value : 1)
                                }}
                                onFocus={handleInputFocus}
                            ></InputText>

                            {/* <span>{ProductDetails.ProductQuantityText}</span> */}
                            <UOMConversion UOMCode={ProductDetails.ProductPriceUOMCode} />

                        </div>}

                    {ProductStock === 0 &&
                        <div className='pl-2 pr-2'>
                            <span className="text-red-600 text-xs">{t(`Out of Stock`)}</span></div>}

                    {
                        (ProductStock === "Loading") &&
                        <div className='flex gap-2 pl-2 pr-2'>
                            <Skeleton width="5rem" height='3rem'></Skeleton>
                            <Skeleton width="3rem" height='3rem'></Skeleton> </div>
                    }


                    <div className='pl-2 mt-2   flex justify-content-between align-items-end'>
                        {(ProductStock > 0 || ProductStock === "NoLimit") &&
                            <div>
                                <div>
                                    <span className='text-base'>Amount</span>
                                </div>
                                <div>
                                    <span className='text-base font-semibold'>{getSymbolFromCurrency(
                                        ProductDetails.ProductPriceCurrencyCode
                                    )} {CurrencyFormat(ProductDetails.ProductQuantity * UOMCodeQuantity * ProductDetails.CurrentProductPrice)}</span>
                                </div>

                            </div>}

                        {
                            (ProductStock === "Loading") &&
                            <div className='flex flex-column'>
                                <Skeleton width="5rem" height='1rem'></Skeleton>
                                <Skeleton width="3rem" height='1rem'></Skeleton> </div>
                        }

                        <div className='pr-2 flex justify-content-end  w-100'>
                            <Button className='secondary-button text-base'
                                onClick={() => handleRemoveProduct(ProductDetails)}
                                icon="pi pi-trash" label=""></Button>
                        </div>

                    </div>
                </div>}
        </>
    )
}

export default PriceAndButtonCard