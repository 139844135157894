import React, {  useEffect, useState } from 'react'
import DataViewGridItem from './DataViewGridItem';
import { DataView } from 'primereact/dataview';


function FavouriteDataView(props) {

  const dataViewRef = props.dataViewRef;
  const [FavProductData, setFavProductData] = useState([]);

  useEffect(() => {
    if (props.ProductList) {
      if (props.filteredValue.length > 0) {
        setFavProductData(props.filteredValue);
      }
      else {
        setFavProductData(props.ProductList);
      }

    }
  }, [props])


  //Item Template for using Gird view of Product List details (grid view portion)
  const itemTemplate = (ProductListData, layout) => {
    return (
      <DataViewGridItem ProductListData={ProductListData}
        component={"favourite"}>
      </DataViewGridItem>
    );
  }

  // console.log("FavProductData", FavProductData)
  return (
    <>
      <DataView
        value={FavProductData}
        layout="grid"
        first={props.calculateFirstRow()}
        // rows={60}
        // paginator
        itemTemplate={itemTemplate}
        ref={dataViewRef}
      />

    </>
  )
}

export default FavouriteDataView