/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClientDetails = /* GraphQL */ `
  query GetClientDetails($id: ID!) {
    getClientDetails(id: $id) {
      id
      tenant
      UserName
      Email
      PhoneNumber
      OrganizationName
      Environment
      PortalURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientDetails = /* GraphQL */ `
  query ListClientDetails(
    $filter: ModelClientDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        UserName
        Email
        PhoneNumber
        OrganizationName
        Environment
        PortalURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDemoUsers = /* GraphQL */ `
  query GetDemoUsers($id: ID!) {
    getDemoUsers(id: $id) {
      id
      UserName
      Email
      PhoneNumber
      VerifiedUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDemoUsers = /* GraphQL */ `
  query ListDemoUsers(
    $filter: ModelDemoUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDemoUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserName
        Email
        PhoneNumber
        VerifiedUser
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLambdaLastRunTime = /* GraphQL */ `
  query GetLambdaLastRunTime($id: ID!) {
    getLambdaLastRunTime(id: $id) {
      id
      ProductPrimaryDetails
      ProductPriceDetails
      ContactDetails
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLambdaLastRunTimes = /* GraphQL */ `
  query ListLambdaLastRunTimes(
    $filter: ModelLambdaLastRunTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLambdaLastRunTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductPrimaryDetails
        ProductPriceDetails
        ContactDetails
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductsList = /* GraphQL */ `
  query GetProductsList($id: ID!) {
    getProductsList(id: $id) {
      id
      ProductId
      ProductName
      ProductUOM
      ProductUOMText
      ProductCategoryId
      ProductCategoryName
      ProductDetailDescription
      ProductImage
      ProductStatus
      createdAt
      updatedAt
      tenant
      ProductActiveStatus
      ProductQuantityDetails
      ProductsPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ProductsDiscounts {
        items {
          id
          ProductID
          ProductDiscount
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerSpecificPrices {
        items {
          id
          ProductID
          ProductPrice
          ProductPriceQuantity
          ProductPriceQuantityCode
          ProductPriceCurrencyCode
          ProductPricecurrencyCodeText
          Customer
          CustomerUUID
          productID
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      UserFavouriteProducts {
        items {
          id
          FavouriteStatus
          owner
          CustomerUUID
          productID
          productDetail {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userFavouriteProductsProductDetailId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listProductsLists = /* GraphQL */ `
  query ListProductsLists(
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductsListsByTenant = /* GraphQL */ `
  query ProductsListsByTenant(
    $tenant: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductsListsByTenant(
      tenant: $tenant
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductPrices = /* GraphQL */ `
  query GetProductPrices($id: ID!) {
    getProductPrices(id: $id) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductPrices = /* GraphQL */ `
  query ListProductPrices(
    $filter: ModelProductPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productPricesByProductID = /* GraphQL */ `
  query ProductPricesByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productPricesByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productPricesByTenant = /* GraphQL */ `
  query ProductPricesByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productPricesByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerSpecificPrices = /* GraphQL */ `
  query GetCustomerSpecificPrices($id: ID!) {
    getCustomerSpecificPrices(id: $id) {
      id
      ProductID
      ProductPrice
      ProductPriceQuantity
      ProductPriceQuantityCode
      ProductPriceCurrencyCode
      ProductPricecurrencyCodeText
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerSpecificPrices = /* GraphQL */ `
  query ListCustomerSpecificPrices(
    $filter: ModelCustomerSpecificPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSpecificPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerSpecificPricesByCustomerUUID = /* GraphQL */ `
  query CustomerSpecificPricesByCustomerUUID(
    $CustomerUUID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSpecificPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSpecificPricesByCustomerUUID(
      CustomerUUID: $CustomerUUID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerSpecificPricesByProductID = /* GraphQL */ `
  query CustomerSpecificPricesByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSpecificPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSpecificPricesByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductPrice
        ProductPriceQuantity
        ProductPriceQuantityCode
        ProductPriceCurrencyCode
        ProductPricecurrencyCodeText
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductDiscounts = /* GraphQL */ `
  query GetProductDiscounts($id: ID!) {
    getProductDiscounts(id: $id) {
      id
      ProductID
      ProductDiscount
      Customer
      CustomerUUID
      productID
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductDiscounts = /* GraphQL */ `
  query ListProductDiscounts(
    $filter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductDiscounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductDiscount
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productDiscountsByCustomerUUID = /* GraphQL */ `
  query ProductDiscountsByCustomerUUID(
    $CustomerUUID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productDiscountsByCustomerUUID(
      CustomerUUID: $CustomerUUID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductDiscount
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productDiscountsByProductID = /* GraphQL */ `
  query ProductDiscountsByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductDiscountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productDiscountsByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        ProductDiscount
        Customer
        CustomerUUID
        productID
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProductsCart = /* GraphQL */ `
  query GetUserProductsCart($id: ID!) {
    getUserProductsCart(id: $id) {
      id
      ProductQuantity
      ProductQuantityText
      ProductPriceUOMCode
      CalculatedUOMQuantity
      owner
      ProductPrice
      ProductPriceCurrencyCode
      ProductDiscount
      carttableID
      Productdetails {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userProductsCartProductdetailsId
      __typename
    }
  }
`;
export const listUserProductsCarts = /* GraphQL */ `
  query ListUserProductsCarts(
    $filter: ModelUserProductsCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProductsCarts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductQuantity
        ProductQuantityText
        ProductPriceUOMCode
        CalculatedUOMQuantity
        owner
        ProductPrice
        ProductPriceCurrencyCode
        ProductDiscount
        carttableID
        Productdetails {
          id
          ProductId
          ProductName
          ProductUOM
          ProductUOMText
          ProductCategoryId
          ProductCategoryName
          ProductDetailDescription
          ProductImage
          ProductStatus
          createdAt
          updatedAt
          tenant
          ProductActiveStatus
          ProductQuantityDetails
          ProductsPrices {
            nextToken
            __typename
          }
          ProductsDiscounts {
            nextToken
            __typename
          }
          CustomerSpecificPrices {
            nextToken
            __typename
          }
          UserFavouriteProducts {
            nextToken
            __typename
          }
          __typename
        }
        tenant
        createdAt
        updatedAt
        userProductsCartProductdetailsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userProductsCartsByCarttableID = /* GraphQL */ `
  query UserProductsCartsByCarttableID(
    $carttableID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserProductsCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userProductsCartsByCarttableID(
      carttableID: $carttableID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductQuantity
        ProductQuantityText
        ProductPriceUOMCode
        CalculatedUOMQuantity
        owner
        ProductPrice
        ProductPriceCurrencyCode
        ProductDiscount
        carttableID
        Productdetails {
          id
          ProductId
          ProductName
          ProductUOM
          ProductUOMText
          ProductCategoryId
          ProductCategoryName
          ProductDetailDescription
          ProductImage
          ProductStatus
          createdAt
          updatedAt
          tenant
          ProductActiveStatus
          ProductQuantityDetails
          ProductsPrices {
            nextToken
            __typename
          }
          ProductsDiscounts {
            nextToken
            __typename
          }
          CustomerSpecificPrices {
            nextToken
            __typename
          }
          UserFavouriteProducts {
            nextToken
            __typename
          }
          __typename
        }
        tenant
        createdAt
        updatedAt
        userProductsCartProductdetailsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserCart = /* GraphQL */ `
  query GetUserCart($id: ID!) {
    getUserCart(id: $id) {
      id
      CartId
      CartStatus
      UserAddress
      SalesOrderId
      CreatedBy
      Userproductcarts {
        items {
          id
          ProductQuantity
          ProductQuantityText
          ProductPriceUOMCode
          CalculatedUOMQuantity
          owner
          ProductPrice
          ProductPriceCurrencyCode
          ProductDiscount
          carttableID
          Productdetails {
            id
            ProductId
            ProductName
            ProductUOM
            ProductUOMText
            ProductCategoryId
            ProductCategoryName
            ProductDetailDescription
            ProductImage
            ProductStatus
            createdAt
            updatedAt
            tenant
            ProductActiveStatus
            ProductQuantityDetails
            __typename
          }
          tenant
          createdAt
          updatedAt
          userProductsCartProductdetailsId
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserCarts = /* GraphQL */ `
  query ListUserCarts(
    $filter: ModelUserCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CartId
        CartStatus
        UserAddress
        SalesOrderId
        CreatedBy
        Userproductcarts {
          items {
            id
            ProductQuantity
            ProductQuantityText
            ProductPriceUOMCode
            CalculatedUOMQuantity
            owner
            ProductPrice
            ProductPriceCurrencyCode
            ProductDiscount
            carttableID
            tenant
            createdAt
            updatedAt
            userProductsCartProductdetailsId
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userCartsByCartId = /* GraphQL */ `
  query UserCartsByCartId(
    $CartId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userCartsByCartId(
      CartId: $CartId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CartId
        CartStatus
        UserAddress
        SalesOrderId
        CreatedBy
        Userproductcarts {
          items {
            id
            ProductQuantity
            ProductQuantityText
            ProductPriceUOMCode
            CalculatedUOMQuantity
            owner
            ProductPrice
            ProductPriceCurrencyCode
            ProductDiscount
            carttableID
            tenant
            createdAt
            updatedAt
            userProductsCartProductdetailsId
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserFavouriteProducts = /* GraphQL */ `
  query GetUserFavouriteProducts($id: ID!) {
    getUserFavouriteProducts(id: $id) {
      id
      FavouriteStatus
      owner
      CustomerUUID
      productID
      productDetail {
        id
        ProductId
        ProductName
        ProductUOM
        ProductUOMText
        ProductCategoryId
        ProductCategoryName
        ProductDetailDescription
        ProductImage
        ProductStatus
        createdAt
        updatedAt
        tenant
        ProductActiveStatus
        ProductQuantityDetails
        ProductsPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ProductsDiscounts {
          items {
            id
            ProductID
            ProductDiscount
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerSpecificPrices {
          items {
            id
            ProductID
            ProductPrice
            ProductPriceQuantity
            ProductPriceQuantityCode
            ProductPriceCurrencyCode
            ProductPricecurrencyCodeText
            Customer
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        UserFavouriteProducts {
          items {
            id
            FavouriteStatus
            owner
            CustomerUUID
            productID
            tenant
            createdAt
            updatedAt
            userFavouriteProductsProductDetailId
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      tenant
      createdAt
      updatedAt
      userFavouriteProductsProductDetailId
      __typename
    }
  }
`;
export const listUserFavouriteProducts = /* GraphQL */ `
  query ListUserFavouriteProducts(
    $filter: ModelUserFavouriteProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFavouriteProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FavouriteStatus
        owner
        CustomerUUID
        productID
        productDetail {
          id
          ProductId
          ProductName
          ProductUOM
          ProductUOMText
          ProductCategoryId
          ProductCategoryName
          ProductDetailDescription
          ProductImage
          ProductStatus
          createdAt
          updatedAt
          tenant
          ProductActiveStatus
          ProductQuantityDetails
          ProductsPrices {
            nextToken
            __typename
          }
          ProductsDiscounts {
            nextToken
            __typename
          }
          CustomerSpecificPrices {
            nextToken
            __typename
          }
          UserFavouriteProducts {
            nextToken
            __typename
          }
          __typename
        }
        tenant
        createdAt
        updatedAt
        userFavouriteProductsProductDetailId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFavouriteProductsByOwner = /* GraphQL */ `
  query UserFavouriteProductsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFavouriteProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFavouriteProductsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FavouriteStatus
        owner
        CustomerUUID
        productID
        productDetail {
          id
          ProductId
          ProductName
          ProductUOM
          ProductUOMText
          ProductCategoryId
          ProductCategoryName
          ProductDetailDescription
          ProductImage
          ProductStatus
          createdAt
          updatedAt
          tenant
          ProductActiveStatus
          ProductQuantityDetails
          ProductsPrices {
            nextToken
            __typename
          }
          ProductsDiscounts {
            nextToken
            __typename
          }
          CustomerSpecificPrices {
            nextToken
            __typename
          }
          UserFavouriteProducts {
            nextToken
            __typename
          }
          __typename
        }
        tenant
        createdAt
        updatedAt
        userFavouriteProductsProductDetailId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFavouriteProductsByProductID = /* GraphQL */ `
  query UserFavouriteProductsByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFavouriteProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFavouriteProductsByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FavouriteStatus
        owner
        CustomerUUID
        productID
        productDetail {
          id
          ProductId
          ProductName
          ProductUOM
          ProductUOMText
          ProductCategoryId
          ProductCategoryName
          ProductDetailDescription
          ProductImage
          ProductStatus
          createdAt
          updatedAt
          tenant
          ProductActiveStatus
          ProductQuantityDetails
          ProductsPrices {
            nextToken
            __typename
          }
          ProductsDiscounts {
            nextToken
            __typename
          }
          CustomerSpecificPrices {
            nextToken
            __typename
          }
          UserFavouriteProducts {
            nextToken
            __typename
          }
          __typename
        }
        tenant
        createdAt
        updatedAt
        userFavouriteProductsProductDetailId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganizationDetails = /* GraphQL */ `
  query GetOrganizationDetails($id: ID!) {
    getOrganizationDetails(id: $id) {
      id
      CompanyID
      CompanyName
      CommencementYear
      SetofBooks
      LanguageCode
      SalesOrganizationName
      SalesOrganizationID
      DistributionChannel
      EmployeeResponsibleID
      CurrencyCode
      BankID
      BankName
      SAPCredentialIndicator
      BankDefaultIndicator
      PriceAutoSyncIndicator
      StripeDefaultIndicator
      PriceDefaultIndicator
      PriceListName
      DiscountListName
      SystemID
      Tax
      Logo
      Address
      tenant
      NotificationList
      AdditionalPricingOptions
      SiteID
      InventoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizationDetails = /* GraphQL */ `
  query ListOrganizationDetails(
    $filter: ModelOrganizationDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CompanyID
        CompanyName
        CommencementYear
        SetofBooks
        LanguageCode
        SalesOrganizationName
        SalesOrganizationID
        DistributionChannel
        EmployeeResponsibleID
        CurrencyCode
        BankID
        BankName
        SAPCredentialIndicator
        BankDefaultIndicator
        PriceAutoSyncIndicator
        StripeDefaultIndicator
        PriceDefaultIndicator
        PriceListName
        DiscountListName
        SystemID
        Tax
        Logo
        Address
        tenant
        NotificationList
        AdditionalPricingOptions
        SiteID
        InventoryStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      CustomerId
      MessageText
      NotificationStatus
      createdAt
      NotificationReason
      NotificationReasonCode
      NotificationData
      tenant
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CustomerId
        MessageText
        NotificationStatus
        createdAt
        NotificationReason
        NotificationReasonCode
        NotificationData
        tenant
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationsByCustomerIdAndCreatedAt = /* GraphQL */ `
  query NotificationsByCustomerIdAndCreatedAt(
    $CustomerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByCustomerIdAndCreatedAt(
      CustomerId: $CustomerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CustomerId
        MessageText
        NotificationStatus
        createdAt
        NotificationReason
        NotificationReasonCode
        NotificationData
        tenant
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentHistory = /* GraphQL */ `
  query GetPaymentHistory($id: ID!) {
    getPaymentHistory(id: $id) {
      id
      TransactionReferenceNo
      CustomerId
      ContactId
      DateOfTransaction
      createdAt
      PaymentAmount
      SAPBankPaymentadviceId
      SAPBankPaymentadviceUUID
      SAPBankPaymentadviceStatus
      PaymentStatus
      PaymentStatusCode
      PaymentHistoryDetails
      tenant
      updatedAt
      __typename
    }
  }
`;
export const listPaymentHistories = /* GraphQL */ `
  query ListPaymentHistories(
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TransactionReferenceNo
        CustomerId
        ContactId
        DateOfTransaction
        createdAt
        PaymentAmount
        SAPBankPaymentadviceId
        SAPBankPaymentadviceUUID
        SAPBankPaymentadviceStatus
        PaymentStatus
        PaymentStatusCode
        PaymentHistoryDetails
        tenant
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentHistoriesByCustomerIdAndDateOfTransaction = /* GraphQL */ `
  query PaymentHistoriesByCustomerIdAndDateOfTransaction(
    $CustomerId: String!
    $DateOfTransaction: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentHistoriesByCustomerIdAndDateOfTransaction(
      CustomerId: $CustomerId
      DateOfTransaction: $DateOfTransaction
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        TransactionReferenceNo
        CustomerId
        ContactId
        DateOfTransaction
        createdAt
        PaymentAmount
        SAPBankPaymentadviceId
        SAPBankPaymentadviceUUID
        SAPBankPaymentadviceStatus
        PaymentStatus
        PaymentStatusCode
        PaymentHistoryDetails
        tenant
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImageErrorLog = /* GraphQL */ `
  query GetImageErrorLog($id: ID!) {
    getImageErrorLog(id: $id) {
      id
      type
      DocumentFile
      DocumentDescription
      CreatedBy
      FileSize
      Status
      ImageCount
      FailedImages {
        items {
          id
          ProductImageName
          ErrorMessage
          ImageErrorLogTableId
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImageErrorLogs = /* GraphQL */ `
  query ListImageErrorLogs(
    $filter: ModelImageErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        DocumentFile
        DocumentDescription
        CreatedBy
        FileSize
        Status
        ImageCount
        FailedImages {
          items {
            id
            ProductImageName
            ErrorMessage
            ImageErrorLogTableId
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImageErrorLogDetails = /* GraphQL */ `
  query GetImageErrorLogDetails($id: ID!) {
    getImageErrorLogDetails(id: $id) {
      id
      ProductImageName
      ErrorMessage
      ImageErrorLogTableId
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImageErrorLogDetails = /* GraphQL */ `
  query ListImageErrorLogDetails(
    $filter: ModelImageErrorLogDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageErrorLogDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductImageName
        ErrorMessage
        ImageErrorLogTableId
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const imageErrorLogDetailsByImageErrorLogTableId = /* GraphQL */ `
  query ImageErrorLogDetailsByImageErrorLogTableId(
    $ImageErrorLogTableId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageErrorLogDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imageErrorLogDetailsByImageErrorLogTableId(
      ImageErrorLogTableId: $ImageErrorLogTableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductImageName
        ErrorMessage
        ImageErrorLogTableId
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactInformation = /* GraphQL */ `
  query GetContactInformation($id: ID!) {
    getContactInformation(id: $id) {
      id
      CognitoUserId
      CustomerUUID
      CustomerId
      CustomerName
      ContactUUID
      ContactId
      ContactName
      EmailId
      UpdatedEmailId
      PhoneNumber
      UpdatedPhoneNumber
      SAPStatus
      PortalStatus
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactInformations = /* GraphQL */ `
  query ListContactInformations(
    $filter: ModelContactInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CognitoUserId
        CustomerUUID
        CustomerId
        CustomerName
        ContactUUID
        ContactId
        ContactName
        EmailId
        UpdatedEmailId
        PhoneNumber
        UpdatedPhoneNumber
        SAPStatus
        PortalStatus
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLambdaGrouping = /* GraphQL */ `
  query GetLambdaGrouping($id: ID!) {
    getLambdaGrouping(id: $id) {
      id
      GroupingName
      LambdaFunctions
      tenant
      NotificationID
      NotificationName
      NotificationDescription
      NotificationMandatoryStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLambdaGroupings = /* GraphQL */ `
  query ListLambdaGroupings(
    $filter: ModelLambdaGroupingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLambdaGroupings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        GroupingName
        LambdaFunctions
        tenant
        NotificationID
        NotificationName
        NotificationDescription
        NotificationMandatoryStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantUsers = /* GraphQL */ `
  query GetTenantUsers($id: ID!) {
    getTenantUsers(id: $id) {
      id
      CognitoUserId
      Email
      PhoneNumber
      UserType
      UserName
      createdAt
      updatedAt
      TenantMasters {
        items {
          id
          CognitoUserId
          CompanyId
          CompanyName
          ContactName
          ContactPersonId
          ContactPersonUUID
          CustomerId
          CustomerUUID
          CustomerName
          UserType
          tenant
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTenantUsers = /* GraphQL */ `
  query ListTenantUsers(
    $filter: ModelTenantUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CognitoUserId
        Email
        PhoneNumber
        UserType
        UserName
        createdAt
        updatedAt
        TenantMasters {
          items {
            id
            CognitoUserId
            CompanyId
            CompanyName
            ContactName
            ContactPersonId
            ContactPersonUUID
            CustomerId
            CustomerUUID
            CustomerName
            UserType
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tenantUsersByEmail = /* GraphQL */ `
  query TenantUsersByEmail(
    $Email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByEmail(
      Email: $Email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CognitoUserId
        Email
        PhoneNumber
        UserType
        UserName
        createdAt
        updatedAt
        TenantMasters {
          items {
            id
            CognitoUserId
            CompanyId
            CompanyName
            ContactName
            ContactPersonId
            ContactPersonUUID
            CustomerId
            CustomerUUID
            CustomerName
            UserType
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tenantUsersByPhoneNumber = /* GraphQL */ `
  query TenantUsersByPhoneNumber(
    $PhoneNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByPhoneNumber(
      PhoneNumber: $PhoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CognitoUserId
        Email
        PhoneNumber
        UserType
        UserName
        createdAt
        updatedAt
        TenantMasters {
          items {
            id
            CognitoUserId
            CompanyId
            CompanyName
            ContactName
            ContactPersonId
            ContactPersonUUID
            CustomerId
            CustomerUUID
            CustomerName
            UserType
            tenant
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantMasters = /* GraphQL */ `
  query GetTenantMasters($id: ID!) {
    getTenantMasters(id: $id) {
      id
      CognitoUserId
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      UserType
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTenantMasters = /* GraphQL */ `
  query ListTenantMasters(
    $filter: ModelTenantMastersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CognitoUserId
        CompanyId
        CompanyName
        ContactName
        ContactPersonId
        ContactPersonUUID
        CustomerId
        CustomerUUID
        CustomerName
        UserType
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tenantMastersByCognitoUserId = /* GraphQL */ `
  query TenantMastersByCognitoUserId(
    $CognitoUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantMastersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantMastersByCognitoUserId(
      CognitoUserId: $CognitoUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CognitoUserId
        CompanyId
        CompanyName
        ContactName
        ContactPersonId
        ContactPersonUUID
        CustomerId
        CustomerUUID
        CustomerName
        UserType
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSessionDetails = /* GraphQL */ `
  query GetUserSessionDetails($id: ID!) {
    getUserSessionDetails(id: $id) {
      id
      CognitoUserId
      Email
      PhoneNumber
      CompanyId
      CompanyName
      ContactName
      ContactPersonId
      ContactPersonUUID
      CustomerId
      CustomerUUID
      CustomerName
      tenant
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSessionDetails = /* GraphQL */ `
  query ListUserSessionDetails(
    $filter: ModelUserSessionDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSessionDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CognitoUserId
        Email
        PhoneNumber
        CompanyId
        CompanyName
        ContactName
        ContactPersonId
        ContactPersonUUID
        CustomerId
        CustomerUUID
        CustomerName
        tenant
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListByCustomerCreditLimit = /* GraphQL */ `
  query ListByCustomerCreditLimit($sortField: String, $sortOrder: SortOrder) {
    ListByCustomerCreditLimit(sortField: $sortField, sortOrder: $sortOrder) {
      items {
        customer_UUID
        credit_limit
        currency_code
        customer_accountid
        customer_companyid
        __typename
      }
      count
      __typename
    }
  }
`;
export const ListByCustomerOpenInvoiceCount = /* GraphQL */ `
  query ListByCustomerOpenInvoiceCount(
    $sortField: String
    $sortOrder: SortOrder
  ) {
    ListByCustomerOpenInvoiceCount(
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      items {
        customer_UUID
        openinvoice_limit
        __typename
      }
      count
      __typename
    }
  }
`;
export const ListByCustomerOpenOrderCount = /* GraphQL */ `
  query ListByCustomerOpenOrderCount(
    $sortField: String
    $sortOrder: SortOrder
  ) {
    ListByCustomerOpenOrderCount(sortField: $sortField, sortOrder: $sortOrder) {
      items {
        ObjectID
        UUID
        CustomerID
        Order_id
        Order_Status
        Order_StatusCode
        TotalAmount
        TotalAmount_CurrencyCode
        TotalAmount_CurrencyCodeText
        SalesUnit_PartyID
        __typename
      }
      count
      __typename
    }
  }
`;
export const ListByCustomerSalesOrderDetails = /* GraphQL */ `
  query ListByCustomerSalesOrderDetails(
    $year: String!
    $limit: Int!
    $skip: Int!
    $sortOrder: String!
    $sortField: String!
  ) {
    ListByCustomerSalesOrderDetails(
      year: $year
      limit: $limit
      skip: $skip
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      items {
        ObjectID
        UUID
        OrderID
        OrderStatus
        OrderStatusCode
        ExternalReference
        DeliveryStatus
        DeliveryStatusCode
        InvoiceStatus
        InvoiceStatusCode
        GrossAmount
        TotalAmount
        GrossAmountCurrencyCode
        NetAmount
        NetAmountCurrencyCode
        TaxAmount
        TaxAmountCurrencyCode
        PostingDate
        Incoterms
        IncotermsCode
        IncotermsLocation
        CreatedDate
        CustomerID
        PDFUUID
        SalesOrderItem {
          ObjectID
          LineItem
          ProductID
          ProductDescription
          __typename
        }
        __typename
      }
      count
      year
      __typename
    }
  }
`;
export const GetByCustomerSalesOrderDetail = /* GraphQL */ `
  query GetByCustomerSalesOrderDetail($OrderID: ID!) {
    GetByCustomerSalesOrderDetail(OrderID: $OrderID) {
      items {
        ObjectID
        UUID
        OrderID
        RequestedDate
        OrderStatus
        OrderStatusCode
        ExternalReference
        DeliveryStatus
        DeliveryStatusCode
        InvoiceStatus
        InvoiceStatusCode
        GrossAmount
        GrossAmountCurrencyCode
        NetAmount
        NetAmountCurrencyCode
        TaxAmount
        TaxAmountCurrencyCode
        GrossAmountIndicator
        PostingDate
        Incoterms
        IncotermsCode
        IncotermsLocation
        CreatedDate
        CustomerID
        CustomerName
        CustomerAddress
        ContactPersonID
        ContactPersonName
        PaymentTerm
        PaymentTermText
        Note
        PDFUUID
        ProcessingTypeCode
        SalesOrderItem {
          ObjectID
          LineItem
          ProductID
          ProductDescription
          PriceQuantity
          PriceQuantityUOM
          PriceQuantityUOMText
          Quantity
          QuantityUOM
          QuantityUOMText
          UnitPrice
          UnitPriceCode
          UnitPriceCodeText
          NetPrice
          NetPriceCode
          NetPriceCodeText
          NetValue
          NetValueUnitCode
          NetValueUnitCodeText
          DeliveryDate
          Discount
          TaxRate
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;
export const GetSalesOrderPDF = /* GraphQL */ `
  query GetSalesOrderPDF($PDFUUID: ID!) {
    GetSalesOrderPDF(PDFUUID: $PDFUUID) {
      items {
        DocumentUUID
        OutputPDF
        DocumentName
        PDFStatus
        __typename
      }
      __typename
    }
  }
`;
export const ListByCustomerAddressList = /* GraphQL */ `
  query ListByCustomerAddressList($sortField: String, $sortOrder: SortOrder) {
    ListByCustomerAddressList(sortField: $sortField, sortOrder: $sortOrder) {
      items {
        RootObjectID
        RootUUID
        AccountID
        AccountName
        LifeCycleStatusCode
        LifeCycleStatusCodeText
        CustomerAddressInformation {
          CustomerAddressInformation_ObjectID
          CustomerAddressInformation_UUID
          AddressSnapshot_ObjectID
          ConventionalPhone
          Email
          MobilePhone
          PostalAddress_ObjectID
          HouseID
          StreetName
          CityName
          StreetPostalCode
          CountryCode
          CountryCodeText
          StreetPrefixName
          AdditionalStreetPrefixName
          StreetSuffixName
          AdditionalStreetSuffixName
          DistrictName
          RegionCode
          RegionCodeText
          FormattedAddress_ObjectID
          FormattedAddress
          DefaultAddress
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;
export const ListByCustomerOutboundDeliveryDetails = /* GraphQL */ `
  query ListByCustomerOutboundDeliveryDetails(
    $year: String!
    $limit: Int!
    $skip: Int!
    $sortOrder: String!
    $sortField: String!
  ) {
    ListByCustomerOutboundDeliveryDetails(
      year: $year
      limit: $limit
      skip: $skip
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      items {
        ObjectID
        UUID
        DeliveryID
        DeliveryDate
        ReleaseStatus
        ReleaseStatusText
        TrackingID
        TrackingURI
        FreightForwarder
        FreightForwarderID
        GrossWeight
        GrossWeightUnitCode
        GrossWeightUnitCodeText
        ShipmentDate
        PDFUUID
        OutboundDeliveryItem {
          ObjectID
          LineItem
          ProductID
          SalesOrderID
          __typename
        }
        __typename
      }
      count
      year
      __typename
    }
  }
`;
export const GetByCustomerOutboundDeliveryDetail = /* GraphQL */ `
  query GetByCustomerOutboundDeliveryDetail($DeliveryID: ID!) {
    GetByCustomerOutboundDeliveryDetail(DeliveryID: $DeliveryID) {
      items {
        ObjectID
        UUID
        CustomerName
        CustomerID
        ReleaseStatus
        ReleaseStatusText
        TrackingID
        TrackingURI
        FreightForwarderID
        FreightForwarder
        ShippingAddress
        Incoterms
        IncotermsText
        IncotermsLocation
        GrossWeight
        GrossWeightUnitCode
        GrossWeightUnitCodeText
        PDFUUID
        OutboundDeliveryItem {
          ItemObjectID
          LineItem
          ProductID
          ProductDescription
          Quantity
          QuantityUOM
          QuantityUOMText
          DeliveryDate
          BatchID
          OrderNO
          SalesOrderID
          SalesOrderItemID
          ExternalReferenceNO
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;
export const GetDeliveryPDF = /* GraphQL */ `
  query GetDeliveryPDF($PDFUUID: ID!) {
    GetDeliveryPDF(PDFUUID: $PDFUUID) {
      items {
        DocumentUUID
        OutputPDF
        DocumentName
        PDFStatus
        __typename
      }
      __typename
    }
  }
`;
export const ListByCustomerInvoiceDetails = /* GraphQL */ `
  query ListByCustomerInvoiceDetails(
    $year: String!
    $limit: Int!
    $skip: Int!
    $sortOrder: String!
    $sortField: String!
  ) {
    ListByCustomerInvoiceDetails(
      year: $year
      limit: $limit
      skip: $skip
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      count
      items {
        ExternalReference
        InvoiceID
        InvoiceDate
        NetAmount
        GrossAmount
        PaymentStatusCode
        TotalAmount
        PaymentDueDate
        PaymentStatus
        OpenAmount
        __typename
      }
      year
      __typename
    }
  }
`;
export const GetInvoicePDF = /* GraphQL */ `
  query GetInvoicePDF($InvoiceID: ID!) {
    GetInvoicePDF(InvoiceID: $InvoiceID) {
      items {
        DocumentUUID
        OutputPDF
        DocumentName
        PDFStatus
        __typename
      }
      __typename
    }
  }
`;
export const GetByCustomerInvoiceDetail = /* GraphQL */ `
  query GetByCustomerInvoiceDetail($InvoiceID: ID!) {
    GetByCustomerInvoiceDetail(InvoiceID: $InvoiceID) {
      count
      items {
        ObjectID
        ExternalReference
        InvoiceID
        CustomerID
        CustomerName
        CustomerAddress
        PaymentTerms
        PaymentTermsText
        Incoterms
        ProcessingTypeCode
        ReleaseStatusCode
        ReleaseStatusCodeText
        IncotermsText
        GrossAmountIndicator
        Notes
        PDFUUID
        CustomerInvoiceItem {
          LineItem
          ObjectID
          ProductID
          ProductDescription
          PriceQuantity
          PriceQuantityUOM
          PriceQuantityUOMText
          Quantity
          QuantityUOM
          QuantityUOMText
          UnitPrice
          UnitPriceUnitCode
          UnitPriceUnitCodeText
          TaxRate
          Discount
          NetPrice
          NetPriceCode
          NetPriceCodeText
          NetValue
          NetValueUnitCode
          NetValueUnitCodeText
          SalesOrderReference
          POReference
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const ListByCustomerCreditNotes = /* GraphQL */ `
  query ListByCustomerCreditNotes(
    $year: String!
    $limit: Int!
    $skip: Int!
    $sortOrder: String!
    $sortField: String!
  ) {
    ListByCustomerCreditNotes(
      year: $year
      limit: $limit
      skip: $skip
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      count
      items {
        ObjectID
        ExternalReferenceID
        CreditNoteID
        CreditNoteDate
        NetAmount
        NetAmountCurrencyCode
        NetAmountCurrencyCodeText
        GrossAmount
        GrossAmountCurrencyCode
        GrossAmountCurrencyCodeText
        TaxAmount
        TaxAmountCurrencyCode
        TaxAmountCurrencyCodeText
        ReleaseStatusCode
        ReleaseStatusCodeText
        PaymentDueDate
        PaymentStatus
        ProcessingTypeCode
        GrossAmountIndicator
        PDFUUID
        __typename
      }
      year
      __typename
    }
  }
`;
export const GetByCustomerCreditNotes = /* GraphQL */ `
  query GetByCustomerCreditNotes($InvoiceID: ID!) {
    GetByCustomerCreditNotes(InvoiceID: $InvoiceID) {
      count
      items {
        ObjectID
        ExternalReference
        CreditID
        CustomerID
        CustomerName
        CustomerAddress
        PaymentTerms
        PaymentTermsText
        Incoterms
        IncotermsText
        GrossAmountIndicator
        ReleaseStatusCode
        ReleaseStatusCodeText
        Notes
        NetAmount
        GrossAmount
        GrossAmountCurrencyCode
        GrossAmountCurrencyCodeText
        PDFUUID
        CustomerCreditNotesItem {
          LineItem
          ObjectID
          ProductID
          ProductDescription
          PriceQuantity
          PriceQuantityUOM
          PriceQuantityUOMText
          Quantity
          QuantityUOM
          QuantityUOMText
          UnitPrice
          UnitPriceUnitCode
          UnitPriceUnitCodeText
          TaxRate
          Discount
          NetPrice
          NetPriceCode
          NetPriceCodeText
          NetValue
          NetValueUnitCode
          NetValueUnitCodeText
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const GetCreditNotePDF = /* GraphQL */ `
  query GetCreditNotePDF($PDFUUID: ID!) {
    GetCreditNotePDF(PDFUUID: $PDFUUID) {
      items {
        DocumentUUID
        OutputPDF
        DocumentName
        PDFStatus
        __typename
      }
      __typename
    }
  }
`;
export const ListByCustomerStatementOfAccounts = /* GraphQL */ `
  query ListByCustomerStatementOfAccounts(
    $year: String!
    $sortOrder: String!
    $sortField: String!
  ) {
    ListByCustomerStatementOfAccounts(
      year: $year
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      items {
        Date
        Transaction
        ExternalReference
        DueDate
        Amount
        Payment
        Balance
        Status
        StatusCode
        __typename
      }
      count
      OpeningBalance
      InvoiceAmount
      PaymentAmount
      ClosedBalance
      BuyerID
      SellerID
      SellerAddress
      BuyerName
      __typename
    }
  }
`;
export const ListByProductListandUserCount = /* GraphQL */ `
  query ListByProductListandUserCount {
    ListByProductListandUserCount {
      ProductListCount
      UserListCount
      __typename
    }
  }
`;
export const ListByAPIErrorlogs = /* GraphQL */ `
  query ListByAPIErrorlogs(
    $StartDateandTime: String!
    $EndDateandTime: String!
    $GroupName: String
    $GroupingName: [String!]
    $CustomerId: String!
  ) {
    ListByAPIErrorlogs(
      StartDateandTime: $StartDateandTime
      EndDateandTime: $EndDateandTime
      GroupName: $GroupName
      GroupingName: $GroupingName
      CustomerId: $CustomerId
    ) {
      APIName
      ErrorDescription
      DateandTime
      __typename
    }
  }
`;
export const SearchByProductList = /* GraphQL */ `
  query SearchByProductList(
    $q: String!
    $tenant: String!
    $from: String
    $size: String!
  ) {
    SearchByProductList(q: $q, tenant: $tenant, from: $from, size: $size) {
      SearchResult {
        ProductName
        ProductImage
        ProductUOMText
        ProductCategoryName
        ProductId
        ProductUOM
        ProductUUID
        ProductDetailDescription
        ProductsDiscounts
        ProductsPrices
        ProductCategoryId
        id
        tenant
        ProductActiveStatus
        ProductStatus
        ProductQuantityDetails
        __typename
      }
      TotalValue
      __typename
    }
  }
`;
export const ListByProductInventoryDetails = /* GraphQL */ `
  query ListByProductInventoryDetails($MaterialID: String) {
    ListByProductInventoryDetails(MaterialID: $MaterialID) {
      SearchResult {
        MaterialID
        AvailableStock
        AvailableStockUOM
        CalculatedAvailableStock
        __typename
      }
      __typename
    }
  }
`;
export const GetByPriceCSVDownload = /* GraphQL */ `
  query GetByPriceCSVDownload {
    GetByPriceCSVDownload {
      base64
      __typename
    }
  }
`;
export const GetByDiscountCSVDownload = /* GraphQL */ `
  query GetByDiscountCSVDownload {
    GetByDiscountCSVDownload {
      base64
      __typename
    }
  }
`;
export const GetByBasePriceCSVDownload = /* GraphQL */ `
  query GetByBasePriceCSVDownload {
    GetByBasePriceCSVDownload {
      base64
      __typename
    }
  }
`;
export const GetUOMCodeList = /* GraphQL */ `
  query GetUOMCodeList {
    GetUOMCodeList {
      count
      items {
        Value
        Description
        __typename
      }
      __typename
    }
  }
`;
export const ListByCustomerSalesBlockDetails = /* GraphQL */ `
  query ListByCustomerSalesBlockDetails(
    $sortField: String
    $sortOrder: SortOrder
  ) {
    ListByCustomerSalesBlockDetails(
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      items {
        SaleOrderBlockedIndicator
        DeliveryBlockedIndicator
        InvoiceBlockedIndicator
        __typename
      }
      count
      __typename
    }
  }
`;
export const StripeWebhook = /* GraphQL */ `
  query StripeWebhook(
    $amount: String!
    $currency: String!
    $tenant: String!
    $tableId: String!
  ) {
    StripeWebhook(
      amount: $amount
      currency: $currency
      tenant: $tenant
      tableId: $tableId
    ) {
      PaymentIntent
      PublishableKey
      __typename
    }
  }
`;
export const GetStripeDetails = /* GraphQL */ `
  query GetStripeDetails {
    GetStripeDetails {
      PublishableKey
      __typename
    }
  }
`;
export const GetInvoiceClearingStatus = /* GraphQL */ `
  query GetInvoiceClearingStatus($input: [InvoiceClearingStatusInput]) {
    GetInvoiceClearingStatus(input: $input) {
      count
      items {
        InvoiceID
        ClearingID
        __typename
      }
      __typename
    }
  }
`;
