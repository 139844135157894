import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from '../CurrencyFormat';
import getSymbolFromCurrency from 'currency-symbol-map';
import UOMConversion from '../UOMConversion';
import { InputText } from 'primereact/inputtext';
import { useMediaQuery } from '@mui/material';
import TruncateText from '../TruncateText';
import Authorizedimages from './Authorizedimages';
import { AppContext } from '../../Layout/Layout';
import { API } from 'aws-amplify';
import { ListByProductInventoryDetails } from '../../graphql/queries';
import { updateUserProductsCart } from '../../Layout/MutaionsAndQueries';
import { showToast } from '../ToastUtils';
import { useTranslation } from 'react-i18next';

function MobileSingleProductCart({ CartProduct, handleCartandGlobalCartquantityChange, TotalAmountTransfer, handleRemoveProduct }) {
    const { t } = useTranslation();
    const contextVariable = useContext(AppContext);
    const [count, setCount] = useState(CartProduct.ProductQuantity);

    const [ProductStock, setProductStock] = useState("Loading");
    const [UOMCodeQuantity, setUOMCodeQuantity] = useState(1);


    //UseEffect for Fetch the Product current stock from  redis db
    useEffect(() => {
        if (contextVariable.taxDetails.InventoryStatus && contextVariable.AllUOMDetails.length > 0) {
            const FetchProductStock = async () => {
                try {
                    const ProductStockDetails = await API.graphql({
                        query: ListByProductInventoryDetails,
                        variables:
                        {
                            MaterialID: CartProduct.Productdetails.id
                        }
                    })
                    // console.log("productStockdetails", ProductStockDetails)
                    if (ProductStockDetails && ProductStockDetails.data.ListByProductInventoryDetails.SearchResult.length > 0) {
                        const ProductStockCount = Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) > 0 ?
                            Number(ProductStockDetails.data.ListByProductInventoryDetails.SearchResult[0].CalculatedAvailableStock) : 0;
                        if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
                            const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === CartProduct.userProductsCartProductdetailsId);
                            if (FindSameProductStockReduce.length > 0) {
                                const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                                if (TotalStockValue > ProductStockCount) {
                                    setProductStock(0)
                                }
                                else {
                                    setProductStock(ProductStockCount);
                                }
                            }
                            else {
                                setProductStock(ProductStockCount);
                            }
                        }
                    }
                    else {
                        setProductStock(0);
                    }

                } catch (error) {
                    console.error("Fetching Individual product stock", error)
                }
            }
            FetchProductStock();
        }
        else if (!contextVariable.taxDetails.InventoryStatus) {
            setProductStock("NoLimit")
        }
    //eslint-disable-next-line
    }, [contextVariable.taxDetails.InventoryStatus, contextVariable.AllUOMDetails, CartProduct.Productdetails.id])

    //UseEffect for Find the Cart product uom quantity and add the UOMCodeQuantity field in the Cart product (local use)
    useEffect(() => {
        const FindUOMCodeQuantity = CartProduct.ProductUOM.find(p => p.CorrespondingQuantityTypeCode === CartProduct.ProductPriceUOMCode)
        if (FindUOMCodeQuantity) {
            const quantity = parseFloat(FindUOMCodeQuantity.Quantity);
            const formattedQuantity = parseFloat(quantity.toFixed(2));
            setUOMCodeQuantity(formattedQuantity)
        }
        if (CartProduct.ProductPrice > 0) {
            if (ProductStock === "NoLimit" || ProductStock > 0) {
                const CurrentAmountValue = {
                    id: CartProduct.id,
                    ProductQuantity: CartProduct.ProductQuantity,
                    CalculatedUOMQuantity: UOMCodeQuantity * CartProduct.ProductQuantity,
                    UOMCodeQuantity: UOMCodeQuantity,
                    ProductStock: ProductStock,
                    Update: "QtyUpdate",
                };
                TotalAmountTransfer(CurrentAmountValue)
            }
            else if (ProductStock === 0) {
                const CurrentAmountValue = {
                    id: CartProduct.id,
                    ProductQuantity: 0,
                    CalculatedUOMQuantity: UOMCodeQuantity * CartProduct.ProductQuantity,
                    UOMCodeQuantity: UOMCodeQuantity,
                    ProductStock: ProductStock,
                    Update: "QtyUpdate",
                };
                TotalAmountTransfer(CurrentAmountValue)
            }


        }
        //eslint-disable-next-line
    }, [ProductStock, UOMCodeQuantity])

    // UseEffect for Product price or product discount changes related update functions
    useEffect(() => {

        if (ProductStock > 0 || ProductStock === "NoLimit") {
            //Discount =0 Discount=0 Condition
            if (
                CartProduct.ProductDiscount === 0 &&
                CartProduct.Productdetails.ProductsDiscounts &&
                CartProduct.Productdetails.ProductsDiscounts.items.length === 0
            ) {

                //Discount =0 Discount=0 Condition but Product Price change
                if (CartProduct.ProductPrice !== CartProduct.Productdetails.ProductPrice) {
                    if (onceUpdated) {
                        // contextVariable.setDialogBox(true);
                        setOnceUpdated(false);
                        updatedFunction();
                    }
                }
            }
            //Discount =0 Discount >0 Added (price equal or Change)
            else if (
                CartProduct.ProductDiscount === 0 &&
                CartProduct.Productdetails.ProductsDiscounts &&
                CartProduct.Productdetails.ProductsDiscounts.items.length > 0
            ) {
                if (onceUpdated) {
                    // contextVariable.setDialogBox(true);
                    setOnceUpdated(false);
                    updatedFunction()
                }

            }
            //Discount Available  vs Discount Available part
            else if (
                Math.abs(parseFloat(CartProduct.ProductDiscount)) > 0 &&
                CartProduct.Productdetails.ProductsDiscounts &&
                CartProduct.Productdetails.ProductsDiscounts.items.length > 0
            ) {

                //Discount >0 and Discount >0 in this case discount Changed
                if (
                    Math.abs(parseFloat(CartProduct.ProductDiscount)) !==
                    Math.abs(
                        parseFloat(
                            CartProduct.Productdetails.ProductsDiscounts.items[0].ProductDiscount
                        )
                    )
                ) {
                    if (onceUpdated) {
                        // contextVariable.setDialogBox(true);
                        setOnceUpdated(false);
                        updatedFunction();
                    }

                }
                // Discount are Equal but Product price Changed
                else if (
                    Math.abs(parseFloat(CartProduct.ProductDiscount)) === Math.abs(parseFloat(CartProduct.Productdetails.ProductsDiscounts.items[0].ProductDiscount))
                    &&
                    CartProduct.ProductPrice !== CartProduct.Productdetails.ProductPrice
                ) {
                    if (onceUpdated) {
                        // contextVariable.setDialogBox(true);
                        setOnceUpdated(false);
                        updatedFunction();
                    }


                }
            }
            // product Discount Cancelled
            else if (
                Math.abs(parseFloat(CartProduct.ProductDiscount)) > 0 &&
                CartProduct.Productdetails.ProductsDiscounts &&
                CartProduct.Productdetails.ProductsDiscounts.items.length === 0
            ) {
                if (onceUpdated) {
                    // contextVariable.setDialogBox(true);
                    setOnceUpdated(false);
                    updatedFunction();
                }

            }
        }


        //eslint-disable-next-line
    }, [ProductStock])



    const mobileS = useMediaQuery("(max-width:321px)");
    const mobileM = useMediaQuery("(max-width:376px)");
    const mobileL = useMediaQuery("(max-width:426px)");

    const [onceUpdated, setOnceUpdated] = useState(true);
    const updatedFunction = async () => {
        const updateCart = {
            id: CartProduct.id,
            ProductDiscount: CartProduct.Productdetails.ProductDiscount,
            ProductPrice: CartProduct.Productdetails.ProductPrice
        };
        const updateInput = {
            input: updateCart
        };

        try {
            const updateCartDetails = await API.graphql({
                query: updateUserProductsCart,
                variables: updateInput
            });
            const CurrentAmountValue = {
                id: updateCartDetails.data.updateUserProductsCart.userProductsCartProductdetailsId,
                ProductPrice: updateCartDetails.data.updateUserProductsCart.ProductPrice,
                ProductDiscount: updateCartDetails.data.updateUserProductsCart.ProductDiscount,
                Update: "PriceAndDiscountUpdate"
            };
            TotalAmountTransfer(CurrentAmountValue)
            contextVariable.setDialogBox(true);
        } catch (error) {
            console.error(error);
        }
    };


    const handleQuantityChange = (event) => {
        const enteredValue = event.target.value.trim();
        let RemainingStock;
        if (
            event.target.value === "" ||
            event.key === "deleteContentForward" ||
            event.key === "deleteContentBackward"
        ) {
            setCount("");
        }
        else {

            const numericValue = enteredValue.replace(/[^0-9]/g, "");
            const value = Number(numericValue);
            const CheckingProductStock = ProductStock === "NoLimit" ? 9999 : ProductStock;

            if (contextVariable.UserCartItems.length > 0 && ProductStock !== "NoLimit") {
                const FindSameProductStockReduce = contextVariable.UserCartItems.filter(p => p.userProductsCartProductdetailsId === CartProduct.userProductsCartProductdetailsId);
                if (FindSameProductStockReduce.length > 0) {
                    const TotalStockValue = FindSameProductStockReduce.reduce((a, b) => a + b.CalculatedUOMQuantity, 0);
                    RemainingStock = parseInt((CheckingProductStock - TotalStockValue + CartProduct.CalculatedUOMQuantity) / UOMCodeQuantity);
                }
                else {
                    RemainingStock = CheckingProductStock;
                }

            }
            else {
                RemainingStock = CheckingProductStock;
            }
            const newValue = Math.min(Math.max(value, 1), RemainingStock);
            setCount(newValue);
            const ShowToastUOM = contextVariable.AllUOMDetails.find(P => P.Value === CartProduct.ProductPriceUOMCode)?.Description || '';
            if (value > RemainingStock) {
                showToast(`${t('Only')} ${RemainingStock} ${ShowToastUOM} ${t(`available`)}`, "Info", 2000)
            }
            const CurrentAmountValue = {
                id: CartProduct.id,
                ProductQuantity: newValue,
                UOMCodeQuantity: UOMCodeQuantity,
                CalculatedUOMQuantity: newValue * UOMCodeQuantity,
                ProductStock: CheckingProductStock,
                Update: "QtyUpdate"
            };
            handleCartandGlobalCartquantityChange(CurrentAmountValue);

        }


    };
    const handleInputBlur = (event) => {
        if (event.target.value === "") {
            setCount(1);
            const CurrentAmountValue = {
                id: CartProduct.id,
                ProductQuantity: 1,
                UOMCodeQuantity: UOMCodeQuantity,
                CalculatedUOMQuantity: 1 * UOMCodeQuantity,
                ProductStock: ProductStock,
                Update: "QtyUpdate"
            };
            handleCartandGlobalCartquantityChange(CurrentAmountValue);
        }
        handleSave();

    }
    const handleSave = async () => {
        const savedValue = count === "" ? 1 : count;
        const CurrentAmountValue = {
            id: CartProduct.id,
            ProductQuantity: savedValue,
            CalculatedUOMQuantity: UOMCodeQuantity * savedValue
        };
        try {
            await API.graphql({
                query: updateUserProductsCart,
                variables: {
                    input: CurrentAmountValue,
                },
            });

        }
        catch (error) {
            console.error("Saving Quantity Error", error.data);

        };
    }
    return (
        <>
            <div className='w-50 border-right-1'>
                <div className="text-white border-round-bottom" style={{ background: 'var(--sidebar-color)' }}>
                    <i className='ml-1 pi pi-tag' style={{ fontSize: '0.7rem' }}></i>
                    <span className='text-xs'>{" "}{CartProduct.CurrentProductCategoryName}</span>
                </div>
                <div className='flex justify-content-center pt-4 pb-4'>
                    <Authorizedimages ProductImage={CartProduct.ProductImageURL} From="MobileCard" />
                </div>
            </div>

            <div className='p-1  w-50 '>
                <div className='  h-full flex flex-column justify-content-between'>

                    <div className='pl-2 '>
                        <span className='text-sm'>
                            <TruncateText
                                text={CartProduct.CurrentProductName}
                                maxLength={mobileS ? 17 : mobileM ? 22 : mobileL ? 24 : 29}
                            />
                        </span>
                    </div>

                    <div className='pl-2 text-base  ' style={{ letterSpacing: 0.5 }}>
                        <span> {getSymbolFromCurrency(
                            CartProduct.ProductPriceCurrencyCode
                        )} {CurrencyFormat(CartProduct.CurrentProductPrice)}</span>
                    </div>

                    {(ProductStock > 0 || ProductStock === "NoLimit") &&
                        <div className='pl-2  mt-2 flex align-items-center gap-2 '>

                            <InputText className='p-inputtext-sm w-50'
                                value={count}
                                onChange={handleQuantityChange}
                                onBlur={handleInputBlur}
                            // onFocus={handleInputFocus}
                            ></InputText>

                            {/* <span>{CartProducts.ProductQuantityText}</span> */}
                            <UOMConversion UOMCode={CartProduct.ProductPriceUOMCode} />

                        </div>}

                    {ProductStock === 0 &&
                        <div className='pl-2 pr-2'>
                            <span className="text-red-600 text-xs">{t(`Out of Stock`)}</span></div>}

                    {
                        (ProductStock === "Loading") &&
                        <div className='flex gap-2 pl-2 pr-2'>
                            <Skeleton width="5rem" height='3rem'></Skeleton>
                            <Skeleton width="3rem" height='3rem'></Skeleton> </div>
                    }


                    <div className='pl-2 mt-2   flex justify-content-between align-items-end'>
                        {(ProductStock > 0 || ProductStock === "NoLimit") &&
                            <div>
                                <div>
                                    <span className='text-base'>Amount</span>
                                </div>
                                <div>
                                    <span className='text-base font-semibold'>{getSymbolFromCurrency(
                                        CartProduct.ProductPriceCurrencyCode
                                    )} {CurrencyFormat(CartProduct.ProductQuantity * CartProduct.UOMCodeQuantity * CartProduct.CurrentProductPrice)}</span>
                                </div>

                            </div>}

                        {
                            (ProductStock === "Loading") &&
                            <div className='flex flex-column'>
                                <Skeleton width="5rem" height='1rem'></Skeleton>
                                <Skeleton width="3rem" height='1rem'></Skeleton> </div>
                        }

                        <div className='pr-2 flex justify-content-end  w-100'>
                            <Button className='secondary-button text-base'
                                onClick={() => handleRemoveProduct(CartProduct)}
                                icon="pi pi-trash" label=""></Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileSingleProductCart