import React, { useContext, useEffect, useState } from 'react'
import './footer.css';
import { Menu, Notifications, PieChart, ShoppingCart, Widgets } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import FooterMenu from '../../components/FooterMenu';
import { AppContext } from '../../Layout/Layout';
import { Badge } from '@mui/material';
import { showToast } from '../../components/ToastUtils';
import { handleShowNotification } from '../../components/Notification/NotificationComponent';
import { CreateNotify } from '../../Layout/MutaionsAndQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const [selectedIconIndex, setSelectedIconIndex] = useState(null);
    const [showSidebarMenu, setShowSidebarMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const contextVariable = useContext(AppContext);

    useEffect(() => {
        let prevScrollPos = window.scrollY;

        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrolledDown = prevScrollPos < currentScrollPos;

            setIsFooterVisible(!isScrolledDown);
            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleIconClick = (index) => {
        setSelectedIconIndex(index);
        sessionStorage.setItem('selectedIconIndex', index);
        switch (index) {
            case 1:
                navigate('/');
                break;
            case 2:
                navigate('/productCatalog');
                break;
            case 3:
                navigate('/productCatalog/placeOrder');
                break;
            case 4:
                navigate('/notifications');
                break;
            case 5:
                setShowSidebarMenu(!showSidebarMenu);
                break;
            default:
                // No navigation for other indexes
                break;
        }
    };

    useEffect(() => {
        const storedIndex = sessionStorage.getItem('selectedIconIndex');
        if (!storedIndex) {
            setSelectedIconIndex(1);
        }
        else {
            // console.log("storedIndex", storedIndex)
            const path = location.pathname;
            let index;
            switch (path) {
                case '/':
                    index = 1;
                    sessionStorage.setItem('selectedIconIndex', index);
                    break;

                case '/productCatalog':
                    index = 2;
                    sessionStorage.setItem('selectedIconIndex', index);
                    break;

                case '/productCatalog/placeOrder':
                    index = 3;
                    sessionStorage.setItem('selectedIconIndex', index);
                    break;

                case '/notifications':
                    index = 4;
                    sessionStorage.setItem('selectedIconIndex', index);
                    break;

                case 5:
                    setShowSidebarMenu(!showSidebarMenu);
                    break;

                default:
                    index = 5;
                    sessionStorage.setItem('selectedIconIndex', 5);

                    break;
            }
            setSelectedIconIndex(index);
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    // Notification subscription
    const sendMessageToNative = async (message) => {
        const NotifyObject = {
            Message: "NotificationMsg",
            data: message
        }

        // console.log("message", NotifyObject);
        if (window.ReactNativeWebView) {
            await window.ReactNativeWebView.postMessage(JSON.stringify(NotifyObject));
        } else {
            console.log("window.ReactNativeWebView is not available. Cannot send message to native app.");
        }
    }

    // Notification
    useEffect(() => {
        // getLogo();
        const filter = {
            filter: {
                CustomerId: { eq: contextVariable.userDetails["custom:contactperson_UUID"] },
                NotificationStatus: { eq: 'Unread' }
            }
        }

        const subscription = API.graphql(
            graphqlOperation(CreateNotify, filter)
        ).subscribe({
            next: ({ value }) => {
                const CreatedNotification = value.data.onCreateNotification;
                const SplitnotificationArray = JSON.parse(CreatedNotification.NotificationData);
                const updatedData = [CreatedNotification, ...contextVariable.NotificationMsg];
                // Desktop notification
                handleShowNotification(CreatedNotification);

                // Webview data transfer call
                sendMessageToNative(JSON.stringify(CreatedNotification));

                // console.log("createNofication", CreatedNotification)
                if (CreatedNotification.NotificationReasonCode === "SO_C") {
                    if (SplitnotificationArray && SplitnotificationArray[0] && SplitnotificationArray[0].PlaceOrderStatus === "Ordered") {
                        showToast(`${t(`Your order request submitted with Order ID:`)} ${SplitnotificationArray[0].SalesOrderId}`, "success")
                        contextVariable.setNotificationMsg(updatedData);

                        //Same user only clear Usercarddetails otherwise no one  clear
                        // if ( SplitnotificationArray[0].UserCartTableID === contextVariable.UserCartIdDetails[0].id) {
                            contextVariable.setUserCartIdDetails([]);
                            contextVariable.setUserCartItems([]);
                            contextVariable.setCartCount(0);
                            contextVariable.setCartItemsIds([]);
                            contextVariable.setCartStatusView("OrderRequestPlaced");
                            contextVariable.setSalesorderIdwithPlaced(SplitnotificationArray[0].SalesOrderId)
                        //   }
                    }
                }
                else if (CreatedNotification.NotificationReasonCode === "PS_S") {
                    contextVariable.setShow("PaymentSuccess");
                    contextVariable.setNotificationMsg(updatedData);
                }
                else if (CreatedNotification.NotificationReasonCode === "BPA_C") {
                    contextVariable.setShow("PaymentAdvice Updated");
                    contextVariable.setNotificationMsg(updatedData);
                }
                else if (CreatedNotification.NotificationReasonCode === "BPA_F") {
                    contextVariable.setNotificationMsg(updatedData);
                }
                else if (CreatedNotification.NotificationReasonCode === "SO_F") {
                
                    showToast(`${t(CreatedNotification.NotificationReasonCode)}`, "error")
                    contextVariable.setNotificationMsg(updatedData);
                    contextVariable.setUserCartIdDetails((prev) => prev.map((P) => {
                        return { ...P, CartStatus: "Open", PlaceOrderStatus: "Open" }
                      }))
                      contextVariable.setCartStatusView("ProductsInTheCart");
                      contextVariable.setCartCount(contextVariable.UserCartItems.length);
                }
                else {
                    contextVariable.setNotificationMsg(updatedData);
                }
            },
            error: (error) => {
                console.error('Subscription Error:', error);
            },
        });

        return () => {
            subscription.unsubscribe();
        };

    });

    // console.log("contextvarible", contextVariable)

    const handleSidebarClose = () => {
        setShowSidebarMenu(!showSidebarMenu);
    }

    // console.log("selectedicon", selectedIconIndex)
    return (
        <>
            <footer className={`footer-container ${isFooterVisible ? 'visible' : 'hidden'}`}>
                <div className="footer-icons">
                    {/* Map over the icons and render them */}
                    {[1, 2, 3, 4, 5].map((index) => {
                        let IconComponent;
                        let iconName;
                        switch (index) {
                            case 1:
                                IconComponent = PieChart;
                                iconName = 'dashboard';
                                break;
                            case 2:
                                IconComponent = Widgets;
                                iconName = 'catalogue';
                                break;
                            case 3:
                                IconComponent = ShoppingCart;
                                iconName = 'cart';
                                break;
                            case 4:
                                IconComponent = Notifications;
                                iconName = 'notifications';
                                break;
                            case 5:
                                IconComponent = Menu;
                                iconName = 'Menu';
                                break;
                            default:
                                IconComponent = null;
                                iconName = '';
                        }
                        if (index === 3) {
                            return (

                                <div
                                    key={index}
                                    className='flex flex-column mt-1 justify-content-center align-items-center'
                                    onClick={() => handleIconClick(index)}
                                >
                                    <Badge key={index} badgeContent={contextVariable.CartCount} color="primary">
                                        <div className={`${selectedIconIndex === index ? 'selected' : ''}`}>
                                            <IconComponent />
                                        </div>
                                    </Badge>

                                    <div className={`${selectedIconIndex === index ? 'sidebar-color' : ''}`}>
                                        {iconName}
                                    </div>
                                </div>
                            );
                        }
                        else if (index === 4) {
                            return (

                                <div
                                    key={index}
                                    className='flex flex-column mt-1 justify-content-center align-items-center'
                                    onClick={() => handleIconClick(index)}
                                >
                                    <Badge key={index} badgeContent={contextVariable.NotificationMsg.length} color="primary">
                                        <div className={`${selectedIconIndex === index ? 'selected' : ''}`}>
                                            <IconComponent />
                                        </div>
                                    </Badge>

                                    <div className={`${selectedIconIndex === index ? 'sidebar-color' : ''}`}>
                                        {iconName}
                                    </div>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div
                                    key={index}
                                    className='flex flex-column justify-content-center align-items-center'
                                    onClick={() => handleIconClick(index)}
                                >
                                    <div className={`${selectedIconIndex === index ? 'selected' : ''}`}>
                                        <IconComponent />
                                    </div>

                                    <div className={`${selectedIconIndex === index ? 'sidebar-color' : ''}`}>
                                        {iconName}
                                    </div>
                                </div>
                            );
                        }

                    })}
                </div>
                {/* Sidebar menu */}
                {
                    showSidebarMenu &&
                    <FooterMenu
                        openSidebar={true}
                        closeSidebar={handleSidebarClose}
                    />
                }
            </footer>
        </>
    )
}

export default Footer