import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material'
import FormComponent from './FormComponent';
import GridData from '../../components/GridData';
import { API } from 'aws-amplify';
import { AppContext } from '../../Layout/Layout';
import { showToast } from '../../components/ToastUtils';
import { ListByCustomerStatementOfAccounts } from '../../graphql/queries';
import CurrencyFormat from '../../components/CurrencyFormat';
import { useFade } from '../../components/FadeContext/FadeContext';
import CardTopbar from '../../components/cards/CardTopbar';
import { Dropdown } from 'primereact/dropdown';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import getSymbolFromCurrency from 'currency-symbol-map';
import './StatementOfAccount.css';
import SkeletonLoader from '../../components/SkeletonLoader';
import { Skeleton } from 'primereact/skeleton';
import { SimCardDownload } from '@mui/icons-material';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import DataNotFound from '../../components/cards/dataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';

const StatementOfAccount = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [pdfview, setpdfView] = useState(false);
  const [CustomerStatementsOfAccountsDetails, setCustomerStatementsOfAccountsDetails] = useState(1);
  const [FormComponentValue, setFormComponentValue] = useState({
    InvoiceAmount: 0,
    ClosedBalance: 0,
    PaymentAmount: 0,
    OpeningBalance: 0,
    BuyerID: '',
    SellerID: '',
    SellerAddress: '',
    BuyerName: ''
  });
  const isMobile = contextVariable.isMobile;
  const [disableDate, setDisableDate] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const dates = [
    { label: String(currentYear), year: currentYear },
    { label: String(currentYear - 1), year: currentYear - 1 },
    { label: String(currentYear - 2), year: currentYear - 2 },
    { label: String(currentYear - 3), year: currentYear - 3 },
    { label: String(currentYear - 4), year: currentYear - 4 },
    { label: String(currentYear - 5), year: currentYear - 5 },
    { label: String(currentYear - 6), year: currentYear - 6 },
    { label: String(currentYear - 7), year: currentYear - 7 },
    { label: String(currentYear - 8), year: currentYear - 8 },
    { label: String(currentYear - 9), year: currentYear - 9 },
    { label: String(currentYear - 10), year: currentYear - 10 },
    { label: String(currentYear - 11), year: currentYear - 11 },
    { label: String(currentYear - 12), year: currentYear - 12 },
    { label: String(currentYear - 13), year: currentYear - 13 },
    { label: String(currentYear - 14), year: currentYear - 14 },
    { label: String(currentYear - 15), year: currentYear - 15 }
  ];

  // const [searchQuery, setSearchQuery] = useState('');
  const [itemArray, setItemArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const location = useLocation();

  const handleViewPdf = () => {
    setpdfView(true);
  };

  const columnDefs = [
    {
      field: "Date",
      headerName: t("Date"),
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header'
    },
    {
      field: "Transaction",
      headerName: t("Transaction"),
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header'
    },
    // { field: "Details", headerName: t("Details"), filter: true, flex: 1, headerClass: 'custom-ag-header' },
    {
      field: "Amount",
      headerName: `${t("Transaction Amount")} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    {
      field: "DueDate",
      headerName: t("Due Date / Payment Date"),
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header'
    },
    {
      field: "ExternalReference",
      headerName: t("Reference Number"),
      filter: true,
      flex: 1,
      headerClass: 'custom-ag-header'
    },
  ];

  // const closePDFPreview = () => {
  //   setpdfView(false);
  // }

  sessionStorage.setItem("title", "Account Statement");

  const fetchCustomerStatementsOfAccounts = async () => {
    setDisableDate(true);
    setLoading(true);
    setCustomerStatementsOfAccountsDetails(1);

    contextVariable.setCallDownloadPDF({
      isDataLoaded: false,
      executeDownloadPDFFunction: false
    });

    try {
      const CustomerStatementOfAccountsResponse = await API.graphql({
        query: ListByCustomerStatementOfAccounts,
        variables: {
          year: contextVariable.date,
          sortField: "Date",
          sortOrder: "ASC"
        },
      });
      if (CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.count === 0) {
        setCustomerStatementsOfAccountsDetails(0);
      }
      else {
        const ResponseData = CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.items;
        // console.log("ResponseData", ResponseData);
        setItemArray(ResponseData);
        const formattedData = ResponseData.map(item => {
          const parsedDate = new Date(item.Date);
          const parsedDueDate = new Date(item.DueDate);

          const formattedDate = parsedDate.toLocaleDateString('en-GB'); // Adjust the locale as needed
          const formattedDueDate = parsedDueDate.toLocaleDateString('en-GB');
          return {
            ...item,
            Date: formattedDate,
            DueDate: formattedDueDate
          };
        });
        setCustomerStatementsOfAccountsDetails(ResponseData);
      }
      contextVariable.setCallDownloadPDF({
        isDataLoaded: true
      });

      if (CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.OpeningBalance !== null) {
        setFormComponentValue({
          InvoiceAmount: parseFloat(CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.InvoiceAmount),
          ClosedBalance: parseFloat(CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.ClosedBalance),
          PaymentAmount: parseFloat(CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.PaymentAmount),
          OpeningBalance: parseFloat((CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.OpeningBalance).replace(/,/g, '').split(' ')[0]),
          BuyerID: CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.BuyerID,
          SellerID: CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.SellerID,
          SellerAddress: CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.SellerAddress,
          BuyerName: CustomerStatementOfAccountsResponse.data.ListByCustomerStatementOfAccounts.BuyerName
        })
      }

      setLoading(false);
      setDisableDate(false);
    }
    catch (error) {
      setLoading(false);
      setDisableDate(false);
      setIsError(error.errors[0].message);
      setFormComponentValue({ SellerID: "0" })
      setCustomerStatementsOfAccountsDetails(0);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchSalesorderDetails Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }

      // showToast(`Error in fetching account statement : ${error.errors[0].message}`, "error");
      console.error(`Error in fetching account statement : ${error.errors[0].message}`);
    }

  };

  useEffect(() => {
    fetchCustomerStatementsOfAccounts();
    // eslint-disable-next-line 
  }, [contextVariable.date]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    // eslint-disable-next-line 
  }, [location.pathname])

  const selectedRowsHandler = (rows) => {
  }
  useEffect(() => {
    setTimeout(() => {
      showToast(t(`Please don't refresh the page. Your data is being extracted from SAP, might takes 11-15 seconds.`), "info", 10000);
    }, 3000);
    // eslint-disable-next-line 
  }, [])

  const { fadeIn } = useFade();

  const [dateRange, setDateRange] = useState('');
  const year = contextVariable.date;

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();

    if (year === currentYear) {
      const start = new Date(year, 0, 1);
      setDateRange(`${formatDate(start)} to ${formatDate(today)}`);
    } else {
      const start = new Date(year, 0, 1);
      const end = new Date(year, 11, 31);
      setDateRange(`${formatDate(start)} to ${formatDate(end)}`);
    }
  }, [year]);

  const handleDateChange = (e) => {
    sessionStorage.setItem("date", e.value.year);
    setSelectedDate(e.value.year);
    // contextVariable.setGlobalData(null);
    contextVariable.setGlobalSearch(contextVariable.globalSearch)
    // contextVariable.setFilteredData([]);
    contextVariable.setIsFiltered(false);
    contextVariable.setDate(e.value.year);
  };

  const handleDownloadPDF = () => {
    contextVariable.setCallDownloadPDF({
      isDataLoaded: true,
      executeDownloadPDFFunction: true
    });
  }

  return (
    <>
      {
        !isMobile &&
        <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
          {/* Form Container */}
          {!pdfview && <div>
            <div>
              <div>
                <div>
                  {/* Form */}
                  <FormComponent
                    handleViewPdf={handleViewPdf}
                    FormComponentValue={FormComponentValue}
                    CustomerStatementsOfAccountsDetails={CustomerStatementsOfAccountsDetails}
                  />
                </div>
              </div>
            </div>

            {/* Datagrid */}
            <div className='mt-2'>
              <GridData
                rowData={CustomerStatementsOfAccountsDetails}
                columnDefs={columnDefs}
                component={'Account Statement'}
                filter={'accountStatement'}
                selectedRows={selectedRowsHandler}
                tableHeight={'54vh'}
              />
            </div>
          </div>
          }
        </Box>
      }

      {
        isMobile &&
        <div style={{ backgroundColor: "#ededed", fontFamily: "helvetica" }}>

          {/* Header */}
          {/* Topbar */}
          <div className='sticky top-0 z-3'>
            <CardTopbar
              header={`Account Statement`}
              buttonNeeded={false}
              refresh={fetchCustomerStatementsOfAccounts}
              loading={loading}
            />
          </div>
          {/* <div className='fixed-navbar'>
            <div className='mt-4 p-1'>
              <span className='text-base'>Account Statement</span>
            </div>
          </div> */}

          {/* Body */}
          <div className='overflow-hidden'>
            <div className='m-2'>

              {/* Date */}
              <div className='card p-1 mb-2 border-round-md'>
                <div className='flex justify-content-around align-items-center'>
                  <div>
                    <span className='text-sm font-normal'>{t('Year of Transactions')} :</span>
                  </div>
                  <div>
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 mt-1 w-full mobile-dropdown border-none"
                    />
                  </div>
                </div>
              </div>

              {/* Account summary */}
              {
                (loading && isError === null) ?
                  <>
                    <div className='card shadow-1 p-2 ml-2 mr-2'>
                      <Skeleton height="8rem" />
                    </div>
                  </>
                  :
                  (!loading && isError === null) ?
                    <>
                      <div className='mb-2'>

                        {/* Backup */}
                        {/* <Accordion activeIndex={0}>
                          <AccordionTab header="Account Summary">
                            <div className='pl-4'>
                              <div className='grid'>
                                <div className='col-6 value-color'>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>Opening Balance</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>Invoice Amount</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>Credit/Payment Amount</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>Balance Amount</span>
                                    <span>:</span>
                                  </div>
                                </div>
                                <div className='col-6 sidebar-colorflex  justify-content-start text-right pr-5'>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(FormComponentValue.OpeningBalance)).toFixed(2)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(FormComponentValue.InvoiceAmount)).toFixed(2)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(FormComponentValue.PaymentAmount)).toFixed(2)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {(parseFloat(FormComponentValue.ClosedBalance)).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className='mt-2 text-xs flex justify-content-around align-items-center'>
                              <span className='value-color'>Statement Period :</span>
                              <span className='sidebar-color'>{dateRange}</span>
                            </div>
                          </AccordionTab>
                        </Accordion> */}

                        <Accordion className='card shadow-1'>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            {t('Account Summary')}
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className='pl-4'>
                              <div className='grid'>
                                <div className='col-6 value-color'>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>{t('Opening Balance')}</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>{t('Invoice Amount')}</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>{t('Credit/Payment Amount')}</span>
                                    <span>:</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-baseline'>
                                    <span className='text-xs'>{t('Balance Amount')}</span>
                                    <span>:</span>
                                  </div>
                                </div>
                                <div className='col-6 sidebar-colorflex  justify-content-start text-right pr-5'>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(FormComponentValue.OpeningBalance)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(FormComponentValue.InvoiceAmount)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(FormComponentValue.PaymentAmount)}</span>
                                  </div>
                                  <div>
                                    <span className='text-xs'>
                                      {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(FormComponentValue.ClosedBalance)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/* Statement period */}
                            <div className='mt-2 text-xs flex justify-content-around align-items-center'>
                              <span className='value-color'>{t('Statement Period')} :</span>
                              <span className='sidebar-color'>{dateRange}</span>
                            </div>
                          </AccordionDetails>
                        </Accordion>

                      </div>
                    </>
                    :
                    ''
              }

              {/* Items */}
              {
                (itemArray.length !== 0 && !loading && isError === null) &&
                <>
                  <div className='mb-2 pl-1 pr-1 flex justify-content-between align-items-center sidebar-color'>
                    <div>
                      <span className='font-semibold text-base'>{t('Items')}</span>
                    </div>

                    {
                      (contextVariable.callDownloadPDF.isDataLoaded) ?
                        <>
                          {/* For downloading PDF */}
                          <FormComponent
                            handleViewPdf={handleViewPdf}
                            FormComponentValue={FormComponentValue}
                            CustomerStatementsOfAccountsDetails={CustomerStatementsOfAccountsDetails}
                          />
                          <div>
                            <Button
                              color='sidebar-color'
                              outlined
                              severity='success'
                              label={<span style={{ color: "var(--sidebar-color)" }}>PDF</span>}
                              icon={<SimCardDownload style={{ color: "#ff6c6c" }} />}
                              onClick={handleDownloadPDF}
                            />
                          </div>
                        </>
                        :
                        <>
                          <div></div>
                        </>
                    }
                  </div>
                  {
                    itemArray
                      .map((item, index) => (
                        <div key={index}
                          className={`card p-2 mb-2 ${item.Transaction === 'Payment' ? 'soa-border-payment' :
                            item.Transaction === 'Credit Memo' ? 'soa-border-credit-memo' : 'soa-border-invoice'}`}
                          style={{ fontFamily: "helvetica", marginTop: "0" }}>
                          <div className='text-xs flex justify-content-between align-items-center'>
                            {/* Reference number */}
                            <div className='flex gap-3 align-items-center pb-2'>
                              <span className='value-color'>Ref No :</span>
                              <span className='sidebar-color'>{item.ExternalReference}</span>
                            </div>
                            {/* Status */}
                            <div className='mb-2 text-xs'>
                              {
                                item.Transaction === 'Payment' ?
                                  <span style={{ color: "#00B321" }}>{item.Transaction}</span>
                                  :
                                  item.Transaction === 'Credit Memo' ?
                                    <span style={{ color: "#ff7b00" }}>{item.Transaction}</span>
                                    :
                                    <span style={{ color: "#007bff" }}>{item.Transaction}</span>
                              }
                            </div>
                          </div>
                          <div className='flex justify-content-between align-items-center text-right'>
                            {/* Created date and due date */}
                            <div>
                              <span className='value-color'>{item.Date}</span>
                            </div>
                            <div>
                              <span className='value-color'>Due: {item.DueDate}</span>
                            </div>
                            {/* Amount */}
                            <div className='text-sm'>
                              <span className='sidebar-color'>
                                {getSymbolFromCurrency(contextVariable.taxDetails.CurrencyCode)} {CurrencyFormat(Math.abs(item.Amount))}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                  }
                </>
              }

              {/* Loading */}
              {
                loading === true &&
                <>
                  <div className="flex flex-column">
                    {
                      [...Array(6)].map((_, index) => (
                        <SkeletonLoader key={index} />
                      ))
                    }
                  </div>
                </>
              }

              {/* Error */}
              {
                isError !== null &&
                <>
                  {/* <div style={{ marginTop: "10rem" }}>
                    <MobileErrorComponent
                      errorMessage={isError}
                    />
                  </div> */}
                  <DataNotFound />
                </>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default StatementOfAccount;