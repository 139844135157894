import React, { useContext } from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import GridData from "../../components/GridData";
import { Button } from "primereact/button";
import { NavigateBeforeOutlined, NavigateNextOutlined } from "@mui/icons-material";
import { AppContext } from "../../Layout/Layout";
import { showToast } from "../../components/ToastUtils";
import { API } from "aws-amplify";
import GetOrderPDF from "../order/GetOrderPDF";
import Loader from "../../components/Loader";
import CurrencyFormat from "../../components/CurrencyFormat";
import { useFade } from "../../components/FadeContext/FadeContext";
import { useTranslation } from "react-i18next";
import { GetByCustomerCreditNotes } from "../../graphql/queries";

const CreditNoteView = (props) => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [rowData, setRowData] = useState(1);
  const [generalDetails, setGeneralDetails] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [GrossAmountIndicator, setGrossAmountIndicator] = useState("true");

  // Net order type
  const columnDefsNetOrderType = [
    {
      field: "LineItem",
      headerName: t("Line Item"),
      filter: true,
      flex: 0.1,
      headerClass: 'custom-ag-header'
    },

    { field: "ProductID", headerName: t("Product ID"), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "ProductDescription", headerName: t("Description"), filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: "ProductDescription", },
    {
      field: "Quantity", headerName: t("Qty"), filter: true, flex: 0.1, headerClass: 'custom-ag-header', cellRenderer: (params) => {
        return ((parseFloat(params.value)).toFixed(0));
      }
    },
    { field: "QuantityUOMText", headerName: t("Qty UOM"), filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
    {
      field: "UnitPrice", headerName: `${t('Unit Price')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true,
      flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "PriceQuantity", headerName: t("Price Qty / UOM"), filter: true, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "Discount",
      headerName: `${t('Discount')} (%)`,
      filter: true,
      flex: 0.3,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value !== '') {
          return (Math.abs(parseFloat(params.data.Discount)));
        } else {
          return '-';
        }
      }
    },
    {
      field: "NetPrice", headerName: `${t('Net Price')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    { field: "TaxRate", headerName: `${t("Tax Amount")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "NetValue", headerName: `${t("Net Value")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
  ];

  // Gross order type
  const columnDefsGrossOrderType = [
    {
      field: "LineItem",
      headerName: t("Line Item"),
      filter: true,
      flex: 0.1,
      headerClass: 'custom-ag-header'
    },

    { field: "ProductID", headerName: t("Product ID"), filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    { field: "ProductDescription", headerName: t("Description"), filter: true, flex: 0.3, headerClass: 'custom-ag-header', tooltipField: "ProductDescription", },
    {
      field: "Quantity", headerName: t("Qty"), filter: true, flex: 0.1, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        return ((parseFloat(params.value)).toFixed(0));
      }
    },
    { field: "QuantityUOMText", headerName: t("Qty UOM"), filter: true, flex: 0.1, headerClass: 'custom-ag-header' },
    {
      field: "UnitPrice", headerName: `${t('Unit Price')} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3,
      headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "PriceQuantity", headerName: t('Price Qty / UOM'), filter: true, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue) + ' / ' + params.data.PriceQuantityUOM;
        }
        else {
          return '-';
        }
      }
    },
    {
      field: "Discount",
      headerName: `${t('Discount')} (%)`,
      filter: true,
      flex: 0.3,
      headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value !== '') {
          return (Math.abs(parseFloat(params.data.Discount).toFixed(2)));
        } else {
          return '-';
        }
      }
    },
    {
      field: "NetPrice", headerName: `${t('Gross Price')} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true, flex: 0.3, headerClass: 'custom-ag-header', cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
    { field: "TaxRate", headerName: `${t("Tax Amount")} (${contextVariable.taxDetails.CurrencyCode})`, filter: true, flex: 0.3, headerClass: 'custom-ag-header' },
    {
      field: "NetValue",
      headerName: `${t("Gross Value")} (${contextVariable.taxDetails.CurrencyCode})`,
      filter: true,
      flex: 0.3,
      headerClass: 'custom-ag-header',
      cellStyle: { textAlign: 'right' },
      cellRenderer: (params) => {
        if (params.value) {
          const formattedValue = (parseFloat(params.value)).toFixed(2);
          return CurrencyFormat(formattedValue);
        } else {
          return '-';
        }
      }
    },
  ];

  const fetchCreditNoteItemDetails = async () => {
    setGeneralDetails(1);
    setRowData(1);
    try {
      const response = await API.graphql({
        query: GetByCustomerCreditNotes,
        variables: {
          InvoiceID: contextVariable.rootObject.CreditNoteID
        }
      });
      setGeneralDetails(response.data.GetByCustomerCreditNotes.items[0]);
      setRowData(response.data.GetByCustomerCreditNotes.items[0].CustomerCreditNotesItem);
      if (response.data.GetByCustomerCreditNotes.items[0].GrossAmountIndicator === 'false') {
        setGrossAmountIndicator("false");
      }
      else {
        setGrossAmountIndicator("true");
      }
    }
    catch (error) {
      console.error("fetchCreditNoteItemDetails", error);
      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchCreditNoteItemDetails Error", error);
        // showToast(`fetchCreditNoteItemDetails Error : ${error.errors[0].message}`, 'error', 3000);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  }

  const fetchCreditNoteItemDetails1 = async (invoiceId) => {
    setGeneralDetails(1);
    setRowData(1);
    try {
      const response = await API.graphql({
        query: GetByCustomerCreditNotes,
        variables: {
          InvoiceID: invoiceId
        }
      });
      setGeneralDetails(response.data.GetByCustomerCreditNotes.items[0]);
      setRowData(response.data.GetByCustomerCreditNotes.items[0].CustomerCreditNotesItem);
      if (response.data.GetByCustomerCreditNotes.items[0].GrossAmountIndicator === 'false') {
        setGrossAmountIndicator("false");
      }
      else {
        setGrossAmountIndicator("true");
      }
    }
    catch (error) {
      console.error("fetchCreditNoteItemDetails", error);
      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchCreditNoteItemDetails Error", error);
        // showToast(`fetchCreditNoteItemDetails Error : ${error.errors[0].message}`, 'error', 3000);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  }

  const BeforeDeliveryItem = () => {
    if (currentIndex > 0) {
      contextVariable.setRootObject(contextVariable.filteredData[currentIndex - 1]);
      fetchCreditNoteItemDetails1(contextVariable.filteredData[currentIndex - 1].CreditNoteID);
      setCurrentIndex(currentIndex - 1)
    }
    else {
      showToast(t(`End of records.`), 'info');
    }
  }

  const nextDeliveryItem = () => {
    if (currentIndex < contextVariable.filteredData.length - 1) {
      contextVariable.setRootObject(contextVariable.filteredData[currentIndex + 1]);
      fetchCreditNoteItemDetails1(contextVariable.filteredData[currentIndex + 1].CreditNoteID);
      setCurrentIndex(currentIndex + 1)
    }
    else {
      showToast(t(`End of records.`), 'info');
    }
  }

  useEffect(() => {
    fetchCreditNoteItemDetails();
    // console.log("FIRST", props.creditNoteDetails);
    // console.log("ROOT", contextVariable.rootObject);

    if (contextVariable.filteredData.length === 0) {
      const initialIndex = props.creditNoteDetails.findIndex(item => item.CreditNoteID === contextVariable.rootObject.CreditNoteID);
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
    } else {
      const initialIndex = contextVariable.filteredData.findIndex(item => item.CreditNoteID === contextVariable.rootObject.CreditNoteID);
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
    }
    //eslint-disable-next-line
  }, []);

  const showPdf = () => {
    setLoading(true);
    GetOrderPDF("creditNote", contextVariable.rootObject.PDFUUID, contextVariable.rootObject.CreditNoteID)
      .then(() => {
        setLoading(false);
      });
  }

  const goToCreditNote = () => {
    props.backToCreditNote();
  }

  const selectedRowsHandler = (rows) => {
  }

  const { fadeIn } = useFade();

  return (
    <>
      <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
        {sessionStorage.setItem("title", `Details of Credit Note ID : ${contextVariable.rootObject.CreditNoteID}`)}
        <div className="d-flex justify-content-between">
          <div>

            <Button
              className="secondary-button p-button-sm  mb-2"
              onClick={goToCreditNote}
              label={t('Back to credit note list')}
              icon="pi pi-arrow-left"
            >
            </Button>
            {
              (contextVariable.rootObject.PDFUUID).trim() !== '' ?
                <>
                  <Button
                    className="primary-button p-button-sm mb-2 ml-2"
                    loading={loading}
                    onClick={showPdf}
                    icon="pi pi-file-pdf"
                    label="View PDF"
                  >
                  </Button>
                </> : ''
            }
          </div>
        </div>

        {/* general Details */}
        <div className="headerDetails card-shadow bg-white">
          {
            rowData.length > 0 ?
              <>
                <div className="grid">
                  <div className="col-6 md:col-4 lg:col-2 flex flex-column ">
                    <label className="text-xs  text-left md:text-xs font-semibold text-gray-400">{t('Customer Name')} :</label>
                    <span className="font-semibold text-left">{generalDetails.CustomerName}</span>
                  </div>
                  <div className="col-6 md:col-4 lg:col-2  flex flex-column">
                    <label className="text-xs text-left md:text-xs font-semibold text-gray-400">{t('Payment Terms')} :</label>
                    <span className="font-semibold text-left">{generalDetails.PaymentTermsText}</span>
                  </div>
                  <div className="col-6 md:col-4 lg:col-2 flex flex-column">
                    <label className="text-xs text-left md:text-xs font-semibold text-gray-400">{t('Reference Number')} :</label>
                    <span className="font-semibold text-left">{generalDetails.ExternalReference}</span>
                  </div>
                  <div className="col-6 md:col-4 lg:col-2 flex flex-column">
                    <label className="text-xs text-left md:text-xs font-semibold text-gray-400">{t('Incoterms')} :</label>
                    <span className="font-semibold text-left">{generalDetails.Incoterms}</span>
                  </div>
                  <div className="col-6 md:col-4 lg:col-2 flex flex-column">
                    <label className="text-xs text-left md:text-xs font-semibold text-gray-400">{t('Bill to address')}:</label>
                    <span className="font-semibold text-left">{generalDetails.CustomerAddress || '-'}</span>
                  </div>
                  <div className="col-6 md:col-4 lg:col-2 flex flex-column">
                    <label className="text-xs text-left md:text-xs font-semibold text-gray-400">{t('Status')} :</label>
                    <span className="font-semibold text-left">{generalDetails.ReleaseStatusCodeText}</span>
                  </div>
                </div>

                <div className="flex gap-2 m-2 justify-content-end align-items-center">
                  <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                    {t('Net Amount')} : {generalDetails.NetAmount ? parseFloat(generalDetails.NetAmount).toFixed(2) : '-'} {generalDetails.NetAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                  </span>
                  <span className="px-4 py-1 border-round-lg font-semibold text-xs text-white surface-600">
                    {t('Gross Amount')} : {generalDetails.GrossAmount ? parseFloat(generalDetails.GrossAmount).toFixed(2) : '-'} {generalDetails.GrossAmount ? contextVariable.taxDetails.CurrencyCode : ''}
                  </span>
                </div>
              </>
              :
              <>
                <div
                  style={{ height: '5vh' }}
                  className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              </>
          }

        </div>
        {/* DataGrid Card  */}
        <div className="mt-5">
          {/* <div> */}
          <GridData
            rowData={rowData}
            columnDefs={GrossAmountIndicator === "true" ? columnDefsGrossOrderType : columnDefsNetOrderType}
            tableHeight={"58vh"}
            needFilters={false}
            showPagination={false}
            selectedRows={selectedRowsHandler}
          />
          {/* </div> */}
        </div>
        <div className='d-flex justify-content-between gap-1 mt-3'>
          <button
            className='btn btn-sm'
            disabled={currentIndex === 0}
            style={{ background: 'var(--sidebar-color)', color: '#ffffff' }}
            onClick={BeforeDeliveryItem}
          ><NavigateBeforeOutlined /> {t('Previous')}
          </button>
          <div>
            <span style={{ fontWeight: 600 }}>{currentIndex + 1} </span> {t('of')}
            <span style={{ fontWeight: 600 }}> {contextVariable.filteredData.length}</span>
          </div>
          <button className='btn btn-sm'
            disabled={(contextVariable.filteredData.length - 1) - currentIndex === 0}
            style={{ background: 'var(--sidebar-color)', color: '#ffffff' }}
            onClick={nextDeliveryItem}
          >{t('Next')} <NavigateNextOutlined style={{ fontSize: '18px' }} />
          </button>
        </div>
      </Box>
    </>
  );
};

export default CreditNoteView;
