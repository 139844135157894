import { Favorite, FavoriteBorderOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react'
import { createUserFavouriteProducts } from '../../Layout/MutaionsAndQueries';
import { AppContext } from '../../Layout/Layout';
import { deleteUserFavouriteProducts } from '../../graphql/mutations';
import { showToast } from '../ToastUtils';
import { useTranslation } from 'react-i18next';

export default function FavChoose(props) {
    const [Fav, setFav] = useState(false);
    const [Unavailableproudct, setUnavailableProduct] = useState(false);
    const contextVariable = useContext(AppContext);
    const{ t }=useTranslation();

    useEffect(() => {
        const Checking = contextVariable.AllFavDetailsWithTenent.filter((p) => p.userFavouriteProductsProductDetailId === props.Data.id)
        if (Checking.length !== 0) {
            setFav(true);
        }
        else {
            setFav(false);
        }

    }, [contextVariable.AllFavDetailsWithTenent, props.Data.id])

    useEffect(() => {
        if (props.selectedTab !== 1) {
            if (props.Data.ProductPrice === null) {
                setUnavailableProduct(true);
            }
            else {
                setUnavailableProduct(false);
            }
        }
    }, [props.selectedTab, props.Data.ProductPrice])

    const [addFavDisable, setAddFavDisable] = useState(false);

    const addToFavorite = async (ProductListData) => {
        setAddFavDisable(true);
        setFav(true);
        const data = {
            FavouriteStatus: 'active',
            owner: contextVariable.userDetails.sub,
            productID: ProductListData.id,
            userFavouriteProductsProductDetailId: ProductListData.id
        }
        const AlreadyAddedCheck = await contextVariable.AllFavDetailsWithTenent.filter((P) => P.productID === ProductListData.id);
        if (AlreadyAddedCheck.length === 0 && props.selectedTab !== 1) {
            if (contextVariable.AllFavDetailsWithTenent.length !== 100) {
                try {
                    const result = await API.graphql({
                        query: createUserFavouriteProducts,
                        variables: {
                            input: data
                        }
                    });
                    if (result) {
                        contextVariable.setFavIds((prevIds) => {
                            const AddId = new Set(prevIds);
                            AddId.add(result.data.createUserFavouriteProducts.userFavouriteProductsProductDetailId);
                            return Array.from(AddId)
                        })

                        const Checking = contextVariable.AllFavDetailsWithTenent.filter((p) => p.id === result.data.createUserFavouriteProducts.id)
                        if (Checking.length === 0) {
                            contextVariable.setAllFavDetailsWithTenent((prevItems) => [...prevItems, result.data.createUserFavouriteProducts]);
                        }
                        setAddFavDisable(false);
                    }


                } catch (error) {
                    console.error("Error in Removing", error);
                    setAddFavDisable(false);
                    showToast(t(`Cannot remove this product from favourite. Please contact admin for assistance.`), "error");
                }
            }
            else {
                setAddFavDisable(false);
                setFav(false);
                showToast(t(`Limit Reached! You can only add 100 products in favourite.`));
            }

        }
        else {
            // showToast("All ready add this product to favourite");
            setAddFavDisable(false);
            setFav(true);
        }
        setAddFavDisable(false);

    }

    const removeFromFavourite = async (ProductListData) => {
        const RemoveItemDetails = await contextVariable.AllFavDetailsWithTenent.filter((p) => p.productID === ProductListData.id);
        if (RemoveItemDetails.length !== 0) {
            const itemToDeleteId = RemoveItemDetails[0].id;
            try {
                await API.graphql({
                    query: deleteUserFavouriteProducts,
                    variables: {
                        input: {
                            id: itemToDeleteId
                        }
                    }
                });

                contextVariable.setFavIds((prevIds) => prevIds.filter(prevId => prevId !== ProductListData.id))
                const balanceFav = contextVariable.AllFavDetailsWithTenent.filter((p) => p.id !== itemToDeleteId);
                contextVariable.setAllFavDetailsWithTenent(balanceFav);
                setAddFavDisable(false);

            } catch (error) {
                setAddFavDisable(false);
            }
        }
        else {
            // showToast("Allready removed")
            setAddFavDisable(false);
        }
        setAddFavDisable(false);
    }


    return (
        <>
            {
                (Fav && !Unavailableproudct) && (
                    <IconButton
                        size='small'
                        className='text-red-600' style={{ fontSize: '0.5rem' }}
                        onClick={() => removeFromFavourite(props.Data)}
                        disabled={addFavDisable}
                    >
                        <Favorite className='flipright animation-duration-500 text-red-600' style={{ fontSize: '1rem' }} />
                    </IconButton>
                )
            }
            {(!Fav && props.selectedTab !== 1 && !Unavailableproudct) && (
                <IconButton
                    size='small'
                    className='flipright animation-duration-500 text-red-600' style={{ fontSize: '0.5rem' }}
                    onClick={(e) => {
                        addToFavorite(props.Data)
                    }

                    }
                    disabled={addFavDisable}
                >
                    <FavoriteBorderOutlined className='flipright animation-duration-500 text-red-600' style={{ fontSize: '1rem' }} />
                </IconButton>
            )
            }
        </>
    )
}
