/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cloud_logic_custom": [
        {
            "name": "CustomerportalSAPwebhookNotification",
            "endpoint": "https://bjhnelgj3a.execute-api.ap-south-1.amazonaws.com/stage",
            "region": "ap-south-1"
        },
        {
            "name": "InventoryDetails",
            "endpoint": "https://0gm9ntuxpg.execute-api.ap-south-1.amazonaws.com/stage",
            "region": "ap-south-1"
        },
        {
            "name": "StripeWebhookAPI",
            "endpoint": "https://n6tj6ic4e6.execute-api.ap-south-1.amazonaws.com/stage",
            "region": "ap-south-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://g5psi6hronhwrie2wyf6rjqava.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-q2lyxbzft5epbf4onbdw2w7eoa",
    "aws_cognito_identity_pool_id": "ap-south-1:767fe3ff-ca60-423f-9947-98956cb7a385",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_0v57TytGa",
    "aws_user_pools_web_client_id": "7oeb5vap37arerq6bsf9hs445e",
    "oauth": {
        "domain": "procustomer-stage.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.stage.procustomer.nipurnait.in/",
        "redirectSignOut": "https://www.stage.procustomer.nipurnait.in/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "customerportalproductimages0ff9c-stage",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
