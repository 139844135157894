import React from 'react'
import './DataNotFound.css';
import DataNotFoundImage from './no-data-found.png';
import { Button } from 'primereact/button';

const DataNotFound = (props) => {
    const handleRefresh = () => {
        window.location.reload();
    }

    return (
        <div className="datanotfound-container">
            <img src={DataNotFoundImage} alt="No Data Found" className="datanotfound-image" />
            <p className='text-base font-semibold text-gray-600'>Internal server error</p>
            <Button label="Refresh" icon="pi pi-refresh" onClick={handleRefresh} />
        </div>
    )
}

export default DataNotFound;