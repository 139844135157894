import { Link } from 'react-router-dom';
import NotFound from './NotFound.svg';
import { Button } from 'primereact/button';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ErrorComponent = () => {
    const { t } = useTranslation();
    const handleButtonClick = () => {
        window.location.href = '/';
    };

    return (
        <>
            <div className='container'>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
                    <img
                        style={{ maxWidth: "100%", height: "60vh" }}
                        src={NotFound}
                        alt='Page not found'
                    />
                    <div
                        className='mb-5 font-semibold text-2xl'
                        style={{ color: 'var(--sidebar-color)' }}
                    >
                        {t('Sorry Page Not Found')}
                    </div>
                    <Link to={"/"}>
                        <Button
                            onClick={handleButtonClick}
                            rounded raised
                            className='primary-button p-2 text-base'
                            label={t('Go to Dashboard')}
                            size='large'>
                            <ArrowForward />
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ErrorComponent;