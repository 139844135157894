// import { Suspense } from 'react';
import { Box, Tooltip, Zoom, useMediaQuery } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
// import Tile from "../../components/Tile";
import { useTranslation } from 'react-i18next';
import './Dashboard.css';
import { useEffect } from "react";
import { API, Auth } from "aws-amplify";
import {
  ListByCustomerOpenInvoiceCount,
  ListByCustomerCreditLimit,
  ListByCustomerOpenOrderCount,
} from "../../graphql/queries";
import { useState } from "react";
import CountUp from 'react-countup';
import Loader from "../../components/Loader";
import BackgroundSVG from '../../components/BackgroundSVG';
import '../../components/BackgroundSVG.css';
import { AttachMoneyRounded, InfoOutlined, InventoryOutlined, KeyboardBackspaceOutlined, RequestQuoteOutlined } from "@mui/icons-material";
import { PieChart, pieArcLabelClasses, pieArcClasses } from '@mui/x-charts/PieChart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AppContext } from "../../Layout/Layout";
import { useContext } from "react";
import TrendingSwiper from "./TrendingSwiper";
// import { Storage } from "aws-amplify";
// import { ProductsListsByTenant } from '../../Layout/MutaionsAndQueries';
import Trending from "./Trending.png";
import Chart from "./PieChart.png";
import InfoBar from '../../components/InfoBar';
import { Skeleton } from "primereact/skeleton";
import CardTopbar from "../../components/cards/CardTopbar";
import { showToast } from "../../components/ToastUtils";
import { BaseAndCSDProducts, BaseAndCSPProducts, BasePriceProducts } from "../../Layout/PriceAndDiscountQueries";

const Dashboard = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  sessionStorage.setItem("title", t('Dashboard'));
  const [openOrders, setOpenOrders] = useState("");
  const [openordersLoading, setOpenordersLoading] = useState(true);
  const [openOrderError, setOpenOrderError] = useState(null);

  const [openInvoices, setOpenInvoices] = useState("");
  const [openInvoicesLoading, setOpenInvoicesLoading] = useState(true);
  const [openInvoiceError, setOpenInvoiceError] = useState(null);

  const [creditLimits, setcreditLimits] = useState("");
  const [creditLimitsLoading, setCreditLimitsLoading] = useState(true);
  const [creditLimitError, setCreditLimitError] = useState(null);

  const [CurrencyCode, setCurrencyCode] = useState('')
  const [OrderStatusCount, setOrderStatusCount] = useState("Loading");
  const [orderStatusTotal, setOrderStatusTotal] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [TrendingProducts, setTrendingProducts] = useState("Loading");

  const isMobileS = useMediaQuery("(max-width: 321px)");
  const isMobileM = useMediaQuery("(max-width: 376px)");
  const isMobileL = useMediaQuery("(max-width: 426px)");
  const isLaptop = useMediaQuery("(max-width: 1400px)");
  const isLaptopL = useMediaQuery("(max-width: 1441px)");
  // console.log("isLaptop", isLaptop)
  // console.log("width", window.innerWidth)
  const isMobile = useMediaQuery("(max-width: 600px)");

  const isMobileApp = contextVariable.isMobile;

  const skeletonLoading = () => {
    return (
      <>
        <div className="flex flex-column justify-content-between align-items-center gap-3">
          <div className="">
            <Skeleton width="5rem" className=""></Skeleton>
          </div>
          <div className="">
            <Skeleton width="5rem" className=""></Skeleton>
          </div>
        </div>
      </>
    )
  }

  // console.log("matches", window.innerWidth)

  const getSymbolFromCurrency = require("currency-symbol-map");

  const fetchDatacount = async () => {
    try {
      const openOrdersPromise = API.graphql({
        query: ListByCustomerOpenOrderCount,
      });

      const creditLimitsPromise = API.graphql({
        query: ListByCustomerCreditLimit,
      });

      // Fire off all the API requests concurrently
      Promise.all([openOrdersPromise, creditLimitsPromise])
        .then(
          ([
            openOrdersResponse,
            creditLimitsResponse,
          ]) => {
            const openOrdersCount = openOrdersResponse.data.ListByCustomerOpenOrderCount;
            setOpenOrders((openOrdersCount.items.filter((p) => p.Order_StatusCode === "1").length));
            const SumOfTotalAmount = openOrdersCount.items.reduce(
              (sum, item) => sum + parseFloat(item.TotalAmount),
              0
            );
            setOpenordersLoading(false);
            //   const SumOfTotalAmount = openOrdersCount.items
            //   .filter((p) => p.Order_StatusCode === "1")
            //   .reduce((sum, item) => sum + parseFloat(item.TotalAmount), 0);
            // setOpenordersLoading(false);

            // console.log("creditLimitsResponse", creditLimitsResponse)
            const CreditLimitsData = creditLimitsResponse.data.ListByCustomerCreditLimit.count > 0 ? creditLimitsResponse.data.ListByCustomerCreditLimit : 0;
            const CreditLimitValue = CreditLimitsData !== 0 ? CreditLimitsData.items[0].credit_limit : 0;
            const CurrencyCode = CreditLimitValue !== 0 ? CreditLimitsData.items[0].currency_code : 0;
            const BalanceCreditLimit = parseFloat(CreditLimitValue) - SumOfTotalAmount;

            // Ensure the calculated balance credit limit is not negative or less than zero
            const finalCreditLimit = Math.max(BalanceCreditLimit, 0);
            // console.log("BalanceCreditLimit", finalCreditLimit)
            setcreditLimits(finalCreditLimit.toFixed(2));
            setCurrencyCode(CurrencyCode);
            setCreditLimitsLoading(false);

            // set Chart value after getting openOrdersResponse
            const orderItems = openOrdersResponse.data.ListByCustomerOpenOrderCount.items;
            setOrderStatusTotal(`(${orderItems.length})`)
            if (orderItems) {
              const statusCounts = {};
              const statusColors = {
                Open: "var(--info-color)",
                InPreparation: "var(--primary-color)",
                InProcess: "var(--secondary-color)",
                Completed: "var(--success-color)",
              };
              // Count the occurrences of each status
              orderItems.forEach((item) => {
                const normalizedStatus = item.Order_Status.replace(/\s+/g, ""); // Remove spaces from status label
                if (statusCounts[normalizedStatus]) {
                  statusCounts[normalizedStatus]++;
                } else {
                  statusCounts[normalizedStatus] = 1;
                }
              });

              // Convert statusCounts to the desired array format
              const newArray = Object.keys(statusCounts).map((status) => ({
                value: statusCounts[status],
                label: status.replace(/([A-Z])/g, " $1").trim(), // Insert space before uppercase letters
                color: statusColors[status], // Assign color based on status
              }));
              setOrderStatusCount(newArray);
              // console.log("first", newArray)
              setIsLoading(false);
            } else {
            }
          }
        )
        .catch((error) => {
          console.error("An error occurred:", error);
          setOpenordersLoading(false);
          setOpenOrderError(JSON.stringify(error.errors[0].message));
          setCreditLimitError(JSON.stringify(error.errors[0].message));
          setOrderStatusCount([]);
          setIsLoading(false);
          setcreditLimits(0);
          setCreditLimitsLoading(false);
        });
    }
    catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
      setOpenordersLoading(false);
      setOrderStatusCount([]);
      setIsLoading(false);
      setcreditLimits(0);
      setCreditLimitsLoading(false);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("Dashboard api Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }

    }
  };

  const fetchDatacountInvoice = async () => {
    try {
      const [openInvoicesResponse] = await Promise.all([
        API.graphql({
          query: ListByCustomerOpenInvoiceCount,
        }),
      ]);

      const openInvoicesCount =
        openInvoicesResponse.data.ListByCustomerOpenInvoiceCount;

      setOpenInvoices(openInvoicesCount.count);
      setOpenInvoicesLoading(false);
    } catch (error) {
      setOpenInvoicesLoading(false);
      setOpenInvoiceError(error.errors[0].message);
      setOpenInvoices(0);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetchdatacountinvoice api Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }

      console.error("An error occurred:", error);
    }
  };

  const fetchProductDetails = async () => {
    try {
      let nextToken = null;
      let productList = [];
      let ProductWithPriceTable;
      ProductWithPriceTable = contextVariable.taxDetails.AdditionalPricingOptions === "None" ? BasePriceProducts :
        contextVariable.taxDetails.AdditionalPricingOptions === "CSD" ? BaseAndCSDProducts : BaseAndCSPProducts;


      const filter = {
        ProductStatus: {
          eq: "Trending"
        },
        ProductActiveStatus: {
          eq: true
        }

      };

      do {
        const result = await API.graphql({
          query: ProductWithPriceTable,
          variables: {
            limit: 5000,
            tenant: contextVariable.userDetails["custom:tenant"],
            nextToken: nextToken,
            filter,
            customerUUID: contextVariable.userDetails["custom:customer_UUID"]
          },
        });
        // console.log("result", result)
        nextToken = result.data.ProductsListsByTenant.nextToken;
        const productItems = result.data.ProductsListsByTenant.items;
        productList = productList.concat(productItems);
        // if (productList.length >= 10 || productList.length <= 0) {
        //   nextToken = null;
        // }
      } while (nextToken);


      const first10Objects = productList.slice(0, 10);
      const productItems = first10Objects.map((p) => {
        const newProduct = { ...p };
        // console.log("newProduct", newProduct)
        if (contextVariable.taxDetails.AdditionalPricingOptions === "None") {
          if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items[0]) {
            newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
            newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity
            newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
          }
          else {
            newProduct.ProductPrice = null;
            newProduct.ProductPriceCurrencyCode = null;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = ""
            newProduct.ProductPriceQuantityCode = ""
          }
          return newProduct;
        }
        else if (contextVariable.taxDetails.AdditionalPricingOptions === "CSD") {
          if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items[0]) {
            newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
            newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity
            newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
          }
          else {
            newProduct.ProductPrice = null;
            newProduct.ProductPriceCurrencyCode = null;
            newProduct.ProductPriceQuantity = ""
            newProduct.ProductPriceQuantityCode = ""
          }
          if (p.ProductsDiscounts && p.ProductsDiscounts.items[0] && p.ProductsDiscounts.items[0].ProductDiscount) {
            newProduct.ProductDiscount = Math.abs(parseFloat(p.ProductsDiscounts.items[0].ProductDiscount).toFixed(2))
          }
          else {
            newProduct.ProductDiscount = "";
          }
          return newProduct;
        }
        else if (contextVariable.taxDetails.AdditionalPricingOptions === "CSP") {
          if (p.CustomerSpecificPrices && p.CustomerSpecificPrices.items && p.CustomerSpecificPrices.items[0] &&
            p.CustomerSpecificPrices.items[0].ProductPrice && p.CustomerSpecificPrices.items[0].ProductPrice > 0
          ) {
            newProduct.ProductPrice = p.CustomerSpecificPrices.items[0].ProductPrice;
            newProduct.ProductPriceCurrencyCode = p.CustomerSpecificPrices.items[0].ProductPriceCurrencyCode;
            newProduct.ProductDiscount = "";
            newProduct.ProductsDiscounts = { items: [] };
            newProduct.ProductPriceQuantity = p.CustomerSpecificPrices.items[0].ProductPriceQuantity
            newProduct.ProductPriceQuantityCode = p.CustomerSpecificPrices.items[0].ProductPriceQuantityCode ? p.CustomerSpecificPrices.items[0].ProductPriceQuantityCode : "";
          }
          else
            if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items[0]) {
              newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
              newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
              newProduct.ProductDiscount = "";
              newProduct.ProductsDiscounts = { items: [] };
              newProduct.ProductPriceQuantity = p.ProductsPrices.items[0].ProductPriceQuantity;
              newProduct.ProductPriceQuantityCode = p.ProductsPrices.items[0].ProductPriceQuantityCode ? p.ProductsPrices.items[0].ProductPriceQuantityCode : "";
            }
            else {
              newProduct.ProductPrice = null;
              newProduct.ProductPriceCurrencyCode = null;
              newProduct.ProductDiscount = "";
              newProduct.ProductsDiscounts = { items: [] };
              newProduct.ProductPriceQuantity = ""
              newProduct.ProductPriceQuantityCode = ""
            }
          return newProduct;
        }

        // if (p.ProductsPrices && p.ProductsPrices.items && p.ProductsPrices.items.length > 0) {
        //   newProduct.ProductPrice = p.ProductsPrices.items[0].ProductPrice;
        //   newProduct.ProductPriceCurrencyCode = p.ProductsPrices.items[0].ProductPriceCurrencyCode;
        // }
        // else {
        //   newProduct.ProductPrice = null;
        //   newProduct.ProductPriceCurrencyCode = null;
        // }

        return newProduct;

      });
      // console.log("productItems", productItems)
      setTrendingProducts(productItems);


    } catch (error) {
      setTrendingProducts([])
      console.error("Error fetching data:", error);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error("fetch product details api Error", error);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
    }
  };

  const [username, setUsername] = useState('');
  const getUsername = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.signInUserSession && user.signInUserSession.idToken) {
      setUsername(user.signInUserSession.idToken.payload["name"])
    }
  }

  //UseEffect for fetching Details
  useEffect(() => {
    if (contextVariable.userDetails !== "" && Object.keys(contextVariable.taxDetails).length > 0) {
      fetchProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextVariable.userDetails, contextVariable.taxDetails]);

  useEffect(() => {
    fetchDatacount();
    fetchDatacountInvoice();
    if (isMobileApp) {
      getUsername();
    }
    //eslint-disable-next-line
  }, []);

  // Handling back navigation for mobile
  const location = useLocation();
  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname])

  // let creditVeiwTileClassname = 'col-sm-12 col-md-6 col-lg-4';

  const handleGoToFavourites = () => {
    contextVariable.setFavouritesTab(1);
  }

  // const startMonthDay = '12-10';
  // const endMonthDay = '12-30';
  // const currentDate = new Date();
  // const currentMonthDay = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate
  //   .getDate()
  //   .toString()
  //   .padStart(2, '0')}`;
  // const isDateInRange = currentMonthDay >= startMonthDay && currentMonthDay <= endMonthDay;

  const responsiveText = 'text-xs md:text-sm lg:text-base';

  return (
    <div className='mt-0 md:mt-6 lg:mt-0'>
      <BackgroundSVG />

      {contextVariable.pageInfo === "creditLimitsCalculations" && <InfoBar header={t(`Credit Limit Calculation`)}></InfoBar>}

      {
        !isMobileApp ? (
          <>
            <Box className="fadein animation-duration-100">

              <div className={`flex justify-content-end align-items-center mt-3 p-2`}
                style={{ marginBottom: contextVariable.ProductListDetails.length <= 0 ? "40px" : "" }}
              >
                <Link
                  to="/productCatalog"
                  className={`flex gap-1 justify-content-between align-items-center font-semibold secondary-button p-button p-button-sm ${responsiveText} ${contextVariable.ProductListDetails.length <= 0 ? 'hidden' : 'visible'}`}
                  onClick={contextVariable.ProductListDetails.length > 0 ? handleGoToFavourites : undefined}
                >
                  {t("Go to Favourites")}
                  <ArrowForwardIcon fontSize='small' className='mt-1' />
                </Link>
              </div>

              <div className="container" style={{ marginTop: isMobile ? '0.8rem' : '0.5rem' }}>
                {/* Tile Secti̥on */}
                <div
                  className={`grid ${responsiveText}`}
                >

                  {/* Open orders Section */}
                  <div className='col-sm-12 col-md-6 col-lg-4 flex justify-content-center align-items-center mx-auto'>
                    <Link to={"/order"} onClick={() => { contextVariable.setGlobalSearch("Open") }}
                      className="fadein animation-duration-1000 card-shadow tile-card flex justify-content-around align-items-center dashboardCard border-3 border-round-lg">
                      <div className="tile">
                        {openordersLoading ? (
                          <div style={{ marginTop: '1rem' }}>
                            <Loader></Loader>
                          </div>
                        ) : (
                          // Show the CountUp component once the data is loaded
                          <div className="font-semibold text-base md:text-xl lg:text-2xl">
                            <CountUp end={openOrders} duration={2} />
                          </div>
                        )}

                        <h2 className={`tile-title ${responsiveText}`}>{t('Open Orders')}</h2>
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: 'var(--sidebar-color)',
                                fontSize: '12px'
                              },
                            },
                          }}
                          title="Takes you to Order page"
                          placement="bottom"
                          TransitionComponent={Zoom}
                          enterDelay={500}
                        >
                          <div className="mt-4 tile-link">{t("View Orders")} {contextVariable.isRTL ? <KeyboardBackspaceOutlined /> : <KeyboardBackspaceOutlined sx={{ transform: 'scaleX(-1)' }} />}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="icon-div">
                        <InventoryOutlined sx={{ color: '#ffffff' }} />
                      </div>
                    </Link>
                  </div>

                  {/* Open Invoice Section */}
                  <div className='col-sm-12 col-md-6 col-lg-4 flex justify-content-center align-items-center mx-auto'>
                    <div>
                      <Link to="/invoice" onClick={() => { contextVariable.setGlobalSearch("Open") }}
                        className="fadein animation-duration-1000 card-shadow tile-card flex justify-content-around dashboardCard border-3 border-round-lg">
                        <div className="tile">
                          {openInvoicesLoading ? (
                            <div style={{ marginTop: '1rem' }}>
                              <Loader></Loader>
                            </div>
                          ) : (
                            // Show the CountUp component once the data is loaded
                            <div className="font-semibold text-base md:text-xl lg:text-2xl">
                              <CountUp end={openInvoices} duration={2} />
                            </div>
                          )}
                          <h2 className={`tile-title ${responsiveText}`}>{t('Open Invoices')}</h2>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            title="Takes you to Invoice page"
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                          >
                            <div className="mt-4 tile-link">{t("View Invoices")} {contextVariable.isRTL ? <KeyboardBackspaceOutlined /> : <KeyboardBackspaceOutlined sx={{ transform: 'scaleX(-1)' }} />}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="icon-div">
                          <RequestQuoteOutlined sx={{ color: '#ffffff' }} />
                        </div>
                      </Link>
                    </div>
                  </div>

                  {/* Credit Limits Section */}
                  {creditLimits > 0 &&
                    <div className='col-sm-12 col-md-6 col-lg-4 flex justify-content-center align-items-center mx-auto'>
                      <div className="fadein animation-duration-1000 card-shadow tile-card flex justify-content-around dashboardCard border-3 border-round-lg">

                        <div className="tile">
                          {creditLimitsLoading ? (
                            <div style={{ marginTop: '1rem' }} >
                              <Loader></Loader>
                            </div>
                          ) : (
                            // Show the CountUp component once the data is loaded
                            <div className="font-semibold text-base md:text-xl lg:text-2xl">
                              {getSymbolFromCurrency(CurrencyCode)}<CountUp end={creditLimits} duration={2} />
                            </div>
                          )}
                          <h2 className={`tile-title ${responsiveText}`}>{t('Credit Limits')}
                          </h2>
                          {/* <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'var(--sidebar-color)',
                            fontSize: '12px'
                          },
                        },
                      }}
                      title="Explanation of Calculation"
                      placement="bottom"
                      TransitionComponent={Zoom}
                      enterDelay={500}
                    >
                      <div className="mt-4 tile-link cursor-pointer flex justify-content-center align-items-center"
                        onClick={() => contextVariable.setPageInfo("creditLimitsCalculations")}
                      >
                        <span>Info</span>
                        <InfoOutlined className='ml-2' fontSize='small'>
                        </InfoOutlined>
                      </div>
                    </Tooltip> */}
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'var(--sidebar-color)',
                                  fontSize: '12px'
                                },
                              },
                            }}
                            title="Explanation of Calculation"
                            placement="bottom"
                            TransitionComponent={Zoom}
                            enterDelay={500}
                          >
                            <div
                              className="cursor-pointer mt-4 tile-link flex justify-content-start align-items-center gap-2"
                              onClick={() => contextVariable.setPageInfo("creditLimitsCalculations")}>
                              {t('Info')}
                              {<InfoOutlined className='text-sm md:text-base' />}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="icon-div">
                          <AttachMoneyRounded sx={{ color: '#ffffff' }} />
                        </div>

                      </div>
                    </div>}


                  {/* <AnimatedTile number={creditLimits} title={t('creditLimit')} /> */}
                </div>

                <div className="row flex flex-column text-xs justify-content-center align-items-center md:flex-row md:text-base md:justify-content-between mt-3">
                  {/* Graph Section */}
                  <div className="col-12 mt-3 md:col-12  lg:col-6">
                    {
                      (OrderStatusCount.length !== 0) &&
                      <>
                        <div>
                          <div className="fadein animation-duration-1000 card-shadow flex justify-content-center flex-column dashboardCard border-3 border-round-lg">
                            <div className="Headerdiv flex gap-2 justify-content-start align-items-center">
                              <span className='text-base font-semibold md:text-xl'>
                                {`${t("Orders")} ${orderStatusTotal}`}
                              </span>
                              {/* <Header title={`Orders ${orderStatusTotal}`}>
                </Header> */}
                              <span className='mt-1 px-1 text-xs text-white bg-gray-600 font-semibold border-gray-600 border-1 border-round-md'>
                                {t("Last 365 days")}
                              </span>
                            </div>
                            {isLoading ? (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
                                <Loader></Loader>
                              </div>
                            )
                              :
                              (
                                <div
                                  className='flex flex-column justify-content-center align-items-center md:flex-row justify-content-around lg:flex-column'
                                  style={{ minHeight: '45vh' }}
                                >
                                  <div className='right-5 w-100'>
                                    <PieChart
                                      slotProps={{ legend: { hidden: true } }}
                                      series={[
                                        {
                                          arcLabel: (item) => `${item.value}`,
                                          arcLabelMinAngle: 20,
                                          data: OrderStatusCount,
                                          highlightScope: {
                                            faded: "global",
                                            highlighted: "item",
                                          },
                                          paddingAngle: 2,
                                          innerRadius: 20,
                                          outerRadius: isMobileS ? 90 : isMobileM ? 90 : isMobileL ? 100 : 120,
                                          cornerRadius: 5,
                                          cx: isMobileS ? 150 : isMobileM ? 170 : isMobileL ? 190 : isLaptop ? 250 : isLaptopL ? 320 : 320,
                                          cy: isMobileS ? 125 : isMobileM ? 125 : isMobileL ? 140 : 140,
                                          faded: { innerRadius: 30, additionalRadius: -30 },
                                        },
                                      ]}
                                      width={isMobileS ? 250 : isMobileM ? 280 : isMobileL ? 300 : 450}
                                      height={isMobileS ? 250 : isMobileM ? 250 : isMobileL ? 270 : 280}

                                      sx={{

                                        [`& .${pieArcClasses.faded}`]: {
                                          fill: "gray",

                                        },
                                        [`& .${pieArcLabelClasses.root}`]: {
                                          fill: "white",
                                          fontWeight: "bold",
                                          fontSize: "15px",

                                        },


                                      }}
                                    />
                                  </div>
                                  {/* Legend */}
                                  {/* <div className="text-xs justify-content-center gap-2 md:flex-column md:justify-content-around align-items-start mb-5 lg:flex-row">
                      <div className="flex gap-1 md:gap-2 align-items-center">
                        <span className="w-1rem h-1rem bg-primary p-1 md:p-2 border-round-sm"></span>
                        <span className="font-semibold">In preparation</span>
                      </div>
                      <div className="flex gap-1 md:gap-2 align-items-center">
                        <span className="w-1rem h-1rem bg-secondary p-1 md:p-2 border-round-sm"></span>
                        <span className="font-semibold">In process</span>
                      </div>
                      <div className="flex gap-1 md:gap-2 align-items-center">
                        <span className="w-1rem h-1rem bg-blue-500 p-1 md:p-2 border-round-sm"></span>
                        <span className="font-semibold">Open</span>
                      </div>
                      <div className="flex gap-1 md:gap-2 align-items-center">
                        <span style={{ background: "#198754" }} className="w-1rem h-1rem p-1 md:p-2 border-round-sm"></span>
                        <span className="font-semibold">Completed</span>
                      </div>
                    </div> */}
                                  <div className="text-xs grid  w-100  pl-6 lg:pl-1 md:pl-8 ">
                                    <div className="col-6 md:col-12 lg:col-3 flex gap-1 ">
                                      <span className="w-1rem h-1rem bg-primary p-1 md:p-2 border-round-sm"></span>
                                      <span className="font-semibold">{t("In preparation")}</span>
                                    </div>
                                    <div className="col-6 md:col-12 lg:col-3 flex gap-1">
                                      <span className="w-1rem h-1rem bg-secondary p-1 md:p-2 border-round-sm"></span>
                                      <span className="font-semibold">{t("In process")}</span>
                                    </div>
                                    <div className="col-6 md:col-12 lg:col-3 flex  gap-1 ">
                                      <span className="w-1rem h-1rem bg-blue-500 p-1 md:p-2 border-round-sm"></span>
                                      <span className="font-semibold">{t("Open")}</span>
                                    </div>
                                    <div className="col-6 md:col-12 lg:col-3 flex gap-1 ">
                                      <span style={{ background: "#198754" }} className="w-1rem h-1rem p-1 md:p-2 border-round-sm"></span>
                                      <span className="font-semibold">{t("Completed")}</span>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </>
                    }

                    {
                      (OrderStatusCount.length === 0) &&
                      <>
                        <div className='dashboardCard mt-3 card-shadow border-3 border-round-lg'>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "50vh" }}>
                            <img
                              src={Chart}
                              style={{ maxWidth: "100%", height: "auto", maxHeight: "40vh" }}
                              alt="Chart"
                            >
                            </img>
                            <label className={`font-semibold ${responsiveText}`}>
                              The chart view, showcasing order statuses, will be presented here.
                            </label>
                          </div>
                        </div>
                      </>
                    }
                  </div>

                  {/* Trending Sections */}
                  <div className="col-12 mt-3 md:col-12 lg:col-6">
                    {
                      (TrendingProducts.length !== 0) &&
                      <>
                        <div
                          className="fadein animation-duration-1000 card-shadow d-flex justify-content-center flex-column dashboardCard border-3 border-round-lg"
                        >
                          <TrendingSwiper TrendingProducts={TrendingProducts} />
                        </div>
                      </>
                    }
                    {
                      (TrendingProducts.length === 0) &&
                      <>
                        <div className='fadein animation-duration-1000 dashboardCard card-shadow'>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "50vh" }}>
                            <img
                              src={Trending}
                              style={{ maxWidth: "100%", height: "auto", maxHeight: "40vh" }}
                              alt="Trending"
                            />
                            <label className="font-semibold text-xs md:text-base">
                              Trending products will be displayed here.
                            </label>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
                {/* <Slider slides={slides} /> */}
              </div>
            </Box>
          </>
        ) : (
          <>
            {/* Pro customer logo */}
            {/* <div className="flex justify-content-center align-items-center">
              <img
                src={ProCustomer}
                style={{ height: "60px", width: "230px" }}
              />
            </div> */}
            {/* Card topbar */}
            <CardTopbar
              header={`dashboard`}
              username={username.charAt(0).toUpperCase() + username.slice(1)}
              buttonNeeded={false}
            />
            {/* Open orders and open invoices */}
            <div className="grid m-3">
              <div className="col-6">
                <Link to={"/order"}>
                  <div className="card text-left p-3 shadow-6 border-round-xl">
                    {
                      (openordersLoading === false && openOrderError === null) &&
                      <>
                        <div className="">
                          <span className="text-base">{t("Open Orders")}</span>
                        </div>
                        <div className="">
                          <span className="font-bold text-lg">
                            <CountUp end={openOrders} duration={2} />
                          </span>
                        </div>
                      </>
                    }
                    {
                      (openordersLoading === true && openOrderError === null) &&
                      <div>
                        {skeletonLoading()}
                      </div>
                    }
                    {
                      (openordersLoading === false && openOrderError !== null) &&
                      <>
                        <div className="">
                          <span className="text-base">Open Orders</span>
                        </div>
                        <div className="">
                          <span className="font-bold text-lg">
                            0
                          </span>
                        </div>
                      </>
                    }
                    {/* <div className="flex justify-content-between align-items-center">
                      {
                        openOrders !== "" &&
                        <>
                          <div className="ml-3">
                            <span className="text-lg">Open</span>
                            <br />
                            <span className="text-lg">Orders</span>
                          </div>
                          <div className="mr-3">
                            <span className="font-semibold text-lg">
                              <CountUp end={openOrders} duration={2} />
                            </span>
                          </div>
                        </>
                      }
                      {
                        openOrders === "" &&
                        <div>
                          {skeletonLoading()}
                        </div>
                      }
                    </div> */}
                  </div>
                </Link>
              </div>
              <div className="col-6">
                <Link to="/invoice">
                  <div className="card text-left pr-2 pl-3 pb-3 pt-3 shadow-6 border-round-xl">
                    {
                      (openInvoicesLoading === false && openInvoiceError === null) &&
                      <>
                        <div className="">
                          <span className="text-base">{t("Open Invoices")}</span>
                        </div>
                        <div className="">
                          <span className="font-bold text-lg">
                            <CountUp end={openInvoices} duration={2} />
                          </span>
                        </div>
                      </>
                    }
                    {
                      (openInvoicesLoading === true && openInvoiceError === null) &&
                      <>
                        {skeletonLoading()}
                      </>
                    }
                    {
                      (openInvoicesLoading === false && openInvoiceError !== null) &&
                      <>
                        <div className="">
                          <span className="text-base">{t("Open Invoices")}</span>
                        </div>
                        <div className="">
                          <span className="font-bold text-lg">
                            0
                          </span>
                        </div>
                      </>
                    }
                  </div>
                </Link>
              </div>
            </div>

            {/* Credit limit */}
            <div className="grid m-3">
              <div className="col-12">
                <div className="card text-left p-3 shadow-6 border-round-xl">
                  <div className="flex justify-content-between align-items-center">
                    {/* <div>creditLimitsLoading {creditLimitsLoading}</div> */}
                    {/* <div>creditLimitError {creditLimitError}</div> */}
                    {
                      (creditLimitsLoading === false && creditLimitError === null) &&
                      <>
                        <div>
                          <span className="text-base">Available </span>
                          <br />
                          <span className="text-base">Credit Limit</span>
                        </div>
                        <div>
                          <span className="font-bold text-lg">
                            {getSymbolFromCurrency(CurrencyCode)}<CountUp end={creditLimits} duration={2} />
                          </span>
                        </div>
                      </>
                    }
                    {
                      (creditLimitsLoading === true && creditLimitError === null) &&
                      <>
                        <div className="flex justify-content-evenly w-full">
                          <div className="">
                            <Skeleton width="5rem" className=""></Skeleton>
                          </div>
                          <div className="">
                            <Skeleton width="5rem" className=""></Skeleton>
                          </div>
                        </div>
                      </>
                    }
                    {
                      (creditLimitError !== null) &&
                      <>
                        <div>
                          <span className="text-base">Available </span>
                          <br />
                          <span className="text-base">Credit Limit</span>
                        </div>
                        <div>
                          <span className="font-bold text-lg">
                            0
                          </span>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* Graph */}
            <div className="grid m-3">
              <div className="col-12">
                <div className="card shadow-6 border-round-xl h-24rem">
                  {
                    (OrderStatusCount !== "Loading" && OrderStatusCount.length !== 0) &&
                    <div className='m-3 w-100'>
                      <span className="text-xl font-medium">Order Status</span>

                      <div className="grid mt-5" style={{ color: "var(--sidebar-color)", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {OrderStatusCount.length !== 0 ? (
                          OrderStatusCount.map((status, index) => (
                            <div key={index} className="col-12 flex justify-content-center align-items-center text-justify gap-2" style={{ width: "100%", maxWidth: "20rem" }}>
                              <span
                                style={{
                                  background: status.color,
                                  color: "white",
                                  borderRadius: "50%",
                                  width: "3rem",
                                  height: "3rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: 600
                                }}
                              >
                                {status.value}
                              </span>
                              <span className="font-normal text-lg" style={{ marginLeft: "1rem", textAlign: "left", flexGrow: 1 }}>
                                {status.label}
                              </span>
                            </div>
                          )))
                          :
                          (
                            <div className="col-12 flex justify-content-center align-items-center">
                              <p>Unable to fetch order status data. Please try again later.</p>
                            </div>
                          )
                        }
                      </div>


                      {/* <PieChart
                        slotProps={{ legend: { hidden: true } }}
                        series={[
                          {
                            arcLabel: (item) => `${item.value}`,
                            arcLabelMinAngle: 20,
                            data: OrderStatusCount,
                            highlightScope: {
                              faded: "global",
                              highlighted: "item",
                            },
                            paddingAngle: 2,
                            innerRadius: 20,
                            outerRadius: isMobileS ? 90 : isMobileM ? 90 : isMobileL ? 100 : 120,
                            cornerRadius: 5,
                            cx: isMobileS ? 130 : isMobileM ? 120 : isMobileL ? 175 : isLaptop ? 250 : isLaptopL ? 320 : 320,
                            cy: isMobileS ? 125 : isMobileM ? 125 : isMobileL ? 140 : 140,
                            faded: { innerRadius: 30, additionalRadius: -30 },
                          },
                        ]}
                        width={isMobileS ? 250 : isMobileM ? 280 : isMobileL ? 300 : 450}
                        height={isMobileS ? 250 : isMobileM ? 250 : isMobileL ? 270 : 280}

                        sx={{

                          [`& .${pieArcClasses.faded}`]: {
                            fill: "gray",

                          },
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: "white",
                            fontWeight: "bold",
                            fontSize: "15px",
                          },

                        }}
                      /> */}
                      {/* legends */}
                      {/* <div className="text-sm grid pl-4 mt-2" style={{ color: "var(--sidebar-color)" }}>
                        {OrderStatusCount.map((status, index) => (
                          <div key={index} className="col-6 flex align-items-center gap-2">
                            <span
                              className="w-1rem h-1rem p-1 border-circle"
                              style={{ background: status.color }}
                            ></span>
                            <span className="font-normal text-xs">
                              {`${status.label} (${status.value})`}
                            </span>
                          </div>
                        ))}
                      </div> */}
                    </div>
                  }
                  {/* Skeleton loader */}
                  {
                    (OrderStatusCount === "Loading" &&
                      <div className="flex flex-column mt-5 gap-3 align-items-center p-3">
                        <Skeleton shape="circle" size="10rem" className="border-circle"></Skeleton>
                        <div className="flex gap-5 justify-content-between align-items-center mt-5">
                          <div>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          </div>
                          <div>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          </div>
                        </div>
                      </div>)
                  }
                  {(OrderStatusCount !== "Loading" && OrderStatusCount.length === 0) &&
                    (
                      <div className='dashboardCard mt-3 card-shadow border-3 border-round-lg'>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", minHeight: "40vh" }}>
                          <img
                            src={Chart}
                            style={{ maxWidth: "100%", height: "auto", maxHeight: "35vh" }}
                            alt="Chart"
                          >
                          </img>
                          <label className={`text-sm ${responsiveText}`}>
                            Unable to fetch order status data.
                          </label>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

          </>
        )
      }


    </div>
  );
};

export default Dashboard;
