export const listImageErrorLogs = /* GraphQL */ `
  query ListImageErrorLogs(
    $filter: ModelImageErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DocumentFile
        DocumentDescription
        CreatedBy
        FileSize
        Status
        ImageCount
        createdAt
        type
        # updatedAt
        FailedImages {
        items {
          id
          ErrorMessage
          ProductImageName
          ImageErrorLogTableId
        }
      }
      tenant
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listLambdaLastRunTimes = /* GraphQL */ `
  query ListLambdaLastRunTimes(
    $filter: ModelLambdaLastRunTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLambdaLastRunTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductPrimaryDetails
        ProductPriceDetails
        ContactDetails
        tenant
        __typename
      }
      nextToken
      __typename
    }
  }
`;