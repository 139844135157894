import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../Layout/Layout';

export default function UOMConversion(props) {
    const contextVariable = useContext(AppContext);
    const [UOM, setUOM] = useState("");

    useEffect(() => {
        if (contextVariable.AllUOMDetails.length > 0) {
            setUOM(contextVariable.AllUOMDetails.find(P =>
                P.Value === props.UOMCode
            )?.Description || '')
        }
        // eslint-disable-next-line 
    }, [props.UOMCode])

    return (
        <span>{UOM}</span>
    )
}
