import { Box, IconButton } from '@mui/material'
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import GridData from '../../components/GridData';
import DeliveryView from './DeliveryView';
import { PictureAsPdfOutlined, CachedOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { showToast } from '../../components/ToastUtils';
import { API } from 'aws-amplify';
import { AppContext } from '../../Layout/Layout';
import { listByCustomerOutboundDeliveryDetails } from '../../Layout/MutaionsAndQueries';
import GetOrderPDF from '../order/GetOrderPDF';
import copyToClipboard from '../../components/copyToClipboard';
import { useFade } from '../../components/FadeContext/FadeContext';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import DeliveryCard from '../../components/cards/deliveries/DeliveryCard';
import SkeletonLoader from '../../components/SkeletonLoader';
import NoResultsIcon from '../order/NoResultsIcon';
import CardTopbar from '../../components/cards/CardTopbar';
import DeliveryDetailsCard from '../../components/cards/deliveries/DeliveryDetailsCard';
import { useLocation } from 'react-router-dom';
import DataNotFound from '../../components/cards/dataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';

const Delivery = () => {
  const { t } = useTranslation();
  const contextVariable = useContext(AppContext);
  const [deliveryView, setDeliveryVeiw] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState(1);
  const [rowLoadingStates, setRowLoadingStates] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [error, setError] = useState(null);

  const setRowLoadingState = (rowKey, loading) => {
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [rowKey]: loading,
    }));
  };
  const isMobile = contextVariable.isMobile;
  const [disableDate, setDisableDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const dates = [
    { label: String(currentYear), year: currentYear },
    { label: String(currentYear - 1), year: currentYear - 1 },
    { label: String(currentYear - 2), year: currentYear - 2 },
    { label: String(currentYear - 3), year: currentYear - 3 },
    { label: String(currentYear - 4), year: currentYear - 4 },
    { label: String(currentYear - 5), year: currentYear - 5 },
    { label: String(currentYear - 6), year: currentYear - 6 },
    { label: String(currentYear - 7), year: currentYear - 7 },
    { label: String(currentYear - 8), year: currentYear - 8 },
    { label: String(currentYear - 9), year: currentYear - 9 },
    { label: String(currentYear - 10), year: currentYear - 10 },
    { label: String(currentYear - 11), year: currentYear - 11 },
    { label: String(currentYear - 12), year: currentYear - 12 },
    { label: String(currentYear - 13), year: currentYear - 13 },
    { label: String(currentYear - 14), year: currentYear - 14 },
    { label: String(currentYear - 15), year: currentYear - 15 }
  ];

  const [searchQuery, setSearchQuery] = useState('');
  // const [searchText, setSearchText] = useState('')


  // const CustomCellRenderer = ({ value, searchText }) => {
  //   console.log('Rendering cell with value:', value); // Check if this log is printed
  //   const highlightedText = highlightText(value, contextVariable.globalSearch);
  //   return (
  //     <span>{highlightedText}</span>
  //   );
  // };

  const columnDefs = [
    {
      field: 'DeliveryID', headerName: `${t('Delivery ID')}`, filter: 'agNumberColumnFilter', flex: 0.5, headerClass: 'custom-ag-header',
      sortable: true,
      comparator: (valueA, valueB) => {
        return parseInt(valueA, 10) - parseInt(valueB, 10);
      },
    },
    { field: 'DeliveryDate', headerName: `${t('Delivery Date')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    {
      field: 'ReleaseStatusText', headerName: `${t('Status')}`, flex: 0.3, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        if (params.data.ReleaseStatus === "1") {
          return (
            <span className='px-2 py-1 text-yellow-600 bg-yellow-100 border-round-md font-semibold'>
              {params.value}
            </span>
          );
        } else if (params.data.ReleaseStatus === "3") {
          return (
            <span className='px-2 py-1 text-blue-600 bg-blue-100 border-round-md font-semibold'>
              {params.value}
            </span>
          );
        }
        else {
          return (
            <span className=''>
              {params.value}
            </span>
          );
        }
      },
    },
    { field: 'TrackingID', headerName: `${t('Tracking No')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    {
      field: 'FreightForwarderWithID', headerName: `${t('Freight Forwarder')}`, filter: true, flex: 0.7, headerClass: 'custom-ag-header'
    },
    {
      field: 'TrackingURI', tooltipField: "TrackingURI", headerName: `${t('Tracking URI')}`, filter: true, flex: 0.7, headerClass: 'custom-ag-header',
      cellRenderer: (params) => {

        const handleCopyToClipboard = () => {
          copyToClipboard(params.value);
          showToast(t(`Copied to your clipboard.`), 'info');
        }

        return (
          <>
            {
              params.value !== '' ?
                <div className='d-flex justify-content-between align-items-center'>
                  <u>
                    <a href={params.value} target='_blank' rel='noopener noreferrer' className='text-blue-500'>
                      {params.data.TrackingID}
                    </a>
                  </u>
                  <IconButton onClick={handleCopyToClipboard}>
                    <ContentCopyOutlined style={{ fontSize: '15px' }} />
                  </IconButton>
                </div>
                : ''
            }
          </>
        )
      }
    },
    { field: 'GrossWeight', headerName: `${t('Gross Weight')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    { field: 'GrossWeightUnitCodeText', headerName: `${t('Gross Weight Unit')}`, filter: true, flex: 0.5, headerClass: 'custom-ag-header' },
    {
      field: 'preview',
      headerName: `${t('PDF Preview')}`,
      flex: 0.5,
      headerClass: 'custom-ag-header',
      cellRenderer: (params) => {
        const handlePreviewClick = () => {
          setRowLoadingState(params.data.PDFUUID, true);
          GetOrderPDF("delivery", params.data.PDFUUID, params.data.DeliveryID).then(() => {
            setRowLoadingState(params.data.PDFUUID, false);
          });
        };
        // if (params.data.OrderStatus !== "In Preparation") {
        return (
          rowLoadingStates[params.data.PDFUUID] ? (
            <CachedOutlined
              className="rotating-icon-anticlockwise"
              style={{ marginTop: '-0.3rem' }}
            />
          ) :
            (params.data.PDFUUID).trim() !== '' ?
              <>
                <IconButton
                  style={{ marginTop: '-0.3rem' }}
                  color="var(--sidebar-color)"
                  onClick={handlePreviewClick}
                >
                  <PictureAsPdfOutlined style={{ color: 'red' }} />
                </IconButton>
              </> :
              <IconButton
                style={{ marginTop: '-0.3rem' }}
                color="var(--sidebar-color)"
                onClick={handlePreviewClick}
                disabled={true}
              >
                <PictureAsPdfOutlined />
              </IconButton>
        );
        // }
      },
    },
    {
      field: "ProductIds",
      headerName: "Product Ids ",
      filter: true,
      flex: 1,
      headerClass: "custom-ag-header",
      hide: true,
    },
    {
      field: "ExternalReference",
      headerName: "Reference Number",
      filter: true,
      flex: 1,
      headerClass: "custom-ag-header",
      tooltipField: "ExternalReference",
      hide: true
    },
    {
      field: "OrderNo",
      headerName: "Order No",
      filter: true,
      flex: 1,
      headerClass: "custom-ag-header",
      hide: true
    },
  ];

  sessionStorage.setItem("title", "Delivery")

  useEffect(() => {
    sessionStorage.setItem("date", contextVariable.date);
    //eslint-disable-next-line
  }, []);

  const fetchOutboundDelivery = async () => {
    setDeliveryDetails(1);
    setDisableDate(true);
    setLoading(true);
    let DeliveryDetails = [];
    let Skip = 0;
    try {
      do {
        const responseData = await API.graphql({
          query: listByCustomerOutboundDeliveryDetails,
          variables: {
            year: contextVariable.date,
            limit: contextVariable.FetchLimit.delivery,
            skip: Skip,
            sortField: "ID",
            sortOrder: "desc",
          }
        });
        const sessionYear = parseInt(sessionStorage.getItem("date"))
        if (responseData.data.ListByCustomerOutboundDeliveryDetails.year === sessionYear) {
          if (responseData.data.ListByCustomerOutboundDeliveryDetails.count < contextVariable.FetchLimit.delivery) {
            Skip = 0;
            contextVariable.setIsFiltered(false);
          }
          else if (responseData.data.ListByCustomerOutboundDeliveryDetails.count >= contextVariable.FetchLimit.delivery) {
            Skip = Skip + responseData.data.ListByCustomerOutboundDeliveryDetails.count;
            contextVariable.setIsFiltered(false);
          }
          DeliveryDetails = DeliveryDetails.concat(responseData.data.ListByCustomerOutboundDeliveryDetails.items)
          if (DeliveryDetails.length > 0) {
            const transformedArray = DeliveryDetails.map(item => {
              const ProductIds = item.OutboundDeliveryItem.map(item => item.ProductID).join(',');
              const OrderNo = item.OutboundDeliveryItem.map(item => item.SalesOrderID).join(',');
              return {
                ...item,
                ProductIds: ProductIds,
                OrderNo: OrderNo,
                FreightForwarderWithID: item.FreightForwarderID + "-" + item.FreightForwarder
              };
            });
            setDeliveryDetails(transformedArray);
          }
          else if (responseData.data.ListByCustomerOutboundDeliveryDetails.items.length === 0) {
            setDeliveryDetails(0);
          }
        }
        else {
          Skip = 0;
        }
      } while (Skip)
      setDisableDate(false);
      setLoading(false);
    }
    catch (error) {
      setDisableDate(false);
      setDeliveryDetails(0);
      setLoading(false);
      setError(error.errors[0].message);
      console.error("fetchOutboundDelivery Error", error);

      if (error.message && error.message.includes('No current user')) {
        console.error('Session timeout or unauthenticated state detected:', error);
        contextVariable.setIsSessionExpired(true);
      }
      else {
        console.error('fetchOutboundDelivery Error', error);
        setError(error.errors[0].message);
        showToast(t(`Something went wrong. Please contact the admin.`), "error", 4000);
      }
      // showToast(`Cannot get Outbound delivery list : ${error.errors[0].message}`, "error");
    }
  }

  useEffect(() => {
    fetchOutboundDelivery();
    //eslint-disable-next-line
  }, [contextVariable.date]);

  useEffect(() => {
    if (contextVariable) {
      contextVariable.setShowView((prev) => ({
        showOrderDetails: false,
        showDeliveryDetails: false,
        showPaymentHistoryDetails: false,
        showCreditNoteDetails: false,
        showInvoiceDetails: false,
        showInvoicPayment: false
      }));
    }
    //eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (!contextVariable.showView.showDeliveryDetails) {
      window.scrollTo(0, contextVariable.scrollPosition);
    }
  }, [contextVariable.showView.showDeliveryDetails, contextVariable.scrollPosition]);


  const openDeliveryItemView = () => {
    setDeliveryVeiw(true);
  }

  const closeDeliveryItemView = () => {
    setDeliveryVeiw(false);
  }

  const selectedRowsHandler = (rows) => {
  }

  const { fadeIn } = useFade();

  // Handler for updating the search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateChange = (e) => {
    sessionStorage.setItem("date", e.value.year);
    setSelectedDate(e.value.year);
    // contextVariable.setGlobalData(null);
    contextVariable.setGlobalSearch(contextVariable.globalSearch)
    // contextVariable.setFilteredData([]);
    contextVariable.setIsFiltered(false);
    contextVariable.setDate(e.value.year);
  };

  return (
    <>
      {deliveryView &&
        <DeliveryView
          deliveryDetails={deliveryDetails}
          backToDelivery={closeDeliveryItemView}>
        </DeliveryView>
      }

      {
        (!isMobile && contextVariable.showView.showDeliveryDetails === false) &&
        <>
          {!deliveryView && <Box className={`box-container mt-7 lg:mt-3 ${fadeIn ? "fade-in" : ""}`}>
            {/* HEADER */}
            <Box>
              {/* Datagrid Card */}
              <div className='card card-shadow'>
                <div className='card-body'>
                  <GridData
                    // rowData={rowData || "No Data"}
                    rowData={deliveryDetails}
                    // orderInput={orderValue}
                    columnDefs={columnDefs}
                    deliveryView={openDeliveryItemView}
                    component={'delivery'}
                    tableHeight={'78vh'}
                    needFilters={true}
                    showPagination={true}
                    selectedRows={selectedRowsHandler}
                    filter={'Delivery'}
                  />
                </div>
              </div>
            </Box>
          </Box>
          }
        </>
      }

      {
        (isMobile && contextVariable.showView.showDeliveryDetails === false) &&
        <>
          <div className="bg-gray-50">
            <div className='sticky top-0 z-1'>
              {/* Topbar */}
              <CardTopbar
                header="Deliveries"
                buttonNeeded={false}
                refresh={fetchOutboundDelivery}
                loading={loading}
              />
              {/* Dropdown and Search */}
              <div className="bg-gray-50 border-bottom-2 pt-2 pr-2 pl-2 shadow-2">
                <div className="grid">
                  <div className="col-4">
                    <Dropdown
                      disabled={disableDate}
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={dates}
                      optionLabel="year"
                      placeholder={contextVariable.date}
                      className="p-1 w-full mobile-dropdown"
                    />
                  </div>
                  <div className="col-8">
                    <span className="p-input-icon-left w-full">
                      <i className="pt-1 pi pi-search" />
                      <InputText
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="id, freight forwarder etc..."
                        className="p-inputtext-sm p-2 pl-5 w-full"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto">
              {
                (deliveryDetails !== 0 && deliveryDetails !== 1) &&

                deliveryDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .map((filteredEl, index) => (
                    <DeliveryCard
                      key={index}
                      index={index}
                      deliveryDetails={filteredEl}
                      contextVariable={contextVariable}
                    />
                  ))
              }
              {/* Loading skeleton */}
              {
                deliveryDetails === 1 &&
                <div className="flex flex-column">
                  {
                    [...Array(7)].map((_, index) => (
                      (deliveryDetails === 0 || deliveryDetails === 1) &&
                      <SkeletonLoader key={index} />
                    ))
                  }
                </div>
              }

              {/* No data foundd for selected year */}
              {
                (deliveryDetails === 0 && !error) &&
                <>
                  <div className="mt-3 h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                </>
              }

              {/* No data found on search */}
              {
                (deliveryDetails !== 0 && deliveryDetails !== 1) &&
                deliveryDetails
                  .filter((el) =>
                    Object.values(el).some(
                      (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                  )
                  .length === 0 && (
                  <div className="h-30rem pt-5 flex flex-column justify-content-center align-items-center bg-white">
                    <NoResultsIcon className="h-2 w-2 text-gray-400" />
                    <span>No results found</span>
                  </div>
                )
              }

            </div>

          </div>
        </>
      }

      {/* Api error */}
      {
        (error) &&
        <DataNotFound />
      }

      {
        (isMobile && contextVariable.showView.showDeliveryDetails === true) &&
        <>
          <DeliveryDetailsCard />
        </>
      }

    </>
  )
}

export default Delivery;