import { Skeleton } from "primereact/skeleton";

const SkeletonLoader = () => {
    return (
        <>
            <div className="card shadow-1 p-2 m-2">
                <div className="flex justify-content-between align-items-left pt-1">
                    <div className="align-items-center">
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                    </div>
                    <div>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                    </div>
                    <div className="align-self-center">
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SkeletonLoader;