import { API } from "aws-amplify";
import { listImageErrorLogs } from "../../Layout/AdminMutationsAndQueries";
import { ListByProductListandUserCount, listContactInformations, listOrganizationDetails } from "../../graphql/queries";
import { showToast } from "../ToastUtils";
import { updateOrganizationDetails } from "../../graphql/mutations";
import i18n from '../../i18/i18nConfig';

// Fetching Count Details in Dashboard 
export const CountInDashBoard = async (setUsersCount, setLoading, setProductsCount) => {

    try {
        const response = await API.graphql(
            {
                query: ListByProductListandUserCount
            }

        )
        setLoading(false);
        setProductsCount(response.data.ListByProductListandUserCount.ProductListCount);
        setUsersCount(response.data.ListByProductListandUserCount.UserListCount);

    }
    catch (error) {
        setLoading(false);
        setProductsCount(0);
        setUsersCount(0);
        console.error("Count Fetching error", error)
    }
}

// Fetching Contact Details List 
export const ListContactDetails = async (setTotalConteacDetails) => {
    setTotalConteacDetails(1);
    let contactDetails = [];
    let nextToken = null;
    try {
        do {
            const ContactDetailsResponse = await API.graphql(
                {
                    query: listContactInformations,
                    variables: {
                        limit: 3000,
                        nextToken: nextToken,
                    },
                }
            )
            // console.log("ContactDetailsResponse", ContactDetailsResponse)
            if (ContactDetailsResponse.data.listContactInformations.items.length === 0 && ContactDetailsResponse.data.listContactInformations.nextToken === null && contactDetails.length === 0) {
                nextToken = null;
                setTotalConteacDetails(0);
            }
            else {
                nextToken = ContactDetailsResponse.data.listContactInformations.nextToken;
                const contactDetail = ContactDetailsResponse.data.listContactInformations.items;
                contactDetails = contactDetails.concat(contactDetail);
                // console.log("contactDetails", contactDetails)
                setTotalConteacDetails(contactDetails);
            }

        }
        while (nextToken)

    }
    catch (error) {
        console.error("Contect details list error", error);
        setTotalConteacDetails(0);
    }

}

// Fetching Product image upload  details
export const productImageUploadFunction = async (setProductImageUploadData) => {
    setProductImageUploadData(1)
    let ZipFilesErrorDetails = [];
    let nextToken = null;
    const filter = {
        type: { ne: "Logo" },
    };
    do {
        try {
            const productImageUpload = await API.graphql({
                query: listImageErrorLogs,
                variables:
                {
                    limit: 3000,
                    nextToken: nextToken,
                    filter

                }
            })
            // console.log("productImageUpload", productImageUpload)
            if (productImageUpload.data.listImageErrorLogs.items.length === 0 && productImageUpload.data.listImageErrorLogs.nextToken === null && ZipFilesErrorDetails.length === 0) {
                nextToken = null;
                setProductImageUploadData(0);
            }
            else {
                nextToken = productImageUpload.data.listImageErrorLogs.nextToken;
                const AlterDataForFailedImages = productImageUpload.data.listImageErrorLogs.items.map((p) => {
                    const AddedFailedImageField = p.FailedImages.items.map((newp) => newp.ProductImageName + " " + newp.ErrorMessage + " ")

                    return {
                        ...p,
                        FailedImagesFull: AddedFailedImageField.join(', ')
                    }
                })
                ZipFilesErrorDetails = ZipFilesErrorDetails.concat(AlterDataForFailedImages)
                // const filteredRowData = ZipFilesErrorDetails.filter(row => row.type !== 'Logo');
                setProductImageUploadData(ZipFilesErrorDetails);
            }



        }
        catch (error) {
            showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, 'error', 4000);
            console.error("Product Image Upload list error", error);
            setProductImageUploadData(0);
        }
    }
    while (nextToken)
}

//Fetching organization details
export const OraganizationList = async (setOrganizationDetails, setShow) => {
    try {
        const response = await API.graphql(
            {
                query: listOrganizationDetails
            }
        )
        const items = response.data.listOrganizationDetails.items[0]
        if (items) {
            // sessionStorage.setItem("BankIndicat", items.BankDefaultIndicator)
            setOrganizationDetails(items);
            setShow("Hide");
            // setOrganizationDetails("Empty");
            // setShow("Info");
        }
        else {
            setOrganizationDetails("Empty");
            setShow("Info");
        }

    }
    catch (error) {
        console.error("Organizations List error", error);
        setOrganizationDetails("Empty");
        setShow("Info");
        showToast(`${i18n.t('Something went wrong. Please contact the admin.')}`, "error", 5000)
    }
}

// Update notification details
export const updateNotificationList = async (params, contextVariable) => {

    let inputData = {
        id: contextVariable.OrganizationDetails.id,
        NotificationList: params
    }

    // console.log("inputData",inputData);
    try {
        const updateOrganizationDetailsresponse = await API.graphql(
            {
                query: updateOrganizationDetails,
                variables:
                {
                    input: inputData
                }
            }
        )
        // console.log("first", updateOrganizationDetailsresponse);
        contextVariable.setOrganizationDetails(updateOrganizationDetailsresponse.data.updateOrganizationDetails);
        return true;
    } catch (error) {
        console.error("Error updating notification list:", error);
        return false;
    }
}



