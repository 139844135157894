import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationHB from './hb.json';
import translationFR from './fr.json';
import translationGE from './ge.json';
import translationSP from './sp.json';

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      hb: {
        translation: translationHB,
      },
      fr: {
        translation: translationFR,
      },
      ge: {
        translation: translationGE,
      },
      sp: {
        translation: translationSP,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
